import {
  AccessSetupCheckInAndCheckoutTimeModalStyle,
  BottomContainer,
  Input,
  InputMessage,
  OptionLabel,
} from '../styles/AccessSetupModals.styles';
import { FormProvider, useForm } from 'react-hook-form';
import React, { useCallback, useMemo } from 'react';
import { closeForm, openActionProcessingModal } from '../../../redux/actions/modals.actions';
import {
  converTo12HoursFormat,
  convertTo24HoursFormat,
  decreaseEndTime,
  generate24HoursTimeList,
  increaseEndTime,
} from '../../../util/time';
import { object, string } from 'yup';
import { ActionResult } from '../types';
import { BaseModalHeader } from '../../ActionProcessingModal/components/BaseModalHeader';
import { Button as ButtonComponent } from '../components/Button';
import { Container } from '../styles/TimeRangeModal.styles';
import { EarlyCheckInPackageData } from './Settings';
import { InputDropdown } from './InputDropdown';
import Modal from 'react-modal';
import { useAccessSetup } from '../../../pages/Location/components/AccessSetupContext';
import { useCreateEarlyCheckInPackage } from '../../../hooks/useCreateEarlyCheckInPackage';
import { useDispatch } from 'react-redux';
import { useUpdateEarlyCheckInPackage } from '../../../hooks/useUpdateEarlyCheckInPackage';
import { yupResolver } from '@hookform/resolvers/yup';

export const AccessSetupTimeRangeModal = 'AccessSetupTimeRangeModal';

interface TimeRange {
  startTime: string;
  endTime: string;
  pmsPackageId: string;
}

const TimeRangeValuesValidationSchema = object().shape({
  startTime: string().min(5).required(),
  endTime: string().min(5).required(),
  pmsPackageId: string().required(),
});

interface TimeRangeModalProps {
  earlyCheckInPackage?: EarlyCheckInPackageData;
}

export const TimeRangeModal: React.FC<TimeRangeModalProps> = ({ earlyCheckInPackage }) => {
  const dispatch = useDispatch();
  const { locationId, fetchData } = useAccessSetup();

  const { promise: updateEarlyCheckInPackage } = useUpdateEarlyCheckInPackage({
    onCompleted: fetchData,
  });

  const { promise: createEarlyCheckInPackage } = useCreateEarlyCheckInPackage({
    onCompleted: fetchData,
  });

  const startTime = useMemo(() => converTo12HoursFormat(earlyCheckInPackage?.startTime), [
    earlyCheckInPackage,
  ]);

  const endTime = useMemo(
    () =>
      converTo12HoursFormat(
        earlyCheckInPackage?.endTime ? increaseEndTime(earlyCheckInPackage?.endTime) : undefined
      ),
    [earlyCheckInPackage]
  );

  const pmsPackageId = useMemo(() => earlyCheckInPackage?.externalId ?? '', [earlyCheckInPackage]);

  const timeList = useMemo(() => generate24HoursTimeList(15), []);

  const form = useForm<TimeRange>({
    defaultValues: { startTime, endTime, pmsPackageId },
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(TimeRangeValuesValidationSchema),
  });

  const handleSave = useCallback(async (): Promise<void> => {
    if (!locationId) {
      return;
    }

    const isValid = await form.trigger();

    if (!isValid) {
      return;
    }

    const values = {
      locationId,
      endTime: decreaseEndTime(convertTo24HoursFormat(form.getValues('endTime'))),
      startTime: convertTo24HoursFormat(form.getValues('startTime')),
      externalId: form.getValues('pmsPackageId'),
    };

    if (!startTime || !endTime) {
      dispatch(
        openActionProcessingModal({
          action: async (): Promise<ActionResult> => {
            await createEarlyCheckInPackage({
              variables: {
                data: values,
              },
            });

            return { success: true };
          },
        })
      );
    } else {
      if (!earlyCheckInPackage?.serviceId) {
        return;
      }

      dispatch(
        openActionProcessingModal({
          action: async (): Promise<ActionResult> => {
            await updateEarlyCheckInPackage({
              variables: {
                data: values,
                where: { serviceId: earlyCheckInPackage.serviceId },
              },
            });

            return { success: true };
          },
        })
      );
    }

    dispatch(closeForm());
  }, [createEarlyCheckInPackage, earlyCheckInPackage]);

  const handleCancel = useCallback((): void => {
    dispatch(closeForm());
  }, []);

  return (
    <Modal isOpen onRequestClose={handleCancel} style={AccessSetupCheckInAndCheckoutTimeModalStyle}>
      <BaseModalHeader headerTitle="Time Range" onClose={handleCancel} />
      <FormProvider {...form}>
        <Container onSubmit={form.handleSubmit(handleSave)}>
          <div>
            <OptionLabel>From</OptionLabel>
            <InputDropdown
              name="startTime"
              options={timeList}
              error={form.errors.startTime?.message}
            />
            <InputMessage>
              {form.errors.startTime?.message ? 'Required Field' : undefined}
            </InputMessage>
          </div>
          <div>
            <OptionLabel>To</OptionLabel>
            <InputDropdown name="endTime" options={timeList} error={form.errors.endTime?.message} />
            <InputMessage>
              {form.errors.endTime?.message ? 'Required Field' : undefined}
            </InputMessage>
          </div>
          <div>
            <OptionLabel>PMS Package ID</OptionLabel>
            <Input
              name="pmsPackageId"
              ref={form.register}
              error={form.errors.pmsPackageId?.message}
            />
            <InputMessage>
              {form.errors.pmsPackageId?.message ? 'Required Field' : undefined}
            </InputMessage>
          </div>
          <BottomContainer>
            <ButtonComponent.Cancel handleCancel={handleCancel} />
            <ButtonComponent.Save handleSave={handleSave} />
          </BottomContainer>
        </Container>
      </FormProvider>
    </Modal>
  );
};
