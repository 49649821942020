/* eslint-disable sort-keys */

import { Colors } from '../../../../../../theme/colors';
import { FontSize } from '../../../../../../theme/fontSizes';
import Theme from '../../../../../../theme';
import styled from 'styled-components';

const KioskLiveUsageCardStyle: React.CSSProperties = {
  width: 385,
  minHeight: 390,
  borderRadius: 15,
  padding: 24,
  gap: 15,
  backgroundColor: Theme.colors.white.hexCode,
  gridTemplateRows: '32px auto',
};

export const KioskLiveUsageCardHeaderStyle: React.CSSProperties = {
  display: 'flex',
  flex: 1,
  justifyContent: 'space-between',
};

export const KioskLiveUsageCardKioskNameStyle: React.CSSProperties = {
  fontSize: FontSize.secondHeading,
  fontWeight: 700,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'block',
  textTransform: 'uppercase',
  width: 270,
};

export const KioskLiveUsagePreBookedPackagesHeaderStyle: React.CSSProperties = {
  marginTop: 5,
  fontSize: FontSize.thirdHeading,
};

const KioskLiveUsageCardInfoBaseStyle: React.CSSProperties = {
  borderRadius: 8,
  paddingRight: 10,
  paddingLeft: 10,
  paddingTop: 5,
  paddingBottom: 5,
  color: Colors.white,
  textAlign: 'center',
};

export const KioskLiveUsageCardStatusStyle: React.CSSProperties = {
  ...KioskLiveUsageCardInfoBaseStyle,
  color: Colors.white,
  fontWeight: 'bold',
};

export const KioskLiveUsageCardStatusActiveStyle: React.CSSProperties = {
  backgroundColor: Colors.mountainMeadow,
};

export const KioskLiveUsageCardStatusIdleStyle: React.CSSProperties = {
  color: Colors.black,
  backgroundColor: Theme.colors.frostGray.hexCode,
};

export const KioskLiveUsageCardStatusErrorStyle: React.CSSProperties = {
  color: Colors.white,
  backgroundColor: Theme.colors.danger.hexCode,
};

export const KioskLiveUsageReservationContainerInfoStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: 10,
  width: 337,
};

export const KioskLiveUsageReservationContainerRowStyle: React.CSSProperties = {
  marginBottom: '10px',
  fontSize: '16px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'block',
};

const KioskLiveUsageReservationBaseStyle: React.CSSProperties = {
  ...KioskLiveUsageCardInfoBaseStyle,
  backgroundColor: Colors.white,
  color: Colors.blackTint,
  fontWeight: 500,
};

export const KioskLiveUsageReservationInfoStyle: React.CSSProperties = {
  ...KioskLiveUsageReservationBaseStyle,
  flex: 3,
};

export const KioskLiveUsageReservation1stColumnInfoStyle: React.CSSProperties = {
  flex: 5,
};

export const KioskLiveUsageReservationConfirmationNumberStyle: React.CSSProperties = {
  color: Colors.dodgerBlue,
  fontWeight: 'bold',
};

export const KioskLiveUsagePreBookedPackagesContainerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  flex: 1,
};

export const KioskLiveUsagePreBookedPackagesStyle: React.CSSProperties = {
  ...KioskLiveUsageReservationBaseStyle,
  flex: '45%',
};

export const KioskLiveUsageUserNameStyle: React.CSSProperties = {
  fontSize: '18px',
  fontWeight: 700,
};

export const KioskLiveUsageDatesContainer: React.CSSProperties = {
  display: 'grid',
  gridTemplateColumns: 'max-content 50px max-content',
  marginBottom: '10px',
  fontSize: '16px',
};

export const LiveUsageCardTimeFormattingArrow: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '20px',
};

export const QuickActionsButtonStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  height: '52px',
  borderRadius: '12px',
  border: 'none',
  backgroundColor: Theme.colors.quickActionButton.hexCode,
  color: Theme.colors.white.hexCode,
  padding: '0 18px',
  fontSize: FontSize.label,
  fontWeight: 500,
};

export const SecondaryUsersRow: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  height: '52px',
  borderRadius: '12px',
  border: 'none',
  backgroundColor: Theme.colors.quickActionButton.hexCode,
  color: Theme.colors.white.hexCode,
  padding: '0 18px',
  fontSize: FontSize.label,
  fontWeight: 500,
  marginTop: '22px',
};

export const LiveUsageLabelContainer = styled.div`
  display: flex;
  align-items: center;

  > :nth-child(2) {
    font-size: 20px;
    margin-left: 10px;
  }
`;

export const QuickActionsButtonIconContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: ${FontSize.thirdHeading};

  > :first-child {
    margin-right: 5px;
  }
`;

export const IdleLiveUsageCardIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Theme.colors.ghostWhite.hexCode};
  border-radius: 12px;

  & > svg {
    height: 190px;
    width: 190px;
  }
`;

export default KioskLiveUsageCardStyle;
