import React, { useMemo } from 'react';

const LiveUsageQuickActionsIcon: React.FC<{ color?: string }> = ({ color }) => {
  const fillColor = useMemo(() => color || '#fff', [color]);

  return (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1726 5.74408C15.498 6.06951 15.498 6.59715 15.1726 6.92259L11.8393 10.2559C11.5138 10.5814 10.9862 10.5814 10.6607 10.2559L8.99408 8.58926C8.66864 8.26382 8.66864 7.73618 8.99408 7.41074C9.31951 7.08531 9.84715 7.08531 10.1726 7.41074L11.25 8.48816L13.9941 5.74408C14.3195 5.41864 14.8472 5.41864 15.1726 5.74408Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33333 0.5H16.6667C18.5076 0.5 20 1.99238 20 3.83333V12.1667C20 14.0076 18.5076 15.5 16.6667 15.5H3.33333C1.49239 15.5 0 14.0076 0 12.1667V3.83333C0 1.99238 1.49238 0.5 3.33333 0.5ZM3.33333 2.16667C2.41286 2.16667 1.66667 2.91286 1.66667 3.83333V12.1667C1.66667 13.0871 2.41286 13.8333 3.33333 13.8333H16.6667C17.5871 13.8333 18.3333 13.0871 18.3333 12.1667V3.83333C18.3333 2.91286 17.5871 2.16667 16.6667 2.16667H3.33333Z"
        fill={fillColor}
      />
      <path
        d="M4.16667 9.04167C4.16667 8.69649 4.44649 8.41667 4.79167 8.41667C5.13684 8.41667 5.41667 8.69649 5.41667 9.04167V11.125C5.41667 11.4702 5.13684 11.75 4.79167 11.75C4.44649 11.75 4.16667 11.4702 4.16667 11.125V9.04167Z"
        fill={fillColor}
      />
      <path
        d="M4.16667 4.875C4.16667 4.52982 4.44649 4.25 4.79167 4.25C5.13684 4.25 5.41667 4.52982 5.41667 4.875V6.95833C5.41667 7.30351 5.13684 7.58333 4.79167 7.58333C4.44649 7.58333 4.16667 7.30351 4.16667 6.95833V4.875Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default LiveUsageQuickActionsIcon;
