import {
  GetLocationsAndCountQuery,
  GetLocationsAndCountQueryVariables,
} from '../__generated__/graphql';
import {
  LazyQueryHookData,
  LazyQueryHookParams,
  LazyQueryHookType,
  defaultLazyQueryProps,
} from './@types/LazyQueryHookData';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Datum } from './@types/QueryHookData';
import { GET_LOCATIONS_AND_COUNT } from '../graphql/getLocationsAndCount';
import { RootState } from '../redux/reducers';
import { generateQueryFilteringLocation } from '../util/api/generateLocationMembershipQuery';
import { merge } from 'lodash';
import { useLazyQuery } from '@apollo/client';
import { useSelector } from 'react-redux';

type GetLocationsQueryData = GetLocationsAndCountQuery;
type GetLocationsQueryArgs = GetLocationsAndCountQueryVariables;

type UseGetLocationsProps = LazyQueryHookParams;
type UseGetLocationsType = LazyQueryHookType<Datum>;

export default function useGetLocations(props: UseGetLocationsProps): UseGetLocationsType {
  const membership = useSelector((state: RootState) => state.me.activeMembership);

  const { take } = merge(defaultLazyQueryProps, props);

  const skip = useRef<number>(0);

  const [locationsData, setLocationsData] = useState<LazyQueryHookData<Datum>>(undefined);
  const [total, setTotal] = useState<number | undefined>(undefined);

  const [getLocationsAndCount, { data, error, loading }] = useLazyQuery<
    GetLocationsQueryData,
    GetLocationsQueryArgs
  >(GET_LOCATIONS_AND_COUNT, {
    fetchPolicy: 'no-cache',
    variables: {
      where: generateQueryFilteringLocation(membership),
    },
  });

  useEffect(() => {
    if (data?.locations) {
      const newLocations = [...(locationsData ?? []), ...data.locations];

      setLocationsData(newLocations);

      setTotal(data.aggregateLocation.count?.all);
      skip.current += data.locations.length;
    }
  }, [data]);

  const locations = useMemo(() => {
    return locationsData?.map((location) => ({ id: location.id, name: location.name }));
  }, [locationsData]);

  const hasMoreData = useMemo((): boolean => {
    const locationsLoaded = locations?.length ?? 0;

    return !total || locationsLoaded < total;
  }, [total, locations]);

  const query = useCallback(() => {
    if (hasMoreData) {
      getLocationsAndCount({
        variables: {
          take,
          skip: skip.current,
        },
      });
    }
  }, [skip, take]);

  useEffect(() => {
    query();
  }, [query]);

  return {
    data: locations,
    error,
    loading,
    query,
    hasMoreData,
  };
}
