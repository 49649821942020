/* eslint-disable sort-keys */

import { JSONSchema7 } from 'json-schema';

export const kioskLocalizationSchema: JSONSchema7 = {
  type: 'object',
  properties: {
    error: {
      type: 'object',
      title: '#1 Error',
      properties: {
        'cardPrintingError:enable': {
          title: '#1 Card printing error',
          type: 'boolean',
          description: '[error.cardPrintingError]',
        },
        cardPrintingError: {
          type: 'string',
        },
        'noEmailFoundHeader:enable': {
          title: '#2 No email found header',
          type: 'boolean',
          description: '[error.noEmailFoundHeader]',
        },
        noEmailFoundHeader: {
          type: 'string',
        },
        'resourceNotFoundHeader:enable': {
          title: '#3 Resource not found header',
          type: 'boolean',
          description: '[error.resourceNotFoundHeader]',
        },
        resourceNotFoundHeader: {
          type: 'string',
        },
        'noEmailFoundButtonLabel:enable': {
          title: '#4 No email found button label',
          type: 'boolean',
          description: '[error.noEmailFoundButtonLabel]',
        },
        noEmailFoundButtonLabel: {
          type: 'string',
        },
        'noEmailFoundDescription:enable': {
          title: '#5 No email found description',
          type: 'boolean',
          description: '[error.noEmailFoundDescription]',
        },
        noEmailFoundDescription: {
          type: 'string',
        },
        'noPhoneNumberFoundHeader:enable': {
          title: '#6 No phone number found header',
          type: 'boolean',
          description: '[error.noPhoneNumberFoundHeader]',
        },
        noPhoneNumberFoundHeader: {
          type: 'string',
        },
        'noCheckInAccessGrantHeader:enable': {
          title: '#7 No check-in access grant header',
          type: 'boolean',
          description: '[error.noCheckInAccessGrantHeader]',
        },
        noCheckInAccessGrantHeader: {
          type: 'string',
        },
        'noCheckOutAccessGrantHeader:enable': {
          title: '#8 No check-out access grant header',
          type: 'boolean',
          description: '[error.noCheckOutAccessGrantHeader]',
        },
        noCheckOutAccessGrantHeader: {
          type: 'string',
        },
        'resourceNotFoundButtonLabel:enable': {
          title: '#9 Resource not found button label',
          type: 'boolean',
          description: '[error.resourceNotFoundButtonLabel]',
        },
        resourceNotFoundButtonLabel: {
          type: 'string',
        },
        'resourceNotFoundDescription:enable': {
          title: '#10 Resource not found description',
          type: 'boolean',
          description: '[error.resourceNotFoundDescription]',
        },
        resourceNotFoundDescription: {
          type: 'string',
        },
        'noPhoneNumberFoundButtonLabel:enable': {
          title: '#11 No phone number found button label',
          type: 'boolean',
          description: '[error.noPhoneNumberFoundButtonLabel]',
        },
        noPhoneNumberFoundButtonLabel: {
          type: 'string',
        },
        'noPhoneNumberFoundDescription:enable': {
          title: '#12 No phone number found description',
          type: 'boolean',
          description: '[error.noPhoneNumberFoundDescription]',
        },
        noPhoneNumberFoundDescription: {
          type: 'string',
        },
        'noCheckInAccessGrantButtonLabel:enable': {
          title: '#13 No check-in access grant button label',
          type: 'boolean',
          description: '[error.noCheckInAccessGrantButtonLabel]',
        },
        noCheckInAccessGrantButtonLabel: {
          type: 'string',
        },
        'noCheckInAccessGrantDescription:enable': {
          title: '#14 No check-in access grant description',
          type: 'boolean',
          description: '[error.noCheckInAccessGrantDescription]',
        },
        noCheckInAccessGrantDescription: {
          type: 'string',
        },
        'noCheckOutAccessGrantButtonLabel:enable': {
          title: '#15 No check-out access grant button label',
          type: 'boolean',
          description: '[error.noCheckOutAccessGrantButtonLabel]',
        },
        noCheckOutAccessGrantButtonLabel: {
          type: 'string',
        },
        'noCheckOutAccessGrantDescription:enable': {
          title: '#16 No check-out access grant description',
          type: 'boolean',
          description: '[error.noCheckOutAccessGrantDescription]',
        },
        noCheckOutAccessGrantDescription: {
          type: 'string',
        },
        'userNotFoundHeader:enable': {
          title: '#17 User not found header',
          type: 'boolean',
          description: '[error.userNotFoundHeader]',
        },
        userNotFoundHeader: {
          type: 'string',
        },
        'userNotFoundDescription:enable': {
          title: '#18 User not found description',
          type: 'boolean',
          description: '[error.userNotFoundDescription]',
        },
        userNotFoundDescription: {
          type: 'string',
        },
        'userNotFoundButtonLabel:enable': {
          title: '#19 User not found button label',
          type: 'boolean',
          description: '[error.userNotFoundButtonLabel]',
        },
        userNotFoundButtonLabel: {
          type: 'string',
        },
        'maximumCardsIssuedError:enable': {
          title: '#20 Maximum cards issued error',
          type: 'boolean',
          description: '[error.maximumCardsIssuedError]',
        },
        maximumCardsIssuedError: {
          type: 'string',
        },
      },
    },
    general: {
      type: 'object',
      title: '#2 General',
      properties: {
        'second:enable': {
          title: '#1 Second',
          type: 'boolean',
          description: '[general.second]',
        },
        second: {
          type: 'string',
        },
        'seconds:enable': {
          title: '#2 Seconds',
          type: 'boolean',
          description: '[general.seconds]',
        },
        seconds: {
          type: 'string',
        },
        'qrButton:enable': {
          title: '#3 QR button',
          type: 'boolean',
          description: '[general.qrButton]',
        },
        qrButton: {
          type: 'string',
        },
        'backButton:enable': {
          title: '#4 Back button',
          type: 'boolean',
          description: '[general.backButton]',
        },
        backButton: {
          type: 'string',
        },
        'cameraText:enable': {
          title: '#5 Camera text',
          type: 'boolean',
          description: '[general.cameraText]',
        },
        cameraText: {
          type: 'string',
        },
        'doneButton:enable': {
          title: '#6 Done button',
          type: 'boolean',
          description: '[general.doneButton]',
        },
        doneButton: {
          type: 'string',
        },
        'nameButton:enable': {
          title: '#7 Name button',
          type: 'boolean',
          description: '[general.nameButton]',
        },
        nameButton: {
          type: 'string',
        },
        'clearButton:enable': {
          title: '#8 Clear button',
          type: 'boolean',
          description: '[general.clearButton]',
        },
        clearButton: {
          type: 'string',
        },
        'emailButton:enable': {
          title: '#9 Email button',
          type: 'boolean',
          description: '[general.emailButton]',
        },
        emailButton: {
          type: 'string',
        },
        'phoneButton:enable': {
          title: '#10 Phone button',
          type: 'boolean',
          description: '[general.phoneButton]',
        },
        phoneButton: {
          type: 'string',
        },
        'replayButton:enable': {
          title: '#11 Replay button',
          type: 'boolean',
          description: '[general.replayButton]',
        },
        replayButton: {
          type: 'string',
        },
        'updateButton:enable': {
          title: '#12 Update button',
          type: 'boolean',
          description: '[general.updateButton]',
        },
        updateButton: {
          type: 'string',
        },
        'restartButton:enable': {
          title: '#13 Restart button',
          type: 'boolean',
          description: '[general.restartButton]',
        },
        restartButton: {
          type: 'string',
        },
        'continueButton:enable': {
          title: '#14 Continue button',
          type: 'boolean',
          description: '[general.continueButton]',
        },
        continueButton: {
          type: 'string',
        },
        'validateButton:enable': {
          title: '#15 Validate button',
          type: 'boolean',
          description: '[general.validateButton]',
        },
        validateButton: {
          type: 'string',
        },
        'codePlaceholder:enable': {
          title: '#16 Code placeholder',
          type: 'boolean',
          description: '[general.codePlaceholder]',
        },
        codePlaceholder: {
          type: 'string',
        },
        'talkToAgentText:enable': {
          title: '#17 Talk to agent text',
          type: 'boolean',
          description: '[general.talkToAgentText]',
        },
        talkToAgentText: {
          type: 'string',
        },
        'emailPlaceholder:enable': {
          title: '#18 Email placeholder',
          type: 'boolean',
          description: '[general.emailPlaceholder]',
        },
        emailPlaceholder: {
          type: 'string',
        },
        'resendCodeButton:enable': {
          title: '#19 Resend code button',
          type: 'boolean',
          description: '[general.resendCodeButton]',
        },
        resendCodeButton: {
          type: 'string',
        },
        'tryAnotherOption:enable': {
          title: '#20 Try another option',
          type: 'boolean',
          description: '[general.tryAnotherOption]',
        },
        tryAnotherOption: {
          type: 'string',
        },
        'qrCodeDescription:enable': {
          title: '#21 QR code description',
          type: 'boolean',
          description: '[general.qrCodeDescription]',
        },
        qrCodeDescription: {
          type: 'string',
        },
        'joinedLoyaltyTitle:enable': {
          title: '#22 Joined loyalty title',
          type: 'boolean',
          description: '[general.joinedLoyaltyTitle]',
        },
        joinedLoyaltyTitle: {
          type: 'string',
        },
        'fullNamePlaceholder:enable': {
          title: '#23 Full name placeholder',
          type: 'boolean',
          description: '[general.fullNamePlaceholder]',
        },
        fullNamePlaceholder: {
          type: 'string',
        },
        // humanReadableErrors: [
        //   {
        //     code: 'REDIRECT_GUEST_TO_HELP_DESK',
        //     message:
        // eslint-disable-next-line max-len
        //       'There was a problem checking you out. Please see a guest service agent at the reception desk.',
        //   },
        //   {
        //     code: 'AGE_VERIFICATION_FAILED',
        //     message: 'There was an issue verifying your age',
        //   },
        // ],
        'lastNamePlaceholder:enable': {
          title: '#25 Last name placeholder',
          type: 'boolean',
          description: '[general.lastNamePlaceholder]',
        },
        lastNamePlaceholder: {
          type: 'string',
        },
        'firstNamePlaceholder:enable': {
          title: '#26 First name placeholder',
          type: 'boolean',
          description: '[general.firstNamePlaceholder]',
        },
        firstNamePlaceholder: {
          type: 'string',
        },
        'idVerificationButton:enable': {
          title: '#27 ID verification button',
          type: 'boolean',
          description: '[general.idVerificationButton]',
        },
        idVerificationButton: {
          type: 'string',
        },
        'companyNamePlaceholder:enable': {
          title: '#28 Company name placeholder',
          type: 'boolean',
          description: '[general.companyNamePlaceholder]',
        },
        companyNamePlaceholder: {
          type: 'string',
        },
        'phoneNumberPlaceholder:enable': {
          title: '#29 Phone number placeholder',
          type: 'boolean',
          description: '[general.phoneNumberPlaceholder]',
        },
        phoneNumberPlaceholder: {
          type: 'string',
        },
        'remoteAssistanceButton:enable': {
          title: '#30 Remote assistance button',
          type: 'boolean',
          description: '[general.remoteAssistanceButton]',
        },
        remoteAssistanceButton: {
          type: 'string',
        },
        'joinedLoyaltyDescription:enable': {
          title: '#31 Joined loyalty description',
          type: 'boolean',
          description: '[general.joinedLoyaltyDescription]',
        },
        joinedLoyaltyDescription: {
          type: 'string',
        },
        'confirmationNumberPlaceholder:enable': {
          title: '#32 Confirmation number placeholder',
          type: 'boolean',
          description: '[general.confirmationNumberPlaceholder]',
        },
        confirmationNumberPlaceholder: {
          type: 'string',
        },
        'idleCountDownTitle:enable': {
          title: '#33 IDLE countdown popup title',
          type: 'boolean',
          description: '[general.idleCountDownTitle]',
        },
        idleCountDownTitle: {
          type: 'string',
        },
        'idleCountDownPositiveButtonText:enable': {
          title: '#34 IDLE countdown popup positive button text',
          type: 'boolean',
          description: '[general.idleCountDownPositiveButtonText]',
        },
        idleCountDownPositiveButtonText: {
          type: 'string',
        },
        'addButton:enable': {
          title: '#35 Add button text',
          type: 'boolean',
          description: '[general.addButton]',
        },
        addButton: {
          type: 'string',
        },
        'addSecondaryUserButton:enable': {
          title: '#36 Add secondary user button text',
          type: 'boolean',
          description: '[general.addSecondaryUserButton]',
        },
        addSecondaryUserButton: {
          type: 'string',
        },
        'departureDatePlaceholder:enable': {
          title: '#37 Depature date placeholder',
          type: 'boolean',
          description: '[general.departureDatePlaceholder]',
        },
        departureDatePlaceholder: {
          type: 'string',
        },
        'loading:enable': {
          title: '#38 Loading',
          type: 'boolean',
          description: '[general.loading]',
        },
        loading: {
          type: 'string',
        },
        'unhandledExceptionTitle:enable': {
          title: '#39 Unhandled exception popup title',
          type: 'boolean',
          description: '[general.unhandledExceptionTitle]',
        },
        unhandledExceptionTitle: {
          type: 'string',
        },
        'unhandledExceptionMessage:enable': {
          title: '#40 Unhandled exception popup message',
          type: 'boolean',
          description: '[general.unhandledExceptionMessage]',
        },
        unhandledExceptionMessage: {
          type: 'string',
        },
        'unhandledExceptionRestartButton:enable': {
          title: '#41 Unhandled exception popup restart button',
          type: 'boolean',
          description: '[general.unhandledExceptionRestartButton]',
        },
        unhandledExceptionRestartButton: {
          type: 'string',
        },
        'humanReadableErrors:enable': {
          title: '#42 Human readable errors',
          type: 'boolean',
          description: '[general.humanReadableErrors]',
        },
        humanReadableErrors: {
          title: 'Localize error codes to be human readable errors',
          type: 'array',
          items: {
            type: 'object',
            properties: {
              code: {
                title: 'Error code',
                type: 'string',
              },
              message: {
                title: 'Human readable error text',
                type: 'string',
              },
              header: {
                title: 'Human readable error header (not required)',
                type: 'string',
              },
            },
            required: ['code', 'message'],
          },
        },
        'checkInDatePlaceholder:enable': {
          title: '#43 Check-in date placeholder',
          type: 'boolean',
          description: '[general.checkInDatePlaceholder]',
        },
        checkInDatePlaceholder: {
          type: 'string',
        },
        'checkOutDatePlaceholder:enable': {
          title: '#44 Check-out date placeholder',
          type: 'boolean',
          description: '[general.checkOutDatePlaceholder]',
        },
        checkOutDatePlaceholder: {
          type: 'string',
        },
        'room:enable': {
          title: '#45 Room label',
          type: 'boolean',
          description: '[general.room]',
        },
        room: {
          type: 'string',
        },
        'floorPrefix:enable': {
          title: '#46 Floor label prefix',
          type: 'boolean',
          description: '[general.floorPrefix]',
        },
        floorPrefix: {
          type: 'string',
        },
        'floor:enable': {
          title: '#47 Floor label',
          type: 'boolean',
          description: '[general.floor]',
        },
        floor: {
          type: 'string',
        },
        'floorSuffix:enable': {
          title: '#48 Floor label suffix',
          type: 'boolean',
          description: '[general.floorSuffix]',
        },
        floorSuffix: {
          type: 'string',
        },
        'buildingPrefix:enable': {
          title: '#49 Building label prefix',
          type: 'boolean',
          description: '[general.buildingPrefix]',
        },
        buildingPrefix: {
          type: 'string',
        },
        'building:enable': {
          title: '#50 Building label',
          type: 'boolean',
          description: '[general.building]',
        },
        building: {
          type: 'string',
        },
        'buildingSuffix:enable': {
          title: '#51 Building label suffix',
          type: 'boolean',
          description: '[general.buildingSuffix]',
        },
        buildingSuffix: {
          type: 'string',
        },
        'packageSuccess:enable': {
          title: '#52 Package success',
          type: 'boolean',
          description: '[general.packageSuccess]',
        },
        packageSuccess: {
          type: 'string',
        },
        'tryAnotherOptionCheckInFlow:enable': {
          title: '#53 Try another option check-in flow',
          type: 'boolean',
          description: '[general.tryAnotherOptionCheckInFlow]',
        },
        tryAnotherOptionCheckInFlow: {
          type: 'string',
        },
        'tryAnotherOptionCheckOutFlow:enable': {
          title: '#54 Try another option check-out flow',
          type: 'boolean',
          description: '[general.tryAnotherOptionCheckOutFlow]',
        },
        tryAnotherOptionCheckOutFlow: {
          type: 'string',
        },
        'frontCameraText:enable': {
          title: '#55 Front camera text',
          type: 'boolean',
          description: '[general.frontCameraText]',
        },
        frontCameraText: {
          type: 'string',
        },
        'disableSingleAppMode:enable': {
          title: '#56 Disable single app mode text',
          type: 'boolean',
          description: '[general.disableSingleAppMode]',
        },
        disableSingleAppMode: {
          type: 'string',
        },
        'enableSingleAppMode:enable': {
          title: '#57 Enable single app mode text',
          type: 'boolean',
          description: '[general.enableSingleAppMode]',
        },
        enableSingleAppMode: {
          type: 'string',
        },
        'singleAppModeOnAlert:enable': {
          title: '#58 Single app mode ON alert',
          type: 'boolean',
          description: '[general.singleAppModeOnAlert]',
        },
        singleAppModeOnAlert: {
          type: 'string',
        },
        'singleAppModeOffAlert:enable': {
          title: '#59 Single app mode OFF alert',
          type: 'boolean',
          description: '[general.singleAppModeOffAlert]',
        },
        singleAppModeOffAlert: {
          type: 'string',
        },
        'singleAppModeOnFailedAlert:enable': {
          title: '#60 Single app mode ON failed alert',
          type: 'boolean',
          description: '[general.singleAppModeOnFailedAlert]',
        },
        singleAppModeOnFailedAlert: {
          type: 'string',
        },
        'singleAppModeOffFailedAlert:enable': {
          title: '#61 Single app mode OFF failed alert',
          type: 'boolean',
          description: '[general.singleAppModeOffFailedAlert]',
        },
        singleAppModeOffFailedAlert: {
          type: 'string',
        },
        'retryButton:enable': {
          title: '#62 Retry button',
          type: 'boolean',
          description: '[general.retryButton]',
        },
        retryButton: {
          type: 'string',
        },
        'okLabel:enable': {
          title: '#63 Retry button',
          type: 'boolean',
          description: '[general.okLabel]',
        },
        okLabel: {
          type: 'string',
        },
      },
    },
    homeScreen: {
      type: 'object',
      title: '#3 Home screen',
      properties: {
        'button:enable': {
          title: '#1 Button',
          type: 'boolean',
          description: '[homeScreen.button]',
        },
        button: {
          type: 'string',
        },
        'welcomeText:enable': {
          title: '#2 Welcome text',
          type: 'boolean',
          description: '[homeScreen.welcomeText]',
        },
        welcomeText: {
          type: 'string',
        },
        // welcomeBullets: ['Welcome to the hotel.', 'Please select from the options below.'],
        'checkoutTextButton:enable': {
          title: '#4 Checkout text button',
          type: 'boolean',
          description: '[homeScreen.checkoutTextButton]',
        },
        checkoutTextButton: {
          type: 'string',
        },
        'printAnotherKeycardTextButton:enable': {
          title: '#5 Print another keycard text button',
          type: 'boolean',
          description: '[homeScreen.printAnotherKeycardTextButton]',
        },
        printAnotherKeycardTextButton: {
          type: 'string',
        },
        'folioTextButton:enable': {
          title: '#6 Folio text button',
          type: 'boolean',
          description: '[homeScreen.folioTextButton]',
        },
        folioTextButton: {
          type: 'string',
        },
        'description:enable': {
          title: '#7 Description text (new layout)',
          type: 'boolean',
          description: '[homeScreen.description]',
        },
        description: {
          type: 'string',
        },
        'title:enable': {
          title: '#8 Title text (new layout)',
          type: 'boolean',
          description: '[homeScreen.title]',
        },
        title: {
          type: 'string',
        },
        'header:enable': {
          title: '#9 Header text (new layout)',
          type: 'boolean',
          description: '[homeScreen.header]',
        },
        header: {
          type: 'string',
        },
        'conciergeTextButton:enable': {
          title: '#10 Concierge text button',
          type: 'boolean',
          description: '[homeScreen.conciergeTextButton]',
        },
        conciergeTextButton: {
          type: 'string',
        },
        'instructionText:enable': {
          title: '#11 Instruction text',
          type: 'boolean',
          description: '[homeScreen.instructionText]',
        },
        instructionText: {
          type: 'string',
        },
        'idcard_title:enable': {
          title: '#12 ID card title',
          type: 'boolean',
          description: '[homeScreen.idcard_title]',
        },
        idcard_title: {
          type: 'string',
        },
        'idcard_desc:enable': {
          title: '#13 ID card description',
          type: 'boolean',
          description: '[homeScreen.idcard_desc]',
        },
        idcard_desc: {
          type: 'string',
        },
        'credit_title:enable': {
          title: '#14 Credit card title',
          type: 'boolean',
          description: '[homeScreen.credit_title]',
        },
        credit_title: {
          type: 'string',
        },
        'credit_desc:enable': {
          title: '#15 Credit card description',
          type: 'boolean',
          description: '[homeScreen.credit_desc]',
        },
        credit_desc: {
          type: 'string',
        },
        'welcomeBullets:enable': {
          title: '#16 Welcome bullets',
          type: 'boolean',
          description: '[homeScreen.welcomeBullets]',
        },
        welcomeBullets: {
          type: 'string',
        },
      },
    },
    vettingScreen: {
      type: 'object',
      title: '#4 Vetting screen',
      properties: {
        'headerText:enable': {
          title: '#1 Header text',
          type: 'boolean',
          description: '[vettingScreen.headerText]',
        },
        headerText: {
          type: 'string',
        },
        'errorHeaderText:enable': {
          title: '#2 Error header text',
          type: 'boolean',
          description: '[vettingScreen.errorHeaderText]',
        },
        errorHeaderText: {
          type: 'string',
        },
        'errorDescriptionText:enable': {
          title: '#3 Error description text',
          type: 'boolean',
          description: '[vettingScreen.errorDescriptionText]',
        },
        errorDescriptionText: {
          type: 'string',
        },
        'remoteAssistanceButton:enable': {
          title: '#4 Remote assistance button text',
          type: 'boolean',
          description: '[vettingScreen.remoteAssistanceButton]',
        },
        remoteAssistanceButton: {
          type: 'string',
        },
        'questions:enable': {
          title: '#5 Questions',
          type: 'boolean',
          description: '[vettingScreen.questions]',
        },
        questions: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              position: {
                title: 'Question position',
                description: 'The display position of the question',
                type: 'number',
              },
              question: {
                title: 'Question text',
                description: 'The question presented to the user',
                type: 'string',
              },
            },
            required: ['position', 'question'],
          },
        },
      },
    },
    loyaltyScreen: {
      type: 'object',
      title: '#5 Loyalty screen',
      properties: {
        'header:enable': {
          title: '#1 Header',
          type: 'boolean',
          description: '[loyaltyScreen.header]',
        },
        header: {
          type: 'string',
        },
        'joinButton:enable': {
          title: '#2 Join button',
          type: 'boolean',
          description: '[loyaltyScreen.joinButton]',
        },
        joinButton: {
          type: 'string',
        },
        'skipButton:enable': {
          title: '#3 Skip button',
          type: 'boolean',
          description: '[loyaltyScreen.skipButton]',
        },
        skipButton: {
          type: 'string',
        },
        'joinLoyaltyFailed:enable': {
          title: '#4 Join loyalty failed',
          type: 'boolean',
          description: '[loyaltyScreen.joinLoyaltyFailed]',
        },
        joinLoyaltyFailed: {
          type: 'string',
        },
      },
    },
    paymentScreen: {
      type: 'object',
      title: '#6 Payment screen',
      properties: {
        'header:enable': {
          title: '#1 Header',
          type: 'boolean',
          description: '[paymentScreen.header]',
        },
        header: {
          type: 'string',
        },
        'description:enable': {
          title: '#2 Description',
          type: 'boolean',
          description: '[paymentScreen.description]',
        },
        description: {
          type: 'string',
        },
        'primaryButton:enable': {
          title: '#3 Primary button',
          type: 'boolean',
          description: '[paymentScreen.primaryButton]',
        },
        primaryButton: {
          type: 'string',
        },
        'authorizationTitle:enable': {
          title: '#4 Authorization title',
          type: 'boolean',
          description: '[paymentScreen.authorizationTitle]',
        },
        authorizationTitle: {
          type: 'string',
        },
        'captureHeader:enable': {
          title: '#5 Capture header',
          type: 'boolean',
          description: '[paymentScreen.captureHeader]',
        },
        captureHeader: {
          type: 'string',
        },
        'captureDescription:enable': {
          title: '#6 Capture description',
          type: 'boolean',
          description: '[paymentScreen.captureDescription]',
        },
        captureDescription: {
          type: 'string',
        },
        'captureTitle:enable': {
          title: '#7 Capture title',
          type: 'boolean',
          description: '[paymentScreen.captureTitle]',
        },
        captureTitle: {
          type: 'string',
        },
        'pay:enable': {
          title: '#8 Pay button text',
          type: 'boolean',
          description: '[paymentScreen.pay]',
        },
        pay: {
          type: 'string',
        },
        addCardOverlay: {
          type: 'object',
          title: '#9 Add card overlay',
          properties: {
            'startButton:enable': {
              title: '#9.1 Start button text',
              type: 'boolean',
              description: '[paymentScreen.addCardOverlay.startButton]',
            },
            startButton: {
              type: 'string',
            },
            'startHeader:enable': {
              title: '#9.2 Start header text',
              type: 'boolean',
              description: '[paymentScreen.addCardOverlay.startHeader]',
            },
            startHeader: {
              type: 'string',
            },
            'progressingHeader:enable': {
              title: '#9.3 Collecting card information header text',
              type: 'boolean',
              description: '[paymentScreen.addCardOverlay.progressingHeader]',
            },
            progressingHeader: {
              type: 'string',
            },
            'successHeader:enable': {
              title: '#9.4 Success collecting card information header text',
              type: 'boolean',
              description: '[paymentScreen.addCardOverlay.successHeader]',
            },
            successHeader: {
              type: 'string',
            },
            'collectingInformation:enable': {
              title: '#9.5 Collecting card information text',
              type: 'boolean',
              description: '[paymentScreen.addCardOverlay.collectingInformation]',
            },
            collectingInformation: {
              type: 'string',
            },
          },
        },
      },
    },
    checkoutScreen: {
      type: 'object',
      title: '#7 Checkout screen',
      properties: {
        'header:enable': {
          title: '#1 Header',
          type: 'boolean',
          description: '[checkoutScreen.header]',
        },
        header: {
          type: 'string',
        },
        'checkoutFailed:enable': {
          title: '#2 Checkout failed',
          type: 'boolean',
          description: '[checkoutScreen.checkoutFailed]',
        },
        checkoutFailed: {
          type: 'string',
        },
        'useDefaultCard:enable': {
          title: '#3 Use default card',
          type: 'boolean',
          description: '[checkoutScreen.useDefaultCard]',
        },
        useDefaultCard: {
          type: 'string',
        },
        'useDifferentCard:enable': {
          title: '#4 Use different card',
          type: 'boolean',
          description: '[checkoutScreen.useDifferentCard]',
        },
        useDifferentCard: {
          type: 'string',
        },
        'checkoutUnavailable:enable': {
          title: '#5 Checkout unavailable',
          type: 'boolean',
          description: '[checkoutScreen.checkoutUnavailable]',
        },
        checkoutUnavailable: {
          type: 'string',
        },
        'checkoutSuccessfully:enable': {
          title: '#6 Checkout successfully',
          type: 'boolean',
          description: '[checkoutScreen.checkoutSuccessfully]',
        },
        checkoutSuccessfully: {
          type: 'string',
        },
        'folioHeader:enable': {
          title: '#7 Folio header',
          type: 'boolean',
          description: '[checkoutScreen.folioHeader]',
        },
        folioHeader: {
          type: 'string',
        },
        'total:enable': {
          title: '#8 Total',
          type: 'boolean',
          description: '[checkoutScreen.total]',
        },
        total: {
          type: 'string',
        },
        'description:enable': {
          title: '#9 Description',
          type: 'boolean',
          description: '[checkoutScreen.description]',
        },
        description: {
          type: 'string',
        },
        'done:enable': {
          title: '#10 Done',
          type: 'boolean',
          description: '[checkoutScreen.done]',
        },
        done: {
          type: 'string',
        },
        'paymentInfoHeader:enable': {
          title: '#11 Payment info header',
          type: 'boolean',
          description: '[checkoutScreen.paymentInfoHeader]',
        },
        paymentInfoHeader: {
          type: 'string',
        },
        'checkoutButton:enable': {
          title: '#12 Checkout button',
          type: 'boolean',
          description: '[checkoutScreen.checkoutButton]',
        },
        checkoutButton: {
          type: 'string',
        },
        'reservationDetail:enable': {
          title: '#13 Reservation detail title',
          type: 'boolean',
          description: '[checkoutScreen.reservationDetail]',
        },
        reservationDetail: {
          type: 'string',
        },
      },
    },
    signatureScreen: {
      type: 'object',
      title: '#8 Signature screen',
      properties: {
        'title:enable': {
          title: '#1 Title',
          type: 'boolean',
          description: '[signatureScreen.title]',
        },
        title: {
          type: 'string',
        },
        'header:enable': {
          title: '#2 Header',
          type: 'boolean',
          description: '[signatureScreen.header]',
        },
        header: {
          type: 'string',
        },
        'description:enable': {
          title: '#3 Description',
          type: 'boolean',
          description: '[signatureScreen.description]',
        },
        description: {
          type: 'string',
        },
        'termsAndConditionsButton:enable': {
          title: '#4 Terms and conditions button',
          type: 'boolean',
          description: '[signatureScreen.termsAndConditionsButton]',
        },
        termsAndConditionsButton: {
          type: 'string',
        },
        'signDescription:enable': {
          title: '#5 Description in signature container ',
          type: 'boolean',
          description: '[signatureScreen.signDescription]',
        },
        signDescription: {
          type: 'string',
        },
        'signTitle:enable': {
          title: '#6 title in signature container ',
          type: 'boolean',
          description: '[signatureScreen.signTitle]',
        },
        signTitle: {
          type: 'string',
        },
        'agreementTitle:enable': {
          title: '#7 Title in agreement container',
          type: 'boolean',
          description: '[signatureScreen.agreementTitle]',
        },
        agreementTitle: {
          type: 'string',
        },
        'agreements:enable': {
          title: '#8 Agreements messages',
          type: 'boolean',
          description: '[signatureScreen.agreements]',
        },
        agreements: {
          type: 'array',
          items: {
            type: 'string',
          },
        },
        'submit:enable': {
          title: '#9 Submit button',
          type: 'boolean',
          description: '[signatureScreen.submit]',
        },
        submit: {
          type: 'string',
        },
        'requiredFieldError:enable': {
          title: '#10 Required fields error',
          type: 'boolean',
          description: '[signatureScreen.requiredFieldError]',
        },
        requiredFieldError: {
          type: 'string',
        },
        'updateRequirementFailed:enable': {
          title: '#11 Update requirement error',
          type: 'boolean',
          description: '[signatureScreen.updateRequirementFailed]',
        },
        updateRequirementFailed: {
          type: 'string',
        },
        'uploadRegcardError:enable': {
          title: '#12 Upload reg card error',
          type: 'boolean',
          description: '[signatureScreen.uploadRegcardError]',
        },
        uploadRegcardError: {
          type: 'string',
        },
        'uploadSignatureError:enable': {
          title: '#13 Upload signature error',
          type: 'boolean',
          description: '[signatureScreen.uploadSignatureError]',
        },
        uploadSignatureError: {
          type: 'string',
        },
        'downloadLeaseError:enable': {
          title: '#14 Download lease error',
          type: 'boolean',
          description: '[signatureScreen.downloadLeaseError]',
        },
        downloadLeaseError: {
          type: 'string',
        },
        'tncCloseButton:enable': {
          title: '#15 T&C close button',
          type: 'boolean',
          description: '[signatureScreen.tncCloseButton]',
        },
        tncCloseButton: {
          type: 'string',
        },
        'agreementsWithOptions:enable': {
          title: '#16 Agreements with Options',
          type: 'boolean',
          description: '[signatureScreen.agreementsWithOptions]',
        },
        agreementsWithOptions: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              id: {
                title: 'Agreement ID',
                type: 'string',
                readOnly: true,
              },
              title: {
                title: 'Agreement Title',
                description: 'This field will only be use to populate the report in Event Server',
                type: 'string',
              },
              message: {
                title: 'Agreement message',
                type: 'string',
              },
              options: {
                title: 'Agreement options',
                type: 'object',
                properties: {
                  isOptional: {
                    title: 'Is Agreement Optional',
                    type: undefined,
                    enum: [true, false],
                  },
                },
              },
            },
            required: ['message', 'id', 'title'],
            uniqueItems: true,
          },
        },
      },
    },
    authSelectScreen: {
      type: 'object',
      title: '#9 Auth select screen',
      properties: {
        'name:enable': {
          title: '#1 Name',
          type: 'boolean',
          description: '[authSelectScreen.name]',
        },
        name: {
          type: 'string',
        },
        'email:enable': {
          title: '#2 Email',
          type: 'boolean',
          description: '[authSelectScreen.email]',
        },
        email: {
          type: 'string',
        },
        'header:enable': {
          title: '#3 Header',
          type: 'boolean',
          description: '[authSelectScreen.header]',
        },
        header: {
          type: 'string',
        },
        'qrCode:enable': {
          title: '#4 QR code',
          type: 'boolean',
          description: '[authSelectScreen.qrCode]',
        },
        qrCode: {
          type: 'string',
        },
        'phoneNumber:enable': {
          title: '#5 Phone number',
          type: 'boolean',
          description: '[authSelectScreen.phoneNumber]',
        },
        phoneNumber: {
          type: 'string',
        },
        'checkoutHeader:enable': {
          title: '#6 Checkout header',
          type: 'boolean',
          description: '[authSelectScreen.checkoutHeader]',
        },
        checkoutHeader: {
          type: 'string',
        },
        'printAnotherKeycardHeader:enable': {
          title: '#7 Print another keycard header',
          type: 'boolean',
          description: '[authSelectScreen.printAnotherKeycardHeader]',
        },
        printAnotherKeycardHeader: {
          type: 'string',
        },
        'idVerification:enable': {
          title: '#8 ID verification',
          type: 'boolean',
          description: '[authSelectScreen.idVerification]',
        },
        idVerification: {
          type: 'string',
        },
        'idAuthentication:enable': {
          title: '#9 ID authentication',
          type: 'boolean',
          description: '[authSelectScreen.idAuthentication]',
        },
        idAuthentication: {
          type: 'string',
        },
        'room:enable': {
          title: '#10 Room',
          type: 'boolean',
          description: '[authSelectScreen.room]',
        },
        room: {
          type: 'string',
        },
        'folioheader:enable': {
          title: '#11 Folio header',
          type: 'boolean',
          description: '[authSelectScreen.folioheader]',
        },
        folioheader: {
          type: 'string',
        },
        'nameAndDepartureDate:enable': {
          title: '#12 Name and departure date',
          type: 'boolean',
          description: '[authSelectScreen.nameAndDepartureDate]',
        },
        nameAndDepartureDate: {
          type: 'string',
        },
        'confirmationCode:enable': {
          title: '#13 Confirmation code only',
          type: 'boolean',
          description: '[authSelectScreen.confirmationCode]',
        },
        confirmationCode: {
          type: 'string',
        },
      },
    },
    scanQRCodeScreen: {
      type: 'object',
      title: '#10 Scan QR code screen',
      properties: {
        'header:enable': {
          title: '#1 Header',
          type: 'boolean',
          description: '[scanQRCodeScreen.header]',
        },
        header: {
          type: 'string',
        },
        'retakeButton:enable': {
          title: '#2 Retake button',
          type: 'boolean',
          description: '[scanQRCodeScreen.retakeButton]',
        },
        retakeButton: {
          type: 'string',
        },
        'pastReservationHeader:enable': {
          title: '#3 Past reservation header',
          type: 'boolean',
          description: '[scanQRCodeScreen.pastReservationHeader]',
        },
        pastReservationHeader: {
          type: 'string',
        },
        'notTodayReservationHeader:enable': {
          title: '#4 Not today reservation header',
          type: 'boolean',
          description: '[scanQRCodeScreen.notTodayReservationHeader]',
        },
        notTodayReservationHeader: {
          type: 'string',
        },
        'pastReservationButtonLabel:enable': {
          title: '#5 Past reservation buttonLabel',
          type: 'boolean',
          description: '[scanQRCodeScreen.pastReservationButtonLabel]',
        },
        pastReservationButtonLabel: {
          type: 'string',
        },
        'pastReservationDescription:enable': {
          title: '#6 Past reservation description',
          type: 'boolean',
          description: '[scanQRCodeScreen.pastReservationDescription]',
        },
        pastReservationDescription: {
          type: 'string',
        },
        'notTodayReservationButtonLabel:enable': {
          title: '#7 Not today reservation buttonLabel',
          type: 'boolean',
          description: '[scanQRCodeScreen.notTodayReservationButtonLabel]',
        },
        notTodayReservationButtonLabel: {
          type: 'string',
        },
        'notTodayReservationDescription:enable': {
          title: '#8 Not today reservation description',
          type: 'boolean',
          description: '[scanQRCodeScreen.notTodayReservationDescription]',
        },
        notTodayReservationDescription: {
          type: 'string',
        },
        'resourceNotFoundHeader:enable': {
          title: '#9 Resource not found header',
          type: 'boolean',
          description: '[scanQRCodeScreen.resourceNotFoundHeader]',
        },
        resourceNotFoundHeader: {
          type: 'string',
        },
        'resourceNotFoundDescription:enable': {
          title: '#10 Resource not found description',
          type: 'boolean',
          description: '[scanQRCodeScreen.resourceNotFoundDescription]',
        },
        resourceNotFoundDescription: {
          type: 'string',
        },
        'resourceNotFoundButtonLabel:enable': {
          title: '#11 Resource not found button label',
          type: 'boolean',
          description: '[scanQRCodeScreen.resourceNotFoundButtonLabel]',
        },
        resourceNotFoundButtonLabel: {
          type: 'string',
        },
        'fallbackErrorMessage:enable': {
          title: '#12 Fallback error message',
          type: 'boolean',
          description: '[scanQRCodeScreen.fallbackErrorMessage]',
        },
        fallbackErrorMessage: {
          type: 'string',
        },
      },
    },
    requirementsScreen: {
      type: 'object',
      title: '#11 Requirements screen',
      properties: {
        'title:enable': {
          title: '#1 Title',
          type: 'boolean',
          description: '[requirementsScreen.title]',
        },
        title: {
          type: 'string',
        },
        'header:enable': {
          title: '#2 Header',
          type: 'boolean',
          description: '[requirementsScreen.header]',
        },
        header: {
          type: 'string',
        },
        'idVerification:enable': {
          title: '#3 ID Verification requirement text',
          type: 'boolean',
          description: '[requirementsScreen.idVerification]',
        },
        idVerification: {
          type: 'string',
        },
        'paymentStatus:enable': {
          title: '#4 Payment Status requirement text',
          type: 'boolean',
          description: '[requirementsScreen.paymentStatus]',
        },
        paymentStatus: {
          type: 'string',
        },
        'paymentAuthorization:enable': {
          title: '#5 Payment Authorization requirement text',
          type: 'boolean',
          description: '[requirementsScreen.paymentAuthorization]',
        },
        paymentAuthorization: {
          type: 'string',
        },
        'termsAndConditions:enable': {
          title: '#6 Terms and Conditions requirement text',
          type: 'boolean',
          description: '[requirementsScreen.termsAndConditions]',
        },
        termsAndConditions: {
          type: 'string',
        },
        'paymentCapture:enable': {
          title: '#7 Payment Capture requirement text',
          type: 'boolean',
          description: '[requirementsScreen.paymentCapture]',
        },
        paymentCapture: {
          type: 'string',
        },
        'mrzIdVerification:enable': {
          title: '#8 MRZ (Simplified) ID Verification requirement text',
          type: 'boolean',
          description: '[requirementsScreen.mrzIdVerification]',
        },
        mrzIdVerification: {
          type: 'string',
        },
        onRequirementBackButton: {
          type: 'object',
          title: '#9 All requirements screen back button popup localization',
          properties: {
            'popupTitle:enable': {
              title: '#9.1 Popup title',
              type: 'boolean',
              description: '[requirementsScreen.onRequirementBackButton.popupTitle]',
            },
            popupTitle: {
              type: 'string',
            },
            'popupNegativeButton:enable': {
              title: '#9.2 Popup negative button',
              type: 'boolean',
              description: '[requirementsScreen.onRequirementBackButton.popupNegativeButton]',
            },
            popupNegativeButton: {
              type: 'string',
            },
            'popupPositiveButton:enable': {
              title: '#9.3 Popup positive button',
              type: 'boolean',
              description: '[requirementsScreen.onRequirementBackButton.popupPositiveButton]',
            },
            popupPositiveButton: {
              type: 'string',
            },
          },
        },
        'userInformation:enable': {
          title: '#10 User Information requirement text',
          type: 'boolean',
          description: '[requirementsScreen.userInformation]',
        },
        userInformation: {
          type: 'string',
        },
        'nameMatches:enable': {
          title: '#11 Name matches requirement text',
          type: 'boolean',
          description: '[requirementsScreen.nameMatches]',
        },
        nameMatches: {
          type: 'string',
        },
        'pmsConfirmation:enable': {
          title: '#12 PMS confirmation requirement text',
          type: 'boolean',
          description: '[requirementsScreen.pmsConfirmation]',
        },
        pmsConfirmation: {
          type: 'string',
        },
        'healthCheck:enable': {
          title: '#13 Health check requirement text',
          type: 'boolean',
          description: '[requirementsScreen.healthCheck]',
        },
        healthCheck: {
          type: 'string',
        },
        'manualCheckIn:enable': {
          title: '#14 Manual check-in requirement text',
          type: 'boolean',
          description: '[requirementsScreen.manualCheckIn]',
        },
        manualCheckIn: {
          type: 'string',
        },
        'businessIdVerification:enable': {
          title: '#15 Business ID verification requirement text',
          type: 'boolean',
          description: '[requirementsScreen.businessIdVerification]',
        },
        businessIdVerification: {
          type: 'string',
        },
        'requirementFailedTitle:enable': {
          title: '#16 Requirement failed title text',
          type: 'boolean',
          description: '[requirementsScreen.requirementFailedTitle]',
        },
        requirementFailedTitle: {
          type: 'string',
        },
        'contactFrontDesk:enable': {
          title: '#17 Contact front desk text',
          type: 'boolean',
          description: '[requirementsScreen.contactFrontDesk]',
        },
        contactFrontDesk: {
          type: 'string',
        },
      },
    },
    keyDispensingScreen: {
      type: 'object',
      title: '#12 Key dispensing screen',
      properties: {
        'info:enable': {
          title: '#1 Info',
          type: 'boolean',
          description: '[keyDispensingScreen.info]',
        },
        info: {
          type: 'string',
        },
        'header:enable': {
          title: '#2 Header',
          type: 'boolean',
          description: '[keyDispensingScreen.header]',
        },
        header: {
          type: 'string',
        },
        'noButton:enable': {
          title: '#3 No button',
          type: 'boolean',
          description: '[keyDispensingScreen.noButton]',
        },
        noButton: {
          type: 'string',
        },
        'codeTitle:enable': {
          title: '#4 Code title',
          type: 'boolean',
          description: '[keyDispensingScreen.codeTitle]',
        },
        codeTitle: {
          type: 'string',
        },
        'helpTitle:enable': {
          title: '#5 Help title',
          type: 'boolean',
          description: '[keyDispensingScreen.helpTitle]',
        },
        helpTitle: {
          type: 'string',
        },
        'keycardHeader:enable': {
          title: '#6 Keycard header',
          type: 'boolean',
          description: '[keyDispensingScreen.keycardHeader]',
        },
        keycardHeader: {
          type: 'string',
        },
        'codeDescription:enable': {
          title: '#7 Code description',
          type: 'boolean',
          description: '[keyDispensingScreen.codeDescription]',
        },
        codeDescription: {
          type: 'string',
        },
        'helpDescription:enable': {
          title: '#8 Help description',
          type: 'boolean',
          description: '[keyDispensingScreen.helpDescription]',
        },
        helpDescription: {
          type: 'string',
        },
        'printingKeyCard:enable': {
          title: '#9 Printing key card',
          type: 'boolean',
          description: '[keyDispensingScreen.printingKeyCard]',
        },
        printingKeyCard: {
          type: 'string',
        },
        'anotherKeyCardText:enable': {
          title: '#10 Another key card text',
          type: 'boolean',
          description: '[keyDispensingScreen.anotherKeyCardText]',
        },
        anotherKeyCardText: {
          type: 'string',
        },
        'expiresPlaceholder:enable': {
          title: '#11 Expires placeholder',
          type: 'boolean',
          description: '[keyDispensingScreen.expiresPlaceholder]',
        },
        expiresPlaceholder: {
          type: 'string',
        },
        'keyCardDescription:enable': {
          title: '#12 Key card description',
          type: 'boolean',
          description: '[keyDispensingScreen.keyCardDescription]',
        },
        keyCardDescription: {
          type: 'string',
        },
        'printKeyCardButton:enable': {
          title: '#13 Print key card button',
          type: 'boolean',
          description: '[keyDispensingScreen.printKeyCardButton]',
        },
        printKeyCardButton: {
          type: 'string',
        },
        'cardHolderPlaceholder:enable': {
          title: '#14 Card holder placeholder',
          type: 'boolean',
          description: '[keyDispensingScreen.cardHolderPlaceholder]',
        },
        cardHolderPlaceholder: {
          type: 'string',
        },
        'codeHolderPlaceholder:enable': {
          title: '#15 Code holder placeholder',
          type: 'boolean',
          description: '[keyDispensingScreen.codeHolderPlaceholder]',
        },
        codeHolderPlaceholder: {
          type: 'string',
        },
        'codeInstructionMessage:enable': {
          title: '#16 Code instruction message',
          type: 'boolean',
          description: '[keyDispensingScreen.codeInstructionMessage]',
        },
        codeInstructionMessage: {
          type: 'string',
        },
        'keycardInstructionMessage:enable': {
          title: '#17 Keycard instruction message',
          type: 'boolean',
          description: '[keyDispensingScreen.keycardInstructionMessage]',
        },
        keycardInstructionMessage: {
          type: 'string',
        },
        'qrScan:enable': {
          title: '#18 QR scan',
          type: 'boolean',
          description: 'Scan this QR Code on QR Code sections [keyDispensingScreen.qrScan]',
        },
        qrScan: {
          type: 'string',
        },
        'qrDescription:enable': {
          title: '#19 QR description',
          type: 'boolean',
          description: 'QR description on QR Code sections [keyDispensingScreen.qrDescription]',
        },
        qrDescription: {
          type: 'string',
        },
        'qrHeader:enable': {
          title: '#20 QR header',
          type: 'boolean',
          description: 'Header on left side sections [keyDispensingScreen.qrHeader]',
        },
        qrHeader: {
          type: 'string',
        },
        'additionalTitle:enable': {
          title: '#21 Additional section title',
          type: 'boolean',
          description: 'Header on additional sections [keyDispensingScreen.additionalTitle]',
        },
        additionalTitle: {
          type: 'string',
        },
        'additionalDescription:enable': {
          title: '#22 Additional description',
          type: 'boolean',
          description:
            'Description of additional container [keyDispensingScreen.additionalDescription]',
        },
        additionalDescription: {
          type: 'string',
        },
        'mobileWebTitle:enable': {
          title: '#23 Mobile web title',
          type: 'boolean',
          description: 'Header on  mobile web qr sections [keyDispensingScreen.mobileWebTitle]',
        },
        mobileWebTitle: {
          type: 'string',
        },
        'mobileWebDescription:enable': {
          title: '#24 Mobile web description',
          type: 'boolean',
          description:
            'description on mobile web qr sections [keyDispensingScreen.mobileWebDescription]',
        },
        mobileWebDescription: {
          type: 'string',
        },
        'deeplinkTitle:enable': {
          title: '#25 Deeplink Title',
          type: 'boolean',
          description: 'Header on Deep link qr code sections [keyDispensingScreen.deeplinkTitle]',
        },
        deeplinkTitle: {
          type: 'string',
        },
        'deeplinkDescription:enable': {
          title: '#26 Deeplink description',
          type: 'boolean',
          description:
            'description on Deep link qr code sections [keyDispensingScreen.deeplinkDescription]',
        },
        deeplinkDescription: {
          type: 'string',
        },
        'description:enable': {
          title: '#27 Description',
          type: 'boolean',
          description: 'Sub header of the screen [keyDispensingScreen.description]',
        },
        description: {
          type: 'string',
        },
        'roomTitle:enable': {
          title: '#28 Room title',
          type: 'boolean',
          description: 'Sub header of the screen [keyDispensingScreen.roomTitle]',
        },
        roomTitle: {
          type: 'string',
        },
        'doneButton:enable': {
          title: '#29 Done button',
          type: 'boolean',
          description: 'Sub header of the screen [keyDispensingScreen.doneButton]',
        },
        doneButton: {
          type: 'string',
        },
        'maxKeyLimitTitle:enable': {
          title: '#30 Max key limit title',
          type: 'boolean',
          description: 'Sub header of the screen [keyDispensingScreen.maxKeyLimitTitle]',
        },
        maxKeyLimitTitle: {
          type: 'string',
        },
        animatedKeyCard: {
          type: 'object',
          title: '#31 Animated Key Card',
          properties: {
            'title:enable': {
              title: '#31.1 Animated Key Card Title Text',
              type: 'boolean',
              description: 'Sub header of the screen [keyDispensingScreen.animatedKeyCard.title]',
            },
            title: {
              type: 'string',
            },
            'description:enable': {
              title: '#31.2 Animated Key Card description Text',
              type: 'boolean',
              description:
                'Sub header of the screen [keyDispensingScreen.animatedKeyCard.description]',
            },
            description: {
              type: 'string',
            },
            'footer:enable': {
              title: '#31.3 Animated Key Card Footer Text',
              type: 'boolean',
              description: 'Sub header of the screen [keyDispensingScreen.animatedKeyCard.footer]',
            },
            footer: {
              type: 'string',
            },
            'optionalTitle:enable': {
              title: '#31.4 Animated Key Card Room Label Text',
              type: 'boolean',
              description:
                'Sub header of the screen [keyDispensingScreen.animatedKeyCard.optionalTitle]',
            },
            optionalTitle: {
              type: 'string',
            },
          },
        },
        keyCardPrintSuccess: {
          type: 'object',
          title: '#32 Key Card Print Success',
          properties: {
            'headerText:enable': {
              title: '#32.1 Key Card Print Success Screen Header Text',
              type: 'boolean',
              description:
                'Header of the screen [keyDispensingScreen.keyCardPrintSuccess.headerText]',
            },
            headerText: {
              type: 'string',
            },
            'subHeaderText:enable': {
              title: '#32.2 Key Card Print Success Screen sub Header Text',
              type: 'boolean',
              description:
                'Header of the screen [keyDispensingScreen.keyCardPrintSuccess.subHeaderText]',
            },
            subHeaderText: {
              type: 'string',
            },
            printExtraKeyCard: {
              type: 'object',
              title: '#32.3 Key Card Print Success Print extra key card',
              properties: {
                'buttonText:enable': {
                  title: '#32.3.1 Key Card Print Success Screen print Extra Key Card Button Text',
                  type: 'boolean',
                  description:
                    'Header of the screen [keyDispensingScreen.keyCardPrintSuccess.printExtraKeyCard.buttonText]',
                },
                buttonText: {
                  type: 'string',
                },
                'optionalText:enable': {
                  title:
                    '#32.3.1 Key Card Print Success Screen print Extra Key Card Button optional Text',
                  type: 'boolean',
                  description:
                    'Header of the screen [keyDispensingScreen.keyCardPrintSuccess.printExtraKeyCard.optionalText]',
                },
                optionalText: {
                  type: 'string',
                },
              },
            },
            asideLayoutGridConfig: {
              type: 'object',
              title: '#32.4 Key Card Print Success aside layout grid titles',
              properties: {
                'topGridTitle:enable': {
                  title: '#32.4.1 Key Card Print Success aside layout grid top section title',
                  type: 'boolean',
                  description:
                    'Key Card Print Success aside layout grid top section title [keyDispensingScreen.keyCardPrintSuccess.asideLayoutGridConfig.topGridTitle]',
                },
                topGridTitle: {
                  type: 'string',
                },
                'bottomGridTitle:enable': {
                  title: '#32.4.2 Key Card Print Success aside layout grid bottom section title',
                  type: 'boolean',
                  description:
                    'Key Card Print Success aside layout grid bottom section title [keyDispensingScreen.keyCardPrintSuccess.asideLayoutGridConfig.bottomGridTitle]',
                },
                bottomGridTitle: {
                  type: 'string',
                },
                'topGridHeader:enable': {
                  title: '#32.4.3 Key Card Print Success aside layout grid top section header',
                  type: 'boolean',
                  description:
                    'Key Card Print Success aside layout grid top section title [keyDispensingScreen.keyCardPrintSuccess.asideLayoutGridConfig.topGridHeader]',
                },
                topGridHeader: {
                  type: 'string',
                },
                'bottomGridHeader:enable': {
                  title: '#32.4.4 Key Card Print Success aside layout grid bottom section header',
                  type: 'boolean',
                  description:
                    'Key Card Print Success aside layout grid bottom section title [keyDispensingScreen.keyCardPrintSuccess.asideLayoutGridConfig.bottomGridHeader]',
                },
                bottomGridHeader: {
                  type: 'string',
                },
              },
            },
          },
        },
        keyCardPrintError: {
          type: 'object',
          title: '#33 Key Card Print Error',
          properties: {
            'headerText:enable': {
              title: '#33.1 Key Card Print Error Screen Header Text',
              type: 'boolean',
              description:
                'Header of the screen [keyDispensingScreen.keyCardPrintError.headerText]',
            },
            headerText: {
              type: 'string',
            },
            'descriptionText:enable': {
              title: '#33.2 Key Card Print Error Screen description Text',
              type: 'boolean',
              description:
                'Header of the screen [keyDispensingScreen.keyCardPrintError.descriptionText]',
            },
            descriptionText: {
              type: 'string',
            },
            'message:enable': {
              title: '#33.3 Key Card Print Error Screen message Text',
              type: 'boolean',
              description: 'Header of the screen [keyDispensingScreen.keyCardPrintError.message]',
            },
            message: {
              type: 'string',
            },
          },
        },
      },
    },
    obfuscateAuthScreen: {
      type: 'object',
      title: '#13 Obfuscate auth screen',
      properties: {
        'header:enable': {
          title: '#1 Header',
          type: 'boolean',
          description: '[obfuscateAuthScreen.header]',
        },
        header: {
          type: 'string',
        },
        'cardTitle:enable': {
          title: '#2 Card title',
          type: 'boolean',
          description: '[obfuscateAuthScreen.cardTitle]',
        },
        cardTitle: {
          type: 'string',
        },
        'emailButtonLabel:enable': {
          title: '#3 Email button label',
          type: 'boolean',
          description: '[obfuscateAuthScreen.emailButtonLabel]',
        },
        emailButtonLabel: {
          type: 'string',
        },
        'cardOtherAuthTitle:enable': {
          title: '#4 Card other auth title',
          type: 'boolean',
          description: '[obfuscateAuthScreen.cardOtherAuthTitle]',
        },
        cardOtherAuthTitle: {
          type: 'string',
        },
        'phoneNumberButtonLabel:enable': {
          title: '#5 Phone number button label',
          type: 'boolean',
          description: '[obfuscateAuthScreen.phoneNumberButtonLabel]',
        },
        phoneNumberButtonLabel: {
          type: 'string',
        },
        'idVerificationButtonLabel:enable': {
          title: '#6 ID verification button label',
          type: 'boolean',
          description: '[obfuscateAuthScreen.idVerificationButtonLabel]',
        },
        idVerificationButtonLabel: {
          type: 'string',
        },
        'remoteAssistanceButtonLabel:enable': {
          title: '#7 Remote assistance button label',
          type: 'boolean',
          description: '[obfuscateAuthScreen.remoteAssistanceButtonLabel]',
        },
        remoteAssistanceButtonLabel: {
          type: 'string',
        },
      },
    },
    idVerificationScreen: {
      type: 'object',
      title: '#14 ID verification screen',
      properties: {
        'retry:enable': {
          title: '#1 Retry',
          type: 'boolean',
          description: '[idVerificationScreen.retry]',
        },
        retry: {
          type: 'string',
        },
        'header:enable': {
          title: '#2 Header',
          type: 'boolean',
          description: '[idVerificationScreen.header]',
        },
        header: {
          type: 'string',
        },
        'backText:enable': {
          title: '#3 Back text',
          type: 'boolean',
          description: '[idVerificationScreen.backText]',
        },
        backText: {
          type: 'string',
        },
        'titleText:enable': {
          title: '#4 Title text',
          type: 'boolean',
          description: '[idVerificationScreen.titleText]',
        },
        titleText: {
          type: 'string',
        },
        'titleAlert:enable': {
          title: '#5 Title alert',
          type: 'boolean',
          description: '[idVerificationScreen.titleAlert]',
        },
        titleAlert: {
          type: 'string',
        },
        'description:enable': {
          title: '#6 Description',
          type: 'boolean',
          description: '[idVerificationScreen.description]',
        },
        description: {
          type: 'string',
        },
        'loginHeader:enable': {
          title: '#7 Login header',
          type: 'boolean',
          description: '[idVerificationScreen.loginHeader]',
        },
        loginHeader: {
          type: 'string',
        },
        'uploadTitle:enable': {
          title: '#8 Upload title',
          type: 'boolean',
          description: '[idVerificationScreen.uploadTitle]',
        },
        uploadTitle: {
          type: 'string',
        },
        'backSideText:enable': {
          title: '#9 Back side text',
          type: 'boolean',
          description: '[idVerificationScreen.backSideText]',
        },
        backSideText: {
          type: 'string',
        },
        'faceSideText:enable': {
          title: '#10 Face side text',
          type: 'boolean',
          description: '[idVerificationScreen.faceSideText]',
        },
        faceSideText: {
          type: 'string',
        },
        'frontSideText:enable': {
          title: '#11 Front side text',
          type: 'boolean',
          description: '[idVerificationScreen.frontSideText]',
        },
        frontSideText: {
          type: 'string',
        },
        'visaTitleText:enable': {
          title: '#12 Visa title text',
          type: 'boolean',
          description: '[idVerificationScreen.visaTitleText]',
        },
        visaTitleText: {
          type: 'string',
        },
        'nextButtonText:enable': {
          title: '#13 Next button text',
          type: 'boolean',
          description: '[idVerificationScreen.nextButtonText]',
        },
        nextButtonText: {
          type: 'string',
        },
        'uploadingTitle:enable': {
          title: '#14 Uploading title',
          type: 'boolean',
          description: '[idVerificationScreen.uploadingTitle]',
        },
        uploadingTitle: {
          type: 'string',
        },
        'idCardTitleText:enable': {
          title: '#15 ID card title text',
          type: 'boolean',
          description: '[idVerificationScreen.idCardTitleText]',
        },
        idCardTitleText: {
          type: 'string',
        },
        'textButtonAlert:enable': {
          title: '#16 Text button alert',
          type: 'boolean',
          description: '[idVerificationScreen.textButtonAlert]',
        },
        textButtonAlert: {
          type: 'string',
        },
        'consentTitleText:enable': {
          title: '#17 Consent title text',
          type: 'boolean',
          description: '[idVerificationScreen.consentTitleText]',
        },
        consentTitleText: {
          type: 'string',
        },
        'countryTitleText:enable': {
          title: '#18 Country title text',
          type: 'boolean',
          description: '[idVerificationScreen.countryTitleText]',
        },
        countryTitleText: {
          type: 'string',
        },
        'descriptionAlert:enable': {
          title: '#19 Description alert',
          type: 'boolean',
          description: '[idVerificationScreen.descriptionAlert]',
        },
        descriptionAlert: {
          type: 'string',
        },
        'headerResultText:enable': {
          title: '#20 Header result text',
          type: 'boolean',
          description: '[idVerificationScreen.headerResultText]',
        },
        headerResultText: {
          type: 'string',
        },
        'retakeButtonText:enable': {
          title: '#21 Retake button text',
          type: 'boolean',
          description: '[idVerificationScreen.retakeButtonText]',
        },
        retakeButtonText: {
          type: 'string',
        },
        'driverLicenseText:enable': {
          title: '#22 Driver license text',
          type: 'boolean',
          description: '[idVerificationScreen.driverLicenseText]',
        },
        driverLicenseText: {
          type: 'string',
        },
        'passportTitleText:enable': {
          title: '#23 Passport title text',
          type: 'boolean',
          description: '[idVerificationScreen.passportTitleText]',
        },
        passportTitleText: {
          type: 'string',
        },
        'uploadDescription:enable': {
          title: '#24 Upload description',
          type: 'boolean',
          description: '[idVerificationScreen.uploadDescription]',
        },
        uploadDescription: {
          type: 'string',
        },
        'uploadFailedTitle:enable': {
          title: '#25 Upload failed title',
          type: 'boolean',
          description: '[idVerificationScreen.uploadFailedTitle]',
        },
        uploadFailedTitle: {
          type: 'string',
        },
        'selectCountryTitle:enable': {
          title: '#26 Select country title',
          type: 'boolean',
          description: '[idVerificationScreen.selectCountryTitle]',
        },
        selectCountryTitle: {
          type: 'string',
        },
        'uploadSuccessTitle:enable': {
          title: '#27 Upload success title',
          type: 'boolean',
          description: '[idVerificationScreen.uploadSuccessTitle]',
        },
        uploadSuccessTitle: {
          type: 'string',
        },
        'backInstructionText:enable': {
          title: '#28 Back instruction text',
          type: 'boolean',
          description: '[idVerificationScreen.backInstructionText]',
        },
        backInstructionText: {
          type: 'string',
        },
        'faceInstructionText:enable': {
          title: '#29 Face instruction text',
          type: 'boolean',
          description: '[idVerificationScreen.faceInstructionText]',
        },
        faceInstructionText: {
          type: 'string',
        },
        'frontInstructionText:enable': {
          title: '#30 Front instruction text',
          type: 'boolean',
          description: '[idVerificationScreen.frontInstructionText]',
        },
        frontInstructionText: {
          type: 'string',
        },
        'scanYourPassportText:enable': {
          title: '#31 Scan your passport text',
          type: 'boolean',
          description: '[idVerificationScreen.scanYourPassportText]',
        },
        scanYourPassportText: {
          type: 'string',
        },
        'uploadingDescription:enable': {
          title: '#32 Uploading description',
          type: 'boolean',
          description: '[idVerificationScreen.uploadingDescription]',
        },
        uploadingDescription: {
          type: 'string',
        },
        'driverLicenseTitleText:enable': {
          title: '#33 Driver license title text',
          type: 'boolean',
          description: '[idVerificationScreen.driverLicenseTitleText]',
        },
        driverLicenseTitleText: {
          type: 'string',
        },
        'flipDocumentAlertTitle:enable': {
          title: '#34 Flip document alert title',
          type: 'boolean',
          description: '[idVerificationScreen.flipDocumentAlertTitle]',
        },
        flipDocumentAlertTitle: {
          type: 'string',
        },
        'shutterLabelButtonText:enable': {
          title: '#35 Shutter label button text',
          type: 'boolean',
          description: '[idVerificationScreen.shutterLabelButtonText]',
        },
        shutterLabelButtonText: {
          type: 'string',
        },
        'passportInstructionText:enable': {
          title: '#36 Passport instruction text',
          type: 'boolean',
          description: '[idVerificationScreen.passportInstructionText]',
        },
        passportInstructionText: {
          type: 'string',
        },
        'uploadSuccessDescription:enable': {
          title: '#37 Upload success description',
          type: 'boolean',
          description: '[idVerificationScreen.uploadSuccessDescription]',
        },
        uploadSuccessDescription: {
          type: 'string',
        },
        'idCardBackInstructionText:enable': {
          title: '#38 ID card back instruction text',
          type: 'boolean',
          description: '[idVerificationScreen.idCardBackInstructionText]',
        },
        idCardBackInstructionText: {
          type: 'string',
        },
        'backCaptureInstructionText:enable': {
          title: '#39 Back capture instruction text',
          type: 'boolean',
          description: '[idVerificationScreen.backCaptureInstructionText]',
        },
        backCaptureInstructionText: {
          type: 'string',
        },
        'faceCaptureInstructionText:enable': {
          title: '#40 Face capture instruction text',
          type: 'boolean',
          description: '[idVerificationScreen.faceCaptureInstructionText]',
        },
        faceCaptureInstructionText: {
          type: 'string',
        },
        'idCardFrontInstructionText:enable': {
          title: '#41 idCardFrontInstructionText',
          type: 'boolean',
          description: '[idVerificationScreen.idCardFrontInstructionText]',
        },
        idCardFrontInstructionText: {
          type: 'string',
        },
        'passportPreInstructionText:enable': {
          title: '#42 Passport pre instruction text',
          type: 'boolean',
          description: '[idVerificationScreen.passportPreInstructionText]',
        },
        passportPreInstructionText: {
          type: 'string',
        },
        'faceVerificationInstruction:enable': {
          title: '#43 Face verification instruction',
          type: 'boolean',
          description: '[idVerificationScreen.faceVerificationInstruction]',
        },
        faceVerificationInstruction: {
          type: 'string',
        },
        'frontCaptureInstructionText:enable': {
          title: '#44 Front capture instruction text',
          type: 'boolean',
          description: '[idVerificationScreen.frontCaptureInstructionText]',
        },
        frontCaptureInstructionText: {
          type: 'string',
        },
        'flipDocumentAlertButtonLabel:enable': {
          title: '#45 Flip document alert button label',
          type: 'boolean',
          description: '[idVerificationScreen.flipDocumentAlertButtonLabel]',
        },
        flipDocumentAlertButtonLabel: {
          type: 'string',
        },
        'flipDocumentAlertDescription:enable': {
          title: '#46 Flip document alert description',
          type: 'boolean',
          description: '[idVerificationScreen.flipDocumentAlertDescription]',
        },
        flipDocumentAlertDescription: {
          type: 'string',
        },
        'passportCaptureInstructionText:enable': {
          title: '#47 Passport capture instruction text',
          type: 'boolean',
          description: '[idVerificationScreen.passportCaptureInstructionText]',
        },
        passportCaptureInstructionText: {
          type: 'string',
        },
        'documentVerificationInstruction:enable': {
          title: '#48 Document verification instruction',
          type: 'boolean',
          description: '[idVerificationScreen.documentVerificationInstruction]',
        },
        documentVerificationInstruction: {
          type: 'string',
        },
        'passportVerificationInstruction:enable': {
          title: '#49 Passport verification instruction',
          type: 'boolean',
          description: '[idVerificationScreen.passportVerificationInstruction]',
        },
        passportVerificationInstruction: {
          type: 'string',
        },
        'driverLicenseBackInstructionText:enable': {
          title: '#50 Driver license back instruction text',
          type: 'boolean',
          description: '[idVerificationScreen.driverLicenseBackInstructionText]',
        },
        driverLicenseBackInstructionText: {
          type: 'string',
        },
        'driverLicenseFrontInstructionText:enable': {
          title: '#51 Driver license front instruction text',
          type: 'boolean',
          description: '[idVerificationScreen.driverLicenseFrontInstructionText]',
        },
        driverLicenseFrontInstructionText: {
          type: 'string',
        },
        'instructionText:enable': {
          title: '#52 Instruction text',
          description:
            '[idVerificationScreen.instructionText] - ID Verification main screen instruction text',
          type: 'boolean',
        },
        instructionText: {
          type: 'string',
        },
        'instruction_ID:enable': {
          title: '#53 Document type instruction text',
          type: 'boolean',
          description:
            '[idVerificationScreen.instruction_ID] - ID Verification main screen document type instruction text',
        },
        instruction_ID: {
          type: 'string',
        },
        'instruction_AGE:enable': {
          title: '#54 Age verification instruction text',
          type: 'boolean',
          description:
            '[idVerificationScreen.instruction_AGE] - ID Verification main screen age verification instruction text',
        },
        instruction_AGE: {
          type: 'string',
        },
        'instruction_NAME:enable': {
          title: '#55 Name match instruction text',
          type: 'boolean',
          description:
            '[idVerificationScreen.instruction_NAME] - ID Verification main screen name match instruction text',
        },
        instruction_NAME: {
          type: 'string',
        },
        'uploadFailedDescription:enable': {
          title: '#56 Upload failed description',
          type: 'boolean',
          description: '[idVerificationScreen.uploadFailedDescription]',
        },
        uploadFailedDescription: {
          type: 'string',
        },
        'idleCountDownTitle:enable': {
          title: '#57 Idle popup countdown title',
          type: 'boolean',
          description: '[idVerificationScreen.idleCountDownTitle]',
        },
        idleCountDownTitle: {
          type: 'string',
        },
        'idleCountDownPositiveButtonText:enable': {
          title: '#58 Idle popup positive button text',
          type: 'boolean',
          description: '[idVerificationScreen.idleCountDownPositiveButtonText]',
        },
        idleCountDownPositiveButtonText: {
          type: 'string',
        },
        'idleCountDownNegativeButtonText:enable': {
          title: '#59 Idle popup negative button text',
          type: 'boolean',
          description: '[idVerificationScreen.idleCountDownNegativeButtonText]',
        },
        idleCountDownNegativeButtonText: {
          type: 'string',
        },
        'consentText:enable': {
          title: '#60 Consent text',
          type: 'boolean',
          description: '[idVerificationScreen.consentText]',
        },
        consentText: {
          type: 'string',
        },
        'privacyText:enable': {
          title: '#61 Privacy policy hyperlink text',
          type: 'boolean',
          description: '[idVerificationScreen.privacyText]',
        },
        privacyText: {
          type: 'string',
        },
        'continueButton:enable': {
          title: '#62 Continue button text',
          type: 'boolean',
          description: '[idVerificationScreen.continueButton]',
        },
        continueButton: {
          type: 'string',
        },
        'privacyPolicyHeader:enable': {
          title: '#63 Privacy policy modal header text',
          type: 'boolean',
          description: '[idVerificationScreen.privacyPolicyHeader]',
        },
        privacyPolicyHeader: {
          type: 'string',
        },
        'faceNotFullyVisibleAlertTitle:enable': {
          title: '#64 Face not fully visible alert title',
          type: 'boolean',
          description: '[idVerificationScreen.faceNotFullyVisibleAlertTitle]',
        },
        faceNotFullyVisibleAlertTitle: {
          type: 'string',
        },
        'faceNotFullyVisibleAlertDescription:enable': {
          title: '#65 Face not fully visible alert description',
          type: 'boolean',
          description: '[idVerificationScreen.faceNotFullyVisibleAlertDescription]',
        },
        faceNotFullyVisibleAlertDescription: {
          type: 'string',
        },
        'faceNotFullyVisibleAlertButtonLabel:enable': {
          title: '#66 Face not fully visible alert button label',
          type: 'boolean',
          description: '[idVerificationScreen.faceNotFullyVisibleAlertButtonLabel]',
        },
        faceNotFullyVisibleAlertButtonLabel: {
          type: 'string',
        },
        'faceNotDetectedErrorText:enable': {
          title: '#67 Face not detected error text',
          type: 'boolean',
          description: '[idVerificationScreen.faceNotDetectedErrorText]',
        },
        faceNotDetectedErrorText: {
          type: 'string',
        },
        'faceNotFullyVisibleErrorText:enable': {
          title: '#68 Face not fully visible error text',
          type: 'boolean',
          description: '[idVerificationScreen.faceNotFullyVisibleErrorText]',
        },
        faceNotFullyVisibleErrorText: {
          type: 'string',
        },
        'faceNotCenteredErrorText:enable': {
          title: '#69 Face not centered error text',
          type: 'boolean',
          description: '[idVerificationScreen.faceNotCenteredErrorText]',
        },
        faceNotCenteredErrorText: {
          type: 'string',
        },
        'backAlertText:enable': {
          title: '#70 Back alert text',
          type: 'boolean',
          description: '[idVerificationScreen.backAlertText]',
        },
        backAlertText: {
          type: 'string',
        },
        'backAlertNegativeButton:enable': {
          title: '#71 Back alert negative button text',
          type: 'boolean',
          description: '[idVerificationScreen.backAlertNegativeButton]',
        },
        backAlertNegativeButton: {
          type: 'string',
        },
        'backAlertPositiveButton:enable': {
          title: '#72 Back alert positive button text',
          type: 'boolean',
          description: '[idVerificationScreen.backAlertPositiveButton]',
        },
        backAlertPositiveButton: {
          type: 'string',
        },
        'processingText:enable': {
          title: '#73 Processing text',
          type: 'boolean',
          description: '[idVerificationScreen.processingText]',
        },
        processingText: {
          type: 'string',
        },
        'retryPopupTitle:enable': {
          title: '#74 Retry popup title',
          type: 'boolean',
          description: '[idVerificationScreen.retryPopupTitle]',
        },
        retryPopupTitle: {
          type: 'string',
        },
        'retryPopupCancelButton:enable': {
          title: '#75 Retry popup cancel button',
          type: 'boolean',
          description: '[idVerificationScreen.retryPopupCancelButton]',
        },
        retryPopupCancelButton: {
          type: 'string',
        },
        'retryPopupRestartButton:enable': {
          title: '#76 Retry popup restart button',
          type: 'boolean',
          description: '[idVerificationScreen.retryPopupRestartButton]',
        },
        retryPopupRestartButton: {
          type: 'string',
        },
        'retryPopupSelfieButton:enable': {
          title: '#77 Retry popup selfie button',
          type: 'boolean',
          description: '[idVerificationScreen.retryPopupSelfieButton]',
        },
        retryPopupSelfieButton: {
          type: 'string',
        },
      },
    },
    specialRequestScreen: {
      type: 'object',
      title: '#15 Special request screen',
      properties: {
        'title:enable': {
          title: '#1 Title',
          type: 'boolean',
          description: '[specialRequestScreen.title]',
        },
        title: {
          type: 'string',
        },
        'header:enable': {
          title: '#2 Header',
          type: 'boolean',
          description: '[specialRequestScreen.header]',
        },
        header: {
          type: 'string',
        },
        'description:enable': {
          title: '#3 Description',
          type: 'boolean',
          description: '[specialRequestScreen.description]',
        },
        description: {
          type: 'string',
        },
        'textInputPlaceholder:enable': {
          title: '#4 Text input placeholder',
          type: 'boolean',
          description: '[specialRequestScreen.textInputPlaceholder]',
        },
        textInputPlaceholder: {
          type: 'string',
        },
      },
    },
    userInformationScreen: {
      type: 'object',
      title: '#16 User information screen',
      properties: {
        'cost:enable': {
          title: '#1 Cost',
          type: 'boolean',
          description: '[userInformationScreen.cost]',
        },
        cost: {
          type: 'string',
        },
        'header:enable': {
          title: '#2 Header',
          type: 'boolean',
          description: '[userInformationScreen.header]',
        },
        header: {
          type: 'string',
        },
        'checkIn:enable': {
          title: '#3 Check-in',
          type: 'boolean',
          description: '[userInformationScreen.checkIn]',
        },
        checkIn: {
          type: 'string',
        },
        'checkOut:enable': {
          title: '#4 Check-out',
          type: 'boolean',
          description: '[userInformationScreen.checkOut]',
        },
        checkOut: {
          type: 'string',
        },
        'roomType:enable': {
          title: '#5 Room type',
          type: 'boolean',
          description: '[userInformationScreen.roomType]',
        },
        roomType: {
          type: 'string',
        },
        'roomNumber:enable': {
          title: '#6 Room number',
          type: 'boolean',
          description: '[userInformationScreen.roomNumber]',
        },
        roomNumber: {
          type: 'string',
        },
        'contactInformation:enable': {
          title: '#7 Contact information',
          type: 'boolean',
          description: '[userInformationScreen.contactInformation]',
        },
        contactInformation: {
          type: 'string',
        },
        'updateSuccessfullyText:enable': {
          title: '#8 Update successfully text',
          type: 'boolean',
          description: '[userInformationScreen.updateSuccessfullyText]',
        },
        updateSuccessfullyText: {
          type: 'string',
        },
        'emailUpdateRequired:enable': {
          title: '#9 Email required message',
          type: 'boolean',
          description: '[userInformationScreen.emailUpdateRequired]',
        },
        emailUpdateRequired: {
          type: 'string',
        },
        'headerDetails:enable': {
          title: '#10 Header details',
          type: 'boolean',
          description: '[userInformationScreen.headerDetails]',
        },
        headerDetails: {
          type: 'string',
        },
        'requireNameErrorText:enable': {
          title: '#11 Required name error',
          type: 'boolean',
          description: '[userInformationScreen.requireNameErrorText]',
        },
        requireNameErrorText: {
          type: 'string',
        },
        'requireEmailErrorText:enable': {
          title: '#12 Required email error',
          type: 'boolean',
          description: '[userInformationScreen.requireEmailErrorText]',
        },
        requireEmailErrorText: {
          type: 'string',
        },
        'invalidEmailErrorText:enable': {
          title: '#13 Invalid email error',
          type: 'boolean',
          description: '[userInformationScreen.invalidEmailErrorText]',
        },
        invalidEmailErrorText: {
          type: 'string',
        },
        'requirePhoneErrorText:enable': {
          title: '#14 Required phone error',
          type: 'boolean',
          description: '[userInformationScreen.requirePhoneErrorText]',
        },
        requirePhoneErrorText: {
          type: 'string',
        },
        'invalidPhoneErrorText:enable': {
          title: '#15 Invalid phone error',
          type: 'boolean',
          description: '[userInformationScreen.invalidPhoneErrorText]',
        },
        invalidPhoneErrorText: {
          type: 'string',
        },
        'addUserHeader:enable': {
          title: '#16 Add user header',
          type: 'boolean',
          description: '[userInformationScreen.addUserHeader]',
        },
        addUserHeader: {
          type: 'string',
        },
        'addUserSubtitle:enable': {
          title: '#17 Add user subtitle',
          type: 'boolean',
          description: '[userInformationScreen.addUserSubtitle]',
        },
        addUserSubtitle: {
          type: 'string',
        },
        'updateRequirementFailed:enable': {
          title: '#18 Update requirement failed error text',
          type: 'boolean',
          description: '[userInformationScreen.updateRequirementFailed]',
        },
        updateRequirementFailed: {
          type: 'string',
        },
        'updateDynamicInfoError:enable': {
          title: '#19 Update dynamic info failed text',
          type: 'boolean',
          description: '[userInformationScreen.updateDynamicInfoError]',
        },
        updateDynamicInfoError: {
          type: 'string',
        },
        'emailOrPhoneNumberRequiredError:enable': {
          title: '#20 Required email or phone number failed text',
          type: 'boolean',
          description: '[userInformationScreen.emailOrPhoneNumberRequiredError]',
        },
        emailOrPhoneNumberRequiredError: {
          type: 'string',
        },
        'errorPopupTitle:enable': {
          title: '#21 Error popup title',
          type: 'boolean',
          description: '[userInformationScreen.errorPopupTitle]',
        },
        errorPopupTitle: {
          type: 'string',
        },
        'errorPopupFallbackMessage:enable': {
          title: '#22 Error popup fallback message',
          type: 'boolean',
          description: '[userInformationScreen.errorPopupFallbackMessage]',
        },
        errorPopupFallbackMessage: {
          type: 'string',
        },
        'errorPopupButtonText:enable': {
          title: '#23 Error popup button text',
          type: 'boolean',
          description: '[userInformationScreen.errorPopupButtonText]',
        },
        errorPopupButtonText: {
          type: 'string',
        },
        'firstNameEmptyError:enable': {
          title: '#24 First name empty error',
          type: 'boolean',
          description: '[userInformationScreen.firstNameEmptyError]',
        },
        firstNameEmptyError: {
          type: 'string',
        },
        'lastNameEmptyError:enable': {
          title: '#25 Last name empty error',
          type: 'boolean',
          description: '[userInformationScreen.lastNameEmptyError]',
        },
        lastNameEmptyError: {
          type: 'string',
        },
        'emailEmptyError:enable': {
          title: '#26 Email empty error',
          type: 'boolean',
          description: '[userInformationScreen.emailEmptyError]',
        },
        emailEmptyError: {
          type: 'string',
        },
        'emailInvalidFormatError:enable': {
          title: '#27 Email invalid format error',
          type: 'boolean',
          description: '[userInformationScreen.emailInvalidFormatError]',
        },
        emailInvalidFormatError: {
          type: 'string',
        },
        'phoneEmptyError:enable': {
          title: '#28 Phone number empty error',
          type: 'boolean',
          description: '[userInformationScreen.phoneEmptyError]',
        },
        phoneEmptyError: {
          type: 'string',
        },
        'phoneInvalidFormatError:enable': {
          title: '#29 Phone number invalid format error',
          type: 'boolean',
          description: '[userInformationScreen.phoneInvalidFormatError]',
        },
        phoneInvalidFormatError: {
          type: 'string',
        },
        editUser: {
          type: 'object',
          title: '#30 Edit User',
          properties: {
            'disableFirstName:enable': {
              title: '#30.1 Disable first name input',
              type: 'boolean',
              description: '[userInformationScreen.editUser.disableFirstName]',
            },
            disableFirstName: {
              type: 'string',
            },
            'disableLastName:enable': {
              title: '#30.2 Disable last name input',
              type: 'boolean',
              description: '[userInformationScreen.editUser.disableLastName]',
            },
            disableLastName: {
              type: 'string',
            },
          },
        },
      },
    },
    nameVerificationScreen: {
      type: 'object',
      title: '#17 Name verification screen',
      properties: {
        'header:enable': {
          title: '#1 Header',
          type: 'boolean',
          description: '[nameVerificationScreen.header]',
        },
        header: {
          type: 'string',
        },
        'userNotFound:enable': {
          title: '#2 User not found',
          type: 'boolean',
          description: '[nameVerificationScreen.userNotFound]',
        },
        userNotFound: {
          type: 'string',
        },
      },
    },
    remoteAssistanceScreen: {
      type: 'object',
      title: '#18 Remote assistance screen',
      properties: {
        'header:enable': {
          title: '#1 Header',
          type: 'boolean',
          description: '[remoteAssistanceScreen.header]',
        },
        header: {
          type: 'string',
        },
        'videoCall:enable': {
          title: '#2 Video call',
          type: 'boolean',
          description: '[remoteAssistanceScreen.videoCall]',
        },
        videoCall: {
          type: 'string',
        },
        'voiceCall:enable': {
          title: '#3 Voice call',
          type: 'boolean',
          description: '[remoteAssistanceScreen.voiceCall]',
        },
        voiceCall: {
          type: 'string',
        },
        'description:enable': {
          title: '#4 Description',
          type: 'boolean',
          description: '[remoteAssistanceScreen.description]',
        },
        description: {
          type: 'string',
        },
        noOneAnsweredModal: {
          type: 'object',
          title: '#5 No one answered modal',
          properties: {
            'title:enable': {
              title: '#5.1 Title',
              type: 'boolean',
              description: '[remoteAssistanceScreen.noOneAnsweredModal.title]',
            },
            title: {
              type: 'string',
            },
            'description:enable': {
              title: '#5.2 Description',
              type: 'boolean',
              description: '[remoteAssistanceScreen.noOneAnsweredModal.description]',
            },
            description: {
              type: 'string',
            },
            'submitButton:enable': {
              title: '#5.3 Submit button',
              type: 'boolean',
              description: '[remoteAssistanceScreen.noOneAnsweredModal.submitButton]',
            },
            submitButton: {
              type: 'string',
            },
          },
        },
      },
    },
    emailVerificationScreen: {
      type: 'object',
      title: '#19 Email verification screen',
      properties: {
        'header:enable': {
          title: '#1 Header',
          type: 'boolean',
          description: '[emailVerificationScreen.header]',
        },
        header: {
          type: 'string',
        },
        'emailNotFound:enable': {
          title: '#2 Email not found',
          type: 'boolean',
          description: '[emailVerificationScreen.emailNotFound]',
        },
        emailNotFound: {
          type: 'string',
        },
      },
    },
    phoneVerificationScreen: {
      type: 'object',
      title: '#20 Phone verification screen',
      properties: {
        'header:enable': {
          title: '#1 Header',
          type: 'boolean',
          description: '[phoneVerificationScreen.header]',
        },
        header: {
          type: 'string',
        },
        'phoneNotFound:enable': {
          title: '#2 Phone not found',
          type: 'boolean',
          description: '[phoneVerificationScreen.phoneNotFound]',
        },
        phoneNotFound: {
          type: 'string',
        },
      },
    },
    reservationStatusScreen: {
      type: 'object',
      title: '#21 Reservation status screen',
      properties: {
        'helpButton:enable': {
          title: '#1 Help button',
          type: 'boolean',
          description: '[reservationStatusScreen.helpButton]',
        },
        helpButton: {
          type: 'string',
        },
        'noRoomTitle:enable': {
          title: '#2 No room title',
          type: 'boolean',
          description: '[reservationStatusScreen.noRoomTitle]',
        },
        noRoomTitle: {
          type: 'string',
        },
        'noRoomHeader:enable': {
          title: '#3 No room header',
          type: 'boolean',
          description: '[reservationStatusScreen.noRoomHeader]',
        },
        noRoomHeader: {
          type: 'string',
        },
        'noRoomDescription:enable': {
          title: '#4 No room description',
          type: 'boolean',
          description: '[reservationStatusScreen.noRoomDescription]',
        },
        noRoomDescription: {
          type: 'string',
        },
        'noReservationTitle:enable': {
          title: '#5 No reservation title',
          type: 'boolean',
          description: '[reservationStatusScreen.noReservationTitle]',
        },
        noReservationTitle: {
          type: 'string',
        },
        'noReservationHeader:enable': {
          title: '#6 No reservation header',
          type: 'boolean',
          description: '[reservationStatusScreen.noReservationHeader]',
        },
        noReservationHeader: {
          type: 'string',
        },
        'todayReservationTitle:enable': {
          title: '#7 Today reservation title',
          type: 'boolean',
          description: '[reservationStatusScreen.todayReservationTitle]',
        },
        todayReservationTitle: {
          type: 'string',
        },
        'futureReservationTitle:enable': {
          title: '#8 Future reservation title',
          type: 'boolean',
          description: '[reservationStatusScreen.futureReservationTitle]',
        },
        futureReservationTitle: {
          type: 'string',
        },
        'todayReservationHeader:enable': {
          title: '#9 Today reservation header',
          type: 'boolean',
          description: '[reservationStatusScreen.todayReservationHeader]',
        },
        todayReservationHeader: {
          type: 'string',
        },
        'futureReservationHeader:enable': {
          title: '#10 Future reservation header',
          type: 'boolean',
          description: '[reservationStatusScreen.futureReservationHeader]',
        },
        futureReservationHeader: {
          type: 'string',
        },
        'invalidReservationHeader:enable': {
          title: '#11 Invalid reservation header',
          type: 'boolean',
          description: '[reservationStatusScreen.invalidReservationHeader]',
        },
        invalidReservationHeader: {
          type: 'string',
        },
        'invalidReservationTitle:enable': {
          title: '#12 Invalid reservation title',
          type: 'boolean',
          description: '[reservationStatusScreen.invalidReservationTitle]',
        },
        invalidReservationTitle: {
          type: 'string',
        },
        'invalidReservationDescription:enable': {
          title: '#13 Invalid reservation description',
          type: 'boolean',
          description: '[reservationStatusScreen.invalidReservationDescription]',
        },
        invalidReservationDescription: {
          type: 'string',
        },
        'noReservationDescription:enable': {
          title: '#14 No reservation description',
          type: 'boolean',
          description: '[reservationStatusScreen.noReservationDescription]',
        },
        noReservationDescription: {
          type: 'string',
        },
        'todayReservationDescription:enable': {
          title: '#15 Today reservation description',
          type: 'boolean',
          description: '[reservationStatusScreen.todayReservationDescription]',
        },
        todayReservationDescription: {
          type: 'string',
        },
        'futureReservationDescription:enable': {
          title: '#16 Future reservation description',
          type: 'boolean',
          description: '[reservationStatusScreen.futureReservationDescription]',
        },
        futureReservationDescription: {
          type: 'string',
        },
        'qrCodeTitle:enable': {
          title: '#17 QR code title',
          type: 'boolean',
          description: '[reservationStatusScreen.qrCodeTitle]',
        },
        qrCodeTitle: {
          type: 'string',
        },
        'roomIsNotReadyHeader:enable': {
          title: '#18 Room is not ready header',
          type: 'boolean',
          description: '[reservationStatusScreen.roomIsNotReadyHeader]',
        },
        roomIsNotReadyHeader: {
          type: 'string',
        },
        'roomIsNotReadyTitle:enable': {
          title: '#19 Room is not ready title',
          type: 'boolean',
          description: '[reservationStatusScreen.roomIsNotReadyTitle]',
        },
        roomIsNotReadyTitle: {
          type: 'string',
        },
        'roomIsNotReadyDescription:enable': {
          title: '#20 Room is not ready description',
          type: 'boolean',
          description: '[reservationStatusScreen.roomIsNotReadyDescription]',
        },
        roomIsNotReadyDescription: {
          type: 'string',
        },
        'roomNotAssignedHeader:enable': {
          title: '#21 Room is not assigned header',
          type: 'boolean',
          description: '[reservationStatusScreen.roomNotAssignedHeader]',
        },
        roomNotAssignedHeader: {
          type: 'string',
        },
        'roomNotAssignedTitle:enable': {
          title: '#22 Room is not assigned title',
          type: 'boolean',
          description: '[reservationStatusScreen.roomNotAssignedTitle]',
        },
        roomNotAssignedTitle: {
          type: 'string',
        },
        'roomNotAssignedDescription:enable': {
          title: '#23 Room is not assigned description',
          type: 'boolean',
          description: '[reservationStatusScreen.roomNotAssignedDescription]',
        },
        roomNotAssignedDescription: {
          type: 'string',
        },
        'roomAheadOfTimeHeader:enable': {
          title: '#24 Room ahead of time header',
          type: 'boolean',
          description: '[reservationStatusScreen.roomAheadOfTimeHeader]',
        },
        roomAheadOfTimeHeader: {
          type: 'string',
        },
        'roomAheadOfTimeTitle:enable': {
          title: '#25 Room ahead of time title',
          type: 'boolean',
          description: '[reservationStatusScreen.roomAheadOfTimeTitle]',
        },
        roomAheadOfTimeTitle: {
          type: 'string',
        },
        'roomAheadOfTimeDescription:enable': {
          title: '#26 Room ahead of time description',
          type: 'boolean',
          description: '[reservationStatusScreen.roomAheadOfTimeDescription]',
        },
        roomAheadOfTimeDescription: {
          type: 'string',
        },
      },
    },
    selectReservationScreen: {
      type: 'object',
      title: '#22 Select reservation screen',
      properties: {
        'header:enable': {
          title: '#1 Header',
          type: 'boolean',
          description: '[selectReservationScreen.header]',
        },
        header: {
          type: 'string',
        },
        'checkin:enable': {
          title: '#2 Check-in',
          type: 'boolean',
          description: '[selectReservationScreen.checkin]',
        },
        checkin: {
          type: 'string',
        },
        'checkout:enable': {
          title: '#3 Check-out',
          type: 'boolean',
          description: '[selectReservationScreen.checkout]',
        },
        checkout: {
          type: 'string',
        },
        'okButton:enable': {
          title: '#4 Ok button',
          type: 'boolean',
          description: '[selectReservationScreen.okButton]',
        },
        okButton: {
          type: 'string',
        },
        'cardRoomTitle:enable': {
          title: '#5 Card room title',
          type: 'boolean',
          description: '[selectReservationScreen.cardRoomTitle]',
        },
        cardRoomTitle: {
          type: 'string',
        },
        'checkoutHeader:enable': {
          title: '#6 Check-out header',
          type: 'boolean',
          description: '[selectReservationScreen.checkoutHeader]',
        },
        checkoutHeader: {
          type: 'string',
        },
        'noRoomAssigned:enable': {
          title: '#7 No room assigned',
          type: 'boolean',
          description: '[selectReservationScreen.noRoomAssigned]',
        },
        noRoomAssigned: {
          type: 'string',
        },
        'failedToCheckin:enable': {
          title: '#8 Failed to check-in',
          type: 'boolean',
          description: '[selectReservationScreen.failedToCheckin]',
        },
        failedToCheckin: {
          type: 'string',
        },
        'cardReservationTitle:enable': {
          title: '#9 Card reservation title',
          type: 'boolean',
          description: '[selectReservationScreen.cardReservationTitle]',
        },
        cardReservationTitle: {
          type: 'string',
        },
        'contactRemoteAssistanceButton:enable': {
          title: '#10 Contact remote assistance button',
          type: 'boolean',
          description: '[selectReservationScreen.contactRemoteAssistanceButton]',
        },
        contactRemoteAssistanceButton: {
          type: 'string',
        },
        remoteAssistancePopup: {
          type: 'object',
          title: '#11 Remote assistance popup',
          properties: {
            'title:enable': {
              title: '#11.1 Popup title',
              type: 'boolean',
              description: '[selectReservationScreen.remoteAssistancePopup.title]',
            },
            title: {
              type: 'string',
            },
            'description:enable': {
              title: '#11.2 Popup description',
              type: 'boolean',
              description: '[selectReservationScreen.remoteAssistancePopup.description]',
            },
            description: {
              type: 'string',
            },
            'okButton:enable': {
              title: '#11.3 Popup ok button',
              type: 'boolean',
              description: '[selectReservationScreen.remoteAssistancePopup.okButton]',
            },
            okButton: {
              type: 'string',
            },
            'cancelButton:enable': {
              title: '#11.4 Popup cancel button',
              type: 'boolean',
              description: '[selectReservationScreen.remoteAssistancePopup.cancelButton]',
            },
            cancelButton: {
              type: 'string',
            },
          },
        },
        'checkInEmptyReservation:enable': {
          title: '#12 Check-in empty reservation text',
          type: 'boolean',
          description: '[selectReservationScreen.checkInEmptyReservation]',
        },
        checkInEmptyReservation: {
          type: 'string',
        },
        'checkOutEmptyReservation:enable': {
          title: '#13 Check-out empty reservation text',
          type: 'boolean',
          description: '[selectReservationScreen.checkOutEmptyReservation]',
        },
        checkOutEmptyReservation: {
          type: 'string',
        },
        'folioEmptyReservation:enable': {
          title: '#14 Folio empty reservation text',
          type: 'boolean',
          description: '[selectReservationScreen.folioEmptyReservation]',
        },
        folioEmptyReservation: {
          type: 'string',
        },
        'printAnotherKeyEmptyReservation:enable': {
          title: '#15 Print another key empty reservation text',
          type: 'boolean',
          description: '[selectReservationScreen.printAnotherKeyEmptyReservation]',
        },
        printAnotherKeyEmptyReservation: {
          type: 'string',
        },
      },
    },
    validateEmailCodeScreen: {
      type: 'object',
      title: '#23 Validate email code screen',
      properties: {
        'header:enable': {
          title: '#1 Header',
          type: 'boolean',
          description: '[validateEmailCodeScreen.header]',
        },
        header: {
          type: 'string',
        },
      },
    },
    validatePhoneCodeScreen: {
      type: 'object',
      title: '#24 Validate phone code screen',
      properties: {
        'header:enable': {
          title: '#1 Header',
          type: 'boolean',
          description: '[validatePhoneCodeScreen.header]',
        },
        header: {
          type: 'string',
        },
      },
    },
    confirmationNumberScreen: {
      type: 'object',
      title: '#25 Confirmation number screen',
      properties: {
        'header:enable': {
          title: '#1 Header',
          type: 'boolean',
          description: '[confirmationNumberScreen.header]',
        },
        header: {
          type: 'string',
        },
        'confirmationNumberNotFound:enable': {
          title: '#2 Confirmation number not found',
          type: 'boolean',
          description: '[confirmationNumberScreen.confirmationNumberNotFound]',
        },
        confirmationNumberNotFound: {
          type: 'string',
        },
      },
    },
    remoteAssistanceComponent: {
      type: 'object',
      title: '#26 Remote assistance component',
      properties: {
        'title:enable': {
          title: '#1 Title',
          type: 'boolean',
          description: '[remoteAssistanceComponent.title]',
        },
        title: {
          type: 'string',
        },
        'subtitle:enable': {
          title: '#2 Subtitle',
          type: 'boolean',
          description: '[remoteAssistanceComponent.subtitle]',
        },
        subtitle: {
          type: 'string',
        },
      },
    },
    paymentAuthorizationScreen: {
      type: 'object',
      title: '#27 Payment authorization screen',
      properties: {
        'header:enable': {
          title: '#1 Header',
          type: 'boolean',
          description: '[paymentAuthorizationScreen.header]',
        },
        header: {
          type: 'string',
        },
        'description:enable': {
          title: '#2 Description',
          type: 'boolean',
          description: '[paymentAuthorizationScreen.description]',
        },
        description: {
          type: 'string',
        },
        'swipeCardTitle:enable': {
          title: '#3 Swipe card title',
          type: 'boolean',
          description: '[paymentAuthorizationScreen.swipeCardTitle]',
        },
        swipeCardTitle: {
          type: 'string',
        },
        'confirmAuthorizedAmountTitle:enable': {
          title: '#4 Confirm authorized amount title',
          type: 'boolean',
          description: '[paymentAuthorizationScreen.confirmAuthorizedAmountTitle]',
        },
        confirmAuthorizedAmountTitle: {
          type: 'string',
        },
        'issueConnectingTitle:enable': {
          title: '#5 Issue connecting title',
          type: 'boolean',
          description: '[paymentAuthorizationScreen.issueConnectingTitle]',
        },
        issueConnectingTitle: {
          type: 'string',
        },
        'issueConnectingDescriptionCheckIn:enable': {
          title: '#6 Issue connecting description on check-in flow',
          type: 'boolean',
          description: '[paymentAuthorizationScreen.issueConnectingDescriptionCheckIn]',
        },
        issueConnectingDescriptionCheckIn: {
          type: 'string',
        },
        'issueConnectingDescriptionCheckOut:enable': {
          title: '#7 Issue connecting description on check-out flow',
          type: 'boolean',
          description: '[paymentAuthorizationScreen.issueConnectingDescriptionCheckOut]',
        },
        issueConnectingDescriptionCheckOut: {
          type: 'string',
        },
        'tokenizeConcardisError:enable': {
          title: '#8 Tokenize Concardis error',
          type: 'boolean',
          description: '[paymentAuthorizationScreen.tokenizeConcardisError]',
        },
        tokenizeConcardisError: {
          type: 'string',
        },
      },
    },
    verifyingRequirementsScreen: {
      type: 'object',
      title: '#28 Verifying requirements screen',
      properties: {
        'contactAgent:enable': {
          title: '#1 Contact agent',
          type: 'boolean',
          description: '[verifyingRequirementsScreen.contactAgent]',
        },
        contactAgent: {
          type: 'string',
        },
        'failedToVerify:enable': {
          title: '#2 Failed to verify',
          type: 'boolean',
          description: '[verifyingRequirementsScreen.failedToVerify]',
        },
        failedToVerify: {
          type: 'string',
        },
        'uploadingDocument:enable': {
          title: '#3 Uploading document',
          type: 'boolean',
          description: '[verifyingRequirementsScreen.uploadingDocument]',
        },
        uploadingDocument: {
          type: 'string',
        },
        'checkRequirementTitle:enable': {
          title: '#4 Check requirement title',
          type: 'boolean',
          description: '[verifyingRequirementsScreen.checkRequirementTitle]',
        },
        checkRequirementTitle: {
          type: 'string',
        },
        'checkRequirementDescription:enable': {
          title: '#5 Check requirement description',
          type: 'boolean',
          description: '[verifyingRequirementsScreen.checkRequirementDescription]',
        },
        checkRequirementDescription: {
          type: 'string',
        },
        'idVerificationMaxRetriesErrorTitle:enable': {
          title: '#6 ID Verification max retries error title',
          type: 'boolean',
          description: '[verifyingRequirementsScreen.idVerificationMaxRetriesErrorTitle]',
        },
        idVerificationMaxRetriesErrorTitle: {
          type: 'string',
        },
        'idVerificationMaxRetriesErrorMessage:enable': {
          title: '#7 ID Verification max retries error message',
          type: 'boolean',
          description: '[verifyingRequirementsScreen.idVerificationMaxRetriesErrorMessage]',
        },
        idVerificationMaxRetriesErrorMessage: {
          type: 'string',
        },
        'idVerificationMaxRetriesErrorButton:enable': {
          title: '#8 ID Verification max retries error button text',
          type: 'boolean',
          description: '[verifyingRequirementsScreen.idVerificationMaxRetriesErrorButton]',
        },
        idVerificationMaxRetriesErrorButton: {
          type: 'string',
        },
      },
    },
    waitingIDVerificationScreen: {
      type: 'object',
      title: '#29 Waiting ID verification screen',
      properties: {
        'header:enable': {
          title: '#1 Header',
          type: 'boolean',
          description: '[waitingIDVerificationScreen.header]',
        },
        header: {
          type: 'string',
        },
        'description:enable': {
          title: '#2 Description',
          type: 'boolean',
          description: '[waitingIDVerificationScreen.description]',
        },
        description: {
          type: 'string',
        },
        'userNotFound:enable': {
          title: '#3 User not found',
          type: 'boolean',
          description: '[waitingIDVerificationScreen.userNotFound]',
        },
        userNotFound: {
          type: 'string',
        },
        'uploadDocument:enable': {
          title: '#4 Upload document',
          type: 'boolean',
          description: '[waitingIDVerificationScreen.uploadDocument]',
        },
        uploadDocument: {
          type: 'string',
        },
        'dontHaveAnyStays:enable': {
          title: '#5 Dont have any stays',
          type: 'boolean',
          description: '[waitingIDVerificationScreen.dontHaveAnyStays]',
        },
        dontHaveAnyStays: {
          type: 'string',
        },
        'idVerificationFailed:enable': {
          title: '#6 ID verification failed',
          type: 'boolean',
          description: '[waitingIDVerificationScreen.idVerificationFailed]',
        },
        idVerificationFailed: {
          type: 'string',
        },
        'idVerificationFailedWithStatus:enable': {
          title: '#7 ID verification failed with status',
          type: 'boolean',
          description: '[waitingIDVerificationScreen.idVerificationFailedWithStatus]',
        },
        idVerificationFailedWithStatus: {
          type: 'string',
        },
        'noAvailableCheckOutReservation:enable': {
          title: '#8 No available check-out reservation',
          type: 'boolean',
          description: '[waitingIDVerificationScreen.noAvailableCheckOutReservation]',
        },
        noAvailableCheckOutReservation: {
          type: 'string',
        },
        'idVerificationRejectedReason:enable': {
          title: '#9 ID verification rejected reason title',
          type: 'boolean',
          description: '[waitingIDVerificationScreen.idVerificationRejectedReason]',
        },
        idVerificationRejectedReason: {
          type: 'string',
        },
      },
    },
    packageScreen: {
      type: 'object',
      title: '#30 Packages screen',
      properties: {
        'errorUpdate:enable': {
          type: 'boolean',
          title: '#1 Error update',
          description:
            'Text of error message when failed to add package [packageScreen.errorUpdate]',
        },
        errorUpdate: {
          type: 'string',
        },
        'successfulAdd:enable': {
          type: 'boolean',
          title: '#2 Successful add',
          description: 'Successfully message [packageScreen.successfulAdd]',
        },
        successfulAdd: {
          type: 'string',
        },
        'closeCart:enable': {
          type: 'boolean',
          title: '#3 Close cart',
          description: 'Success message dismiss button [packageScreen.closeCart]',
        },
        closeCart: {
          type: 'string',
        },
        'emptyPrebook:enable': {
          type: 'boolean',
          title: '#4 Empty prebook',
          description: 'Prebook header when have 0 prebooked packages [packageScreen.emptyPrebook]',
        },
        emptyPrebook: {
          type: 'string',
        },
        'singlePrebook:enable': {
          type: 'boolean',
          title: '#5 Single prebook',
          description:
            'Prebook header when have single prebooked packages [packageScreen.singlePrebook]',
        },
        singlePrebook: {
          type: 'string',
        },
        'submit:enable': {
          type: 'boolean',
          title: '#6 Submit',
          description: 'Add to reservation text [packageScreen.submit]',
        },
        submit: {
          type: 'string',
        },
        'viewCart:enable': {
          type: 'boolean',
          title: '#7 View cart',
          description: 'Add to reservation text [packageScreen.viewCart]',
        },
        viewCart: {
          type: 'string',
        },
        'total:enable': {
          type: 'boolean',
          title: '#8 Total',
          description: 'Total text [packageScreen.total]',
        },
        total: {
          type: 'string',
        },
        'cart:enable': {
          type: 'boolean',
          title: '#9 Cart',
          description: 'Cart Header text [packageScreen.cart]',
        },
        cart: {
          type: 'string',
        },
        'addModal:enable': {
          type: 'boolean',
          title: '#10 Add modal',
          description: 'Add to cart text [packageScreen.addModal]',
        },
        addModal: {
          type: 'string',
        },
        'booked:enable': {
          type: 'boolean',
          title: '#11 Booked',
          description: 'Prebooked text [packageScreen.booked]',
        },
        booked: {
          type: 'string',
        },
        'add:enable': {
          type: 'boolean',
          title: '#12 Add',
          description: 'Add button to open modal text [packageScreen.enable]',
        },
        add: {
          type: 'string',
        },
        'packages:enable': {
          type: 'boolean',
          title: '#13 Packages',
          description: 'Packages header text [packageScreen.packages]',
        },
        packages: {
          type: 'string',
        },
        'seePackageText:enable': {
          type: 'boolean',
          title: '#14 See package text',
          description: 'See my packages button [packageScreen.seePackageText]',
        },
        seePackageText: {
          type: 'string',
        },
        'subtitle:enable': {
          type: 'boolean',
          title: '#15 Subtitle',
          description: 'Subtitle of pacakges screen [packageScreen.subtitle]',
        },
        subtitle: {
          type: 'string',
        },
        'prebookHeader:enable': {
          type: 'boolean',
          title: '#16 Pre book header',
          description:
            'Use {n} to refer to number it will be replace by prebook number [packageScreen.prebookHeader]',
        },
        prebookHeader: {
          type: 'string',
        },
        'addMoreCartLabel:enable': {
          type: 'boolean',
          title: '#17 Add more cart label',
          description: 'Add more cart label [packageScreen.addMoreCartLabel]',
        },
        addMoreCartLabel: {
          type: 'string',
        },
        'addCartLabel:enable': {
          type: 'boolean',
          title: '#18 Add cart label',
          description: 'Add cart label [packageScreen.addCartLabel]',
        },
        addCartLabel: {
          type: 'string',
        },
        'addedMessage:enable': {
          type: 'boolean',
          title: '#19 Added message',
          description: 'Package added message [packageScreen.addedMessage]',
        },
        addedMessage: {
          type: 'string',
        },
        'close:enable': {
          type: 'boolean',
          title: '#20 Close',
          description: 'Close text[packageScreen.close]',
        },
        close: {
          type: 'string',
        },
        'include:enable': {
          type: 'boolean',
          title: '#21 Include',
          description: 'Include text [packageScreen.include]',
        },
        include: {
          type: 'string',
        },
        'loadingTitle:enable': {
          type: 'boolean',
          title: '#22 Loading title',
          description: 'Loading title [packageScreen.loadingTitle]',
        },
        loadingTitle: {
          type: 'string',
        },
        'loadingSubtitle:enable': {
          type: 'boolean',
          title: '#23 Loading subtitle',
          description: 'Loading subtitle [packageScreen.loadingSubtitle]',
        },
        loadingSubtitle: {
          type: 'string',
        },
        'title:enable': {
          type: 'boolean',
          title: '#24 Title',
          description: 'Title [packageScreen.title]',
        },
        title: {
          type: 'string',
        },
        'category:enable': {
          type: 'boolean',
          title: '#25 Category',
          description: 'Category [packageScreen.category]',
        },
        category: {
          type: 'string',
        },
        'cannotRemovePrebookedPackage:enable': {
          type: 'boolean',
          title: '#26 Cannot remove prebooked package',
          description:
            'Cannot remove prebooked package error text [packageScreen.cannotRemovePrebookedPackage]',
        },
        cannotRemovePrebookedPackage: {
          type: 'string',
        },
        'reachMaximumAvailableQuantity:enable': {
          type: 'boolean',
          title: '#27 Reach maximum available quantity',
          description:
            'Reach maximum available quantity of package error text [packageScreen.reachMaximumAvailableQuantity]',
        },
        reachMaximumAvailableQuantity: {
          type: 'string',
        },
        'prebookedAmountText:enable': {
          type: 'boolean',
          title: '#28 Prebooked amount text',
          description: 'Prebooked amount text [packageScreen.prebookedAmountText]',
        },
        prebookedAmountText: {
          type: 'string',
        },
      },
    },
    checkInFailScreen: {
      type: 'object',
      title: '#31 Check-in fail screen',
      properties: {
        'header:enable': {
          type: 'boolean',
          title: '#1 Header main title',
          description: 'Text of title in the header [checkInFailScreen.header]',
        },
        header: {
          type: 'string',
        },
        'title:enable': {
          type: 'boolean',
          title: '#2 Card instruction title',
          description: 'Card instruction title [checkInFailScreen.title]',
        },
        title: {
          type: 'string',
        },
        'description:enable': {
          type: 'boolean',
          title: '#3 Card instruction description',
          description: 'Card instruction description [checkInFailScreen.description]',
        },
        description: {
          type: 'string',
        },
        'helpButton:enable': {
          type: 'boolean',
          title: '#4 Help button text',
          description: 'Remote assistence button text [checkInFailScreen.helpButton]',
        },
        helpButton: {
          type: 'string',
        },
        'endSessionButton:enable': {
          type: 'boolean',
          title: '#5 End session button',
          description: '[checkInFailScreen.endSessionButton]',
        },
        endSessionButton: {
          type: 'string',
        },
        'retryPaymentButton:enable': {
          type: 'boolean',
          title: '#6 Retry payment button',
          description: '[checkInFailScreen.retryPaymentButton]',
        },
        retryPaymentButton: {
          type: 'string',
        },
      },
    },
    conciergeScreen: {
      type: 'object',
      title: '#32 Concierge screen',
      properties: {
        'title:enable': {
          type: 'boolean',
          title: '#1 Title',
          description: 'Title on the left side [conciergeScreen.title]',
        },
        title: {
          type: 'string',
        },
        'description:enable': {
          type: 'boolean',
          title: '#2 Description',
          description: 'Description on the left side [conciergeScreen.description]',
        },
        description: {
          type: 'string',
        },
        'backText:enable': {
          type: 'boolean',
          title: '#3 Back Icon Text',
          description: 'Back icon text after select category [conciergeScreen.backText]',
        },
        backText: {
          type: 'string',
        },
        'popupCloseButton:enable': {
          type: 'boolean',
          title: '#4 Popup close button',
          description: 'Popup close button text [conciergeScreen.popupCloseButton]',
        },
        popupCloseButton: {
          type: 'string',
        },
        'information:enable': {
          type: 'boolean',
          title: '#5 Information text',
          description: 'Information text [conciergeScreen.information]',
        },
        information: {
          type: 'string',
        },
        'moreInfoButton:enable': {
          type: 'boolean',
          title: '#6 More info button',
          description: 'More info button text [conciergeScreen.moreInfoButton]',
        },
        moreInfoButton: {
          type: 'string',
        },
      },
    },
    mrzIdVerificationScreen: {
      type: 'object',
      title: '#33 MRZ ID Verification screen',
      properties: {
        'instructionText:enable': {
          type: 'boolean',
          title: '#1 Instruction text',
          description:
            'Scanner screen primary instruction text [mrzIdVerificationScreen.instructionText]',
        },
        instructionText: {
          type: 'string',
        },
        'instructionText2:enable': {
          type: 'boolean',
          title: '#2 Instruction text 2',
          description:
            'Scanner screen secondary instruction text [mrzIdVerificationScreen.instructionText2]',
        },
        instructionText2: {
          type: 'string',
        },
        'instructionImageTitle:enable': {
          type: 'boolean',
          title: '#3 Instruction image title',
          description:
            'Scanner screen primary instruction image title [mrzIdVerificationScreen.instructionImageTitle]',
        },
        instructionImageTitle: {
          type: 'string',
        },
        'instructionImageTitle2:enable': {
          type: 'boolean',
          title: '#4 Instruction image title 2',
          description:
            'Scanner screen secondary instruction image title [mrzIdVerificationScreen.instructionImageTitle2]',
        },
        instructionImageTitle2: {
          type: 'string',
        },
        'backLabel:enable': {
          type: 'boolean',
          title: '#5 Back label',
          description: 'Back icon label [mrzIdVerificationScreen.backLabel]',
        },
        backLabel: {
          type: 'string',
        },
        'cameraText:enable': {
          type: 'boolean',
          title: '#6 Camera text',
          description: 'Camera label [mrzIdVerificationScreen.cameraText]',
        },
        cameraText: {
          type: 'string',
        },
        'scanInstructionText:enable': {
          type: 'boolean',
          title: '#7 Scan instruction text',
          description:
            'Scan instruction text on the bottom right of the scan screen [mrzIdVerificationScreen.scanInstructionText]',
        },
        scanInstructionText: {
          type: 'string',
        },
        'headerText:enable': {
          type: 'boolean',
          title: '#8 Header text',
          description: 'Scan screen title [mrzIdVerificationScreen.headerText]',
        },
        headerText: {
          type: 'string',
        },
        'initScreenHeader:enable': {
          type: 'boolean',
          title: '#9 Init screen header',
          description: 'Initial screen header text [mrzIdVerificationScreen.initScreenHeader]',
        },
        initScreenHeader: {
          type: 'string',
        },
        'initScreenInstructionText:enable': {
          type: 'boolean',
          title: '#10 Init screen instruction text',
          description:
            'Init screen instruction text [mrzIdVerificationScreen.initScreenInstructionText]',
        },
        initScreenInstructionText: {
          type: 'string',
        },
        'instruction_ID:enable': {
          title: '#11 Document type instruction text',
          type: 'boolean',
          description:
            '[mrzIdVerificationScreen.instruction_ID] - MRZ ID Verification main screen document type instruction text',
        },
        instruction_ID: {
          type: 'string',
        },
        'instruction_AGE:enable': {
          title: '#12 Age verification instruction text',
          type: 'boolean',
          description:
            '[mrzIdVerificationScreen.instruction_AGE] - MRZ ID Verification main screen age verification instruction text',
        },
        instruction_AGE: {
          type: 'string',
        },
        'instruction_NAME:enable': {
          title: '#13 Name match instruction text',
          type: 'boolean',
          description:
            '[mrzIdVerificationScreen.instruction_NAME] - MRZ ID Verification main screen name match instruction text',
        },
        instruction_NAME: {
          type: 'string',
        },
        'initScreenDescription:enable': {
          title: '#14 Init screen description',
          type: 'boolean',
          description:
            'Init screen description text [mrzIdVerificationScreen.initScreenDescription]',
        },
        initScreenDescription: {
          type: 'string',
        },
        'popupCloseButton:enable': {
          title: '#15 Popup close button',
          type: 'boolean',
          description:
            'Init screen popup close button text [mrzIdVerificationScreen.popupCloseButton]',
        },
        popupCloseButton: {
          type: 'string',
        },
        'popupDefaultErrorHeaderText:enable': {
          title: '#16 Popup default error header text',
          type: 'boolean',
          description:
            'Init screen popup default error header text [mrzIdVerificationScreen.popupDefaultErrorHeaderText]',
        },
        popupDefaultErrorHeaderText: {
          type: 'string',
        },
        'backAlert:enable': {
          title: '#17 Back alert text',
          type: 'boolean',
          description: 'Scan screen on press back popup text [mrzIdVerificationScreen.backAlert]',
        },
        backAlert: {
          type: 'string',
        },
        'remoteAssistanceAlert:enable': {
          title: '#18 Remote assistance alert text',
          type: 'boolean',
          description:
            'Scan screen on press remote assistance popup text [mrzIdVerificationScreen.backAlert]',
        },
        remoteAssistanceAlert: {
          type: 'string',
        },
        'homeAlert:enable': {
          title: '#19 Home alert text',
          type: 'boolean',
          description: 'Scan screen on press home popup text [mrzIdVerificationScreen.homeAlert]',
        },
        homeAlert: {
          type: 'string',
        },
        'backAlertPositiveButton:enable': {
          title: '#20 Back alert positive button',
          type: 'boolean',
          description:
            'Scan screen on press back popup positive button [mrzIdVerificationScreen.backAlertPositiveButton]',
        },
        backAlertPositiveButton: {
          type: 'string',
        },
        'remoteAssistanceAlertPositiveButton:enable': {
          title: '#21 Remote assistance alert positive button',
          type: 'boolean',
          description:
            'Scan screen on press remote assistance popup positive button [mrzIdVerificationScreen.remoteAssistanceAlertPositiveButton]',
        },
        remoteAssistanceAlertPositiveButton: {
          type: 'string',
        },
        'homeAlertPositiveButton:enable': {
          title: '#22 Home alert positive button',
          type: 'boolean',
          description:
            'Scan screen on press home popup positive button [mrzIdVerificationScreen.homeAlertPositiveButton]',
        },
        homeAlertPositiveButton: {
          type: 'string',
        },
        'backAlertNegativeButton:enable': {
          title: '#23 Back alert negative button',
          type: 'boolean',
          description:
            'Scan screen on press back popup negative button [mrzIdVerificationScreen.backAlertNegativeButton]',
        },
        backAlertNegativeButton: {
          type: 'string',
        },
        'remoteAssistanceAlertNegativeButton:enable': {
          title: '#24 Remote assistance alert negative button',
          type: 'boolean',
          description:
            'Scan screen on press remote assistance popup negative button [mrzIdVerificationScreen.remoteAssistanceAlertNegativeButton]',
        },
        remoteAssistanceAlertNegativeButton: {
          type: 'string',
        },
        'homeAlertNegativeButton:enable': {
          title: '#25 Home alert negative button',
          type: 'boolean',
          description:
            'Scan screen on press home popup negative button [mrzIdVerificationScreen.homeAlertNegativeButton]',
        },
        homeAlertNegativeButton: {
          type: 'string',
        },
        'instruction:enable': {
          title: '#26 Instruction',
          type: 'boolean',
          description: 'How to position the document on the scanner',
        },
        instruction: {
          type: 'string',
        },
        'feedback:enable': {
          title: '#27 Feedback',
          type: 'boolean',
          description: 'Wait for the document scanning process',
        },
        feedback: {
          type: 'string',
        },
        'helpButtonTitle:enable': {
          title: '#28 Help button title',
          type: 'boolean',
          description: 'Help button title',
        },
        helpButtonTitle: {
          type: 'string',
        },
        helpModal: {
          title: '#29 Help modal',
          type: 'object',
          properties: {
            'title:enable': {
              title: '#29.1 Title',
              type: 'boolean',
              description: 'Modal title',
            },
            title: {
              type: 'string',
            },
            documentNotFound: {
              title: '#29.2 Document not found guide',
              type: 'object',
              properties: {
                'title:enable': {
                  title: '#29.2.1 Title',
                  type: 'boolean',
                  description: 'Section title',
                },
                title: {
                  type: 'string',
                },
                'barcodeLocation:enable': {
                  title: '#29.2.2 Barcode location',
                  type: 'boolean',
                  description: 'Barcode location',
                },
                barcodeLocation: {
                  type: 'string',
                },
                'guide:enable': {
                  title: '#29.2.3 Guide',
                  type: 'boolean',
                  description: 'Genera guide on barcode scanning',
                },
                guide: {
                  type: 'string',
                },
              },
            },
            notScanning: {
              title: '#29.3 Not scanning guide',
              type: 'object',
              properties: {
                'title:enable': {
                  title: '#29.3.1 Title',
                  type: 'boolean',
                  description: 'Section title',
                },
                title: {
                  type: 'string',
                },
                'guide:enable': {
                  title: '#29.3.2 Guide',
                  type: 'boolean',
                  description: 'Genera guide on fail to scan',
                },
                guide: {
                  type: 'string',
                },
              },
            },
            legalAndPolice: {
              title: '#29.4 Legal & Police section',
              type: 'object',
              properties: {
                'title:enable': {
                  title: '#29.4.1 Section title',
                  type: 'boolean',
                  description: 'Section title',
                },
                title: {
                  type: 'string',
                },
                'guide:enable': {
                  title: '#29.4.2 Guide',
                  type: 'boolean',
                  description: 'Legal & Police notice',
                },
                guide: {
                  type: 'string',
                },
              },
            },
          },
        },
      },
    },
    CollectUserInfoScreen: {
      type: 'object',
      title: '#34 Additional Information Screen (Dynamic Screen)',
      properties: {
        'title:enable': {
          type: 'boolean',
          title: '#1 title text',
          description: 'title text [CollectUserInfoScreen.title]',
        },
        title: {
          type: 'string',
        },
        'subtitle:enable': {
          type: 'boolean',
          title: '#2 subtitle text',
          description: 'subtitle text [CollectUserInfoScreen.title]',
        },
        subtitle: {
          type: 'string',
        },
      },
    },
    nameAndDepartureDateVerificationScreen: {
      type: 'object',
      title: '#35 Name and departure date verification screen',
      properties: {
        'header:enable': {
          type: 'boolean',
          title: '#1 Header text',
          description: 'header text [nameAndDepartureDateVerificationScreen.header]',
        },
        header: {
          type: 'string',
        },
        'userNotFound:enable': {
          type: 'boolean',
          title: '#2 User not found text',
          description: '[nameAndDepartureDateVerificationScreen.userNotFound]',
        },
        userNotFound: {
          type: 'string',
        },
      },
    },
    confirmationCodeVerificationScreen: {
      type: 'object',
      title: '#36 Confirmation code verification screen',
      properties: {
        'header:enable': {
          type: 'boolean',
          title: '#1 Header text',
          description: 'header text [confirmationCodeVerificationScreen.header]',
        },
        header: {
          type: 'string',
        },
        'confirmationNumberNotFound:enable': {
          type: 'boolean',
          title: '#2 Confirmation number not found text',
          description: '[confirmationCodeVerificationScreen.confirmationNumberNotFound]',
        },
        confirmationNumberNotFound: {
          type: 'string',
        },
      },
    },
    mrzScannerScreen: {
      type: 'object',
      title: '#37 MRZ Scanner screen (ID Authentication method)',
      properties: {
        'instructionText:enable': {
          type: 'boolean',
          title: '#1 Instruction text',
          description: 'scan screen primary instruction text [mrzScannerScreen.instructionText]',
        },
        instructionText: {
          type: 'string',
        },
        'instructionText2:enable': {
          type: 'boolean',
          title: '#2 Instruction text 2',
          description: 'scan screen secondary instruction text [mrzScannerScreen.instructionText2]',
        },
        instructionText2: {
          type: 'string',
        },

        'instructionImageTitle:enable': {
          type: 'boolean',
          title: '#3 Instruction image title',
          description:
            'scan screen primary instruction image title [mrzScannerScreen.instructionImageTitle]',
        },
        instructionImageTitle: {
          type: 'string',
        },
        'instructionImageTitle2:enable': {
          type: 'boolean',
          title: '#4 Instruction image title 2',
          description:
            'scan screen secondary instruction image title [mrzScannerScreen.instructionImageTitle2]',
        },
        instructionImageTitle2: {
          type: 'string',
        },
        'backLabel:enable': {
          type: 'boolean',
          title: '#5 Back label',
          description: 'back label text [mrzScannerScreen.backLabel]',
        },
        backLabel: {
          type: 'string',
        },
        'cameraText:enable': {
          type: 'boolean',
          title: '#6 Camera text',
          description: 'camera position text [mrzScannerScreen.cameraText]',
        },
        cameraText: {
          type: 'string',
        },
        'scanInstructionText:enable': {
          type: 'boolean',
          title: '#7 Scan instruction text',
          description:
            'Scan instruction text on the bottom right of the scan screen [mrzScannerScreen.scanInstructionText]',
        },
        scanInstructionText: {
          type: 'string',
        },
        'headerText:enable': {
          type: 'boolean',
          title: '#8 Header text',
          description: 'Scan screen title [mrzScannerScreen.headerText]',
        },
        headerText: {
          type: 'string',
        },
        'initScreenHeader:enable': {
          type: 'boolean',
          title: '#9 Init screen header',
          description: 'Initial screen header text [mrzScannerScreen.initScreenHeader]',
        },
        initScreenHeader: {
          type: 'string',
        },
        'initScreenInstructionText:enable': {
          type: 'boolean',
          title: '#10 Init screen instruction text',
          description: 'Init screen instruction text [mrzScannerScreen.initScreenInstructionText]',
        },
        initScreenInstructionText: {
          type: 'string',
        },
        'instruction_ID:enable': {
          title: '#11 Document type instruction text',
          type: 'boolean',
          description:
            '[mrzScannerScreen.instruction_ID] - MRZ ID Verification main screen document type instruction text',
        },
        instruction_ID: {
          type: 'string',
        },
        'instruction_AGE:enable': {
          title: '#12 Age verification instruction text',
          type: 'boolean',
          description:
            '[mrzScannerScreen.instruction_AGE] - MRZ ID Verification main screen age verification instruction text',
        },
        instruction_AGE: {
          type: 'string',
        },
        'instruction_NAME:enable': {
          title: '#13 Name match instruction text',
          type: 'boolean',
          description:
            '[mrzScannerScreen.instruction_NAME] - MRZ ID Verification main screen name match instruction text',
        },
        instruction_NAME: {
          type: 'string',
        },
        'initScreenDescription:enable': {
          title: '#14 Init screen description',
          type: 'boolean',
          description: 'Init screen description text [mrzScannerScreen.initScreenDescription]',
        },
        initScreenDescription: {
          type: 'string',
        },
        'popupCloseButton:enable': {
          title: '#15 Popup close button',
          type: 'boolean',
          description: 'Init screen popup close button text [mrzScannerScreen.popupCloseButton]',
        },
        popupCloseButton: {
          type: 'string',
        },
        'popupDefaultErrorHeaderText:enable': {
          title: '#16 Popup default error header text',
          type: 'boolean',
          description:
            'Init screen popup default error header text [mrzScannerScreen.popupDefaultErrorHeaderText]',
        },
        popupDefaultErrorHeaderText: {
          type: 'string',
        },
        'backAlert:enable': {
          title: '#17 Back alert text',
          type: 'boolean',
          description: 'Scan screen on press back popup text [mrzScannerScreen.backAlert]',
        },
        backAlert: {
          type: 'string',
        },
        'remoteAssistanceAlert:enable': {
          title: '#18 Remote assistance alert text',
          type: 'boolean',
          description:
            'Scan screen on press remote assistance popup text [mrzScannerScreen.backAlert]',
        },
        remoteAssistanceAlert: {
          type: 'string',
        },
        'homeAlert:enable': {
          title: '#19 Home alert text',
          type: 'boolean',
          description: 'Scan screen on press home popup text [mrzScannerScreen.homeAlert]',
        },
        homeAlert: {
          type: 'string',
        },
        'backAlertPositiveButton:enable': {
          title: '#20 Back alert positive button',
          type: 'boolean',
          description:
            'Scan screen on press back popup positive button [mrzScannerScreen.backAlertPositiveButton]',
        },
        backAlertPositiveButton: {
          type: 'string',
        },
        'remoteAssistanceAlertPositiveButton:enable': {
          title: '#21 Remote assistance alert positive button',
          type: 'boolean',
          description:
            'Scan screen on press remote assistance popup positive button [mrzScannerScreen.remoteAssistanceAlertPositiveButton]',
        },
        remoteAssistanceAlertPositiveButton: {
          type: 'string',
        },
        'homeAlertPositiveButton:enable': {
          title: '#22 Home alert positive button',
          type: 'boolean',
          description:
            'Scan screen on press home popup positive button [mrzScannerScreen.homeAlertPositiveButton]',
        },
        homeAlertPositiveButton: {
          type: 'string',
        },
        'backAlertNegativeButton:enable': {
          title: '#23 Back alert negative button',
          type: 'boolean',
          description:
            'Scan screen on press back popup negative button [mrzScannerScreen.backAlertNegativeButton]',
        },
        backAlertNegativeButton: {
          type: 'string',
        },
        'remoteAssistanceAlertNegativeButton:enable': {
          title: '#24 Remote assistance alert negative button',
          type: 'boolean',
          description:
            'Scan screen on press remote assistance popup negative button [mrzScannerScreen.remoteAssistanceAlertNegativeButton]',
        },
        remoteAssistanceAlertNegativeButton: {
          type: 'string',
        },
        'homeAlertNegativeButton:enable': {
          title: '#25 Home alert negative button',
          type: 'boolean',
          description:
            'Scan screen on press home popup negative button [mrzScannerScreen.homeAlertNegativeButton]',
        },
        homeAlertNegativeButton: {
          type: 'string',
        },
      },
    },
    wristbandDispensingScreen: {
      type: 'object',
      title: '#38 Wristband Dispensing Screen',
      properties: {
        'mainTitle:enable': {
          type: 'boolean',
          title: '#1 Main Title',
          description: '[wristbandDispensingScreen.mainTitle]',
        },
        mainTitle: {
          type: 'string',
        },
        'heroSubtitle:enable': {
          type: 'boolean',
          title: '#2 Subtitle',
          description: 'text of the main subtitle [wristbandDispensingScreen.heroSubtitle]',
        },
        heroSubtitle: {
          type: 'string',
        },
        'guestsTitle:enable': {
          type: 'boolean',
          title: '#3 Guests Title',
          description:
            'text of the title of left side list [wristbandDispensingScreen.guestsTitle]',
        },
        guestsTitle: {
          type: 'string',
        },
        'guestsDispensed:enable': {
          type: 'boolean',
          title: '#4 Guest Dispensed',
          description:
            'text of the tag label for guest who have wristbands already dispensed [wristbandDispensingScreen.guestsDispensed]',
        },
        guestsDispensed: {
          type: 'string',
        },
        'guestSetAccess:enable': {
          type: 'boolean',
          title: '#5 Guest Set Access',
          description:
            'text of the label for the button to set access for a single guest [wristbandDispensingScreen.guestSetAccess]',
        },
        guestSetAccess: {
          type: 'string',
        },
        'primaryGuest:enable': {
          type: 'boolean',
          title: '#6 Primary Guest',
          description:
            'text to describe how the primary guests will be called [wristbandDispensingScreen.primaryGuest]',
        },
        primaryGuest: {
          type: 'string',
        },
        'secondaryGuest:enable': {
          type: 'boolean',
          title: '#7 Secondary Guest',
          description:
            'text to describe how the secondary guests will be called [wristbandDispensingScreen.secondaryGuest]',
        },
        secondaryGuest: {
          type: 'string',
        },
        'enjoyStay:enable': {
          type: 'boolean',
          title: '#8 Enjoy Stay',
          description:
            'message to show to the guests after successfully dispense all wristbands [wristbandDispensingScreen.enjoyStay]',
        },
        enjoyStay: {
          type: 'string',
        },
        'enjoyStayComplement:enable': {
          type: 'boolean',
          title: '#9 Enjoy Stay Complement',
          description:
            'complement to the enjoy message to show to the guests after successfully dispense all wristbands [wristbandDispensingScreen.enjoyStayComplement]',
        },
        enjoyStayComplement: {
          type: 'string',
        },
        'accessTitle:enable': {
          type: 'boolean',
          title: '#10 Access Title',
          description:
            'title for the right side of set access side [wristbandDispensingScreen.accessTitle]',
        },
        accessTitle: {
          type: 'string',
        },
        'roomAccessTitle:enable': {
          type: 'boolean',
          title: '#11 Room Access Title',
          description:
            'title for the label of the room access switch [wristbandDispensingScreen.roomAccessTitle]',
        },
        roomAccessTitle: {
          type: 'string',
        },
        'allowChargingTitle:enable': {
          type: 'boolean',
          title: '#12 Allow Charging Title',
          description:
            'title for the label of the allow charging switch [wristbandDispensingScreen.allowChargingTitle]',
        },
        allowChargingTitle: {
          type: 'string',
        },
        'wristbandDispensingInfo:enable': {
          type: 'boolean',
          title: '#13 Wristband Dispensing Info',
          description:
            'description about the amount wristbands each user can receive [wristbandDispensingScreen.wristbandDispensingInfo]',
        },
        wristbandDispensingInfo: {
          type: 'string',
        },
        'wristbandCollectInfo:enable': {
          type: 'boolean',
          title: '#14 Wristband Collect Info',
          description:
            'description about where the user will pick the wristband [wristbandDispensingScreen.wristbandCollectInfo]',
        },
        wristbandCollectInfo: {
          type: 'string',
        },
        'dispenseWristbandButtonLabel:enable': {
          type: 'boolean',
          title: '#15 Dispense Wristband Button Label',
          description: '[wristbandDispensingScreen.dispenseWristbandButtonLabel]',
        },
        dispenseWristbandButtonLabel: {
          type: 'string',
        },
        'wristbandDispensingError:enable': {
          type: 'boolean',
          title: '#16 Wristband Dispensing Error',
          description: '[wristbandDispensingScreen.wristbandDispensingError]',
        },
        wristbandDispensingError: {
          type: 'string',
        },
        'wristbandDispensingErrorSubtitle:enable': {
          type: 'boolean',
          title: '#17 Dispense Wristband Error Subtitle',
          description: '[wristbandDispensingScreen.wristbandDispensingErrorSubtitle]',
        },
        wristbandDispensingErrorSubtitle: {
          type: 'string',
        },
        'endSessionButtonLabel:enable': {
          type: 'boolean',
          title: '#18 End Session Button Label',
          description: '[wristbandDispensingScreen.endSessionButtonLabel]',
        },
        endSessionButtonLabel: {
          type: 'string',
        },
        'dispenseConfirmationTitle:enable': {
          type: 'boolean',
          title: '#19 Dispense Confirmation Title',
          description: '[wristbandDispensingScreen.dispenseConfirmationTitle]',
        },
        dispenseConfirmationTitle: {
          type: 'string',
        },
        'dispensingCancelButtonLabel:enable': {
          type: 'boolean',
          title: '#20 Dispensing Cancel Button Label',
          description: '[wristbandDispensingScreen.dispensingCancelButtonLabel]',
        },
        dispensingCancelButtonLabel: {
          type: 'string',
        },
        'dispensingConfirmButtonLabel:enable': {
          type: 'boolean',
          title: '#21 Dispensing Confirm Button Label',
          description: '[wristbandDispensingScreen.dispensingConfirmButtonLabel]',
        },
        dispensingConfirmButtonLabel: {
          type: 'string',
        },
        'modalInformationTitle:enable': {
          type: 'boolean',
          title: '#22 Title of Information Modal',
          description: '[wristbandDispensingScreen.modalInformationTitle]',
        },
        modalInformationTitle: {
          type: 'string',
        },
        'roomAccessDescription:enable': {
          type: 'boolean',
          title: '#23 Room Access Description',
          description: '[wristbandDispensingScreen.roomAccessDescription]',
        },
        roomAccessDescription: {
          type: 'string',
        },
        'allowChargingDescription:enable': {
          type: 'boolean',
          title: '#24 Allow Charging Description',
          description: '[wristbandDispensingScreen.allowChargingDescription]',
        },
        allowChargingDescription: {
          type: 'string',
        },
        'dispensingWristbandTitle:enable': {
          type: 'boolean',
          title: '#25 Dispensing Wristband Title',
          description:
            'text to show while the wristband is dispensing [wristbandDispensingScreen.dispensingWristbandTitle]',
        },
        dispensingWristbandTitle: {
          type: 'string',
        },
        'wristbandDispensedTitle:enable': {
          type: 'boolean',
          title: '#26 Wristband Dispensed Title',
          description:
            'text to show after the wristband is dispensed [wristbandDispensingScreen.wristbandDispensedTitle]',
        },
        wristbandDispensedTitle: {
          type: 'string',
        },
        'logoutModalTitle:enable': {
          type: 'boolean',
          title: '#27 Logout Modal Title',
          description: '[wristbandDispensingScreen.logoutModalTitle]',
        },
        logoutModalTitle: {
          type: 'string',
        },
        'logoutModalCancelButtonLabel:enable': {
          type: 'boolean',
          title: '#28 Logout Modal Cancel Button Label',
          description: '[wristbandDispensingScreen.logoutModalCancelButtonLabel]',
        },
        logoutModalCancelButtonLabel: {
          type: 'string',
        },
        'logoutModalConfirmButtonLabel:enable': {
          type: 'boolean',
          title: '#29 Logout Modal Confirm Button Label',
          description: '[wristbandDispensingScreen.logoutModalConfirmButtonLabel]',
        },
        logoutModalConfirmButtonLabel: {
          type: 'string',
        },
        'finalHeroSubtitle:enable': {
          type: 'boolean',
          title: '#30 Final Subtitle',
          description:
            'Text that should appear at the top subtitle after all wristbands been dispensed. [wristbandDispensingScreen.finalHeroSubtitle]',
        },
        finalHeroSubtitle: {
          type: 'string',
        },
        'finalMainTitle:enable': {
          type: 'boolean',
          title: '#31 Final Main Title',
          description:
            'Text that should appear at the top main title after all wristbands been dispensed. [wristbandDispensingScreen.finalMainTitle]',
        },
        finalMainTitle: {
          type: 'string',
        },

        'labelForBlankWristband:enable': {
          type: 'boolean',
          title: '#32 Label for Blank Wristbands',
          description:
            'Text that should be printed on the blank wristband. [wristbandDispensingScreen.labelForBlankWristband]',
        },
        labelForBlankWristband: {
          type: 'string',
        },
        'labelForRoomAccessWristband:enable': {
          type: 'boolean',
          title: '#33 Label for Room Access Wristbands',
          description:
            'Text that should be printed on wristbands that contains room access. [wristbandDispensingScreen.labelForRoomAccessWristband]',
        },
        labelForRoomAccessWristband: {
          type: 'string',
        },
        'labelForSecondOptionWristband:enable': {
          type: 'boolean',
          title: '#34 Label for Second Option Wristbands',
          description:
            'Text that should be printed on wristbands that contains a second option. [wristbandDispensingScreen.labelForSecondOptionWristband]',
        },
        labelForSecondOptionWristband: {
          type: 'string',
        },
        'optionalWristbandGuestDescription:enable': {
          type: 'boolean',
          title: '#35 Optional wristband guest description',
          description:
            'Optional wristband guest description. [wristbandDispensingScreen.optionalWristbandGuestDescription]',
        },
        optionalWristbandGuestDescription: {
          type: 'string',
        },
        'voidWristbandModalHeader:enable': {
          type: 'boolean',
          title: '#36 VOID wristband modal header',
          description:
            'Text to be displayed as the header of the VOID wristband error modal. [wristbandDispensingScreen.voidWristbandModalHeader]',
        },
        voidWristbandModalHeader: {
          type: 'string',
        },
        'voidWristbandModalMessage:enable': {
          type: 'boolean',
          title: '#37 VOID wristband modal message',
          description:
            'Text to be displayed as the message of the VOID wristband error modal. [wristbandDispensingScreen.voidWristbandModalMessage]',
        },
        voidWristbandModalMessage: {
          type: 'string',
        },
        'voidWristbandModalDiscardAndReissueButton:enable': {
          type: 'boolean',
          title: '#38 VOID wristband modal action button text',
          description:
            'Text to be displayed in the VOID wristband error modal action button. [wristbandDispensingScreen.voidWristbandModalDiscardAndReissueButton]',
        },
        voidWristbandModalDiscardAndReissueButton: {
          type: 'string',
        },
        'labelForVoidWristband:enable': {
          type: 'boolean',
          title: '#39 Label to be printed onto VOID wristbands',
          description:
            'Text to be printed onto VOID wristband. [wristbandDispensingScreen.labelForVoidWristband]',
        },
        labelForVoidWristband: {
          type: 'string',
        },
        'voidWristbandTimeOutMessage:enable': {
          type: 'boolean',
          title: '#40 Void wristband modal timeout message',
          description:
            'Add "{{counter}}" in the copy as a placeholder for the countdown [wristbandDispensingScreen.voidWristbandTimeOutMessage]',
        },
        voidWristbandTimeOutMessage: {
          type: 'string',
        },
      },
    },
    finalScreen: {
      type: 'object',
      title: '#39 Final screen',
      properties: {
        'header:enable': {
          title: '#1 Header',
          type: 'boolean',
          description: '[finalScreen.header]',
        },
        header: {
          type: 'string',
        },
        // Final Screen A
        'needVoucherRoomIsReadyTitle:enable': {
          title: '#2 needVoucherRoomIsReadyTitle text',
          type: 'boolean',
          description: '[finalScreen.needVoucherRoomIsReadyTitle]',
        },
        needVoucherRoomIsReadyTitle: {
          type: 'string',
        },
        'needVoucherRoomIsReadyDescription:enable': {
          title: '#3 needVoucherRoomIsReadyDescription text',
          type: 'boolean',
          description: '[finalScreen.needVoucherRoomIsReadyDescription]',
        },
        needVoucherRoomIsReadyDescription: {
          type: 'string',
        },
        // Final Screen B
        'notNeedVoucherRoomIsReadyTitle:enable': {
          title: '#4 notNeedVoucherRoomIsReadyTitle text',
          type: 'boolean',
          description: '[finalScreen.notNeedVoucherRoomIsReadyTitle]',
        },
        notNeedVoucherRoomIsReadyTitle: {
          type: 'string',
        },
        'notNeedVoucherRoomIsReadyDescription:enable': {
          title: '#5 notNeedVoucherRoomIsReadyDescription text',
          type: 'boolean',
          description: '[finalScreen.notNeedVoucherRoomIsReadyDescription]',
        },
        notNeedVoucherRoomIsReadyDescription: {
          type: 'string',
        },
        // Final Screen C
        'needVoucherRoomIsNotReadyTitle:enable': {
          title: '#6 needVoucherRoomIsNotReadyTitle text',
          type: 'boolean',
          description: '[finalScreen.needVoucherRoomIsNotReadyTitle]',
        },
        needVoucherRoomIsNotReadyTitle: {
          type: 'string',
        },
        'needVoucherRoomIsNotReadyDescription:enable': {
          title: '#7 needVoucherRoomIsNotReadyDescription text',
          type: 'boolean',
          description: '[finalScreen.needVoucherRoomIsNotReadyDescription]',
        },
        needVoucherRoomIsNotReadyDescription: {
          type: 'string',
        },
        // Final Screen D
        'notNeedVoucherRoomIsNotReadyTitle:enable': {
          title: '#8 notNeedVoucherRoomIsNotReadyTitle text',
          type: 'boolean',
          description: '[finalScreen.notNeedVoucherRoomIsNotReadyTitle]',
        },
        notNeedVoucherRoomIsNotReadyTitle: {
          type: 'string',
        },
        'notNeedVoucherRoomIsNotReadyDescription:enable': {
          title: '#9 notNeedVoucherRoomIsNotReadyDescription text',
          type: 'boolean',
          description: '[finalScreen.notNeedVoucherRoomIsNotReadyDescription]',
        },
        notNeedVoucherRoomIsNotReadyDescription: {
          type: 'string',
        },
        'endSessionButtonLabel:enable': {
          title: '#10 endSessionButtonLabel text button',
          type: 'boolean',
          description: '[finalScreen.endSessionButtonLabel]',
        },
        endSessionButtonLabel: {
          type: 'string',
        },
        'finalScreenImageUrl:enable': {
          title: '#11 finalScreenImageUrl',
          type: 'boolean',
          description: '[finalScreen.finalScreenImageUrl]',
        },
        finalScreenImageUrl: {
          type: 'string',
        },
      },
    },
    simplifiedIdVerification: {
      type: 'object',
      title: '#40 Simplified ID Verification config',
      properties: {
        config: {
          type: 'object',
          title: '#1 Config',
          properties: {
            authentication: {
              type: 'object',
              title: '#1.1 Authentication flow',
              properties: {
                documentSelection: {
                  type: 'object',
                  title: '#1.1.1 Document selection screen config',
                  properties: {
                    'header:enable': {
                      title: '#1.1.1.1 Header',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.authentication.documentSelection.header]',
                    },
                    header: {
                      type: 'string',
                    },
                    'popupDefaultErrorHeaderText:enable': {
                      title: '#1.1.1.2 Popup default error header text',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.authentication.documentSelection.popupDefaultErrorHeaderText]',
                    },
                    popupDefaultErrorHeaderText: {
                      type: 'string',
                    },
                    'popupCloseButton:enable': {
                      title: '#1.1.1.3 Popup close button',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.authentication.documentSelection.popupCloseButton]',
                    },
                    popupCloseButton: {
                      type: 'string',
                    },
                    'initScreenHeader:enable': {
                      title: '#1.1.1.4 Initial screen header',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.authentication.documentSelection.initScreenHeader]',
                    },
                    initScreenHeader: {
                      type: 'string',
                    },
                    'initScreenInstructionText:enable': {
                      title: '#1.1.1.5 Initial screen instruction text',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.authentication.documentSelection.initScreenInstructionText]',
                    },
                    initScreenInstructionText: {
                      type: 'string',
                    },
                    'instruction_ID:enable': {
                      title: '#1.1.1.6 Document type instruction text',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.authentication.documentSelection.instruction_ID]',
                    },
                    instruction_ID: {
                      type: 'string',
                    },
                    'instruction_AGE:enable': {
                      title: '#1.1.1.7 Age verification instruction text',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.authentication.documentSelection.instruction_AGE]',
                    },
                    instruction_AGE: {
                      type: 'string',
                    },
                    'instruction_NAME:enable': {
                      title: '#1.1.1.8 Name match instruction text',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.authentication.documentSelection.instruction_NAME]',
                    },
                    instruction_NAME: {
                      type: 'string',
                    },
                    'initScreenDescription:enable': {
                      title: '#1.1.1.9 Initial screen description',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.authentication.documentSelection.initScreenDescription]',
                    },
                    initScreenDescription: {
                      type: 'string',
                    },
                    'documentsConfig:enable': {
                      title: '#1.1.1.10 Document configuration',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.authentication.documentSelection.documentsConfig]',
                    },
                    documentsConfig: {
                      title: 'Document configuration',
                      type: 'array',
                      items: {
                        type: 'object',
                        properties: {
                          scannerType: {
                            title: 'Scanner type',
                            type: 'string',
                            enum: ['MRZ', 'PDF417'],
                          },
                          documentType: {
                            title: 'Document type',
                            type: 'string',
                            enum: ['PASSPORT', 'ID_CARD'],
                          },
                          documentBoxText: {
                            title: 'Document box text',
                            type: 'string',
                          },
                          scannerConfig: {
                            title: 'Scanner screen config',
                            type: 'object',
                            properties: {
                              instructionText: {
                                title: 'Instruction text',
                                type: 'string',
                              },
                              instructionText2: {
                                title: 'Instruction text 2',
                                type: 'string',
                              },
                              instructionImageTitle: {
                                title: 'Instruction image title',
                                type: 'string',
                              },
                              instructionImageTitle2: {
                                title: 'Instruction image title 2',
                                type: 'string',
                              },
                              backLabel: {
                                title: 'Back label',
                                type: 'string',
                              },
                              cameraText: {
                                title: 'Camera text',
                                type: 'string',
                              },
                              scanInstructionText: {
                                title: 'Scan instruction text',
                                type: 'string',
                              },
                              headerText: {
                                title: 'Header text',
                                type: 'string',
                              },
                              initScreenHeader: {
                                title: 'Initial screen header',
                                type: 'string',
                              },
                              initScreenInstructionText: {
                                title: 'Initial screen instruction text',
                                type: 'string',
                              },
                              instruction_ID: {
                                title: 'Document type instruction text',
                                type: 'string',
                              },
                              instruction_AGE: {
                                title: 'Age verification instruction text',
                                type: 'string',
                              },
                              instruction_NAME: {
                                title: 'Name match instruction text',
                                type: 'string',
                              },
                              initScreenDescription: {
                                title: 'Initial screen description',
                                type: 'string',
                              },
                              popupCloseButton: {
                                title: 'Popup close button',
                                type: 'string',
                              },
                              popupDefaultErrorHeaderText: {
                                title: 'Popup default error header text',
                                type: 'string',
                              },
                              backAlert: {
                                title: 'Back alert text',
                                type: 'string',
                              },
                              remoteAssistanceAlert: {
                                title: 'Remote assistance alert text',
                                type: 'string',
                              },
                              homeAlert: {
                                title: 'Home alert text',
                                type: 'string',
                              },
                              backAlertPositiveButton: {
                                title: 'Back alert positive button',
                                type: 'string',
                              },
                              remoteAssistanceAlertPositiveButton: {
                                title: 'Remote assistance alert positive button',
                                type: 'string',
                              },
                              homeAlertPositiveButton: {
                                title: 'Home alert positive button',
                                type: 'string',
                              },
                              backAlertNegativeButton: {
                                title: 'Back alert negative button',
                                type: 'string',
                              },
                              remoteAssistanceAlertNegativeButton: {
                                title: 'Remote assistance alert negative button',
                                type: 'string',
                              },
                              homeAlertNegativeButton: {
                                title: 'Home alert negative button',
                                type: 'string',
                              },
                            },
                          },
                        },
                        required: ['scannerType', 'documentType'],
                      },
                    },
                    'popupRetryButton:enable': {
                      title: '#1.1.1.11 Popup retry button text',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.authentication.documentSelection.popupRetryButton]',
                    },
                    popupRetryButton: {
                      type: 'string',
                    },
                    'popupCancelButton:enable': {
                      title: '#1.1.1.12 Popup cancel button text',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.authentication.documentSelection.popupCancelButton]',
                    },
                    popupCancelButton: {
                      type: 'string',
                    },
                  },
                },
                fallbackErrors: {
                  type: 'object',
                  title: '#1.1.2 Fallback errors',
                  properties: {
                    'userNotFound:enable': {
                      title: '#1.1.2.1 Popup user not found fallback text',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.authentication.fallbackErrors.userNotFound]',
                    },
                    userNotFound: {
                      type: 'string',
                    },
                  },
                },
                'humanReadableErrors:enable': {
                  title: '#1.1.3 Simplified ID verification auth screen custom human errors',
                  type: 'boolean',
                  description:
                    '[simplifiedIdVerification.config.authentication.humanReadableErrors]',
                },
                humanReadableErrors: {
                  title:
                    'Simplified ID verification auth screen custom localize error codes to be human readable errors',
                  type: 'array',
                  items: {
                    type: 'object',
                    properties: {
                      code: {
                        title: 'Error code',
                        type: 'string',
                      },
                      message: {
                        title: 'Human readable error text',
                        type: 'string',
                      },
                    },
                    required: ['code', 'message'],
                  },
                },
              },
            },
            requirement: {
              type: 'object',
              title: '#1.2 Requirement flow',
              properties: {
                documentSelection: {
                  type: 'object',
                  title: '#1.2.1 Document selection screen config',
                  properties: {
                    'header:enable': {
                      title: '#1.2.1.1 Header',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.requirement.documentSelection.header]',
                    },
                    header: {
                      type: 'string',
                    },
                    'popupDefaultErrorHeaderText:enable': {
                      title: '#1.2.1.2 Popup default error header text',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.requirement.documentSelection.popupDefaultErrorHeaderText]',
                    },
                    popupDefaultErrorHeaderText: {
                      type: 'string',
                    },
                    'popupCloseButton:enable': {
                      title: '#1.2.1.3 Popup close button',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.requirement.documentSelection.popupCloseButton]',
                    },
                    popupCloseButton: {
                      type: 'string',
                    },
                    'initScreenHeader:enable': {
                      title: '#1.2.1.4 Initial screen header',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.requirement.documentSelection.initScreenHeader]',
                    },
                    initScreenHeader: {
                      type: 'string',
                    },
                    'initScreenInstructionText:enable': {
                      title: '#1.2.1.5 Initial screen instruction text',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.requirement.documentSelection.initScreenInstructionText]',
                    },
                    initScreenInstructionText: {
                      type: 'string',
                    },
                    'instruction_ID:enable': {
                      title: '#1.2.1.6 Document type instruction text',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.requirement.documentSelection.instruction_ID]',
                    },
                    instruction_ID: {
                      type: 'string',
                    },
                    'instruction_AGE:enable': {
                      title: '#1.2.1.7 Age verification instruction text',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.requirement.documentSelection.instruction_AGE]',
                    },
                    instruction_AGE: {
                      type: 'string',
                    },
                    'instruction_NAME:enable': {
                      title: '#1.2.1.8 Name match instruction text',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.requirement.documentSelection.instruction_NAME]',
                    },
                    instruction_NAME: {
                      type: 'string',
                    },
                    'initScreenDescription:enable': {
                      title: '#1.2.1.9 Initial screen description',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.requirement.documentSelection.initScreenDescription]',
                    },
                    initScreenDescription: {
                      type: 'string',
                    },
                    'documentsConfig:enable': {
                      title: '#1.2.1.10 Document configuration',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.requirement.documentSelection.documentsConfig]',
                    },
                    documentsConfig: {
                      title: 'Document configuration',
                      type: 'array',
                      items: {
                        type: 'object',
                        properties: {
                          scannerType: {
                            title: 'Scanner type',
                            type: 'string',
                            enum: ['MRZ', 'PDF417'],
                          },
                          documentType: {
                            title: 'Document type',
                            type: 'string',
                            enum: ['PASSPORT', 'ID_CARD'],
                          },
                          documentBoxText: {
                            title: 'Document box text',
                            type: 'string',
                          },
                          scannerConfig: {
                            title: 'Scanner screen config',
                            type: 'object',
                            properties: {
                              instructionText: {
                                title: 'Instruction text',
                                type: 'string',
                              },
                              instructionText2: {
                                title: 'Instruction text 2',
                                type: 'string',
                              },
                              instructionImageTitle: {
                                title: 'Instruction image title',
                                type: 'string',
                              },
                              instructionImageTitle2: {
                                title: 'Instruction image title 2',
                                type: 'string',
                              },
                              backLabel: {
                                title: 'Back label',
                                type: 'string',
                              },
                              cameraText: {
                                title: 'Camera text',
                                type: 'string',
                              },
                              scanInstructionText: {
                                title: 'Scan instruction text',
                                type: 'string',
                              },
                              headerText: {
                                title: 'Header text',
                                type: 'string',
                              },
                              initScreenHeader: {
                                title: 'Initial screen header',
                                type: 'string',
                              },
                              initScreenInstructionText: {
                                title: 'Initial screen instruction text',
                                type: 'string',
                              },
                              instruction_ID: {
                                title: 'Document type instruction text',
                                type: 'string',
                              },
                              instruction_AGE: {
                                title: 'Age verification instruction text',
                                type: 'string',
                              },
                              instruction_NAME: {
                                title: 'Name match instruction text',
                                type: 'string',
                              },
                              initScreenDescription: {
                                title: 'Initial screen description',
                                type: 'string',
                              },
                              popupCloseButton: {
                                title: 'Popup close button',
                                type: 'string',
                              },
                              popupDefaultErrorHeaderText: {
                                title: 'Popup default error header text',
                                type: 'string',
                              },
                              backAlert: {
                                title: 'Back alert text',
                                type: 'string',
                              },
                              remoteAssistanceAlert: {
                                title: 'Remote assistance alert text',
                                type: 'string',
                              },
                              homeAlert: {
                                title: 'Home alert text',
                                type: 'string',
                              },
                              backAlertPositiveButton: {
                                title: 'Back alert positive button',
                                type: 'string',
                              },
                              remoteAssistanceAlertPositiveButton: {
                                title: 'Remote assistance alert positive button',
                                type: 'string',
                              },
                              homeAlertPositiveButton: {
                                title: 'Home alert positive button',
                                type: 'string',
                              },
                              backAlertNegativeButton: {
                                title: 'Back alert negative button',
                                type: 'string',
                              },
                              remoteAssistanceAlertNegativeButton: {
                                title: 'Remote assistance alert negative button',
                                type: 'string',
                              },
                              homeAlertNegativeButton: {
                                title: 'Home alert negative button',
                                type: 'string',
                              },
                            },
                          },
                        },
                        required: ['scannerType', 'documentType'],
                      },
                    },
                    'popupRetryButton:enable': {
                      title: '#1.2.1.11 Popup retry button text',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.requirement.documentSelection.popupRetryButton]',
                    },
                    popupRetryButton: {
                      type: 'string',
                    },
                    'popupCancelButton:enable': {
                      title: '#1.2.1.12 Popup cancel button text',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.requirement.documentSelection.popupCancelButton]',
                    },
                    popupCancelButton: {
                      type: 'string',
                    },
                  },
                },
              },
            },
            general: {
              type: 'object',
              title: '#1.3 Simplified ID Verification general config',
              properties: {
                'invalidExpireDateErrorHeaderText:enable': {
                  title: '#1.3.1 Invalid expire date error header text',
                  type: 'boolean',
                  description:
                    '[simplifiedIdVerification.config.general.invalidExpireDateErrorHeaderText]',
                },
                invalidExpireDateErrorHeaderText: {
                  type: 'string',
                },
                'invalidExpireDateErrorDescription:enable': {
                  title: '#1.3.2 Invalid expire date error description',
                  type: 'boolean',
                  description:
                    '[simplifiedIdVerification.config.general.invalidExpireDateErrorDescription]',
                },
                invalidExpireDateErrorDescription: {
                  type: 'string',
                },
                'expiredDocumentErrorHeaderText:enable': {
                  title: '#1.3.3 Expired document error header text',
                  type: 'boolean',
                  description:
                    '[simplifiedIdVerification.config.general.expiredDocumentErrorHeaderText]',
                },
                expiredDocumentErrorHeaderText: {
                  type: 'string',
                },
                'expiredDocumentErrorDescription:enable': {
                  title: '#1.3.4 Expired document error description',
                  type: 'boolean',
                  description:
                    '[simplifiedIdVerification.config.general.expiredDocumentErrorDescription]',
                },
                expiredDocumentErrorDescription: {
                  type: 'string',
                },
                'validateDocumentFallbackErrorHeaderText:enable': {
                  title: '#1.3.5 Validate document fallback error header text',
                  type: 'boolean',
                  description:
                    '[simplifiedIdVerification.config.general.validateDocumentFallbackErrorHeaderText]',
                },
                validateDocumentFallbackErrorHeaderText: {
                  type: 'string',
                },
                'validateDocumentFallbackErrorDescription:enable': {
                  title: '#1.3.6 Validate document fallback error description',
                  type: 'boolean',
                  description:
                    '[simplifiedIdVerification.config.general.validateDocumentFallbackErrorDescription]',
                },
                validateDocumentFallbackErrorDescription: {
                  type: 'string',
                },
                'confirmationNumberNotFound:enable': {
                  title: '#1.3.7 Confirmation number not found error description',
                  type: 'boolean',
                  description:
                    '[simplifiedIdVerification.config.general.confirmationNumberNotFound]',
                },
                confirmationNumberNotFound: {
                  type: 'string',
                },
              },
            },
          },
        },
      },
    },
    failedRequirementsOverlay: {
      type: 'object',
      title: '#41 Failed requirements overlay',
      properties: {
        'title:enable': {
          title: '#1 Title',
          type: 'boolean',
          description: '[failedRequirementsOverlay.title]',
        },
        title: {
          type: 'string',
        },
        'retry:enable': {
          title: '#2 Retry button',
          type: 'boolean',
          description: '[failedRequirementsOverlay.retry]',
        },
        retry: {
          type: 'string',
        },
        'close:enable': {
          title: '#3 Close button',
          type: 'boolean',
          description: '[failedRequirementsOverlay.close]',
        },
        close: {
          type: 'string',
        },
        'remoteAssistance:enable': {
          title: '#4 Remote assistance button',
          type: 'boolean',
          description: '[failedRequirementsOverlay.remoteAssistance]',
        },
        remoteAssistance: {
          type: 'string',
        },
        'failedBusinessIdVerification:enable': {
          title: '#5 Failed business id verification requirement text',
          type: 'boolean',
          description: '[failedRequirementsOverlay.failedBusinessIdVerification]',
        },
        failedBusinessIdVerification: {
          type: 'string',
        },
        'failedHealthCheck:enable': {
          title: '#6 Failed health check requirement text',
          type: 'boolean',
          description: '[failedRequirementsOverlay.failedHealthCheck]',
        },
        failedHealthCheck: {
          type: 'string',
        },
        'failedIdVerification:enable': {
          title: '#7 Failed id verification requirement text',
          type: 'boolean',
          description: '[failedRequirementsOverlay.failedIdVerification]',
        },
        failedIdVerification: {
          type: 'string',
        },
        'failedPaymentStatus:enable': {
          title: '#8 Failed payment status requirement text',
          type: 'boolean',
          description: '[failedRequirementsOverlay.failedPaymentStatus]',
        },
        failedPaymentStatus: {
          type: 'string',
        },
        'failedPMSConfirmation:enable': {
          title: '#9 Failed PMS confirmation requirement text',
          type: 'boolean',
          description: '[failedRequirementsOverlay.failedPMSConfirmation]',
        },
        failedPMSConfirmation: {
          type: 'string',
        },
        'failedPaymentAuthorization:enable': {
          title: '#10 Failed payment authorization requirement text',
          type: 'boolean',
          description: '[failedRequirementsOverlay.failedPaymentAuthorization]',
        },
        failedPaymentAuthorization: {
          type: 'string',
        },
        'failedTermsAndConditionsConfirmation:enable': {
          title: '#11 Failed terms and conditions confirmation requirement text',
          type: 'boolean',
          description: '[failedRequirementsOverlay.failedTermsAndConditionsConfirmation]',
        },
        failedTermsAndConditionsConfirmation: {
          type: 'string',
        },
        'failedMatchNames:enable': {
          title: '#12 Failed match names requirement text',
          type: 'boolean',
          description: '[failedRequirementsOverlay.failedMatchNames]',
        },
        failedMatchNames: {
          type: 'string',
        },
        'failedRequirementRejectedReason:enable': {
          title: '#13 Failed requirement rejected reason text',
          type: 'boolean',
          description: '[failedRequirementsOverlay.failedRequirementRejectedReason]',
        },
        failedRequirementRejectedReason: {
          type: 'string',
        },
        'failedUserInformation:enable': {
          title: '#14 Failed user information requirement text',
          type: 'boolean',
          description: '[failedRequirementsOverlay.failedUserInformation]',
        },
        failedUserInformation: {
          type: 'string',
        },
      },
    },
    AdditionInfoScreen: {
      type: 'object',
      title: '#42 Additional Info screen',
      properties: {
        'title:enable': {
          title: '#1 Title',
          type: 'boolean',
          description: '[AdditionInfoScreen.title]',
        },
        title: {
          type: 'string',
        },
        'errorRequiredFields:enable': {
          title: '#2 Error required fields',
          type: 'boolean',
          description: '[AdditionInfoScreen.errorRequiredFields]',
        },
        errorRequiredFields: {
          type: 'string',
        },
      },
    },
    ServiceScreen: {
      type: 'object',
      title: '#43 Service screen',
      properties: {
        'header:enable': {
          title: '#1 Header',
          type: 'boolean',
          description: '[ServiceScreen.header]',
        },
        header: {
          type: 'string',
        },
        'subheader:enable': {
          title: '#2 Subheader',
          type: 'boolean',
          description: '[ServiceScreen.subheader]',
        },
        subheader: {
          type: 'string',
        },
        'body:enable': {
          title: '#3 Body',
          type: 'boolean',
          description: '[ServiceScreen.body]',
        },
        body: {
          type: 'string',
        },
        'petEnrollFailed:enable': {
          title: '#4 Pet enroll failed',
          type: 'boolean',
          description: '[ServiceScreen.petEnrollFailed]',
        },
        petEnrollFailed: {
          type: 'string',
        },
        'joinButton:enable': {
          title: '#5 Join button',
          type: 'boolean',
          description: '[ServiceScreen.joinButton]',
        },
        joinButton: {
          type: 'string',
        },
        'skipButton:enable': {
          title: '#6 Skip button',
          type: 'boolean',
          description: '[ServiceScreen.skipButton]',
        },
        skipButton: {
          type: 'string',
        },
        'confirmButton:enable': {
          title: '#7 Confirm button',
          type: 'boolean',
          description: '[ServiceScreen.confirmButton]',
        },
        confirmButton: {
          type: 'string',
        },
        'cancelButton:enable': {
          title: '#8 Cancel button',
          type: 'boolean',
          description: '[ServiceScreen.cancelButton]',
        },
        cancelButton: {
          type: 'string',
        },
        'confirmationMessage:enable': {
          title: '#9 Confirmation message',
          type: 'boolean',
          description: '[ServiceScreen.confirmationMessage]',
        },
        confirmationMessage: {
          type: 'string',
        },
        'acknowledgeButton:enable': {
          title: '#10 Acknowledge button',
          type: 'boolean',
          description: '[ServiceScreen.acknowledgeButton]',
        },
        acknowledgeButton: {
          type: 'string',
        },
        'acknowledgeMessage:enable': {
          title: '#11 Acknowledge message',
          type: 'boolean',
          description: '[ServiceScreen.acknowledgeMessage]',
        },
        acknowledgeMessage: {
          type: 'string',
        },
      },
    },
    RoomNumberAndLastnameAuthScreen: {
      type: 'object',
      title: '#43 Room number and last name screen',
      properties: {
        'header:enable': {
          title: '#1 Header',
          type: 'boolean',
          description: '[RoomNumberAndLastnameAuthScreen.header]',
        },
        header: {
          type: 'string',
        },
        'userNotFound:enable': {
          title: '#2 User not found',
          type: 'boolean',
          description: '[RoomNumberAndLastnameAuthScreen.userNotFound]',
        },
        userNotFound: {
          type: 'string',
        },
      },
    },
    noInternetConnectionOverlay: {
      type: 'object',
      title: '#44 No internet connection overlay',
      properties: {
        'title:enable': {
          title: '#1 Title',
          type: 'boolean',
          description: '[noInternetConnectionOverlay.title]',
        },
        title: {
          type: 'string',
        },
        'reconnect:enable': {
          title: '#2 Reconnect',
          type: 'boolean',
          description: '[noInternetConnectionOverlay.reconnect]',
        },
        reconnect: {
          type: 'string',
        },
        'description:enable': {
          title: '#3 Description',
          type: 'boolean',
          description: '[noInternetConnectionOverlay.description]',
        },
        description: {
          type: 'string',
        },
      },
    },
    faceLivenessScreen: {
      type: 'object',
      title: '#45 Face liveness screen',
      properties: {
        'backLabel:enable': {
          title: '#1 Back label',
          type: 'boolean',
          description: '[faceLivenessScreen.backLabel]',
        },
        backLabel: {
          type: 'string',
        },
        'cameraText:enable': {
          title: '#2 Camera text',
          type: 'boolean',
          description: '[faceLivenessScreen.cameraText]',
        },
        cameraText: {
          type: 'string',
        },
        'instructionText:enable': {
          title: '#3 Instruction text',
          type: 'boolean',
          description: '[faceLivenessScreen.instructionText]',
        },
        instructionText: {
          type: 'string',
        },
        'instructionDescriptionText:enable': {
          title: '#4 Instruction description text',
          type: 'boolean',
          description: '[faceLivenessScreen.instructionDescriptionText]',
        },
        instructionDescriptionText: {
          type: 'string',
        },
        'backAlert:enable': {
          title: '#5 Back alert text',
          type: 'boolean',
          description: '[faceLivenessScreen.backAlert]',
        },
        backAlert: {
          type: 'string',
        },
        'remoteAssistanceAlert:enable': {
          title: '#6 Remote assistance alert text',
          type: 'boolean',
          description: '[faceLivenessScreen.remoteAssistanceAlert]',
        },
        remoteAssistanceAlert: {
          type: 'string',
        },
        'homeAlert:enable': {
          title: '#7 Home alert text',
          type: 'boolean',
          description: '[faceLivenessScreen.homeAlert]',
        },
        homeAlert: {
          type: 'string',
        },
        'backAlertPositiveButton:enable': {
          title: '#8 Back alert positive button text',
          type: 'boolean',
          description: '[faceLivenessScreen.backAlertPositiveButton]',
        },
        backAlertPositiveButton: {
          type: 'string',
        },
        'remoteAssistanceAlertPositiveButton:enable': {
          title: '#9 Remote assistance alert positive button text',
          type: 'boolean',
          description: '[faceLivenessScreen.remoteAssistanceAlertPositiveButton]',
        },
        remoteAssistanceAlertPositiveButton: {
          type: 'string',
        },
        'homeAlertPositiveButton:enable': {
          title: '#10 Home alert positive button text',
          type: 'boolean',
          description: '[faceLivenessScreen.homeAlertPositiveButton]',
        },
        homeAlertPositiveButton: {
          type: 'string',
        },
        'backAlertNegativeButton:enable': {
          title: '#11 Back alert negative button text',
          type: 'boolean',
          description: '[faceLivenessScreen.backAlertNegativeButton]',
        },
        backAlertNegativeButton: {
          type: 'string',
        },
        'remoteAssistanceAlertNegativeButton:enable': {
          title: '#12 Remote assistance alert negative button text',
          type: 'boolean',
          description: '[faceLivenessScreen.remoteAssistanceAlertNegativeButton]',
        },
        remoteAssistanceAlertNegativeButton: {
          type: 'string',
        },
        'homeAlertNegativeButton:enable': {
          title: '#13 Home alert negative button text',
          type: 'boolean',
          description: '[faceLivenessScreen.homeAlertNegativeButton]',
        },
        homeAlertNegativeButton: {
          type: 'string',
        },
        'headerText:enable': {
          title: '#14 Header text',
          type: 'boolean',
          description: '[faceLivenessScreen.headerText]',
        },
        headerText: {
          type: 'string',
        },
        'subheaderText:enable': {
          title: '#15 Subheader text',
          type: 'boolean',
          description: '[faceLivenessScreen.subheaderText]',
        },
        subheaderText: {
          type: 'string',
        },
        'startButtonText:enable': {
          title: '#16 Start button text',
          type: 'boolean',
          description: '[faceLivenessScreen.startButtonText]',
        },
        startButtonText: {
          type: 'string',
        },
        'faceMatchFailedDescription:enable': {
          title: '#17 Face match failed description text',
          type: 'boolean',
          description: '[faceLivenessScreen.faceMatchFailedDescription]',
        },
        faceMatchFailedDescription: {
          type: 'string',
        },
        'retryButtonText:enable': {
          title: '#18 Retry button text',
          type: 'boolean',
          description: '[faceLivenessScreen.retryButtonText]',
        },
        retryButtonText: {
          type: 'string',
        },
        'endSessionButtonText:enable': {
          title: '#19 End session button text',
          type: 'boolean',
          description: '[faceLivenessScreen.endSessionButtonText]',
        },
        endSessionButtonText: {
          type: 'string',
        },
        'faceLivenessFailedDescription:enable': {
          title: '#20 Face liveness failed description text',
          type: 'boolean',
          description: '[faceLivenessScreen.faceLivenessFailedDescription]',
        },
        faceLivenessFailedDescription: {
          type: 'string',
        },
        'rewatchButtonText:enable': {
          title: '#21 Rewatch button text',
          type: 'boolean',
          description: '[faceLivenessScreen.rewatchButtonText]',
        },
        rewatchButtonText: {
          type: 'string',
        },
      },
    },
    remoteAssistanceButton: {
      type: 'object',
      title: '#46 Remote assistance button',
      properties: {
        'topRightRemoteAssistanceButtonText:enable': {
          title: '#1 Top Right Remote Assistance Button Text',
          type: 'boolean',
          description: '[remoteAssistanceButton.topRightRemoteAssistanceButtonText]',
        },
        topRightRemoteAssistanceButtonText: {
          type: 'string',
        },
        'menuRemoteAssistanceButtonText:enable': {
          title: '#2 Menu Remote Assistance Button Text',
          type: 'boolean',
          description: '[remoteAssistanceButton.menuRemoteAssistanceButtonText]',
        },
        menuRemoteAssistanceButtonText: {
          type: 'string',
        },
      },
    },
    paymentConfig: {
      type: 'object',
      title: '#47 Payment config',
      properties: {
        shift4: {
          type: 'object',
          title: '#1 Shift4',
          properties: {
            'errors:enable': {
              title: '#1.1 Errors',
              type: 'boolean',
              description: '[paymentConfig.shift4.errors]',
            },
            errors: {
              title: 'Localize Shift4 error codes to be readable errors',
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  code: {
                    title: 'Error code',
                    type: 'string',
                  },
                  message: {
                    title: 'Human readable error text',
                    type: 'string',
                  },
                },
                required: ['code', 'message'],
              },
            },
          },
        },
      },
    },
    earlyCheckInScreen: {
      type: 'object',
      title: '#48 Early Check-in screen',
      properties: {
        'headerText:enable': {
          type: 'boolean',
          title: '#1 Header',
          description: '[earlyCheckInScreen.headerText]',
        },
        headerText: {
          type: 'string',
          default: 'You are Early!',
        },
        'subheaderText:enable': {
          type: 'boolean',
          title: '#2 Sub-header',
          description: '[earlyCheckInScreen.subheaderText]',
        },
        subheaderText: {
          type: 'string',
          default: 'Check-in is at [checkInTime]',
        },
        'descriptionText:enable': {
          type: 'boolean',
          title: '#3 Description',
          description: '[earlyCheckInScreen.descriptionText]',
        },
        descriptionText: {
          type: 'string',
          default:
            'Complete your information now and your room will be sent at check-in time or upgrade with early room access now.',
        },
        'declineButtonText:enable': {
          type: 'boolean',
          title: '#4 Decline Button',
          description: '[earlyCheckInScreen.declineButtonText]',
        },
        declineButtonText: {
          type: 'string',
          default: 'Decline offer',
        },
        'card:enable': {
          title: '#5 Localize the package offer per code',
          type: 'boolean',
          description: '[earlyCheckInScreen.card]',
        },
        card: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              code: {
                title: 'Package code',
                type: 'string',
              },
              title: {
                title: 'Title',
                type: 'string',
                default: 'Early Room Access',
              },
              description: {
                title: 'Description',
                type: 'string',
                default: 'Get your room immediately with this exclusive offer.',
              },
            },
            required: ['code', 'title', 'description'],
          },
        },
        'cardAcceptButtonText:enable': {
          type: 'boolean',
          title: '#6 Card Accept Button',
          description: '[earlyCheckInScreen.cardAcceptButtonText]',
        },
        cardAcceptButtonText: {
          type: 'string',
          default: 'Yes, please!',
        },
        'errorHeaderText:enable': {
          type: 'boolean',
          title: '#7 Error header text',
          description: '[earlyCheckInScreen.errorHeaderText]',
        },
        errorHeaderText: {
          type: 'string',
        },
        'errorDescriptionText:enable': {
          type: 'boolean',
          title: '#8 Error description text',
          description: '[earlyCheckInScreen.errorDescriptionText]',
        },
        errorDescriptionText: {
          type: 'string',
        },
        'errorButtonText:enable': {
          type: 'boolean',
          title: '#9 Error button text',
          description: '[earlyCheckInScreen.errorButtonText]',
        },
        errorButtonText: {
          type: 'string',
        },
      },
    },
  },
};

export const kioskLocalizationUiSchema = {
  error: {
    'ui:title': '‎',
    cardPrintingError: {
      'ui:label': false,
      'ui:readonly': true,
    },
    noEmailFoundHeader: {
      'ui:label': false,
      'ui:readonly': true,
    },
    resourceNotFoundHeader: {
      'ui:label': false,
      'ui:readonly': true,
    },
    noEmailFoundButtonLabel: {
      'ui:label': false,
      'ui:readonly': true,
    },
    noEmailFoundDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    noPhoneNumberFoundHeader: {
      'ui:label': false,
      'ui:readonly': true,
    },
    noCheckInAccessGrantHeader: {
      'ui:label': false,
      'ui:readonly': true,
    },
    noCheckOutAccessGrantHeader: {
      'ui:label': false,
      'ui:readonly': true,
    },
    resourceNotFoundButtonLabel: {
      'ui:label': false,
      'ui:readonly': true,
    },
    resourceNotFoundDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    noPhoneNumberFoundButtonLabel: {
      'ui:label': false,
      'ui:readonly': true,
    },
    noPhoneNumberFoundDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    noCheckInAccessGrantButtonLabel: {
      'ui:label': false,
      'ui:readonly': true,
    },
    noCheckInAccessGrantDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    noCheckOutAccessGrantButtonLabel: {
      'ui:label': false,
      'ui:readonly': true,
    },
    noCheckOutAccessGrantDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    userNotFoundHeader: {
      'ui:label': false,
      'ui:readonly': true,
    },
    userNotFoundDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    userNotFoundButtonLabel: {
      'ui:label': false,
      'ui:readonly': true,
    },
    maximumCardsIssuedError: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  CollectUserInfoScreen: {
    'ui:title': '‎',
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    subtitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  general: {
    'ui:title': '‎',
    second: {
      'ui:label': false,
      'ui:readonly': true,
    },
    seconds: {
      'ui:label': false,
      'ui:readonly': true,
    },
    qrButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    backButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    cameraText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    doneButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    nameButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    clearButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    emailButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    phoneButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    replayButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    updateButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    restartButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    continueButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    validateButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    codePlaceholder: {
      'ui:label': false,
      'ui:readonly': true,
    },
    talkToAgentText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    emailPlaceholder: {
      'ui:label': false,
      'ui:readonly': true,
    },
    resendCodeButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    tryAnotherOption: {
      'ui:label': false,
      'ui:readonly': true,
    },
    qrCodeDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    joinedLoyaltyTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    fullNamePlaceholder: {
      'ui:label': false,
      'ui:readonly': true,
    },
    // humanReadableErrors: [
    //   {
    //     code: 'REDIRECT_GUEST_TO_HELP_DESK',
    //     message:
    // eslint-disable-next-line max-len
    //       'There was a problem checking you out. Please see a guest service agent at the reception desk.',
    //   },
    //   {
    //     code: 'AGE_VERIFICATION_FAILED',
    //     message: 'There was an issue verifying your age',
    //   },
    // ],
    lastNamePlaceholder: {
      'ui:label': false,
      'ui:readonly': true,
    },
    firstNamePlaceholder: {
      'ui:label': false,
      'ui:readonly': true,
    },
    idVerificationButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    companyNamePlaceholder: {
      'ui:label': false,
      'ui:readonly': true,
    },
    phoneNumberPlaceholder: {
      'ui:label': false,
      'ui:readonly': true,
    },
    remoteAssistanceButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    joinedLoyaltyDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    confirmationNumberPlaceholder: {
      'ui:label': false,
      'ui:readonly': true,
    },
    idleCountDownTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    idleCountDownPositiveButtonText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    addButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    addSecondaryUserButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    departureDatePlaceholder: {
      'ui:label': false,
      'ui:readonly': true,
    },
    loading: {
      'ui:label': false,
      'ui:readonly': true,
    },
    unhandledExceptionTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    unhandledExceptionMessage: {
      'ui:label': false,
      'ui:readonly': true,
    },
    unhandledExceptionRestartButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    humanReadableErrors: {
      'ui:label': false,
      'ui:title': '‎',
      'ui:readonly': true,
      items: {
        code: {},
        message: {},
      },
    },
    checkInDatePlaceholder: {
      'ui:label': false,
      'ui:readonly': true,
    },
    checkOutDatePlaceholder: {
      'ui:label': false,
      'ui:readonly': true,
    },
    room: {
      'ui:label': false,
      'ui:readonly': true,
    },
    floorPrefix: {
      'ui:label': false,
      'ui:readonly': true,
    },
    floor: {
      'ui:label': false,
      'ui:readonly': true,
    },
    floorSuffix: {
      'ui:label': false,
      'ui:readonly': true,
    },
    buildingPrefix: {
      'ui:label': false,
      'ui:readonly': true,
    },
    building: {
      'ui:label': false,
      'ui:readonly': true,
    },
    buildingSuffix: {
      'ui:label': false,
      'ui:readonly': true,
    },
    packageSuccess: {
      'ui:label': false,
      'ui:readonly': true,
    },
    tryAnotherOptionCheckInFlow: {
      'ui:label': false,
      'ui:readonly': true,
    },
    tryAnotherOptionCheckOutFlow: {
      'ui:label': false,
      'ui:readonly': true,
    },
    frontCameraText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    disableSingleAppMode: {
      'ui:label': false,
      'ui:readonly': true,
    },
    enableSingleAppMode: {
      'ui:label': false,
      'ui:readonly': true,
    },
    singleAppModeOnAlert: {
      'ui:label': false,
      'ui:readonly': true,
    },
    singleAppModeOffAlert: {
      'ui:label': false,
      'ui:readonly': true,
    },
    singleAppModeOnFailedAlert: {
      'ui:label': false,
      'ui:readonly': true,
    },
    singleAppModeOffFailedAlert: {
      'ui:label': false,
      'ui:readonly': true,
    },
    retryButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    okLabel: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  homeScreen: {
    'ui:title': '‎',
    button: {
      'ui:label': false,
      'ui:readonly': true,
    },
    welcomeText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    // welcomeBullets: ['Welcome to the hotel.', 'Please select from the options below.'],
    checkoutTextButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    printAnotherKeycardTextButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    folioTextButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    description: {
      'ui:label': false,
      'ui:readonly': true,
    },
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    header: {
      'ui:label': false,
      'ui:readonly': true,
    },
    conciergeTextButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    instructionText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    idcard_title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    idcard_desc: {
      'ui:label': false,
      'ui:readonly': true,
    },
    credit_title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    credit_desc: {
      'ui:label': false,
      'ui:readonly': true,
    },
    welcomeBullets: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  vettingScreen: {
    'ui:title': '‎',
    headerText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    errorHeaderText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    errorDescriptionText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    remoteAssistanceButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    questions: {
      'ui:label': false,
      'ui:title': '‎',
      'ui:readonly': true,
      items: {
        position: {},
        question: {},
      },
    },
  },
  loyaltyScreen: {
    'ui:title': '‎',
    header: {
      'ui:label': false,
      'ui:readonly': true,
    },
    joinButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    skipButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    joinLoyaltyFailed: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  paymentScreen: {
    'ui:title': '‎',
    header: {
      'ui:label': false,
      'ui:readonly': true,
    },
    description: {
      'ui:label': false,
      'ui:readonly': true,
    },
    primaryButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    authorizationTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    captureHeader: {
      'ui:label': false,
      'ui:readonly': true,
    },
    captureDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    captureTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    pay: {
      'ui:label': false,
      'ui:readonly': true,
    },
    addCardOverlay: {
      startButton: {
        'ui:label': false,
        'ui:readonly': true,
      },
      startHeader: {
        'ui:label': false,
        'ui:readonly': true,
      },
      progressingHeader: {
        'ui:label': false,
        'ui:readonly': true,
      },
      successHeader: {
        'ui:label': false,
        'ui:readonly': true,
      },
      collectingInformation: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
  },
  checkoutScreen: {
    'ui:title': '‎',
    header: {
      'ui:label': false,
      'ui:readonly': true,
    },
    checkoutFailed: {
      'ui:label': false,
      'ui:readonly': true,
    },
    useDefaultCard: {
      'ui:label': false,
      'ui:readonly': true,
    },
    useDifferentCard: {
      'ui:label': false,
      'ui:readonly': true,
    },
    checkoutUnavailable: {
      'ui:label': false,
      'ui:readonly': true,
    },
    checkoutSuccessfully: {
      'ui:label': false,
      'ui:readonly': true,
    },
    folioHeader: {
      'ui:label': false,
      'ui:readonly': true,
    },
    total: {
      'ui:label': false,
      'ui:readonly': true,
    },
    description: {
      'ui:label': false,
      'ui:readonly': true,
    },
    done: {
      'ui:label': false,
      'ui:readonly': true,
    },
    paymentInfoHeader: {
      'ui:label': false,
      'ui:readonly': true,
    },
    checkoutButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    reservationDetail: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  signatureScreen: {
    'ui:title': '‎',
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    header: {
      'ui:label': false,
      'ui:readonly': true,
    },
    description: {
      'ui:label': false,
      'ui:readonly': true,
    },
    termsAndConditionsButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    signDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    signTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    agreementTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    agreements: {
      'ui:label': false,
      'ui:title': '‎',
      'ui:readonly': true,
      items: {
        'ui:label': false,
      },
    },
    submit: {
      'ui:label': false,
      'ui:readonly': true,
    },
    requiredFieldError: {
      'ui:label': false,
      'ui:readonly': true,
    },
    updateRequirementFailed: {
      'ui:label': false,
      'ui:readonly': true,
    },
    uploadRegcardError: {
      'ui:label': false,
      'ui:readonly': true,
    },
    uploadSignatureError: {
      'ui:label': false,
      'ui:readonly': true,
    },
    downloadLeaseError: {
      'ui:label': false,
      'ui:readonly': true,
    },
    tncCloseButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    agreementsWithOptions: {
      'ui:label': false,
      'ui:title': '‎',
      'ui:readonly': true,
      items: {
        id: {
          'ui:widget': 'CustomAutoGeneratedId',
        },
        title: {},
        message: {},
        options: {
          isOptional: {
            'ui:label': true,
            'ui:widget': 'select',
          },
        },
      },
    },
  },
  authSelectScreen: {
    'ui:title': '‎',
    name: {
      'ui:label': false,
      'ui:readonly': true,
    },
    email: {
      'ui:label': false,
      'ui:readonly': true,
    },
    header: {
      'ui:label': false,
      'ui:readonly': true,
    },
    qrCode: {
      'ui:label': false,
      'ui:readonly': true,
    },
    phoneNumber: {
      'ui:label': false,
      'ui:readonly': true,
    },
    checkoutHeader: {
      'ui:label': false,
      'ui:readonly': true,
    },
    printAnotherKeycardHeader: {
      'ui:label': false,
      'ui:readonly': true,
    },
    idVerification: {
      'ui:label': false,
      'ui:readonly': true,
    },
    idAuthentication: {
      'ui:label': false,
      'ui:readonly': true,
    },
    room: {
      'ui:label': false,
      'ui:readonly': true,
    },
    folioheader: {
      'ui:label': false,
      'ui:readonly': true,
    },
    nameAndDepartureDate: {
      'ui:label': false,
      'ui:readonly': true,
    },
    confirmationCode: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  scanQRCodeScreen: {
    'ui:title': '‎',
    header: {
      'ui:label': false,
      'ui:readonly': true,
    },
    retakeButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    pastReservationHeader: {
      'ui:label': false,
      'ui:readonly': true,
    },
    notTodayReservationHeader: {
      'ui:label': false,
      'ui:readonly': true,
    },
    pastReservationButtonLabel: {
      'ui:label': false,
      'ui:readonly': true,
    },
    pastReservationDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    notTodayReservationButtonLabel: {
      'ui:label': false,
      'ui:readonly': true,
    },
    notTodayReservationDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    resourceNotFoundHeader: {
      'ui:label': false,
      'ui:readonly': true,
    },
    resourceNotFoundDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    resourceNotFoundButtonLabel: {
      'ui:label': false,
      'ui:readonly': true,
    },
    fallbackErrorMessage: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  requirementsScreen: {
    'ui:title': '‎',
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    header: {
      'ui:label': false,
      'ui:readonly': true,
    },
    idVerification: {
      'ui:label': false,
      'ui:readonly': true,
    },
    paymentStatus: {
      'ui:label': false,
      'ui:readonly': true,
    },
    paymentAuthorization: {
      'ui:label': false,
      'ui:readonly': true,
    },
    termsAndConditions: {
      'ui:label': false,
      'ui:readonly': true,
    },
    paymentCapture: {
      'ui:label': false,
      'ui:readonly': true,
    },
    mrzIdVerification: {
      'ui:label': false,
      'ui:readonly': true,
    },
    onRequirementBackButton: {
      popupTitle: {
        'ui:label': false,
        'ui:readonly': true,
      },
      popupNegativeButton: {
        'ui:label': false,
        'ui:readonly': true,
      },
      popupPositiveButton: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    userInformation: {
      'ui:label': false,
      'ui:readonly': true,
    },
    nameMatches: {
      'ui:label': false,
      'ui:readonly': true,
    },
    pmsConfirmation: {
      'ui:label': false,
      'ui:readonly': true,
    },
    healthCheck: {
      'ui:label': false,
      'ui:readonly': true,
    },
    manualCheckIn: {
      'ui:label': false,
      'ui:readonly': true,
    },
    businessIdVerification: {
      'ui:label': false,
      'ui:readonly': true,
    },
    requirementFailedTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    contactFrontDesk: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  keyDispensingScreen: {
    'ui:title': '‎',
    info: {
      'ui:label': false,
      'ui:readonly': true,
    },
    header: {
      'ui:label': false,
      'ui:readonly': true,
    },
    noButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    codeTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    helpTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    keycardHeader: {
      'ui:label': false,
      'ui:readonly': true,
    },
    codeDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    helpDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    printingKeyCard: {
      'ui:label': false,
      'ui:readonly': true,
    },
    anotherKeyCardText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    expiresPlaceholder: {
      'ui:label': false,
      'ui:readonly': true,
    },
    keyCardDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    printKeyCardButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    cardHolderPlaceholder: {
      'ui:label': false,
      'ui:readonly': true,
    },
    codeHolderPlaceholder: {
      'ui:label': false,
      'ui:readonly': true,
    },
    codeInstructionMessage: {
      'ui:label': false,
      'ui:readonly': true,
    },
    keycardInstructionMessage: {
      'ui:label': false,
      'ui:readonly': true,
    },
    qrScan: {
      'ui:label': false,
      'ui:readonly': true,
    },
    qrDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    qrHeader: {
      'ui:label': false,
      'ui:readonly': true,
    },
    additionalTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    additionalDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    mobileWebTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    mobileWebDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    deeplinkTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    deeplinkDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    description: {
      'ui:label': false,
      'ui:readonly': true,
    },
    roomTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    doneButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    maxKeyLimitTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    animatedKeyCard: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      description: {
        'ui:label': false,
        'ui:readonly': true,
      },
      footer: {
        'ui:label': false,
        'ui:readonly': true,
      },
      optionalTitle: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    keyCardPrintSuccess: {
      headerText: {
        'ui:label': false,
        'ui:readonly': true,
      },
      subHeaderText: {
        'ui:label': false,
        'ui:readonly': true,
      },
      printExtraKeyCard: {
        buttonText: {
          'ui:label': false,
          'ui:readonly': true,
        },
        optionalText: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
      asideLayoutGridConfig: {
        topGridTitle: {
          'ui:label': false,
          'ui:readonly': true,
        },
        bottomGridTitle: {
          'ui:label': false,
          'ui:readonly': true,
        },
        topGridHeader: {
          'ui:label': false,
          'ui:readonly': true,
        },
        bottomGridHeader: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
    },
    keyCardPrintError: {
      headerText: {
        'ui:label': false,
        'ui:readonly': true,
      },
      descriptionText: {
        'ui:label': false,
        'ui:readonly': true,
      },
      message: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
  },
  obfuscateAuthScreen: {
    'ui:title': '‎',
    header: {
      'ui:label': false,
      'ui:readonly': true,
    },
    cardTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    emailButtonLabel: {
      'ui:label': false,
      'ui:readonly': true,
    },
    cardOtherAuthTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    phoneNumberButtonLabel: {
      'ui:label': false,
      'ui:readonly': true,
    },
    idVerificationButtonLabel: {
      'ui:label': false,
      'ui:readonly': true,
    },
    remoteAssistanceButtonLabel: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  idVerificationScreen: {
    'ui:title': '‎',
    retry: {
      'ui:label': false,
      'ui:readonly': true,
    },
    header: {
      'ui:label': false,
      'ui:readonly': true,
    },
    backText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    titleText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    titleAlert: {
      'ui:label': false,
      'ui:readonly': true,
    },
    description: {
      'ui:label': false,
      'ui:readonly': true,
    },
    loginHeader: {
      'ui:label': false,
      'ui:readonly': true,
    },
    uploadTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    backSideText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    faceSideText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    frontSideText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    visaTitleText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    nextButtonText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    uploadingTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    idCardTitleText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    textButtonAlert: {
      'ui:label': false,
      'ui:readonly': true,
    },
    consentTitleText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    countryTitleText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    descriptionAlert: {
      'ui:label': false,
      'ui:readonly': true,
    },
    headerResultText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    retakeButtonText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    driverLicenseText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    passportTitleText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    uploadDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    uploadFailedTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    selectCountryTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    uploadSuccessTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    backInstructionText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    faceInstructionText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    frontInstructionText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    scanYourPassportText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    uploadingDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    driverLicenseTitleText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    flipDocumentAlertTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    shutterLabelButtonText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    passportInstructionText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    uploadSuccessDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    idCardBackInstructionText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    backCaptureInstructionText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    faceCaptureInstructionText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    idCardFrontInstructionText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    passportPreInstructionText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    faceVerificationInstruction: {
      'ui:label': false,
      'ui:readonly': true,
    },
    frontCaptureInstructionText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    flipDocumentAlertButtonLabel: {
      'ui:label': false,
      'ui:readonly': true,
    },
    flipDocumentAlertDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    passportCaptureInstructionText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    documentVerificationInstruction: {
      'ui:label': false,
      'ui:readonly': true,
    },
    passportVerificationInstruction: {
      'ui:label': false,
      'ui:readonly': true,
    },
    driverLicenseBackInstructionText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    driverLicenseFrontInstructionText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    instructionText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    instruction_ID: {
      'ui:label': false,
      'ui:readonly': true,
    },
    instruction_AGE: {
      'ui:label': false,
      'ui:readonly': true,
    },
    instruction_NAME: {
      'ui:label': false,
      'ui:readonly': true,
    },
    uploadFailedDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    idleCountDownTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    idleCountDownPositiveButtonText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    idleCountDownNegativeButtonText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    consentText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    privacyText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    continueButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    privacyPolicyHeader: {
      'ui:label': false,
      'ui:readonly': true,
    },
    faceNotFullyVisibleAlertTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    faceNotFullyVisibleAlertDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    faceNotFullyVisibleAlertButtonLabel: {
      'ui:label': false,
      'ui:readonly': true,
    },
    faceNotDetectedErrorText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    faceNotFullyVisibleErrorText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    faceNotCenteredErrorText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    backAlertText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    backAlertNegativeButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    backAlertPositiveButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    processingText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    retryPopupTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    retryPopupCancelButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    retryPopupRestartButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    retryPopupSelfieButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  specialRequestScreen: {
    'ui:title': '‎',
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    header: {
      'ui:label': false,
      'ui:readonly': true,
    },
    description: {
      'ui:label': false,
      'ui:readonly': true,
    },
    textInputPlaceholder: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  userInformationScreen: {
    'ui:title': '‎',
    cost: {
      'ui:label': false,
      'ui:readonly': true,
    },
    header: {
      'ui:label': false,
      'ui:readonly': true,
    },
    checkIn: {
      'ui:label': false,
      'ui:readonly': true,
    },
    checkOut: {
      'ui:label': false,
      'ui:readonly': true,
    },
    roomType: {
      'ui:label': false,
      'ui:readonly': true,
    },
    roomNumber: {
      'ui:label': false,
      'ui:readonly': true,
    },
    contactInformation: {
      'ui:label': false,
      'ui:readonly': true,
    },
    updateSuccessfullyText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    emailUpdateRequired: {
      'ui:label': false,
      'ui:readonly': true,
    },
    headerDetails: {
      'ui:label': false,
      'ui:readonly': true,
    },
    requireNameErrorText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    requireEmailErrorText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    invalidEmailErrorText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    requirePhoneErrorText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    invalidPhoneErrorText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    addUserHeader: {
      'ui:label': false,
      'ui:readonly': true,
    },
    addUserSubtitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    updateRequirementFailed: {
      'ui:label': false,
      'ui:readonly': true,
    },
    updateDynamicInfoError: {
      'ui:label': false,
      'ui:readonly': true,
    },
    emailOrPhoneNumberRequiredError: {
      'ui:label': false,
      'ui:readonly': true,
    },
    errorPopupTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    errorPopupFallbackMessage: {
      'ui:label': false,
      'ui:readonly': true,
    },
    errorPopupButtonText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    firstNameEmptyError: {
      'ui:label': false,
      'ui:readonly': true,
    },
    lastNameEmptyError: {
      'ui:label': false,
      'ui:readonly': true,
    },
    emailEmptyError: {
      'ui:label': false,
      'ui:readonly': true,
    },
    emailInvalidFormatError: {
      'ui:label': false,
      'ui:readonly': true,
    },
    phoneEmptyError: {
      'ui:label': false,
      'ui:readonly': true,
    },
    phoneInvalidFormatError: {
      'ui:label': false,
      'ui:readonly': true,
    },
    editUser: {
      disableFirstName: {
        'ui:label': false,
        'ui:readonly': true,
      },
      disableLastName: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
  },
  nameVerificationScreen: {
    'ui:title': '‎',
    header: {
      'ui:label': false,
      'ui:readonly': true,
    },
    userNotFound: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  remoteAssistanceScreen: {
    'ui:title': '‎',
    header: {
      'ui:label': false,
      'ui:readonly': true,
    },
    videoCall: {
      'ui:label': false,
      'ui:readonly': true,
    },
    voiceCall: {
      'ui:label': false,
      'ui:readonly': true,
    },
    description: {
      'ui:label': false,
      'ui:readonly': true,
    },
    noOneAnsweredModal: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      description: {
        'ui:label': false,
        'ui:readonly': true,
      },
      submitButton: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
  },
  emailVerificationScreen: {
    'ui:title': '‎',
    header: {
      'ui:label': false,
      'ui:readonly': true,
    },
    emailNotFound: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  phoneVerificationScreen: {
    'ui:title': '‎',
    header: {
      'ui:label': false,
      'ui:readonly': true,
    },
    phoneNotFound: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  reservationStatusScreen: {
    'ui:title': '‎',
    helpButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    noRoomTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    noRoomHeader: {
      'ui:label': false,
      'ui:readonly': true,
    },
    noRoomDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    noReservationTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    noReservationHeader: {
      'ui:label': false,
      'ui:readonly': true,
    },
    todayReservationTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    futureReservationTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    todayReservationHeader: {
      'ui:label': false,
      'ui:readonly': true,
    },
    futureReservationHeader: {
      'ui:label': false,
      'ui:readonly': true,
    },
    invalidReservationHeader: {
      'ui:label': false,
      'ui:readonly': true,
    },
    noReservationDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    todayReservationDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    futureReservationDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    qrCodeTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    invalidReservationTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    invalidReservationDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    roomIsNotReadyHeader: {
      'ui:label': false,
      'ui:readonly': true,
    },
    roomIsNotReadyTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    roomIsNotReadyDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    roomNotAssignedHeader: {
      'ui:label': false,
      'ui:readonly': true,
    },
    roomNotAssignedTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    roomNotAssignedDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    roomAheadOfTimeHeader: {
      'ui:label': false,
      'ui:readonly': true,
    },
    roomAheadOfTimeTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    roomAheadOfTimeDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  selectReservationScreen: {
    'ui:title': '‎',
    header: {
      'ui:label': false,
      'ui:readonly': true,
    },
    checkin: {
      'ui:label': false,
      'ui:readonly': true,
    },
    checkout: {
      'ui:label': false,
      'ui:readonly': true,
    },
    okButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    cardRoomTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    checkoutHeader: {
      'ui:label': false,
      'ui:readonly': true,
    },
    noRoomAssigned: {
      'ui:label': false,
      'ui:readonly': true,
    },
    failedToCheckin: {
      'ui:label': false,
      'ui:readonly': true,
    },
    cardReservationTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    contactRemoteAssistanceButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    remoteAssistancePopup: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      description: {
        'ui:label': false,
        'ui:readonly': true,
      },
      okButton: {
        'ui:label': false,
        'ui:readonly': true,
      },
      cancelButton: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    checkInEmptyReservation: {
      'ui:label': false,
      'ui:readonly': true,
    },
    checkOutEmptyReservation: {
      'ui:label': false,
      'ui:readonly': true,
    },
    folioEmptyReservation: {
      'ui:label': false,
      'ui:readonly': true,
    },
    printAnotherKeyEmptyReservation: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  validateEmailCodeScreen: {
    'ui:title': '‎',
    header: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  validatePhoneCodeScreen: {
    'ui:title': '‎',
    header: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  confirmationNumberScreen: {
    'ui:title': '‎',
    header: {
      'ui:label': false,
      'ui:readonly': true,
    },
    confirmationNumberNotFound: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  remoteAssistanceComponent: {
    'ui:title': '‎',
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    subtitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  paymentAuthorizationScreen: {
    'ui:title': '‎',
    header: {
      'ui:label': false,
      'ui:readonly': true,
    },
    description: {
      'ui:label': false,
      'ui:readonly': true,
    },
    swipeCardTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    confirmAuthorizedAmountTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    issueConnectingTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    issueConnectingDescriptionCheckIn: {
      'ui:label': false,
      'ui:readonly': true,
    },
    issueConnectingDescriptionCheckOut: {
      'ui:label': false,
      'ui:readonly': true,
    },
    tokenizeConcardisError: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  verifyingRequirementsScreen: {
    'ui:title': '‎',
    contactAgent: {
      'ui:label': false,
      'ui:readonly': true,
    },
    failedToVerify: {
      'ui:label': false,
      'ui:readonly': true,
    },
    uploadingDocument: {
      'ui:label': false,
      'ui:readonly': true,
    },
    checkRequirementTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    checkRequirementDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    idVerificationMaxRetriesErrorTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    idVerificationMaxRetriesErrorMessage: {
      'ui:label': false,
      'ui:readonly': true,
    },
    idVerificationMaxRetriesErrorButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  waitingIDVerificationScreen: {
    'ui:title': '‎',
    header: {
      'ui:label': false,
      'ui:readonly': true,
    },
    description: {
      'ui:label': false,
      'ui:readonly': true,
    },
    userNotFound: {
      'ui:label': false,
      'ui:readonly': true,
    },
    uploadDocument: {
      'ui:label': false,
      'ui:readonly': true,
    },
    dontHaveAnyStays: {
      'ui:label': false,
      'ui:readonly': true,
    },
    idVerificationFailed: {
      'ui:label': false,
      'ui:readonly': true,
    },
    idVerificationFailedWithStatus: {
      'ui:label': false,
      'ui:readonly': true,
    },
    noAvailableCheckOutReservation: {
      'ui:label': false,
      'ui:readonly': true,
    },
    idVerificationRejectedReason: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  packageScreen: {
    'ui:title': '‎',
    errorUpdate: {
      'ui:label': false,
      'ui:readonly': true,
    },
    successfulAdd: {
      'ui:label': false,
      'ui:readonly': true,
    },
    closeCart: {
      'ui:label': false,
      'ui:readonly': true,
    },
    emptyPrebook: {
      'ui:label': false,
      'ui:readonly': true,
    },
    singlePrebook: {
      'ui:label': false,
      'ui:readonly': true,
    },
    submit: {
      'ui:label': false,
      'ui:readonly': true,
    },
    viewCart: {
      'ui:label': false,
      'ui:readonly': true,
    },
    total: {
      'ui:label': false,
      'ui:readonly': true,
    },
    cart: {
      'ui:label': false,
      'ui:readonly': true,
    },
    addModal: {
      'ui:label': false,
      'ui:readonly': true,
    },
    booked: {
      'ui:label': false,
      'ui:readonly': true,
    },
    add: {
      'ui:label': false,
      'ui:readonly': true,
    },
    packages: {
      'ui:label': false,
      'ui:readonly': true,
    },
    seePackageText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    subtitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    prebookHeader: {
      'ui:label': false,
      'ui:readonly': true,
    },
    addMoreCartLabel: {
      'ui:label': false,
      'ui:readonly': true,
    },
    addCartLabel: {
      'ui:label': false,
      'ui:readonly': true,
    },
    addedMessage: {
      'ui:label': false,
      'ui:readonly': true,
    },
    close: {
      'ui:label': false,
      'ui:readonly': true,
    },
    include: {
      'ui:label': false,
      'ui:readonly': true,
    },
    loadingTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    loadingSubtitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    category: {
      'ui:label': false,
      'ui:readonly': true,
    },
    cannotRemovePrebookedPackage: {
      'ui:label': false,
      'ui:readonly': true,
    },
    reachMaximumAvailableQuantity: {
      'ui:label': false,
      'ui:readonly': true,
    },
    prebookedAmountText: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  checkInFailScreen: {
    'ui:title': '‎',
    header: {
      'ui:label': false,
      'ui:readonly': true,
    },
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    description: {
      'ui:label': false,
      'ui:readonly': true,
    },
    helpButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    endSessionButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    retryPaymentButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  conciergeScreen: {
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    description: {
      'ui:label': false,
      'ui:readonly': true,
    },
    backText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    popupCloseButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    information: {
      'ui:label': false,
      'ui:readonly': true,
    },
    moreInfoButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  mrzIdVerificationScreen: {
    instructionText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    instructionText2: {
      'ui:label': false,
      'ui:readonly': true,
    },
    instructionImageTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    instructionImageTitle2: {
      'ui:label': false,
      'ui:readonly': true,
    },
    backLabel: {
      'ui:label': false,
      'ui:readonly': true,
    },
    cameraText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    scanInstructionText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    headerText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    initScreenHeader: {
      'ui:label': false,
      'ui:readonly': true,
    },
    initScreenInstructionText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    instruction_ID: {
      'ui:label': false,
      'ui:readonly': true,
    },
    instruction_AGE: {
      'ui:label': false,
      'ui:readonly': true,
    },
    instruction_NAME: {
      'ui:label': false,
      'ui:readonly': true,
    },
    initScreenDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    popupCloseButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    popupDefaultErrorHeaderText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    backAlert: {
      'ui:label': false,
      'ui:readonly': true,
    },
    remoteAssistanceAlert: {
      'ui:label': false,
      'ui:readonly': true,
    },
    homeAlert: {
      'ui:label': false,
      'ui:readonly': true,
    },
    backAlertPositiveButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    remoteAssistanceAlertPositiveButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    homeAlertPositiveButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    backAlertNegativeButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    remoteAssistanceAlertNegativeButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    homeAlertNegativeButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    instruction: {
      'ui:label': false,
      'ui:readonly': true,
    },
    feedback: {
      'ui:label': false,
      'ui:readonly': true,
    },
    helpButtonTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    helpModal: {
      'ui:title': 'Help modal',
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      documentNotFound: {
        'ui:title': 'Document not found guide',
        title: {
          'ui:label': false,
          'ui:readonly': true,
        },
        barcodeLocation: {
          'ui:label': false,
          'ui:readonly': true,
        },
        guide: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
      notScanning: {
        'ui:title': 'Not scanning guide',
        title: {
          'ui:label': false,
          'ui:readonly': true,
        },
        guide: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
      legalAndPolice: {
        'ui:title': 'Legal & Police',
        title: {
          'ui:label': false,
          'ui:readonly': true,
        },
        guide: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
    },
  },
  nameAndDepartureDateVerificationScreen: {
    header: {
      'ui:label': false,
      'ui:readonly': true,
    },
    userNotFound: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  confirmationCodeVerificationScreen: {
    header: {
      'ui:label': false,
      'ui:readonly': true,
    },
    confirmationNumberNotFound: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  mrzScannerScreen: {
    instructionText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    instructionText2: {
      'ui:label': false,
      'ui:readonly': true,
    },
    instructionImageTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    instructionImageTitle2: {
      'ui:label': false,
      'ui:readonly': true,
    },
    backLabel: {
      'ui:label': false,
      'ui:readonly': true,
    },
    cameraText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    scanInstructionText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    headerText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    initScreenHeader: {
      'ui:label': false,
      'ui:readonly': true,
    },
    initScreenInstructionText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    instruction_ID: {
      'ui:label': false,
      'ui:readonly': true,
    },
    instruction_AGE: {
      'ui:label': false,
      'ui:readonly': true,
    },
    instruction_NAME: {
      'ui:label': false,
      'ui:readonly': true,
    },
    initScreenDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    popupCloseButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    popupDefaultErrorHeaderText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    backAlert: {
      'ui:label': false,
      'ui:readonly': true,
    },
    remoteAssistanceAlert: {
      'ui:label': false,
      'ui:readonly': true,
    },
    homeAlert: {
      'ui:label': false,
      'ui:readonly': true,
    },
    backAlertPositiveButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    remoteAssistanceAlertPositiveButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    homeAlertPositiveButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    backAlertNegativeButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    remoteAssistanceAlertNegativeButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    homeAlertNegativeButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  wristbandDispensingScreen: {
    'ui:title': '‎',
    mainTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    heroSubtitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    guestsTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    guestsDispensed: {
      'ui:label': false,
      'ui:readonly': true,
    },
    guestSetAccess: {
      'ui:label': false,
      'ui:readonly': true,
    },
    primaryGuest: {
      'ui:label': false,
      'ui:readonly': true,
    },
    secondaryGuest: {
      'ui:label': false,
      'ui:readonly': true,
    },
    enjoyStay: {
      'ui:label': false,
      'ui:readonly': true,
    },
    enjoyStayComplement: {
      'ui:label': false,
      'ui:readonly': true,
    },
    accessTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    roomAccessTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    allowChargingTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    wristbandDispensingInfo: {
      'ui:label': false,
      'ui:readonly': true,
    },
    wristbandCollectInfo: {
      'ui:label': false,
      'ui:readonly': true,
    },
    dispenseWristbandButtonLabel: {
      'ui:label': false,
      'ui:readonly': true,
    },
    wristbandDispensingError: {
      'ui:label': false,
      'ui:readonly': true,
    },
    wristbandDispensingErrorSubtitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    endSessionButtonLabel: {
      'ui:label': false,
      'ui:readonly': true,
    },
    dispenseConfirmationTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    dispensingCancelButtonLabel: {
      'ui:label': false,
      'ui:readonly': true,
    },
    dispensingConfirmButtonLabel: {
      'ui:label': false,
      'ui:readonly': true,
    },
    modalInformationTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    roomAccessDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    allowChargingDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    dispensingWristbandTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    wristbandDispensedTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    logoutModalTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    logoutModalCancelButtonLabel: {
      'ui:label': false,
      'ui:readonly': true,
    },
    logoutModalConfirmButtonLabel: {
      'ui:label': false,
      'ui:readonly': true,
    },
    finalHeroSubtitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    finalMainTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    labelForBlankWristband: {
      'ui:label': false,
      'ui:readonly': true,
    },
    labelForRoomAccessWristband: {
      'ui:label': false,
      'ui:readonly': true,
    },
    labelForSecondOptionWristband: {
      'ui:label': false,
      'ui:readonly': true,
    },
    optionalWristbandGuestDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    voidWristbandModalHeader: {
      'ui:label': false,
      'ui:readonly': true,
    },
    voidWristbandModalMessage: {
      'ui:label': false,
      'ui:readonly': true,
    },
    voidWristbandModalDiscardAndReissueButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    labelForVoidWristband: {
      'ui:label': false,
      'ui:readonly': true,
    },
    voidWristbandTimeOutMessage: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  finalScreen: {
    'ui:title': '‎',
    header: {
      'ui:label': false,
      'ui:readonly': true,
    },
    needVoucherRoomIsReadyTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    needVoucherRoomIsReadyDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    notNeedVoucherRoomIsReadyTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    notNeedVoucherRoomIsReadyDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    needVoucherRoomIsNotReadyTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    needVoucherRoomIsNotReadyDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    notNeedVoucherRoomIsNotReadyTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    notNeedVoucherRoomIsNotReadyDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    endSessionButtonLabel: {
      'ui:label': false,
      'ui:readonly': true,
    },
    finalScreenImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  simplifiedIdVerification: {
    'ui:title': '‎',
    config: {
      authentication: {
        documentSelection: {
          header: {
            'ui:label': false,
            'ui:readonly': true,
          },
          popupDefaultErrorHeaderText: {
            'ui:label': false,
            'ui:readonly': true,
          },
          popupCloseButton: {
            'ui:label': false,
            'ui:readonly': true,
          },
          initScreenHeader: {
            'ui:label': false,
            'ui:readonly': true,
          },
          initScreenInstructionText: {
            'ui:label': false,
            'ui:readonly': true,
          },
          instruction_ID: {
            'ui:label': false,
            'ui:readonly': true,
          },
          instruction_AGE: {
            'ui:label': false,
            'ui:readonly': true,
          },
          instruction_NAME: {
            'ui:label': false,
            'ui:readonly': true,
          },
          initScreenDescription: {
            'ui:label': false,
            'ui:readonly': true,
          },
          documentsConfig: {
            'ui:label': false,
            'ui:title': '‎',
            'ui:readonly': true,
            items: {
              scannerType: {
                'ui:label': true,
                'ui:widget': 'select',
              },
              documentType: {
                'ui:label': true,
                'ui:widget': 'select',
              },
              documentBoxText: {},
              scannerConfig: {
                instructionText: {},
                instructionText2: {},
                instructionImageTitle: {},
                instructionImageTitle2: {},
                backLabel: {},
                cameraText: {},
                scanInstructionText: {},
                headerText: {},
                initScreenHeader: {},
                initScreenInstructionText: {},
                instruction_ID: {},
                instruction_AGE: {},
                instruction_NAME: {},
                initScreenDescription: {},
                popupCloseButton: {},
                popupDefaultErrorHeaderText: {},
                backAlert: {},
                remoteAssistanceAlert: {},
                homeAlert: {},
                backAlertPositiveButton: {},
                remoteAssistanceAlertPositiveButton: {},
                homeAlertPositiveButton: {},
                backAlertNegativeButton: {},
                remoteAssistanceAlertNegativeButton: {},
                homeAlertNegativeButton: {},
              },
            },
          },
        },
        fallbackErrors: {
          userNotFound: {
            'ui:label': false,
            'ui:readonly': true,
          },
        },
        humanReadableErrors: {
          'ui:label': false,
          'ui:title': '‎',
          'ui:readonly': true,
          items: {
            code: {},
            message: {},
          },
        },
      },
      requirement: {
        documentSelection: {
          header: {
            'ui:label': false,
            'ui:readonly': true,
          },
          popupDefaultErrorHeaderText: {
            'ui:label': false,
            'ui:readonly': true,
          },
          popupCloseButton: {
            'ui:label': false,
            'ui:readonly': true,
          },
          initScreenHeader: {
            'ui:label': false,
            'ui:readonly': true,
          },
          initScreenInstructionText: {
            'ui:label': false,
            'ui:readonly': true,
          },
          instruction_ID: {
            'ui:label': false,
            'ui:readonly': true,
          },
          instruction_AGE: {
            'ui:label': false,
            'ui:readonly': true,
          },
          instruction_NAME: {
            'ui:label': false,
            'ui:readonly': true,
          },
          initScreenDescription: {
            'ui:label': false,
            'ui:readonly': true,
          },
          documentsConfig: {
            'ui:label': false,
            'ui:title': '‎',
            'ui:readonly': true,
            items: {
              scannerType: {
                'ui:label': true,
                'ui:widget': 'select',
              },
              documentType: {
                'ui:label': true,
                'ui:widget': 'select',
              },
              documentBoxText: {},
              scannerConfig: {
                instructionText: {},
                instructionText2: {},
                instructionImageTitle: {},
                instructionImageTitle2: {},
                backLabel: {},
                cameraText: {},
                scanInstructionText: {},
                headerText: {},
                initScreenHeader: {},
                initScreenInstructionText: {},
                instruction_ID: {},
                instruction_AGE: {},
                instruction_NAME: {},
                initScreenDescription: {},
                popupCloseButton: {},
                popupDefaultErrorHeaderText: {},
                backAlert: {},
                remoteAssistanceAlert: {},
                homeAlert: {},
                backAlertPositiveButton: {},
                remoteAssistanceAlertPositiveButton: {},
                homeAlertPositiveButton: {},
                backAlertNegativeButton: {},
                remoteAssistanceAlertNegativeButton: {},
                homeAlertNegativeButton: {},
              },
            },
          },
        },
      },
      general: {
        invalidExpireDateErrorHeaderText: {
          'ui:label': false,
          'ui:readonly': true,
        },
        invalidExpireDateErrorDescription: {
          'ui:label': false,
          'ui:readonly': true,
        },
        expiredDocumentErrorHeaderText: {
          'ui:label': false,
          'ui:readonly': true,
        },
        expiredDocumentErrorDescription: {
          'ui:label': false,
          'ui:readonly': true,
        },
        validateDocumentFallbackErrorHeaderText: {
          'ui:label': false,
          'ui:readonly': true,
        },
        validateDocumentFallbackErrorDescription: {
          'ui:label': false,
          'ui:readonly': true,
        },
        confirmationNumberNotFound: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
    },
    humanReadableErrors: {
      'ui:label': false,
      'ui:title': '‎',
      'ui:readonly': true,
      items: {
        code: {},
        message: {},
      },
    },
  },
  failedRequirementsOverlay: {
    'ui:title': '‎',
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    retry: {
      'ui:label': false,
      'ui:readonly': true,
    },
    close: {
      'ui:label': false,
      'ui:readonly': true,
    },
    remoteAssistance: {
      'ui:label': false,
      'ui:readonly': true,
    },
    failedBusinessIdVerification: {
      'ui:label': false,
      'ui:readonly': true,
    },
    failedHealthCheck: {
      'ui:label': false,
      'ui:readonly': true,
    },
    failedIdVerification: {
      'ui:label': false,
      'ui:readonly': true,
    },
    failedPaymentStatus: {
      'ui:label': false,
      'ui:readonly': true,
    },
    failedPMSConfirmation: {
      'ui:label': false,
      'ui:readonly': true,
    },
    failedPaymentAuthorization: {
      'ui:label': false,
      'ui:readonly': true,
    },
    failedTermsAndConditionsConfirmation: {
      'ui:label': false,
      'ui:readonly': true,
    },
    failedMatchNames: {
      'ui:label': false,
      'ui:readonly': true,
    },
    failedRequirementRejectedReason: {
      'ui:label': false,
      'ui:readonly': true,
    },
    failedUserInformation: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  AdditionInfoScreen: {
    'ui:title': '‎',
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    errorRequiredFields: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  ServiceScreen: {
    'ui:title': '‎',
    header: {
      'ui:label': false,
      'ui:readonly': true,
    },
    subheader: {
      'ui:label': false,
      'ui:readonly': true,
    },
    body: {
      'ui:label': false,
      'ui:readonly': true,
    },
    petEnrollFailed: {
      'ui:label': false,
      'ui:readonly': true,
    },
    joinButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    skipButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    confirmButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    cancelButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    confirmationMessage: {
      'ui:label': false,
      'ui:readonly': true,
    },
    acknowledgeButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    acknowledgeMessage: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  RoomNumberAndLastnameAuthScreen: {
    'ui:title': '‎',
    header: {
      'ui:label': false,
      'ui:readonly': true,
    },
    userNotFound: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  noInternetConnectionOverlay: {
    'ui:title': '‎',
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    reconnect: {
      'ui:label': false,
      'ui:readonly': true,
    },
    description: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  faceLivenessScreen: {
    'ui:title': '‎',
    backLabel: {
      'ui:label': false,
      'ui:readonly': true,
    },
    cameraText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    instructionText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    instructionDescriptionText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    backAlert: {
      'ui:label': false,
      'ui:readonly': true,
    },
    remoteAssistanceAlert: {
      'ui:label': false,
      'ui:readonly': true,
    },
    homeAlert: {
      'ui:label': false,
      'ui:readonly': true,
    },
    backAlertPositiveButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    remoteAssistanceAlertPositiveButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    homeAlertPositiveButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    backAlertNegativeButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    remoteAssistanceAlertNegativeButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    homeAlertNegativeButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    headerText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    subheaderText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    startButtonText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    faceMatchFailedDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    retryButtonText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    endSessionButtonText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    faceLivenessFailedDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    rewatchButtonText: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  remoteAssistanceButton: {
    topRightRemoteAssistanceButtonText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    menuRemoteAssistanceButtonText: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  paymentConfig: {
    'ui:title': '‎',
    shift4: {
      errors: {
        'ui:label': false,
        'ui:title': '‎',
        'ui:readonly': true,
        items: {
          code: {},
          message: {},
        },
      },
    },
  },
  earlyCheckInScreen: {
    'ui:title': '‎',
    headerText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    subheaderText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    descriptionText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    declineButtonText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    card: {
      'ui:label': false,
      'ui:title': '‎',
      'ui:readonly': true,
      items: {
        'ui:label': false,
        code: {},
        title: {},
        description: {},
      },
    },
    cardTitleText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    cardDescriptionText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    cardAcceptButtonText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    errorHeaderText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    errorDescriptionText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    errorButtonText: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
};
