import styled, { css } from 'styled-components';
import Theme from '../../../theme';

export const BaseModalFooterContainer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: end;
  height: 50px;
  margin-top: 20px;

  & > :only-child {
    margin: 0 auto;
  }
`;

const Button = css`
  display: flex;
  min-width: 126px;
  height: 60px;
  padding: 16px 40px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 12px;
  border-width: 0px;

  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-feature-settings: 'liga' off, 'clig' off;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const CancelButton = styled.button`
  ${Button}
  background: transparent;
  color: #000;
`;

export const BackButton = styled.button`
  ${Button}
  background: ${Theme.colors.quickActionButton.hexCode};
  color: #fff;

  &:hover {
    background: ${Theme.colors.buttonDominant.hexCode};
  }
`;
