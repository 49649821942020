import { KeyDispensingConfirmationMessageConttainer } from './styles/KioskLiveUsageQuickActionsModal.styles';
import React from 'react';
import { useKioskLiveUsageContext } from '../contexts/KioskLiveUsageContext';

const KeyDispensingConfirmationMessage: React.FC = () => {
  const { quickActionsLiveUsageData } = useKioskLiveUsageContext();

  return (
    <KeyDispensingConfirmationMessageConttainer>
      <p>
        Are you sure you want to dispense{' '}
        <span>{quickActionsLiveUsageData?.accessGrant?.user.name}&apos;s</span> keycard?
      </p>
      <p>Please make sure you or the guest is present in front of the kiosk.</p>
    </KeyDispensingConfirmationMessageConttainer>
  );
};

export default KeyDispensingConfirmationMessage;
