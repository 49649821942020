import { AccessSetupGracePeriodModal, GracePeriodModal } from './GracePeriodModal';
import { AccessSetupSettingsOption, Settings, useSettings } from './Settings';
import { GracePeriod, QueryGetGracePeriodsConfigArgs } from '../../../__generated__/graphql';
import React, { useCallback, useEffect, useState } from 'react';
import {
  closeAccessSetupConfirmationModal,
  closeForm,
} from '../../../redux/actions/modals.actions';
import { ConfirmationModal } from './ConfirmationModal';
import { GracePeriodTime } from './GracePeriodTime';
import { Spacer } from '../styles/Spacer.styles';
import { cloneDeep } from 'lodash';
import { formatTime } from '../../../util/time';
import { useConfirmationModal } from '../hooks/useConfirmationModal';
import { useDispatch } from 'react-redux';
import useSetGracePeriodsData from '../../../hooks/useSetGracePeriodsData';
import { withActionHandler } from '../hocs/withActionHandler';

type EarlyCheckInGracePeriodProps = {
  gracePeriodsData: GracePeriod | undefined;
} & QueryGetGracePeriodsConfigArgs;

export const EarlyCheckInGracePeriod: React.FC<EarlyCheckInGracePeriodProps> = ({
  gracePeriodsData: gracePeriodInitialData,
  locationId,
  tenantId,
  accessGrantId,
  gracePeriodsConfigLevel,
}) => {
  const [gracePeriod, setGracePeriod] = useState(gracePeriodInitialData);
  const { options, updateOption } = useSettings();
  const { openConfirmationModal, modalState } = useConfirmationModal();
  const { setGracePeriodsConfigCallback } = useSetGracePeriodsData({
    accessGrantId,
    locationId,
    gracePeriodsConfigLevel,
    tenantId,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    setGracePeriod(gracePeriodInitialData);
    updateOption(
      'checkInGracePeriodsChecked',
      gracePeriodInitialData?.earlyCheckInConfig.allowEarlyCheckIn ?? false
    );
  }, [gracePeriodInitialData]);

  const updateGracePeriodConfig = useCallback(
    ({
      enabled,
      gracePeriodTimeInMinutes,
    }: {
      enabled: boolean;
      gracePeriodTimeInMinutes?: number;
    }) => {
      if (!gracePeriod) {
        return;
      }

      const newGracePeriods = cloneDeep(gracePeriod);

      newGracePeriods.earlyCheckInConfig.allowEarlyCheckIn = enabled;
      newGracePeriods.earlyCheckInConfig.checkInGracePeriod = gracePeriodTimeInMinutes;

      setGracePeriodsConfigCallback(newGracePeriods);
      setGracePeriod(newGracePeriods);
    },
    [setGracePeriodsConfigCallback, gracePeriod]
  );

  const handleCheckboxClick = useCallback(() => {
    const action = withActionHandler(() => {
      const newState = !options.checkInGracePeriodsChecked.enabled;

      updateOption('checkInGracePeriodsChecked', newState);

      updateGracePeriodConfig({ enabled: newState });

      dispatch(closeAccessSetupConfirmationModal());
      dispatch(closeForm());
    });

    openConfirmationModal({
      id: AccessSetupSettingsOption.checkInGracePeriodsChecked,
      action,
    });
  }, [updateOption, dispatch, options]);

  const handleOnSaveClick = useCallback(
    (timeInMinutes?: number) => {
      const action = withActionHandler(() => {
        updateGracePeriodConfig({
          enabled: options.checkInGracePeriodsChecked.enabled,
          gracePeriodTimeInMinutes: timeInMinutes,
        });

        dispatch(closeAccessSetupConfirmationModal());
        dispatch(closeForm());
      });

      openConfirmationModal({
        id: AccessSetupSettingsOption.checkInGracePeriodsChecked,
        action,
      });
    },
    [dispatch, updateGracePeriodConfig]
  );

  return (
    <Settings.Container
      description="Guests can check-in for free during the grace period, which will apply before the check-in
        time on the reservation."
      title="Check-in Grace Period"
    >
      <Settings.Toogle
        title="Enable Check-in Grace Period"
        onChange={handleCheckboxClick}
        checked={options.checkInGracePeriodsChecked.enabled}
      />
      <GracePeriodTime
        time={formatTime(gracePeriod?.earlyCheckInConfig.checkInGracePeriod)}
        enabled={options.checkInGracePeriodsChecked.enabled}
      />
      <Spacer size="24px" />
      {modalState.accessSetup &&
        modalState.accessSetup.id === AccessSetupSettingsOption.checkInGracePeriodsChecked && (
          <ConfirmationModal
            headerTitle={modalState.accessSetup.title}
            content={modalState.accessSetup.message}
            id={modalState.accessSetup.id}
            onConfirm={modalState.accessSetup.action}
          />
        )}
      {modalState.form.name === AccessSetupGracePeriodModal && (
        <GracePeriodModal
          onSave={handleOnSaveClick}
          timeInMinutes={gracePeriod?.earlyCheckInConfig.checkInGracePeriod as number}
        />
      )}
    </Settings.Container>
  );
};
