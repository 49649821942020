import * as React from 'react';

const RemoteAssistanceLiveUsageTabIcon: React.FC<{ color?: string }> = ({ color }) => {
  const fillColor = React.useMemo(() => color || '#fff', [color]);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path
        fill={fillColor}
        fillRule="evenodd"
        d="M10 15.556a5.556 5.556 0 1 0 0-11.111 5.556 5.556 0 0 0 0 11.11m0 1.389a6.944 6.944 0 1 0 0-13.89 6.944 6.944 0 0 0 0 13.89"
        clipRule="evenodd"
      ></path>
      <path
        fill={fillColor}
        d="M12.778 10a2.778 2.778 0 1 1-5.556 0 2.778 2.778 0 0 1 5.556 0"
      ></path>
    </svg>
  );
};

export default RemoteAssistanceLiveUsageTabIcon;
