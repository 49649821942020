import { Query, QueryGetReservationConfigArgs } from '../__generated__/graphql';
import { GET_RESERVATION_CONFIG } from '../graphql/getReservationConfig';
import { flashApolloError } from '../util/errorUtils';
import { useQuery } from '@apollo/client';

interface UseGetReservationConfigResponse {
  isLoadingReservationConfig: boolean;
  reservationConfig?: Query['getReservationConfig'];
}

type GetReservationConfigResponse = Pick<Query, 'getReservationConfig'>;

export default function useGetReservationConfig({
  locationId,
  tenantId,
}: QueryGetReservationConfigArgs): UseGetReservationConfigResponse {
  const res = useQuery<GetReservationConfigResponse, QueryGetReservationConfigArgs>(
    GET_RESERVATION_CONFIG,
    {
      fetchPolicy: 'network-only',
      onError: flashApolloError,
      variables: {
        locationId,
        tenantId,
      },
    }
  );

  return {
    isLoadingReservationConfig: res.loading,
    reservationConfig: res.data?.getReservationConfig,
  };
}
