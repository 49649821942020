import Theme from '../../../theme';
import styled from 'styled-components';

const Group = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const Label = styled.label<{ selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 400;
  font-weight: ${(selected): string => {
    return selected ? 'bold' : 'normal';
  }};
  color: ${({ selected }): string => {
    return selected ? Theme.colors.quickActionButton.hexCode : Theme.colors.black.hexCode;
  }};
`;

const Input = styled.input<{ selected: boolean }>`
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid
    ${({ selected }): string => {
      return selected ? Theme.colors.quickActionButton.hexCode : Theme.colors.black.hexCode;
    }};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;

  &:checked::before {
    content: '';
    width: 10px;
    height: 10px;
    background-color: ${({ selected }): string => {
      return selected ? Theme.colors.quickActionButton.hexCode : Theme.colors.black.hexCode;
    }};
    border-radius: 50%;
  }
`;

export const Radio = { Group, Label, Input };
