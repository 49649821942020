import React, { useMemo } from 'react';

export const KioskLiveUsageModalHeaderIcon: React.FC<{
  color?: string;
}> = ({ color }) => {
  const fillColor = useMemo(() => color || '#fff', [color]);

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.2254 2C20.1972 2 21 2.74643 21 3.68929V12.8821C21 13.825 20.1972 14.5714 19.2254 14.5714H14.2535V20.4381H16.507H7.49296H9.74648V14.5714H4.77465C3.80282 14.5714 3 13.825 3 12.8821V3.68929C3 2.74643 3.80282 2 4.77465 2H19.2254ZM19.2254 12.8821V3.68929H4.77465V12.8821H19.2254Z"
        fill={fillColor}
      />
      <rect
        x="5.04858"
        y="20.0938"
        width="13.8284"
        height="1.90476"
        rx="0.952381"
        fill={fillColor}
      />
    </svg>
  );
};
