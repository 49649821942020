import { ActionResult } from '../../../../../../../components/QuickActionBar/@types/QuickActionBarTypes';
import { openQuickActionProcessingModal } from '../../../../../../../redux/actions/modals.actions';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useLoadReservationOnKiosk } from './useLoadReservationOnKiosk';
import { useLoadReservationOnKioskModalContext } from '../../contexts/LoadReservationOnKioskModalContext';

type UseLoadReservationOnKioskProcessingType = {
  openLoadReservationOnKioskProcessingModal: () => void;
};

export function useLoadReservationOnKioskProcessing(): UseLoadReservationOnKioskProcessingType {
  const { closeModal, location, accessGrant, kiosk } = useLoadReservationOnKioskModalContext();
  const { loadKioskSession } = useLoadReservationOnKiosk();

  const dispatch = useDispatch();

  const loadKioskSessionAction = useCallback((): Promise<ActionResult> => {
    if (!accessGrant || !kiosk) {
      throw new Error();
    }

    closeModal();

    return loadKioskSession({
      accessGrantId: accessGrant.id,
      kioskId: kiosk.id,
    });
  }, [closeModal, accessGrant, kiosk]);

  const openLoadReservationOnKioskProcessingModal = useCallback(() => {
    dispatch(
      openQuickActionProcessingModal({
        action: loadKioskSessionAction,
        ctaType: 'other',
        modalAlignment: 'center',
        loadingText: 'Loading kiosk...',
        errorHeader: 'Failed to Load Reservation',
      })
    );
  }, [openQuickActionProcessingModal, loadKioskSessionAction, accessGrant, location, kiosk]);

  return {
    openLoadReservationOnKioskProcessingModal,
  };
}
