import React, { createContext, useContext, useState } from 'react';
import { KioskLiveUsageData } from '../../../../../../__generated__/graphql';

interface KioskLiveUsageContextProps {
  isQuickActionsModalOpen: boolean;
  openQuickActionsModal: (kioskLiveUsageData: KioskLiveUsageData) => void;
  closeQuickActionsModal: () => void;
  quickActionsLiveUsageData?: KioskLiveUsageData;
}

const KioskLiveUsageContext = createContext<KioskLiveUsageContextProps | undefined>(undefined);

export const KioskLiveUsageProvider: React.FC = ({ children }) => {
  const [isQuickActionsModalOpen, setIsQuickActionsModalOpen] = useState(false);
  const [quickActionsLiveUsageData, setQuickActionsLiveUsageData] = useState<
    KioskLiveUsageData | undefined
  >();

  const openQuickActionsModal = (kioskData: KioskLiveUsageData): void => {
    setQuickActionsLiveUsageData(kioskData);
    setIsQuickActionsModalOpen(true);
  };
  const closeQuickActionsModal = (): void => setIsQuickActionsModalOpen(false);

  return (
    <KioskLiveUsageContext.Provider
      value={{
        isQuickActionsModalOpen,
        openQuickActionsModal,
        closeQuickActionsModal,
        quickActionsLiveUsageData,
      }}
    >
      {children}
    </KioskLiveUsageContext.Provider>
  );
};

export const useKioskLiveUsageContext = (): KioskLiveUsageContextProps => {
  const context = useContext(KioskLiveUsageContext);

  if (!context) {
    throw new Error('useKioskLiveUsageContext must be used within a KioskLiveUsageProvider');
  }

  return context;
};
