import {
  TableHeader,
  TableRow,
} from '../../../../../../../../components/DataTableV2/DataTableV2.styles';

import React from 'react';
import { SelectReservationHeaderFields } from './types';
import styled from 'styled-components';

export const ReservationTableRow = styled(TableRow)`
  gap: 32px;
`;

export const ReservationTableHeader = styled(TableHeader)`
  gap: 32px;
`;

export const SelectReservationTableSizes: {
  [P in keyof typeof SelectReservationHeaderFields]: number;
} = {
  PrimaryGuest: 24,
  SecondaryGuests: 42,
  RoomNumber: 10,
  CheckIn: 11,
  Arrow: 2,
  CheckOut: 11,
};

export const DefaultFieldsValueStyle: React.CSSProperties = {
  fontWeight: 400,
};

export const PrimaryGuestFieldValueStyle: React.CSSProperties = {
  fontWeight: 600,
};
