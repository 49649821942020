import { Container, Text } from '../styles/EmptyList.styles';
import React from 'react';

interface EmptyListProps {
  title: string;
}

export const EmptyList: React.FC<EmptyListProps> = ({ title }: EmptyListProps) => {
  return (
    <Container>
      <Text>{title}</Text>
    </Container>
  );
};
