import {
  BaseModalHeaderContainer,
  CloseModalButton,
  HeaderIcon,
  HeaderText,
} from './styles/BaseModalHeader.styles';
import { BaseModalHeaderProps } from './@types';
import { MdClose } from 'react-icons/md';
import React from 'react';

const BaseModalHeader: React.FC<BaseModalHeaderProps> = ({ header, onClose }) => {
  return (
    <BaseModalHeaderContainer showIcon={!!header?.icon}>
      {header?.icon && <HeaderIcon>{header.icon}</HeaderIcon>}
      <HeaderText>{header?.title}</HeaderText>
      <CloseModalButton onClick={onClose}>
        <MdClose size="22px" />
      </CloseModalButton>
    </BaseModalHeaderContainer>
  );
};

export default BaseModalHeader;
