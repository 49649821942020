import {
  DataTableHeaderField,
  DataTableRowValue,
  DataTableSearchField,
  DataTableV2,
  DataTableValue,
} from '../../../../../../../../components/DataTableV2';
import {
  DefaultFieldsValueStyle,
  PrimaryGuestFieldValueStyle,
  ReservationTableHeader,
  ReservationTableRow,
  SelectReservationTableSizes,
} from './SelectReservation.styles';
import React, { useCallback, useMemo } from 'react';
import {
  SelectReservationFields,
  SelectReservationHeaderFields,
  SelectReservationSearchFields,
  SelectReservationSortFields,
} from './types';
import useLazyGetAccessGrantsByLocation, {
  AccessGrantType,
} from '../../../../../../../../hooks/useLazyGetAccessGrantsByLocation';

import { DataTableV2ContextProvider } from '../../../../../../../../components/DataTableV2/context/DataTableV2Context';
import { ResourceType } from '../../../../../../../../__generated__/graphql';
import useDateTimeFormatter from '../../../../../../../../hooks/useDateTimeFormatter';
import { useLoadReservationOnKioskModalContext } from '../../../contexts/LoadReservationOnKioskModalContext';

const SelectReservationTable: React.FC = () => {
  const { setAccessGrant, location } = useLoadReservationOnKioskModalContext();

  const { formatDate, formatTime } = useDateTimeFormatter();

  if (!location) {
    throw new Error('The location should be selected for the Select Access Grant component');
  }

  const {
    data: accessGrants,
    loading,
    query: getAccessGrants,
    hasMoreData,
    search,
    sort,
  } = useLazyGetAccessGrantsByLocation({
    take: 15,
    locationId: location.id,
  });

  const accessGrantsDataTableValue = useMemo((): DataTableValue<AccessGrantType> => {
    return accessGrants?.map(
      (ag): DataTableRowValue<AccessGrantType> => {
        const room = ag.resources.find((r) => r.type === ResourceType.Lock);

        return {
          value: ag,
          key: ag.id,
          fields: [
            {
              value: [
                {
                  key: SelectReservationFields.PrimaryGuest,
                  displayValue: ag.user.name ?? '',
                  style: PrimaryGuestFieldValueStyle,
                },
                {
                  key: SelectReservationFields.ConfirmationCode,
                  displayValue: ag.confirmationCode ?? '',
                  style: DefaultFieldsValueStyle,
                },
              ],
              flex: SelectReservationTableSizes.PrimaryGuest,
            },
            {
              value: {
                key: SelectReservationFields.SecondaryGuests,
                displayValue:
                  ag.secondaryUsers.length > 0
                    ? ag.secondaryUsers
                        .map((secondaryUser) => secondaryUser.user.name ?? '')
                        .join(', ')
                    : 'N/A',
                style: DefaultFieldsValueStyle,
              },
              flex: SelectReservationTableSizes.SecondaryGuests,
            },
            {
              value: {
                key: SelectReservationFields.RoomNumber,
                displayValue: room?.name ?? 'N/A',
                style: DefaultFieldsValueStyle,
              },
              flex: SelectReservationTableSizes[SelectReservationFields.RoomNumber],
            },
            {
              value: [
                {
                  key: SelectReservationFields.CheckInDate,
                  displayValue: formatDate(ag.startsAt, {
                    timezone: ag.location.timezone,
                    format: 'MMM DD',
                  }),
                  style: DefaultFieldsValueStyle,
                },
                {
                  key: SelectReservationFields.CheckInTime,
                  displayValue: formatTime(ag.startsAt, {
                    timezone: ag.location.timezone,
                    format: 'LT',
                  }),
                  style: DefaultFieldsValueStyle,
                },
              ],
              flex: SelectReservationTableSizes.CheckIn,
            },
            {
              value: {
                key: SelectReservationFields.Arrow,
                displayValue: '→',
                style: DefaultFieldsValueStyle,
              },
              flex: SelectReservationTableSizes.Arrow,
            },
            {
              value: [
                {
                  key: SelectReservationFields.CheckOutDate,
                  displayValue: formatDate(ag.endsAt, {
                    timezone: ag.location.timezone,
                    format: 'MMM DD',
                  }),
                  style: DefaultFieldsValueStyle,
                },
                {
                  key: SelectReservationFields.CheckOutTime,
                  displayValue: formatTime(ag.endsAt, {
                    timezone: ag.location.timezone,
                    format: 'LT',
                  }),
                  style: DefaultFieldsValueStyle,
                },
              ],
              flex: SelectReservationTableSizes.CheckOut,
            },
          ],
        };
      }
    );
  }, [accessGrants]);

  const onSelectValue = useCallback(
    (accessGrant: AccessGrantType) => {
      setAccessGrant({
        id: accessGrant.id,
        confirmationCode: accessGrant.confirmationCode,
        user: accessGrant.user,
      });
    },
    [setAccessGrant]
  );

  const headers = useMemo((): DataTableHeaderField[] => {
    return [
      {
        key: SelectReservationHeaderFields.PrimaryGuest,
        displayValue: 'Primary Guest',
        flex: SelectReservationTableSizes.PrimaryGuest,
      },
      {
        key: SelectReservationHeaderFields.SecondaryGuests,
        displayValue: 'Secondary Guest(s)',
        flex: SelectReservationTableSizes.SecondaryGuests,
      },
      {
        key: SelectReservationHeaderFields.RoomNumber,
        displayValue: 'Room #',
        flex: SelectReservationTableSizes.RoomNumber,
      },
      {
        key: SelectReservationHeaderFields.CheckIn,
        displayValue: 'Check-in',
        flex: SelectReservationTableSizes.CheckIn,
      },
      {
        key: SelectReservationHeaderFields.Arrow,
        displayValue: '',
        flex: SelectReservationTableSizes.Arrow,
      },
      {
        key: SelectReservationHeaderFields.CheckOut,
        displayValue: 'Check-out',
        flex: SelectReservationTableSizes.CheckOut,
      },
    ];
  }, []);

  const searchFields = useMemo((): DataTableSearchField[] => {
    return [
      { key: SelectReservationSearchFields.GuestName, title: 'Guest Name' },
      { key: SelectReservationSearchFields.RoomNumber, title: 'Room #' },
      { key: SelectReservationSearchFields.ConfirmationCode, title: 'Reservation #' },
    ];
  }, []);

  const sortFields = useMemo((): string[] => {
    return Object.values(SelectReservationSortFields);
  }, []);

  return (
    <DataTableV2<AccessGrantType>
      data={accessGrantsDataTableValue}
      headers={headers}
      searchFields={searchFields}
      onSelectValue={onSelectValue}
      hasMoreData={hasMoreData}
      loadData={getAccessGrants}
      search={search}
      isLoading={loading}
      TableRowComponent={ReservationTableRow}
      TableHeaderComponent={ReservationTableHeader}
      sort={sort}
      sortFields={sortFields}
    />
  );
};

export const SelectReservation: React.FC = () => {
  return (
    <DataTableV2ContextProvider>
      <SelectReservationTable />
    </DataTableV2ContextProvider>
  );
};
