import { DataTableBody, DataTableHeader, DataTableSearchBar } from './components';
import { DataTableV2Container, Table } from './DataTableV2.styles';
import React, { useMemo } from 'react';

import { DataTableV2Props } from './@types';
import { noResults as noResultsFn } from './utils/noResults';

export const DataTableV2 = <T,>(props: DataTableV2Props<T>): React.ReactElement => {
  const { headers, isLoading, data, searchFields } = props;

  const noResults = useMemo((): boolean => {
    return noResultsFn({ data, isLoading });
  }, [data, isLoading]);

  const displayHeaders = useMemo(() => {
    return headers && !noResults;
  }, [headers, noResults]);

  const displaySearchBar = useMemo(() => {
    return searchFields?.length;
  }, [searchFields]);

  return (
    <DataTableV2Container>
      {displaySearchBar && <DataTableSearchBar {...props} />}
      <Table>
        {displayHeaders && <DataTableHeader<T> {...props} />}
        <DataTableBody<T> {...props} />
      </Table>
    </DataTableV2Container>
  );
};
