/* eslint-disable max-len */
import { QuickActionsModalTabType, UpdatedRequirementsList } from '../../types';
import React, { createContext, useCallback, useContext, useState } from 'react';

type ConfirmationModalData = {
  title: string;
  type: QuickActionsModalTabType;
  action: () => void;
  buttonText?: {
    confirm?: string;
    cancel?: string;
  };
  newRequirements?: UpdatedRequirementsList[];
};

interface QuickActionConfirmationModalContextProps {
  showQuickActionConfirmationModal: boolean;
  openQuickActionConfirmationModal: (data: ConfirmationModalData) => void;
  closeQuickActionConfirmationModal: () => void;
  modalData: ConfirmationModalData | undefined;
}

const QuickActionConfirmationModalContext = createContext<
  QuickActionConfirmationModalContextProps | undefined
>(undefined);

export const QuickActionConfirmationModalProvider: React.FC = ({ children }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState<ConfirmationModalData>();

  const openQuickActionConfirmationModal = useCallback((data: ConfirmationModalData) => {
    setModalData(data);
    setShowModal(true);
  }, []);

  const closeQuickActionConfirmationModal = useCallback(() => {
    setShowModal(false);
  }, []);

  return (
    <QuickActionConfirmationModalContext.Provider
      value={{
        showQuickActionConfirmationModal: showModal,
        openQuickActionConfirmationModal,
        closeQuickActionConfirmationModal,
        modalData,
      }}
    >
      {children}
    </QuickActionConfirmationModalContext.Provider>
  );
};

export const useQuickActionConfirmationModalContext = (): QuickActionConfirmationModalContextProps => {
  const context = useContext(QuickActionConfirmationModalContext);

  if (!context) {
    throw new Error(
      'useQuickActionConfirmationModalContext must be used within a QuickActionConfirmationModalProvider'
    );
  }

  return context;
};
