/* eslint-disable sort-keys */
import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3LocalizationErrorCodesSchema: NodeSchemaProperties = {
  errors: {
    title: '#27 Errors',
    type: 'object',
    properties: {
      'SERVER_ERROR:enable': {
        title: '#1 Server error occurred (SERVER_ERROR)',
        type: 'boolean',
        description: 'Error message for SERVER_ERROR [errors.SERVER_ERROR]',
      },
      SERVER_ERROR: {
        type: 'string',
      },
      'VENDOR_INTEGRATION_FAILED:enable': {
        title: '#2 Vendor integration failed (VENDOR_INTEGRATION_FAILED)',
        type: 'boolean',
        description:
          'Error message for VENDOR_INTEGRATION_FAILED [errors.VENDOR_INTEGRATION_FAILED]',
      },
      VENDOR_INTEGRATION_FAILED: {
        type: 'string',
      },
      'TIME_OUT:enable': {
        title: '#3 Operation timed out (TIME_OUT)',
        type: 'boolean',
        description: 'Error message for TIME_OUT [errors.TIME_OUT]',
      },
      TIME_OUT: {
        type: 'string',
      },
      'INVALID_OR_MISSING_CONFIGURATION:enable': {
        title: '#4 Invalid or missing configuration (INVALID_OR_MISSING_CONFIGURATION)',
        type: 'boolean',
        description:
          'Error message for INVALID_OR_MISSING_CONFIGURATION [errors.INVALID_OR_MISSING_CONFIGURATION]',
      },
      INVALID_OR_MISSING_CONFIGURATION: {
        type: 'string',
      },
      'UNKNOWN_OR_INVALID_VENDOR:enable': {
        title: '#5 Unknown or invalid vendor (UNKNOWN_OR_INVALID_VENDOR)',
        type: 'boolean',
        description:
          'Error message for UNKNOWN_OR_INVALID_VENDOR [errors.UNKNOWN_OR_INVALID_VENDOR]',
      },
      UNKNOWN_OR_INVALID_VENDOR: {
        type: 'string',
      },
      'LOCATION_MISSMATCH:enable': {
        title: '#6 Location mismatch (LOCATION_MISSMATCH)',
        type: 'boolean',
        description: 'Error message for LOCATION_MISSMATCH [errors.LOCATION_MISSMATCH]',
      },
      LOCATION_MISSMATCH: {
        type: 'string',
      },
      'USER_MISSMATCH:enable': {
        title: '#7 User mismatch (USER_MISSMATCH)',
        type: 'boolean',
        description: 'Error message for USER_MISSMATCH [errors.USER_MISSMATCH]',
      },
      USER_MISSMATCH: {
        type: 'string',
      },
      'UNAUTHORIZED:enable': {
        title: '#8 Unauthorized access (UNAUTHORIZED)',
        type: 'boolean',
        description: 'Error message for UNAUTHORIZED [errors.UNAUTHORIZED]',
      },
      UNAUTHORIZED: {
        type: 'string',
      },
      'ACTIVE_ACCESS_GRANT_NOT_FOUND:enable': {
        title: '#9 Active access grant not found (ACTIVE_ACCESS_GRANT_NOT_FOUND)',
        type: 'boolean',
        description:
          'Error message for ACTIVE_ACCESS_GRANT_NOT_FOUND [errors.ACTIVE_ACCESS_GRANT_NOT_FOUND]',
      },
      ACTIVE_ACCESS_GRANT_NOT_FOUND: {
        type: 'string',
      },
      'ID_VERIFICATION_DATA_MISSMATCH:enable': {
        title: '#10 ID verification data mismatch (ID_VERIFICATION_DATA_MISSMATCH)',
        type: 'boolean',
        description:
          'Error message for ID_VERIFICATION_DATA_MISSMATCH [errors.ID_VERIFICATION_DATA_MISSMATCH]',
      },
      ID_VERIFICATION_DATA_MISSMATCH: {
        type: 'string',
      },
      'AGE_VERIFICATION_FAILED:enable': {
        title: '#11 Age verification failed (AGE_VERIFICATION_FAILED)',
        type: 'boolean',
        description: 'Error message for AGE_VERIFICATION_FAILED [errors.AGE_VERIFICATION_FAILED]',
      },
      AGE_VERIFICATION_FAILED: {
        type: 'string',
      },
      'NAME_VERIFICATION_FAILED:enable': {
        title: '#12 Name verification failed (NAME_VERIFICATION_FAILED)',
        type: 'boolean',
        description: 'Error message for NAME_VERIFICATION_FAILED [errors.NAME_VERIFICATION_FAILED]',
      },
      NAME_VERIFICATION_FAILED: {
        type: 'string',
      },
      'ID_VERIFICATION_FAILED:enable': {
        title: '#13 ID verification failed (ID_VERIFICATION_FAILED)',
        type: 'boolean',
        description: 'Error message for ID_VERIFICATION_FAILED [errors.ID_VERIFICATION_FAILED]',
      },
      ID_VERIFICATION_FAILED: {
        type: 'string',
      },
      'DOCUMENT_EXPIRED:enable': {
        title: '#14 Document expired (DOCUMENT_EXPIRED)',
        type: 'boolean',
        description: 'Error message for DOCUMENT_EXPIRED [errors.DOCUMENT_EXPIRED]',
      },
      DOCUMENT_EXPIRED: {
        type: 'string',
      },
      'DOCUMENT_INVALID_EXPIRE_DATE:enable': {
        title: '#15 Document has an invalid expiration date (DOCUMENT_INVALID_EXPIRE_DATE)',
        type: 'boolean',
        description:
          'Error message for DOCUMENT_INVALID_EXPIRE_DATE [errors.DOCUMENT_INVALID_EXPIRE_DATE]',
      },
      DOCUMENT_INVALID_EXPIRE_DATE: {
        type: 'string',
      },
      'FACE_VERIFICATION_FAILED:enable': {
        title: '#16 Face verification failed (FACE_VERIFICATION_FAILED)',
        type: 'boolean',
        description: 'Error message for FACE_VERIFICATION_FAILED [errors.FACE_VERIFICATION_FAILED]',
      },
      FACE_VERIFICATION_FAILED: {
        type: 'string',
      },
      'PAYMENT_NOT_FOUND:enable': {
        title: '#17 Payment not found (PAYMENT_NOT_FOUND)',
        type: 'boolean',
        description: 'Error message for PAYMENT_NOT_FOUND [errors.PAYMENT_NOT_FOUND]',
      },
      PAYMENT_NOT_FOUND: {
        type: 'string',
      },
      'ENDPOINT_NOT_FOUND:enable': {
        title: '#18 Endpoint not found (ENDPOINT_NOT_FOUND)',
        type: 'boolean',
        description: 'Error message for ENDPOINT_NOT_FOUND [errors.ENDPOINT_NOT_FOUND]',
      },
      ENDPOINT_NOT_FOUND: {
        type: 'string',
      },
      'NO_LOCK_RESOURCES_FOUND:enable': {
        title: '#19 No lock resources found (NO_LOCK_RESOURCES_FOUND)',
        type: 'boolean',
        description: 'Error message for NO_LOCK_RESOURCES_FOUND [errors.NO_LOCK_RESOURCES_FOUND]',
      },
      NO_LOCK_RESOURCES_FOUND: {
        type: 'string',
      },
      'ERROR_TO_CONNECT_TO_VENDOR_SERVER:enable': {
        title: '#20 Error connecting to vendor server (ERROR_TO_CONNECT_TO_VENDOR_SERVER)',
        type: 'boolean',
        description:
          'Error message for ERROR_TO_CONNECT_TO_VENDOR_SERVER [errors.ERROR_TO_CONNECT_TO_VENDOR_SERVER]',
      },
      ERROR_TO_CONNECT_TO_VENDOR_SERVER: {
        type: 'string',
      },
      'CREDENTIAL_NOT_FOUND:enable': {
        title: '#21 Credential not found (CREDENTIAL_NOT_FOUND)',
        type: 'boolean',
        description: 'Error message for CREDENTIAL_NOT_FOUND [errors.CREDENTIAL_NOT_FOUND]',
      },
      CREDENTIAL_NOT_FOUND: {
        type: 'string',
      },
      'CREDENTIAL_IS_NOT_ACTIVE:enable': {
        title: '#22 Credential is not active (CREDENTIAL_IS_NOT_ACTIVE)',
        type: 'boolean',
        description: 'Error message for CREDENTIAL_IS_NOT_ACTIVE [errors.CREDENTIAL_IS_NOT_ACTIVE]',
      },
      CREDENTIAL_IS_NOT_ACTIVE: {
        type: 'string',
      },
      'MOBILE_KEY_CREATION_FAILED:enable': {
        title: '#23 Mobile key creation failed (MOBILE_KEY_CREATION_FAILED)',
        type: 'boolean',
        description:
          'Error message for MOBILE_KEY_CREATION_FAILED [errors.MOBILE_KEY_CREATION_FAILED]',
      },
      MOBILE_KEY_CREATION_FAILED: {
        type: 'string',
      },
      'WRONG_CHECK_IN_DATE:enable': {
        title: '#24 Wrong check-in date (WRONG_CHECK_IN_DATE)',
        type: 'boolean',
        description: 'Error message for WRONG_CHECK_IN_DATE [errors.WRONG_CHECK_IN_DATE]',
      },
      WRONG_CHECK_IN_DATE: {
        type: 'string',
      },
      'WRONG_CHECK_OUT_DATE:enable': {
        title: '#25 Wrong check-out date (WRONG_CHECK_OUT_DATE)',
        type: 'boolean',
        description: 'Error message for WRONG_CHECK_OUT_DATE [errors.WRONG_CHECK_OUT_DATE]',
      },
      WRONG_CHECK_OUT_DATE: {
        type: 'string',
      },
      'UNATTENDED_BILLING_WINDOWS_EXIST:enable': {
        title: '#26 Unattended billing windows exist (UNATTENDED_BILLING_WINDOWS_EXIST)',
        type: 'boolean',
        description:
          'Error message for UNATTENDED_BILLING_WINDOWS_EXIST [errors.UNATTENDED_BILLING_WINDOWS_EXIST]',
      },
      UNATTENDED_BILLING_WINDOWS_EXIST: {
        type: 'string',
      },
      'BILLING_CURRENCY_MISMATCH:enable': {
        title: '#27 Billing currency mismatch (BILLING_CURRENCY_MISMATCH)',
        type: 'boolean',
        description:
          'Error message for BILLING_CURRENCY_MISMATCH [errors.BILLING_CURRENCY_MISMATCH]',
      },
      BILLING_CURRENCY_MISMATCH: {
        type: 'string',
      },
      'BAD_CHECK_IN_STATUS:enable': {
        title: '#28 Bad check-in status (BAD_CHECK_IN_STATUS)',
        type: 'boolean',
        description: 'Error message for BAD_CHECK_IN_STATUS [errors.BAD_CHECK_IN_STATUS]',
      },
      BAD_CHECK_IN_STATUS: {
        type: 'string',
      },
      'BAD_CHECK_OUT_STATUS:enable': {
        title: '#29 Bad check-out status (BAD_CHECK_OUT_STATUS)',
        type: 'boolean',
        description: 'Error message for BAD_CHECK_OUT_STATUS [errors.BAD_CHECK_OUT_STATUS]',
      },
      BAD_CHECK_OUT_STATUS: {
        type: 'string',
      },
      'CANNOT_CHECK_OUT_RESERVATION:enable': {
        title: '#30 Cannot check out reservation (CANNOT_CHECK_OUT_RESERVATION)',
        type: 'boolean',
        description:
          'Error message for CANNOT_CHECK_OUT_RESERVATION [errors.CANNOT_CHECK_OUT_RESERVATION]',
      },
      CANNOT_CHECK_OUT_RESERVATION: {
        type: 'string',
      },
      'REDIRECT_GUEST_TO_HELP_DESK:enable': {
        title: '#31 Redirect guest to help desk (REDIRECT_GUEST_TO_HELP_DESK)',
        type: 'boolean',
        description:
          'Error message for REDIRECT_GUEST_TO_HELP_DESK [errors.REDIRECT_GUEST_TO_HELP_DESK]',
      },
      REDIRECT_GUEST_TO_HELP_DESK: {
        type: 'string',
      },
      'NO_CHECK_IN_AHEAD_OF_TIME:enable': {
        title: '#32 No check-in ahead of time (NO_CHECK_IN_AHEAD_OF_TIME)',
        type: 'boolean',
        description:
          'Error message for NO_CHECK_IN_AHEAD_OF_TIME [errors.NO_CHECK_IN_AHEAD_OF_TIME]',
      },
      NO_CHECK_IN_AHEAD_OF_TIME: {
        type: 'string',
      },
      'CHECK_IN_NOT_ALLOWED_AT_THIS_TIME:enable': {
        title: '#33 Check-in not allowed at this time (CHECK_IN_NOT_ALLOWED_AT_THIS_TIME)',
        type: 'boolean',
        description:
          'Error message for CHECK_IN_NOT_ALLOWED_AT_THIS_TIME [errors.CHECK_IN_NOT_ALLOWED_AT_THIS_TIME]',
      },
      CHECK_IN_NOT_ALLOWED_AT_THIS_TIME: {
        type: 'string',
      },
      'ROOM_NOT_READY:enable': {
        title: '#34 Room not ready (ROOM_NOT_READY)',
        type: 'boolean',
        description: 'Error message for ROOM_NOT_READY [errors.ROOM_NOT_READY]',
      },
      ROOM_NOT_READY: {
        type: 'string',
      },
      'ROOM_NOT_ASSIGNED:enable': {
        title: '#35 Room not assigned (ROOM_NOT_ASSIGNED)',
        type: 'boolean',
        description: 'Error message for ROOM_NOT_ASSIGNED [errors.ROOM_NOT_ASSIGNED]',
      },
      ROOM_NOT_ASSIGNED: {
        type: 'string',
      },
      'NO_PMS_CONFIGURATION_FOUND:enable': {
        title: '#36 No PMS configuration found (NO_PMS_CONFIGURATION_FOUND)',
        type: 'boolean',
        description:
          'Error message for NO_PMS_CONFIGURATION_FOUND [errors.NO_PMS_CONFIGURATION_FOUND]',
      },
      NO_PMS_CONFIGURATION_FOUND: {
        type: 'string',
      },
      'NO_PAYMENT_INFO_FOUND:enable': {
        title: '#37 No payment information found (NO_PAYMENT_INFO_FOUND)',
        type: 'boolean',
        description: 'Error message for NO_PAYMENT_INFO_FOUND [errors.NO_PAYMENT_INFO_FOUND]',
      },
      NO_PAYMENT_INFO_FOUND: {
        type: 'string',
      },
      'RESERVATION_LOCKED_FOR_PROCESSING:enable': {
        title: '#38 Reservation locked for processing (RESERVATION_LOCKED_FOR_PROCESSING)',
        type: 'boolean',
        description:
          'Error message for RESERVATION_LOCKED_FOR_PROCESSING [errors.RESERVATION_LOCKED_FOR_PROCESSING]',
      },
      RESERVATION_LOCKED_FOR_PROCESSING: {
        type: 'string',
      },
      'ROOM_LOCKED_FOR_PROCESSING:enable': {
        title: '#39 Room locked for processing (ROOM_LOCKED_FOR_PROCESSING)',
        type: 'boolean',
        description:
          'Error message for ROOM_LOCKED_FOR_PROCESSING [errors.ROOM_LOCKED_FOR_PROCESSING]',
      },
      ROOM_LOCKED_FOR_PROCESSING: {
        type: 'string',
      },
      'ROOM_ASSIGNMENT_NOT_ALLOWED:enable': {
        title: '#40 Room assignment not allowed (ROOM_ASSIGNMENT_NOT_ALLOWED)',
        type: 'boolean',
        description:
          'Error message for ROOM_ASSIGNMENT_NOT_ALLOWED [errors.ROOM_ASSIGNMENT_NOT_ALLOWED]',
      },
      ROOM_ASSIGNMENT_NOT_ALLOWED: {
        type: 'string',
      },
      'BLUETOOTH_COMMUNICATION_FAILED:enable': {
        title: '#41 Bluetooth communication failed (BLUETOOTH_COMMUNICATION_FAILED)',
        type: 'boolean',
        description:
          'Error message for BLUETOOTH_COMMUNICATION_FAILED [errors.BLUETOOTH_COMMUNICATION_FAILED]',
      },
      BLUETOOTH_COMMUNICATION_FAILED: {
        type: 'string',
      },
      'LOCK_REJECTED:enable': {
        title: '#42 Lock rejected (LOCK_REJECTED)',
        type: 'boolean',
        description: 'Error message for LOCK_REJECTED [errors.LOCK_REJECTED]',
      },
      LOCK_REJECTED: {
        type: 'string',
      },
      'MOBILE_KEY_EXIPRED:enable': {
        title: '#43 Mobile key expired (MOBILE_KEY_EXIPRED)',
        type: 'boolean',
        description: 'Error message for MOBILE_KEY_EXIPRED [errors.MOBILE_KEY_EXIPRED]',
      },
      MOBILE_KEY_EXIPRED: {
        type: 'string',
      },
      'MOBILE_KEY_NOT_VALID:enable': {
        title: '#44 Mobile key not valid (MOBILE_KEY_NOT_VALID)',
        type: 'boolean',
        description: 'Error message for MOBILE_KEY_NOT_VALID [errors.MOBILE_KEY_NOT_VALID]',
      },
      MOBILE_KEY_NOT_VALID: {
        type: 'string',
      },
      'PERMISSIONS_NOT_GRANTED:enable': {
        title: '#45 Permissions not granted (PERMISSIONS_NOT_GRANTED)',
        type: 'boolean',
        description: 'Error message for PERMISSIONS_NOT_GRANTED [errors.PERMISSIONS_NOT_GRANTED]',
      },
      PERMISSIONS_NOT_GRANTED: {
        type: 'string',
      },
      'UNKNOWN:enable': {
        title: '#46 Unknown error occurred (UNKNOWN)',
        type: 'boolean',
        description: 'Error message for UNKNOWN [errors.UNKNOWN]',
      },
      UNKNOWN: {
        type: 'string',
      },
      'CANCELLED_BY_USER:enable': {
        title: '#47 Cancelled by user (CANCELLED_BY_USER)',
        type: 'boolean',
        description: 'Error message for CANCELLED_BY_USER [errors.CANCELLED_BY_USER]',
      },
      CANCELLED_BY_USER: {
        type: 'string',
      },
      'CAMERA_NOT_AVAILABLE:enable': {
        title: '#48 Camera not available (CAMERA_NOT_AVAILABLE)',
        type: 'boolean',
        description: 'Error message for CAMERA_NOT_AVAILABLE [errors.CAMERA_NOT_AVAILABLE]',
      },
      CAMERA_NOT_AVAILABLE: {
        type: 'string',
      },
      'DOCUMENT_INVALID:enable': {
        title: '#49 Document is not valid (DOCUMENT_INVALID)',
        type: 'boolean',
        description: 'Error message for DOCUMENT_INVALID [errors.DOCUMENT_INVALID]',
      },
      DOCUMENT_INVALID: {
        type: 'string',
      },
      'NO_INTERNET_CONNECTION:enable': {
        title: '#50 No internet connection (NO_INTERNET_CONNECTION)',
        type: 'boolean',
        description: 'Error message for NO_INTERNET_CONNECTION [errors.NO_INTERNET_CONNECTION]',
      },
      NO_INTERNET_CONNECTION: {
        type: 'string',
      },
      'CAPABILITY_FAILED:enable': {
        title: '#51 Jumio Capibility checks failed (CAPABILITY_FAILED)',
        type: 'boolean',
        description: 'Error message for CAPABILITY_FAILED [errors.CAPABILITY_FAILED]',
      },
      CAPABILITY_FAILED: {
        type: 'string',
      },
      'CAPABILITY_USABILITY_FAILED:enable': {
        title:
          '#52 Failed to ensure that the images captured of the government issued ID or Document are of sufficient quality. (CAPABILITY_USABILITY_FAILED)',
        type: 'boolean',
        description:
          'Error message for CAPABILITY_USABILITY_FAILED [errors.CAPABILITY_USABILITY_FAILED]',
      },
      CAPABILITY_USABILITY_FAILED: {
        type: 'string',
      },
      'CAPABILITY_IMAGECHECKS_FAILED:enable': {
        title: '#53 Failed to perform automated image checks. (CAPABILITY_IMAGECHECKS_FAILED)',
        type: 'boolean',
        description:
          'Error message for CAPABILITY_IMAGECHECKS_FAILED [errors.CAPABILITY_IMAGECHECKS_FAILED]',
      },
      CAPABILITY_IMAGECHECKS_FAILED: {
        type: 'string',
      },
      'CAPABILITY_EXTRACTION_FAILED:enable': {
        title:
          '#54 Failed to extracts data from ID/Document image(s). (CAPABILITY_EXTRACTION_FAILED)',
        type: 'boolean',
        description:
          'Error message for CAPABILITY_EXTRACTION_FAILED [errors.CAPABILITY_EXTRACTION_FAILED]',
      },
      CAPABILITY_EXTRACTION_FAILED: {
        type: 'string',
      },
      'CAPABILITY_DATACHECKS_FAILED:enable': {
        title:
          '#55 Failed to perform fraud checks on extracted data. (CAPABILITY_DATACHECKS_FAILED)',
        type: 'boolean',
        description:
          'Error message for CAPABILITY_DATACHECKS_FAILED [errors.CAPABILITY_DATACHECKS_FAILED]',
      },
      CAPABILITY_DATACHECKS_FAILED: {
        type: 'string',
      },
      'CAPABILITY_LIVENESS_FAILED:enable': {
        title:
          '#56 Failed to perform automated checks that ensure the end user is physically present during the verification. (CAPABILITY_LIVENESS_FAILED)',
        type: 'boolean',
        description:
          'Error message for CAPABILITY_LIVENESS_FAILED [errors.CAPABILITY_LIVENESS_FAILED]',
      },
      CAPABILITY_LIVENESS_FAILED: {
        type: 'string',
      },
      'CAPABILITY_SIMILARITY_FAILED:enable': {
        title:
          '#57 Failed to compares the face of the user in the ID photo against the face captured by the Selfie. (CAPABILITY_SIMILARITY_FAILED)',
        type: 'boolean',
        description:
          'Error message for CAPABILITY_SIMILARITY_FAILED [errors.CAPABILITY_SIMILARITY_FAILED]',
      },
      CAPABILITY_SIMILARITY_FAILED: {
        type: 'string',
      },
      'NFC_CERTIFICATE:enable': {
        title:
          '#58 There is a mismatch between the data extracted from the microchip on the passport and the data extracted from the document using OCR. (NFC_CERTIFICATE)',
        type: 'boolean',
        description: 'Error message for NFC_CERTIFICATE [errors.NFC_CERTIFICATE]',
      },
      NFC_CERTIFICATE: {
        type: 'string',
      },
      'MISMATCHING_DATAPOINTS:enable': {
        title:
          '#59 There is a mismatch between different occurrences of a repeating datapoint on a document. (MISMATCHING_DATAPOINTS)',
        type: 'boolean',
        description: 'Error message for MISMATCHING_DATAPOINTS [errors.MISMATCHING_DATAPOINTS]',
      },
      MISMATCHING_DATAPOINTS: {
        type: 'string',
      },
      'MRZ_CHECKSUM:enable': {
        title:
          '#60 The machine-readable zone (MRZ) checksum has an unexpected value. (MRZ_CHECKSUM)',
        type: 'boolean',
        description: 'Error message for MRZ_CHECKSUM [errors.MRZ_CHECKSUM]',
      },
      MRZ_CHECKSUM: {
        type: 'string',
      },
      'MISMATCHING_DATA_REPEATED_FACE:enable': {
        title:
          '#61 The ID or selfie matches a previously uploaded selfie or ID with mismatching data. (MISMATCHING_DATA_REPEATED_FACE)',
        type: 'boolean',
        description:
          'Error message for MISMATCHING_DATA_REPEATED_FACE [errors.MISMATCHING_DATA_REPEATED_FACE]',
      },
      MISMATCHING_DATA_REPEATED_FACE: {
        type: 'string',
      },
      'MISMATCH_HRZ_MRZ_DATA:enable': {
        title:
          '#62 The data in the human-readable zone (HRZ) and machine-readable zone (MRZ) of the ID do not match. (MISMATCH_HRZ_MRZ_DATA)',
        type: 'boolean',
        description: 'Error message for MISMATCH_HRZ_MRZ_DATA [errors.MISMATCH_HRZ_MRZ_DATA]',
      },
      MISMATCH_HRZ_MRZ_DATA: {
        type: 'string',
      },
      'DIGITAL_COPY:enable': {
        title: '#63 ID image is of a screen. (DIGITAL_COPY)',
        type: 'boolean',
        description: 'Error message for DIGITAL_COPY [errors.DIGITAL_COPY]',
      },
      DIGITAL_COPY: {
        type: 'string',
      },
      'WATERMARK:enable': {
        title: '#64 The image of the ID contains a watermark. (WATERMARK)',
        type: 'boolean',
        description: 'Error message for WATERMARK [errors.WATERMARK]',
      },
      WATERMARK: {
        type: 'string',
      },
      'MANIPULATED_DOCUMENT:enable': {
        title: '#65 The ID shows a physically superimposed photo or text. (MANIPULATED_DOCUMENT)',
        type: 'boolean',
        description: 'Error message for MANIPULATED_DOCUMENT [errors.MANIPULATED_DOCUMENT]',
      },
      MANIPULATED_DOCUMENT: {
        type: 'string',
      },
      'OTHER_REJECTION:enable': {
        title:
          '#66 The image of the ID was rejected for a reason that does not fall under the other decision labels. (OTHER_REJECTION)',
        type: 'boolean',
        description: 'Error message for OTHER_REJECTION [errors.OTHER_REJECTION]',
      },
      OTHER_REJECTION: {
        type: 'string',
      },
      'GHOST_IMAGE_DIFFERENT:enable': {
        title:
          "#67 There is a mismatch between the ID's main image and ghost image. (GHOST_IMAGE_DIFFERENT)",
        type: 'boolean',
        description: 'Error message for GHOST_IMAGE_DIFFERENT [errors.GHOST_IMAGE_DIFFERENT]',
      },
      GHOST_IMAGE_DIFFERENT: {
        type: 'string',
      },
      'PUNCHED:enable': {
        title: '#68 The ID has been hole-punched. (PUNCHED)',
        type: 'boolean',
        description: 'Error message for PUNCHED [errors.PUNCHED]',
      },
      PUNCHED: {
        type: 'string',
      },
      'SAMPLE:enable': {
        title: '#69 The ID is a known sample document. (SAMPLE)',
        type: 'boolean',
        description: 'Error message for SAMPLE [errors.SAMPLE]',
      },
      SAMPLE: {
        type: 'string',
      },
      'FAKE:enable': {
        title: '#70 The ID is fake. (FAKE)',
        type: 'boolean',
        description: 'Error message for FAKE [errors.FAKE]',
      },
      FAKE: {
        type: 'string',
      },
      'CHIP_MISSING:enable': {
        title: '#71 The ID has a missing chip. (CHIP_MISSING)',
        type: 'boolean',
        description: 'Error message for CHIP_MISSING [errors.CHIP_MISSING]',
      },
      CHIP_MISSING: {
        type: 'string',
      },
      'DIGITAL_MANIPULATION:enable': {
        title:
          '#72 The image of the ID was manipulated before being uploaded. (DIGITAL_MANIPULATION)',
        type: 'boolean',
        description: 'Error message for DIGITAL_MANIPULATION [errors.DIGITAL_MANIPULATION]',
      },
      DIGITAL_MANIPULATION: {
        type: 'string',
      },
      'MISMATCH_FRONT_BACK:enable': {
        title:
          '#73 The country shown on the front of the ID does not match the one shown on the back. (MISMATCH_FRONT_BACK)',
        type: 'boolean',
        description: 'Error message for FAKE [errors.MISMATCH_FRONT_BACK]',
      },
      MISMATCH_FRONT_BACK: {
        type: 'string',
      },
      'DIFFERENT_PERSON:enable': {
        title:
          '#74 When the user submits an image that includes both a face and an ID, the face does not match the person on the ID. (DIFFERENT_PERSON)',
        type: 'boolean',
        description: 'Error message for DIFFERENT_PERSON [errors.DIFFERENT_PERSON]',
      },
      DIFFERENT_PERSON: {
        type: 'string',
      },
      'REPEATED_FACE:enable': {
        title:
          '#75 The same face and data occur multiple times, pointing to the potential opening of multiple accounts. (REPEATED_FACE)',
        type: 'boolean',
        description: 'Error message for REPEATED_FACE [errors.REPEATED_FACE]',
      },
      REPEATED_FACE: {
        type: 'string',
      },
      'NO_MATCH:enable': {
        title: '#76 The faces in the selfie and ID do not match. (NO_MATCH)',
        type: 'boolean',
        description: 'Error message for NO_MATCH [errors.NO_MATCH]',
      },
      NO_MATCH: {
        type: 'string',
      },
      'NOT_POSSIBLE:enable': {
        title: '#77 Similarity cannot be determined. (NOT_POSSIBLE)',
        type: 'boolean',
        description: 'Error message for NOT_POSSIBLE [errors.NOT_POSSIBLE]',
      },
      NOT_POSSIBLE: {
        type: 'string',
      },
      'LIVENESS_UNDETERMINED:enable': {
        title: '#78 Liveness cannot be determined. (LIVENESS_UNDETERMINED)',
        type: 'boolean',
        description: 'Error message for FAKE [errors.LIVENESS_UNDETERMINED]',
      },
      LIVENESS_UNDETERMINED: {
        type: 'string',
      },
      'ID_USED_AS_SELFIE:enable': {
        title: '#79 An ID photo was used for the selfie. (ID_USED_AS_SELFIE)',
        type: 'boolean',
        description: 'Error message for ID_USED_AS_SELFIE [errors.ID_USED_AS_SELFIE]',
      },
      ID_USED_AS_SELFIE: {
        type: 'string',
      },
      'MULTIPLE_PEOPLE:enable': {
        title: '#80 More than one person appears in the selfie. (MULTIPLE_PEOPLE)',
        type: 'boolean',
        description: 'Error message for MULTIPLE_PEOPLE [errors.MULTIPLE_PEOPLE]',
      },
      MULTIPLE_PEOPLE: {
        type: 'string',
      },
      'PHOTOCOPY:enable': {
        title:
          '#81 The document in the image is a photocopy and not the original document. (PHOTOCOPY)',
        type: 'boolean',
        description: 'Error message for PHOTOCOPY [errors.PHOTOCOPY]',
      },
      PHOTOCOPY: {
        type: 'string',
      },
      'MANIPULATED:enable': {
        title: '#82 The selfie was manipulated prior to uploading. (MANIPULATED)',
        type: 'boolean',
        description: 'Error message for MANIPULATED [errors.MANIPULATED]',
      },
      MANIPULATED: {
        type: 'string',
      },
      'NO_FACE_PRESENT:enable': {
        title: '#83 A face does not appear in the selfie. (NO_FACE_PRESENT)',
        type: 'boolean',
        description: 'Error message for NO_FACE_PRESENT [errors.NO_FACE_PRESENT]',
      },
      NO_FACE_PRESENT: {
        type: 'string',
      },
      'FACE_NOT_FULLY_VISIBLE:enable': {
        title: '#84 The face is only partially visible in the selfie. (FACE_NOT_FULLY_VISIBLE)',
        type: 'boolean',
        description: 'Error message for FACE_NOT_FULLY_VISIBLE [errors.FACE_NOT_FULLY_VISIBLE]',
      },
      FACE_NOT_FULLY_VISIBLE: {
        type: 'string',
      },
      'BLACK_WHITE:enable': {
        title: '#85 Black and white images are not supported. (BLACK_WHITE)',
        type: 'boolean',
        description: 'Error message for BLACK_WHITE [errors.BLACK_WHITE]',
      },
      BLACK_WHITE: {
        type: 'string',
      },
      'AGE_DIFFERENCE:enable': {
        title:
          '#86 There is a large difference between the estimated selfie age and the date of birth on the ID. (AGE_DIFFERENCE)',
        type: 'boolean',
        description: 'Error message for AGE_DIFFERENCE [errors.AGE_DIFFERENCE]',
      },
      AGE_DIFFERENCE: {
        type: 'string',
      },
      'BAD_QUALITY:enable': {
        title: '#87 The selfie is of bad quality. (BAD_QUALITY)',
        type: 'boolean',
        description: 'Error message for BAD_QUALITY [errors.BAD_QUALITY]',
      },
      BAD_QUALITY: {
        type: 'string',
      },
      'NOT_UPLOADED:enable': {
        title: '#80 The image was not uploaded. (NOT_UPLOADED)',
        type: 'boolean',
        description: 'Error message for NOT_UPLOADED [errors.NOT_UPLOADED]',
      },
      NOT_UPLOADED: {
        type: 'string',
      },
      'MISSING_PAGE:enable': {
        title:
          '#88 The front and back pages of the document were required, but the user uploaded only one of them. (MISSING_PAGE)',
        type: 'boolean',
        description: 'Error message for MISSING_PAGE [errors.MISSING_PAGE]',
      },
      MISSING_PAGE: {
        type: 'string',
      },
      'MISSING_SIGNATURE:enable': {
        title: '#89 No signature detected. (MISSING_SIGNATURE)',
        type: 'boolean',
        description: 'Error message for MISSING_SIGNATURE [errors.MISSING_SIGNATURE]',
      },
      MISSING_SIGNATURE: {
        type: 'string',
      },
      'NOT_A_DOCUMENT:enable': {
        title: '#90 The image is not a recognized document. (NOT_A_DOCUMENT)',
        type: 'boolean',
        description: 'Error message for NOT_A_DOCUMENT [errors.NOT_A_DOCUMENT]',
      },
      NOT_A_DOCUMENT: {
        type: 'string',
      },
      'UNSUPPORTED_COUNTRY:enable': {
        title:
          '#91 The document was issued by a country that is not configured for your organization. (UNSUPPORTED_COUNTRY)',
        type: 'boolean',
        description: 'Error message for UNSUPPORTED_COUNTRY [errors.UNSUPPORTED_COUNTRY]',
      },
      UNSUPPORTED_COUNTRY: {
        type: 'string',
      },
      'UNSUPPORTED_DOCUMENT_TYPE:enable': {
        title: '#92 The document type is not supported. (UNSUPPORTED_DOCUMENT_TYPE)',
        type: 'boolean',
        description:
          'Error message for UNSUPPORTED_DOCUMENT_TYPE [errors.UNSUPPORTED_DOCUMENT_TYPE]',
      },
      UNSUPPORTED_DOCUMENT_TYPE: {
        type: 'string',
      },
      'PRECONDITION_NOT_FULFILLED:enable': {
        title:
          '#93 Required data from another capability is not available. (PRECONDITION_NOT_FULFILLED)',
        type: 'boolean',
        description:
          'Error message for PRECONDITION_NOT_FULFILLED [errors.PRECONDITION_NOT_FULFILLED]',
      },
      PRECONDITION_NOT_FULFILLED: {
        type: 'string',
      },
      'TECHNICAL_ERROR:enable': {
        title: '#94 An error prevented execution. (TECHNICAL_ERROR)',
        type: 'boolean',
        description: 'Error message for TECHNICAL_ERROR [errors.TECHNICAL_ERROR]',
      },
      TECHNICAL_ERROR: {
        type: 'string',
      },

      'HEALTH_CHECK_INCOMPLETED:enable': {
        title: '#95 Health Check requirement is incomplete (HEALTH_CHECK_INCOMPLETED)',
        type: 'boolean',
        description: 'Error message for HEALTH_CHECK_INCOMPLETED [errors.HEALTH_CHECK_INCOMPLETED]',
      },
      HEALTH_CHECK_INCOMPLETED: {
        type: 'string',
      },
      'PAYMENT_STATUS_INCOMPLETED:enable': {
        title: '#96 Payment Status requirement is incomplete (PAYMENT_STATUS_INCOMPLETED)',
        type: 'boolean',
        description:
          'Error message for PAYMENT_STATUS_INCOMPLETED [errors.PAYMENT_STATUS_INCOMPLETED]',
      },
      PAYMENT_STATUS_INCOMPLETED: {
        type: 'string',
      },
      'ID_VERIFICATION_INCOMPLETED:enable': {
        title: '#97 ID Verification requirement is incomplete (ID_VERIFICATION_INCOMPLETED)',
        type: 'boolean',
        description:
          'Error message for ID_VERIFICATION_INCOMPLETED [errors.ID_VERIFICATION_INCOMPLETED]',
      },
      ID_VERIFICATION_INCOMPLETED: {
        type: 'string',
      },
      'BUSINESS_ID_VERIFICATION_INCOMPLETED:enable': {
        title:
          '#98 Business ID Verification requirement is incomplete (BUSINESS_ID_VERIFICATION_INCOMPLETED)',
        type: 'boolean',
        description:
          'Error message for BUSINESS_ID_VERIFICATION_INCOMPLETED [errors.BUSINESS_ID_VERIFICATION_INCOMPLETED]',
      },
      BUSINESS_ID_VERIFICATION_INCOMPLETED: {
        type: 'string',
      },
      'PMS_CONFIRMATION_INCOMPLETED:enable': {
        title: '#99 PMS Confirmation requirement is incomplete (PMS_CONFIRMATION_INCOMPLETED)',
        type: 'boolean',
        description:
          'Error message for PMS_CONFIRMATION_INCOMPLETED [errors.PMS_CONFIRMATION_INCOMPLETED]',
      },
      PMS_CONFIRMATION_INCOMPLETED: {
        type: 'string',
      },
      'PAYMENT_AUTHORIZATION_INCOMPLETED:enable': {
        title:
          '#100 Payment Authorization requirement is incomplete (PAYMENT_AUTHORIZATION_INCOMPLETED)',
        type: 'boolean',
        description:
          'Error message for PAYMENT_AUTHORIZATION_INCOMPLETED [errors.PAYMENT_AUTHORIZATION_INCOMPLETED]',
      },
      PAYMENT_AUTHORIZATION_INCOMPLETED: {
        type: 'string',
      },
      'TERMS_AND_CONDITIONS_CONFIRMATION_INCOMPLETED:enable': {
        title:
          '#101 Terms & Conditions requirement is incomplete (TERMS_AND_CONDITIONS_CONFIRMATION_INCOMPLETED)',
        type: 'boolean',
        description:
          'Error message for TERMS_AND_CONDITIONS_CONFIRMATION_INCOMPLETED [errors.TERMS_AND_CONDITIONS_CONFIRMATION_INCOMPLETED]',
      },
      TERMS_AND_CONDITIONS_CONFIRMATION_INCOMPLETED: {
        type: 'string',
      },
      'CAPTURE_SECURITY_DEPOSIT_INCOMPLETED:enable': {
        title:
          '#102 Capture Security Deposit requirement is incomplete (CAPTURE_SECURITY_DEPOSIT_INCOMPLETED)',
        type: 'boolean',
        description:
          'Error message for CAPTURE_SECURITY_DEPOSIT_INCOMPLETED [errors.CAPTURE_SECURITY_DEPOSIT_INCOMPLETED]',
      },
      CAPTURE_SECURITY_DEPOSIT_INCOMPLETED: {
        type: 'string',
      },
      'MATCH_NAMES_INCOMPLETED:enable': {
        title: '#103 Match Names requirement is incomplete (MATCH_NAMES_INCOMPLETED)',
        type: 'boolean',
        description: 'Error message for MATCH_NAMES_INCOMPLETED [errors.MATCH_NAMES_INCOMPLETED]',
      },
      MATCH_NAMES_INCOMPLETED: {
        type: 'string',
      },
      'PAYMENT_CAPTURE_INCOMPLETED:enable': {
        title: '#104 Payment Capture requirement is incomplete (PAYMENT_CAPTURE_INCOMPLETED)',
        type: 'boolean',
        description:
          'Error message for PAYMENT_CAPTURE_INCOMPLETED [errors.PAYMENT_CAPTURE_INCOMPLETED]',
      },
      PAYMENT_CAPTURE_INCOMPLETED: {
        type: 'string',
      },
      'MRZ_ID_VERIFICATION_INCOMPLETED:enable': {
        title:
          '#105 Simplified ID Verification requirement is incomplete (MRZ_ID_VERIFICATION_INCOMPLETED)',
        type: 'boolean',
        description:
          'Error message for MRZ_ID_VERIFICATION_INCOMPLETED [errors.MRZ_ID_VERIFICATION_INCOMPLETED]',
      },
      MRZ_ID_VERIFICATION_INCOMPLETED: {
        type: 'string',
      },
      'CLEAR_ID_VERIFICATION_INCOMPLETED:enable': {
        title:
          '#106 CLEAR ID Verification requirement is incomplete (CLEAR_ID_VERIFICATION_INCOMPLETED)',
        type: 'boolean',
        description:
          'Error message for CLEAR_ID_VERIFICATION_INCOMPLETED [errors.CLEAR_ID_VERIFICATION_INCOMPLETED]',
      },
      CLEAR_ID_VERIFICATION_INCOMPLETED: {
        type: 'string',
      },
      'MANUAL_CHECK_IN_INCOMPLETED:enable': {
        title: '#107 Manual Check-in requirement is incomplete (MANUAL_CHECK_IN_INCOMPLETED)',
        type: 'boolean',
        description:
          'Error message for MANUAL_CHECK_IN_INCOMPLETED [errors.MANUAL_CHECK_IN_INCOMPLETED]',
      },
      MANUAL_CHECK_IN_INCOMPLETED: {
        type: 'string',
      },
      'USER_INFORMATION_INCOMPLETED:enable': {
        title: '#108 User Information requirement is incomplete (USER_INFORMATION_INCOMPLETED)',
        type: 'boolean',
        description:
          'Error message for USER_INFORMATION_INCOMPLETED [errors.USER_INFORMATION_INCOMPLETED]',
      },
      USER_INFORMATION_INCOMPLETED: {
        type: 'string',
      },
      'FACE_MATCH_INCOMPLETED:enable': {
        title: '#109 Face Match requirement is incomplete (FACE_MATCH_INCOMPLETED)',
        type: 'boolean',
        description: 'Error message for FACE_MATCH_INCOMPLETED [errors.FACE_MATCH_INCOMPLETED]',
      },
      FACE_MATCH_INCOMPLETED: {
        type: 'string',
      },
      'INVALID_EMAIL:enable': {
        title: '#110 Invalid email address (INVALID_EMAIL)',
        type: 'boolean',
        description: 'Error message for INVALID_EMAIL [errors.INVALID_EMAIL]',
      },
      INVALID_EMAIL: {
        type: 'string',
      },
      'REQUIRED_EMAIL:enable': {
        title: '#111 Email address is required (REQUIRED_EMAIL)',
        type: 'boolean',
        description: 'Error message for REQUIRED_EMAIL [errors.REQUIRED_EMAIL]',
      },
      REQUIRED_EMAIL: {
        type: 'string',
      },
      'INVALID_PHONE_NUMBER:enable': {
        title: '#112 Invalid phone number (INVALID_PHONE_NUMBER)',
        type: 'boolean',
        description: 'Error message for INVALID_PHONE_NUMBER [errors.INVALID_PHONE_NUMBER]',
      },
      INVALID_PHONE_NUMBER: {
        type: 'string',
      },
      'REQUIRED_PHONE_NUMBER:enable': {
        title: '#113 Phone number is required (REQUIRED_PHONE_NUMBER)',
        type: 'boolean',
        description: 'Error message for REQUIRED_PHONE_NUMBER [errors.REQUIRED_PHONE_NUMBER]',
      },
      REQUIRED_PHONE_NUMBER: {
        type: 'string',
      },
      'INVALID_OTP:enable': {
        title: '#114 Invalid OTP (INVALID_OTP)',
        type: 'boolean',
        description: 'Error message for INVALID_OTP [errors.INVALID_OTP]',
      },
      INVALID_OTP: {
        type: 'string',
      },
      'LIMIT_KEYS_REACHED:enable': {
        title: '#115 Limit keys reached (LIMIT_KEYS_REACHED)',
        type: 'boolean',
        description: 'Error message for LIMIT_KEYS_REACHED [errors.LIMIT_KEYS_REACHED]',
      },
      LIMIT_KEYS_REACHED: {
        type: 'string',
      },
      'PERMISSION_DENIED:enable': {
        title: '#116 Permission Denied (PERMISSION_DENIED)',
        type: 'boolean',
        description: 'Error message for PERMISSION_DENIED [errors.PERMISSION_DENIED]',
      },
      PERMISSION_DENIED: {
        type: 'string',
      },
      'PAYMENT_COMPARE_NAME_FAILURE:enable': {
        title:
          '#117 Payment requirement, The name on the payment card does not match the name on the reservation (PAYMENT_COMPARE_NAME_FAILURE)',
        type: 'boolean',
        description:
          'Error message for PAYMENT_COMPARE_NAME_FAILURE [errors.PAYMENT_COMPARE_NAME_FAILURE]',
      },
      PAYMENT_COMPARE_NAME_FAILURE: {
        type: 'string',
      },
      'INSUFFICIENT_FUNDS:enable': {
        title:
          '#118 Payment requirement, the payment card does not have sufficient funds (INSUFFICIENT_FUNDS)',
        type: 'boolean',
        description: 'Error message for INSUFFICIENT_FUNDS [errors.INSUFFICIENT_FUNDS]',
      },
      INSUFFICIENT_FUNDS: {
        type: 'string',
      },
      'INVALID_BIRTH_DATE:enable': {
        title:
          "#119 Simplified ID Verification requirement, the client failed to extract the birthdate from user's ID (INVALID_BIRTH_DATE)",
        type: 'boolean',
        description: 'Error message for INVALID_BIRTH_DATE [errors.INVALID_BIRTH_DATE]',
      },
      INVALID_BIRTH_DATE: {
        type: 'string',
      },
      'PAYMENT_PROCESSING_FAILED:enable': {
        title: '#120 During check-in, the generic error for when PMS failed to process the payment',
        type: 'boolean',
        description:
          'Error message for PAYMENT_PROCESSING_FAILED [errors.PAYMENT_PROCESSING_FAILED]',
      },
      PAYMENT_PROCESSING_FAILED: {
        type: 'string',
      },
      'PAYMENT_AUTH_MAX_RETRY:enable': {
        title:
          '#121 During check-in, user reaches the maximum amount of retry on payment processing with the PMS',
        type: 'boolean',
        description: 'Error message for PAYMENT_AUTH_MAX_RETRY [errors.PAYMENT_AUTH_MAX_RETRY]',
      },
      PAYMENT_AUTH_MAX_RETRY: {
        type: 'string',
      },
    },
  },
};

export const mobileV3LocalizationErrorCodesUiSchema: NodeUiSchemaProperties = {
  errors: {
    SERVER_ERROR: {
      'ui:label': false,
      'ui:readonly': true,
    },
    VENDOR_INTEGRATION_FAILED: {
      'ui:label': false,
      'ui:readonly': true,
    },
    TIME_OUT: {
      'ui:label': false,
      'ui:readonly': true,
    },
    INVALID_OR_MISSING_CONFIGURATION: {
      'ui:label': false,
      'ui:readonly': true,
    },
    UNKNOWN_OR_INVALID_VENDOR: {
      'ui:label': false,
      'ui:readonly': true,
    },
    LOCATION_MISSMATCH: {
      'ui:label': false,
      'ui:readonly': true,
    },
    USER_MISSMATCH: {
      'ui:label': false,
      'ui:readonly': true,
    },
    UNAUTHORIZED: {
      'ui:label': false,
      'ui:readonly': true,
    },
    ACTIVE_ACCESS_GRANT_NOT_FOUND: {
      'ui:label': false,
      'ui:readonly': true,
    },
    ID_VERIFICATION_DATA_MISSMATCH: {
      'ui:label': false,
      'ui:readonly': true,
    },
    AGE_VERIFICATION_FAILED: {
      'ui:label': false,
      'ui:readonly': true,
    },
    NAME_VERIFICATION_FAILED: {
      'ui:label': false,
      'ui:readonly': true,
    },
    ID_VERIFICATION_FAILED: {
      'ui:label': false,
      'ui:readonly': true,
    },
    DOCUMENT_EXPIRED: {
      'ui:label': false,
      'ui:readonly': true,
    },
    DOCUMENT_INVALID_EXPIRE_DATE: {
      'ui:label': false,
      'ui:readonly': true,
    },
    FACE_VERIFICATION_FAILED: {
      'ui:label': false,
      'ui:readonly': true,
    },
    PAYMENT_NOT_FOUND: {
      'ui:label': false,
      'ui:readonly': true,
    },
    ENDPOINT_NOT_FOUND: {
      'ui:label': false,
      'ui:readonly': true,
    },
    NO_LOCK_RESOURCES_FOUND: {
      'ui:label': false,
      'ui:readonly': true,
    },
    ERROR_TO_CONNECT_TO_VENDOR_SERVER: {
      'ui:label': false,
      'ui:readonly': true,
    },
    CREDENTIAL_NOT_FOUND: {
      'ui:label': false,
      'ui:readonly': true,
    },
    CREDENTIAL_IS_NOT_ACTIVE: {
      'ui:label': false,
      'ui:readonly': true,
    },
    MOBILE_KEY_CREATION_FAILED: {
      'ui:label': false,
      'ui:readonly': true,
    },
    WRONG_CHECK_IN_DATE: {
      'ui:label': false,
      'ui:readonly': true,
    },
    WRONG_CHECK_OUT_DATE: {
      'ui:label': false,
      'ui:readonly': true,
    },
    UNATTENDED_BILLING_WINDOWS_EXIST: {
      'ui:label': false,
      'ui:readonly': true,
    },
    BILLING_CURRENCY_MISMATCH: {
      'ui:label': false,
      'ui:readonly': true,
    },
    BAD_CHECK_IN_STATUS: {
      'ui:label': false,
      'ui:readonly': true,
    },
    BAD_CHECK_OUT_STATUS: {
      'ui:label': false,
      'ui:readonly': true,
    },
    CANNOT_CHECK_OUT_RESERVATION: {
      'ui:label': false,
      'ui:readonly': true,
    },
    REDIRECT_GUEST_TO_HELP_DESK: {
      'ui:label': false,
      'ui:readonly': true,
    },
    NO_CHECK_IN_AHEAD_OF_TIME: {
      'ui:label': false,
      'ui:readonly': true,
    },
    CHECK_IN_NOT_ALLOWED_AT_THIS_TIME: {
      'ui:label': false,
      'ui:readonly': true,
    },
    ROOM_NOT_READY: {
      'ui:label': false,
      'ui:readonly': true,
    },
    ROOM_NOT_ASSIGNED: {
      'ui:label': false,
      'ui:readonly': true,
    },
    NO_PMS_CONFIGURATION_FOUND: {
      'ui:label': false,
      'ui:readonly': true,
    },
    NO_PAYMENT_INFO_FOUND: {
      'ui:label': false,
      'ui:readonly': true,
    },
    RESERVATION_LOCKED_FOR_PROCESSING: {
      'ui:label': false,
      'ui:readonly': true,
    },
    ROOM_LOCKED_FOR_PROCESSING: {
      'ui:label': false,
      'ui:readonly': true,
    },
    ROOM_ASSIGNMENT_NOT_ALLOWED: {
      'ui:label': false,
      'ui:readonly': true,
    },
    BLUETOOTH_COMMUNICATION_FAILED: {
      'ui:label': false,
      'ui:readonly': true,
    },
    LOCK_REJECTED: {
      'ui:label': false,
      'ui:readonly': true,
    },
    MOBILE_KEY_EXIPRED: {
      'ui:label': false,
      'ui:readonly': true,
    },
    MOBILE_KEY_NOT_VALID: {
      'ui:label': false,
      'ui:readonly': true,
    },
    PERMISSIONS_NOT_GRANTED: {
      'ui:label': false,
      'ui:readonly': true,
    },
    UNKNOWN: {
      'ui:label': false,
      'ui:readonly': true,
    },
    CANCELLED_BY_USER: {
      'ui:label': false,
      'ui:readonly': true,
    },
    CAMERA_NOT_AVAILABLE: {
      'ui:label': false,
      'ui:readonly': true,
    },
    DOCUMENT_INVALID: {
      'ui:label': false,
      'ui:readonly': true,
    },
    NO_INTERNET_CONNECTION: {
      'ui:label': false,
      'ui:readonly': true,
    },
    CAPABILITY_FAILED: {
      'ui:label': false,
      'ui:readonly': true,
    },
    CAPABILITY_USABILITY_FAILED: {
      'ui:label': false,
      'ui:readonly': true,
    },
    CAPABILITY_IMAGECHECKS_FAILED: {
      'ui:label': false,
      'ui:readonly': true,
    },
    CAPABILITY_EXTRACTION_FAILED: {
      'ui:label': false,
      'ui:readonly': true,
    },
    CAPABILITY_DATACHECKS_FAILED: {
      'ui:label': false,
      'ui:readonly': true,
    },
    CAPABILITY_LIVENESS_FAILED: {
      'ui:label': false,
      'ui:readonly': true,
    },
    CAPABILITY_SIMILARITY_FAILED: {
      'ui:label': false,
      'ui:readonly': true,
    },
    NFC_CERTIFICATE: {
      'ui:label': false,
      'ui:readonly': true,
    },
    MISMATCHING_DATAPOINTS: {
      'ui:label': false,
      'ui:readonly': true,
    },
    MRZ_CHECKSUM: {
      'ui:label': false,
      'ui:readonly': true,
    },
    MISMATCHING_DATA_REPEATED_FACE: {
      'ui:label': false,
      'ui:readonly': true,
    },
    MISMATCH_HRZ_MRZ_DATA: {
      'ui:label': false,
      'ui:readonly': true,
    },
    DIGITAL_COPY: {
      'ui:label': false,
      'ui:readonly': true,
    },
    WATERMARK: {
      'ui:label': false,
      'ui:readonly': true,
    },
    MANIPULATED_DOCUMENT: {
      'ui:label': false,
      'ui:readonly': true,
    },
    OTHER_REJECTION: {
      'ui:label': false,
      'ui:readonly': true,
    },
    GHOST_IMAGE_DIFFERENT: {
      'ui:label': false,
      'ui:readonly': true,
    },
    PUNCHED: {
      'ui:label': false,
      'ui:readonly': true,
    },
    SAMPLE: {
      'ui:label': false,
      'ui:readonly': true,
    },
    FAKE: {
      'ui:label': false,
      'ui:readonly': true,
    },
    CHIP_MISSING: {
      'ui:label': false,
      'ui:readonly': true,
    },
    DIGITAL_MANIPULATION: {
      'ui:label': false,
      'ui:readonly': true,
    },
    MISMATCH_FRONT_BACK: {
      'ui:label': false,
      'ui:readonly': true,
    },
    DIFFERENT_PERSON: {
      'ui:label': false,
      'ui:readonly': true,
    },
    REPEATED_FACE: {
      'ui:label': false,
      'ui:readonly': true,
    },
    NO_MATCH: {
      'ui:label': false,
      'ui:readonly': true,
    },
    NOT_POSSIBLE: {
      'ui:label': false,
      'ui:readonly': true,
    },
    LIVENESS_UNDETERMINED: {
      'ui:label': false,
      'ui:readonly': true,
    },
    ID_USED_AS_SELFIE: {
      'ui:label': false,
      'ui:readonly': true,
    },
    MULTIPLE_PEOPLE: {
      'ui:label': false,
      'ui:readonly': true,
    },
    PHOTOCOPY: {
      'ui:label': false,
      'ui:readonly': true,
    },
    MANIPULATED: {
      'ui:label': false,
      'ui:readonly': true,
    },
    NO_FACE_PRESENT: {
      'ui:label': false,
      'ui:readonly': true,
    },
    FACE_NOT_FULLY_VISIBLE: {
      'ui:label': false,
      'ui:readonly': true,
    },
    BLACK_WHITE: {
      'ui:label': false,
      'ui:readonly': true,
    },
    AGE_DIFFERENCE: {
      'ui:label': false,
      'ui:readonly': true,
    },
    BAD_QUALITY: {
      'ui:label': false,
      'ui:readonly': true,
    },
    NOT_UPLOADED: {
      'ui:label': false,
      'ui:readonly': true,
    },
    MISSING_PAGE: {
      'ui:label': false,
      'ui:readonly': true,
    },
    MISSING_SIGNATURE: {
      'ui:label': false,
      'ui:readonly': true,
    },
    NOT_A_DOCUMENT: {
      'ui:label': false,
      'ui:readonly': true,
    },
    UNSUPPORTED_COUNTRY: {
      'ui:label': false,
      'ui:readonly': true,
    },
    UNSUPPORTED_DOCUMENT_TYPE: {
      'ui:label': false,
      'ui:readonly': true,
    },
    PRECONDITION_NOT_FULFILLED: {
      'ui:label': false,
      'ui:readonly': true,
    },
    TECHNICAL_ERROR: {
      'ui:label': false,
      'ui:readonly': true,
    },
    HEALTH_CHECK_INCOMPLETED: {
      'ui:label': false,
      'ui:readonly': true,
    },
    PAYMENT_STATUS_INCOMPLETED: {
      'ui:label': false,
      'ui:readonly': true,
    },
    ID_VERIFICATION_INCOMPLETED: {
      'ui:label': false,
      'ui:readonly': true,
    },
    BUSINESS_ID_VERIFICATION_INCOMPLETED: {
      'ui:label': false,
      'ui:readonly': true,
    },
    PMS_CONFIRMATION_INCOMPLETED: {
      'ui:label': false,
      'ui:readonly': true,
    },
    PAYMENT_AUTHORIZATION_INCOMPLETED: {
      'ui:label': false,
      'ui:readonly': true,
    },
    TERMS_AND_CONDITIONS_CONFIRMATION_INCOMPLETED: {
      'ui:label': false,
      'ui:readonly': true,
    },
    CAPTURE_SECURITY_DEPOSIT_INCOMPLETED: {
      'ui:label': false,
      'ui:readonly': true,
    },
    MATCH_NAMES_INCOMPLETED: {
      'ui:label': false,
      'ui:readonly': true,
    },
    PAYMENT_CAPTURE_INCOMPLETED: {
      'ui:label': false,
      'ui:readonly': true,
    },
    MRZ_ID_VERIFICATION_INCOMPLETED: {
      'ui:label': false,
      'ui:readonly': true,
    },
    CLEAR_ID_VERIFICATION_INCOMPLETED: {
      'ui:label': false,
      'ui:readonly': true,
    },
    MANUAL_CHECK_IN_INCOMPLETED: {
      'ui:label': false,
      'ui:readonly': true,
    },
    USER_INFORMATION_INCOMPLETED: {
      'ui:label': false,
      'ui:readonly': true,
    },
    FACE_MATCH_INCOMPLETED: {
      'ui:label': false,
      'ui:readonly': true,
    },
    INVALID_EMAIL: {
      'ui:label': false,
      'ui:readonly': true,
    },
    REQUIRED_EMAIL: {
      'ui:label': false,
      'ui:readonly': true,
    },
    INVALID_PHONE_NUMBER: {
      'ui:label': false,
      'ui:readonly': true,
    },
    REQUIRED_PHONE_NUMBER: {
      'ui:label': false,
      'ui:readonly': true,
    },
    INVALID_OTP: {
      'ui:label': false,
      'ui:readonly': true,
    },
    LIMIT_KEYS_REACHED: {
      'ui:label': false,
      'ui:readonly': true,
    },
    PERMISSION_DENIED: {
      'ui:label': false,
      'ui:readonly': true,
    },
    PAYMENT_COMPARE_NAME_FAILURE: {
      'ui:label': false,
      'ui:readonly': true,
    },
    INSUFFICIENT_FUNDS: {
      'ui:label': false,
      'ui:readonly': true,
    },
    INVALID_BIRTH_DATE: {
      'ui:label': false,
      'ui:readonly': true,
    },
    PAYMENT_PROCESSING_FAILED: {
      'ui:label': false,
      'ui:readonly': true,
    },
    PAYMENT_AUTH_MAX_RETRY: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
};
