import React, { MouseEventHandler, useEffect, useRef, useState } from 'react';
import TableHighlightText, { TableHighlightTextProps } from './TableHighlightText';
import {
  getTableExpandableArrowStyle,
  getTableExpandableCellStyle,
  getTableExpandableTextStyle,
} from '../styles/TableExpandableCell.style';
import Theme from '../../../theme';

type ExpandableCellProps = TableHighlightTextProps;

const TableExpandableCell: React.FC<ExpandableCellProps> = ({
  text,
  searchKey,
  style,
  highlightedColor,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const textRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState<boolean>(false);

  useEffect(() => {
    if (textRef.current) {
      setIsOverflowing(textRef.current.scrollWidth > textRef.current.clientWidth);
    }
  }, [text]);

  const toggleExpand: MouseEventHandler<HTMLTableCellElement> = () => {
    setIsExpanded(!isExpanded);
  };

  const cellStyle = getTableExpandableCellStyle({ isOverflowing });

  const textStyle = getTableExpandableTextStyle({ isExpanded });

  const arrowStyle = getTableExpandableArrowStyle({ isExpanded, isOverflowing });

  return (
    <td style={cellStyle} onClick={isOverflowing ? toggleExpand : undefined}>
      <div style={textStyle} ref={textRef}>
        <TableHighlightText
          highlightedColor={highlightedColor}
          style={style ?? {}}
          text={text}
          searchKey={searchKey}
        >
          {text}
        </TableHighlightText>
      </div>
      <div>
        <svg
          style={arrowStyle}
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 24 24"
          fill="none"
          stroke={Theme.colors.white.hexCode}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <polyline points="6 9 12 15 18 9" />
        </svg>
      </div>
    </td>
  );
};

export default TableExpandableCell;
