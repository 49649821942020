import {
  ClearSearchButton,
  SearchBarContainer,
  SearchBarField,
  SearchBarFieldContainer,
  SearchButton,
  SearchOption,
  SearchOptionsContainer,
} from './DataTableSearchBar.styles';
import { DataTableSearchField, DataTableSearchType, DataTableV2Props } from '../../@types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { CloseIcon } from '../../../../assets/icons/closeIcon';
import { SearchIcon } from '../../../../assets/icons/searchIcon';
import Theme from '../../../../theme';

type DataTableSearchBarProps<T> = Pick<DataTableV2Props<T>, 'searchFields' | 'search'>;

export const DataTableSearchBar = <T,>(props: DataTableSearchBarProps<T>): React.ReactElement => {
  const { searchFields, search } = props;

  if (!searchFields?.length) {
    throw new Error('For this component, at least one search field is required');
  }
  if (!search) {
    throw new Error('For this component, the search function is required');
  }

  const [searchField, setSearchField] = useState<DataTableSearchField | undefined>();
  const [searchText, setSearchText] = useState<string>('');

  useEffect(() => {
    setSearchField(searchFields[0]);
  }, []);

  const searchProps = useMemo((): DataTableSearchType => {
    return {
      field: searchField,
      text: searchText,
    };
  }, [searchField, searchText]);

  const displayClearSearch = useMemo(() => Boolean(searchText), [searchText]);

  const clearSearch = useCallback(() => {
    setSearchText('');
  }, []);

  return (
    <SearchBarContainer>
      <SearchBarFieldContainer>
        <SearchBarField
          placeholder={`Search by ${searchField?.title}`}
          value={searchText}
          onChange={(e): void => setSearchText(e.target.value)}
        />
        {displayClearSearch && (
          <ClearSearchButton onClick={(): void => clearSearch()}>
            <CloseIcon color={Theme.colors.black.hexCode} />
          </ClearSearchButton>
        )}
        <SearchButton onClick={(): void => search(searchProps)}>
          <SearchIcon />
          Search
        </SearchButton>
      </SearchBarFieldContainer>
      <SearchOptionsContainer>
        {searchFields.map((field) => (
          <SearchOption
            key={field.key}
            isSelected={searchField?.key === field.key}
            onClick={(): void => setSearchField(field)}
          >
            {field.title}
          </SearchOption>
        ))}
      </SearchOptionsContainer>
    </SearchBarContainer>
  );
};
