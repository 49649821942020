import React, { createContext, useContext, useEffect, useState } from 'react';
import { AccessSetupResponse } from '../../../__generated__/graphql';
import useGetAccessSetup from '../../../hooks/useGetAccessSetup';

export type AccessSetupData = Omit<Omit<AccessSetupResponse, 'limitKeys'>, 'gracePeriods'>;

export type AccessSetupContext = {
  data?: AccessSetupData;
  locationId?: string;
  fetchData: () => void;
};

const Context = createContext<AccessSetupContext>({
  fetchData: () => {},
});

interface ProviderProps {
  children: React.ReactNode;
  locationId?: string;
}

const Provider: React.FC<ProviderProps> = ({ children, locationId: locationIdFromProps }) => {
  const [locationId, setLocationId] = useState<string | undefined>();

  const { data, get: fetchData } = useGetAccessSetup({ locationId: locationIdFromProps });

  useEffect(() => {
    setLocationId(locationIdFromProps);
    fetchData();
  }, [fetchData]);

  return <Context.Provider value={{ fetchData, locationId, data }}>{children}</Context.Provider>;
};

export const useAccessSetup = (): AccessSetupContext => {
  const context = useContext(Context);

  if (!context) {
    throw new Error('useAccessSetup must be used within a AccessSetupProvider');
  }

  return context;
};

export const AccessSetup = { Provider };
