import 'styled-components/macro';

import KioskLiveUsageCardStyle, {
  IdleLiveUsageCardIconContainer,
  KioskLiveUsageDatesContainer,
  KioskLiveUsageReservationContainerInfoStyle,
  KioskLiveUsageReservationContainerRowStyle,
  KioskLiveUsageUserNameStyle,
  LiveUsageCardTimeFormattingArrow,
} from './styles/KioskLiveUsageCard.style';

import React, { useMemo } from 'react';
import Container from '../../../../../components/Container';
import FormattedTime from './FormattedTime';
import { GuestEngagementComponent } from './GuestEngagementComponent';
import { GuestsInfo } from './GuestsInfo';
import { KioskCardTitle } from './KioskLiveUsageCardTitle';
import { KioskLiveUsageCardProps } from '../types';
import { KioskLiveUsageModalHeaderIcon } from '../../../../../assets/icons/kioskLiveUsageModalHeaderIcon';
import { Link } from 'react-router-dom';
import LiveUsageQuickActionsButton from './LiveUsageQuickActionsButton';
import { PrebookedPackagesInfo } from './PrebookedPackagesInfo';
import { RequirementStatus } from '../../../../../__generated__/graphql';
import Theme from '../../../../../theme';
import links from '../../../../../util/links';
import { useGuestEngagementPreferences } from '../hooks/useGuestEngagementPreferences';

const KioskLiveUsageCard: React.FC<KioskLiveUsageCardProps> = ({
  kiosk,
}: KioskLiveUsageCardProps) => {
  const roomDescription = useMemo(() => {
    const resource = kiosk.accessGrant?.resources.find(({ pmsId }) => {
      return pmsId === kiosk.accessGrant?.roomNumber;
    });

    return resource?.description;
  }, [kiosk.accessGrant?.resources, kiosk.accessGrant?.roomNumber]);
  const { shouldDisplayGuestEngagementButton } = useGuestEngagementPreferences(kiosk);

  const hasFailedRequirement = useMemo(() => {
    const failedRequirement = kiosk.accessGrant?.requirements?.some(
      (requirement) => requirement.status === RequirementStatus.Failed
    );

    return !!failedRequirement;
  }, []);

  return (
    <Container
      style={{
        ...KioskLiveUsageCardStyle,
        ...(hasFailedRequirement ? { border: `2px solid ${Theme.colors.danger.hexCode}` } : {}),
      }}
    >
      <KioskCardTitle kiosk={kiosk} hasFailedRequirement={hasFailedRequirement} />
      {kiosk.accessGrant ? (
        <div style={KioskLiveUsageReservationContainerInfoStyle}>
          <div>
            <div style={KioskLiveUsageReservationContainerRowStyle}>
              <Link
                to={`${links.paths.USERS}/${kiosk.accessGrant.user.id}`}
                style={KioskLiveUsageUserNameStyle}
              >
                {kiosk.accessGrant.user.name}
              </Link>
            </div>
            <div style={KioskLiveUsageDatesContainer}>
              <FormattedTime
                dateTime={kiosk.accessGrant.startsAt}
                timezone={kiosk.kiosk.location.timezone}
              />
              <div style={LiveUsageCardTimeFormattingArrow}>&rarr;</div>
              <FormattedTime
                dateTime={kiosk.accessGrant.endsAt}
                timezone={kiosk.kiosk.location.timezone}
              />
            </div>
            {roomDescription && (
              <div style={KioskLiveUsageReservationContainerRowStyle}>{roomDescription}</div>
            )}
            <GuestsInfo kiosk={kiosk} />
            <PrebookedPackagesInfo kiosk={kiosk} />
          </div>
          <div style={KioskLiveUsageReservationContainerInfoStyle}>
            {shouldDisplayGuestEngagementButton && <GuestEngagementComponent kiosk={kiosk} />}
            <LiveUsageQuickActionsButton kiosk={kiosk} />
          </div>
        </div>
      ) : (
        <IdleLiveUsageCardIconContainer>
          <KioskLiveUsageModalHeaderIcon color={Theme.colors.whiteSmoke.hexCode} />
        </IdleLiveUsageCardIconContainer>
      )}
    </Container>
  );
};

export default KioskLiveUsageCard;
