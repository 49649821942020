import Theme from '../../../theme';
import styled from 'styled-components';

export const SettingsTabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${Theme.colors.darkAlternate.hexCode};
  border-radius: 24px;
  padding: 30px 30px 35px;
  display: flex;
  min-height: 800px;
`;

export const Container = styled.div`
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 40px;
  border-radius: 8px;
  background: ${Theme.colors.bgBlackTint.hexCode};
  width: 468px;
  border-radius: 8px;
`;

export const ControlTitle = styled.p`
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: ${Theme.colors.white.hexCode};
  margin-bottom: 24px;
`;

export const Separator = styled.hr`
  border-top: 1px solid ${Theme.colors.tableHeading.hexCode};
  margin: 50px 0;
`;

export const Title = styled.div`
  font-feature-settings: 'liga' off, 'clig' off;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.5px;
  color: ${Theme.colors.white.hexCode};
`;

export const Time = styled.div`
  font-feature-settings: 'liga' off, 'clig' off;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: ${Theme.colors.white.hexCode};
`;

export const TextGrid = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

export const editIconStyle: React.CSSProperties = {
  backgroundColor: Theme.colors.quickActionButton.hexCode,
  display: 'flex',
  width: 18,
  height: 18,
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 8,
};
