import { GWR_TENANT_ID } from '../constants';
import { KioskLiveUsageData } from '../../../../../__generated__/graphql';
import { useMemo } from 'react';

interface UseGuestEngagementPreferences {
  shouldDisplayGuestEngagementButton: boolean;
}

export const useGuestEngagementPreferences = (
  kiosk: KioskLiveUsageData
): UseGuestEngagementPreferences => {
  const shouldDisplayGuestEngagementButton = useMemo(() => {
    return kiosk.kiosk.location.tenantId === GWR_TENANT_ID;
  }, []);

  return {
    shouldDisplayGuestEngagementButton,
  };
};
