import {
  AccessGrant,
  Kiosk,
  LoadReservationStepsEnum,
  Location,
} from '../LoadReservationOnKioskModal/types';
import React, { createContext, useCallback, useContext, useState } from 'react';

import KiosksLiveUsageReduxState from '../../../../../../redux/reducers/kiosksLiveUsage.reducer/@types/KiosksLiveUsageReduxState';
import { RootState } from '../../../../../../redux/reducers';
import { useSelector } from 'react-redux';

type LoadReservationOnKioskModalContextProps = {
  isModalVisible: boolean;
  openModal: () => void;
  closeModal: () => void;

  step: LoadReservationStepsEnum;

  location?: Location;
  setLocation: (location: Location) => void;

  accessGrant?: AccessGrant;
  setAccessGrant: (accessGrant: AccessGrant) => void;

  kiosk?: Kiosk;
  setKiosk: (kiosk: Kiosk) => void;

  setConfirmation: () => void;
};

const LoadReservationOnKioskModalContext = createContext<
  LoadReservationOnKioskModalContextProps | undefined
>(undefined);

const initialState: Omit<
  LoadReservationOnKioskModalContextProps,
  'openModal' | 'closeModal' | 'setLocation' | 'setAccessGrant' | 'setKiosk' | 'setConfirmation'
> = {
  isModalVisible: false,
  step: LoadReservationStepsEnum.SelectLocation,
  location: undefined,
  accessGrant: undefined,
  kiosk: undefined,
};

export const LoadReservationOnKioskModalContextProvider: React.FC = ({ children }) => {
  // Redux state
  const { activeMembership } = useSelector((state: RootState) => state.me);
  const kiosksLiveUsageState: KiosksLiveUsageReduxState = useSelector(
    (store: RootState) => store.kioskLiveUsage
  );

  // Local variables
  const [isModalVisible, setModalVisibility] = useState(initialState.isModalVisible);

  const [step, setStep] = useState<LoadReservationStepsEnum>(initialState.step);

  const [location, setLocationState] = useState<Location | undefined>(initialState.location);
  const [accessGrant, setAccessGrantState] = useState<AccessGrant | undefined>(
    initialState.accessGrant
  );
  const [kiosk, setKioskState] = useState<Kiosk | undefined>(initialState.kiosk);

  // Functions navigation
  const setConfirmation = useCallback(() => {
    setStep(LoadReservationStepsEnum.LoadingAndFeedback);
  }, [setStep, step]);

  const setKiosk = useCallback(
    (newKiosk: Kiosk) => {
      setKioskState(newKiosk);

      const kioskLiveUsageState = kiosksLiveUsageState[newKiosk.id];
      const kioskIsIdle = !kioskLiveUsageState?.data?.accessGrant;
      if (kioskIsIdle) {
        // Kiosk is in the IDLE status, doesn't need to confirm
        setConfirmation();
      } else {
        setStep(LoadReservationStepsEnum.Confirmation);
      }
    },
    [setConfirmation, setKioskState, setStep, step]
  );

  const setAccessGrant = useCallback(
    (newAccessGrant: AccessGrant) => {
      setAccessGrantState(newAccessGrant);
      setStep(LoadReservationStepsEnum.SelectKiosk);
    },
    [setAccessGrantState, setStep, step]
  );

  const setLocation = useCallback(
    (newLocation: Location) => {
      setLocationState(newLocation);
      setStep(LoadReservationStepsEnum.SelectReservation);
    },
    [setLocationState, setStep, step]
  );

  // Modal opening
  const openModal = useCallback(() => {
    if (activeMembership.location) {
      // Logged in on a Location membership, doesn't need to select the location
      setLocation(activeMembership.location);
    }

    setModalVisibility(true);
  }, [setModalVisibility, activeMembership]);

  const closeModal = useCallback(() => {
    setModalVisibility(false);

    // Reset to initial state
    setModalVisibility(initialState.isModalVisible);
    setStep(initialState.step);
    setLocationState(initialState.location);
    setAccessGrantState(initialState.accessGrant);
    setKioskState(initialState.kiosk);
  }, [setModalVisibility, setStep, setLocationState, setAccessGrantState, setKioskState]);

  return (
    <LoadReservationOnKioskModalContext.Provider
      value={{
        isModalVisible,
        openModal,
        closeModal,
        step,
        location,
        setLocation,
        accessGrant,
        setAccessGrant,
        kiosk,
        setKiosk,
        setConfirmation,
      }}
    >
      {children}
    </LoadReservationOnKioskModalContext.Provider>
  );
};

// eslint-disable-next-line max-len
export const useLoadReservationOnKioskModalContext = (): LoadReservationOnKioskModalContextProps => {
  const context = useContext(LoadReservationOnKioskModalContext);

  if (!context) {
    throw new Error(
      'useLoadReservationOnKioskModalContext must be used within a LoadReservationOnKioskModalProvider'
    );
  }

  return context;
};
