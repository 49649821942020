export enum LoadReservationStepsEnum {
  SelectLocation,
  SelectReservation,
  SelectKiosk,
  Confirmation,
  LoadingAndFeedback,
}

export type Location = {
  id: string;
  name: string;
};

export type Kiosk = {
  id: string;
  name: string;
};

export type AccessGrant = {
  id: string;
  confirmationCode?: string | null;
  user: {
    name?: string | null;
  };
};
