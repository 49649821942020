/* eslint-disable sort-keys */
import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3LocalizationCheckInSchema: NodeSchemaProperties = {
  checkIn: {
    title: '#25 Check In',
    type: 'object',
    properties: {
      'title:enable': {
        description: '[checkIn.title]',
        title: '#1 Title',
        type: 'boolean',
      },
      title: {
        type: 'string',
      },
      'subtitle:enable': {
        description: '[checkIn.subtitle]',
        title: '#2 Subtitle',
        type: 'boolean',
      },
      subtitle: {
        type: 'string',
      },
      'description:enable': {
        description: '[checkIn.description]',
        title: '#3 Description',
        type: 'boolean',
      },
      description: {
        type: 'string',
      },
      requirementSteps: {
        title: '#4 Requirement Steps',
        type: 'object',
        properties: {
          'title:enable': {
            description: '[checkIn.requirementSteps.title]',
            title: '#4.1 Title',
            type: 'boolean',
          },
          title: {
            type: 'string',
          },
          'idVerification:enable': {
            description: '[checkIn.requirementSteps.idVerification]',
            title: '#4.2 Id Verification',
            type: 'boolean',
          },
          idVerification: {
            type: 'string',
          },
          'expectedArrival:enable': {
            description: '[checkIn.requirementSteps.expectedArrival]',
            title: '#4.3 Expected Arrival',
            type: 'boolean',
          },
          expectedArrival: {
            type: 'string',
          },
          'paymentAuthorization:enable': {
            description: '[checkIn.requirementSteps.paymentAuthorization]',
            title: '#4.4 Payment Authorization',
            type: 'boolean',
          },
          paymentAuthorization: {
            type: 'string',
          },
          'paymentCapture:enable': {
            description: '[checkIn.requirementSteps.paymentCapture]',
            title: '#4.5 Payment Capture',
            type: 'boolean',
          },
          paymentCapture: {
            type: 'string',
          },
          'paymentStatus:enable': {
            description: '[checkIn.requirementSteps.paymentStatus]',
            title: '#4.6 Payment Status',
            type: 'boolean',
          },
          paymentStatus: {
            type: 'string',
          },
          'termsAndConditions:enable': {
            description: '[checkIn.requirementSteps.termsAndConditions]',
            title: '#4.7 Terms And Conditions',
            type: 'boolean',
          },
          termsAndConditions: {
            type: 'string',
          },
          'userInformation:enable': {
            description: '[checkIn.requirementSteps.userInformation]',
            title: '#4.8 User Information',
            type: 'boolean',
          },
          userInformation: {
            type: 'string',
          },
          'packages:enable': {
            description: '[checkIn.requirementSteps.packages]',
            title: '#4.9 Packages',
            type: 'boolean',
          },
          packages: {
            type: 'string',
          },
          'checkIn:enable': {
            description: '[checkIn.requirementSteps.checkIn]',
            title: '#4.10 Check In',
            type: 'boolean',
          },
          checkIn: {
            type: 'string',
          },
        },
      },
      'continueButton:enable': {
        description: '[checkIn.continueButton]',
        title: '#5 Continue Button',
        type: 'boolean',
      },
      continueButton: {
        type: 'string',
      },
      'startButton:enable': {
        description: '[checkIn.startButton]',
        title: '#6 Start Button',
        type: 'boolean',
      },
      startButton: {
        type: 'string',
      },
      'performingCheckIn:enable': {
        description: '[checkIn.performingCheckIn]',
        title: '#7 Performing Check In',
        type: 'boolean',
      },
      performingCheckIn: {
        type: 'string',
      },
      success: {
        title: '#8 Success',
        type: 'object',
        properties: {
          'title:enable': {
            description: '[checkIn.success.title]',
            title: '#8.1 Title',
            type: 'boolean',
          },
          title: {
            type: 'string',
          },
          'subtitle:enable': {
            description: '[checkIn.success.subtitle]',
            title: '#8.2 Subtitle',
            type: 'boolean',
          },
          subtitle: {
            type: 'string',
          },
          'description:enable': {
            description: '[checkIn.success.description]',
            title: '#8.3 Description',
            type: 'boolean',
          },
          description: {
            type: 'string',
          },
          'button:enable': {
            description: '[checkIn.success.button]',
            title: '#8.4 Button',
            type: 'boolean',
          },
          button: {
            type: 'string',
          },
          'buttonMobileKey:enable': {
            description: '[checkIn.success.buttonMobileKey]',
            title: '#8.5 Button Mobile Key',
            type: 'boolean',
          },
          buttonMobileKey: {
            type: 'string',
          },
        },
      },
      error: {
        title: '#9 Error',
        type: 'object',
        properties: {
          'title:enable': {
            description: '[checkIn.error.title]',
            title: '#9.1 Title',
            type: 'boolean',
          },
          title: {
            type: 'string',
          },
          'subtitle:enable': {
            description: '[checkIn.error.subtitle]',
            title: '#9.2 Subtitle',
            type: 'boolean',
          },
          subtitle: {
            type: 'string',
          },
          'description:enable': {
            description: '[checkIn.error.description]',
            title: '#9.3 Description',
            type: 'boolean',
          },
          description: {
            type: 'string',
          },
          'okButton:enable': {
            description: '[checkIn.error.okButton]',
            title: '#9.4 OK Button',
            type: 'boolean',
          },
          okButton: {
            type: 'string',
          },
        },
      },
      preCheckInSuccessModal: {
        title: '#10 Pre Check In Success Modal',
        type: 'object',
        properties: {
          'title:enable': {
            description: '[checkIn.preCheckInSuccessModal.title]',
            title: '#10.1 Title',
            type: 'boolean',
          },
          title: {
            type: 'string',
          },
          'description:enable': {
            description: '[checkIn.preCheckInSuccessModal.description]',
            title: '#10.2 Description',
            type: 'boolean',
          },
          description: {
            type: 'string',
          },
        },
      },
      retryPayment: {
        title: '#11 Retry payment',
        type: 'object',
        properties: {
          'title:enable': {
            description: '[checkIn.retryPayment.title]',
            title: '#11.1 Title',
            type: 'boolean',
          },
          title: {
            type: 'string',
          },
          'subtitle:enable': {
            description: '[checkIn.retryPayment.subtitle]',
            title: '#11.2 Subtitle',
            type: 'boolean',
          },
          subtitle: {
            type: 'string',
          },
          'description:enable': {
            description: '[checkIn.retryPayment.description]',
            title: '#11.3 Description',
            type: 'boolean',
          },
          description: {
            type: 'string',
          },
          'retryButton:enable': {
            description: '[checkIn.retryPayment.retryButton]',
            title: '#11.4 Retry button',
            type: 'boolean',
          },
          retryButton: {
            type: 'string',
          },
          'cancelButton:enable': {
            description: '[checkIn.retryPayment.cancelButton]',
            title: '#11.5 Cancel button',
            type: 'boolean',
          },
          cancelButton: {
            type: 'string',
          },
          'remoteAssistanceText:enable': {
            description: '[checkIn.retryPayment.remoteAssistanceText]',
            title: '#11.6 Remote assistance text',
            type: 'boolean',
          },
          remoteAssistanceText: {
            type: 'string',
          },
        },
      },
    },
  },
};

export const mobileV3LocalizationCheckInUiSchema: NodeUiSchemaProperties = {
  checkIn: {
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    subtitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    description: {
      'ui:label': false,
      'ui:readonly': true,
    },
    requirementSteps: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      idVerification: {
        'ui:label': false,
        'ui:readonly': true,
      },
      expectedArrival: {
        'ui:label': false,
        'ui:readonly': true,
      },
      paymentAuthorization: {
        'ui:label': false,
        'ui:readonly': true,
      },
      paymentCapture: {
        'ui:label': false,
        'ui:readonly': true,
      },
      paymentStatus: {
        'ui:label': false,
        'ui:readonly': true,
      },
      termsAndConditions: {
        'ui:label': false,
        'ui:readonly': true,
      },
      userInformation: {
        'ui:label': false,
        'ui:readonly': true,
      },
      packages: {
        'ui:label': false,
        'ui:readonly': true,
      },
      checkIn: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    continueButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    startButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    performingCheckIn: {
      'ui:label': false,
      'ui:readonly': true,
    },
    success: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      subtitle: {
        'ui:label': false,
        'ui:readonly': true,
      },
      description: {
        'ui:label': false,
        'ui:readonly': true,
      },
      button: {
        'ui:label': false,
        'ui:readonly': true,
      },
      buttonMobileKey: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    error: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      subtitle: {
        'ui:label': false,
        'ui:readonly': true,
      },
      description: {
        'ui:label': false,
        'ui:readonly': true,
      },
      okButton: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    preCheckInSuccessModal: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      description: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    retryPayment: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      subtitle: {
        'ui:label': false,
        'ui:readonly': true,
      },
      description: {
        'ui:label': false,
        'ui:readonly': true,
      },
      retryButton: {
        'ui:label': false,
        'ui:readonly': true,
      },
      cancelButton: {
        'ui:label': false,
        'ui:readonly': true,
      },
      remoteAssistanceText: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
  },
};
