import {
  CustomRequirementUpdateInput,
  Query,
  QueryAccessGrantArgs,
  RequirementType,
} from '../../../../../../__generated__/graphql';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { KeyOverrideContainer, LoaderContainer } from './styles/RequirementsOverride.style';
import React, { useCallback, useEffect, useMemo } from 'react';
import { RequirementsOverrideForm, UpdatedRequirementsList } from '../../types';
import { BaseModalContainer } from '../../../../../../components/BaseQuickActionModal/styles/BaseModal.styles';
import BaseModalFooter from '../../../../../../components/BaseQuickActionModal/BaseModalFooter';
import GET_ACCESS_GRANT from '../../../../../../graphql/getAccessGrant';
import LoadingAnimation from '../../../../../../components/LoadingAnimation';
import RequirementItemRow from './RequirementItem';
import { SUPPORTED_REQUIREMENTS } from '../../constants';
import { openQuickActionProcessingModal } from '../../../../../../redux/actions/modals.actions';
import { useDispatch } from 'react-redux';
import { useKioskLiveUsageContext } from '../contexts/KioskLiveUsageContext';
import { useLazyQuery } from '@apollo/client';
import { useUpdateRequirement } from '../../../../../../hooks/useUpdateRequirement';

const RequirementsOverride: React.FC = () => {
  const { quickActionsLiveUsageData, closeQuickActionsModal } = useKioskLiveUsageContext();
  const { updateRequirements } = useUpdateRequirement();
  const dispatch = useDispatch();
  const form = useForm<RequirementsOverrideForm>({
    defaultValues: {},
    reValidateMode: 'onChange',
  });

  const [getAccessGrant, { data, loading }] = useLazyQuery<
    Pick<Query, 'accessGrant'>,
    QueryAccessGrantArgs
  >(GET_ACCESS_GRANT, {
    fetchPolicy: 'network-only',
  });

  const findMatchingSupportedRequirement = useCallback((type: RequirementType) => {
    const matchingRequirement = SUPPORTED_REQUIREMENTS.find(
      (supportedRequirement) => type === supportedRequirement.type
    );

    return matchingRequirement;
  }, []);

  const selectionsHaveChanged = useMemo(() => {
    const formValues = form.watch();
    const selectionHasChanged = Object.entries(formValues).some(([, value]) => {
      return value !== undefined;
    });

    return selectionHasChanged;
  }, [data?.accessGrant?.requirements, form.watch()]);

  const onSubmit: SubmitHandler<RequirementsOverrideForm> = async (formData): Promise<void> => {
    const accessGrantId = quickActionsLiveUsageData?.accessGrant?.id || '';
    const kioskId = quickActionsLiveUsageData?.kiosk?.id || '';
    const requirementArgsList: CustomRequirementUpdateInput[] = [];
    const newRequirements: UpdatedRequirementsList[] = [];

    data?.accessGrant?.requirements.forEach(({ id, type, status: currentStatus }) => {
      const newStatus = formData[type];

      if (newStatus && newStatus !== currentStatus) {
        requirementArgsList.push({
          id,
          status: newStatus,
        });

        const requirementObject = findMatchingSupportedRequirement(type);

        if (requirementObject) {
          const selection = requirementObject.selectors.find(({ status }) => {
            return status === newStatus;
          });

          newRequirements.push({
            requirement: requirementObject.displayText,
            newValue: selection?.text || '',
          });
        }
      }
    });

    closeQuickActionsModal();

    dispatch(
      openQuickActionProcessingModal({
        action: () =>
          updateRequirements({
            accessGrantId,
            kioskId,
            requirementArgsList,
          }),
        ctaType: 'remotePrintKeyCard',
        modalAlignment: 'center',
        loadingText: 'Updating kiosk...',
      })
    );
  };

  useEffect(() => {
    const accessGrantId = quickActionsLiveUsageData?.accessGrant?.id;

    if (accessGrantId) {
      getAccessGrant({
        variables: {
          where: {
            id: accessGrantId,
          },
        },
      });
    }
  }, [quickActionsLiveUsageData]);

  return (
    <FormProvider {...form}>
      <form
        onSubmit={(event: React.FormEvent<HTMLFormElement>): void => {
          event.preventDefault();
        }}
      >
        <BaseModalContainer>
          {loading ? (
            <LoaderContainer>
              <LoadingAnimation />
            </LoaderContainer>
          ) : (
            <KeyOverrideContainer>
              {data?.accessGrant?.requirements
                .sort((a, b) => {
                  const supportedOrder = SUPPORTED_REQUIREMENTS.map((req) => req.type);
                  return supportedOrder.indexOf(a.type) - supportedOrder.indexOf(b.type);
                })
                .map((requirement) => {
                  const matchingRequirement = findMatchingSupportedRequirement(requirement.type);

                  if (!matchingRequirement) {
                    return null;
                  }

                  return (
                    <RequirementItemRow
                      requirement={requirement}
                      matchingRequirement={matchingRequirement}
                    />
                  );
                })
                .filter(Boolean)}
            </KeyOverrideContainer>
          )}

          <BaseModalFooter
            confirmButton={{
              action: (): void => {
                form.handleSubmit(onSubmit)();
              },
              text: 'Confirm & Update Kiosk',
              disabled: !selectionsHaveChanged,
            }}
            cancelButton={{
              action: (): void => {
                closeQuickActionsModal();
              },
            }}
          />
        </BaseModalContainer>
      </form>
    </FormProvider>
  );
};

export default RequirementsOverride;
