import {
  KioskLiveUsageData,
  Requirement,
  RequirementStatus,
  RequirementType,
} from '../../../../../__generated__/graphql';

export type KioskLiveUsageCardProps = {
  kiosk: KioskLiveUsageData;
};

export type KioskCardTitleProps = {
  kiosk: KioskLiveUsageData;
  hasFailedRequirement: boolean;
};

export enum QuickActionsModalTabType {
  REQUIREMENTS_OVERRIDE = 'Requirements Override',
  KEY_CARD_DISPENSING = 'Keycard Dispensing',
}

export type KeyDispensingStepProps = {
  imageSrc: string;
  step: number;
  title: string;
};

export type RequirementsOverrideForm = {
  [K in RequirementType]?: RequirementStatus;
};

export type UpdatedRequirementsList = {
  requirement: string;
  newValue: string;
};

export enum SelectorTypeEnum {
  AGENT_COMPLETE = 'Agent Complete',
  REQUEST_AGAIN = 'Request Again',
}

export type SupportedRequirement = {
  type: RequirementType;
  displayText: string;
  selectors: {
    text: SelectorTypeEnum;
    status: RequirementStatus;
    disabled?: boolean;
  }[];
};

export type RequirementItemProps = {
  requirement: Requirement;
  matchingRequirement: SupportedRequirement;
};

export type RequirementStateSelectorProps = {
  inputs: {
    label: string;
    name: RequirementType;
    value: RequirementStatus;
    disabled?: boolean;
  }[];
};
