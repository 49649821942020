export default function generateTableDataKey({
  key,
  value,
  index,
}: {
  key: string;
  value: React.ReactElement | string | number;
  index: number;
}): string {
  return `table-data-${key}-${value}-${index}`;
}
