import React, { useCallback } from 'react';
import { SelectValueButton, TableHeader, TableHeaderCell } from '../DataTableV2.styles';

import { DataTableV2Props } from '../@types';
import { DownArrowIcon } from '../../../assets/icons/downArrowIcon';
import { SortOrder } from '../../../__generated__/graphql';
import Theme from '../../../theme';
import { useDataTableV2Context } from '../context/DataTableV2Context';

type DataTableHeaderProps<T> = Pick<
  DataTableV2Props<T>,
  'headers' | 'onSelectValue' | 'TableHeaderComponent' | 'sort' | 'sortFields'
>;

export const DataTableHeader = <T,>(props: DataTableHeaderProps<T>): React.ReactElement => {
  const {
    headers,
    onSelectValue,
    TableHeaderComponent: _TableHeaderComponent,
    sort,
    sortFields,
  } = props;

  const TableHeaderComponent = _TableHeaderComponent || TableHeader;

  const { sortParams } = useDataTableV2Context();

  const getFieldIsSortable = useCallback(
    (key: string) => {
      return sortFields?.includes(key) ?? false;
    },
    [sortFields]
  );

  const getSortOrder = useCallback(
    (key: string): SortOrder | null => {
      if (!sortParams || !getFieldIsSortable(key)) {
        return null;
      }

      const fieldIsSort = sortParams.key === key;
      if (!fieldIsSort) {
        return null;
      }

      return sortParams.order;
    },
    [getFieldIsSortable, sortParams]
  );

  return (
    <TableHeaderComponent>
      {headers?.map(({ displayValue, flex, key }, index) => {
        const sortOrder = getSortOrder(key);
        const rotateIcon = sortOrder === SortOrder.Asc ? 0 : 180;
        const fieldIsSortable = getFieldIsSortable(key);

        return (
          <TableHeaderCell
            key={`header-${index}`}
            style={{ flex }}
            isSortable={fieldIsSortable}
            isSort={Boolean(sortOrder)}
            {...(fieldIsSortable ? { onClick: (): void => sort?.(key) } : {})}
          >
            <th>{displayValue}</th>
            {sortOrder && (
              <DownArrowIcon
                color={Theme.colors.quickActionButton.hexCode}
                rotate={rotateIcon}
                size="14"
              />
            )}
          </TableHeaderCell>
        );
      })}
      {/* Display the Select Value button hidden only to match with the Table body size */}
      {onSelectValue && (
        <tr>
          <th>
            <SelectValueButton style={{ visibility: 'hidden' }}>&rarr;</SelectValueButton>
          </th>
        </tr>
      )}
    </TableHeaderComponent>
  );
};
