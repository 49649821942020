import * as yup from 'yup';
import {
  AccessSetupGracePeriodModalStyle,
  Container,
  Input,
  InputMessage,
  OptionLabel,
} from '../styles/AccessSetupModals.styles';
import {
  BottomContainer,
  CheckboxContainer,
  GridContainer,
  InputContainer,
} from '../styles/GracePeriodModal.styles';
import { FormProvider, useForm } from 'react-hook-form';
import React, { useCallback, useMemo, useState } from 'react';
import { BaseModalHeader } from '../../ActionProcessingModal/components/BaseModalHeader';
import { Button as ButtonComponent } from '../components/Button';
import { Label } from '../styles/GracePeriodModal.styles';
import Modal from 'react-modal';
import { closeForm } from '../../../redux/actions/modals.actions';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';

export const AccessSetupGracePeriodModal = 'AccessSetupGracePeriodModal';

interface GracePeriodTime {
  hours: number;
  minutes: number;
}
interface GracePeriodModalProps {
  timeInMinutes?: number;
  onSave?: (timeInMinutes?: number) => void;
}

export const GracePeriodTimeValidationSchema = yup
  .object()
  .shape({
    hours: yup.number(),
    minutes: yup.number(),
  })
  .default({});

export const GracePeriodModal: React.FC<GracePeriodModalProps> = ({ timeInMinutes, onSave }) => {
  const dispatch = useDispatch();

  const handleCancel = useCallback((): void => {
    dispatch(closeForm());
  }, []);

  const time = useMemo(() => {
    if (!timeInMinutes) {
      return { hours: 0, minutes: 0 };
    }

    const hoursFromMinutes = Math.floor(timeInMinutes / 60);
    const minutes = timeInMinutes % 60;

    return { hours: hoursFromMinutes, minutes };
  }, [timeInMinutes]);

  const form = useForm<GracePeriodTime>({
    defaultValues: { hours: time.hours, minutes: time.minutes },
    mode: 'onTouched',
    reValidateMode: 'onChange',
    resolver: yupResolver(GracePeriodTimeValidationSchema),
  });

  const hours = form.getValues('hours');
  const minutes = form.getValues('minutes');

  const [allowAllDayEarlyCheckIn, setAllowAllDayEarlyCheckIn] = useState(
    !time.hours && !time.minutes
  );

  const handleSave = useCallback(async (): Promise<void> => {
    if (allowAllDayEarlyCheckIn) {
      onSave?.(undefined);
      return;
    }

    const totalMinutes = Number(hours) * 60 + Number(minutes);

    const isValid = await form.trigger();

    if (!isValid) {
      return;
    }

    onSave?.(totalMinutes);
  }, [time, hours, minutes, allowAllDayEarlyCheckIn]);

  const handleCheckboxClick = useCallback(() => {
    setAllowAllDayEarlyCheckIn(!allowAllDayEarlyCheckIn);
  }, [time, setAllowAllDayEarlyCheckIn]);

  return (
    <Modal isOpen onRequestClose={handleCancel} style={AccessSetupGracePeriodModalStyle}>
      <BaseModalHeader headerTitle="Grace Period" onClose={handleCancel} />
      <Container>
        <FormProvider {...form}>
          <InputContainer onSubmit={form.handleSubmit(handleSave)}>
            <GridContainer>
              <OptionLabel>Hours</OptionLabel>
              <Input
                disabled={allowAllDayEarlyCheckIn}
                name="hours"
                ref={form.register}
                type="number"
                error={form.errors.hours?.message}
              />
              <InputMessage>
                {form.errors.hours?.message ? 'Invalid input' : undefined}
              </InputMessage>
            </GridContainer>
            <GridContainer>
              <OptionLabel>Minutes</OptionLabel>
              <Input
                disabled={allowAllDayEarlyCheckIn}
                name="minutes"
                ref={form.register}
                type="number"
                error={form.errors.minutes?.message}
              />
              <InputMessage>
                {form.errors.minutes?.message ? 'Invalid input' : undefined}
              </InputMessage>
            </GridContainer>
            <CheckboxContainer>
              <input
                type="checkbox"
                checked={allowAllDayEarlyCheckIn}
                onChange={handleCheckboxClick}
              />
              <Label>Allow the guest to check-in for free all day.</Label>
            </CheckboxContainer>
            <BottomContainer>
              <ButtonComponent.Cancel handleCancel={handleCancel} />
              <ButtonComponent.Save handleSave={handleSave} />
            </BottomContainer>
          </InputContainer>
        </FormProvider>
      </Container>
    </Modal>
  );
};
