import styled, { FlattenSimpleInterpolation, css } from 'styled-components';
import Theme from '../../../theme';

interface SubmitButtonProps {
  enabled?: boolean;
}

const commomStyles = css<SubmitButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  color: ${Theme.colors.white.hexCode};

  ${({ enabled }): FlattenSimpleInterpolation => {
    if (enabled) {
      return css`
        background-color: ${Theme.colors.linkWater.hexCode};
      `;
    }

    return css`
      background-color: ${Theme.colors.buttonInputEmbedded.hexCode};

      :hover {
        background-color: ${Theme.colors.buttonDominant.hexCode};
        transition: all 0.2s;
      }
    `;
  }}
`;

const Submit = styled.button<SubmitButtonProps>`
  ${commomStyles}
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  border: none;
  margin: 20px 0;
  gap: 8px;
  border-radius: 10px;
`;

const Edit = styled.button<SubmitButtonProps>`
  ${commomStyles}
  padding: 10px;
  height: 36px;
  width: 36px;
  border: none;
  border-radius: 8px;
`;

export const ButtonStyle = { Submit, Edit };
