import {
  DataTableRowValue,
  DataTableValue,
} from '../../../../../../../../components/DataTableV2/@types';
import React, { useCallback, useMemo } from 'react';

import { DataTableV2 } from '../../../../../../../../components/DataTableV2';
import { DataTableV2ContextProvider } from '../../../../../../../../components/DataTableV2/context/DataTableV2Context';
import { Datum } from '../../../../../../../../hooks/@types/QueryHookData';
import { KioskStatus } from './components/KioskStatus';
import useGetKiosksOnlineByLocation from '../../../../../../../../hooks/useGetKiosksOnlineByLocation';
import { useLoadReservationOnKioskModalContext } from '../../../contexts/LoadReservationOnKioskModalContext';

enum SelectKioskFields {
  Name = 'Name',
}

const SelectKioskTable: React.FC = () => {
  const { setKiosk, location } = useLoadReservationOnKioskModalContext();

  if (!location) {
    throw new Error('The location should be selected for the Select Kiosk component');
  }

  const { data: kiosks, loading, query: getKiosks, hasMoreData } = useGetKiosksOnlineByLocation({
    take: 15,
    locationId: location.id,
  });

  const kiosksDataTableValue = useMemo((): DataTableValue<Datum> => {
    return kiosks?.map(
      (k): DataTableRowValue<Datum> => ({
        value: k,
        key: k.id,
        fields: [
          {
            value: {
              key: SelectKioskFields.Name,
              displayValue: k.name,
            },
          },
        ],
      })
    );
  }, [kiosks]);

  const onSelectValue = useCallback(
    (kiosk: Datum) => {
      setKiosk({ id: kiosk.id, name: kiosk.name });
    },
    [setKiosk]
  );

  return (
    <DataTableV2<Datum>
      data={kiosksDataTableValue}
      onSelectValue={onSelectValue}
      hasMoreData={hasMoreData}
      loadData={getKiosks}
      isLoading={loading}
      PrefixComponent={KioskStatus}
      noResultsMessage="No kiosks available"
    />
  );
};

export const SelectKiosk: React.FC = () => {
  return (
    <DataTableV2ContextProvider>
      <SelectKioskTable />
    </DataTableV2ContextProvider>
  );
};
