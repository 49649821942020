import {
  AccessSetupModalStyle,
  BottomContainer,
  Button,
  Container,
  Text,
} from '../styles/AccessSetupModals.styles';
import React, { useCallback } from 'react';
import { BaseModalHeader } from '../../ActionProcessingModal/components/BaseModalHeader';
import Modal from 'react-modal';
import Theme from '../../../theme';
import { closeAccessSetupConfirmationModal } from '../../../redux/actions/modals.actions';
import { useDispatch } from 'react-redux';

export const AccessSetupConfirmationModal = 'AccessSetupConfirmationModal';

interface ConfirmaionModal {
  headerTitle: string;
  content: string | JSX.Element;
  id: string;
  onConfirm?: () => void;
  onCancel?: () => void;
}

export const ConfirmationModal: React.FC<ConfirmaionModal> = ({
  onCancel,
  onConfirm,
  content,
  headerTitle,
}) => {
  const dispatch = useDispatch();
  const handleConfirm = useCallback((): void => {
    onConfirm?.();
  }, []);

  const handleCancel = useCallback((): void => {
    if (onCancel) {
      onCancel();
    } else {
      dispatch(closeAccessSetupConfirmationModal());
    }
  }, []);

  return (
    <Modal isOpen onRequestClose={handleCancel} style={AccessSetupModalStyle}>
      <BaseModalHeader headerTitle={headerTitle} onClose={handleCancel} />
      <Container style={{ height: '100%' }}>
        <Text>{content}</Text>
        <BottomContainer>
          <Button
            color={Theme.colors.black.hexCode}
            background={Theme.colors.whiteSmoke.hexCode}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            color={Theme.colors.white.hexCode}
            background={Theme.colors.quickActionButton.hexCode}
            onClick={handleConfirm}
          >
            Confirm
          </Button>
        </BottomContainer>
      </Container>
    </Modal>
  );
};
