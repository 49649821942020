import Lottie from 'react-lottie';
import React from 'react';
import SpinnerAnimation from '../../assets/animations/spinner.json';

interface Props {
  height?: number;
  width?: number;
}

const LoadingAnimation: React.FC<Props> = ({ height, width }) => {
  return (
    <Lottie
      height={height || 64}
      width={width || 64}
      options={{
        animationData: SpinnerAnimation,
      }}
    />
  );
};

export default LoadingAnimation;
