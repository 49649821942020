/* eslint-disable @typescript-eslint/explicit-function-return-type */
import styled, { css } from 'styled-components';
import Theme from '../../../../theme';

export interface TextHighlightedProps {
  color?: string;
}

export const TextHighlighted = styled.span<TextHighlightedProps>`
  ${({ color }) => {
    return css`
      color: ${color || Theme.colors.quickActionButton.hexCode};
    `;
  }}
`;
