import {
  OperationMessageText,
  OperationStateContainer,
  SuccessAnimationContainer,
} from './components/BaseModal.styles';
import { useCallback, useEffect, useState } from 'react';
import { ActionBarModalErrorIcon } from '../../assets/icons/actionBarModalErrorIcon';
import { BaseActionModal } from './components/BaseActionModal';
import { ErrorMessageText } from './components/ErrorMessageText';
import LoadingHourglassAnimation from '../../assets/animations/loading-hourglass.json';
import Lottie from 'react-lottie';
import React from 'react';
import SuccessAnimation from '../../assets/animations/success.json';
import { closeActionProcessingModal } from '../../redux/actions/modals.actions';
import { useDispatch } from 'react-redux';

interface ActionError {
  title: string;
  message: string;
}

interface ActionResult {
  success: boolean;
  error?: ActionError;
}

interface ActionProcessingModalProps {
  action: () => Promise<ActionResult>;
  error?: ActionError;
}

export const ActionProcessingModal: React.FC<ActionProcessingModalProps> = ({
  action,
  error: defaultError,
}) => {
  const [actionResult, setActionResult] = useState<ActionResult | undefined>();
  const [loading, setLoading] = useState<boolean | undefined>();
  const dispatch = useDispatch();

  const executeAction = useCallback(async (): Promise<void> => {
    setLoading(true);

    try {
      const result = await action();
      setActionResult(result);
    } catch (error) {
      const message = (error as Error)?.message;

      setActionResult({
        success: false,
        error: {
          title: defaultError?.title ?? 'Error',
          message: defaultError?.message ?? message,
        },
      });
    } finally {
      setLoading(false);
    }
  }, []);

  const closeModal = useCallback(() => {
    dispatch(closeActionProcessingModal());
  }, []);

  const renderContainerElement = (): React.ReactNode => {
    if (!loading && actionResult?.success) {
      return (
        <OperationStateContainer>
          <SuccessAnimationContainer
            height={160}
            width={160}
            options={{
              animationData: SuccessAnimation,
              loop: false,
            }}
          />
          <OperationMessageText>Success!</OperationMessageText>
        </OperationStateContainer>
      );
    }

    if (actionResult?.error) {
      return <ErrorMessageText message={actionResult?.error.message} />;
    }

    return (
      <OperationStateContainer>
        <Lottie
          height={240}
          width={240}
          style={{ margin: -40 }}
          options={{
            animationData: LoadingHourglassAnimation,
          }}
        />
        <OperationMessageText>Loading...</OperationMessageText>
      </OperationStateContainer>
    );
  };

  useEffect(() => {
    executeAction();
  }, []);

  useEffect(() => {
    if (actionResult?.success) {
      setTimeout(() => {
        closeModal();
      }, 4000);
    }
  }, [actionResult?.success]);

  return (
    <BaseActionModal
      shouldAllowSoftDismissing={!loading}
      headerTitle={actionResult?.error?.title}
      icon={<ActionBarModalErrorIcon />}
      confirmButton={
        actionResult?.error
          ? {
              action: closeModal,
              text: 'Back',
            }
          : undefined
      }
      cancelButton={
        actionResult?.error
          ? {
              action: closeModal,
              text: 'Cancel',
            }
          : undefined
      }
    >
      {renderContainerElement()}
    </BaseActionModal>
  );
};
