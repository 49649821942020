import { BackButton, BaseModalFooterContainer, CancelButton } from './BaseModalFooter.styles';
import { BaseActionModalProps } from './BaseActionModal';
import React from 'react';

export type BaseModalFooterProps = Pick<BaseActionModalProps, 'confirmButton' | 'cancelButton'>;

export const BaseModalFooter: React.FC<BaseModalFooterProps> = ({
  confirmButton,
  cancelButton,
}) => {
  return (
    <BaseModalFooterContainer>
      {cancelButton?.action && (
        <CancelButton onClick={cancelButton.action}>{cancelButton.text ?? 'Confirm '}</CancelButton>
      )}
      {confirmButton?.action && (
        <BackButton onClick={confirmButton.action}>{confirmButton.text ?? 'Confirm '}</BackButton>
      )}
    </BaseModalFooterContainer>
  );
};
