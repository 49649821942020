import {
  AccessSetupCheckInAndCheckoutTimeModalStyle,
  BottomContainer,
  Input,
  InputMessage,
  OptionLabel,
} from '../styles/AccessSetupModals.styles';
import { FormProvider, useForm } from 'react-hook-form';
import React, { useCallback, useMemo } from 'react';
import { boolean, object, string } from 'yup';
import { closeForm, openActionProcessingModal } from '../../../redux/actions/modals.actions';
import { ActionResult } from '../types';
import { BaseModalHeader } from '../../ActionProcessingModal/components/BaseModalHeader';
import { Button as ButtonComponent } from './Button';
import { Container } from '../styles/VipOrLoyaltyCodeOptionModal.styles';
import { EarlyCheckInPackageData } from './Settings';
import { InputTags } from './InputTags';
import Modal from 'react-modal';
import { Radio } from '../styles/RadioOption.styles';
import { RadioOption } from './RadioOption';
import { useAccessSetup } from '../../../pages/Location/components/AccessSetupContext';
import { useCreateEarlyCheckInPackage } from '../../../hooks/useCreateEarlyCheckInPackage';
import { useDispatch } from 'react-redux';
import { useUpdateEarlyCheckInPackage } from '../../../hooks/useUpdateEarlyCheckInPackage';
import { yupResolver } from '@hookform/resolvers/yup';

export const AccessSetupVipAndLoyaltyModal = 'AccessSetupVipAndLoyaltyModal';

export interface VipOrLoyaltyOptions {
  loyaltyCodes?: string;
  pmsPackageId?: string;
  freeUpgrade?: boolean;
}

const VipOrLoyaltyOptionsModalValuesValidationSchema = object().shape({
  loyaltyCodes: string().required(),
  pmsPackageId: string(),
  freeUpgrade: boolean().required(),
});

interface VipOrLoyaltyModalModalProps {
  selectedPackage?: EarlyCheckInPackageData;
}

export const VipOrLoyaltyOptionsModal: React.FC<VipOrLoyaltyModalModalProps> = ({
  selectedPackage,
}) => {
  const dispatch = useDispatch();
  const { locationId, fetchData } = useAccessSetup();
  const { promise: updateEarlyCheckInPackage } = useUpdateEarlyCheckInPackage({
    onCompleted: fetchData,
  });
  const { promise: createEarlyCheckInPackage } = useCreateEarlyCheckInPackage({
    onCompleted: fetchData,
  });

  const [selectedOption, setSelectedOption] = React.useState('pms');

  const loyaltyCodes = useMemo(() => selectedPackage?.loyaltyCodes?.join(', '), [selectedPackage]);
  const pmsPackageId = useMemo(() => selectedPackage?.externalId ?? '', [selectedPackage]);
  const freeUpgrade = useMemo(() => selectedPackage?.freeUpgrade ?? false, [selectedPackage]);

  const form = useForm<VipOrLoyaltyOptions>({
    defaultValues: {
      loyaltyCodes,
      pmsPackageId,
      freeUpgrade,
    },
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(VipOrLoyaltyOptionsModalValuesValidationSchema),
  });

  const handleCancel = useCallback((): void => {
    dispatch(closeForm());
  }, []);

  const handleSave = useCallback(async (): Promise<void> => {
    if (!locationId) {
      return;
    }

    const isValid = await form.trigger();

    if (!isValid) {
      return;
    }

    const values = {
      locationId,
      externalId: form.getValues('pmsPackageId'),
      loyaltyCodes: form
        .getValues('loyaltyCodes')
        ?.split(',')
        .map((l) => l.trim()),
      freeUpgrade: selectedOption === 'free',
    };

    if (!selectedPackage) {
      dispatch(
        openActionProcessingModal({
          action: async (): Promise<ActionResult> => {
            await createEarlyCheckInPackage({
              variables: {
                data: values,
              },
            });

            return { success: true };
          },
        })
      );
    } else {
      if (!selectedPackage?.serviceId) {
        return;
      }

      dispatch(
        openActionProcessingModal({
          action: async (): Promise<ActionResult> => {
            await updateEarlyCheckInPackage({
              variables: {
                data: values,
                where: { serviceId: selectedPackage.serviceId },
              },
            });

            return { success: true };
          },
        })
      );
    }

    dispatch(closeForm());
  }, [selectedPackage, selectedOption, form, locationId]);

  return (
    <Modal isOpen onRequestClose={handleCancel} style={AccessSetupCheckInAndCheckoutTimeModalStyle}>
      <BaseModalHeader headerTitle="VIP/Loyalty Option" onClose={handleCancel} />
      <FormProvider {...form}>
        <Container onSubmit={form.handleSubmit(handleSave)}>
          <div>
            <OptionLabel>VIP/Loyalty Code</OptionLabel>
            <InputTags form={form} />
            <InputMessage>{form.errors.loyaltyCodes?.message}</InputMessage>
          </div>
          <Radio.Group>
            <RadioOption
              label="PMS Package"
              value="pms"
              selected={selectedOption === 'pms'}
              onChange={setSelectedOption}
            />
            <RadioOption
              label="Free"
              value="free"
              selected={selectedOption === 'free'}
              onChange={setSelectedOption}
            />
          </Radio.Group>
          <div>
            <OptionLabel>PMS Package ID</OptionLabel>
            <Input
              name="pmsPackageId"
              disabled={selectedOption === 'free'}
              error={form.errors.pmsPackageId?.message}
              ref={form.register}
            />
            <InputMessage>{form.errors.pmsPackageId?.message}</InputMessage>
          </div>
          <BottomContainer>
            <ButtonComponent.Cancel handleCancel={handleCancel} />
            <ButtonComponent.Save title="Add" handleSave={handleSave} />
          </BottomContainer>
        </Container>
      </FormProvider>
    </Modal>
  );
};
