import { Column, Container, IconsContainer, Title, Value } from '../styles/TimeRange.styles';
import { Button } from './Button';
import React from 'react';

interface TimeRangeProps {
  timeRange?: string;
  pmsPackageId?: string | null;
  price?: number | null;
  earlyCheckinPrice?: number | null;
  vipAndloyaltyCode?: string;
  onEdit?: () => void;
  onDelete?: () => void;
}

const Item: React.FC<TimeRangeProps> = ({
  timeRange,
  pmsPackageId,
  price,
  vipAndloyaltyCode,
  earlyCheckinPrice,
  onEdit,
  onDelete,
}: TimeRangeProps) => {
  return (
    <Container>
      {timeRange != null ? (
        <Column>
          <Title>Time Range</Title>
          <Value>{timeRange}</Value>
        </Column>
      ) : null}

      {vipAndloyaltyCode != null ? (
        <Column>
          <Title>VIP/Loyalty Code</Title>
          <Value>{vipAndloyaltyCode}</Value>
        </Column>
      ) : null}

      <Column>
        <Title>PMS Package ID</Title>
        <Value>{pmsPackageId ?? 'N/A'}</Value>
      </Column>

      {price != null ? (
        <Column>
          <Title>Price</Title>
          <Value>{price ? `$${price}` : 'Free'}</Value>
        </Column>
      ) : null}

      {earlyCheckinPrice != null ? (
        <Column>
          <Title>Early Check-in Price</Title>
          <Value>{earlyCheckinPrice ? `$${earlyCheckinPrice}` : 'Free'}</Value>
        </Column>
      ) : null}

      <IconsContainer>
        <Button.Edit onClick={onEdit} />
        <Button.Delete onClick={onDelete} />
      </IconsContainer>
    </Container>
  );
};

export const TimeRange = { Item };
