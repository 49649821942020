import convert24To12HoursFormat from '../components/GracePeriodsTab/util/convert24To12HoursFormat';

export function generate24HoursTimeList(offset = 30): string[] {
  const times = [];
  for (let hour = 0; hour < 24; hour += 1) {
    for (let minute = 0; minute < 60; minute += offset) {
      const period = hour < 12 ? 'AM' : 'PM';
      const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
      const formattedMinute = minute.toString().padStart(2, '0');
      times.push(`${formattedHour}:${formattedMinute} ${period}`);
    }
  }
  return times;
}

export function convertTo24HoursFormat(timeIn12HourFormat: string): string {
  const [time, period] = timeIn12HourFormat.split(' ');
  const [hours, minutes] = time.split(':').map(Number);
  let hourIn24HourFormat = hours;

  if (period === 'PM' && hours !== 12) {
    hourIn24HourFormat += 12;
  }
  if (period === 'AM' && hours === 12) {
    hourIn24HourFormat = 0;
  }

  return `${hourIn24HourFormat.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}

export function converTo12HoursFormat(timeIn24HourFormat?: string | null): string {
  if (!timeIn24HourFormat) {
    return '';
  }
  const { time, period } = convert24To12HoursFormat(timeIn24HourFormat);

  return `${time} ${period}`;
}

export function increaseEndTime(time: string): string {
  const [hours, minutes] = time.split(':').map(Number);

  const newMinutesValue = (minutes + 1) % 60;
  const newHoursValue = newMinutesValue === 0 ? hours + 1 : hours;

  return `${newHoursValue.toString().padStart(2, '0')}:${newMinutesValue
    .toString()
    .padStart(2, '0')}`;
}

export function decreaseEndTime(time: string): string {
  const [hours, minutes] = time.split(':').map(Number);

  const newMinutesValue = minutes === 0 ? 59 : minutes - 1;
  const newHourValue = minutes === 0 ? hours - 1 : hours;

  return `${newHourValue.toString().padStart(2, '0')}:${newMinutesValue
    .toString()
    .padStart(2, '0')}`;
}

export function formatTime(timeInMinutes?: number | null): string {
  if (!timeInMinutes) {
    return 'All day';
  }

  const hours = Math.floor(timeInMinutes / 60);
  const minutes = timeInMinutes % 60;
  let result = '';

  if (hours > 0) {
    result += `${hours}h `;
  }

  if (minutes > 0) {
    result += `${minutes}min`;
  }

  return result;
}
