import {
  AccessSetupSettingsOption,
  EarlyCheckInPackageData,
  Settings,
  useSettings,
} from './Settings';
import {
  AccessSetupVipAndLoyaltyModal,
  VipOrLoyaltyOptionsModal,
} from './VipOrLoyaltyOptionsModal';
import React, { useCallback, useEffect, useState } from 'react';
import {
  closeAccessSetupConfirmationModal,
  closeForm,
  openForm,
} from '../../../redux/actions/modals.actions';
import { ConfirmationModal } from './ConfirmationModal';
import { Spacer } from '../styles/Spacer.styles';
import { VipOrLoyaltyOptions } from './VipOrLoyaltyOptions';
import { useAccessSetup } from '../../../pages/Location/components/AccessSetupContext';
import { useConfirmationModal } from '../hooks/useConfirmationModal';
import { useDeleteEarlyCheckInPackage } from '../../../hooks/useDeleteEarlyCheckInPackage';
import { useDispatch } from 'react-redux';
import useSetAccessSetup from '../../../hooks/useSetAccessSetup';
import { withActionHandler } from '../hocs/withActionHandler';

export const KioskVipOrLoyaltyOptions: React.FC = () => {
  const { locationId, fetchData, data } = useAccessSetup();
  const { openConfirmationModal, modalState } = useConfirmationModal();
  const { options, updateOption } = useSettings();
  const dispatch = useDispatch();
  const { mutation: updateAccessSetup } = useSetAccessSetup({ onCompleted: fetchData });
  const { mutation: deleteEarlyCheckInPackage } = useDeleteEarlyCheckInPackage({
    onCompleted: fetchData,
  });

  const onConfirm = useCallback(() => {
    const newState = !options.kioskVipLoyaltyCodeOptionsChecked.enabled;
    updateOption('kioskVipLoyaltyCodeOptionsChecked', newState);

    updateAccessSetup(
      {
        enableEarlyCheckInByLoyalty: newState,
        enableEarlyCheckInByTimeRange: options.kioskPaidEarlyCheckInChecked.enabled,
      },
      locationId
    );

    dispatch(closeAccessSetupConfirmationModal());
    dispatch(closeForm());
  }, [updateOption, dispatch, options]);

  const [selectedPackage, setSelectedPackage] = useState<EarlyCheckInPackageData | undefined>();

  const openEditorModal = useCallback(() => {
    const id = AccessSetupVipAndLoyaltyModal;
    dispatch(openForm(AccessSetupVipAndLoyaltyModal, id, {}));
  }, [dispatch]);

  const handleEdit = useCallback(
    (earlyCheckInPackage: EarlyCheckInPackageData): void => {
      setSelectedPackage(earlyCheckInPackage);

      openEditorModal();
    },
    [setSelectedPackage, openEditorModal]
  );

  const handleCreate = useCallback((): void => {
    setSelectedPackage(undefined);

    openEditorModal();
  }, [setSelectedPackage, openEditorModal]);

  const handleDelete = useCallback(
    (earlyCheckInPackage: EarlyCheckInPackageData): void => {
      setSelectedPackage(undefined);

      const action = withActionHandler(() => {
        if (earlyCheckInPackage?.serviceId) {
          deleteEarlyCheckInPackage(earlyCheckInPackage?.serviceId);
        }

        dispatch(closeAccessSetupConfirmationModal());
      });

      openConfirmationModal({
        id: AccessSetupSettingsOption.kioskPaidEarlyCheckInChecked,
        action,
      });
    },
    [deleteEarlyCheckInPackage]
  );

  const handleCheckboxClick = useCallback(() => {
    const action = withActionHandler(() => {
      onConfirm();
    });

    openConfirmationModal({
      id: AccessSetupSettingsOption.kioskVipLoyaltyCodeOptionsChecked,
      action,
    });
  }, [openConfirmationModal, onConfirm]);

  useEffect(() => {
    updateOption('kioskVipLoyaltyCodeOptionsChecked', data?.enableEarlyCheckInByLoyalty ?? false);
  }, [data?.enableEarlyCheckInByLoyalty]);

  return (
    <Settings.Container
      description="Set special check-in options for VIP guests. VIP options will be applied first if available, and override all other package or time range settings."
      title="Kiosk VIP/Loyalty Options"
      titleMode="large"
    >
      <Settings.Provider>
        <Settings.Toogle
          title="Enable Kiosk VIP/Loyalty Options"
          onChange={handleCheckboxClick}
          checked={options.kioskVipLoyaltyCodeOptionsChecked.enabled}
        />
        <Spacer size="4px" />
        <VipOrLoyaltyOptions
          onCreate={handleCreate}
          onDelete={handleDelete}
          onEdit={handleEdit}
          enabled={options.kioskVipLoyaltyCodeOptionsChecked.enabled}
        />
        {modalState.accessSetup &&
          modalState.accessSetup.id ===
            AccessSetupSettingsOption.kioskVipLoyaltyCodeOptionsChecked && (
            <ConfirmationModal
              headerTitle={modalState.accessSetup.title}
              content={modalState.accessSetup.message}
              id={modalState.accessSetup.id}
              onConfirm={onConfirm}
            />
          )}
        {modalState.form.name === AccessSetupVipAndLoyaltyModal && (
          <VipOrLoyaltyOptionsModal selectedPackage={selectedPackage} />
        )}
      </Settings.Provider>
    </Settings.Container>
  );
};
