/* eslint-disable sort-keys */
import {
  mobileV3ThemeAccessManagementSchema,
  mobileV3ThemeAccessManagementUiSchema,
  mobileV3ThemeAmenitiesSchema,
  mobileV3ThemeAmenitiesUiSchema,
  mobileV3ThemeAssistanceSchema,
  mobileV3ThemeAssistanceUiSchema,
  mobileV3ThemeAuthenticationSchema,
  mobileV3ThemeAuthenticationUiSchema,
  mobileV3ThemeCheckInFailSchema,
  mobileV3ThemeCheckInFailUiSchema,
  mobileV3ThemeCheckInSchema,
  mobileV3ThemeCheckInUiSchema,
  mobileV3ThemeCheckOutSchema,
  mobileV3ThemeCheckOutUiSchema,
  mobileV3ThemeConciergeSchema,
  mobileV3ThemeConciergeUiSchema,
  mobileV3ThemeCurrentBookingSchema,
  mobileV3ThemeCurrentBookingUiSchema,
  mobileV3ThemeExpectedArrivalSchema,
  mobileV3ThemeExpectedArrivalUiSchema,
  mobileV3ThemeGeneralSchema,
  mobileV3ThemeGeneralUiSchema,
  mobileV3ThemeGuestsSchema,
  mobileV3ThemeGuestsUiSchema,
  mobileV3ThemeHomeSchema,
  mobileV3ThemeHomeUiSchema,
  mobileV3ThemePackagesSchema,
  mobileV3ThemePackagesUiSchema,
  mobileV3ThemePaymentAuthorizationSchema,
  mobileV3ThemePaymentAuthorizationUiSchema,
  mobileV3ThemePaymentCaptureSchema,
  mobileV3ThemePaymentCaptureUiSchema,
  mobileV3ThemePaymentStatusSchema,
  mobileV3ThemePaymentStatusUiSchema,
  mobileV3ThemeProfileSchema,
  mobileV3ThemeProfileUiSchema,
  mobileV3ThemeReservationSchema,
  mobileV3ThemeReservationUiSchema,
  mobileV3ThemeShareKeySchema,
  mobileV3ThemeShareKeyUiSchema,
  mobileV3ThemeSimplifiedIDVerificationSchema,
  mobileV3ThemeSimplifiedIDVerificationUiSchema,
  mobileV3ThemeSuccessSchema,
  mobileV3ThemeSuccessUiSchema,
  mobileV3ThemeTermsAndConditionsSchema,
  mobileV3ThemeTermsAndConditionsUiSchema,
  mobileV3ThemeTripsSchema,
  mobileV3ThemeTripsUiSchema,
  mobileV3ThemeUserDetailsSchema,
  mobileV3ThemeUserDetailsUiSchema,
} from './MobileV3Theme';

import { JSONSchema7 } from 'json-schema';

export const mobileV3ThemeCustomizationSchema: JSONSchema7 = {
  type: 'object',
  properties: {
    ...mobileV3ThemeGeneralSchema,
    ...mobileV3ThemeAuthenticationSchema,
    ...mobileV3ThemeHomeSchema,
    ...mobileV3ThemeCurrentBookingSchema,
    ...mobileV3ThemeReservationSchema,
    ...mobileV3ThemeAmenitiesSchema,
    ...mobileV3ThemePackagesSchema,
    ...mobileV3ThemeTripsSchema,
    ...mobileV3ThemeProfileSchema,
    ...mobileV3ThemeUserDetailsSchema,
    ...mobileV3ThemeAssistanceSchema,
    ...mobileV3ThemeExpectedArrivalSchema,
    ...mobileV3ThemeAccessManagementSchema,
    ...mobileV3ThemeShareKeySchema,
    ...mobileV3ThemeSimplifiedIDVerificationSchema,
    ...mobileV3ThemeTermsAndConditionsSchema,
    ...mobileV3ThemeSuccessSchema,
    ...mobileV3ThemePaymentStatusSchema,
    ...mobileV3ThemePaymentAuthorizationSchema,
    ...mobileV3ThemePaymentCaptureSchema,
    ...mobileV3ThemeGuestsSchema,
    ...mobileV3ThemeCheckInSchema,
    ...mobileV3ThemeCheckInFailSchema,
    ...mobileV3ThemeCheckOutSchema,
    ...mobileV3ThemeConciergeSchema,
  },
};

export const mobileV3ThemeCustomizationUiSchema = {
  ...mobileV3ThemeGeneralUiSchema,
  ...mobileV3ThemeAuthenticationUiSchema,
  ...mobileV3ThemeHomeUiSchema,
  ...mobileV3ThemeCurrentBookingUiSchema,
  ...mobileV3ThemeReservationUiSchema,
  ...mobileV3ThemeAmenitiesUiSchema,
  ...mobileV3ThemePackagesUiSchema,
  ...mobileV3ThemeTripsUiSchema,
  ...mobileV3ThemeProfileUiSchema,
  ...mobileV3ThemeUserDetailsUiSchema,
  ...mobileV3ThemeAssistanceUiSchema,
  ...mobileV3ThemeExpectedArrivalUiSchema,
  ...mobileV3ThemeAccessManagementUiSchema,
  ...mobileV3ThemeShareKeyUiSchema,
  ...mobileV3ThemeSimplifiedIDVerificationUiSchema,
  ...mobileV3ThemeTermsAndConditionsUiSchema,
  ...mobileV3ThemeSuccessUiSchema,
  ...mobileV3ThemePaymentStatusUiSchema,
  ...mobileV3ThemePaymentAuthorizationUiSchema,
  ...mobileV3ThemePaymentCaptureUiSchema,
  ...mobileV3ThemeGuestsUiSchema,
  ...mobileV3ThemeCheckInUiSchema,
  ...mobileV3ThemeCheckInFailUiSchema,
  ...mobileV3ThemeCheckOutUiSchema,
  ...mobileV3ThemeConciergeUiSchema,
};
