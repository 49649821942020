import Theme from '../../../theme';
import styled from 'styled-components';

export const SettingsTabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${Theme.colors.darkAlternate.hexCode};
  border-radius: 24px;
  padding: 30px 30px 35px;
  display: flex;
  min-height: 800px;
`;

export const Container = styled.div`
  margin: 25px 0;
`;

export const Form = styled.form`
  margin: 20px 0;
`;

export const ControlTitle = styled.p`
  font-size: 26px;
  font-weight: 600;
  color: ${Theme.colors.white.hexCode};
`;

export const Separator = styled.hr<{ size?: number }>`
  border-top: 1px solid ${Theme.colors.tableHeading.hexCode};
  margin: ${({ size }): string => {
    return size ? `${size}px 0px` : '64px 0px';
  }};
`;
