import { BaseModalFooterContainer } from './styles/BaseModalFooter.styles';
import { BaseModalFooterProps } from './@types';
import Button from '../Button';
import ButtonModel from '../Button/enums/ButtonModel.enum';
import React from 'react';

const BaseModalFooter: React.FC<BaseModalFooterProps> = ({ confirmButton, cancelButton }) => {
  return (
    <BaseModalFooterContainer>
      {cancelButton?.action && (
        <Button
          model={ButtonModel.QUICK_ACTION_MODAL_CANCEL}
          onClick={cancelButton.action}
          bold
          fontSize={cancelButton.styles?.fontSize}
          padding={cancelButton.styles?.padding}
        >
          {cancelButton.text ?? 'Cancel'}
        </Button>
      )}
      {confirmButton?.action && (
        <Button
          model={ButtonModel.QUICK_ACTION_MODAL_CONFIRM}
          onClick={confirmButton.action}
          bold
          disabled={confirmButton.disabled}
          fontSize={confirmButton.styles?.fontSize}
          padding={confirmButton.styles?.padding}
        >
          {confirmButton.text ?? 'Confirm'}
        </Button>
      )}
    </BaseModalFooterContainer>
  );
};

export default BaseModalFooter;
