import {
  CentralizedRow,
  NoResultsMessage,
  SelectValueButton,
  TableBody,
  TableRow,
} from '../DataTableV2.styles';
import React, { useEffect, useMemo } from 'react';

import { DataTableRowValue } from './DataTableRowValue';
import { DataTableV2Props } from '../@types';
import LoadingAnimation from '../../LoadingAnimation';
import { noResults as noResultsFn } from '../utils/noResults';
import { useOnScreenIntersection } from '../../../hooks/useOnScreenIntersection';

type DataTableBodyProps<T> = Pick<
  DataTableV2Props<T>,
  | 'data'
  | 'onSelectValue'
  | 'hasMoreData'
  | 'loadData'
  | 'isLoading'
  | 'PrefixComponent'
  | 'TableRowComponent'
  | 'noResultsMessage'
>;

export const DataTableBody = <T,>(props: DataTableBodyProps<T>): React.ReactElement => {
  const {
    data,
    onSelectValue,
    hasMoreData,
    loadData,
    isLoading,
    PrefixComponent,
    TableRowComponent: _TableRowComponent,
    noResultsMessage,
  } = props;

  const { isIntersecting, observer, elementRef } = useOnScreenIntersection({
    entitiesList: data,
    rowsRemainingToLoadData: 3,
  });

  useEffect(() => {
    if (isIntersecting && hasMoreData) {
      loadData();
      observer?.disconnect();
    }

    return (): void => {
      observer?.disconnect();
    };
  }, [isIntersecting, hasMoreData, loadData]);

  const noResults = useMemo((): boolean => {
    return noResultsFn({ data, isLoading });
  }, [data, isLoading]);

  const TableRowComponent = _TableRowComponent || TableRow;

  return (
    <TableBody>
      {data?.map(({ key: rowKey, value, fields }, rowIndex) => (
        <TableRowComponent
          key={rowKey}
          ref={elementRef(rowIndex)}
          {...(onSelectValue ? { onClick: (): void => onSelectValue(value) } : {})}
        >
          {PrefixComponent && <PrefixComponent id={rowKey} />}
          {fields.map((field, index) => (
            <DataTableRowValue key={`${rowKey}-${index}`} field={field} />
          ))}
          {onSelectValue && (
            <td>
              <SelectValueButton onClick={(): void => onSelectValue(value)}>
                &rarr;
              </SelectValueButton>
            </td>
          )}
        </TableRowComponent>
      ))}
      {isLoading && (
        <CentralizedRow>
          <td>
            <LoadingAnimation />
          </td>
        </CentralizedRow>
      )}
      {noResults && (
        <CentralizedRow>
          <td>
            <NoResultsMessage>{noResultsMessage ?? 'No matching results.'}</NoResultsMessage>
          </td>
        </CentralizedRow>
      )}
    </TableBody>
  );
};
