import styled from 'styled-components';

export const Container = styled.form`
  display: grid;

  grid-template-columns: repeat(1, 1fr); /* Two columns */
  grid-template-rows: auto auto auto; /* Three rows */

  & > :nth-child(1) {
    grid-column: 1 / span 2; /* Span full width */
    grid-row: 1;
  } /* First item */

  & > :nth-child(2) {
    grid-column: 1 / span 2; /* Span full width */
    grid-row: 2;
  } /* Second item */

  & > :nth-child(3) {
    grid-column: 1 / span 2; /* Span full width */
    grid-row: 3;
  } /* Third item */

  gap: 8px;
  margin: 0;
`;
