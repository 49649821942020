import { gql } from '../__generated__';

const UPDATE_EARLY_CHECKIN_PACKAGE = gql(`
  mutation updateEarlyCheckInPackage(
    $data: EarlyCheckInPackageInput!
    $where: UpdateEarlyCheckInPackageWhere!
  ) {
    updateEarlyCheckInPackage(data: $data, where: $where) {
      endTime,
      externalId,
      freeUpgrade,
      locationId,
      loyaltyCodes,
      serviceId,
      startTime
    }
  }
`);

export default UPDATE_EARLY_CHECKIN_PACKAGE;
