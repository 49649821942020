import React, { useMemo } from 'react';
import { getTotalAdultsLabel, getTotalKidsLabel } from './util/getTotalGuestsPlural';
import { KioskLiveUsageCardProps } from '../types';
import { KioskLiveUsageReservationContainerRowStyle } from './styles/KioskLiveUsageCard.style';

export const GuestsInfo: React.FC<KioskLiveUsageCardProps> = ({ kiosk }) => {
  const totalGuests = useMemo(() => {
    return kiosk?.accessGrant?.totalGuests;
  }, [kiosk.accessGrant]);

  return (totalGuests?.total ?? 0) > 0 ? (
    <div style={KioskLiveUsageReservationContainerRowStyle}>
      <p>
        {totalGuests?.adults} {getTotalAdultsLabel(totalGuests?.adults ?? undefined)}
        {', '}
        {totalGuests?.children} {getTotalKidsLabel(totalGuests?.children ?? undefined)}
      </p>
    </div>
  ) : (
    <></>
  );
};
