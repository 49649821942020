import { Datum } from '../../../components/DataTable';
import { FilterDashboardAccessGrantsQueryData } from '..';
import { UseDateTimeFormatterInterface } from '../../useDateTimeFormatter';
import getRoomNumber from '../../../pages/AccessGrant/functions/getRoomNumber';
import links from '../../../util/links';

export default function generateAccessGrantsTableData(
  formatter: UseDateTimeFormatterInterface,
  data?: FilterDashboardAccessGrantsQueryData
): Datum[] {
  const { formatDate } = formatter;
  const accessGrants = data?.filterDashboardAccessGrants.data || [];

  const tableData = accessGrants.map((accessGrant) => {
    return {
      dataType: 'AccessGrant',
      fields: [
        {
          key: 'Reservation #',
          link: `${links.paths.ACCESS_GRANTS}/${accessGrant.id}`,
          value: accessGrant.confirmationCode || 'N/A',
        },
        {
          key: 'Primary Guest',
          value: accessGrant.user.name ?? accessGrant.user.id,
          highlight: true,
        },
        {
          key: 'Secondary Guest(s)',
          value: accessGrant.secondaryUsers?.length
            ? accessGrant.secondaryUsers?.reduce((a, b) => {
                return `${a.length ? `${a}, ` : ``}${b.user.name}`;
              }, '')
            : 'N/A',
          expandable: true,
          highlight: true,
        },
        {
          key: 'State',
          value: accessGrant.state ?? 'Unknown',
        },
        {
          key: 'Room #',
          value: getRoomNumber(accessGrant.resources) ?? 'N/A',
          highlight: true,
        },
        {
          key: 'Location',
          value: accessGrant.location.name,
        },
        {
          key: 'Start',
          value: formatDate(accessGrant.startsAt, { timezone: accessGrant.location.timezone }),
        },
        {
          key: 'End',
          value: formatDate(accessGrant.endsAt, { timezone: accessGrant.location.timezone }),
        },
        {
          key: 'In/Out',
          value: `${accessGrant.startTime ?? '?'}/${accessGrant.endTime ?? '?'}`,
        },
      ],
      key: accessGrant.id,
    };
  });

  return tableData;
}
