import * as React from 'react';

export const LiveUsageCheckMarkIcon: React.FC<{
  color?: string;
}> = ({ color }) => {
  const fillColor = React.useMemo(() => color || '#0A6CFF', [color]);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
      <g id="Page-1" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g id="Dashboard_-1-Copy-24" transform="translate(-153 -294)">
          <g id="🍱Large-Comp/6-products" transform="translate(114 161.2)">
            <g id="checkbox/element/uncheck-copy" transform="translate(39.128 133)">
              <path
                id="icon-bg"
                fill={fillColor}
                fillRule="nonzero"
                d="M5.128 0h9.744c1.783 0 2.43.186 3.082.534s1.163.86 1.512 1.512c.348.652.534 1.299.534 3.082v9.744c0 1.783-.186 2.43-.534 3.082a3.64 3.64 0 0 1-1.512 1.512c-.652.348-1.299.534-3.082.534H5.128c-1.783 0-2.43-.186-3.082-.534a3.64 3.64 0 0 1-1.512-1.512C.186 17.302 0 16.655 0 14.872V5.128c0-1.783.186-2.43.534-3.082A3.64 3.64 0 0 1 2.046.534C2.698.186 3.345 0 5.128 0"
              ></path>
              <g id="f-check" fill="#FFF" transform="translate(5 5)">
                <path id="Path" d="m3.5 5.375-2-2-1.5 1.5 3.5 3.5 6.5-6.5-1.5-1.5z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
