import {
  AccessSetupInput,
  MutationSetAccessSetupArgs,
  SetAccessSetupMutation,
} from '../../__generated__/graphql';
import MutationHookData from '../@types/MutationHookData';
import MutationHookParams from '../@types/MutationHookParams';
import SET_ACCESS_SETUP from '../../graphql/setAccessSetup';
import { apolloRequirePreflightHeader } from '../../client';
import { useMutation } from '@apollo/client';

export default function useSetAccessSetup({
  onCompleted,
  onError,
}: MutationHookParams<SetAccessSetupMutation> = {}): MutationHookData<
  AccessSetupInput,
  SetAccessSetupMutation
> {
  const [update, { data, error, loading }] = useMutation<
    SetAccessSetupMutation,
    MutationSetAccessSetupArgs
  >(SET_ACCESS_SETUP, {
    context: {
      headers: apolloRequirePreflightHeader,
    },
    onCompleted,
    onError,
  });

  return {
    data,
    error,
    loading,
    mutation: (inputs: AccessSetupInput, id): void => {
      update({
        variables: {
          data: inputs,
          where: { locationId: id ?? '' },
        },
      });
    },
  };
}
