import { DataTableSearchField, DataTableSortParams } from '../@types';
import React, { createContext, useContext, useState } from 'react';

type DataTableSearchType = {
  field: DataTableSearchField | undefined;
  text: string;
};

type DataTableV2ContextProps = {
  searchParams?: DataTableSearchType;
  setSearchParams: (newSearchParams: DataTableSearchType) => void;

  sortParams?: DataTableSortParams;
  setSortParams: (newSortParams: DataTableSortParams | undefined) => void;
};

const DataTableV2Context = createContext<DataTableV2ContextProps | undefined>(undefined);

export const DataTableV2ContextProvider: React.FC = ({ children }) => {
  const [searchParams, setSearchParams] = useState<DataTableSearchType | undefined>();
  const [sortParams, setSortParams] = useState<DataTableSortParams | undefined>();

  return (
    <DataTableV2Context.Provider
      value={{ searchParams, setSearchParams, sortParams, setSortParams }}
    >
      {children}
    </DataTableV2Context.Provider>
  );
};

export const useDataTableV2Context = (): DataTableV2ContextProps => {
  const context = useContext(DataTableV2Context);

  if (!context) {
    throw new Error('useDataTableV2Context must be used within a DataTableV2Provider');
  }

  return context;
};
