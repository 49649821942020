import Button from '../../../../../../components/Button';
import ButtonModel from '../../../../../../components/Button/enums/ButtonModel.enum';
import { Plus } from '../../../../../../assets/icons/plus';
import React from 'react';
import { useLoadReservationOnKioskModalContext } from '../contexts/LoadReservationOnKioskModalContext';

export const LoadReservationButton: React.FC = () => {
  const { openModal: openLoadReservationModal } = useLoadReservationOnKioskModalContext();

  return (
    <Button
      model={ButtonModel.QUICK_ACTION_MODAL_CONFIRM}
      onClick={openLoadReservationModal}
      bold
      style={{ gap: '5px' }}
    >
      <Plus />
      Load Reservation for Check-in
    </Button>
  );
};
