type Result = {
  success: boolean;
  errorMessage?: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AsyncOrSyncFn = (...args: any) => any;

export function withActionHandler<T extends AsyncOrSyncFn>(fn: T) {
  return async (...args: Parameters<T>): Promise<Result> => {
    try {
      const result = fn(...args);

      if (!(result instanceof Promise)) {
        return Promise.resolve({ success: true });
      }

      await result;
      return { success: true };
    } catch (error) {
      return {
        success: false,
        errorMessage: (error as Error)?.message || 'Unknown error',
      };
    }
  };
}
