/* eslint-disable sort-keys */
import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3LocalizationCheckInFailSchema: NodeSchemaProperties = {
  checkInFail: {
    title: '#30 Check In Fail',
    type: 'object',
    properties: {
      'header:enable': {
        description: '[checkInFail.title]',
        title: '#1 Header',
        type: 'boolean',
      },
      header: {
        type: 'string',
      },
      'title:enable': {
        description: '[checkInFail.title]',
        title: '#2 Title',
        type: 'boolean',
      },
      title: {
        type: 'string',
      },
      'helpButton:enable': {
        description: '[checkInFail.helpButton]',
        title: '#3 Help button',
        type: 'boolean',
      },
      helpButton: {
        type: 'string',
      },
      'endSessionButton:enable': {
        description: '[checkInFail.endSessionButton]',
        title: '#4 End session button',
        type: 'boolean',
      },
      endSessionButton: {
        type: 'string',
      },
      'retryPaymentButton:enable': {
        description: '[checkInFail.retryPaymentButton]',
        title: '#5 Retry payment button',
        type: 'boolean',
      },
      retryPaymentButton: {
        type: 'string',
      },
      'errorLocalizations:enable': {
        title: '#6 Error localizations',
        type: 'boolean',
        description: '[checkInFail.errorLocalizations]',
      },
      errorLocalizations: {
        title: 'Localize error codes to be human readable errors',
        type: 'array',
        items: {
          type: 'object',
          properties: {
            code: {
              title: 'Error code',
              type: 'string',
            },
            message: {
              title: 'Human readable error text',
              type: 'string',
            },
            header: {
              title: 'Human readable error header',
              type: 'string',
            },
          },
          required: ['code', 'message', 'header'],
        },
      },
    },
  },
};

export const mobileV3LocalizationCheckInFailUiSchema: NodeUiSchemaProperties = {
  checkInFail: {
    header: {
      'ui:label': false,
      'ui:readonly': true,
    },
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    helpButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    endSessionButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    retryPaymentButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    errorLocalizations: {
      'ui:label': false,
      'ui:title': '‎',
      'ui:readonly': true,
      items: {
        code: {},
        message: {},
        header: {},
      },
    },
  },
};
