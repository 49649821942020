import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionBarConfirmationModalReservationIcon } from '../../../assets/icons/actionBarConfirmationModalReservationIcon';
import { ActionBarConfirmationModalUserIcon } from '../../../assets/icons/actionBarConfirmationModalUserIcon';
import { ActionConfirmationModalProps } from '../@types/QuickActionBarTypes';
import BaseQuickActionModal from '../../BaseQuickActionModal';
import { BaseQuickActionModalHeaderValues } from '../../BaseQuickActionModal/@types';
import { CTAIcon } from './CTAIcon';
import { MainMessage } from '../styles/ActionConfirmationModal.styles';
import ModalInfoBox from '../../BaseQuickActionModal/ModalInfoBox';
import { RootState } from '../../../redux/reducers';
import Theme from '../../../theme';
import { closeQuickActionModal } from '../../../redux/actions/modals.actions';
import { useCtaBasedText } from '../hooks/useCtaBasedText';

const ActionConfirmationModal: React.FC<ActionConfirmationModalProps> = ({ ctaType, action }) => {
  const accessGrant = useSelector((state: RootState) => state.accessGrant);
  const dispatch = useDispatch();

  const { title, message } = useCtaBasedText(ctaType);

  const closeModal = useCallback(() => {
    dispatch(closeQuickActionModal('quickActionConfirmationModal'));
  }, []);

  const itemsList: BaseQuickActionModalHeaderValues = [
    {
      item: 'Guest Name',
      value: accessGrant.pageInfo?.user.name || '',
      icon: <ActionBarConfirmationModalUserIcon />,
    },
    {
      item: 'Reservation Number',
      value: accessGrant.pageInfo?.confirmationCode || '',
      icon: <ActionBarConfirmationModalReservationIcon />,
    },
  ];

  return (
    <BaseQuickActionModal
      header={{
        title,
        icon: <CTAIcon type={ctaType} color={Theme.colors.quickActionButton.hexCode} />,
      }}
      confirmButton={{
        action: (): void => {
          closeModal();
          action();
        },
      }}
      cancelButton={{
        action: (): void => closeModal(),
      }}
    >
      <ModalInfoBox itemsList={itemsList} itemsPerRow={2} />
      {message && <MainMessage>{message}</MainMessage>}
    </BaseQuickActionModal>
  );
};

export default ActionConfirmationModal;
