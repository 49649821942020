import React, { useCallback, useMemo } from 'react';
import { closeForm, closeQuickActionModal } from '../../redux/actions/modals.actions';

import { BaseModalContainer } from './styles/BaseModal.styles';
import BaseModalFooter from './BaseModalFooter';
import BaseModalHeader from './BaseModalHeader';
import { BaseQuickActionModalProps } from './@types';
import Container from '../Container';
import Modal from 'react-modal';
import ModalInformationStyle from '../ModalInformationComponent/styles/ModalInformationStyle';
import ReactModal from 'react-modal';
import Theme from '../../theme';
import { useDispatch } from 'react-redux';

const BaseQuickActionModal: React.FC<BaseQuickActionModalProps> = ({
  children,
  confirmButton,
  cancelButton,
  shouldAllowSoftDismissing,
  closeModalAction,
  styles,
  header,
  modalAlignment = 'top',
}) => {
  const dispatch = useDispatch();

  const closeModal = useCallback((): void => {
    closeModalAction?.();
    dispatch(closeForm());
    dispatch(closeQuickActionModal('quickActionConfirmationModal'));
    dispatch(closeQuickActionModal('quickActionProcessingModal'));
  }, []);

  const modalStyles = useMemo((): ReactModal.Styles => {
    const style: ReactModal.Styles = {
      content: {
        ...ModalInformationStyle.content,
        backgroundColor: Theme.colors.ghostWhite.hexCode,
        borderRadius: '12px',
        minHeight: '250px',
        width: styles?.width || '600px',
      },
      overlay: {
        ...ModalInformationStyle.overlay,
        background: 'rgba(0, 0, 0, 0.5)',
      },
    };

    if (modalAlignment === 'center') {
      style.content = {
        ...style.content,
        top: '50%',
        left: '50%',
        right: 'unset',
        transform: 'translate(-50%, -50%)',
      };
    }

    return style;
  }, [styles, modalAlignment]);

  return (
    <Modal
      isOpen
      onRequestClose={closeModal}
      shouldCloseOnEsc={shouldAllowSoftDismissing !== false}
      shouldCloseOnOverlayClick={shouldAllowSoftDismissing !== false}
      style={modalStyles}
    >
      <BaseModalContainer>
        {header?.title && <BaseModalHeader header={header} onClose={closeModal} />}
        <Container style={{ height: '100%', maxHeight: '75vh' }}>{children}</Container>
        {(confirmButton?.action || cancelButton?.action) && (
          <BaseModalFooter confirmButton={confirmButton} cancelButton={cancelButton} />
        )}
      </BaseModalContainer>
    </Modal>
  );
};

export default BaseQuickActionModal;
