import {
  DispenserType,
  KioskLiveUsageData,
  Requirement,
  RequirementStatus,
  RequirementType,
} from '../../../../../__generated__/graphql';

export const mockLiveUsage: KioskLiveUsageData[] = [
  {
    kiosk: {
      id: '1',
      name: 'Kiosk 1',
      hasDispenser: true,
      dispenserType: DispenserType.F1_1000,
      location: {
        id: '1',
        name: 'Location 1',
        tenantId: '1',
        timezone: 'America/New_York',
      },
    },
    accessGrant: {
      id: '1',
      startsAt: '2021-08-01T00:00:00Z',
      endsAt: '2021-08-01T23:59:59Z',
      confirmationCode: '123456',
      bookedPackages: [],
      resources: [],
      secondaryUsers: [],
      requirements: [
        {
          id: '1',
          type: RequirementType.IdVerification,
          status: RequirementStatus.Failed,
        } as Requirement,
      ],
      totalGuests: {
        total: 1,
        adults: 1,
        children: 0,
      },
      user: {
        id: '1',
        email: 'user1@email.com',
        name: 'User 1',
      },
    },
  },
  {
    kiosk: {
      id: '2',
      name: 'Kiosk 2',
      hasDispenser: true,
      dispenserType: DispenserType.F1_1000,
      location: {
        id: '2',
        name: 'Location 2',
        tenantId: '2',
        timezone: 'America/Chicago',
      },
    },
    accessGrant: {
      id: '2',
      startsAt: '2021-08-02T00:00:00Z',
      endsAt: '2021-08-02T23:59:59Z',
      confirmationCode: '654321',
      bookedPackages: [],
      resources: [],
      secondaryUsers: [],
      requirements: [],
      totalGuests: {
        total: 2,
        adults: 2,
        children: 0,
      },
      user: {
        id: '2',
        email: 'user2@email.com',
        name: 'User 2',
      },
    },
  },
  {
    kiosk: {
      id: '3',
      name: 'Kiosk 3',
      hasDispenser: true,
      dispenserType: DispenserType.F1_1000,
      location: {
        id: '3',
        name: 'Location 3',
        tenantId: '3',
        timezone: 'America/Los_Angeles',
      },
    },
    accessGrant: {
      id: '3',
      startsAt: '2021-08-03T00:00:00Z',
      endsAt: '2021-08-03T23:59:59Z',
      confirmationCode: '789012',
      bookedPackages: [],
      resources: [],
      secondaryUsers: [],
      requirements: [],
      totalGuests: {
        total: 3,
        adults: 3,
        children: 0,
      },
      user: {
        id: '3',
        email: 'user3@email.com',
        name: 'User 3',
      },
    },
  },
  {
    kiosk: {
      id: '4',
      name: 'Kiosk 4',
      hasDispenser: true,
      dispenserType: DispenserType.F1_1000,
      location: {
        id: '4',
        name: 'Location 4',
        tenantId: '4',
        timezone: 'America/Denver',
      },
    },
    accessGrant: {
      id: '4',
      startsAt: '2021-08-04T00:00:00Z',
      endsAt: '2021-08-04T23:59:59Z',
      confirmationCode: '345678',
      bookedPackages: [],
      resources: [],
      secondaryUsers: [],
      requirements: [],
      totalGuests: {
        total: 4,
        adults: 4,
        children: 0,
      },
      user: {
        id: '4',
        email: 'user4@email.com',
        name: 'User 4',
      },
    },
  },
  {
    kiosk: {
      id: '5',
      name: 'Kiosk 5',
      hasDispenser: true,
      dispenserType: DispenserType.F1_1000,
      location: {
        id: '5',
        name: 'Location 5',
        tenantId: '5',
        timezone: 'America/Phoenix',
      },
    },
    accessGrant: {
      id: '5',
      startsAt: '2021-08-05T00:00:00Z',
      endsAt: '2021-08-05T23:59:59Z',
      confirmationCode: '901234',
      bookedPackages: [],
      resources: [],
      secondaryUsers: [],
      requirements: [],
      totalGuests: {
        total: 5,
        adults: 5,
        children: 0,
      },
      user: {
        id: '5',
        email: 'user5@email.com',
        name: 'User 5',
      },
    },
  },
  {
    kiosk: {
      id: '6',
      name: 'Kiosk 6',
      hasDispenser: true,
      dispenserType: DispenserType.F1_1000,
      location: {
        id: '6',
        name: 'Location 6',
        tenantId: '6',
        timezone: 'America/Anchorage',
      },
    },
    accessGrant: {
      id: '6',
      startsAt: '2021-08-06T00:00:00Z',
      endsAt: '2021-08-06T23:59:59Z',
      confirmationCode: '567890',
      bookedPackages: [],
      resources: [],
      secondaryUsers: [],
      requirements: [],
      totalGuests: {
        total: 6,
        adults: 6,
        children: 0,
      },
      user: {
        id: '6',
        email: 'user6@email.com',
        name: 'User 6',
      },
    },
  },
  {
    kiosk: {
      id: '7',
      name: 'Kiosk 7',
      hasDispenser: true,
      dispenserType: DispenserType.F1_1000,
      location: {
        id: '7',
        name: 'Location 7',
        tenantId: '7',
        timezone: 'America/Honolulu',
      },
    },
    accessGrant: {
      id: '7',
      startsAt: '2021-08-07T00:00:00Z',
      endsAt: '2021-08-07T23:59:59Z',
      confirmationCode: '234567',
      bookedPackages: [],
      resources: [],
      secondaryUsers: [],
      requirements: [],
      totalGuests: {
        total: 7,
        adults: 7,
        children: 0,
      },
      user: {
        id: '7',
        email: 'user7@email.com',
        name: 'User 7',
      },
    },
  },
  {
    kiosk: {
      id: '8',
      name: 'Kiosk 8',
      hasDispenser: true,
      dispenserType: DispenserType.F1_1000,
      location: {
        id: '8',
        name: 'Location 8',
        tenantId: '8',
        timezone: 'America/Adak',
      },
    },
    accessGrant: {
      id: '8',
      startsAt: '2021-08-08T00:00:00Z',
      endsAt: '2021-08-08T23:59:59Z',
      confirmationCode: '890123',
      bookedPackages: [],
      resources: [],
      secondaryUsers: [],
      requirements: [],
      totalGuests: {
        total: 8,
        adults: 8,
        children: 0,
      },
      user: {
        id: '8',
        email: 'user8@email.com',
        name: 'User 8',
      },
    },
  },
];
