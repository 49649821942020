import React, { useMemo } from 'react';

export const SelectReservationIcon: React.FC<{
  color?: string;
}> = ({ color }) => {
  const fillColor = useMemo(() => color || '#fff', [color]);

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.2071 9.29289C18.5976 9.68342 18.5976 10.3166 18.2071 10.7071L14.2071 14.7071C13.8166 15.0976 13.1834 15.0976 12.7929 14.7071L10.7929 12.7071C10.4024 12.3166 10.4024 11.6834 10.7929 11.2929C11.1834 10.9024 11.8166 10.9024 12.2071 11.2929L13.5 12.5858L16.7929 9.29289C17.1834 8.90237 17.8166 8.90237 18.2071 9.29289Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 3H20C22.2091 3 24 4.79086 24 7V17C24 19.2091 22.2091 21 20 21H4C1.79086 21 0 19.2091 0 17V7C0 4.79086 1.79086 3 4 3ZM4 5C2.89543 5 2 5.89543 2 7V17C2 18.1046 2.89543 19 4 19H20C21.1046 19 22 18.1046 22 17V7C22 5.89543 21.1046 5 20 5H4Z"
        fill={fillColor}
      />
      <path
        d="M5 13.25C5 12.8358 5.33579 12.5 5.75 12.5C6.16421 12.5 6.5 12.8358 6.5 13.25V15.75C6.5 16.1642 6.16421 16.5 5.75 16.5C5.33579 16.5 5 16.1642 5 15.75V13.25Z"
        fill={fillColor}
      />
      <path
        d="M5 8.25C5 7.83579 5.33579 7.5 5.75 7.5C6.16421 7.5 6.5 7.83579 6.5 8.25V10.75C6.5 11.1642 6.16421 11.5 5.75 11.5C5.33579 11.5 5 11.1642 5 10.75V8.25Z"
        fill={fillColor}
      />
    </svg>
  );
};
