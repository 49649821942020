import { gql } from '../__generated__/gql';

const FILTER_DASHBOARD_ACCESS_GRANTS = gql(`
  query FilterDashboardAccessGrants(
    $tenantId: String!
    $locationId: DAGFStringFilter
    $timeFilter: DashboardTimeFilter
    $status: DAGFAccessGrantStatusFilter
    $state: DAGFAccessGrantStateFilter
    $retryCheckinStatus: DAGFRetryCheckinStatusFilter
    $requirementType: DAGFRequirementTypeFilter
    $requirementStatus: DAGFRequirementStatusFilter
    $userId: String
    $textFilter: DashboardTextFilter
    $limit: Int
    $offset: Int
    $orderBy: [DashboardAccessGrantsOrderBy!]
  ) {
    filterDashboardAccessGrants(
      tenantId: $tenantId
      locationId: $locationId
      timeFilter: $timeFilter
      status: $status
      state: $state
      retryCheckinStatus: $retryCheckinStatus
      requirementType: $requirementType
      requirementStatus: $requirementStatus
      userId: $userId
      textFilter: $textFilter
      limit: $limit
      offset: $offset
      orderBy: $orderBy
    ) {
      data {
        id
        confirmationCode
        state
        status

        startsAt
        startDate
        startTime
        endsAt
        endDate
        endTime
        arrivalTime

        location {
          id
          name
          timezone
        }
        user {
          id
          name
          lastSeenAt
        }
        requirements {
          id
          status
        }
        secondaryUsers {
          user {
            id
            name 
          }
        }
        resources {
          id
          name
          type
        }
      }
      dataCount
      totalCount
    }
  }
`);

export default FILTER_DASHBOARD_ACCESS_GRANTS;
