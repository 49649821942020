import {
  GuestInfoContainer,
  GuestInfoTextContainer,
  GuestInformationBox,
  GuestInformationIcon,
  GuestInformationText,
  GuestInformationTitle,
  LinkContainer,
  LinkText,
} from './styles/ModalInfoBox.styles';
import { ModalInfoBoxProps } from './@types';
import React from 'react';

const ModalInfoBox: React.FC<ModalInfoBoxProps> = ({ itemsList, itemsPerRow }) => {
  return (
    <GuestInformationBox itemsPerRow={itemsPerRow}>
      {itemsList.map(({ icon, item, value, link }, key) => {
        if (link) {
          return (
            <LinkContainer to={link.url} key={`item_${key}`}>
              <LinkText>{link.text}</LinkText>
              <div>&rarr;</div>
            </LinkContainer>
          );
        }

        return (
          <GuestInfoContainer key={`item_${key}`}>
            <GuestInformationIcon>{icon}</GuestInformationIcon>
            <GuestInfoTextContainer>
              <GuestInformationTitle>{item}</GuestInformationTitle>
              <GuestInformationText>{value}</GuestInformationText>
            </GuestInfoTextContainer>
          </GuestInfoContainer>
        );
      })}
    </GuestInformationBox>
  );
};

export default ModalInfoBox;
