import { MdKeyboardArrowDown } from 'react-icons/md';
import React from 'react';
import Theme from '../../../theme';
import styled from 'styled-components';

type DropdownProps = {
  error?: string;
};

const DropdownButton = styled.button<DropdownProps>`
  display: flex;
  width: 100%;
  padding: 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 2px solid
    ${({ error }): string => {
      return error ? Theme.colors.danger.hexCode : Theme.colors.whiteSmoke.hexCode;
    }};
  background: ${Theme.colors.white.hexCode};
`;

const DropdownButtonLabel = styled.input`
  flex: 1;
  border: none;
  color: ${Theme.colors.black.hexCode};
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: start;
  outline: none;

  &:focused {
    border: none;
  }
`;

const Separator = styled.div`
  width: 2px;
  align-self: stretch;
  opacity: 0.3;
  background: ${Theme.colors.black.hexCode};
`;

const iconStyle: React.CSSProperties = {
  color: Theme.colors.black.hexCode,
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 400,
  opacity: 0.3,
};

const Icon = (): React.ReactElement => <MdKeyboardArrowDown style={iconStyle} />;

const DropdownContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`;

const DropdownList = styled.div`
  position: absolute;
  background-color: white;
  border: 2px solid ${Theme.colors.borders.hexCode};
  border-radius: 8px;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.1);
  margin-top: 60px;
  padding: 5px 0;
  z-index: 1;
  width: 100%;
  max-height: 150px;
  overflow: auto;
`;

export const DropdownItem = styled.div`
  padding: 8px 12px;
  cursor: pointer;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &:hover {
    background-color: ${Theme.colors.quickActionButton.hexCode};
    color: ${Theme.colors.white.hexCode};
  }
`;

export const DropdownSeparator = styled.div`
  width: 1px;
  height: 20px;
  background-color: ${Theme.colors.separator.hexCode};
  margin: 0 8px;
`;

export const Dropdown = {
  Button: DropdownButton,
  Label: DropdownButtonLabel,
  Separator,
  Icon,
  Container: DropdownContainer,
  List: DropdownList,
  Item: DropdownItem,
  ListSeparator: DropdownSeparator,
};
