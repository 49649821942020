import {
  AccessGrantStatus,
  RequirementStatus,
  RequirementType,
  SortOrder,
} from '../../../__generated__/graphql';
import Heading, { HeadingModel } from '../../../components/Heading';

import DataTable from '../../../components/DataTable';
import { EntityEnum } from '../../../@types/client/DashboardUserRolePermissions';
import React from 'react';
import VERIFICATIONS_TABLE_HEADERS from '../constants/VerificationsTableHeaders';
import generateVerificationsTableData from '../functions/generateVerificationsTableData';
import moment from 'moment';
import useAccessGrantsTableData from '../../../hooks/useAccessGrantsTableData';

const Verifications: React.FC<{}> = () => {
  const tableData = useAccessGrantsTableData({
    dataTableKey: 'accessGrants-Verifications',
    fetchPolicy: 'no-cache',
    generateTableData: generateVerificationsTableData,
    headers: VERIFICATIONS_TABLE_HEADERS,
    permission: EntityEnum.NONE,
    queryOptions: {
      orderBy: [
        {
          startsAt: SortOrder.Asc,
        },
      ],
      requirementStatus: {
        notIn: [RequirementStatus.Completed, RequirementStatus.NotRequired],
      },
      requirementType: {
        in: [RequirementType.IdVerification, RequirementType.MrzIdVerification],
      },
      timeFilter: {
        startsAt: {
          from: moment(new Date()).subtract(2, 'days').format(),
        },
      },
      status: { notIn: [AccessGrantStatus.Canceled] },
    },
  });

  return (
    <>
      <Heading margin="35px 0 20px" model={HeadingModel.SECONDARY}>
        Stays Requiring Attention
      </Heading>
      <DataTable {...tableData} />
    </>
  );
};

export default Verifications;
