import {
  DashboardTextFilter,
  DashboardTimeFilter,
  FilterDashboardAccessGrantsQuery,
  FilterDashboardAccessGrantsQueryVariables,
} from '../../__generated__/graphql';
import { useCallback, useEffect, useRef, useState } from 'react';
import useDateTimeFormatter, { UseDateTimeFormatterInterface } from '../useDateTimeFormatter';

import ACCESS_GRANTS_SEARCH_FIELDS from './constants/AccessGrantsTableSearchFields';
import ACCESS_GRANTS_TABLE_HEADERS from './constants/AccessGrantsTableHeaders';
import Datum from '../../components/DataTable/@types/Datum';
import { EntityEnum } from '../../@types/client/DashboardUserRolePermissions';
import FILTER_DASHBOARD_ACCESS_GRANTS from '../../graphql/filterDashboardAccessGrants';
import { LocationPageInfo } from '../../pages/Location/@types/GetLocationPageInfo';
import { RootState } from '../../redux/reducers';
import SearchField from '../../@types/SearchField';
import TableHookData from '../@types/TableHookData';
import TableHookProps from '../@types/TableHookProps';
import generateAccessGrantsMembershipQuery from './util/generateAccessGrantsMembershipQuery';
import generateAccessGrantsTableData from './functions/generateAccessGrantsTableData';
import parseAccessGrantsFilterParameters from './util/parseAccessGrantsFilterParameters';
import parseAccessGrantsOrderByParameters from './util/parseAccessGrantsOrderByParameters';
import useAccessGrantFilters from './hooks/useAccessGrantFilters';
import useCheckLocationsAbleToPollReservation from '../../pages/AccessGrants/hooks/useCheckLocationsAbleToPollReservation';
import useGetMany from '../useGetMany';
import useRemoveAccessGrant from '../useRemoveAccessGrant';
import { useSelector } from 'react-redux';

export type FilterDashboardAccessGrantsQueryData = FilterDashboardAccessGrantsQuery;
export type FilterDashboardAccessGrantsQueryArgs = FilterDashboardAccessGrantsQueryVariables;

export type FilterDashboardAccessGrantsQueryWhereInput = Omit<
  FilterDashboardAccessGrantsQueryArgs,
  'tenantId' | 'limit' | 'offset'
>;

export type FilterDashboardAccessGrantsQuerySearchParameters = {
  textFilter?: DashboardTextFilter;
  timeFilter?: DashboardTimeFilter;
};

interface AccessGrantsTableData
  extends Omit<
    TableHookProps<
      FilterDashboardAccessGrantsQueryArgs,
      FilterDashboardAccessGrantsQuerySearchParameters,
      FilterDashboardAccessGrantsQueryData
    >,
    'generateTableData'
  > {
  generateTableData?: (
    formatter: UseDateTimeFormatterInterface,
    data?: FilterDashboardAccessGrantsQueryData
  ) => Datum[];
  relatedLocation?: LocationPageInfo;
  verifyIfLocationsSupportsPollReservation?: boolean;
}

export default function useAccessGrantsTableDataOld({
  dataTableKey = 'accessGrants',
  fetchPolicy = 'cache-first',
  formName = 'accessGrant',
  generateTableData,
  headers = ACCESS_GRANTS_TABLE_HEADERS,
  isSearchable = true,
  isImportEnabled = false,
  permission = EntityEnum.ACCESS_GRANT,
  queryOptions,
  searchFields = ACCESS_GRANTS_SEARCH_FIELDS,
  relatedLocation,
  verifyIfLocationsSupportsPollReservation = false,
}: AccessGrantsTableData): TableHookData<FilterDashboardAccessGrantsQuerySearchParameters> {
  const membership = useSelector((state: RootState) => state.me.activeMembership);
  const options = useRef<Partial<FilterDashboardAccessGrantsQueryArgs>>(queryOptions ?? {});
  const fieldsFiltered = useSelector((state: RootState) => state.dataTable.filters);
  const sortOptions = useSelector((state: RootState) => state.dataTable.sortOptions);
  const filters = useAccessGrantFilters();
  const dateTimeFormatter = useDateTimeFormatter();

  const [searchParameters, setSearchParameters] = useState<
    FilterDashboardAccessGrantsQuerySearchParameters
  >({});

  const { data, error, loading, offset, query, setOffset } = useGetMany<
    FilterDashboardAccessGrantsQueryData,
    FilterDashboardAccessGrantsQueryArgs
  >({
    fetchPolicy,
    graphqlQuery: FILTER_DASHBOARD_ACCESS_GRANTS,
  });

  const createParams = useCallback((): FilterDashboardAccessGrantsQueryArgs => {
    const membershipParams = generateAccessGrantsMembershipQuery(membership);

    const filtersParams = parseAccessGrantsFilterParameters(fieldsFiltered[dataTableKey]);
    const sortParams = parseAccessGrantsOrderByParameters(sortOptions[dataTableKey]);

    const finalParams: FilterDashboardAccessGrantsQueryArgs = {
      ...membershipParams,
      ...filtersParams,
      ...searchParameters,
      ...(sortParams && { orderBy: sortParams }),
    };

    return finalParams;
  }, [membership, sortOptions, fieldsFiltered, dataTableKey, searchParameters]);

  const get = useCallback(() => {
    const params = createParams();

    const variables: FilterDashboardAccessGrantsQueryArgs = {
      ...params,
      ...options.current,
      offset: options.current?.offset ?? offset,
      limit: options.current?.limit ?? 10,
    };

    query(variables);
  }, [createParams, offset, query]);

  const {
    query: queryLocationsAbleToPoll,
    data: dataLocationsAbleToPoll,
  } = useCheckLocationsAbleToPollReservation();

  if (verifyIfLocationsSupportsPollReservation) {
    queryLocationsAbleToPoll();
  }

  useEffect(() => {
    get();
  }, [get]);

  return {
    aditionalDataOnOpeningForm: relatedLocation
      ? {
          location: {
            defaultEndTime: relatedLocation.defaultEndTime,
            defaultStartTime: relatedLocation.defaultStartTime,
            id: relatedLocation.id,
            name: relatedLocation.name,
          },
        }
      : undefined,
    count: data?.filterDashboardAccessGrants.totalCount ?? 0,
    data: generateTableData
      ? generateTableData(dateTimeFormatter, data)
      : generateAccessGrantsTableData(dateTimeFormatter, data),
    dataTableKey,
    dataTableTexts: {
      importText: 'Poll reservation',
    },
    dataType: 'AccessGrant',
    error,
    filters,
    formName,
    headers,
    isImportEnabled: isImportEnabled || dataLocationsAbleToPoll?.thereIsLocationEnabledToPoll,
    isLoading: loading,
    isSearchable,
    offset,
    permission,
    remove: useRemoveAccessGrant,
    search: (
      searchField: SearchField<FilterDashboardAccessGrantsQuerySearchParameters>,
      searchValue: string
    ): void => {
      setSearchParameters({
        [searchField.queryField]: searchField.transform(searchValue),
      });

      setOffset(0);
    },
    searchFields,
    setOffset,
  };
}
