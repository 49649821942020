import {
  AccessSetupCheckInAndCheckoutTimeModalStyle,
  BottomContainer,
  Container,
  OptionLabel,
} from '../styles/AccessSetupModals.styles';
import { FormProvider, useForm } from 'react-hook-form';
import React, { useCallback, useMemo } from 'react';
import {
  SetAccessSetupMutation,
  SetAccessSetupMutationVariables,
} from '../../../__generated__/graphql';
import { closeForm, openActionProcessingModal } from '../../../redux/actions/modals.actions';
import { convertTo24HoursFormat, generate24HoursTimeList } from '../../../util/time';
import { object, string } from 'yup';
import { ActionResult } from '../types';
import { BaseModalHeader } from '../../ActionProcessingModal/components/BaseModalHeader';
import { Button as ButtonComponent } from '../components/Button';
import { InputDropdown } from './InputDropdown';
import Modal from 'react-modal';
import SET_ACCESS_SETUP from '../../../graphql/setAccessSetup';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';

export const AccessSetupCheckInAndCheckOutTimeModal = 'AccessSetupCheckInAndCheckOutTimeModal';

export type CheckInAndCheckoutTime = { checkInTime: string; checkOutTime: string };

const CheckInAndCheckOutTimeModalValuesValidationSchema = object().shape({
  checkInTime: string(),
  checkOutTime: string(),
});
interface CheckinAndCheckoutTimeModal {
  checkInTime: string;
  checkOutTime: string;
  locationId?: string;
  setCheckInAndCheckOutTimeCallback?: (args: CheckInAndCheckoutTime) => void;
}

export const CheckInAndCheckOutTimeModal: React.FC<CheckinAndCheckoutTimeModal> = ({
  checkInTime: initialCheckInTime,
  checkOutTime: initialCheckOutTime,
  locationId,
  setCheckInAndCheckOutTimeCallback,
}) => {
  const dispatch = useDispatch();

  const [updateAccessSetup] = useMutation<SetAccessSetupMutation, SetAccessSetupMutationVariables>(
    SET_ACCESS_SETUP
  );

  const form = useForm<CheckInAndCheckoutTime>({
    defaultValues: { checkInTime: initialCheckInTime, checkOutTime: initialCheckOutTime },
    mode: 'onTouched',
    reValidateMode: 'onChange',
    resolver: yupResolver(CheckInAndCheckOutTimeModalValuesValidationSchema),
  });

  const closeModal = useCallback(() => {
    setCheckInAndCheckOutTimeCallback?.({
      checkInTime: form.getValues('checkInTime'),
      checkOutTime: form.getValues('checkOutTime'),
    });
    dispatch(closeForm());
  }, [form, setCheckInAndCheckOutTimeCallback, dispatch, closeForm]);

  const handleSave = useCallback((): void => {
    const action = async (): Promise<ActionResult> => {
      try {
        await updateAccessSetup({
          variables: {
            data: {
              defaultEndTime: convertTo24HoursFormat(form.getValues('checkOutTime')),
              defaultStartTime: convertTo24HoursFormat(form.getValues('checkInTime')),
            },
            where: { locationId: locationId ?? '' },
          },
        });

        return {
          success: true,
        };
      } catch (error) {
        return {
          success: false,
          errorMessage: (error as Error).message,
        };
      }
    };

    closeModal();
    dispatch(openActionProcessingModal({ action }));
  }, [form, closeModal]);

  const handleCancel = useCallback((): void => {
    dispatch(closeForm());
  }, []);

  const timeList = useMemo(() => generate24HoursTimeList(), []);

  return (
    <Modal isOpen onRequestClose={handleCancel} style={AccessSetupCheckInAndCheckoutTimeModalStyle}>
      <BaseModalHeader headerTitle="Standard Check-in & Check-out Time" onClose={handleCancel} />
      <FormProvider {...form}>
        <Container onSubmit={form.handleSubmit(() => {})}>
          <OptionLabel>Standard Check-in Time</OptionLabel>
          <InputDropdown options={timeList} name="checkInTime" />
          <div style={{ height: '12px' }} />
          <OptionLabel>Standard Check-out Time.</OptionLabel>
          <InputDropdown options={timeList} name="checkOutTime" />
          <BottomContainer>
            <ButtonComponent.Cancel handleCancel={handleCancel} />
            <ButtonComponent.Save handleSave={handleSave} />
          </BottomContainer>
        </Container>
      </FormProvider>
    </Modal>
  );
};
