/* eslint-disable @typescript-eslint/explicit-function-return-type */
import styled, { css } from 'styled-components';
import { BaseModalContainerProps } from '../@types';
import Container from '../../Container';
import Lottie from 'react-lottie';

export const BaseModalContainer = styled.div<BaseModalContainerProps>`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${({ shouldDisplayFooter }) => {
    return (
      shouldDisplayFooter &&
      css`
        justify-content: space-between;
      `
    );
  }}
`;

export const BaseModalBody = styled(Container)`
  gap: 15px;
`;

export const OperationStateContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const OperationMessageText = styled.text`
  font-size: 24px;
  font-weight: 700;
`;

export const SuccessAnimationContainer = styled(Lottie)`
  g {
    g {
      background-color: transparent;
      fill: transparent;
    }
  }
`;

export const MessageText = styled.text`
  font-size: 17px;
  font-weight: 400;
  overflow: hidden;
`;
