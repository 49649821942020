import {
  DeleteEarlyCheckInPackageMutation,
  MutationDeleteEarlyCheckInPackageArgs,
} from '../../__generated__/graphql';
import DELETE_EARLY_CHECKIN_PACKAGE from '../../graphql/deleteEarlyCheckInPackage';
import MutationHookData from '../@types/MutationHookData';
import MutationHookParams from '../@types/MutationHookParams';
import { useMutation } from '@apollo/client';

type DeleteEarlyCheckInPackageData = DeleteEarlyCheckInPackageMutation;

export function useDeleteEarlyCheckInPackage({
  onCompleted,
  onError,
}: MutationHookParams<DeleteEarlyCheckInPackageData> = {}): MutationHookData<
  string,
  DeleteEarlyCheckInPackageData
> {
  const [update, { data, error, loading }] = useMutation<
    DeleteEarlyCheckInPackageData,
    MutationDeleteEarlyCheckInPackageArgs
  >(DELETE_EARLY_CHECKIN_PACKAGE, {
    onCompleted,
    onError,
  });

  return {
    data,
    error,
    loading,
    mutation: (serviceId: string): void => {
      update({
        variables: {
          serviceId,
        },
      });
    },
  };
}
