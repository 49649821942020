import styled, { FlattenSimpleInterpolation, css } from 'styled-components';
import { AccessSetupModalStyle } from '../../AccessSetup/styles/AccessSetupModals.styles';
import { BaseModalContainerProps } from './BaseActionModal';
import Lottie from 'react-lottie';
import { Styles } from 'react-modal';
import Theme from '../../../theme';

export const OperationStateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const OperationMessageText = styled.text`
  font-size: 24px;
  font-weight: 700;
`;

export const SuccessAnimationContainer = styled(Lottie)`
  g {
    g {
      background-color: transparent;
      fill: transparent;
    }
  }
`;

export const MessageText = styled.text`
  font-size: 17px;
  font-weight: 400;
  text-align: justify;
  overflow: hidden;
`;

export const BaseModalContainer = styled.div<BaseModalContainerProps>`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${({ shouldDisplayFooter }): FlattenSimpleInterpolation | undefined => {
    return shouldDisplayFooter
      ? css`
          justify-content: space-between;
        `
      : undefined;
  }}
`;

export const ActionProcessingStyle: Styles = {
  ...AccessSetupModalStyle,
  content: {
    ...AccessSetupModalStyle.content,
    backgroundColor: Theme.colors.whiteSmoke.hexCode,
    borderRadius: '12px',
    height: 285,
    width: 400,
  },
};
