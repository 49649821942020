import Theme from '../../../theme';
import styled from 'styled-components';

export const InputContainer = styled.form`
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns */
  grid-template-rows: repeat(3, 1fr); /* 3 rows */

  & > :nth-child(1) {
    grid-column: 1;
    grid-row: 1;
  } /* First item */

  & > :nth-child(2) {
    grid-column: 2;
    grid-row: 1;
  } /* Second item */

  & > :nth-child(3) {
    grid-column: 1 / span 2; /* Span full width */
    grid-row: 2;
  } /* Third item */

  & > :nth-child(4) {
    grid-column: 2;
    grid-row: 3;
  } /* Last item aligned right */

  gap: 10px;
  margin: 0;
`;

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
`;

export const Label = styled.p`
  color: ${Theme.colors.black.hexCode};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 8px;
  height: 16px;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 16px;
`;
