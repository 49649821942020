import Theme from '../../../../../../theme';
import styled from 'styled-components';

export const GuestEnagementContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  & > button {
    width: 100%;
    height: 42px;
    color: ${Theme.colors.quickActionButton.hexCode};
    background-color: ${Theme.colors.white.hexCode};
    border: 1px solid ${Theme.colors.quickActionButton.hexCode};
    padding: 10px 24px 10px 24px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 12px;
    transition: background-color 0.3s ease, color 0.3s ease;

    &:hover {
      color: ${Theme.colors.white.hexCode};
      background-color: ${Theme.colors.quickActionButton.hexCode};
    }
  }
`;
