import React from 'react';
import useDateTimeFormatter from '../../../../../hooks/useDateTimeFormatter';

const FormattedTime: React.FC<{
  dateTime: string | Date;
  timezone: string;
}> = ({ dateTime, timezone }) => {
  const { formatDate, formatTime } = useDateTimeFormatter();
  const dateFormat = 'MMMM DD';
  const timeFormat = 'h:mmA';

  return (
    <div>
      <p>
        {formatDate(dateTime, {
          timezone,
          format: dateFormat,
        })}
      </p>
      <p>
        {formatTime(dateTime, {
          timezone,
          format: timeFormat,
        })}
      </p>
    </div>
  );
};

export default FormattedTime;
