import { BlurredContainer, MessageContainer } from './styles/LiveUsageNotAvailable.styles';
import React from 'react';

const LiveUsageNotAvailable: React.FC = () => {
  return (
    <BlurredContainer>
      <MessageContainer>
        <p>Kiosk Live Usage</p>
        <p>
          Start, view and assist guests with reservations at any kiosk in real-time. Please contact
          your Virdee customer support representative for more information.
        </p>
      </MessageContainer>
    </BlurredContainer>
  );
};

export default LiveUsageNotAvailable;
