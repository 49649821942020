import { Header } from '../../../components/DataTable/@types/DataTableProps';
import SortType from '../../../components/DataTable/@types/SortType.enum';

const ACCESS_GRANTS_TABLE_HEADERS: Header[] = [
  {
    displayValue: 'Reservation #',
    isSortable: true,
    sortType: SortType.SortOrderInput,
    value: 'confirmationCode',
  },
  { displayValue: 'Primary Guest', isSortable: false, value: 'user.name' },
  { displayValue: 'Secondary Guest(s)', isSortable: false },
  { displayValue: 'State', isSortable: true, sortType: SortType.SortOrderInput, value: 'state' },
  {
    displayValue: 'Room #',
    isSortable: false,
  },
  { displayValue: 'Location', isSortable: false, value: 'location.name' },
  { displayValue: 'Start', isSortable: true, sortType: SortType.SortOrder, value: 'startsAt' },
  { displayValue: 'End', isSortable: true, sortType: SortType.SortOrder, value: 'endsAt' },
  { displayValue: 'In/Out', isSortable: false },
];

export default ACCESS_GRANTS_TABLE_HEADERS;
