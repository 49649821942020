import { ActiveMembership } from '../../../redux/reducers/@types/MeReduxState';
import { FilterDashboardAccessGrantsQueryArgs } from '..';
import { MembershipAccessLevelEnum } from '../../../util/membership/types';
import { getMembershipAccessLevel } from '../../../util/membership/membershipUtils';

type MembershipQuery = Pick<FilterDashboardAccessGrantsQueryArgs, 'tenantId' | 'locationId'>;

export default function generateAccessGrantsMembershipQuery(
  membership: ActiveMembership
): MembershipQuery {
  const baseTenantQuery: MembershipQuery = {
    tenantId: membership.tenantId,
  };

  let locationIds: string[] = [];

  const membershipAccessLevel = getMembershipAccessLevel(membership);
  if (membershipAccessLevel === MembershipAccessLevelEnum.location && membership.location?.id) {
    locationIds = [membership.location?.id];
  } else if (membershipAccessLevel === MembershipAccessLevelEnum.locationGroup) {
    locationIds = membership.locationGroup?.locationIds ?? [];
  }

  return {
    ...baseTenantQuery,
    ...(locationIds.length > 0 ? { locationId: { in: locationIds } } : {}),
  };
}
