import Theme from '../../../theme';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 12px 16px;
  align-items: center;
  border-radius: 8px;
  background: ${Theme.colors.bgBlackTint.hexCode};
  min-width: 321px;
  gap: 20px;
  margin-top: 8px;
`;

export const TimeContainer = styled.div`
  background-color: ${Theme.colors.dark.hexCode};
  padding: 11px 16px;
  align-items: center;
  min-width: 86px;
  height: 41px;
  border-radius: 8px;
`;

export const Text = styled.span`
  color: ${Theme.colors.white.hexCode};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
