import {
  CreateEarlyCheckInPackageMutation,
  MutationCreateEarlyCheckInPackageArgs,
} from '../../__generated__/graphql';
import CREATE_EARLY_CHECKIN_PACKAGE from '../../graphql/createEarlyCheckInPackage';
import { MutationHookDataWithPromise } from '../@types/MutationHookData';
import MutationHookParams from '../@types/MutationHookParams';
import { useMutation } from '@apollo/client';

type CreateEarlyCheckInPackageData = CreateEarlyCheckInPackageMutation;

export function useCreateEarlyCheckInPackage({
  onCompleted,
  onError,
}: MutationHookParams<CreateEarlyCheckInPackageData> = {}): MutationHookDataWithPromise<
  MutationCreateEarlyCheckInPackageArgs,
  CreateEarlyCheckInPackageData
> {
  const [create, { data, error, loading }] = useMutation<
    CreateEarlyCheckInPackageData,
    MutationCreateEarlyCheckInPackageArgs
  >(CREATE_EARLY_CHECKIN_PACKAGE, {
    onCompleted,
    onError,
  });

  return {
    data,
    error,
    loading,
    promise: create,
  };
}
