import 'styled-components/macro';

import Heading, { HeadingModel } from '../../../components/Heading';
import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';

import { AccessGrantStatus } from '../../../__generated__/graphql';
import Container from '../../../components/Container';
import DataTable from '../../../components/DataTable';
import { EntityEnum } from '../../../@types/client/DashboardUserRolePermissions';
import { SingleDatePicker } from 'react-dates';
import useAccessGrantsTableData from '../../../hooks/useAccessGrantsTableData';

const ArrivalsTab: React.FC<{}> = () => {
  const [day, setDay] = useState<Moment>(moment());
  const [dateFocused, setDateFocused] = useState<boolean | null>(null);

  const { search, ...tableData } = useAccessGrantsTableData({
    dataTableKey: 'accessGrants-Arrivals',
    permission: EntityEnum.NONE,
    queryOptions: {
      status: { notIn: [AccessGrantStatus.Canceled] },
    },
  });

  function searchByDay(_day: Moment): void {
    search(
      {
        label: 'StartsAt',
        queryField: 'timeFilter',
        transform: () => {
          return {
            startsAt: {
              from: _day.startOf('day').format(),
              to: _day.endOf('day').format(),
            },
          };
        },
      },
      _day.startOf('day').format()
    );
  }

  useEffect(() => {
    searchByDay(day);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [day]);

  return (
    <>
      <Heading margin="0 0 10px" model={HeadingModel.TERTIARY}>
        Select Date
      </Heading>
      <Container
        css={`
          margin: 0 0 20px;
          width: 150px;
        `}
      >
        <SingleDatePicker
          date={day}
          id="single-date-picker"
          focused={dateFocused}
          onDateChange={(date: Moment | null): void => {
            if (date) {
              setDay(date);
            } else {
              setDay(moment().startOf('day'));
            }
          }}
          onFocusChange={({ focused }): void => setDateFocused(focused)}
        />
      </Container>
      <DataTable {...tableData} search={search} />
    </>
  );
};

export default ArrivalsTab;
