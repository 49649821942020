import {
  AccessSetupSettingsOption,
  EarlyCheckInPackageData,
  Settings,
  useSettings,
} from './Settings';
import { AccessSetupTimeRangeModal, TimeRangeModal } from './TimeRangeModal';
import React, { useCallback, useEffect, useState } from 'react';
import {
  closeAccessSetupConfirmationModal,
  closeForm,
  openForm,
} from '../../../redux/actions/modals.actions';
import { ConfirmationModal } from './ConfirmationModal';
import { PackagesList } from './PackagesList';
import { useAccessSetup } from '../../../pages/Location/components/AccessSetupContext';
import { useConfirmationModal } from '../hooks/useConfirmationModal';
import { useDeleteEarlyCheckInPackage } from '../../../hooks/useDeleteEarlyCheckInPackage';
import { useDispatch } from 'react-redux';
import useSetAccessSetup from '../../../hooks/useSetAccessSetup';
import { withActionHandler } from '../hocs/withActionHandler';

export const KioskPaidEarlyCheckIn: React.FC = () => {
  const { locationId, fetchData, data } = useAccessSetup();
  const { openConfirmationModal, modalState } = useConfirmationModal();
  const dispatch = useDispatch();
  const { options, updateOption } = useSettings();
  const { mutation: updateAccessSetup } = useSetAccessSetup({
    onCompleted: fetchData,
  });
  const { mutation: deleteEarlyCheckInPackage } = useDeleteEarlyCheckInPackage({
    onCompleted: fetchData,
  });
  const [selectedPackage, setSelectedPackage] = useState<EarlyCheckInPackageData>();

  const onEdit = (earlyCheckInPackage?: EarlyCheckInPackageData): void => {
    const id = AccessSetupTimeRangeModal;
    setSelectedPackage(earlyCheckInPackage);
    dispatch(openForm(AccessSetupTimeRangeModal, id, {}));
  };

  const onDelete = useCallback(
    (earlyCheckInPackage?: EarlyCheckInPackageData): void => {
      const action = withActionHandler(() => {
        if (earlyCheckInPackage?.serviceId) {
          deleteEarlyCheckInPackage(earlyCheckInPackage?.serviceId);
        }

        dispatch(closeAccessSetupConfirmationModal());
      });

      openConfirmationModal({
        id: AccessSetupSettingsOption.kioskPaidEarlyCheckInChecked,
        action,
      });
    },
    [openConfirmationModal, dispatch]
  );

  const onCreate = (): void => {
    const id = AccessSetupTimeRangeModal;
    setSelectedPackage(undefined);
    dispatch(openForm(AccessSetupTimeRangeModal, id, {}));
  };

  const onConfirm = useCallback(() => {
    const newState = !options.kioskPaidEarlyCheckInChecked.enabled;

    updateOption('kioskPaidEarlyCheckInChecked', newState);

    updateAccessSetup(
      {
        enableEarlyCheckInByTimeRange: newState,
        enableEarlyCheckInByLoyalty: options.kioskVipLoyaltyCodeOptionsChecked.enabled,
      },
      locationId
    );

    dispatch(closeAccessSetupConfirmationModal());
  }, [updateOption, dispatch, options]);

  const handleCheckboxClick = useCallback(() => {
    const action = withActionHandler(() => {
      onConfirm();
    });
    openConfirmationModal({
      id: AccessSetupSettingsOption.kioskPaidEarlyCheckInChecked,
      action,
    });
    dispatch(closeForm());
  }, [openConfirmationModal, onConfirm]);

  useEffect(() => {
    updateOption('kioskPaidEarlyCheckInChecked', data?.enableEarlyCheckInByTimeRange ?? false);
  }, [data?.enableEarlyCheckInByTimeRange]);

  return (
    <Settings.Container
      description="Set variable check-in pricing by time range. Guests will only be offered the early check-in option if their room type is available and ready."
      title="Kiosk Paid Early Check-in"
    >
      <Settings.Provider>
        <Settings.Toogle
          title="Enable Kiosk Paid Early Check-in"
          onChange={handleCheckboxClick}
          checked={options.kioskPaidEarlyCheckInChecked.enabled}
        />
        <PackagesList
          onEdit={onEdit}
          onDelete={onDelete}
          onCreate={onCreate}
          enabled={options.kioskPaidEarlyCheckInChecked.enabled}
        />
        {modalState.accessSetup &&
          modalState.accessSetup.id === AccessSetupSettingsOption.kioskPaidEarlyCheckInChecked && (
            <ConfirmationModal
              headerTitle={modalState.accessSetup.title}
              content={modalState.accessSetup.message}
              id={modalState.accessSetup.id}
              onConfirm={modalState.accessSetup.action}
            />
          )}
        {modalState.form.name === AccessSetupTimeRangeModal && (
          <TimeRangeModal earlyCheckInPackage={selectedPackage} />
        )}
      </Settings.Provider>
    </Settings.Container>
  );
};
