import { DeepPartial, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { GracePeriod, Maybe } from '../../../__generated__/graphql';
import React, { useCallback, useEffect, useMemo } from 'react';

import { Bars } from 'svg-loaders-react';
import { Colors } from '../../../theme/colors';
import Container from '../../Container';
import CustomSectionTitle from './CustomSectionTitle';
import { EARLY_CHECK_IN_VALUES_VALIDATION_SCHEMA } from '../constants/earlyCheckInValidationSchema';
import { EarlyCheckInFormValues } from '../@types/EarlyCheckInFormValues';
import EarlyCheckInInput from './EarlyCheckInInput';
import { EarlyCheckInTypeEnum } from '../enums';
import { Form } from '../styles';
import { SubmitButton } from '../styles/GracePeriodSection.styles';
import { cloneDeep } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';

interface EarlyCheckInSectionProps {
  gracePeriods: GracePeriod | undefined;
  setGracePeriodsConfigCallback: (gracePeriodsData: GracePeriod) => void;
  isSubmitting: boolean;
}

const EarlyCheckInSection: React.FC<EarlyCheckInSectionProps> = ({
  setGracePeriodsConfigCallback,
  gracePeriods,
  isSubmitting,
}: EarlyCheckInSectionProps) => {
  const form = useForm<EarlyCheckInFormValues>({
    defaultValues: {},
    mode: 'all',
    resolver: yupResolver(EARLY_CHECK_IN_VALUES_VALIDATION_SCHEMA),
    reValidateMode: 'onChange',
  });
  const { reset, trigger } = form;

  const earlyCheckInDefaultValues = useMemo((): DeepPartial<EarlyCheckInFormValues> | undefined => {
    if (gracePeriods) {
      const { earlyCheckInConfig } = gracePeriods;

      return {
        allowEarlyCheckIn: earlyCheckInConfig?.allowEarlyCheckIn,
        earlyCheckInType: earlyCheckInConfig?.checkInGracePeriod
          ? EarlyCheckInTypeEnum.TIME_CONSTRAINED
          : EarlyCheckInTypeEnum.ALL_DAY,
        timeConstrainedInMinutes: earlyCheckInConfig?.checkInGracePeriod,
      };
    }

    return undefined;
  }, [gracePeriods]);

  useEffect(() => {
    if (earlyCheckInDefaultValues) {
      reset(earlyCheckInDefaultValues);
      trigger();
    }
  }, [earlyCheckInDefaultValues, reset, trigger]);

  const getNewEarlyCheckInConfigObject = useCallback(
    (data: EarlyCheckInFormValues) => {
      const getCheckInGracePeriodValue = ({
        earlyCheckInType,
        timeConstrainedInMinutes,
      }: EarlyCheckInFormValues): Maybe<number> | undefined => {
        if (earlyCheckInType === EarlyCheckInTypeEnum.TIME_CONSTRAINED) {
          return timeConstrainedInMinutes;
        }

        return undefined;
      };

      if (gracePeriods) {
        const newGracePeriods = cloneDeep(gracePeriods);

        newGracePeriods.earlyCheckInConfig = {
          allowEarlyCheckIn: data.allowEarlyCheckIn,
          checkInGracePeriod: getCheckInGracePeriodValue(data),
        };

        return newGracePeriods;
      }

      return gracePeriods;
    },
    [gracePeriods]
  );

  const onSubmit: SubmitHandler<EarlyCheckInFormValues> = (data): void => {
    const newEarlyCheckInConfig = getNewEarlyCheckInConfigObject(data);

    if (newEarlyCheckInConfig) {
      setGracePeriodsConfigCallback(newEarlyCheckInConfig);
    }
  };

  const SubmitButtonComponent: React.FC = () => {
    return (
      <SubmitButton type="submit" disabled={isSubmitting} isSubmitting={isSubmitting}>
        {isSubmitting ? (
          <Bars fill={Colors.shuttleGrey} height="25px" stroke={Colors.shuttleGrey} width="25px" />
        ) : (
          'Save changes'
        )}
      </SubmitButton>
    );
  };

  return (
    <Container>
      <CustomSectionTitle title="Check-in Grace Period" smallerTitle margin="50px 0 0 0" />
      <FormProvider {...form}>
        <Form onSubmit={form.handleSubmit(onSubmit)}>
          <EarlyCheckInInput />
          <SubmitButtonComponent />
        </Form>
      </FormProvider>
    </Container>
  );
};

export default EarlyCheckInSection;
