import { AccessSetupSettings, AccessSetupSettingsOption } from '../components/Settings';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncOrSyncFn } from '../hocs/withActionHandler';
import ModalsReduxState from '../../../redux/reducers/@types/ModalsReduxState';
import React from 'react';
import { RootState } from '../../../redux/reducers';
import { openAccessSetupConfirmationModal } from '../../../redux/actions/modals.actions';

interface OpenConfirmationModalArgs {
  action: AsyncOrSyncFn;
  id: keyof AccessSetupSettings;
}
interface UseConfirmationModal {
  openConfirmationModal: (args: OpenConfirmationModalArgs) => void;
  headerTitle?: string;
  content?: string;
  modalState: ModalsReduxState;
}

export const useConfirmationModal = (): UseConfirmationModal => {
  const dispatch = useDispatch();

  const modalState = useSelector((state: RootState) => state.modals);

  const data = modalState.form.aditionalDataOnOpeningForm as
    | { headerTitle?: string; content?: string; id: string }
    | undefined;

  const openConfirmationModal = ({ action, id }: OpenConfirmationModalArgs): void => {
    switch (id) {
      case AccessSetupSettingsOption.checkInGracePeriodsChecked:
        dispatch(
          openAccessSetupConfirmationModal({
            id: AccessSetupSettingsOption.checkInGracePeriodsChecked,
            title: 'Grace Period Change',
            message: (
              <span>
                This change will apply to <strong>ALL</strong> future reservations and may impact
                guest expectations, operational schedules, and automated communications. Guests with
                existing reservations will <strong>NOT</strong> be notified automatically, so you
                may need to inform them separately.
              </span>
            ),
            action,
          })
        );
        break;
      case AccessSetupSettingsOption.kioskPaidEarlyCheckInChecked:
        dispatch(
          openAccessSetupConfirmationModal({
            id: AccessSetupSettingsOption.kioskPaidEarlyCheckInChecked,
            title: 'Kiosk Paid Early Check-in Change',
            message: (
              <span>
                This change will apply to <strong>ALL</strong> future reservations and may impact
                guest expectations, operational schedules, and automated communications. Guests with
                existing reservations will
                <strong> NOT</strong> be notified automatically, so you may need to inform them
                separately.
              </span>
            ),
            action,
          })
        );
        break;
      case AccessSetupSettingsOption.kioskVipLoyaltyCodeOptionsChecked:
        dispatch(
          openAccessSetupConfirmationModal({
            id: AccessSetupSettingsOption.kioskVipLoyaltyCodeOptionsChecked,
            title: 'Kiosk Paid Early Check-in Change',
            message: (
              <span>
                This change will apply to <strong>ALL</strong> future reservations and may impact
                guest expectations, operational schedules, and automated communications. Guests with
                existing reservations will
                <strong> NOT</strong> be notified automatically, so you may need to inform them
                separately.
              </span>
            ),
            action,
          })
        );
        break;
      default:
        break;
    }
  };

  return {
    ...data,
    openConfirmationModal,
    modalState,
  };
};
