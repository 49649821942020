/* eslint-disable sort-keys */
import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3ThemeCheckInFailSchema: NodeSchemaProperties = {
  checkInFail: {
    title: '#25 Check In fail',
    type: 'object',
    properties: {
      warningIcon: {
        title: '#1 Warning Icon',
        description: '[checkInFail.warningIcon]',
        type: 'object',
        properties: {
          uri: {
            description: '[checkInFail.warningIcon.uri]',
            title: '#1.1 URI',
            type: 'string',
          },
        },
      },
      'displayRemoteAssistanceButton:enable': {
        description: '[checkInFail.displayRemoteAssistanceButton]',
        title: '#2 Display remote assistance button',
        type: 'boolean',
      },
      displayRemoteAssistanceButton: {
        type: undefined,
        enum: [true, false],
      },
      'displayEndSessionButton:enable': {
        description: '[checkInFail.displayEndSessionButton]',
        title: '#3 Display end session button',
        type: 'boolean',
      },
      displayEndSessionButton: {
        type: undefined,
        enum: [true, false],
      },
      'useNewCheckInFailScreen:enable': {
        description: '[checkInFail.useNewCheckInFailScreen]',
        title: '#4 Use new check-in fail screen',
        type: 'boolean',
      },
      useNewCheckInFailScreen: {
        type: undefined,
        enum: [true, false],
      },
      'headerTextColor:enable': {
        description: '[checkInFail.headerTextColor]',
        title: '#5 Header text color',
        type: 'boolean',
      },
      headerTextColor: {
        type: 'string',
      },
      'titleTextColor:enable': {
        description: '[checkInFail.titleTextColor]',
        title: '#6 Title text color',
        type: 'boolean',
      },
      titleTextColor: {
        type: 'string',
      },
      'warningIconColor:enable': {
        description: '[checkInFail.warningIconColor]',
        title: '#7 Warning icon color',
        type: 'boolean',
      },
      warningIconColor: {
        type: 'string',
      },
    },
  },
};

export const mobileV3ThemeCheckInFailUiSchema: NodeUiSchemaProperties = {
  checkInFail: {
    warningIcon: {
      uri: {},
    },
    displayRemoteAssistanceButton: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    displayEndSessionButton: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    useNewCheckInFailScreen: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    headerTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    titleTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    warningIconColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
  },
};
