import {
  KeyDispensingStepsContainer,
  StepNumber,
  StepTitle,
  TitleContainer,
} from './styles/KioskLiveUsageQuickActionsModal.styles';
import { KeyDispensingStepProps } from '../../types';
import React from 'react';

const KeyDispensingStep: React.FC<KeyDispensingStepProps> = ({ imageSrc, step, title }) => {
  return (
    <KeyDispensingStepsContainer>
      <img src={imageSrc} alt={title} />
      <TitleContainer>
        <StepNumber>{step}</StepNumber>
        <StepTitle>{title}</StepTitle>
      </TitleContainer>
    </KeyDispensingStepsContainer>
  );
};

export default KeyDispensingStep;
