import Theme from '../../../theme';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 40px;
  align-self: stretch;
  border-radius: 8px;
  width: 800px;
  align-items: center;
  justify-content: space-between;
  background-color: ${Theme.colors.bgBlackTint.hexCode};
  margin-bottom: 12px;
`;

export const Title = styled.p`
  color: ${Theme.colors.white.hexCode};
  font-feature-settings: 'liga' off, 'clig' off;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.5px;
`;

export const Value = styled.p<{ empty?: boolean }>`
  color: ${({ empty }): string => {
    return empty ? Theme.colors.grey.hexCode : Theme.colors.white.hexCode;
  }};
  font-feature-settings: 'liga' off, 'clig' off;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  max-width: 200px;
  overflow-wrap: break-word;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 240px;
`;

export const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;
