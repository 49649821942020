import { RequirementStatus, RequirementType } from '../../../../../__generated__/graphql';
import { SelectorTypeEnum, SupportedRequirement } from '../types';

export const SUPPORTED_REQUIREMENTS: SupportedRequirement[] = [
  {
    type: RequirementType.IdVerification,
    displayText: 'ID Verification',
    selectors: [
      {
        text: SelectorTypeEnum.AGENT_COMPLETE,
        status: RequirementStatus.Completed,
      },
      {
        text: SelectorTypeEnum.REQUEST_AGAIN,
        status: RequirementStatus.Required,
      },
    ],
  },
  {
    type: RequirementType.PaymentStatus,
    displayText: 'Payment',
    selectors: [
      {
        text: SelectorTypeEnum.REQUEST_AGAIN,
        status: RequirementStatus.Required,
      },
    ],
  },
];

export const GWR_TENANT_ID = '26';

export enum REQUIREMENT_SELECTOR_STATE {
  SELECTED = 'SELECTED',
  UNSELECTED = 'UNSELECTED',
  DISABLED = 'DISABLED',
}

export const requirementOverrideSelectorState: Record<
  RequirementStatus,
  Partial<Record<SelectorTypeEnum, boolean>>
> = {
  [RequirementStatus.Completed]: {
    [SelectorTypeEnum.AGENT_COMPLETE]: false,
    [SelectorTypeEnum.REQUEST_AGAIN]: true,
  },
  [RequirementStatus.Required]: {
    [SelectorTypeEnum.AGENT_COMPLETE]: true,
    [SelectorTypeEnum.REQUEST_AGAIN]: false,
  },
  [RequirementStatus.Failed]: {
    [SelectorTypeEnum.AGENT_COMPLETE]: true,
    [SelectorTypeEnum.REQUEST_AGAIN]: true,
  },
  [RequirementStatus.NotRequired]: {
    [SelectorTypeEnum.AGENT_COMPLETE]: false,
    [SelectorTypeEnum.REQUEST_AGAIN]: false,
  },
  [RequirementStatus.ContactUser]: {
    [SelectorTypeEnum.AGENT_COMPLETE]: true,
    [SelectorTypeEnum.REQUEST_AGAIN]: true,
  },
  [RequirementStatus.Redo]: {
    [SelectorTypeEnum.AGENT_COMPLETE]: true,
    [SelectorTypeEnum.REQUEST_AGAIN]: true,
  },
  [RequirementStatus.VerifyInformation]: {
    [SelectorTypeEnum.AGENT_COMPLETE]: true,
    [SelectorTypeEnum.REQUEST_AGAIN]: true,
  },
  [RequirementStatus.Processing]: {
    [SelectorTypeEnum.AGENT_COMPLETE]: false,
    [SelectorTypeEnum.REQUEST_AGAIN]: false,
  },
};
