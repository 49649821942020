import { ActionProcessingModal } from '..';
import React from 'react';
import { RootState } from '../../../redux/reducers';
import { useSelector } from 'react-redux';

export const ActionProcessingModals: React.FC = () => {
  const modalState = useSelector((state: RootState) => state.modals);

  const data = modalState.actionProcessingModal;

  return (
    <>
      {!!data && (
        <ActionProcessingModal
          action={data.action}
          error={
            data.error
              ? {
                  title: data.error.title,
                  message: data.error.message,
                }
              : undefined
          }
        />
      )}
    </>
  );
};
