import {
  KioskLiveUsageCardHeaderStyle,
  KioskLiveUsageCardKioskNameStyle,
  KioskLiveUsageCardStatusActiveStyle,
  KioskLiveUsageCardStatusErrorStyle,
  KioskLiveUsageCardStatusIdleStyle,
  KioskLiveUsageCardStatusStyle,
} from './styles/KioskLiveUsageCard.style';
import React, { useMemo } from 'react';
import { KioskCardTitleProps } from '../types';
import { Link } from 'react-router-dom';
import links from '../../../../../util/links';

export const KioskCardTitle: React.FC<KioskCardTitleProps> = ({ kiosk, hasFailedRequirement }) => {
  const { kioskStatus, kioskStatusStyle } = useMemo(() => {
    const isActive = !!kiosk.accessGrant;

    let status = 'Idle';
    let style: React.CSSProperties = KioskLiveUsageCardStatusIdleStyle;

    if (hasFailedRequirement) {
      status = 'Error';
      style = KioskLiveUsageCardStatusErrorStyle;
    } else if (isActive) {
      status = 'Active';
      style = KioskLiveUsageCardStatusActiveStyle;
    }

    return { kioskStatus: status, kioskStatusStyle: style };
  }, [kiosk.accessGrant]);

  return (
    <div style={KioskLiveUsageCardHeaderStyle}>
      <Link to={`${links.paths.KIOSKS}/${kiosk.kiosk.id}`} style={KioskLiveUsageCardKioskNameStyle}>
        {kiosk.kiosk.name}
      </Link>
      <p style={{ ...KioskLiveUsageCardStatusStyle, ...kioskStatusStyle }}>{kioskStatus}</p>
    </div>
  );
};
