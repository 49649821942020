import 'styled-components/macro';

import { Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';

import Button from '../../../Button';
import ButtonModel from '../../../Button/enums/ButtonModel.enum';
import Container from '../../../Container';
import Input from './components/Input';
import SearchField from '../../../../@types/SearchField';
import SearchIcon from '../../../../assets/icons/search';
import SearchInputFilterContainerStyle from './styles/SearchInputFilterContainerStyle';
import SearchInputInnerContainerStyle from './styles/SearchInputInnerContainerStyle';
import SearchInputOuterContainerStyle from './styles/SearchInputOuterContainerStyle';

interface SearchInputFormValues {
  searchValue: string;
}

export interface SearchInputProps {
  search: (searchField: SearchField, searchValue: string) => void;
  searchFields: SearchField[];
  onChange?: (searchValue: string) => void;
}

const SearchInput: React.FunctionComponent<SearchInputProps> = ({
  search,
  searchFields,
  onChange,
}: SearchInputProps) => {
  const [selectedFilter, selectFilter] = useState<SearchField>(searchFields[0]);

  function handleFilterClick(e: React.SyntheticEvent<HTMLButtonElement>): void {
    const newFilter = searchFields.find(
      (searchField) => searchField.label === e.currentTarget.innerHTML
    );

    if (newFilter) {
      selectFilter(newFilter);
    }
  }

  function handleFormSubmit(
    values: SearchInputFormValues,
    actions: FormikHelpers<SearchInputFormValues>
  ): void {
    actions.setSubmitting(true);
    search(selectedFilter, values.searchValue.trim());
    onChange?.(values.searchValue.trim());
    actions.setSubmitting(false);
  }

  return (
    <Container css={SearchInputOuterContainerStyle}>
      <Formik
        initialValues={{
          searchValue: '',
        }}
        onSubmit={handleFormSubmit}
      >
        {({ handleChange, handleSubmit, values }): React.ReactElement => (
          <form onSubmit={handleSubmit}>
            <Container alignContent="center" css={SearchInputInnerContainerStyle}>
              <SearchIcon />
              <Input
                onChange={handleChange}
                placeholder="Input"
                name="searchValue"
                type="text"
                value={values.searchValue}
              />
              <Button model={ButtonModel.INPUT_EMBEDDED_BUTTON} type="submit">
                Search
              </Button>
            </Container>
          </form>
        )}
      </Formik>
      <Container css={SearchInputFilterContainerStyle}>
        {searchFields.map((searchField) => (
          <Button
            bold
            key={`search-field-${searchField.queryField}-${searchField.label}`}
            model={
              `${selectedFilter.queryField}-${selectedFilter.label}` ===
              `${searchField.queryField}-${searchField.label}`
                ? ButtonModel.FILTER_DARK_SELECTED
                : ButtonModel.FILTER_DARK
            }
            onClick={handleFilterClick}
          >
            {searchField.label}
          </Button>
        ))}
      </Container>
    </Container>
  );
};

export default SearchInput;
