import styled, { FlattenSimpleInterpolation, css } from 'styled-components';
import Theme from '../../../../theme';

export const RemoteAssistanceTabButton = styled.button<{ active: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;
  width: 210px;
  font-size: 16px;
  font-weight: 600;
  padding: 0;
  border-radius: 12px;
  background-color: ${Theme.colors.white.hexCode};
  color: ${Theme.colors.black.hexCode};
  border: none;

  ${({ active }): FlattenSimpleInterpolation => css`
    ${active &&
    css`
      background-color: ${Theme.colors.black.hexCode};
      color: ${Theme.colors.white.hexCode};
    `}
  `}

  & > svg {
    padding-right: 10px;
    width: 28px;
  }
`;
