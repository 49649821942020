import { DashboardTextFilterType } from '../../../__generated__/graphql';
import { FilterDashboardAccessGrantsQuerySearchParameters } from '..';

import SearchField from '../../../@types/SearchField';
import createSearchField from '../../../util/tableData/createSearchField';
import { transformAccessGrantStringFilter } from '../util/transformAccessGrantStringFilter';

const ACCESS_GRANTS_SEARCH_FIELDS: SearchField<
  FilterDashboardAccessGrantsQuerySearchParameters
>[] = [
  createSearchField<FilterDashboardAccessGrantsQuerySearchParameters, 'textFilter'>({
    label: 'Guest Name',
    queryField: 'textFilter',
    transform: (key: string) =>
      transformAccessGrantStringFilter(DashboardTextFilterType.UserName, key),
  }),
  createSearchField<FilterDashboardAccessGrantsQuerySearchParameters, 'textFilter'>({
    label: 'Reservation #',
    queryField: 'textFilter',
    transform: (key: string) =>
      transformAccessGrantStringFilter(DashboardTextFilterType.ConfirmationCode, key),
  }),
  createSearchField<FilterDashboardAccessGrantsQuerySearchParameters, 'textFilter'>({
    label: 'Room #',
    queryField: 'textFilter',
    transform: (key: string) =>
      transformAccessGrantStringFilter(DashboardTextFilterType.RoomNumber, key),
  }),
];

export default ACCESS_GRANTS_SEARCH_FIELDS;
