import {
  MutationUpdateRequirementsArgs,
  UpdateRequirementsMutation,
} from '../__generated__/graphql';
import { ActionResult } from '../components/QuickActionBar/@types/QuickActionBarTypes';
import { UPDATE_REQUIREMENTS } from '../graphql/updateRequirements';
import { useCallback } from 'react';
import { useMutation } from '@apollo/client';

interface UseUpdateRequirement {
  updateRequirements: (args: MutationUpdateRequirementsArgs) => Promise<ActionResult>;
}

export function useUpdateRequirement(): UseUpdateRequirement {
  const [updateRequirementsMutation] = useMutation<
    UpdateRequirementsMutation,
    MutationUpdateRequirementsArgs
  >(UPDATE_REQUIREMENTS);

  const updateRequirements = useCallback(
    async ({
      accessGrantId,
      kioskId,
      requirementArgsList,
    }: MutationUpdateRequirementsArgs): Promise<ActionResult> => {
      try {
        const response = await updateRequirementsMutation({
          variables: {
            accessGrantId,
            kioskId,
            requirementArgsList,
          },
        });

        return {
          success: !!response.data?.updateRequirements.success,
        };
      } catch (error) {
        return {
          success: false,
          errorMessage: 'There was an error updating the requirements',
        };
      }
    },
    []
  );

  return {
    updateRequirements,
  };
}
