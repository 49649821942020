import { gql } from '../__generated__';

export const LOAD_KIOSK_SESSION = gql(`
  mutation LoadKioskSession(
    $accessGrantId: String!
    $kioskId: String!
  ) {
    loadKioskSession(
      accessGrantId: $accessGrantId
      kioskId: $kioskId
    ) {
      success
    }
  }
`);
