import {
  LocationUpdateInput,
  Mutation,
  MutationUpdateLocationArgs,
} from '../__generated__/graphql';

import GET_LOCATION from '../graphql/getLocation';
import GET_LOCATION_PAGE_INFO from '../pages/Location/graphql/getLocationPageInfo';
import LocationFormInputs from '../components/LocationForm/@types/LocationFormInputs';
import MutationHookData from './@types/MutationHookData';
import MutationHookParams from './@types/MutationHookParams';
import UPDATE_LOCATION from '../graphql/updateLocation';
import { closeForm } from '../redux/actions/modals.actions';
import { flashApolloError } from '../util/errorUtils';
import generateEmailWithDisplayName from '../util/generators/generateEmailWithDisplayName';
import links from '../util/links';
import pick from 'lodash/pick';
import transformCreateInputIntoUpdateInput from '../util/api/transformCreateInputIntoUpdateInput';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';

type UpdateLocationData = Pick<Mutation, 'updateLocation'>;

export default function useUpdateLocation({
  onCompleted = (): void => {},
  onError = flashApolloError,
}: MutationHookParams<UpdateLocationData> = {}): MutationHookData<
  LocationFormInputs,
  UpdateLocationData
> {
  const dispatch = useDispatch();

  const [update, { data, error, loading }] = useMutation<
    UpdateLocationData,
    MutationUpdateLocationArgs
  >(UPDATE_LOCATION, {
    onCompleted: ({ updateLocation }) => {
      dispatch(closeForm());

      if (updateLocation) {
        window.flash({
          link: `${links.paths.LOCATIONS}/${updateLocation.id}`,
          message: `Location: ${updateLocation.name} updated successfully`,
        });
      } else {
        window.flash({ message: 'Fail to update location' });
      }

      onCompleted({ updateLocation });
    },
    onError,
  });

  return {
    data,
    error,
    loading,
    mutation: (inputs: LocationFormInputs, id = ''): void => {
      const defaultRequirementsToCreate = inputs.defaultRequirements.values
        .filter((currentReq) => {
          return (
            currentReq.type &&
            !inputs.defaultRequirements.existing.find((existingReq) => {
              return existingReq.type === currentReq.type;
            })
          );
        })
        .map((currentReq) => ({
          metadata: currentReq.metadata,
          type: currentReq.type,
        }));

      const defaultRequirementsToUpdate = inputs.defaultRequirements.values
        .filter((currentReq) =>
          inputs.defaultRequirements.existing
            .map((existingReq) => existingReq.type)
            .includes(currentReq.type)
        )
        .map((currentReq) => {
          return {
            ...inputs.defaultRequirements.existing.find(
              (existingReq) => existingReq.type === currentReq.type
            ),
            ...currentReq,
          };
        })
        .filter((currentReq) => currentReq.requirementId)
        .map((currentReq) => ({
          data: {
            metadata: currentReq.metadata,
            type: { set: currentReq.type },
          },
          where: {
            id: currentReq.requirementId!,
          },
        }));

      const defaultRequirementsToDelete = inputs.defaultRequirements.existing
        .filter((existingReq) => {
          return !inputs.defaultRequirements.values.find((currentReq) => {
            return currentReq && currentReq.type === existingReq.type;
          });
        })
        .map((req) => ({ type_locationId: { locationId: id, type: req.type } }));

      const updateInputs: LocationUpdateInput = {
        ...transformCreateInputIntoUpdateInput<LocationUpdateInput>({
          ...pick(inputs, [
            'address',
            'defaultEndTime',
            'defaultStartTime',
            'email',
            'logoUrl',
            'imageUrl',
            'name',
            'phoneNumber',
          ]),
        }),
        defaultRequirements: {
          create: defaultRequirementsToCreate,
          delete: defaultRequirementsToDelete,
          update: defaultRequirementsToUpdate,
        },
        emailFrom: {
          set: generateEmailWithDisplayName(inputs.emailFromEmail, inputs.emailFromName),
        },
      };
      update({
        refetchQueries: [
          {
            query: GET_LOCATION_PAGE_INFO,
            variables: { where: { id: { equals: id } } },
          },
          {
            query: GET_LOCATION,
            variables: { where: { id } },
          },
        ],
        variables: {
          data: updateInputs,
          where: { id },
        },
      });
    },
  };
}
