import { DashboardTextFilter, DashboardTextFilterType } from '../../../__generated__/graphql';

export function transformAccessGrantStringFilter(
  key: DashboardTextFilterType,
  value: string
): DashboardTextFilter | undefined {
  if (!value.length) {
    return undefined;
  }

  return {
    type: key,
    value,
  };
}
