import React, { useMemo } from 'react';

import { KioskStatusContainer } from '../SelectKiosk.style';
import KiosksLiveUsageReduxState from '../../../../../../../../../redux/reducers/kiosksLiveUsage.reducer/@types/KiosksLiveUsageReduxState';
import { RootState } from '../../../../../../../../../redux/reducers';
import { useSelector } from 'react-redux';

type KioskStatusProps = {
  id: string;
};

const KioskStatusMap = {
  Active: 'Active',
  Idle: 'Idle',
};

export const KioskStatus: React.FC<KioskStatusProps> = ({ id }) => {
  const kiosksLiveUsageState: KiosksLiveUsageReduxState = useSelector(
    (store: RootState) => store.kioskLiveUsage
  );

  const status = useMemo((): string => {
    const kiosk = kiosksLiveUsageState[id];

    if (kiosk?.data?.accessGrant) {
      return KioskStatusMap.Active;
    }

    return KioskStatusMap.Idle;
  }, []);

  const isActive = status === KioskStatusMap.Active;

  return (
    <td>
      <KioskStatusContainer isActive={isActive}>{status}</KioskStatusContainer>
    </td>
  );
};
