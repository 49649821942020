/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Theme from '../../../../../../../theme';
import styled from 'styled-components';

export const TabsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  width: 455px;
`;

export const TabSelector = styled.button<{ active: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;
  width: 220px;
  border: none;
  border-radius: 12px;
  background-color: ${({ active }) => {
    return active ? Theme.colors.bgBlackTint.hexCode : Theme.colors.white.hexCode;
  }};
  color: ${({ active }) => {
    return active ? Theme.colors.white.hexCode : Theme.colors.bgBlackTint.hexCode;
  }};
  font-size: 16px;
  font-weight: 600;
`;

export const Separator = styled.hr`
  border: none;
  border-top: 1px solid ${Theme.colors.alabaster.hexCode};
  margin: 20px 0;
  width: 100%;
`;

export const RemoteKeyDispensingContainer = styled.div`
  background-color: ${Theme.colors.white.hexCode};
  border-radius: 12px;
  margin-top: 20px;
  padding: 20px;
`;

export const StepsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
`;

export const KeyDispensingStepsContainer = styled.div`
  display: grid;
  gap: 15px;

  > img {
    width: 400px;
    height: 160px;
  }
`;

export const TitleContainer = styled.div`
  display: grid;
  grid-template-columns: 42px 1fr;
  gap: 20px;
`;

export const StepNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Theme.colors.ghostWhite.hexCode};
  color: #000;
  font-size: 24px;
  font-weight: 700;
  height: 42px;
  width: 42px;
  border-radius: 8px;
`;

export const StepTitle = styled.span`
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
`;

export const KeyDispensingConfirmationMessageConttainer = styled.div`
  display: flex;
  font-size: 18px;
  font-weight: 400;
  height: 90px;
  flex-direction: column;
  justify-content: center;

  & > :first-child {
    & > span {
      font-weight: bold;
    }
  }

  & > :nth-child(2) {
    color: #dc143c;
  }
`;

export const RequirementConfirmationContainer = styled.div`
  font-size: 18px;
  font-weight: 400;

  & > ul {
    margin-left: 25px;

    & > li {
      & > span {
        font-weight: bold;
      }

      & > :nth-child(2) {
        color: ${Theme.colors.quickActionButton.hexCode};
      }
    }
  }
`;
