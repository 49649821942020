import { Container, Text, TimeContainer } from '../styles/GracePeriodTime.styles';
import { AccessSetupGracePeriodModal } from './GracePeriodModal';
import { Button } from './Button';
import { FeatureWrapper } from '../styles/Settings.styles';
import React from 'react';
import { openForm } from '../../../redux/actions/modals.actions';
import { useDispatch } from 'react-redux';

interface GracePeriodProps {
  enabled?: boolean;
  time?: string;
}

export const GracePeriodTime: React.FC<GracePeriodProps> = ({
  time,
  enabled,
}: GracePeriodProps) => {
  const dispatch = useDispatch();

  const handleOnClick = (): void => {
    const id = AccessSetupGracePeriodModal;
    dispatch(openForm(AccessSetupGracePeriodModal, id, {}));
  };

  return (
    <FeatureWrapper enabled={enabled}>
      <Container>
        <TimeContainer>
          <Text>{time}</Text>
        </TimeContainer>
        <Text>Before Check-in</Text>
        <Button.Edit onClick={handleOnClick} />
      </Container>
    </FeatureWrapper>
  );
};
