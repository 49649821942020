import { DataTableV2Props } from '../@types';

/** The `noResults` message will be displayed under the following circunstances:
 ** there is no data, AND
 ** it is not loading */
export function noResults<T>({
  data,
  isLoading,
}: Pick<DataTableV2Props<T>, 'isLoading' | 'data'>): boolean {
  return !data?.length && !isLoading;
}
