import { Container, Input, RemoveButton, Tag } from '../styles/InputTags.styles';
import React, { ChangeEvent, KeyboardEvent, useRef, useState } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { VipOrLoyaltyOptions } from './VipOrLoyaltyOptionsModal';

type InputTagsProps = {
  initialTags?: string[];
  onChange?: (tags: string[]) => void;
  form: UseFormMethods<VipOrLoyaltyOptions>;
};

export const InputTags: React.FC<InputTagsProps> = ({ initialTags = [], onChange, form }) => {
  const [tags, setTags] = useState<string[]>(initialTags);
  const [inputValue, setInputValue] = useState<string>('');
  const containerRef = useRef<HTMLDivElement>(null);

  const addTag = (tag: string): void => {
    const trimmedTag = tag.trim();

    if (trimmedTag && !tags.includes(trimmedTag)) {
      const newTags = [...tags, trimmedTag];
      setTags(newTags);
      onChange?.(newTags);
    }

    setInputValue('');
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter' || e.key === ',' || e.key === ' ' || e.key === ';') {
      e.preventDefault();
      addTag(inputValue);
    }

    // Backspace to remove the last tag if input is empty
    if (e.key === 'Backspace' && !inputValue && tags.length > 0) {
      const newTags = tags.slice(0, -1);
      setTags(newTags);
      onChange?.(newTags);
    }
  };

  const removeTag = (indexToRemove: number): void => {
    const newTags = tags.filter((_, index) => index !== indexToRemove);
    setTags(newTags);
    onChange?.(newTags);
  };

  const handleContainerClick = (): void => {
    containerRef.current?.querySelector('input')?.focus();
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setInputValue(e.target.value);
  };

  return (
    <Container
      ref={containerRef}
      onClick={handleContainerClick}
      error={form.errors.loyaltyCodes?.message}
    >
      {tags.map((tag, index) => (
        <Tag key={index}>
          {tag}
          <RemoveButton type="button" onClick={(): void => removeTag(index)}>
            &times;
          </RemoveButton>
        </Tag>
      ))}

      <Input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder="Type and press enter"
      />
      <input
        type="text"
        name="loyaltyCodes"
        ref={form.register}
        value={`${tags.join()}${tags.length > 0 ? ', ' : ''}${inputValue}`}
        hidden
      />
    </Container>
  );
};
