import {
  AccessSetupCheckInAndCheckOutTimeModal,
  CheckInAndCheckOutTimeModal,
  CheckInAndCheckoutTime,
} from './CheckInAndCheckOutTimeModal';
import {
  Container,
  ControlTitle,
  TextGrid,
  Time,
  Title,
} from '../styles/StandardCheckinAndCheckoutTime.styles';
import { LocationQuery, LocationQueryVariables } from '../../../__generated__/graphql';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from './Button';
import { GET_LOCATION } from '../../../graphql/queries';
import { RootState } from '../../../redux/reducers';
import convert24To12HoursFormat from '../../GracePeriodsTab/util/convert24To12HoursFormat';
import { flashApolloError } from '../../../util/errorUtils';
import { openForm } from '../../../redux/actions/modals.actions';
import { useLazyQuery } from '@apollo/client';

interface StandardCheckinAndCheckoutTimeProps {
  locationId?: string;
}
export const StandardCheckinAndCheckoutTime: React.FC<StandardCheckinAndCheckoutTimeProps> = ({
  locationId,
}) => {
  const dispatch = useDispatch();
  const modalState = useSelector((state: RootState) => state.modals);
  const [checkInAndCheckoutTime, setCheckInAndCheckoutTime] = useState<
    CheckInAndCheckoutTime | undefined
  >();

  const [getLocation, { data }] = useLazyQuery<LocationQuery, LocationQueryVariables>(
    GET_LOCATION,
    {
      fetchPolicy: 'network-only',
      onError: flashApolloError,
    }
  );

  const getLocationDataIfNeeded = useCallback(() => {
    if (locationId) {
      getLocation({
        variables: {
          where: {
            id: locationId,
          },
        },
      });
    }
  }, [locationId, getLocation]);

  useEffect(() => {
    getLocationDataIfNeeded();
  }, [getLocationDataIfNeeded]);

  const inputValues = useMemo(() => {
    if (data?.location) {
      const { defaultEndTime, defaultStartTime } = data.location;
      const checkIn = convert24To12HoursFormat(defaultStartTime);
      const checkOut = convert24To12HoursFormat(defaultEndTime);

      return {
        checkIn: checkInAndCheckoutTime?.checkInTime ?? `${checkIn.time} ${checkIn.period}`,
        checkOut: checkInAndCheckoutTime?.checkOutTime ?? `${checkOut.time} ${checkOut.period}`,
      };
    }

    return undefined;
  }, [data, checkInAndCheckoutTime]);

  const handleOnClick = (): void => {
    const id = AccessSetupCheckInAndCheckOutTimeModal;
    dispatch(openForm(AccessSetupCheckInAndCheckOutTimeModal, id, {}));
  };

  const modalCallback = ({ checkInTime, checkOutTime }: CheckInAndCheckoutTime): void => {
    setCheckInAndCheckoutTime({ checkInTime, checkOutTime });
  };

  return (
    <>
      <ControlTitle>Standard Check-in & Check-out Time</ControlTitle>
      <Container>
        <TextGrid>
          <Title>Standard Check-in Time</Title>
          <Time>{inputValues?.checkIn}</Time>
        </TextGrid>
        <TextGrid>
          <Title>Standard Check-out Time</Title>
          <Time>{inputValues?.checkOut}</Time>
        </TextGrid>
        <Button.Edit onClick={handleOnClick} />
      </Container>
      {modalState.form.name === AccessSetupCheckInAndCheckOutTimeModal && (
        <CheckInAndCheckOutTimeModal
          setCheckInAndCheckOutTimeCallback={modalCallback}
          locationId={locationId}
          checkInTime={inputValues?.checkIn ?? ''}
          checkOutTime={inputValues?.checkOut ?? ''}
        />
      )}
    </>
  );
};
