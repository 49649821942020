import { GetKiosksAndCountQuery, GetKiosksAndCountQueryVariables } from '../__generated__/graphql';
import {
  LazyQueryHookData,
  LazyQueryHookParams,
  LazyQueryHookType,
  defaultLazyQueryProps,
} from './@types/LazyQueryHookData';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Datum } from './@types/QueryHookData';
import { GET_KIOSKS_AND_COUNT } from '../graphql/getKiosksAndCount';
import { RootState } from '../redux/reducers';
import { flashApolloError } from '../util/errorUtils';
import generateMembershipQuery from '../util/api/generateMembershipQuery';
import { getKioskOfflineMS } from '../util/kiosk/isKioskOnline';
import { merge } from 'lodash';
import { useLazyQuery } from '@apollo/client';
import { useSelector } from 'react-redux';

type GetKiosksQueryData = GetKiosksAndCountQuery;
type GetKiosksQueryArgs = GetKiosksAndCountQueryVariables;

type UseGetKiosksOnlineByLocationProps = LazyQueryHookParams & { locationId?: string };
type UseGetKiosksOnlineByLocationType = LazyQueryHookType<Datum>;

export default function useGetKiosksOnlineByLocation(
  props: UseGetKiosksOnlineByLocationProps
): UseGetKiosksOnlineByLocationType {
  const membership = useSelector((state: RootState) => state.me.activeMembership);

  const { take, locationId } = merge(defaultLazyQueryProps, props);

  const skip = useRef<number>(0);

  const [kiosksData, setKiosksData] = useState<LazyQueryHookData<Datum>>(undefined);
  const [total, setTotal] = useState<number | undefined>(undefined);

  const dateToConsiderKioskOnline = new Date(getKioskOfflineMS());

  const [getKiosksAndCount, { error, loading }] = useLazyQuery<
    GetKiosksQueryData,
    GetKiosksQueryArgs
  >(GET_KIOSKS_AND_COUNT, {
    fetchPolicy: 'no-cache',
    onError: flashApolloError,
    onCompleted: (data: GetKiosksAndCountQuery): void => {
      setTotal(data.aggregateKiosk.count?.all ?? 0);

      if (data?.kiosks) {
        const newKiosks = [...(kiosksData ?? []), ...data.kiosks];

        setKiosksData(newKiosks);

        skip.current += data.kiosks.length;
      }
    },
  });

  const kiosks = useMemo(() => {
    return kiosksData?.map((kiosk) => ({ id: kiosk.id, name: kiosk.name }));
  }, [kiosksData]);

  const hasMoreData = useMemo((): boolean => {
    const kiosksLoaded = kiosks?.length ?? 0;

    return !total || kiosksLoaded < total;
  }, [total, kiosks]);

  const query = useCallback(() => {
    if (hasMoreData) {
      getKiosksAndCount({
        variables: {
          take,
          skip: skip.current,
          where: {
            ...generateMembershipQuery(membership),
            locationId: { equals: locationId },
            lastSeenAt: { gte: dateToConsiderKioskOnline },
          },
        },
      });
    }
  }, [hasMoreData, getKiosksAndCount, skip, take]);

  useEffect(() => {
    query();
  }, [query]);

  return {
    data: kiosks,
    error,
    loading,
    query,
    hasMoreData,
  };
}
