import DataTable from '../../../components/DataTable';
import { LocationPageInfo } from '../@types/GetLocationPageInfo';
import React from 'react';
import useAccessGrantsTableData from '../../../hooks/useAccessGrantsTableData';
import { useParams } from 'react-router-dom';

interface AccessGrantTabProps {
  location: LocationPageInfo;
}

const AccessGrantsTab: React.FC<AccessGrantTabProps> = ({ location }: AccessGrantTabProps) => {
  const { locationId } = useParams<{ locationId: string }>();

  const tableData = useAccessGrantsTableData({
    dataTableKey: 'accessGrants-Location',
    isImportEnabled: location.supportsPolling,
    queryOptions: {
      locationId: { in: [locationId] },
    },
    relatedLocation: location,
  });

  return <DataTable {...tableData} />;
};

export default AccessGrantsTab;
