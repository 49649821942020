import AccessSetupModalCloseAction, {
  AccessSetupModalCloseActionPayload,
} from './@types/CloseAccessSetupModalAction';
import {
  CloseAccessSetupConfirmationModalAction,
  OpenAccessSetupConfirmationModalAction,
} from './accessSetup.actions';
import {
  CloseActionProcessingModalAction,
  OpenActionProcessingModalAction,
} from './actionProcessing.actions';
import ModalOpenConfirmationAction, {
  ModalOpenConfirmationActionPayload,
} from './@types/ModalOpenConfirmationAction';
import ModalOpenRemoveAction, {
  ModalOpenRemoveActionPayload,
} from './@types/ModalOpenRemoveAction';
import ModalsReduxState, {
  AccessSetupPayload,
  ActionProcessingPayload,
  QuickActionConfirmationPayload,
  QuickActionProcessingPayload,
} from '../reducers/@types/ModalsReduxState';
import ModalActionEnum from '../../enums/ModalAction.enum';
import ModalCloseAction from './@types/ModalCloseAction';
import ModalCloseAllAction from './@types/ModalCloseAllAction';
import ModalCloseConfirmationAction from './@types/ModalCloseConfirmationAction';
import ModalCloseFormAction from './@types/ModalCloseFormAction';
import ModalCloseRemoveAction from './@types/ModalCloseRemoveAction';
import ModalOpenAction from './@types/ModalOpenAction';
import ModalOpenAllAction from './@types/ModalOpenAllAction';
import ModalOpenFormAction from './@types/ModalOpenFormAction';
import ModalOpenQuickActionConfirmationAction from './@types/ModalOpenQuickActionConfirmationAction';
import ModalOpenQuickActionProcessingAction from './@types/ModalOpenQuickActionProcessingAction';
import QuickActionModalCloseAction from './@types/CloseQuickActionModalAction';

export const closeModal = (identifier: keyof ModalsReduxState): ModalCloseAction => ({
  payload: {
    modalKey: identifier,
  },
  type: ModalActionEnum.CLOSE_MODAL,
});

export const closeForm = (): ModalCloseFormAction => ({
  type: ModalActionEnum.CLOSE_FORM,
});

export const closeAccessSetupSettingsConfirmationModal = (
  payload: AccessSetupModalCloseActionPayload
): AccessSetupModalCloseAction => ({
  payload,
  type: ModalActionEnum.CLOSE_ACCESS_SETUP_SETTINGS_MODAL,
});

export const closeRemoveModal = (): ModalCloseRemoveAction => ({
  type: ModalActionEnum.CLOSE_REMOVE_MODAL,
});

export const closeModals = (
  identifiers: Array<keyof ModalsReduxState> = []
): ModalCloseAllAction => ({
  payload: {
    modalKeys: identifiers,
  },
  type: ModalActionEnum.CLOSE_ALL,
});

export const openForm = (
  name: string,
  id?: string,
  aditionalDataOnOpeningForm?: unknown
): ModalOpenFormAction => ({
  payload: {
    aditionalDataOnOpeningForm,
    name,
    ...(id && { id }),
  },
  type: ModalActionEnum.OPEN_FORM,
});

export const openAccessSetupConfirmationModal = (
  payload: AccessSetupPayload
): OpenAccessSetupConfirmationModalAction => ({
  payload,
  type: ModalActionEnum.OPEN_ACCESS_SETUP_CONFIRMATION_MODAL,
});

export const closeAccessSetupConfirmationModal = (): CloseAccessSetupConfirmationModalAction => ({
  type: ModalActionEnum.CLOSE_ACCESS_SETUP_CONFIRMATION_MODAL,
});

export const openModal = (identifier: keyof ModalsReduxState): ModalOpenAction => ({
  payload: {
    modalKey: identifier,
  },
  type: ModalActionEnum.OPEN_MODAL,
});

export const openModals = (identifiers: Array<keyof ModalsReduxState>): ModalOpenAllAction => ({
  payload: {
    modalKeys: identifiers,
  },
  type: ModalActionEnum.OPEN_ALL,
});

export const openRemoveModal = (payload: ModalOpenRemoveActionPayload): ModalOpenRemoveAction => ({
  payload,
  type: ModalActionEnum.OPEN_REMOVE_MODAL,
});

export const openConfirmationModal = (
  payload: ModalOpenConfirmationActionPayload
): ModalOpenConfirmationAction => ({
  payload,
  type: ModalActionEnum.OPEN_CONFIRMATION_MODAL,
});

export const closeConfirmationModal = (): ModalCloseConfirmationAction => ({
  type: ModalActionEnum.CLOSE_CONFIRMATION_MODAL,
});

export const openQuickActionConfirmationModal = (
  payload: QuickActionConfirmationPayload
): ModalOpenQuickActionConfirmationAction => ({
  payload,
  type: ModalActionEnum.OPEN_QUICK_ACTION_CONFIRMATION_MODAL,
});

export const openQuickActionProcessingModal = (
  payload: QuickActionProcessingPayload
): ModalOpenQuickActionProcessingAction => ({
  payload,
  type: ModalActionEnum.OPEN_QUICK_ACTION_PROCESSING_MODAL,
});

export const closeQuickActionModal = (
  identifier: keyof Pick<
    ModalsReduxState,
    'quickActionConfirmationModal' | 'quickActionProcessingModal'
  >
): QuickActionModalCloseAction => ({
  payload: {
    modalKey: identifier,
  },
  type: ModalActionEnum.CLOSE_QUICK_ACTION_MODAL,
});

export const openActionProcessingModal = (
  payload: ActionProcessingPayload
): OpenActionProcessingModalAction => ({
  payload,
  type: ModalActionEnum.OPEN_ACTION_PROCESSING_MODAL,
});

export const closeActionProcessingModal = (): CloseActionProcessingModalAction => ({
  type: ModalActionEnum.CLOSE_ACTION_PROCESSING_MODAL,
});
