import React from 'react';
import { RequirementConfirmationContainer } from './styles/KioskLiveUsageQuickActionsModal.styles';
import { useQuickActionConfirmationModalContext } from '../contexts/QuickActionConfirmationModalContext';

export const RequirementOverrideConfirmationMessage: React.FC = () => {
  const { modalData } = useQuickActionConfirmationModalContext();

  return (
    <RequirementConfirmationContainer>
      <p>Are you sure you want to do the following guest requirement override(s)?</p>
      <ul>
        {modalData?.newRequirements?.map(({ newValue, requirement }) => (
          <li key={requirement}>
            <span>{newValue}</span> the <span>{requirement}</span>
          </li>
        ))}
      </ul>
    </RequirementConfirmationContainer>
  );
};
