import Theme from '../../../../theme';
import styled from 'styled-components';

export const SearchBarContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`;

export const SearchBarFieldContainer = styled.div`
  display: flex;
  flex: 1 0 0;
  flex-direction: row;
  align-items: center;
  padding: 0px 8px 0px 16px;
  gap: 10px;

  background: ${Theme.colors.white.hexCode};
  border-radius: 12px;
  border: 2px solid ${Theme.colors.lighGray.hexCode};

  :focus-within {
    border: 2px solid ${Theme.colors.quickActionButton.hexCode};
    outline: none;
  }
`;

export const SearchBarField = styled.input.attrs({ type: 'text' })`
  border: none;

  display: flex;
  flex: 1;
  height: 52px;

  font-size: 18px;
  color: ${Theme.colors.black.hexCode};
  outline: none;
`;

export const ClearSearchButton = styled.button`
  background: transparent;
  border: none;
`;

const BasicButton = styled.button`
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
  border: 0;

  justify-content: center;
  align-items: center;
`;

export const SearchButton = styled(BasicButton)`
  display: flex;
  height: 36px;
  padding: 10px 24px 10px 18px;
  gap: 8px;

  border-radius: 10px;
  background: ${Theme.colors.quickActionButton.hexCode};
  color: ${Theme.colors.white.hexCode};
`;

export const SearchOptionsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const SearchOption = styled(BasicButton)<{ isSelected: boolean }>`
  display: flex;
  height: 52px;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;

  border-radius: 12px;
  background: ${({ isSelected }): string => {
    return isSelected ? Theme.colors.black.hexCode : Theme.colors.white.hexCode;
  }};
  color: ${({ isSelected }): string => {
    return isSelected ? Theme.colors.white.hexCode : Theme.colors.black.hexCode;
  }};
`;
