import { ActionBarConfirmationModalReservationIcon } from '../../../../../../assets/icons/actionBarConfirmationModalReservationIcon';
import { ActionBarConfirmationModalUserIcon } from '../../../../../../assets/icons/actionBarConfirmationModalUserIcon';
import BaseQuickActionModal from '../../../../../../components/BaseQuickActionModal';
import { BaseQuickActionModalHeaderValues } from '../../../../../../components/BaseQuickActionModal/@types';
import { KioskLiveUsageModalHeaderIcon } from '../../../../../../assets/icons/kioskLiveUsageModalHeaderIcon';
import ModalInfoBox from '../../../../../../components/BaseQuickActionModal/ModalInfoBox';
import React from 'react';
import { Separator } from './styles/KioskLiveUsageQuickActionsModal.styles';
import TabbedMenu from './TabbedMenu';
import Theme from '../../../../../../theme';
import links from '../../../../../../util/links';
import { useKioskLiveUsageContext } from '../contexts/KioskLiveUsageContext';

export const KioskLiveUsageQuickActionsModal: React.FC = () => {
  const { closeQuickActionsModal, quickActionsLiveUsageData: data } = useKioskLiveUsageContext();

  const itemsList: BaseQuickActionModalHeaderValues = [
    {
      item: 'Kiosk Name',
      value: data?.kiosk.name || '',
      icon: <KioskLiveUsageModalHeaderIcon color={Theme.colors.bgBlackTint.hexCode} />,
    },
    {
      item: 'Guest Name',
      value: data?.accessGrant?.user.name || '',
      icon: <ActionBarConfirmationModalUserIcon />,
    },
    {
      item: 'Reservation Number',
      value: data?.accessGrant?.confirmationCode || '',
      icon: <ActionBarConfirmationModalReservationIcon />,
    },
    {
      item: '',
      value: '',
      icon: <ActionBarConfirmationModalReservationIcon />,
      link: {
        text: (
          <>
            <p>Open Reservation</p>
            <p>Access Grant</p>
          </>
        ),
        url: `${links.paths.ACCESS_GRANTS}/${data?.accessGrant?.id}`,
      },
    },
  ];

  return (
    <BaseQuickActionModal
      header={{
        title: 'Kiosk Live Actions',
        icon: <KioskLiveUsageModalHeaderIcon color={Theme.colors.quickActionButton.hexCode} />,
      }}
      closeModalAction={closeQuickActionsModal}
      styles={{
        width: '920px',
      }}
    >
      <ModalInfoBox itemsList={itemsList} itemsPerRow={4} />
      <Separator />
      <TabbedMenu />
    </BaseQuickActionModal>
  );
};
