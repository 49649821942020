import { gql } from '../__generated__';

const CREATE_EARLY_CHECKIN_PACKAGE = gql(`
  mutation createEarlyCheckInPackage(
    $data:EarlyCheckInPackageInput!
  ) {
    createEarlyCheckInPackage(data: $data) {
      endTime,
      externalId,
      freeUpgrade,
      locationId,
      loyaltyCodes,
      price,
      serviceId,
      startTime
    }
  }
`);

export default CREATE_EARLY_CHECKIN_PACKAGE;
