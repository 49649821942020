import { AccessGrantStatus, SortOrder } from '../../../__generated__/graphql';
import Heading, { HeadingModel } from '../../../components/Heading';

import DataTable from '../../../components/DataTable';
import { EntityEnum } from '../../../@types/client/DashboardUserRolePermissions';
import React from 'react';
import moment from 'moment';
import useAccessGrantsTableData from '../../../hooks/useAccessGrantsTableData';

const IncomingStaysTable: React.FC<{}> = () => {
  const tableData = useAccessGrantsTableData({
    dataTableKey: 'accessGrants-IncomingStays',
    fetchPolicy: 'no-cache',
    permission: EntityEnum.NONE,
    queryOptions: {
      orderBy: [
        {
          startsAt: SortOrder.Asc,
        },
      ],
      timeFilter: {
        startsAt: {
          from: moment().startOf('day').toDate(),
          to: moment().add(3, 'days').endOf('day').toDate(),
        },
      },
      status: { notIn: [AccessGrantStatus.Canceled] },
    },
  });

  return (
    <>
      <Heading margin="35px 0 20px" model={HeadingModel.SECONDARY}>
        Incoming Stays (72H)
      </Heading>
      <DataTable {...tableData} />
    </>
  );
};

export default IncomingStaysTable;
