/* eslint-disable @typescript-eslint/explicit-function-return-type */
import styled, { css } from 'styled-components';
import Theme from '../../../../../../../theme';

export const KeyOverrideContainer = styled.div`
  display: grid;
  gap: 15px;
  margin-top: 20px;
`;

export const RequirementItem = styled.div`
  display: grid;
  grid-template-columns: 270px 1fr;
  height: 100px;
  border-radius: 12px;
  background-color ${Theme.colors.white.hexCode};
  padding: 0 20px;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 235px;
`;

export const RequirementTextContainer = styled.div<{
  headerColor: string;
  subHeaderColor?: string;
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: 18px;
  font-weight: 700;
  color: ${({ headerColor }) => headerColor};
  gap: 2px;

  & > :nth-child(2) {
    font-size: 16px;
    font-weight: 600;
    ${({ subHeaderColor }) =>
      subHeaderColor &&
      css`
        color: ${subHeaderColor};
      `}
  }
`;
