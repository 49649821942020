import React, { useMemo } from 'react';

export const Plus: React.FC<{
  color?: string;
}> = ({ color }) => {
  const fillColor = useMemo(() => color || '#fff', [color]);

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.8 3.2C8.8 2.53726 9.33726 2 10 2C10.6627 2 11.2 2.53726 11.2 3.2V8.8H16.8C17.4627 8.8 18 9.33726 18 10C18 10.6627 17.4627 11.2 16.8 11.2H11.2V16.8C11.2 17.4627 10.6627 18 10 18C9.33726 18 8.8 17.4627 8.8 16.8V11.2H3.2C2.53726 11.2 2 10.6627 2 10C2 9.33726 2.53726 8.8 3.2 8.8H8.8V3.2Z"
        fill={fillColor}
      />
    </svg>
  );
};
