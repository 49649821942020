import React, { useMemo } from 'react';

export const CloseIcon: React.FC<{
  color?: string;
}> = ({ color }) => {
  const fillColor = useMemo(() => color || '#fff', [color]);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M5.31682 18.6768C4.88724 18.2559 4.90443 17.5258 5.30823 17.1221L10.4288 11.9941L5.30823 6.87471C4.90443 6.471 4.89584 5.74947 5.31682 5.32C5.73781 4.89052 6.46809 4.90769 6.88049 5.31141L12.0011 10.4308L17.1131 5.31141C17.5341 4.89052 18.2472 4.89911 18.6767 5.32C19.1063 5.74947 19.1063 6.46241 18.6853 6.8833L13.5733 11.9941L18.6853 17.1135C19.1063 17.5344 19.0977 18.2473 18.6767 18.6768C18.2557 19.1063 17.5341 19.1063 17.1131 18.6854L12.0011 13.566L6.88049 18.6854C6.46809 19.0891 5.7464 19.1063 5.31682 18.6768Z"
        fill={fillColor}
      />
    </svg>
  );
};
