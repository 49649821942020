import { ActionResult } from '../../../../../../../components/QuickActionBar/@types/QuickActionBarTypes';
import { RootState } from '../../../../../../../redux/reducers';
import { channelsClient } from '../../../../../../../util/pusher';
import { useSelector } from 'react-redux';

const { REACT_APP_VIRDEE_ENV = 'local' } = process.env;

type LoadReservationFeedbackEventType = {
  success: boolean;
  errorCode?: string;
};

type UseLoadReservationOnKioskFeedbackListenerProps = {
  tenantId: string;
  kioskId: string;
  accessGrantId: string;
};
type UseLoadReservationOnKioskFeedbackListenerType = {
  loadReservationOnKioskFeedbackListener: (
    props: UseLoadReservationOnKioskFeedbackListenerProps
  ) => Promise<ActionResult>;
};

/** in seconds */
const timeout = 30;

enum QRAuthErrorCode {
  FUTURE_ACCESS_GRANT = '1',
  PAST_ACCESS_GRANT = '2',
}

export const FailureMessages = {
  FutureAccessGrant:
    'This reservation cannot be loaded on the kiosk for check-in. The check-in date is not today.',
  PastAccessGrant:
    'This reservation cannot be loaded on the kiosk for check-in. The reservation has already ended.',
  UnknownError:
    'The reservation was not loaded on the kiosk. An unknown error occurred. Please try again.',
  TimeOut: 'The reservation was not loaded on the kiosk. The request timed out. Please try again.',
} as const;

// eslint-disable-next-line max-len
export function useLoadReservationOnKioskFeedbackListener(): UseLoadReservationOnKioskFeedbackListenerType {
  const { tenantId: activeMembershipTenantId } = useSelector(
    (store: RootState) => store.me.activeMembership
  );

  const failureReasonPerErrorCode = new Map<string, string>([
    [QRAuthErrorCode.FUTURE_ACCESS_GRANT, FailureMessages.FutureAccessGrant],
    [QRAuthErrorCode.PAST_ACCESS_GRANT, FailureMessages.PastAccessGrant],
  ]);

  /** Listener to wait for the event from the Kiosk notifying the
   * Load Reservation result (success or failure) */
  const loadReservationOnKioskFeedbackListener = ({
    accessGrantId,
    kioskId,
  }: UseLoadReservationOnKioskFeedbackListenerProps): Promise<ActionResult> => {
    return new Promise<ActionResult>((resolve) => {
      const channelName = `KIOSK-${REACT_APP_VIRDEE_ENV}-${activeMembershipTenantId}-${kioskId}-${accessGrantId}`;
      const loadReservationOnKioskFeedbackChannel =
        channelsClient.channel(channelName) || channelsClient.subscribe(channelName);

      const eventName = 'LOAD_RESERVATION_ON_KIOSK_RESULT';

      // Wait for an event to notify about the Load Session result
      loadReservationOnKioskFeedbackChannel.bind(
        eventName,
        (eventData: LoadReservationFeedbackEventType) => {
          const { success, errorCode } = eventData;

          loadReservationOnKioskFeedbackChannel.unbind(eventName);

          if (success) {
            return resolve({ success });
          }

          const failureReason = errorCode ? failureReasonPerErrorCode.get(errorCode) : undefined;
          return resolve({ success, errorMessage: failureReason || FailureMessages.UnknownError });
        }
      );

      // If it doesn't receive the event in a certain period of time,
      // it gives up and timeout the request
      setTimeout(() => {
        loadReservationOnKioskFeedbackChannel.unbind(eventName);
        resolve({ success: false, errorMessage: FailureMessages.TimeOut });
      }, timeout * 1000);
    });
  };

  return {
    loadReservationOnKioskFeedbackListener,
  };
}
