import { useCallback, useState } from 'react';

type UseOnScreenIntersectionProps<T> = {
  root?: Element | null | undefined;
  rootMargin?: string | undefined;
  threshold?: number | number[] | undefined;
  entitiesList: T[] | undefined | null;
  rowsRemainingToLoadData?: number;
};

type UseOnScreenIntersectionType = {
  isIntersecting: boolean;
  observer: IntersectionObserver | null | undefined;
  elementRef: (zeroBasedIndex: number) => ((node: HTMLElement | null) => void) | null;
};

/** This hook can be used to detect when the end of a list has reached.
 * For usage, every row in the list should have the `ref` property as the `elementRef` value.
 * Once the end of the list is reached, the `isIntersecting` property will be true. */
export function useOnScreenIntersection<T>({
  entitiesList,
  root = null,
  rootMargin = '0px',
  threshold = 0,
  rowsRemainingToLoadData = 1,
}: UseOnScreenIntersectionProps<T>): UseOnScreenIntersectionType {
  const [observer, setObserver] = useState<IntersectionObserver | null>();
  const [isIntersecting, setIntersecting] = useState(false);

  const measureRef = useCallback(
    (node: HTMLElement | null) => {
      if (node) {
        const newObserver = new IntersectionObserver(
          ([entry]) => {
            setIntersecting(entry.isIntersecting);
          },
          { root, rootMargin, threshold }
        );

        newObserver.observe(node);
        setObserver(newObserver);
      }
    },
    [root, rootMargin, threshold]
  );

  const isElementFoundToLoadMoreData = useCallback(
    (zeroBasedIndex: number): boolean => {
      // The _index is zero-based, so, it needs to add +1
      const index = zeroBasedIndex + 1;

      // It should load more location when the user reaches out the -Xº location
      const isLastElement = index + rowsRemainingToLoadData === entitiesList?.length;
      return isLastElement;
    },
    [entitiesList]
  );

  const elementRef = useCallback(
    (zeroBasedIndex: number) => {
      if (isElementFoundToLoadMoreData(zeroBasedIndex)) {
        return measureRef;
      }

      return null;
    },
    [entitiesList]
  );

  return { elementRef, isIntersecting, observer };
}
