/* eslint-disable @typescript-eslint/explicit-function-return-type */
import styled, { css } from 'styled-components';
import { REQUIREMENT_SELECTOR_STATE } from '../../../constants';
import Theme from '../../../../../../../theme';

interface Props {
  state: REQUIREMENT_SELECTOR_STATE;
}

export const Wrapper = styled.div<{ itemsCount: number }>`
  height: auto;
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(${({ itemsCount }) => itemsCount}, 1fr);
  gap: 15px;
  align-items: center;
`;

export const Item = styled.div<Props>`
  display: grid;
  align-items: center;
  height: 48px;
  position: relative;
  border: 2px solid
    ${({ state }) => {
      return state === REQUIREMENT_SELECTOR_STATE.SELECTED
        ? Theme.colors.quickActionButton.hexCode
        : Theme.colors.alabaster.hexCode;
    }};
  box-sizing: border-box;
  border-radius: 8px;
  gap: 10px;
  grid-template-columns: 24px 1fr;
  padding: 0 20px;
  cursor: pointer;
  width: 277.5px;
  justify-self: end;
`;

export const StyledRadioButton = styled.div<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ state }) => {
    if (state === REQUIREMENT_SELECTOR_STATE.SELECTED) {
      return css`
        width: 21px;
        height: 21px;
      `;
    }

    return css`
      width: 19px;
      height: 19px;
      border: 2px solid ${state === REQUIREMENT_SELECTOR_STATE.DISABLED ? '#dbdbdb' : '#b2b2b2'};
      border-radius: 4px;
    `;
  }}
`;

export const Label = styled.span<Props>`
  font-size: 16px;
  font-weight: 600;
  color: ${({ state }) => {
    if (state === REQUIREMENT_SELECTOR_STATE.SELECTED) {
      return Theme.colors.quickActionButton.hexCode;
    }

    if (state === REQUIREMENT_SELECTOR_STATE.DISABLED) {
      return Theme.colors.alabaster.hexCode;
    }

    return Theme.colors.black.hexCode;
  }};
`;
