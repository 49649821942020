import React from 'react';
import Theme from '../../../theme';

export const getTableExpandableCellStyle = ({
  isOverflowing,
}: {
  isOverflowing: boolean;
}): React.CSSProperties => ({
  position: 'relative',
  paddingRight: isOverflowing ? '20px' : '0',
  background: 'transparent',
  borderBottom: `1px solid rgba(${Theme.colors.borderTable.rgb}, 0.1)`,
  padding: '15px 25px 45px 0',
});

export const getTableExpandableTextStyle = ({
  isExpanded,
}: {
  isExpanded: boolean;
}): React.CSSProperties => ({
  overflow: isExpanded ? 'visible' : 'hidden',
  whiteSpace: isExpanded ? 'normal' : 'nowrap',
  textOverflow: isExpanded ? 'clip' : 'ellipsis',
  maxWidth: isExpanded ? '100%' : 'calc(100% - 20px)',
  display: 'inline-block',
  verticalAlign: 'middle',
  color: Theme.colors.white.hexCode,
  wordBreak: 'break-word',
});

export const getTableExpandableArrowStyle = ({
  isExpanded,
  isOverflowing,
}: {
  isExpanded: boolean;
  isOverflowing: boolean;
}): React.CSSProperties => ({
  position: 'absolute',
  right: '5px',
  top: '25px',
  display: isOverflowing ? 'block' : 'none',
  transition: 'transform 0.2s ease',
  transform: isExpanded ? 'translateY(-50%) rotate(180deg)' : 'translateY(-50%) rotate(0deg)',
  verticalAlign: 'middle',
});
