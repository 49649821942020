import { gql } from '../../../__generated__/gql';

const GET_KIOSKS_LIVE_USAGE_DATA = gql(`
  query GetKiosksLiveUsageData($kioskAndAccessGrantParams: [KioskAndAccessGrantParams!]!) {
    getKiosksLiveUsageData(kioskAndAccessGrantParams: $kioskAndAccessGrantParams) {
      data {
        kiosk {
          id
          name
          location {
            id
            name
            timezone
            tenantId
          }
          dispenserType
          hasDispenser
        }
        accessGrant {
          id
          confirmationCode
          startsAt
          endsAt
          user {
            id
            name
            email
          }
          totalGuests {
            adults
            children
            total
          }
          bookedPackages {
            title
            quantity
          }
          resources {
            id
            name
            description
            pmsId
          }
          secondaryUsers {
            user {
              id
              name
              email
            }
          }
          roomNumber
          requirements {
            id
            type
            status
          }
        }
      }
    }
  }
`);

export default GET_KIOSKS_LIVE_USAGE_DATA;
