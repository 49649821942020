import React, { useMemo } from 'react';

export const DownArrowIcon: React.FC<{
  color?: string;
  size?: string;
  rotate?: number;
}> = ({ color, size = '19', rotate = 0 }) => {
  const fillColor = useMemo(() => color || '#fff', [color]);

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: `rotate(${rotate}deg)` }}
    >
      <path
        d="M4.95476 7.35889C4.95476 7.57883 5.03372 7.75366 5.18035 7.90593L9.40444 12.2315C9.59619 12.4233 9.79358 12.5135 10.0361 12.5192C10.2786 12.5192 10.4816 12.4289 10.6677 12.2315L14.8975 7.90593C15.0441 7.75366 15.123 7.57319 15.123 7.35889C15.123 6.91899 14.7734 6.56934 14.3391 6.56934C14.1248 6.56934 13.9218 6.65957 13.7639 6.81748L10.0361 10.6524L6.31392 6.81748C6.15601 6.65957 5.95298 6.56934 5.73867 6.56934C5.30442 6.56934 4.95476 6.91899 4.95476 7.35889Z"
        fill={fillColor}
      />
    </svg>
  );
};
