import {
  AccessGrantStatus,
  GetAccessGrantsAndCountQuery,
  GetAccessGrantsAndCountQueryVariables,
  QueryMode,
  ResourceType,
  SortOrder,
} from '../__generated__/graphql';
import {
  LazyQueryHookData,
  LazyQueryHookParams,
  LazyQueryHookType,
  defaultLazyQueryProps,
} from './@types/LazyQueryHookData';
import {
  SelectReservationSearchFields,
  SelectReservationSortFields,
} from '../pages/RemoteAssistance/components/KiosksLiveUsage/components/LoadReservationOnKioskModal/components/SelectReservation/types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { DataTableSearchType } from '../components/DataTableV2';
import { GET_ACCESS_GRANTS_AND_COUNT } from '../graphql/getAccessGrantsAndCount';
import { RootState } from '../redux/reducers';
import { flashApolloError } from '../util/errorUtils';
import generateMembershipQuery from '../util/api/generateMembershipQuery';
import { merge } from 'lodash';
import { useDataTableV2Context } from '../components/DataTableV2/context/DataTableV2Context';
import { useLazyQuery } from '@apollo/client';
import { useSelector } from 'react-redux';

type GetAccessGrantsQueryData = GetAccessGrantsAndCountQuery;
type GetAccessGrantsQueryArgs = GetAccessGrantsAndCountQueryVariables;

export type AccessGrantType = GetAccessGrantsQueryData['findDashboardAccessGrants'][0];

type UseLazyGetAccessGrantsByLocationProps = LazyQueryHookParams & { locationId: string };
type UseLazyGetAccessGrantsByLocationType = LazyQueryHookType<AccessGrantType>;

const initialValues = {
  skip: 0,
  accessGrantsData: undefined,
  total: undefined,
};

export default function useLazyGetAccessGrantsByLocation(
  props: UseLazyGetAccessGrantsByLocationProps
): UseLazyGetAccessGrantsByLocationType {
  const membership = useSelector((state: RootState) => state.me.activeMembership);

  const { take, locationId } = merge(defaultLazyQueryProps, props);

  const skip = useRef<number>(initialValues.skip);

  const [accessGrantsData, setAccessGrantsData] = useState<LazyQueryHookData<AccessGrantType>>(
    initialValues.accessGrantsData
  );
  const [total, setTotal] = useState<number | undefined>(initialValues.total);

  const { searchParams, setSearchParams, sortParams, setSortParams } = useDataTableV2Context();

  const [getAccessGrantsAndCount, { error, loading }] = useLazyQuery<
    GetAccessGrantsQueryData,
    GetAccessGrantsQueryArgs
  >(GET_ACCESS_GRANTS_AND_COUNT, {
    fetchPolicy: 'no-cache',
    onError: flashApolloError,
    onCompleted: (data): void => {
      setTotal(data.aggregateDashboardAccessGrants?._count?._all ?? 0);

      if (data?.findDashboardAccessGrants) {
        const newAccessGrants = data.findDashboardAccessGrants;
        const fullAccessGrants = [...(accessGrantsData ?? []), ...newAccessGrants];

        setAccessGrantsData(fullAccessGrants);

        skip.current += data.findDashboardAccessGrants.length;
      }
    },
  });

  const accessGrants = useMemo(() => {
    return accessGrantsData;
  }, [accessGrantsData]);

  /** It has more data when: there is nothing loaded yet or
   * the data loaded is less than the total */
  const hasMoreData = useMemo((): boolean => {
    const dataLoaded = accessGrants?.length ?? 0;

    return !total || dataLoaded < total;
  }, [total, accessGrants]);

  const createQueryWhereParams = useCallback((): GetAccessGrantsQueryArgs | null => {
    // Set the params based on the Search bar
    const { field, text } = searchParams ?? {};
    if (!field || !text) {
      return null;
    }

    switch (field.key) {
      case SelectReservationSearchFields.GuestName: {
        return {
          where: {
            user: {
              is: {
                name: {
                  contains: text,
                  mode: QueryMode.Insensitive,
                },
              },
            },
          },
        };
      }

      case SelectReservationSearchFields.RoomNumber: {
        return {
          where: {
            resources: {
              some: {
                type: { equals: ResourceType.Lock },
                name: {
                  contains: text,
                  mode: QueryMode.Insensitive,
                },
              },
            },
          },
        };
      }

      case SelectReservationSearchFields.ConfirmationCode: {
        return {
          where: {
            confirmationCode: {
              contains: text,
              mode: QueryMode.Insensitive,
            },
          },
        };
      }

      default:
        return null;
    }
  }, [searchParams]);

  const createQuerySortParams = useCallback((): GetAccessGrantsQueryArgs | null => {
    // The sort should be: ASC, then, DESC, then, null

    if (!sortParams) {
      return null;
    }

    const { key, order } = sortParams;

    switch (key) {
      case SelectReservationSortFields.PrimaryGuest as string: {
        return {
          orderBy: {
            user: {
              name: {
                sort: order,
              },
            },
          },
        };
      }

      case SelectReservationSortFields.CheckIn as string: {
        return {
          orderBy: {
            startsAt: order,
          },
        };
      }

      case SelectReservationSortFields.CheckOut as string: {
        return {
          orderBy: {
            endsAt: order,
          },
        };
      }

      default:
        return null;
    }
  }, [sortParams]);

  const query = useCallback(() => {
    if (hasMoreData) {
      const baseVariables: GetAccessGrantsQueryArgs = {
        take,
        skip: skip.current,
        where: {
          ...generateMembershipQuery(membership),
          locationId: { equals: locationId },
          status: { equals: AccessGrantStatus.Active },
        },
      };

      const queryWhereParams = createQueryWhereParams();
      const querySortParams = createQuerySortParams();

      const variables = merge(merge(baseVariables, queryWhereParams), querySortParams);

      getAccessGrantsAndCount({
        variables,
      });
    }
  }, [
    hasMoreData,
    take,
    membership,
    locationId,
    createQueryWhereParams,
    createQuerySortParams,
    getAccessGrantsAndCount,
  ]);

  useEffect(() => {
    query();
  }, [query]);

  const search = useCallback((newSearchParams: DataTableSearchType) => {
    // Reset the data
    skip.current = initialValues.skip;
    setTotal(initialValues.total);
    setAccessGrantsData(initialValues.accessGrantsData);

    // Set the new search params
    // Using the spread operator to ensure it will re-query the data
    // even when the parameters hasn't changed
    setSearchParams({ ...newSearchParams });
  }, []);

  const sort = useCallback(
    (newSortParamKey: string) => {
      // Verify if the column clicked is a valid sortable field
      const isAValidKey = Object.values(SelectReservationSortFields)
        .map((enumValue) => enumValue.toString())
        .includes(newSortParamKey);
      if (!isAValidKey) {
        return;
      }

      // Reset the data
      skip.current = initialValues.skip;
      setTotal(initialValues.total);
      setAccessGrantsData(initialValues.accessGrantsData);

      // Set the new sort params - It should first sort by ASC, then, DESC, then, no sort
      if (sortParams?.key === newSortParamKey) {
        if (sortParams.order === SortOrder.Desc) {
          setSortParams(undefined);
        } else {
          setSortParams({ key: newSortParamKey, order: SortOrder.Desc });
        }
      } else {
        setSortParams({ key: newSortParamKey, order: SortOrder.Asc });
      }
    },
    [sortParams]
  );

  return {
    data: accessGrantsData,
    error,
    loading,
    query,
    search,
    sort,
    hasMoreData,
  };
}
