/* eslint-disable sort-keys */
import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3ThemeCheckInSchema: NodeSchemaProperties = {
  checkIn: {
    title: '#22 Check In',
    type: 'object',
    properties: {
      'stepperRightIcon:enable': {
        description: '[checkIn.stepperRightIcon]',
        title: '#1 Stepper Right Icon',
        type: 'boolean',
      },
      stepperRightIcon: {
        type: 'string',
      },
      'stepperLeftIcon:enable': {
        description: '[checkIn.stepperLeftIcon]',
        title: '#2 Stepper Left Icon',
        type: 'boolean',
      },
      stepperLeftIcon: {
        type: 'string',
      },
      'stepperBackgroundColor:enable': {
        description: '[checkIn.stepperBackgroundColor]',
        title: '#3 Stepper Background Color',
        type: 'boolean',
      },
      stepperBackgroundColor: {
        type: 'string',
      },
      'stepperActiveBackgroundColor:enable': {
        description: '[checkIn.stepperActiveBackgroundColor]',
        title: '#4 Stepper Active Background Color',
        type: 'boolean',
      },
      stepperActiveBackgroundColor: {
        type: 'string',
      },
      'allowEarlyCheckIn:enable': {
        description: '[checkIn.allowEarlyCheckIn]',
        title: '#5 Enables early check-in',
        type: 'boolean',
      },
      allowEarlyCheckIn: {
        type: undefined,
        enum: [true, false],
      },
      workflow: {
        title: '#6 Workflow',
        type: 'object',
        properties: {
          'showPackages:enable': {
            description: '[checkIn.workflow.showPackages]',
            title: '#6.1 Show Packages',
            type: 'boolean',
          },
          showPackages: {
            type: undefined,
            enum: [true, false],
          },
          'showEta:enable': {
            description: '[checkIn.workflow.showEta]',
            title: '#6.2 Show ETA',
            type: 'boolean',
          },
          showEta: {
            type: undefined,
            enum: [true, false],
          },
          'showUserInformation:enable': {
            description: '[checkIn.workflow.showUserInformation]',
            title: '#6.3 Show User Information',
            type: 'boolean',
          },
          showUserInformation: {
            type: undefined,
            enum: [true, false],
          },
        },
      },
      retryPayment: {
        title: '#7 Retry payment',
        type: 'object',
        properties: {
          'displayRemoteAssistance:enable': {
            description: '[checkIn.retryPayment.displayRemoteAssistance]',
            title: '#7.1 Display remote assistance',
            type: 'boolean',
          },
          displayRemoteAssistance: {
            type: undefined,
            enum: [true, false],
          },
        },
      },
    },
  },
};

export const mobileV3ThemeCheckInUiSchema: NodeUiSchemaProperties = {
  checkIn: {
    stepperRightIcon: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    stepperLeftIcon: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    stepperBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    stepperActiveBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    allowEarlyCheckIn: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    workflow: {
      showPackages: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
      showEta: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
      showUserInformation: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
    },
    retryPayment: {
      displayRemoteAssistance: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
    },
  },
};
