import AccessGrantAuthenticationCredentials, {
  ACCESS_GRANT_AUTHENTICATION_CREDENTIALS_FORM,
} from '../../AccessGrantAuthenticationCredentials';
import { KEY_DISPENSE_FORM, RemoteKeyDispensingModal } from './RemoteKeyDispensingModal';
import ActionConfirmationModal from './ActionConfirmationModal';
import React from 'react';
import { RootState } from '../../../redux/reducers';
import { useSelector } from 'react-redux';

export const QuickActionModals: React.FC = () => {
  const modalsState = useSelector((state: RootState) => state.modals);

  return (
    <>
      {!!modalsState.quickActionConfirmationModal?.action &&
        !!modalsState.quickActionConfirmationModal?.ctaType && (
          <ActionConfirmationModal
            action={modalsState.quickActionConfirmationModal.action}
            ctaType={modalsState.quickActionConfirmationModal.ctaType}
          />
        )}

      {modalsState.form.name === ACCESS_GRANT_AUTHENTICATION_CREDENTIALS_FORM && (
        <AccessGrantAuthenticationCredentials accessGrantId={modalsState.form.id} />
      )}

      {modalsState.form.name === KEY_DISPENSE_FORM && <RemoteKeyDispensingModal />}
    </>
  );
};
