import { gql } from '../__generated__/gql';

export const GET_ACCESS_GRANTS_AND_COUNT = gql(`
  query GetAccessGrantsAndCount(
    $cursor: AccessGrantWhereUniqueInput
    $distinct: [AccessGrantScalarFieldEnum!]
    $orderBy: [AccessGrantOrderByWithRelationInput!]
    $skip: Int
    $take: Int
    $where: AccessGrantWhereInput
  ) {
    findDashboardAccessGrants(
      cursor: $cursor
      distinct: $distinct
      orderBy: $orderBy
      skip: $skip
      take: $take
      where: $where
    ) {
      id
      confirmationCode
      startsAt
      endsAt
      location {
        timezone
      }
      resources {
        id
        name
        type
      }
      user {
        id
        name
      }
      secondaryUsers {
        user {
          id
          name
        }
      }
    }

    aggregateDashboardAccessGrants(where: $where) {
      _count {
        _all
      }
    }
  }
`);
