import {
  DataTableRowValue,
  DataTableV2,
  DataTableValue,
} from '../../../../../../../../components/DataTableV2';
import React, { useCallback, useMemo } from 'react';

import { DataTableV2ContextProvider } from '../../../../../../../../components/DataTableV2/context/DataTableV2Context';
import { Datum } from '../../../../../../../../hooks/@types/QueryHookData';
import useGetLocations from '../../../../../../../../hooks/useGetLocations';
import { useLoadReservationOnKioskModalContext } from '../../../contexts/LoadReservationOnKioskModalContext';

enum SelectLocationFields {
  Name = 'Name',
}

const SelectLocationTable: React.FC = () => {
  const { setLocation } = useLoadReservationOnKioskModalContext();

  const { data: locations, loading, query: getLocations, hasMoreData } = useGetLocations({
    take: 15,
  });

  const locationsDataTableValue = useMemo((): DataTableValue<Datum> => {
    return locations?.map(
      (l): DataTableRowValue<Datum> => ({
        value: l,
        key: l.id,
        fields: [
          {
            value: {
              key: SelectLocationFields.Name,
              displayValue: l.name,
            },
          },
        ],
      })
    );
  }, [locations]);

  const onSelectValue = useCallback(
    (location: Datum) => {
      setLocation({ id: location.id, name: location.name });
    },
    [setLocation]
  );

  return (
    <DataTableV2<Datum>
      data={locationsDataTableValue}
      onSelectValue={onSelectValue}
      hasMoreData={hasMoreData}
      loadData={getLocations}
      isLoading={loading}
    />
  );
};

export const SelectLocation: React.FC = () => {
  return (
    <DataTableV2ContextProvider>
      <SelectLocationTable />
    </DataTableV2ContextProvider>
  );
};
