import React, { useMemo } from 'react';
import { Button } from './Button';
import { EarlyCheckInPackageData } from './Settings';
import { EmptyList } from './EmptyList';
import { FeatureWrapper } from '../styles/Settings.styles';
import { Spacer } from '../styles/Spacer.styles';
import { TimeRange } from './TimeRange';
import convert24To12HoursFormat from '../../GracePeriodsTab/util/convert24To12HoursFormat';
import { increaseEndTime } from '../../../util/time';
import { useAccessSetup } from '../../../pages/Location/components/AccessSetupContext';

export const PackagesList: React.FC<{
  onEdit?: (earlyCheckInPackage: EarlyCheckInPackageData) => void;
  onDelete?: (earlyCheckInPackage: EarlyCheckInPackageData) => void;
  onCreate?: () => void;
  enabled?: boolean;
}> = ({ onCreate, onDelete, onEdit, enabled }) => {
  const { data } = useAccessSetup();

  const formatTimeTange = ({
    checkInTime24hFormat,
    checkOutTime24hFormat,
  }: {
    checkInTime24hFormat?: string;
    checkOutTime24hFormat?: string;
  }): string => {
    if (!checkInTime24hFormat || !checkOutTime24hFormat) {
      return '';
    }

    const checkInTime = convert24To12HoursFormat(checkInTime24hFormat);
    const checkOutTime = convert24To12HoursFormat(checkOutTime24hFormat);

    return `${checkInTime.time} ${checkInTime.period} to ${checkOutTime.time} ${checkOutTime.period}`;
  };

  const items = useMemo(() => data?.packages?.filter((p) => !p.loyaltyCodes) ?? [], [data]);

  return (
    <FeatureWrapper enabled={enabled}>
      <Spacer size="24px" />
      {!items.length ? <EmptyList title="No paid early check-in time range set up yet" /> : null}
      {items.map((p) => (
        <TimeRange.Item
          key={p.serviceId}
          pmsPackageId={p.externalId}
          price={p.price}
          timeRange={formatTimeTange({
            checkInTime24hFormat: p.startTime?.toString(),
            checkOutTime24hFormat: p.endTime?.toString()
              ? increaseEndTime(p.endTime?.toString())
              : undefined,
          })}
          onEdit={(): void => onEdit?.(p)}
          onDelete={(): void => onDelete?.(p)}
        />
      ))}

      <Button.Submit title="Add Time Range" onClick={onCreate} />
    </FeatureWrapper>
  );
};
