import { Separator, SettingsTabWrapper } from './styles';
import CustomSectionTitle from './components/CustomSectionTitle';
import DefaultReservationTimes from './components/DefaultReservationTimes';
import EarlyCheckInSection from './components/EarlyCheckInSection';
import GRACE_PERIOD_INPUTS from './constants/inputs';
import GracePeriodSection from './components/GracePeriodSection';
import { KeyLimitSection } from './components/KeyLimitSection';
import { QueryGetGracePeriodsConfigArgs } from '../../__generated__/graphql';
import React from 'react';
import useGetGracePeriodsData from '../../hooks/useGetGracePeriodsData';
import useSetGracePeriodsData from '../../hooks/useSetGracePeriodsData';

const GracePeriodsTab: React.FC<QueryGetGracePeriodsConfigArgs> = (
  props: QueryGetGracePeriodsConfigArgs
) => {
  const { gracePeriodsData, isLoadingGracePeriod } = useGetGracePeriodsData(props);
  const { isSubmitting, setGracePeriodsConfigCallback } = useSetGracePeriodsData(props);

  return (
    <SettingsTabWrapper>
      {props.gracePeriodsConfigLevel === 'LOCATION' && (
        <>
          <DefaultReservationTimes locationId={props.locationId} />
          <EarlyCheckInSection
            gracePeriods={gracePeriodsData}
            isSubmitting={isSubmitting}
            setGracePeriodsConfigCallback={setGracePeriodsConfigCallback}
          />
          <Separator />
        </>
      )}
      <CustomSectionTitle title="Override Period Access Setup" margin="25px 0" />
      {GRACE_PERIOD_INPUTS.map(({ title, category, description }, index) => (
        <GracePeriodSection
          key={`section-title-item-${index}`}
          title={`${index + 1}. ${title}`}
          description={description}
          category={category}
          loading={isLoadingGracePeriod}
          gracePeriods={gracePeriodsData}
          setGracePeriodsConfigCallback={setGracePeriodsConfigCallback}
          isSubmitting={isSubmitting}
        />
      ))}
      <KeyLimitSection {...props} limitKeysConfigLevel={props.gracePeriodsConfigLevel} />
    </SettingsTabWrapper>
  );
};

export default GracePeriodsTab;
