import { GetAccessSetupQuery, GetAccessSetupQueryVariables } from '../../__generated__/graphql';
import { AccessSetupData } from '../../pages/Location/components/AccessSetupContext';
import GET_ACCESS_SETUP from '../../graphql/getAccessSetup';
import { useCallback } from 'react';
import useGetMany from '../useGetMany';

type UseGetAccessSetupProps = {
  locationId?: string;
};

type UseGetAccessSetupResult = {
  data?: AccessSetupData;
  get: () => void;
  loading: boolean;
};

const useGetAccessSetup = ({ locationId }: UseGetAccessSetupProps): UseGetAccessSetupResult => {
  const { data, loading, query } = useGetMany<GetAccessSetupQuery, GetAccessSetupQueryVariables>({
    graphqlQuery: GET_ACCESS_SETUP,
  });

  const get = useCallback(() => {
    if (!locationId) {
      return;
    }

    query({
      locationId,
    });
  }, [query, locationId]);
  return {
    data: data?.getAccessSetup,
    get,
    loading,
  };
};

export default useGetAccessSetup;
