import DataTable from '../../../components/DataTable';
import { EntityEnum } from '../../../@types/client/DashboardUserRolePermissions';
import React from 'react';
import useAccessGrantsTableData from '../../../hooks/useAccessGrantsTableData';
import { useParams } from 'react-router';

const AccessGrantTab: React.FC<{}> = () => {
  const { userId } = useParams<{ userId: string }>();

  const tableData = useAccessGrantsTableData({
    dataTableKey: 'accessGrants-User',
    permission: EntityEnum.NONE,
    queryOptions: {
      userId,
    },
  });

  return <DataTable {...tableData} />;
};

export default React.memo(AccessGrantTab);
