import { CustomForm, FormText } from '../styles/RemoteKeyDispensingModal.styles';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import React, { memo } from 'react';
import { closeForm, openQuickActionProcessingModal } from '../../../redux/actions/modals.actions';
import { useDispatch, useSelector } from 'react-redux';
import BaseQuickActionModal from '../../BaseQuickActionModal';
import { CTAIcon } from './CTAIcon';
import InputGroupProps from '../../InputGroup/@types/InputGroupProps';
import { REMOTE_DISPENSE_KEY_VALIDATION_SCHEMA } from '../constants/REMOTE_DISPENSE_KEY_VALIDATION_SCHEMA';
import { RemotePrintKioskFormValues } from '../@types/QuickActionBarTypes';
import { RootState } from '../../../redux/reducers';
import SimpleFormSection from '../../../templates/SimpleFormSection';
import Theme from '../../../theme';
import useLoadKioskOptions from '../hooks/useLoadKioskOptions';
import useSearchKioskByName from '../hooks/useSearchKioskByName';
import { useSendDispenseKeyCommand } from '../../../hooks/useSendDispenseKeyCommand';
import { yupResolver } from '@hookform/resolvers/yup';

export const KEY_DISPENSE_FORM = 'KEY_DISPENSE_FORM';

export const RemoteKeyDispensingModal: React.FC = memo(() => {
  const form = useForm<RemotePrintKioskFormValues>({
    defaultValues: {},
    resolver: yupResolver(REMOTE_DISPENSE_KEY_VALIDATION_SCHEMA),
    reValidateMode: 'onChange',
  });
  const dispatch = useDispatch();
  const accessGrant = useSelector((state: RootState) => state.accessGrant.pageInfo);
  const { sendDispenseKeyCommand } = useSendDispenseKeyCommand();

  const inputs: InputGroupProps[] = [
    {
      label: 'Search Kiosk',
      loadOptions: useLoadKioskOptions,
      name: 'kiosk',
      query: useSearchKioskByName,
      type: 'async-select',
      required: true,
    },
  ];

  const onSubmit: SubmitHandler<RemotePrintKioskFormValues> = (data): void => {
    const kioskId = data.kiosk.value;

    dispatch(closeForm());

    dispatch(
      openQuickActionProcessingModal({
        action: () => sendDispenseKeyCommand(kioskId, accessGrant?.id || ''),
        ctaType: 'remotePrintKeyCard',
      })
    );
  };

  return (
    <BaseQuickActionModal
      header={{
        title: 'Select a kiosk to dispense a key',
        icon: <CTAIcon type="remotePrintKeyCard" color={Theme.colors.quickActionButton.hexCode} />,
      }}
      confirmButton={{
        action: (): void => {
          form.handleSubmit(onSubmit)();
        },
      }}
      cancelButton={{
        action: (): void => {
          dispatch(closeForm());
        },
      }}
    >
      <FormProvider {...form}>
        <CustomForm>
          <SimpleFormSection inputs={inputs} columns={1} />
        </CustomForm>
      </FormProvider>
      <FormText>
        Confirming this action will dispense a key card at the selected kiosk. Please ensure the
        guest is present at the kiosk.
      </FormText>
    </BaseQuickActionModal>
  );
});
