/* eslint-disable sort-keys */
import {
  mobileV3LocalizationAccessManagementSchema,
  mobileV3LocalizationAccessManagementUiSchema,
  mobileV3LocalizationAmenitiesSchema,
  mobileV3LocalizationAmenitiesUiSchema,
  mobileV3LocalizationAssistanceSchema,
  mobileV3LocalizationAssistanceUiSchema,
  mobileV3LocalizationAuthenticationSchema,
  mobileV3LocalizationAuthenticationUiSchema,
  mobileV3LocalizationCheckInFailSchema,
  mobileV3LocalizationCheckInFailUiSchema,
  mobileV3LocalizationCheckInSchema,
  mobileV3LocalizationCheckInUiSchema,
  mobileV3LocalizationCheckOutSchema,
  mobileV3LocalizationCheckOutUiSchema,
  mobileV3LocalizationConciergeSchema,
  mobileV3LocalizationConciergeUiSchema,
  mobileV3LocalizationCurrentBookingSchema,
  mobileV3LocalizationCurrentBookingUiSchema,
  mobileV3LocalizationErrorCodesSchema,
  mobileV3LocalizationErrorCodesUiSchema,
  mobileV3LocalizationExpectedArrivalSchema,
  mobileV3LocalizationExpectedArrivalUiSchema,
  mobileV3LocalizationGeneralSchema,
  mobileV3LocalizationGeneralUiSchema,
  mobileV3LocalizationGuestsSchema,
  mobileV3LocalizationGuestsUiSchema,
  mobileV3LocalizationHomeSchema,
  mobileV3LocalizationHomeUiSchema,
  mobileV3LocalizationIdVerificationResultSchema,
  mobileV3LocalizationIdVerificationResultUiSchema,
  mobileV3LocalizationIdVerificationSchema,
  mobileV3LocalizationIdVerificationUiSchema,
  mobileV3LocalizationPackagesSchema,
  mobileV3LocalizationPackagesUiSchema,
  mobileV3LocalizationPaymentAuthorizationSchema,
  mobileV3LocalizationPaymentAuthorizationUiSchema,
  mobileV3LocalizationPaymentCaptureSchema,
  mobileV3LocalizationPaymentCaptureUiSchema,
  mobileV3LocalizationPaymentFormSchema,
  mobileV3LocalizationPaymentFormUiSchema,
  mobileV3LocalizationPaymentStatusSchema,
  mobileV3LocalizationPaymentStatusUiSchema,
  mobileV3LocalizationProfileSchema,
  mobileV3LocalizationProfileUiSchema,
  mobileV3LocalizationQrCodeSchema,
  mobileV3LocalizationQrCodeUiSchema,
  mobileV3LocalizationReservationSchema,
  mobileV3LocalizationReservationUiSchema,
  mobileV3LocalizationShareKeySchema,
  mobileV3LocalizationShareKeyUiSchema,
  mobileV3LocalizationSimplifiedIDVerificationSchema,
  mobileV3LocalizationSimplifiedIDVerificationUiSchema,
  mobileV3LocalizationSuccessSchema,
  mobileV3LocalizationSuccessUiSchema,
  mobileV3LocalizationTermsAndConditionsSchema,
  mobileV3LocalizationTermsAndConditionsUiSchema,
  mobileV3LocalizationTripsSchema,
  mobileV3LocalizationTripsUiSchema,
  mobileV3LocalizationUserDetailsSchema,
  mobileV3LocalizationUserDetailsUiSchema,
} from './MobileV3Localization';

import { JSONSchema7 } from 'json-schema';

export const mobileV3LocalizationSchema: JSONSchema7 = {
  type: 'object',
  properties: {
    ...mobileV3LocalizationGeneralSchema,
    ...mobileV3LocalizationAuthenticationSchema,
    ...mobileV3LocalizationHomeSchema,
    ...mobileV3LocalizationCurrentBookingSchema,
    ...mobileV3LocalizationReservationSchema,
    ...mobileV3LocalizationAmenitiesSchema,
    ...mobileV3LocalizationPackagesSchema,
    ...mobileV3LocalizationTripsSchema,
    ...mobileV3LocalizationProfileSchema,
    ...mobileV3LocalizationUserDetailsSchema,
    ...mobileV3LocalizationGuestsSchema,
    ...mobileV3LocalizationAssistanceSchema,
    ...mobileV3LocalizationExpectedArrivalSchema,
    ...mobileV3LocalizationAccessManagementSchema,
    ...mobileV3LocalizationShareKeySchema,
    ...mobileV3LocalizationSimplifiedIDVerificationSchema,
    ...mobileV3LocalizationIdVerificationResultSchema,
    ...mobileV3LocalizationIdVerificationSchema,
    ...mobileV3LocalizationTermsAndConditionsSchema,
    ...mobileV3LocalizationSuccessSchema,
    ...mobileV3LocalizationPaymentStatusSchema,
    ...mobileV3LocalizationPaymentAuthorizationSchema,
    ...mobileV3LocalizationPaymentCaptureSchema,
    ...mobileV3LocalizationPaymentFormSchema,
    ...mobileV3LocalizationCheckInSchema,
    ...mobileV3LocalizationCheckInFailSchema,
    ...mobileV3LocalizationCheckOutSchema,
    ...mobileV3LocalizationErrorCodesSchema,
    ...mobileV3LocalizationQrCodeSchema,
    ...mobileV3LocalizationConciergeSchema,
  },
};

export const mobileV3LocalizationUiSchema = {
  ...mobileV3LocalizationGeneralUiSchema,
  ...mobileV3LocalizationAuthenticationUiSchema,
  ...mobileV3LocalizationHomeUiSchema,
  ...mobileV3LocalizationCurrentBookingUiSchema,
  ...mobileV3LocalizationReservationUiSchema,
  ...mobileV3LocalizationAmenitiesUiSchema,
  ...mobileV3LocalizationPackagesUiSchema,
  ...mobileV3LocalizationTripsUiSchema,
  ...mobileV3LocalizationProfileUiSchema,
  ...mobileV3LocalizationUserDetailsUiSchema,
  ...mobileV3LocalizationGuestsUiSchema,
  ...mobileV3LocalizationAssistanceUiSchema,
  ...mobileV3LocalizationExpectedArrivalUiSchema,
  ...mobileV3LocalizationAccessManagementUiSchema,
  ...mobileV3LocalizationShareKeyUiSchema,
  ...mobileV3LocalizationSimplifiedIDVerificationUiSchema,
  ...mobileV3LocalizationIdVerificationResultUiSchema,
  ...mobileV3LocalizationIdVerificationUiSchema,
  ...mobileV3LocalizationTermsAndConditionsUiSchema,
  ...mobileV3LocalizationSuccessUiSchema,
  ...mobileV3LocalizationPaymentStatusUiSchema,
  ...mobileV3LocalizationPaymentAuthorizationUiSchema,
  ...mobileV3LocalizationPaymentCaptureUiSchema,
  ...mobileV3LocalizationPaymentFormUiSchema,
  ...mobileV3LocalizationCheckInUiSchema,
  ...mobileV3LocalizationCheckInFailUiSchema,
  ...mobileV3LocalizationCheckOutUiSchema,
  ...mobileV3LocalizationErrorCodesUiSchema,
  ...mobileV3LocalizationQrCodeUiSchema,
  ...mobileV3LocalizationConciergeUiSchema,
};
