import { gql } from '../__generated__';

const DELETE_EARLY_CHECKIN_PACKAGE = gql(`
  mutation deleteEarlyCheckInPackage(
    $serviceId: String!
  ) {
    deleteEarlyCheckInPackage(serviceId: $serviceId) {
      success
    }
  }
`);

export default DELETE_EARLY_CHECKIN_PACKAGE;
