import {
  BaseModalHeaderContainer,
  CloseModalButton,
  HeaderIcon,
  HeaderText,
} from './BaseModalHeader.styles';
import { BaseActionModalProps } from './BaseActionModal';
import { MdClose } from 'react-icons/md';
import React from 'react';

export type BaseModalHeaderProps = Pick<BaseActionModalProps, 'headerTitle' | 'error' | 'icon'> & {
  onClose: () => void;
};

export const BaseModalHeader: React.FC<BaseModalHeaderProps> = ({ headerTitle, onClose, icon }) => {
  return (
    <BaseModalHeaderContainer>
      {icon ? <HeaderIcon>{icon}</HeaderIcon> : null}
      <HeaderText>{headerTitle}</HeaderText>
      <CloseModalButton onClick={onClose}>
        <MdClose size="22px" />
      </CloseModalButton>
    </BaseModalHeaderContainer>
  );
};
