import { TableDataCell, TableDataCellMultiLine } from '../DataTableV2.styles';

import { DataTableHighlightText } from './DataTableHighlightText/DataTableHighlightText';
import { DataTableRowCell } from '../@types';
import React from 'react';

type DataTableValueProps = { field: DataTableRowCell };

export const DataTableRowValue: React.FC<DataTableValueProps> = (props) => {
  const { field } = props;

  if (Array.isArray(field.value)) {
    return (
      <TableDataCellMultiLine style={{ flex: field.flex }}>
        {field.value.map((value) => (
          <DataTableHighlightText key={value.key} value={value} />
        ))}
      </TableDataCellMultiLine>
    );
  }

  return (
    <TableDataCell style={{ flex: field.flex }}>
      <DataTableHighlightText value={field.value} />
    </TableDataCell>
  );
};
