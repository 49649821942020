/* eslint-disable sort-keys */

import { JSONSchema7 } from 'json-schema';

export const kioskThemeCustomizationSchema: JSONSchema7 = {
  type: 'object',
  properties: {
    general: {
      type: 'object',
      title: '#1 General',
      properties: {
        'qrCodeUrl:enable': {
          title: '#1 QR code URL',
          type: 'boolean',
          description:
            'The URL shown in the QR code on the homeScreen for downloading an app. [general.qrCodeUrl]',
        },
        qrCodeUrl: {
          type: 'string',
        },
        'textColor:enable': {
          title: '#2 Text color',
          type: 'boolean',
          description:
            'This is the color of text in the remote assistance button in the top right, as well as various text throughout the screens not listed as header text. [general.textColor] - RGBA / RGB / Hex / Color name',
        },
        textColor: {
          type: 'string',
        },
        'borderColor:enable': {
          title: '#3 Border color',
          type: 'boolean',
          description:
            'Used for horizontal lines on screens for header and footer. Horizontal lines in menu selections and in setting screen between rows. [general.borderColor] - RGBA / RGB / Hex / Color name',
        },
        borderColor: {
          type: 'string',
        },
        'buttonColor:enable': {
          title: '#4 Button color',
          type: 'boolean',
          description:
            'Background color for “primary” type buttons. Border color for “outline” type buttons. [general.buttonColor] - RGBA / RGB / Hex / Color name',
        },
        buttonColor: {
          type: 'string',
        },
        'shadowColor:enable': {
          title: '#5 Shadow color',
          type: 'boolean',
          description:
            'Used to outline homescreen logo, QR code card, homescreen buttons, menu selections, footer buttons, remote assistance button, form cards. [general.shadowColor] - RGBA / RGB / Hex / Color name',
        },
        shadowColor: {
          type: 'string',
        },
        'logoImageUrl:enable': {
          title: '#6 Logo image URL',
          type: 'boolean',
          description: 'Logo that is displayed on the homeScreen only. [general.logoImageUrl]',
        },
        logoImageUrl: {
          type: 'string',
        },
        'primaryColor:enable': {
          title: '#7 Primary color',
          type: 'boolean',
          description:
            'Used for icons on menu screens, icon buttons in the lower navigation bar (including “back” arrow) and icons in the text input fields. [general.primaryColor] - Hex only',
        },
        primaryColor: {
          type: 'string',
        },
        'doneButtonType:enable': {
          title: '#8 Done button type',
          type: 'boolean',
          description: '[general.doneButtonType]',
        },
        doneButtonType: {
          type: 'string',
          enum: ['primary', 'outline'],
        },
        'secondaryColor:enable': {
          title: '#9 Secondary color',
          type: 'boolean',
          description:
            'Used as the background color for user feedback on form screens. [general.secondaryColor] - RGBA / RGB / Hex / Color name',
        },
        secondaryColor: {
          type: 'string',
        },
        'textInputColor:enable': {
          title: '#10 Text input color',
          type: 'boolean',
          description:
            'For the background text input fields such as when requesting user name or confirmation number etc. [general.textInputColor] - RGBA / RGB / Hex / Color name',
        },
        textInputColor: {
          type: 'string',
        },
        'toastTextColor:enable': {
          title: '#11 Toast text color',
          type: 'boolean',
          description:
            'Top Quart user feedback text color. [general.toastTextColor] - RGBA / RGB / Hex / Color name',
        },
        toastTextColor: {
          type: 'string',
        },
        'backgroundColor:enable': {
          title: '#12 Background color',
          type: 'boolean',
          description:
            'This is the main color that will be seen on most screens as the background color for the screen itself, as well as menu selections, and “outline” style buttons. this is also the text color of the “primary” style buttons. [general.backgroundColor] - RGBA / RGB / Hex / Color name',
        },
        backgroundColor: {
          type: 'string',
        },
        'backgroundImageUrl:enable': {
          title: '#13 Background image URL',
          type: 'boolean',
          description: '[general.backgroundImageUrl]',
        },
        backgroundImageUrl: {
          type: 'string',
        },
        'clearButtonType:enable': {
          title: '#14 Clear button type',
          type: 'boolean',
          description: '[general.clearButtonType]',
        },
        clearButtonType: {
          type: 'string',
          enum: ['primary', 'outline'],
        },
        'primaryTextColor:enable': {
          title: '#15 Primary text color',
          type: 'boolean',
          description:
            'Used for header text, label on the language button, labels on menu screen buttons, the “back” text, and user inputted text, labels on “outline” style buttons. [general.primaryTextColor] - Hex only',
        },
        primaryTextColor: {
          type: 'string',
        },
        'updateButtonType:enable': {
          title: '#16 Update button type',
          type: 'boolean',
          description: '[general.updateButtonType]',
        },
        updateButtonType: {
          type: 'string',
          enum: ['primary', 'outline'],
        },
        'headerLogoImageUrl:enable': {
          title: '#17 Header logo image URL',
          type: 'boolean',
          description: '[general.headerLogoImageUrl]',
        },
        headerLogoImageUrl: {
          type: 'string',
        },
        'continueButtonType:enable': {
          title: '#18 Continue button type',
          type: 'boolean',
          description: '[general.continueButtonType]',
        },
        continueButtonType: {
          type: 'string',
          enum: ['primary', 'outline'],
        },
        'secondaryTextColor:enable': {
          title: '#19 Secondary text color',
          type: 'boolean',
          description:
            'Used for placeholder text in input fields. [general.secondaryTextColor] - RGBA / RGB / Hex / Color name',
        },
        secondaryTextColor: {
          type: 'string',
        },
        'validateButtonType:enable': {
          title: '#20 Validate button type',
          type: 'boolean',
          description: '[general.validateButtonType]',
        },
        validateButtonType: {
          type: 'string',
          enum: ['primary', 'outline'],
        },
        'resendCodeButtonType:enable': {
          title: '#21 Resend code button type',
          type: 'boolean',
          description: '[general.resendCodeButtonType]',
        },
        resendCodeButtonType: {
          type: 'string',
          enum: ['primary', 'outline'],
        },
        'toastBackgroundColor:enable': {
          title: '#22 Toast background color',
          type: 'boolean',
          description:
            'Top Quarter user feedback background color. [general.toastBackgroundColor] - RGBA / RGB / Hex / Color name',
        },
        toastBackgroundColor: {
          type: 'string',
        },
        'disableRemoteAssistance:enable': {
          title: '#23 Disable remote assistance',
          type: 'boolean',
          description:
            'If true, hides the remote assistance button in the top-right of the screen. [general.disableRemoteAssistance]',
        },
        disableRemoteAssistance: {
          type: undefined,
          enum: [true, false],
        },
        'idleTimeout:enable': {
          title: '#24 IDLE popup timeout',
          type: 'boolean',
          description:
            'IDLE popup time in miliseconds to show, default is 60000 (1 minute). [general.idleTimeout]',
        },
        idleTimeout: {
          type: 'number',
        },
        'allowCheckInReservationWithoutRoom:enable': {
          title: '#25 Allow check-in reservation without a room',
          type: 'boolean',
          description:
            'If true, check-in reservation without a room is allowed [general.allowCheckInReservationWithoutRoom]',
        },
        allowCheckInReservationWithoutRoom: {
          type: undefined,
          enum: [true, false],
        },
        'defaultPhonePrefix:enable': {
          title: '#26 Default phone prefix ISO code',
          type: 'boolean',
          description:
            'Default phone prefix ISO code. If not set, default is "us". [general.defaultPhonePrefix]',
        },
        defaultPhonePrefix: {
          type: 'string',
        },
        'buttonTextColor:enable': {
          title: '#27 Button text color',
          type: 'boolean',
          description:
            'Button text color. [general.toastBackgroundColor] - RGBA / RGB / Hex / Color name',
        },
        buttonTextColor: {
          type: 'string',
        },
        'snackBarBackgroundColor:enable': {
          title: '#28 Snackbar background color',
          type: 'boolean',
          description:
            'Snackbar background color. [general.snackBarBackgroundColor] - RGBA / RGB / Hex / Color name',
        },
        snackBarBackgroundColor: {
          type: 'string',
        },
        'snackBarTextColor:enable': {
          title: '#29 Snackbar text color',
          type: 'boolean',
          description:
            'Snackbar text color. [general.snackBarTextColor] - RGBA / RGB / Hex / Color name',
        },
        snackBarTextColor: {
          type: 'string',
        },
        'squareLocationId:enable': {
          title: '#30 Square location ID',
          type: 'boolean',
          description: 'Square location ID. [general.squareLocationId]',
        },
        squareLocationId: {
          type: 'string',
        },
      },
    },
    popup: {
      type: 'object',
      title: '#2 Popup',
      properties: {
        'popupTextColor:enable': {
          title: '#1 Popup text tolor',
          type: 'boolean',
          description: '[popup.popupTextColor] - RGBA / RGB / Hex / Color name',
        },
        popupTextColor: {
          type: 'string',
        },
        'popupButtonColor:enable': {
          title: '#2 Popup button color',
          type: 'boolean',
          description: '[popup.popupButtonColor] - RGBA / RGB / Hex / Color name',
        },
        popupButtonColor: {
          type: 'string',
        },
        'popupBackgroundColor:enable': {
          title: '#3 Popup background color',
          type: 'boolean',
          description: '[popup.popupBackgroundColor] - RGBA / RGB / Hex / Color name',
        },
        popupBackgroundColor: {
          type: 'string',
        },
        'popupButtonTextColor:enable': {
          title: '#4 Popup button text color',
          type: 'boolean',
          description: '[popup.popupButtonTextColor] - RGBA / RGB / Hex / Color name',
        },
        popupButtonTextColor: {
          type: 'string',
        },
      },
    },
    homeScreen: {
      type: 'object',
      title: '#3 Home screen',
      properties: {
        'hasVetting:enable': {
          title: '#1 Enable vetting screen',
          type: 'boolean',
          description: '[homeScreen.hasVetting]',
        },
        hasVetting: {
          type: undefined,
          enum: [true, false],
        },
        'isVertical:enable': {
          title: '#2 Is vertical',
          type: 'boolean',
          description: '[homeScreen.isVertical]',
        },
        isVertical: {
          type: undefined,
          enum: [true, false],
        },
        'buttonColor:enable': {
          title: '#3 Button color',
          type: 'boolean',
          description:
            'Color of the main buttons, including the circle language selector. [homeScreen.buttonColor] - RGBA / RGB / Hex / Color name',
        },
        buttonColor: {
          type: 'string',
        },
        'displayQrCode:enable': {
          title: '#4 Display QR code',
          type: 'boolean',
          description:
            'Shows a QR code to scan with mobile to download an app. [homeScreen.displayQrCode]',
        },
        displayQrCode: {
          type: undefined,
          enum: [true, false],
        },
        'buttonTextColor:enable': {
          title: '#5 Button text color',
          type: 'boolean',
          description:
            'Text color of the main buttons and language selector. [homeScreen.buttonTextColor] - RGBA / RGB / Hex / Color name',
        },
        buttonTextColor: {
          type: 'string',
        },
        'backgroundImageUrl:enable': {
          title: '#6 Background image URL',
          type: 'boolean',
          description:
            'Will show an image in the background of the homeScreen only. [homeScreen.backgroundImageUrl]',
        },
        backgroundImageUrl: {
          type: 'string',
        },
        'logoBackgroundColor:enable': {
          title: '#7 Logo background color',
          type: 'boolean',
          description:
            'Shows a box underneath the logo image. [homeScreen.logoBackgroundColor] - RGBA / RGB / Hex / Color name',
        },
        logoBackgroundColor: {
          type: 'string',
        },
        'displayCheckoutButton:enable': {
          title: '#8 Display checkout button',
          type: 'boolean',
          description:
            'Displays a button that starts the check-out process. [homeScreen.displayCheckoutButton]',
        },
        displayCheckoutButton: {
          type: undefined,
          enum: [true, false],
        },
        'displayWelcomeText:enable': {
          title: '#9 Display welcome text',
          type: 'boolean',
          description: '[homeScreen.displayWelcomeText]',
        },
        displayWelcomeText: {
          type: undefined,
          enum: [true, false],
        },
        'isNewLayout:enable': {
          title: '#10 Turn on new home screen layout',
          type: 'boolean',
          description: 'Full screen with 2 section on left and right [homeScreen.isNewLayout]',
        },
        isNewLayout: {
          type: undefined,
          enum: [true, false],
        },
        'isInstruction:enable': {
          title: '#11 Turn on instruction card on home screen (old layout)',
          type: 'boolean',
          description: 'Full screen with 2 section on left and right [homeScreen.isInstruction]',
        },
        isInstruction: {
          type: undefined,
          enum: [true, false],
        },
        'instructionBorderColor:enable': {
          title: '#12 Instruction container Border Color',
          type: 'boolean',
          description:
            'Border color [homeScreen.instructionBorderColor] - RGBA / RGB / Hex / Color name',
        },
        instructionBorderColor: {
          type: 'string',
        },
        'descriptionTextColor:enable': {
          title: '#13 Description text color',
          type: 'boolean',
          description:
            'Text color [homeScreen.descriptionTextColor] - RGBA / RGB / Hex / Color name',
        },
        descriptionTextColor: {
          type: 'string',
        },
        'welcomeTextColor:enable': {
          title: '#14 Welcome text color',
          type: 'boolean',
          description: 'Text color [homeScreen.welcomeTextColor] - RGBA / RGB / Hex / Color name',
        },
        welcomeTextColor: {
          type: 'string',
        },
        'textColor:enable': {
          title: '#14 Text color of new layout screen',
          type: 'boolean',
          description: 'Text color [homeScreen.textColor] - RGBA / RGB / Hex / Color name',
        },
        textColor: {
          type: 'string',
        },
        'checkinIconUrl:enable': {
          title: '#15 Checkin Icon Url of new layout screen',
          type: 'boolean',
          description: 'Checkin Icon [homeScreen.checkinIconUrl]',
        },
        checkinIconUrl: {
          type: 'string',
        },
        'checkOutIconUrl:enable': {
          title: '#16 Checkout Icon Url of new layout screen',
          type: 'boolean',
          description: 'Checkout Icon [homeScreen.checkOutIconUrl]',
        },
        checkOutIconUrl: {
          type: 'string',
        },
        'printAnotherKeycardIcon:enable': {
          title: '#17 PrintAnotherKeycard Icon Url of new layout screen',
          type: 'boolean',
          description: 'Checkout Icon [homeScreen.printAnotherKeycardIcon]',
        },
        printAnotherKeycardIcon: {
          type: 'string',
        },
        'folioIconUrl:enable': {
          title: '#18 Folio Icon Url of new layout screen',
          type: 'boolean',
          description: 'Checkout Icon [homeScreen.folioIconUrl]',
        },
        folioIconUrl: {
          type: 'string',
        },
        'titleTextSize:enable': {
          title: '#19 Title text size',
          type: 'boolean',
          description: '[homeScreen.titleTextSize]',
        },
        titleTextSize: {
          type: 'number',
        },
        'headerTextSize:enable': {
          title: '#20 Header text size',
          type: 'boolean',
          description: '[homeScreen.headerTextSize]',
        },
        headerTextSize: {
          type: 'number',
        },
        'descriptionTextSize:enable': {
          title: '#21 Description text size',
          type: 'boolean',
          description: '[homeScreen.descriptionTextSize]',
        },
        descriptionTextSize: {
          type: 'number',
        },
        'buttonTextSize:enable': {
          title: '#22 Button text size',
          type: 'boolean',
          description: '[homeScreen.buttonTextSize]',
        },
        buttonTextSize: {
          type: 'number',
        },
        'titleImageUrl:enable': {
          title: '#23 Logo Image url of new layout screen',
          type: 'boolean',
          description: 'titleImageUrl [homeScreen.titleImageUrl]',
        },
        titleImageUrl: {
          type: 'string',
        },
        'containerAlignment:enable': {
          title: '#24 Alignment of left section in new layout screen',
          type: 'boolean',
          description: '[homeScreen.titleImageUrl]',
        },
        containerAlignment: {
          type: undefined,
          enum: ['flex-start', 'center', 'flex-end'],
        },
        'iconDirection:enable': {
          title: '#25 Alignment of menu icon in new layout screen',
          type: 'boolean',
          description: '[homeScreen.iconDirection]',
        },
        iconDirection: {
          type: undefined,
          enum: ['right', 'left'],
        },
        'iconColor:enable': {
          title: '#26 Icon color in new layout screen',
          type: 'boolean',
          description: '[homeScreen.iconColor]',
        },
        iconColor: {
          type: 'string',
        },
        'displayFolioButton:enable': {
          title: '#27 Display folio button',
          type: 'boolean',
          description:
            'Displays a button that starts the folio process. [homeScreen.displayFolioButton]',
        },
        displayFolioButton: {
          type: undefined,
          enum: [true, false],
        },
        'displayPrintAnotherKeycardButton:enable': {
          title: '#28 Display print another keycard button',
          type: 'boolean',
          description:
            'Displays a button that starts the print another keycard process. [homeScreen.displayPrintAnotherKeycardButton]',
        },
        displayPrintAnotherKeycardButton: {
          type: undefined,
          enum: [true, false],
        },
        'displayConciergeButton:enable': {
          title: '#29 Display concierge button',
          type: 'boolean',
          description:
            'Displays a button that starts the concierge process. [homeScreen.displayConciergeButton]',
        },
        displayConciergeButton: {
          type: undefined,
          enum: [true, false],
        },
        'buttonBorderColor:enable': {
          title: '#30 Button Border Color',
          type: 'boolean',
          description: '[homeScreen.buttonBorderColor] - RGBA / RGB / Hex / Color name',
        },
        buttonBorderColor: {
          type: 'string',
        },
        'buttonBorderWidth:enable': {
          title: '#31 Button border width',
          type: 'boolean',
          description: '[homeScreen.buttonBorderWidth]',
        },
        buttonBorderWidth: {
          type: 'number',
        },
        'buttonFontSize:enable': {
          title: '#32 Button font size',
          type: 'boolean',
          description: '[homeScreen.buttonFontSize]',
        },
        buttonFontSize: {
          type: 'number',
        },
        'buttonFontWeight:enable': {
          title: '#33 Button font weight',
          type: 'boolean',
          description: '[homeScreen.buttonFontWeight]',
        },
        buttonFontWeight: {
          type: undefined,
          enum: ['normal', 'bold', '100', '200', '300', '400', '500', '600', '700', '800', '900'],
        },
        'conciergeIconUrl:enable': {
          title: '#34 Concierge Icon Url of new layout screen',
          type: 'boolean',
          description: 'Concierge Icon [homeScreen.conciergeIconUrl]',
        },
        conciergeIconUrl: {
          type: 'string',
        },
        'headerLogoImageUrl:enable': {
          title: '#35 Header logo image URL',
          type: 'boolean',
          description: '[homeScreen.headerLogoImageUrl]',
        },
        headerLogoImageUrl: {
          type: 'string',
        },
        'displayCheckinButton:enable': {
          title: '#36 Display checkin button',
          type: 'boolean',
          description:
            'Displays a button that starts the check-in process. [homeScreen.displayCheckinButton]',
        },
        displayCheckinButton: {
          type: undefined,
          enum: [true, false],
        },
        'iconBackgroundColor:enable': {
          title: '#37 Icon background color in new layout screen',
          type: 'boolean',
          description: '[homeScreen.iconBackgroundColor]',
        },
        iconBackgroundColor: {
          type: 'string',
        },
        'iconSize:enable': {
          title: '#38 Icon size in new layout screen',
          type: 'boolean',
          description: '[homeScreen.iconSize]',
        },
        iconSize: {
          type: 'number',
        },
        'iconBackgroundSize:enable': {
          title: '#39 Icon background size',
          type: 'boolean',
          description: '[homeScreen.iconBackgroundSize]',
        },
        iconBackgroundSize: {
          type: 'number',
        },
        'iconBackgroundBorderRadius:enable': {
          title: '#40 Icon background border radius',
          type: 'boolean',
          description: '[homeScreen.iconBackgroundBorderRadius]',
        },
        iconBackgroundBorderRadius: {
          type: 'number',
        },
        'topRightRemoteAssistanceButtonVisible:enable': {
          title: '#41 Display Top Right Remote Assistance Button Visible',
          type: 'boolean',
          description:
            'Display Top Right Remote Assistance Button Visible. [homeScreen.topRightRemoteAssistanceButtonVisible]',
        },
        topRightRemoteAssistanceButtonVisible: {
          type: undefined,
          enum: [true, false],
        },
        'homeScreenLogoSize:enable': {
          title: '#42 Home screen logo size',
          type: 'boolean',
          description: 'Display Home screen logo size [homeScreen.homeScreenLogoSize]',
        },
        homeScreenLogoSize: {
          type: 'number',
        },
      },
    },
    vettingScreen: {
      type: 'object',
      title: '#4 Vetting screen',
      properties: {
        'shadowColor:enable': {
          title: '#1 Shadow color',
          type: 'boolean',
          description: '[vettingScreen.shadowColor] - RGBA / RGB / Hex / Color name',
        },
        shadowColor: {
          type: 'string',
        },
        'buttonColor:enable': {
          title: '#2 Button color',
          type: 'boolean',
          description: '[vettingScreen.buttonColor] - RGBA / RGB / Hex / Color name',
        },
        buttonColor: {
          type: 'string',
        },
        'buttonTextColor:enable': {
          title: '#3 Button text color',
          type: 'boolean',
          description: '[vettingScreen.buttonTextColor] - RGBA / RGB / Hex / Color name',
        },
        buttonTextColor: {
          type: 'string',
        },
        'backgroundColor:enable': {
          title: '#4 Background color',
          type: 'boolean',
          description:
            '[vettingScreen.backgroundColor] - RGBA / RGB / Hex / Color name, Default: general.backgroundColor',
        },
        backgroundColor: {
          type: 'string',
        },
        'textColor:enable': {
          title: '#5 Text color',
          type: 'boolean',
          description:
            '[vettingScreen.textColor] - RGBA / RGB / Hex / Color name, Default: general.primaryTextColor',
        },
        textColor: {
          type: 'string',
        },
        'unselectedOptionBackgroundColor:enable': {
          title: '#6 Unselected option background color',
          type: 'boolean',
          description:
            '[vettingScreen.unselectedOptionBackgroundColor] - RGBA / RGB / Hex / Color name, Default: #FFFFFF',
        },
        unselectedOptionBackgroundColor: {
          type: 'string',
        },
        'unselectedOptionTextColor:enable': {
          title: '#7 Unselected option text color',
          type: 'boolean',
          description:
            '[vettingScreen.unselectedOptionTextColor] - RGBA / RGB / Hex / Color name, Default: vettingScreen.textColor',
        },
        unselectedOptionTextColor: {
          type: 'string',
        },
        'selectedOptionBackgroundColor:enable': {
          title: '#8 Selected option background color',
          type: 'boolean',
          description:
            '[vettingScreen.selectedOptionBackgroundColor] - RGBA / RGB / Hex / Color name, Default: general.buttonColor [Opacity 20%]',
        },
        selectedOptionBackgroundColor: {
          type: 'string',
        },
        'selectedOptionTextAndBorderColor:enable': {
          title: '#9 Selected option text and border color',
          type: 'boolean',
          description:
            '[vettingScreen.selectedOptionTextAndBorderColor] - RGBA / RGB / Hex / Color name, Default: general.buttonColor',
        },
        selectedOptionTextAndBorderColor: {
          type: 'string',
        },
        'warningIconUrl:enable': {
          title: '#10 Warning icon URL',
          type: 'boolean',
          description: '[vettingScreen.warningIconUrl]',
        },
        warningIconUrl: {
          type: 'string',
        },
        'remoteAssistanceButtonTextColor:enable': {
          title: '#11 Remote Assistance button text color',
          type: 'boolean',
          description:
            '[vettingScreen.remoteAssistanceButtonTextColor] - RGBA / RGB / Hex / Color name, Default: general.primaryTextColor',
        },
        remoteAssistanceButtonTextColor: {
          type: 'string',
        },
        'remoteAssistanceButtonBorderColor:enable': {
          title: '#12 Remote Assistance button border color',
          type: 'boolean',
          description:
            '[vettingScreen.remoteAssistanceButtonBorderColor] - RGBA / RGB / Hex / Color name, Default: general.primaryTextColor',
        },
        remoteAssistanceButtonBorderColor: {
          type: 'string',
        },
        'enableRemoteAssistance:enable': {
          title: '#13 Enable remote assistance button',
          type: 'boolean',
          description: '[vettingScreen.enableRemoteAssistance]',
        },
        enableRemoteAssistance: {
          type: undefined,
          enum: [true, false],
        },
        'warningIconColor:enable': {
          title: '#14 Warning icon color',
          type: 'boolean',
          description:
            '[vettingScreen.warningIconColor] - RGBA / RGB / Hex / Color name, Default: general.primaryColor',
        },
        warningIconColor: {
          type: 'string',
        },
        'dividerColor:enable': {
          title: '#15 Divider color',
          type: 'boolean',
          description:
            '[vettingScreen.dividerColor] - RGBA / RGB / Hex / Color name, Default: #000000',
        },
        dividerColor: {
          type: 'string',
        },
      },
    },
    loyaltyScreen: {
      type: 'object',
      title: '#5 Loyalty screen',
      properties: {
        'enabled:enable': {
          title: '#1 Enabled',
          type: 'boolean',
          description: '[loyaltyScreen.enabled]',
        },
        enabled: {
          type: undefined,
          enum: [true, false],
        },
        'loyaltyCards:enable': {
          title: '#2 Loyalty cards',
          type: 'boolean',
          description: '[loyaltyScreen.loyaltyCards]',
        },
        loyaltyCards: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              title: {
                title: 'Title',
                type: 'string',
              },
              description: {
                title: 'Description',
                type: 'string',
              },
              image: {
                title: 'Image',
                type: 'object',
                properties: {
                  uri: {
                    type: 'string',
                  },
                },
              },
            },
          },
        },
        'isNewLoyalty:enable': {
          title: '#3 New Layout Enabled',
          type: 'boolean',
          description: '[loyaltyScreen.isNewLoyalty]',
        },
        isNewLoyalty: {
          type: undefined,
          enum: [true, false],
        },
        'newLoyaltyImage:enable': {
          title: '#4 New loyalty image URL',
          type: 'boolean',
          description: '[loyaltyScreen.newLoyaltyImage]',
        },
        newLoyaltyImage: {
          type: 'string',
        },
        newLoyaltyImageConfig: {
          type: 'object',
          title: '#5 New loyalty image configuration',
          properties: {
            'height:enable': {
              title: '#5.1 Height',
              type: 'boolean',
              description:
                '[loyaltyScreen.newLoyaltyImageConfig.height] - New loyalty image height',
            },
            height: {
              type: 'number',
            },
            'width:enable': {
              title: '#5.2 Width',
              type: 'boolean',
              description: '[loyaltyScreen.newLoyaltyImageConfig.width] - New loyalty image width',
            },
            width: {
              type: 'number',
            },
            'borderRadius:enable': {
              title: '#5.3 Border radius',
              type: 'boolean',
              description:
                '[loyaltyScreen.newLoyaltyImageConfig.borderRadius] - New loyalty image border radius',
            },
            borderRadius: {
              type: 'number',
            },
            'marginTop:enable': {
              title: '#5.4 Margin top',
              type: 'boolean',
              description:
                '[loyaltyScreen.newLoyaltyImageConfig.marginTop] - New loyalty image margin top',
            },
            marginTop: {
              type: 'number',
            },
          },
        },
        joinButton: {
          type: 'object',
          title: '#6 Join button configuration',
          properties: {
            'buttonContentType:enable': {
              title: '#6.1 Button content type',
              type: 'boolean',
              description:
                '[loyaltyScreen.joinButton.buttonContentType] - Join button content type (default to text)',
            },
            buttonContentType: {
              type: undefined,
              enum: ['text', 'icon', 'image'],
            },
            icon: {
              type: 'object',
              title: '#6.2 Icon configuration',
              description: 'This needs to be config if buttonContentType is icon',
              properties: {
                'iconName:enable': {
                  title: '#6.2.1 Icon name',
                  type: 'boolean',
                  description: '[loyaltyScreen.joinButton.icon.iconName]',
                },
                iconName: {
                  type: 'string',
                },
                'iconColor:enable': {
                  title: '#6.2.2 Icon color',
                  type: 'boolean',
                  description:
                    '[loyaltyScreen.joinButton.icon.iconColor] - RGBA / RGB / Hex / Color name',
                },
                iconColor: {
                  type: 'string',
                },
                'iconSize:enable': {
                  title: '#6.2.3 Icon size',
                  type: 'boolean',
                  description: '[loyaltyScreen.joinButton.icon.iconSize]',
                },
                iconSize: {
                  type: 'number',
                },
              },
            },
            image: {
              type: 'object',
              title: '#6.3 Image configuration',
              description: 'This needs to be config if buttonContentType is image',
              properties: {
                'imageUrl:enable': {
                  title: '#6.3.1 Image URL',
                  type: 'boolean',
                  description: '[loyaltyScreen.joinButton.image.imageUrl]',
                },
                imageUrl: {
                  type: 'string',
                },
                'height:enable': {
                  title: '#6.3.2 Image height',
                  type: 'boolean',
                  description: '[loyaltyScreen.joinButton.image.height]',
                },
                height: {
                  type: 'number',
                },
                'width:enable': {
                  title: '#6.3.3 Image width',
                  type: 'boolean',
                  description: '[loyaltyScreen.joinButton.image.width]',
                },
                width: {
                  type: 'number',
                },
              },
            },
            text: {
              type: 'object',
              title: '#6.4 Text configuration',
              description: 'This needs to be config if buttonContentType is text',
              properties: {
                'textColor:enable': {
                  title: '#6.4.1 Text color',
                  type: 'boolean',
                  description: '[loyaltyScreen.joinButton.text.textColor]',
                },
                textColor: {
                  type: 'string',
                },
              },
            },
            'width:enable': {
              title: '#6.5 Join button width',
              type: 'boolean',
              description: '[loyaltyScreen.joinButton.width]',
            },
            width: {
              type: 'number',
            },
            'height:enable': {
              title: '#6.6 Join button height',
              type: 'boolean',
              description: '[loyaltyScreen.joinButton.height]',
            },
            height: {
              type: 'number',
            },
            'backgroundColor:enable': {
              title: '#6.7 Join button background color',
              type: 'boolean',
              description:
                '[loyaltyScreen.joinButton.backgroundColor] - RGBA / RGB / Hex / Color name',
            },
            backgroundColor: {
              type: 'string',
            },
            'borderColor:enable': {
              title: '#6.8 Join button border color',
              type: 'boolean',
              description: '[loyaltyScreen.joinButton.borderColor] - RGBA / RGB / Hex / Color name',
            },
            borderColor: {
              type: 'string',
            },
            'borderRadius:enable': {
              title: '#6.9 Join button border radius',
              type: 'boolean',
              description: '[loyaltyScreen.joinButton.borderRadius]',
            },
            borderRadius: {
              type: 'number',
            },
            'borderWidth:enable': {
              title: '#6.10 Join button border width',
              type: 'boolean',
              description: '[loyaltyScreen.joinButton.borderWidth]',
            },
            borderWidth: {
              type: 'number',
            },
          },
        },
        skipButton: {
          type: 'object',
          title: '#7 Skip button configuration',
          properties: {
            'buttonContentType:enable': {
              title: '#7.1 Button content type',
              type: 'boolean',
              description:
                '[loyaltyScreen.skipButton.buttonContentType] - Skip button content type (default to text)',
            },
            buttonContentType: {
              type: undefined,
              enum: ['text', 'icon', 'image'],
            },
            icon: {
              type: 'object',
              title: '#7.2 Icon configuration',
              description: 'This needs to be config if buttonContentType is icon',
              properties: {
                'iconName:enable': {
                  title: '#7.2.1 Icon name',
                  type: 'boolean',
                  description: '[loyaltyScreen.skipButton.icon.iconName]',
                },
                iconName: {
                  type: 'string',
                },
                'iconColor:enable': {
                  title: '#7.2.2 Icon color',
                  type: 'boolean',
                  description:
                    '[loyaltyScreen.skipButton.icon.iconColor] - RGBA / RGB / Hex / Color name',
                },
                iconColor: {
                  type: 'string',
                },
                'iconSize:enable': {
                  title: '#7.2.3 Icon size',
                  type: 'boolean',
                  description: '[loyaltyScreen.skipButton.icon.iconSize]',
                },
                iconSize: {
                  type: 'number',
                },
              },
            },
            image: {
              type: 'object',
              title: '#7.3 Image configuration',
              description: 'This needs to be config if buttonContentType is image',
              properties: {
                'imageUrl:enable': {
                  title: '#7.3.1 Image URL',
                  type: 'boolean',
                  description: '[loyaltyScreen.skipButton.image.imageUrl]',
                },
                imageUrl: {
                  type: 'string',
                },
                'height:enable': {
                  title: '#7.3.2 Image height',
                  type: 'boolean',
                  description: '[loyaltyScreen.skipButton.image.height]',
                },
                height: {
                  type: 'number',
                },
                'width:enable': {
                  title: '#7.3.3 Image width',
                  type: 'boolean',
                  description: '[loyaltyScreen.skipButton.image.width]',
                },
                width: {
                  type: 'number',
                },
              },
            },
            text: {
              type: 'object',
              title: '#7.4 Text configuration',
              description: 'This needs to be config if buttonContentType is text',
              properties: {
                'textColor:enable': {
                  title: '#7.4.1 Text color',
                  type: 'boolean',
                  description: '[loyaltyScreen.skipButton.text.textColor]',
                },
                textColor: {
                  type: 'string',
                },
              },
            },
            'width:enable': {
              title: '#7.5 Skip button width',
              type: 'boolean',
              description: '[loyaltyScreen.skipButton.width]',
            },
            width: {
              type: 'number',
            },
            'height:enable': {
              title: '#7.6 Skip button height',
              type: 'boolean',
              description: '[loyaltyScreen.skipButton.height]',
            },
            height: {
              type: 'number',
            },
            'backgroundColor:enable': {
              title: '#7.7 Skip button background color',
              type: 'boolean',
              description:
                '[loyaltyScreen.skipButton.backgroundColor] - RGBA / RGB / Hex / Color name',
            },
            backgroundColor: {
              type: 'string',
            },
            'borderColor:enable': {
              title: '#7.8 Skip button border color',
              type: 'boolean',
              description: '[loyaltyScreen.skipButton.borderColor] - RGBA / RGB / Hex / Color name',
            },
            borderColor: {
              type: 'string',
            },
            'borderRadius:enable': {
              title: '#7.9 Skip button border radius',
              type: 'boolean',
              description: '[loyaltyScreen.skipButton.borderRadius]',
            },
            borderRadius: {
              type: 'number',
            },
            'borderWidth:enable': {
              title: '#7.10 Skip button border width',
              type: 'boolean',
              description: '[loyaltyScreen.skipButton.borderWidth]',
            },
            borderWidth: {
              type: 'number',
            },
            'alignment:enable': {
              title: '#7.11 Skip button alignment',
              type: 'boolean',
              description: '[loyaltyScreen.skipButton.alignment]',
            },
            alignment: {
              type: undefined,
              enum: ['center', 'right'],
            },
            'leftPadding:enable': {
              title: '#7.12 Left padding',
              type: 'boolean',
              description: '[loyaltyScreen.skipButton.leftPadding]',
            },
            leftPadding: {
              type: 'number',
            },
            'rightPadding:enable': {
              title: '#7.13 Right padding',
              type: 'boolean',
              description: '[loyaltyScreen.skipButton.rightPadding]',
            },
            rightPadding: {
              type: 'number',
            },
          },
        },
        'shadowColor:enable': {
          title: '#8 Shadow color',
          type: 'boolean',
          description: '[loyaltyScreen.shadowColor] - RGBA / RGB / Hex / Color name',
        },
        shadowColor: {
          type: 'string',
        },
        'cardHeaderColor:enable': {
          title: '#9 Card header color',
          type: 'boolean',
          description: '[loyaltyScreen.cardHeaderColor] - RGBA / RGB / Hex / Color name',
        },
        cardHeaderColor: {
          type: 'string',
        },
        'cardHeaderAlign:enable': {
          title: '#10 Card header alignment',
          type: 'boolean',
          description: '[loyaltyScreen.cardHeaderAlign]',
        },
        cardHeaderAlign: {
          type: undefined,
          enum: ['auto', 'left', 'right', 'center', 'justify'],
        },
        'cardDescriptionAlign:enable': {
          title: '#11 Card description alignment',
          type: 'boolean',
          description: '[loyaltyScreen.cardDescriptionAlign]',
        },
        cardDescriptionAlign: {
          type: undefined,
          enum: ['auto', 'left', 'right', 'center', 'justify'],
        },
        'cardDescriptionColor:enable': {
          title: '#12 Card description color',
          type: 'boolean',
          description: '[loyaltyScreen.cardDescriptionColor] - RGBA / RGB / Hex / Color name',
        },
        cardDescriptionColor: {
          type: 'string',
        },
        header: {
          type: 'object',
          title: '#13 loyalty screen header configuration',
          properties: {
            'color:enable': {
              title: '#13.1 Color',
              type: 'boolean',
              description: '[loyaltyScreen.header.color] - Loyalty screen header text color',
            },
            color: {
              type: 'string',
            },
            'fontSize:enable': {
              title: '#13.2 Font size',
              type: 'boolean',
              description: '[loyaltyScreen.header.fontSize] - Loyalty screen header text font size',
            },
            fontSize: {
              type: 'string',
            },
            'marginTop:enable': {
              title: '#13.3 Margin top',
              type: 'boolean',
              description:
                '[loyaltyScreen.header.marginTop] - Loyalty screen header text margin top',
            },
            marginTop: {
              type: 'string',
            },
          },
        },
        'cardHeaderFontSize:enable': {
          title: '#14 Card header font size',
          type: 'boolean',
          description: '[loyaltyScreen.cardHeaderFontSize]',
        },
        cardHeaderFontSize: {
          type: 'number',
        },
        'cardContentMargin:enable': {
          title: '#15 Card content margin',
          type: 'boolean',
          description: '[loyaltyScreen.cardContentMargin]',
        },
        cardContentMargin: {
          type: 'number',
        },
        'cardDescriptionFontSize:enable': {
          title: '#16 Card desription font size',
          type: 'boolean',
          description: '[loyaltyScreen.cardDescriptionFontSize]',
        },
        cardDescriptionFontSize: {
          type: 'number',
        },
      },
    },
    paymentScreen: {
      type: 'object',
      title: '#6 Payment screen',
      properties: {
        'imageUrl:enable': {
          title: '#1 Image URL',
          type: 'boolean',
          description: '[paymentScreen.imageUrl]',
        },
        imageUrl: {
          type: 'string',
        },
        'defaultUnitTypeImageUrl:enable': {
          title: '#2 Default unit type image URL',
          type: 'boolean',
          description: '[paymentScreen.defaultUnitTypeImageUrl]',
        },
        defaultUnitTypeImageUrl: {
          type: 'string',
        },
        'buttonColor:enable': {
          title: '#3 Button color',
          type: 'boolean',
          description: '[paymentScreen.buttonColor]',
        },
        buttonColor: {
          type: 'string',
        },
        'subtitleTextColor:enable': {
          title: '#4 Subtitle text color on card overlay',
          type: 'boolean',
          description: '[paymentScreen.subtitleTextColor]',
        },
        subtitleTextColor: {
          type: 'string',
        },
        'startTextColor:enable': {
          title: '#5 Start button text color',
          type: 'boolean',
          description: '[paymentScreen.startTextColor]',
        },
        startTextColor: {
          type: 'string',
        },
        'textColor:enable': {
          title: '#6 Text color',
          type: 'boolean',
          description: '[paymentScreen.textColor]',
        },
        textColor: {
          type: 'string',
        },
        'progressBarInactiveColor:enable': {
          title: '#7 Progress bar inactive color',
          type: 'boolean',
          description: '[paymentScreen.progressBarInactiveColor]',
        },
        progressBarInactiveColor: {
          type: 'string',
        },
        'progressBarActiveImageUrl:enable': {
          title: '#8 Progess bar active image URL',
          type: 'boolean',
          description: '[paymentScreen.progressBarActiveImageUrl]',
        },
        progressBarActiveImageUrl: {
          type: 'string',
        },
        'backgroundColor:enable': {
          title: '#9 Background color',
          type: 'boolean',
          description: '[paymentScreen.backgroundColor]',
        },
        backgroundColor: {
          type: 'string',
        },
        'supportI4Go:enable': {
          title: '#10 Turn on I4Go input',
          type: 'boolean',
          description: '[paymentScreen.supportI4Go]',
        },
        supportI4Go: {
          type: undefined,
          enum: [true, false],
        },
        'cardHeaderColor:enable': {
          title: '#11 Card header color',
          type: 'boolean',
          description: '[paymentScreen.cardHeaderColor]',
        },
        cardHeaderColor: {
          type: 'string',
        },
        'supportCards:enable': {
          title: '#12 Supports credit card list',
          type: 'boolean',
          description: '[paymentScreen.supportCards]',
        },
        supportCards: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              name: {
                title: 'Title',
                type: 'string',
              },
              image: {
                title: 'Image',
                type: 'string',
              },
            },
          },
        },
      },
    },
    checkoutScreen: {
      type: 'object',
      title: '#7 Checkout screen',
      properties: {
        'backgroundUrl:enable': {
          title: '#1 Background URL',
          type: 'boolean',
          description: '[checkoutScreen.backgroundUrl]',
        },
        backgroundUrl: {
          type: 'string',
        },
        'headerColor:enable': {
          title: '#2 Header color',
          type: 'boolean',
          description: '[checkoutScreen.headerColor] - RGBA / RGB / Hex / Color name',
        },
        headerColor: {
          type: 'string',
        },
        'borderColor:enable': {
          title: '#3 Border color',
          type: 'boolean',
          description: '[checkoutScreen.borderColor] - RGBA / RGB / Hex / Color name',
        },
        borderColor: {
          type: 'string',
        },
        'textColor:enable': {
          title: '#4 Text color',
          type: 'boolean',
          description: '[checkoutScreen.textColor] - RGBA / RGB / Hex / Color name',
        },
        textColor: {
          type: 'string',
        },
        'calendarIconColor:enable': {
          title: '#5 Calendar icon color',
          type: 'boolean',
          description: '[checkoutScreen.calendarIconColor] - RGBA / RGB / Hex / Color name',
        },
        calendarIconColor: {
          type: 'string',
        },
        'accessGrantCardColor:enable': {
          title: '#6 Access Grant card color',
          type: 'boolean',
          description: '[checkoutScreen.accessGrantCardColor] - RGBA / RGB / Hex / Color name',
        },
        accessGrantCardColor: {
          type: 'string',
        },
        'amountTextColor:enable': {
          title: '#7 Amount text color',
          type: 'boolean',
          description: '[checkoutScreen.amountTextColor] - RGBA / RGB / Hex / Color name',
        },
        amountTextColor: {
          type: 'string',
        },
        'totalTextColor:enable': {
          title: '#8 Total text color',
          type: 'boolean',
          description: '[checkoutScreen.totalTextColor] - RGBA / RGB / Hex / Color name',
        },
        totalTextColor: {
          type: 'string',
        },
        'isHasFolio:enable': {
          title: '#9 Has Folio',
          type: 'boolean',
          description: '[checkoutScreen.isHasFolio]',
        },
        isHasFolio: {
          type: undefined,
          enum: [true, false],
        },
        'visaIconUrl:enable': {
          title: '#10 Visa icon URL',
          type: 'boolean',
          description: '[checkoutScreen.visaIconUrl]',
        },
        visaIconUrl: {
          type: 'string',
        },
        'confirmationTextColor:enable': {
          title: '#11 Confirmation text color',
          type: 'boolean',
          description: '[checkoutScreen.confirmationTextColor] - RGBA / RGB / Hex / Color name',
        },
        confirmationTextColor: {
          type: 'string',
        },
        'confirmationBGColor:enable': {
          title: '#12 Confirmation background color',
          type: 'boolean',
          description: '[checkoutScreen.confirmationBGColor] - RGBA / RGB / Hex / Color name',
        },
        confirmationBGColor: {
          type: 'string',
        },
        'initialBackgroundColor:enable': {
          title: '#13 Initial background color',
          type: 'boolean',
          description: '[checkoutScreen.initialBackgroundColor] - RGBA / RGB / Hex / Color name',
        },
        initialBackgroundColor: {
          type: 'string',
        },
        'folioBackgroundColor:enable': {
          title: '#14 Folio background color',
          type: 'boolean',
          description: '[checkoutScreen.folioBackgroundColor] - RGBA / RGB / Hex / Color name',
        },
        folioBackgroundColor: {
          type: 'string',
        },
        'initialTextColor:enable': {
          title: '#15 Initial text color',
          type: 'boolean',
          description: '[checkoutScreen.initialTextColor] - RGBA / RGB / Hex / Color name',
        },
        initialTextColor: {
          type: 'string',
        },
        'amexIconUrl:enable': {
          title: '#16 Amex icon URL',
          type: 'boolean',
          description: '[checkoutScreen.amexIconUrl]',
        },
        amexIconUrl: {
          type: 'string',
        },
        'discoverIconUrl:enable': {
          title: '#17 Discover icon URL',
          type: 'boolean',
          description: '[checkoutScreen.discoverIconUrl]',
        },
        discoverIconUrl: {
          type: 'string',
        },
        'mastercardIconUrl:enable': {
          title: '#18 Mastercard icon URL',
          type: 'boolean',
          description: '[checkoutScreen.mastercardIconUrl]',
        },
        mastercardIconUrl: {
          type: 'string',
        },
        'dinersIconUrl:enable': {
          title: '#19 Diners icon URL',
          type: 'boolean',
          description: '[checkoutScreen.dinersIconUrl]',
        },
        dinersIconUrl: {
          type: 'string',
        },
        'maestroIconUrl:enable': {
          title: '#20 Maestro icon URL',
          type: 'boolean',
          description: '[checkoutScreen.maestroIconUrl]',
        },
        maestroIconUrl: {
          type: 'string',
        },
        'unionPayIconUrl:enable': {
          title: '#21 Unionpay icon URL',
          type: 'boolean',
          description: '[checkoutScreen.unionPayIconUrl]',
        },
        unionPayIconUrl: {
          type: 'string',
        },
        'jcbIconUrl:enable': {
          title: '#22 JCB icon URL',
          type: 'boolean',
          description: '[checkoutScreen.jcbIconUrl]',
        },
        jcbIconUrl: {
          type: 'string',
        },
        'folioDateFormat:enable': {
          title: '#23 Folio date format',
          type: 'boolean',
          description: '[checkoutScreen.folioDateFormat]',
        },
        folioDateFormat: {
          type: 'string',
        },
      },
    },
    authSelectScreen: {
      type: 'object',
      title: '#8 Auth select screen',
      properties: {
        'displayQrCode:enable': {
          title: '#1 Display QR code',
          type: 'boolean',
          description: '[authSelectScreen.displayQrCode]',
        },
        displayQrCode: {
          type: undefined,
          enum: [true, false],
        },
        'authMethods:enable': {
          title: '#2 Authentication methods for flow',
          type: 'boolean',
          description: '[authSelectScreen.authMethods]',
        },
        authMethods: {
          title: 'Authentication methods for flows',
          type: 'array',
          items: {
            type: 'object',
            properties: {
              flow: {
                title: 'Flow',
                type: 'string',
                enum: ['CHECK_IN', 'CHECK_OUT', 'FOLIO', 'PRINT_ANOTHER_KEYCARD'],
              },
              displayEmailAuth: {
                title: 'Display Email Authentication method',
                type: undefined,
                enum: [true, false],
              },
              displayQrCodeAuth: {
                title: 'Display QR Code Authentication method',
                type: undefined,
                enum: [true, false],
              },
              displayPhoneNumberAuth: {
                title: 'Display Phone Number Authentication method',
                type: undefined,
                enum: [true, false],
              },
              displayIdVerificationAuth: {
                title: 'Display ID Verification Authentication method',
                type: undefined,
                enum: [true, false],
              },
              displayIdAuthenticationAuth: {
                title: 'Display ID Authentication method',
                type: undefined,
                enum: [true, false],
              },
              displayNameVerification: {
                title: 'Display Name Authentication method',
                type: undefined,
                enum: [true, false],
              },
              displayRoomNumberAndLastNameVerification: {
                title: 'Display Room Number Authentication method',
                description: '*This field effects Folio and Check-out flows only',
                type: undefined,
                enum: [true, false],
              },
              displayNameAndDepartureDateAuth: {
                title: 'Display Name and Departure Date Authentication method',
                type: undefined,
                enum: [true, false],
              },
              displayConfirmationCodeAuth: {
                title: 'Display Confirmation Code only Authentication method',
                type: undefined,
                enum: [true, false],
              },
            },
          },
        },
        'displayIdVerificationAuth:enable': {
          title: '#3 Display ID verification auth',
          type: 'boolean',
          description: '[authSelectScreen.displayIdVerificationAuth]',
        },
        displayIdVerificationAuth: {
          type: undefined,
          enum: [true, false],
        },
        'displayIdAuthenticationAuth:enable': {
          title: '#5 Display Id authentication auth',
          type: 'boolean',
          description: '[authSelectScreen.displayIdAuthenticationAuth]',
        },
        displayIdAuthenticationAuth: {
          type: undefined,
          enum: [true, false],
        },
        'displayRoomNumberAndLastNameVerification:enable': {
          title: '#5 Display room number and last name',
          type: 'boolean',
          description:
            'Display room number and last name. (Checkout flow) [authSelectScreen.displayRoomNumberAndLastNameVerification]',
        },
        displayRoomNumberAndLastNameVerification: {
          type: undefined,
          enum: [true, false],
        },
        'nameAuthImageUrl:enable': {
          title: '#6 Name image',
          type: 'boolean',
          description:
            'Shows an image instead of the normal icon for the Name Auth. [authSelectScreen.nameAuthImageUrl]',
        },
        nameAuthImageUrl: {
          type: 'string',
        },
        'roomNumberAuthImageUrl:enable': {
          title: '#7 Room number image',
          type: 'boolean',
          description:
            'Shows an image instead of the normal icon for the room number Auth. [authSelectScreen.roomNumberAuthImageUrl]',
        },
        roomNumberAuthImageUrl: {
          type: 'string',
        },
        'emailAuthImageUrl:enable': {
          title: '#8 Email image',
          type: 'boolean',
          description:
            'Shows an image instead of the normal icon for the email Auth. [authSelectScreen.emailAuthImageUrl]',
        },
        emailAuthImageUrl: {
          type: 'string',
        },
        'phoneNumberAuthImageUrl:enable': {
          title: '#9 Phone number image',
          type: 'boolean',
          description:
            'Shows an image instead of the normal icon for the phone number Auth. [authSelectScreen.phoneNumberAuthImageUrl]',
        },
        phoneNumberAuthImageUrl: {
          type: 'string',
        },
        'idAuthImageUrl:enable': {
          title: '#10 ID image',
          type: 'boolean',
          description:
            'Shows an image instead of the normal icon for the id Auth. [authSelectScreen.idAuthImageUrl]',
        },
        idAuthImageUrl: {
          type: 'string',
        },
        'qrAuthImageUrl:enable': {
          title: '#11 QR code image',
          type: 'boolean',
          description:
            'Shows an image instead of the normal icon for the QR Auth. [authSelectScreen.qrAuthImageUrl]',
        },
        qrAuthImageUrl: {
          type: 'string',
        },
        'mrzAuthImageUrl:enable': {
          title: '#12 MRZ image',
          type: 'boolean',
          description:
            'Shows an image instead of the normal icon for the MRZ Auth. [authSelectScreen.mrzAuthImageUrl]',
        },
        mrzAuthImageUrl: {
          type: 'string',
        },
        'cardBorderColor:enable': {
          title: '#13 Auth cards border color',
          type: 'boolean',
          description: '[authSelectScreen.cardBorderColor] - RGBA / RGB / Hex / Color name',
        },
        cardBorderColor: {
          type: 'string',
        },
        'cardTopBorderColor:enable': {
          title: '#14 Auth top card border color',
          type: 'boolean',
          description: '[authSelectScreen.cardTopBorderColor] - RGBA / RGB / Hex / Color name',
        },
        cardTopBorderColor: {
          type: 'string',
        },
        'headerColor:enable': {
          title: '#15 Color of the header text',
          type: 'boolean',
          description: '[authSelectScreen.headerColor] - RGBA / RGB / Hex / Color name',
        },
        headerColor: {
          type: 'string',
        },
        'iconColor:enable': {
          title: '#16 Color of the icon',
          type: 'boolean',
          description: '[authSelectScreen.iconColor] - RGBA / RGB / Hex / Color name',
        },
        iconColor: {
          type: 'string',
        },
        'authCardTextColor:enable': {
          title: '#17 Color of the card text',
          type: 'boolean',
          description: '[authSelectScreen.authCardTextColor] - RGBA / RGB / Hex / Color name',
        },
        authCardTextColor: {
          type: 'string',
        },
        'authCardBackgroundColor:enable': {
          title: '#18 Background color of the cards',
          type: 'boolean',
          description: '[authSelectScreen.authCardBackgroundColor] - RGBA / RGB / Hex / Color name',
        },
        authCardBackgroundColor: {
          type: 'string',
        },
        'nameAndDepartureDateImageUrl:enable': {
          title: '#19 Name and Departure Date image',
          type: 'boolean',
          description:
            'Shows an image instead of the normal icon for the Name and Departure Date. [authSelectScreen.nameAndDepartureDateImageUrl]',
        },
        nameAndDepartureDateImageUrl: {
          type: 'string',
        },
        'confirmationCodeImageUrl:enable': {
          title: '#20 Confirmation Code only image',
          type: 'boolean',
          description:
            'Shows an image instead of the normal icon for the Confirmation Code only Auth. [authSelectScreen.confirmationCodeImageUrl]',
        },
        confirmationCodeImageUrl: {
          type: 'string',
        },
      },
    },
    requirementsScreen: {
      type: 'object',
      title: '#9 Requirements screen',
      properties: {
        'imageUrl:enable': {
          title: '#1 Image URL',
          type: 'boolean',
          description: '[requirementsScreen.imageUrl]',
        },
        imageUrl: {
          type: 'string',
        },
        'displayQrCode:enable': {
          title: '#2 Display QR code',
          type: 'boolean',
          description: '[requirementsScreen.displayQrCode]',
        },
        displayQrCode: {
          type: undefined,
          enum: [true, false],
        },
        'shadowColor:enable': {
          title: '#3 Cards shadow color',
          type: 'boolean',
          description: '[requirementsScreen.shadowColor] - RGBA / RGB / Hex / Color name',
        },
        shadowColor: {
          type: 'string',
        },
        'cardBorderColor:enable': {
          title: '#4 Cards border color',
          type: 'boolean',
          description: '[requirementsScreen.cardBorderColor] - RGBA / RGB / Hex / Color name',
        },
        cardBorderColor: {
          type: 'string',
        },
        'cardBackgroundColor:enable': {
          title: '#5 Cards background color',
          type: 'boolean',
          description: '[requirementsScreen.cardBackgroundColor] - RGBA / RGB / Hex / Color name',
        },
        cardBackgroundColor: {
          type: 'string',
        },
        'textColor:enable': {
          title: '#6 Text color',
          type: 'boolean',
          description: '[requirementsScreen.textColor] - RGBA / RGB / Hex / Color name',
        },
        textColor: {
          type: 'string',
        },
        'iconColor:enable': {
          title: '#7 Icons color',
          type: 'boolean',
          description: '[requirementsScreen.iconColor] - RGBA / RGB / Hex / Color name',
        },
        iconColor: {
          type: 'string',
        },
        onRequirementBackButton: {
          type: 'object',
          title: '#8 All requirements screen back button behavior',
          properties: {
            'disableOnRequirementBackPopup:enable': {
              title: '#8.1 Disable requirement back button popup behavior',
              type: 'boolean',
              description:
                '[requirementsScreen.onRequirementBackButton.disableOnRequirementBackPopup]',
            },
            disableOnRequirementBackPopup: {
              type: undefined,
              enum: [true, false],
            },
            'enableFromFirstPosition:enable': {
              title: '#8.2 Enable requirement back button popup behavior for first requirement',
              type: 'boolean',
              description: '[requirementsScreen.onRequirementBackButton.enableFromFirstPosition]',
            },
            enableFromFirstPosition: {
              type: undefined,
              enum: [true, false],
            },
          },
        },
        'contactFrontDeskTextColor:enable': {
          title: '#9 Contact front desk text color',
          type: 'boolean',
          description:
            '[requirementsScreen.contactFrontDeskTextColor] - RGBA / RGB / Hex / Color name',
        },
        contactFrontDeskTextColor: {
          type: 'string',
        },
      },
    },
    keyDispensingScreen: {
      type: 'object',
      title: '#10 Key dispensing screen',
      properties: {
        'displayHelp:enable': {
          title: '#1 Display help',
          type: 'boolean',
          description: '[keyDispensingScreen.displayHelp]',
        },
        displayHelp: {
          type: undefined,
          enum: [true, false],
        },
        'helpImageUrl:enable': {
          title: '#2 Help image URL',
          type: 'boolean',
          description: '[keyDispensingScreen.helpImageUrl]',
        },
        helpImageUrl: {
          type: 'string',
        },
        'keyCardImageUrl:enable': {
          title: '#3 Key card image URL',
          type: 'boolean',
          description: '[keyDispensingScreen.keyCardImageUrl]',
        },
        keyCardImageUrl: {
          type: 'string',
        },
        'keyDispensingTimeout:enable': {
          title: '#4 Key dispensing timeout',
          type: 'boolean',
          description: '[keyDispensingScreen.keyDispensingTimeout]',
        },
        keyDispensingTimeout: {
          type: 'integer',
        },
        'countDownPopupTimeout:enable': {
          title: '#5 Countdown popup timeout',
          type: 'boolean',
          description: '[keyDispensingScreen.countDownPopupTimeout]',
        },
        countDownPopupTimeout: {
          type: 'integer',
        },
        'printKeycardErrorTimeout:enable': {
          title: '#6 Print keycard error timeout',
          type: 'boolean',
          description: '[keyDispensingScreen.printKeycardErrorTimeout]',
        },
        printKeycardErrorTimeout: {
          type: 'integer',
        },
        'offerQR:enable': {
          title: '# 7 Offer QR',
          type: 'boolean',
          description: 'Turn on mobile checkin QR [keyDispensingScreen.offerQR]',
        },
        offerQR: {
          type: undefined,
          enum: [true, false],
        },
        'offerNewLayout:enable': {
          title: '# 8 Offer New Screen Layout',
          type: 'boolean',
          description: 'Turn on new layout [keyDispensingScreen.offerNewLayout]',
        },
        offerNewLayout: {
          type: undefined,
          enum: [true, false],
        },
        'offerMode:enable': {
          title: '# 9 Offer Mode',
          type: 'boolean',
          description: 'Select QR code mode [keyDispensingScreen.offerMode]',
        },
        offerMode: {
          type: undefined,
          enum: ['MOBILE_WEB', 'DEEP_LINK'],
        },
        'additionalImageUrl:enable': {
          title: '#10 Additional section image URL',
          type: 'boolean',
          description: '[keyDispensingScreen.additionalImageUrl]',
        },
        additionalImageUrl: {
          type: 'string',
        },
        'displayInfoBelowCard:enable': {
          title: '#11 Display info below card',
          type: 'boolean',
          description:
            'Display room information below keycard UI [keyDispensingScreen.displayInfoBelowCard]',
        },
        displayInfoBelowCard: {
          type: undefined,
          enum: [true, false],
        },
        'hideInfoOnCard:enable': {
          title: '#12 Hide info on card',
          type: 'boolean',
          description: 'Hide room information on keycard UI [keyDispensingScreen.hideInfoOnCard]',
        },
        hideInfoOnCard: {
          type: undefined,
          enum: [true, false],
        },
        'displayRoomNumberOnCard:enable': {
          title: '#13 Display room number on card',
          type: 'boolean',
          description:
            'Display room number info on card on keycard UI (default is true, unless hideInfoOnCard is set to true then the default is false) [keyDispensingScreen.displayRoomNumberOnCard]',
        },
        displayRoomNumberOnCard: {
          type: undefined,
          enum: [true, false],
        },
        'hideLogoOnKeyCard:enable': {
          title: '#14 Hide logo on key card',
          type: 'boolean',
          description:
            'Hides the logo on the keycard image [keyDispensingScreen.hideLogoOnKeyCard]',
        },
        hideLogoOnKeyCard: {
          type: undefined,
          enum: [true, false],
        },
        'textColor:enable': {
          title: '#15 Text color',
          type: 'boolean',
          description: '[keyDispensingScreen.textColor] - RGBA / RGB / Hex / Color name',
        },
        textColor: {
          type: 'string',
        },
        'borderColor:enable': {
          title: '#16 Border color',
          type: 'boolean',
          description: '[keyDispensingScreen.borderColor] - RGBA / RGB / Hex / Color name',
        },
        borderColor: {
          type: 'string',
        },
        'logoImageUrl:enable': {
          title: '#17 Logo image URL',
          type: 'boolean',
          description: '[keyDispensingScreen.logoImageUrl]',
        },
        logoImageUrl: {
          type: 'string',
        },
        'keycardBackgroundColor:enable': {
          title: '#18 Keycard background color',
          type: 'boolean',
          description:
            '[keyDispensingScreen.keycardBackgroundColor] - RGBA / RGB / Hex / Color name',
        },
        keycardBackgroundColor: {
          type: 'string',
        },
        'keycardSleeveBackgroundColor:enable': {
          title: '#19 Keycard sleeve background color',
          type: 'boolean',
          description:
            '[keyDispensingScreen.keycardSleeveBackgroundColor] - RGBA / RGB / Hex / Color name',
        },
        keycardSleeveBackgroundColor: {
          type: 'string',
        },
        'keycardShadowColor:enable': {
          title: '#20 Keycard shadow color',
          type: 'boolean',
          description: '[keyDispensingScreen.keycardShadowColor] - RGBA / RGB / Hex / Color name',
        },
        keycardShadowColor: {
          type: 'string',
        },
        'keycardTextColor:enable': {
          title: '#21 Keycard text color',
          type: 'boolean',
          description: '[keyDispensingScreen.keycardTextColor] - RGBA / RGB / Hex / Color name',
        },
        keycardTextColor: {
          type: 'string',
        },
        'expiresDateFormat:enable': {
          title: '#22 Expires date format',
          type: 'boolean',
          description: '[keyDispensingScreen.expiresDateFormat]',
        },
        expiresDateFormat: {
          type: 'string',
        },
        'doneButtonType:enable': {
          title: '#23 Done button type',
          type: 'boolean',
          description: '[keyDispensingScreen.doneButtonType]',
        },
        doneButtonType: {
          type: 'string',
          enum: ['primary', 'outline', 'text'],
        },
        animatedKeyCard: {
          title: '#24 Animated Key Card Dispensing Flavour related theme and config',
          type: 'object',
          properties: {
            'keyCardDispensingFlavour:enable': {
              title: '#24.1 Show Animated Key Card Dispensing Flavour',
              type: 'boolean',
              description: '[keyDispensingScreen.animatedKeyCard.keyCardDispensingFlavour]',
            },
            keyCardDispensingFlavour: {
              type: 'string',
              enum: ['classic', 'animated'],
            },
            title: {
              title: '#24.2 Animated Key Card Title config',
              type: 'object',
              properties: {
                'textSize:enable': {
                  title: '#24.2.1 Animated Key Card Title Text Size',
                  type: 'boolean',
                  description: '[keyDispensingScreen.animatedKeyCard.title.textSize]',
                },
                textSize: {
                  type: 'number',
                },
                'textColor:enable': {
                  title: '#24.2.2 Animated Keycard Title text color',
                  type: 'boolean',
                  description:
                    '[keyDispensingScreen.animatedKeyCard.title.textColor] - RGBA / RGB / Hex / Color name',
                },
                textColor: {
                  type: 'string',
                },
                'showOptionalTitle:enable': {
                  title: '#24.2.3 Show Optional Title in animated keycard header',
                  type: 'boolean',
                  description: '[keyDispensingScreen.animatedKeyCard.title.showOptionalTitle]',
                },
                showOptionalTitle: {
                  type: undefined,
                  enum: [true, false],
                },
                'showRoomInTitle:enable': {
                  title: '#24.2.4 Show Room number in Title in animated keycard header',
                  type: 'boolean',
                  description: '[keyDispensingScreen.animatedKeyCard.title.showRoomInTitle]',
                },
                showRoomInTitle: {
                  type: undefined,
                  enum: [true, false],
                },
              },
            },
            description: {
              title: '#24.3 Key Card description config',
              type: 'object',
              properties: {
                'textSize:enable': {
                  title: '#24.3.1 Animated Key Card description Text Size',
                  type: 'boolean',
                  description: '[keyDispensingScreen.animatedKeyCard.description.textSize]',
                },
                textSize: {
                  type: 'number',
                },
                'textColor:enable': {
                  title: '#24.3.2 Animated Key Card description Text Color',
                  type: 'boolean',
                  description:
                    '[keyDispensingScreen.animatedKeyCard.description.textColor] - RGBA / RGB / Hex / Color name',
                },
                textColor: {
                  type: 'string',
                },
              },
            },
            footer: {
              title: '#24.4 Animated Key Card Footer config',
              type: 'object',
              properties: {
                'textSize:enable': {
                  title: '#24.4.1 Animated Key Card Footer Text Size',
                  type: 'boolean',
                  description: '[keyDispensingScreen.animatedKeyCard.footer.textSize]',
                },
                textSize: {
                  type: 'number',
                },
                'textColor:enable': {
                  title: '#24.4.2 Animated Key Card Footer Text Color',
                  type: 'boolean',
                  description:
                    '[keyDispensingScreen.animatedKeyCard.footer.textColor] - RGBA / RGB / Hex / Color name',
                },
                textColor: {
                  type: 'string',
                },
              },
            },
            animation: {
              title: '#24.5 Animated Key Card Animation config',
              type: 'object',
              properties: {
                'backgroundColor:enable': {
                  title: '#24.5.1 Animated Key Card Animation Background Color',
                  type: 'boolean',
                  description:
                    '[keyDispensingScreen.animatedKeyCard.animation.backgroundColor] - RGBA / RGB / Hex / Color name',
                },
                backgroundColor: {
                  type: 'string',
                },
                'imageUrl:enable': {
                  title: '#24.5.2 Animated Key Card Animation Image URL',
                  type: 'boolean',
                  description: '[keyDispensingScreen.animatedKeyCard.animation.imageUrl]',
                },
                imageUrl: {
                  type: 'string',
                },
              },
            },
          },
        },
        keyCardPrintSuccess: {
          title: '#25 Key Card Print success related theme and config',
          type: 'object',
          properties: {
            layout: {
              title: '#25.1 Key Card Print success screen layout',
              type: 'object',
              properties: {
                'screenLayoutType:enable': {
                  title: '#25.1.1 Screen Layout Type',
                  type: 'boolean',
                  description: '[keyDispensingScreen.keyCardPrintSuccess.layout.screenLayoutType]',
                },
                screenLayoutType: {
                  type: 'string',
                  enum: ['Normal', 'Grid'],
                },
                'asideLayoutType:enable': {
                  title: '#25.1.2 Aside Layout Type',
                  type: 'boolean',
                  description: '[keyDispensingScreen.keyCardPrintSuccess.layout.asideLayoutType]',
                },
                asideLayoutType: {
                  type: 'string',
                  enum: ['Normal', 'Grid'],
                },
                'sectionLayoutType:enable': {
                  title: '#25.1.3 Section Layout Type',
                  type: 'boolean',
                  description: '[keyDispensingScreen.keyCardPrintSuccess.layout.sectionLayoutType]',
                },
                sectionLayoutType: {
                  type: 'string',
                  enum: ['Normal', 'Grid'],
                },
              },
            },
            header: {
              title: '#25.2 Key Card Print Success Title config',
              type: 'object',
              properties: {
                'textSize:enable': {
                  title: '#25.2.1 Key Card Print Success Title Text Size',
                  type: 'boolean',
                  description: '[keyDispensingScreen.keyCardPrintSuccess.title.textSize]',
                },
                textSize: {
                  type: 'number',
                },
                'textColor:enable': {
                  title: '#25.2.2 Key Card Print Success Title text color',
                  type: 'boolean',
                  description:
                    '[keyDispensingScreen.keyCardPrintSuccess.title.textColor] - RGBA / RGB / Hex / Color name',
                },
                textColor: {
                  type: 'string',
                },
                'iconVisible:enable': {
                  title: '#25.2.3 Key Card Print Success Icon Visible in title',
                  type: 'boolean',
                  description: '[keyDispensingScreen.keyCardPrintSuccess.title.iconVisible]',
                },
                iconVisible: {
                  type: undefined,
                  enum: [true, false],
                },
                'iconUrl:enable': {
                  title: '#25.2.4 Key Card Print Success header Icon Image URL',
                  type: 'boolean',
                  description: '[keyDispensingScreen.keyCardPrintSuccess.title.iconUrl]',
                },
                iconUrl: {
                  type: 'string',
                },
              },
            },
            subHeader: {
              title: '#25.3 Key Card Print Success subHeader config',
              type: 'object',
              properties: {
                'textSize:enable': {
                  title: '#25.3.1 Key Card Print Success SubHeader Text Size',
                  type: 'boolean',
                  description: '[keyDispensingScreen.keyCardPrintSuccess.subHeader.textSize]',
                },
                textSize: {
                  type: 'number',
                },
                'textColor:enable': {
                  title: '#25.3.2 Key Card Print Success SubHeader Text Color',
                  type: 'boolean',
                  description:
                    '[keyDispensingScreen.keyCardPrintSuccess.subHeader.textColor] - RGBA / RGB / Hex / Color name',
                },
                textColor: {
                  type: 'string',
                },
              },
            },
            printExtraKeyCardButton: {
              title: '#25.4 print Extra Key Card Button',
              type: 'object',
              properties: {
                'type:enable': {
                  title: '#25.4.1 Screen Layout Type',
                  type: 'boolean',
                  description:
                    '[keyDispensingScreen.keyCardPrintSuccess.printExtraKeyCardButton.type]',
                },
                type: {
                  type: 'string',
                  enum: ['Normal', 'Grid'],
                },
                'textSize:enable': {
                  title: '#25.4.2 print Extra Key Card Button optional Text Size',
                  type: 'boolean',
                  description:
                    '[keyDispensingScreen.keyCardPrintSuccess.printExtraKeyCardButton.textSize]',
                },
                textSize: {
                  type: 'number',
                },
                'textColor:enable': {
                  title: '#25.4.3 print Extra Key Card Button optional Text Color',
                  type: 'boolean',
                  description:
                    '[keyDispensingScreen.keyCardPrintSuccess.printExtraKeyCardButton.textColor] - RGBA / RGB / Hex / Color name',
                },
                textColor: {
                  type: 'string',
                },
                'buttonTextSize:enable': {
                  title: '#25.4.4 print Extra Key Card Button Text Size',
                  type: 'boolean',
                  description:
                    '[keyDispensingScreen.keyCardPrintSuccess.printExtraKeyCardButton.buttonTextSize]',
                },
                buttonTextSize: {
                  type: 'number',
                },
                'buttonTextColor:enable': {
                  title: '#25.4.5 print Extra Key Card Button Text Color',
                  type: 'boolean',
                  description:
                    '[keyDispensingScreen.keyCardPrintSuccess.printExtraKeyCardButton.buttonTextColor] - RGBA / RGB / Hex / Color name',
                },
                buttonTextColor: {
                  type: 'string',
                },
              },
            },
            asideLayoutGridConfig: {
              title:
                '#25.5 Key Card Print success screen aside layout grid related theme and config',
              type: 'object',
              properties: {
                'textSize:enable': {
                  title:
                    '#25.5.1 Animated Key Card Print success screen aside layout grid Title Text Size',
                  type: 'boolean',
                  description:
                    '[keyDispensingScreen.keyCardPrintSuccess.asideLayoutGridConfig.textSize]',
                },
                textSize: {
                  type: 'number',
                },
                'textColor:enable': {
                  title:
                    '#25.5.2 Animated Key Card Print success screen aside layout grid Title Text Color',
                  type: 'boolean',
                  description:
                    '[keyDispensingScreen.keyCardPrintSuccess.asideLayoutGridConfig.textColor] - RGBA / RGB / Hex / Color name',
                },
                textColor: {
                  type: 'string',
                },
                'topGridType:enable': {
                  title: '#25.5.3 Aside Layout Type Grid Top Section type (Qr/Image)',
                  type: 'boolean',
                  description:
                    '[keyDispensingScreen.keyCardPrintSuccess.asideLayoutGridConfig.topGridType]',
                },
                topGridType: {
                  type: 'string',
                  enum: ['mobileQr', 'webQr', 'image'],
                },
                'topGridImageUrl:enable': {
                  title: '#25.5.4 Aside Layout Type Grid Top Section Image URL',
                  type: 'boolean',
                  description:
                    '[keyDispensingScreen.keyCardPrintSuccess.asideLayoutGridConfig.topGridImageUrl]',
                },
                topGridImageUrl: {
                  type: 'string',
                },
                'bottomGridType:enable': {
                  title: '#25.5.5 Aside Layout Type Grid Bottom Section type (Qr/Image)',
                  type: 'boolean',
                  description:
                    '[keyDispensingScreen.keyCardPrintSuccess.asideLayoutGridConfig.bottomGridType]',
                },
                bottomGridType: {
                  type: 'string',
                  enum: ['mobileQr', 'webQr', 'image'],
                },
                'bottomGridImageUrl:enable': {
                  title: '#25.5.6 Aside Layout Type Grid Bottom Section Image URL',
                  type: 'boolean',
                  description:
                    '[keyDispensingScreen.keyCardPrintSuccess.asideLayoutGridConfig.bottomGridImageUrl]',
                },
                bottomGridImageUrl: {
                  type: 'string',
                },
                'fontWeight:enable': {
                  title:
                    '#25.5.7 Animated Key Card Print success screen aside layout grid Title Font Weight',
                  type: 'boolean',
                  description:
                    '[keyDispensingScreen.keyCardPrintSuccess.asideLayoutGridConfig.fontWeight]',
                },
                fontWeight: {
                  type: undefined,
                  enum: [
                    'normal',
                    'bold',
                    '100',
                    '200',
                    '300',
                    '400',
                    '500',
                    '600',
                    '700',
                    '800',
                    '900',
                  ],
                },
                header: {
                  title: '#25.5.8 Aside Layout Grid Header',
                  type: 'object',
                  properties: {
                    'textSize:enable': {
                      title: '#25.5.8.1 Aside Layout Grid Header Text Size',
                      type: 'boolean',
                      description:
                        '[keyDispensingScreen.keyCardPrintSuccess.asideLayoutGridConfig.header.textSize]',
                    },
                    textSize: {
                      type: 'number',
                    },
                    'textColor:enable': {
                      title: '#25.5.8.2 Aside Layout Grid Header Text Color',
                      type: 'boolean',
                      description:
                        '[keyDispensingScreen.keyCardPrintSuccess.asideLayoutGridConfig.header.textColor] - RGBA / RGB / Hex / Color name',
                    },
                    textColor: {
                      type: 'string',
                    },
                    'fontWeight:enable': {
                      title: '#25.5.8.3 Aside Layout Grid Header Font Weight',
                      type: 'boolean',
                      description:
                        '[keyDispensingScreen.keyCardPrintSuccess.asideLayoutGridConfig.header.fontWeight]',
                    },
                    fontWeight: {
                      type: undefined,
                      enum: [
                        'normal',
                        'bold',
                        '100',
                        '200',
                        '300',
                        '400',
                        '500',
                        '600',
                        '700',
                        '800',
                        '900',
                      ],
                    },
                  },
                },
              },
            },
            roomInfo: {
              title: '#25.6 Room information card config',
              type: 'object',
              properties: {
                card: {
                  title: '#25.6.1 Card container config',
                  type: 'object',
                  properties: {
                    'borderColor:enable': {
                      title: '#25.6.1.1 Border color',
                      type: 'boolean',
                      description:
                        '[keyDispensingScreen.keyCardPrintSuccess.roomInfo.card.borderColor]',
                    },
                    borderColor: {
                      type: 'string',
                    },
                    'borderWidth:enable': {
                      title: '#25.6.1.2 Border width',
                      type: 'boolean',
                      description:
                        '[keyDispensingScreen.keyCardPrintSuccess.roomInfo.card.borderWidth]',
                    },
                    borderWidth: {
                      type: 'number',
                    },
                    'borderRadius:enable': {
                      title: '#25.6.1.3 Border radius',
                      type: 'boolean',
                      description:
                        '[keyDispensingScreen.keyCardPrintSuccess.roomInfo.card.borderRadius]',
                    },
                    borderRadius: {
                      type: 'number',
                    },
                    'backgroundColor:enable': {
                      title: '#25.6.1.4 Background color',
                      type: 'boolean',
                      description:
                        '[keyDispensingScreen.keyCardPrintSuccess.roomInfo.card.backgroundColor]',
                    },
                    backgroundColor: {
                      type: 'string',
                    },
                  },
                },
                roomNumber: {
                  title: '#25.6.2 Room number text config',
                  type: 'object',
                  properties: {
                    'textColor:enable': {
                      title: '#25.6.2.1 Text color',
                      type: 'boolean',
                      description:
                        '[keyDispensingScreen.keyCardPrintSuccess.roomInfo.roomNumber.textColor]',
                    },
                    textColor: {
                      type: 'string',
                    },
                  },
                },
                additionalInfo: {
                  title: '#25.6.3 Additional info text config',
                  type: 'object',
                  properties: {
                    'textColor:enable': {
                      title: '#25.6.3.1 Text color',
                      type: 'boolean',
                      description:
                        '[keyDispensingScreen.keyCardPrintSuccess.roomInfo.additionalInfo.textColor]',
                    },
                    textColor: {
                      type: 'string',
                    },
                  },
                },
              },
            },
          },
        },
        keyCardPrintError: {
          title: '#26 key Card Print Error related theme and config',
          type: 'object',
          properties: {
            header: {
              title: '#26.1 key Card Print Error header related theme and config',
              type: 'object',
              properties: {
                'textSize:enable': {
                  title: '#26.1.1 key Card Print Error header Text Size',
                  type: 'boolean',
                  description: '[keyDispensingScreen.keyCardPrintError.header.textSize]',
                },
                textSize: {
                  type: 'number',
                },
                'textColor:enable': {
                  title: '#26.1.2 key Card Print Error header text color',
                  type: 'boolean',
                  description:
                    '[keyDispensingScreen.keyCardPrintError.header.textColor] - RGBA / RGB / Hex / Color name',
                },
                textColor: {
                  type: 'string',
                },
              },
            },
            description: {
              title: '#26.2 key Card Print Error description related theme and config',
              type: 'object',
              properties: {
                'textSize:enable': {
                  title: '#26.2.1 key Card Print Error description Text Size',
                  type: 'boolean',
                  description: '[keyDispensingScreen.keyCardPrintError.description.textSize]',
                },
                textSize: {
                  type: 'number',
                },
                'textColor:enable': {
                  title: '#26.2.2 key Card Print Error description text color',
                  type: 'boolean',
                  description:
                    '[keyDispensingScreen.keyCardPrintError.description.textColor] - RGBA / RGB / Hex / Color name',
                },
                textColor: {
                  type: 'string',
                },
              },
            },
            message: {
              title: '#26.3 key Card Print Error message related theme and config',
              type: 'object',
              properties: {
                'textSize:enable': {
                  title: '#26.3.1 key Card Print Error message Text Size',
                  type: 'boolean',
                  description: '[keyDispensingScreen.keyCardPrintError.message.textSize]',
                },
                textSize: {
                  type: 'number',
                },
                'textColor:enable': {
                  title: '#26.3.2 key Card Print Error message text color',
                  type: 'boolean',
                  description:
                    '[keyDispensingScreen.keyCardPrintError.message.textColor] - RGBA / RGB / Hex / Color name',
                },
                textColor: {
                  type: 'string',
                },
              },
            },
            'showContinueButton:enable': {
              title: '#26.4 Key Card Print Error show continue button',
              type: 'boolean',
              description: '[keyDispensingScreen.keyCardPrintError.showContinueButton]',
            },
            showContinueButton: {
              type: undefined,
              enum: [true, false],
            },
          },
        },
      },
    },
    idVerificationScreen: {
      type: 'object',
      title: '#11 ID verification screen',
      properties: {
        'visaZoom:enable': {
          title: '#1 Visa zoom',
          type: 'boolean',
          description: '[idVerificationScreen.visaZoom]',
        },
        visaZoom: {
          type: 'number',
        },
        'homeImage:enable': {
          title: '#2 Home image',
          type: 'boolean',
          description: '[idVerificationScreen.homeImage]',
        },
        homeImage: {
          type: 'string',
        },
        'logoImage:enable': {
          title: '#3 Logo image',
          type: 'boolean',
          description: '[idVerificationScreen.logoImage]',
        },
        logoImage: {
          type: 'string',
        },
        'closeImage:enable': {
          title: '#4 Close image',
          type: 'boolean',
          description: '[idVerificationScreen.closeImage]',
        },
        closeImage: {
          type: 'string',
        },
        'idCardZoom:enable': {
          title: '#5 ID card zoom',
          type: 'boolean',
          description: '[idVerificationScreen.idCardZoom]',
        },
        idCardZoom: {
          type: 'number',
        },
        'useScanbot:enable': {
          title: '#6 Use scanbot',
          type: 'boolean',
          description: '[idVerificationScreen.useScanbot]',
        },
        useScanbot: {
          type: undefined,
          enum: [true, false],
        },
        'passportZoom:enable': {
          title: '#7 Passport zoom',
          type: 'boolean',
          description: '[idVerificationScreen.passportZoom]',
        },
        passportZoom: {
          type: 'number',
        },
        'settingImage:enable': {
          title: '#8 Setting image',
          type: 'boolean',
          description: '[idVerificationScreen.settingImage]',
        },
        settingImage: {
          type: 'string',
        },
        'visaImageURL:enable': {
          title: '#9 Visa image URL',
          type: 'boolean',
          description: '[idVerificationScreen.visaImageURL]',
        },
        visaImageURL: {
          type: 'string',
        },
        'idCardImageURL:enable': {
          title: '#10 ID card image URL',
          type: 'boolean',
          description: '[idVerificationScreen.idCardImageURL]',
        },
        idCardImageURL: {
          type: 'string',
        },
        'driverLicenseImageURL:enable': {
          title: '#11 Driver license image URL',
          type: 'boolean',
          description: '[idVerificationScreen.driverLicenseImageURL]',
        },
        driverLicenseImageURL: {
          type: 'string',
        },
        'passportImageURL:enable': {
          title: '#12 Passport image URL',
          type: 'boolean',
          description: '[idVerificationScreen.passportImageURL]',
        },
        passportImageURL: {
          type: 'string',
        },
        'haveFaceCapture:enable': {
          title: '#13 Have face capture',
          type: 'boolean',
          description: '[idVerificationScreen.haveFaceCapture]',
        },
        haveFaceCapture: {
          type: undefined,
          enum: [true, false],
        },
        'primaryImageUrl:enable': {
          title: '#14 Primary image URL',
          type: 'boolean',
          description: '[idVerificationScreen.primaryImageUrl]',
        },
        primaryImageUrl: {
          type: 'string',
        },
        'cardOverlayAlpha:enable': {
          title: '#15 Card overlay alpha',
          type: 'boolean',
          description: '[idVerificationScreen.cardOverlayAlpha]',
        },
        cardOverlayAlpha: {
          type: 'number',
        },
        'cardOverlayImage:enable': {
          title: '#16 Card overlay image',
          type: 'boolean',
          description: '[idVerificationScreen.cardOverlayImage]',
        },
        cardOverlayImage: {
          type: 'string',
        },
        'cropCapturedImage:enable': {
          title: '#17 Crop captured image',
          type: 'boolean',
          description: '[idVerificationScreen.cropCapturedImage]',
        },
        cropCapturedImage: {
          type: undefined,
          enum: [true, false],
        },
        'driverLicenseZoom:enable': {
          title: '#18 Driver license zoom',
          type: 'boolean',
          description: '[idVerificationScreen.driverLicenseZoom]',
        },
        driverLicenseZoom: {
          type: 'number',
        },
        'secondaryImageUrl:enable': {
          title: '#19 Secondary image URL',
          type: 'boolean',
          description: '[idVerificationScreen.secondaryImageUrl]',
        },
        secondaryImageUrl: {
          type: 'string',
        },
        'backInstructionURL:enable': {
          title: '#20 Back instruction URL',
          type: 'boolean',
          description: '[idVerificationScreen.backInstructionURL]',
        },
        backInstructionURL: {
          type: 'string',
        },
        'faceInstructionURL:enable': {
          title: '#21 Face instruction URL',
          type: 'boolean',
          description: '[idVerificationScreen.faceInstructionURL]',
        },
        faceInstructionURL: {
          type: 'string',
        },
        'frontInstructionURL:enable': {
          title: '#22 Front instruction URL',
          type: 'boolean',
          description: '[idVerificationScreen.frontInstructionURL]',
        },
        frontInstructionURL: {
          type: 'string',
        },
        'backImageCompression:enable': {
          title: '#23 Back image compression',
          type: 'boolean',
          description: '[idVerificationScreen.backImageCompression]',
        },
        backImageCompression: {
          type: 'number',
          minimum: 0,
          maximum: 1,
        },
        'frontImageCompression:enable': {
          title: '#24 Front image compression',
          type: 'boolean',
          description: '[idVerificationScreen.frontImageCompression]',
        },
        frontImageCompression: {
          type: 'number',
          minimum: 0,
          maximum: 1,
        },
        'remoteAssistanceImage:enable': {
          title: '#25 Remote assistance image',
          type: 'boolean',
          description: '[idVerificationScreen.remoteAssistanceImage]',
        },
        remoteAssistanceImage: {
          type: 'string',
        },
        'customInstructionImage:enable': {
          title: '#26 Custom instruction image',
          type: 'boolean',
          description: '[idVerificationScreen.customInstructionImage]',
        },
        customInstructionImage: {
          type: 'string',
        },
        'passportInstructionURL:enable': {
          title: '#27 Passport instruction URL',
          type: 'boolean',
          description: '[idVerificationScreen.passportInstructionURL]',
        },
        passportInstructionURL: {
          type: 'string',
        },
        'scanViewLeftPanelBgColor:enable': {
          title: '#28 Scan view left panel bg color',
          type: 'boolean',
          description:
            '[idVerificationScreen.scanViewLeftPanelBgColor] - RGBA / RGB / Hex / Color name',
        },
        scanViewLeftPanelBgColor: {
          type: 'string',
        },
        'passportVerificationImage:enable': {
          title: '#29 Passport verification image',
          type: 'boolean',
          description: '[idVerificationScreen.passportVerificationImage]',
        },
        passportVerificationImage: {
          type: 'string',
        },
        'scanViewRightPanelBgColor:enable': {
          title: '#30 Scan view right panel bg color',
          type: 'boolean',
          description:
            '[idVerificationScreen.scanViewRightPanelBgColor] - RGBA / RGB / Hex / Color name',
        },
        scanViewRightPanelBgColor: {
          type: 'string',
        },
        'customFaceInstructionImage:enable': {
          title: '#31 ID verification screen',
          type: 'boolean',
          description: '[idVerificationScreen.customFaceInstructionImage]',
        },
        customFaceInstructionImage: {
          type: 'string',
        },
        'idVerificationInstructionVideoUrl:enable': {
          title: '#32 ID verification instruction video URL',
          type: 'boolean',
          description: '[idVerificationScreen.idVerificationInstructionVideoUrl]',
        },
        idVerificationInstructionVideoUrl: {
          type: 'string',
        },
        'idCardFrontInstructionURL:enable': {
          title: '#33 ID card front instruction URL',
          type: 'boolean',
          description: '[idVerificationScreen.idCardFrontInstructionURL]',
        },
        idCardFrontInstructionURL: {
          type: 'string',
        },
        'idCardBackInstructionURL:enable': {
          title: '#34 ID card back instruction URL',
          type: 'boolean',
          description: '[idVerificationScreen.idCardBackInstructionURL]',
        },
        idCardBackInstructionURL: {
          type: 'string',
        },
        'driverLicenseFrontInstructionURL:enable': {
          title: '#35 driver license front instruction URL',
          type: 'boolean',
          description: '[idVerificationScreen.driverLicenseFrontInstructionURL]',
        },
        driverLicenseFrontInstructionURL: {
          type: 'string',
        },
        'driverLicenseBackInstructionURL:enable': {
          title: '#36 Driver license back instruction URL',
          type: 'boolean',
          description: '[idVerificationScreen.driverLicenseBackInstructionURL]',
        },
        driverLicenseBackInstructionURL: {
          type: 'string',
        },
        'iconColor:enable': {
          title: '#37 Icon color',
          type: 'boolean',
          description: '[idVerificationScreen.iconColor]',
        },
        iconColor: {
          type: 'string',
        },
        'isInstruction:enable': {
          title: '#38 Display new instruction ui',
          type: 'boolean',
          description: '[idVerificationScreen.isInstruction]',
        },
        isInstruction: {
          type: undefined,
          enum: [true, false],
        },
        'hasAgeVerification:enable': {
          title: '#39 Has age verification',
          type: 'boolean',
          description: '[idVerificationScreen.hasAgeVerification]',
        },
        hasAgeVerification: {
          type: undefined,
          enum: [true, false],
        },
        'ageVerificationImageUrl:enable': {
          title: '#40 Age verification image url',
          type: 'boolean',
          description: '[idVerificationScreen.ageVerificationImageUrl]',
        },
        ageVerificationImageUrl: {
          type: 'string',
        },
        'isCameraMirrored:enable': {
          title: '#41 Is camera mirrored (using prism)',
          type: 'boolean',
          description: '[idVerificationScreen.isCameraMirrored]',
        },
        isCameraMirrored: {
          type: undefined,
          enum: [true, false],
        },
        'displayInitialScreen:enable': {
          title: '#42 Display id verification initial screen',
          type: 'boolean',
          description: '[idVerificationScreen.displayInitialScreen]',
        },
        displayInitialScreen: {
          type: undefined,
          enum: [true, false],
        },
        'timeout:enable': {
          title: '#43 Timeout',
          type: 'boolean',
          description: '[idVerificationScreen.timeout]',
        },
        timeout: {
          type: 'number',
        },
        'privacyPolicyUrl:enable': {
          title: '#44 Privacy policy URL',
          type: 'boolean',
          description: '[idVerificationScreen.privacyPolicyUrl]',
        },
        privacyPolicyUrl: {
          type: 'string',
        },
        'privacyCloseIconUrl:enable': {
          title: '#45 Privacy policy close icon URL',
          type: 'boolean',
          description: '[idVerificationScreen.privacyCloseIconUrl]',
        },
        privacyCloseIconUrl: {
          type: 'string',
        },
        'continuousAutoFocusMode:enable': {
          title: '#46 Enable continuos auto focus for camera',
          type: 'boolean',
          description: '[idVerificationScreen.continuousAutoFocusMode]',
        },
        continuousAutoFocusMode: {
          type: undefined,
          enum: [true, false],
        },
        'enableFaceLiveness:enable': {
          title: '#47 Enable AWS face liveness',
          type: 'boolean',
          description: '[idVerificationScreen.enableFaceLiveness]',
        },
        enableFaceLiveness: {
          type: undefined,
          enum: [true, false],
        },
        'faceCaptureOverlayImage:enable': {
          title: '#48 Face capture overlay image',
          type: 'boolean',
          description: '[idVerificationScreen.faceCaptureOverlayImage]',
        },
        faceCaptureOverlayImage: {
          type: 'string',
        },
        'displayShutterIconButton:enable': {
          title: '#49 Display shutter icon button',
          type: 'boolean',
          description: '[idVerificationScreen.displayShutterIconButton]',
        },
        displayShutterIconButton: {
          type: undefined,
          enum: [true, false],
        },
        'displayShutterLabelButton:enable': {
          title: '#50 Display shutter label button',
          type: 'boolean',
          description: '[idVerificationScreen.displayShutterLabelButton]',
        },
        displayShutterLabelButton: {
          type: undefined,
          enum: [true, false],
        },
        'dropdownBackgroundColor:enable': {
          title: '#51 Dropdown background color',
          type: 'boolean',
          description: '[idVerificationScreen.dropdownBackgroundColor]',
        },
        dropdownBackgroundColor: {
          type: 'string',
        },
        'textInputColor:enable': {
          title: '#52 Text input color',
          type: 'boolean',
          description: '[idVerificationScreen.textInputColor]',
        },
        textInputColor: {
          type: 'string',
        },
      },
    },
    specialRequestScreen: {
      type: 'object',
      title: '#12 Special request screen',
      properties: {
        'enabled:enable': {
          title: '#1 Enabled',
          type: 'boolean',
          description: '[specialRequestScreen.enabled]',
        },
        enabled: {
          type: undefined,
          enum: [true, false],
        },
        'imageUrl:enable': {
          title: '#2 Image URL',
          type: 'boolean',
          description: '[specialRequestScreen.imageUrl]',
        },
        imageUrl: {
          type: 'string',
        },
      },
    },
    userInformationScreen: {
      type: 'object',
      title: '#13 User information screen',
      properties: {
        'isHideCost:enable': {
          title: '#1 Is hide cost',
          type: 'boolean',
          description: '[userInformationScreen.isHideCost]',
        },
        isHideCost: {
          type: undefined,
          enum: [true, false],
        },
        'OTA_EMAILS:enable': {
          title: '#2 OTA emails',
          description: 'OTA Email for domain validations [userInformationScreen.OTA_EMAILS]',
          type: 'boolean',
        },
        OTA_EMAILS: {
          type: 'array',
          items: {
            type: 'string',
          },
        },
        'disableEditUserInfo:enable': {
          title: '#3 Disable edit user info',
          type: 'boolean',
          description: '[userInformationScreen.disableEditUserInfo]',
        },
        disableEditUserInfo: {
          type: undefined,
          enum: [true, false],
        },
        'displayCompanyName:enable': {
          title: '#4 Display company name',
          type: 'boolean',
          description: '[userInformationScreen.displayCompanyName]',
        },
        displayCompanyName: {
          type: undefined,
          enum: [true, false],
        },
        'defaultUnitTypeImageUrl:enable': {
          title: '#5 Default unit type image URL',
          type: 'boolean',
          description: '[userInformationScreen.defaultUnitTypeImageUrl]',
        },
        defaultUnitTypeImageUrl: {
          type: 'string',
        },
        'checkInDateFormat:enable': {
          title: '#6 Check-in date format',
          type: 'boolean',
          description: '[userInformationScreen.checkInDateFormat]',
        },
        checkInDateFormat: {
          type: 'string',
        },
        'checkOutDateFormat:enable': {
          title: '#7 Check-out Date Format',
          type: 'boolean',
          description: '[userInformationScreen.checkOutDateFormat]',
        },
        checkOutDateFormat: {
          type: 'string',
        },
        'showV3Screen:enable': {
          title: '#8 Switch to V3 layout (new layout)',
          type: 'boolean',
          description: '[userInformationScreen.showV3Screen]',
        },
        showV3Screen: {
          type: undefined,
          enum: [true, false],
        },
        'showSecondaryUsers:enable': {
          title: '#9 Show secondary users',
          type: 'boolean',
          description:
            'Display option to show and edit secondary users, this flag only works if the showV3Screen is set to true. [userInformationScreen.showSecondaryUsers]',
        },
        showSecondaryUsers: {
          type: undefined,
          enum: [true, false],
        },
        'hideImageRoomType:enable': {
          title: '#10 Hide image room type',
          type: 'boolean',
          description:
            'Hide the room type displayed on the image [userInformationScreen.hideImageRoomType]',
        },
        hideImageRoomType: {
          type: undefined,
          enum: [true, false],
        },
        'isDynamicEnabled:enable': {
          title: '#11 Turn on dynamic screen',
          type: 'boolean',
          description: 'Enable additional info screen [userInformationScreen.isDynamicEnabled]',
        },
        isDynamicEnabled: {
          type: undefined,
          enum: [true, false],
        },
        'defaultPhoneInputCountry:enable': {
          title: '#12 Default phone input country',
          type: 'boolean',
          description: '[userInformationScreen.defaultPhoneInputCountry]',
        },
        defaultPhoneInputCountry: {
          type: 'string',
        },
        'obfuscateSensitiveData:enable': {
          title: '#13 Obfuscate email and phone number on V3 layout',
          type: 'boolean',
          description: '[userInformationScreen.obfuscateSensitiveData]',
        },
        obfuscateSensitiveData: {
          type: undefined,
          enum: [true, false],
        },
        'enableAddSecondaryUsers:enable': {
          title: '#14 Enable add secondary users button',
          type: 'boolean',
          description: '[userInformationScreen.enableAddSecondaryUsers]',
        },
        enableAddSecondaryUsers: {
          type: undefined,
          enum: [true, false],
        },
        'enableEditSecondaryUsers:enable': {
          title: '#15 Enable edit secondary users',
          type: 'boolean',
          description: '[userInformationScreen.enableEditSecondaryUsers]',
        },
        enableEditSecondaryUsers: {
          type: undefined,
          enum: [true, false],
        },
        'imageUrl:enable': {
          type: 'boolean',
          title: '#16 Image URL',
          description: '[userInformationScreen.imageUrl]',
        },
        imageUrl: {
          type: 'string',
        },
        'shadowColor:enable': {
          type: 'boolean',
          title: '#17 Shadow color',
          description: '[userInformationScreen.shadowColor] - RGBA / RGB / Hex / Color name',
        },
        shadowColor: {
          type: 'string',
        },
        'backgroundColor:enable': {
          type: 'boolean',
          title: '#18 Background color',
          description: '[userInformationScreen.backgroundColor] - RGBA / RGB / Hex / Color name',
        },
        backgroundColor: {
          type: 'string',
        },
        'cardBackgroundColor:enable': {
          type: 'boolean',
          title: '#19 Card background color',
          description:
            '[userInformationScreen.cardBackgroundColor] - RGBA / RGB / Hex / Color name',
        },
        cardBackgroundColor: {
          type: 'string',
        },
        'cardTextColor:enable': {
          type: 'boolean',
          title: '#20 Card text color',
          description: '[userInformationScreen.cardTextColor] - RGBA / RGB / Hex / Color name',
        },
        cardTextColor: {
          type: 'string',
        },
        'enablePrimaryUserEmailPhoneNumberValidation:enable': {
          title: "#21 Enable primary user's email & phone number validation",
          type: 'boolean',
          description: '[userInformationScreen.enablePrimaryUserEmailPhoneNumberValidation]',
        },
        enablePrimaryUserEmailPhoneNumberValidation: {
          type: undefined,
          enum: [true, false],
        },
        editUser: {
          type: 'object',
          title: '#22 Edit User',
          properties: {
            'disableFirstName:enable': {
              title: '#22.1 Disable first name input',
              type: 'boolean',
              description: '[userInformationScreen.editUser.disableFirstName]',
            },
            disableFirstName: {
              type: undefined,
              enum: [true, false],
            },
            'disableLastName:enable': {
              title: '#22.2 Disable last name input',
              type: 'boolean',
              description: '[userInformationScreen.editUser.disableLastName]',
            },
            disableLastName: {
              type: undefined,
              enum: [true, false],
            },
          },
        },
      },
    },
    remoteAssistanceScreen: {
      type: 'object',
      title: '#14 Remote assistance screen',
      properties: {
        'disableVideoButton:enable': {
          title: '#1 Disable video button',
          type: 'boolean',
          description: '[remoteAssistanceScreen.disableVideoButton]',
        },
        disableVideoButton: {
          type: undefined,
          enum: [true, false],
        },
        'disableVoiceButton:enable': {
          title: '#2 Disable voice button',
          type: 'boolean',
          description: '[remoteAssistanceScreen.disableVoiceButton]',
        },
        disableVoiceButton: {
          type: undefined,
          enum: [true, false],
        },
        'remoteAssistanceVoiceCallMode:enable': {
          title: '#3 Remote assistance voice call mode',
          type: 'boolean',
          description:
            'Way to make voice call on Remote Assistance [remoteAssistanceScreen.remoteAssistanceVoiceCallMode]',
        },
        remoteAssistanceVoiceCallMode: {
          type: 'string',
          enum: ['phoneNumber', 'dashboard'],
        },
        'callTimeout:enable': {
          title: '#4 Call timeout',
          type: 'boolean',
          description:
            'Timeout on calling from Kiosk to Dashboard. Minimum value: 10 - Maximum value: 120 - Default value: 60 (seconds). [remoteAssistanceScreen.callTimeout]',
        },
        callTimeout: {
          type: 'number',
          minimum: 10,
          maximum: 120,
        },
        'disableGuestDetailsOnCallTimeout:enable': {
          title: '#5 Disable guest details on call timeout',
          type: 'boolean',
          description:
            'Configuration to decide to display or not the guest details form when a call is made and no one answered. Default: false. When is true, the missed call email will be sent with the default values (no information when the guest is not authenticated and the guest details when authenticated) [remoteAssistanceScreen.disableGuestDetailsOnCallTimeout]',
        },
        disableGuestDetailsOnCallTimeout: {
          type: undefined,
          enum: [true, false],
        },
        'remoteAssistanceVendor:enable': {
          title: '#6 Remote assistance vendor',
          type: 'boolean',
          description:
            'Vendor for remote assistance [remoteAssistanceScreen.remoteAssistanceVendor]',
        },
        remoteAssistanceVendor: {
          type: undefined,
          enum: ['virdee', 'sprinklr'],
        },
      },
    },
    reservationStatusScreen: {
      type: 'object',
      title: '#15 Reservation status screen',
      properties: {
        'imageUrl:enable': {
          title: '#1 Image URL',
          type: 'boolean',
          description: '[reservationStatusScreen.imageUrl]',
        },
        imageUrl: {
          type: 'string',
        },
        'displayImage:enable': {
          title: '#2 Display image',
          type: 'boolean',
          description: '[reservationStatusScreen.displayImage]',
        },
        displayImage: {
          type: undefined,
          enum: [true, false],
        },
        'displayButton:enable': {
          title: '#3 Display button',
          type: 'boolean',
          description: '[reservationStatusScreen.displayButton]',
        },
        displayButton: {
          type: undefined,
          enum: [true, false],
        },
        'showWebQrCode:enable': {
          title: '#4 Show QR Code',
          type: 'boolean',
          description: '[reservationStatusScreen.showWebQrCode]',
        },
        showWebQrCode: {
          type: undefined,
          enum: [true, false],
        },
      },
    },
    selectReservationScreen: {
      type: 'object',
      title: '#16 Select reservation screen',
      properties: {
        'reservationCardFallbackImageUrl:enable': {
          title: '#1 Reservation card fallback image URL',
          type: 'boolean',
          description: '[selectReservationScreen.reservationCardFallbackImageUrl]',
        },
        reservationCardFallbackImageUrl: {
          type: 'string',
        },
        'hideRoomName:enable': {
          title: '#2 Hide room name',
          type: 'boolean',
          description: '[selectReservationScreen.hideRoomName]',
        },
        hideRoomName: {
          type: undefined,
          enum: [true, false],
        },
        'filterReservation:enable': {
          title: '#3 Filter reservation for each flow',
          type: 'boolean',
          description: '[selectReservationScreen.filterReservation]',
        },
        filterReservation: {
          title: 'Filter reservation for flows',
          type: 'array',
          items: {
            type: 'object',
            properties: {
              flow: {
                title: 'Flow',
                type: 'string',
                enum: ['CHECK_IN', 'CHECK_OUT', 'FOLIO', 'PRINT_ANOTHER_KEYCARD'],
              },
              filterTypes: {
                title: 'Filter reservation types',
                type: 'string',
                oneOf: [
                  {
                    const: 'STAY_DATES',
                    title: 'Pre check-in and checked-in reservation (during stay date)',
                  },
                  {
                    const: 'STAY_DATES_IN_HOUSE',
                    title: 'Checked-in reservation (during stay date)',
                  },
                  { const: 'DUE_IN', title: 'Pre check-in reservation (on check in date only)' },
                  { const: 'DUE_OUT', title: 'Checked-in reservation (on check-out date only' },
                ],
              },
            },
          },
        },
        'textColor:enable': {
          title: '#4 Text color',
          type: 'boolean',
          description: '[selectReservationScreen.textColor] - RGBA / RGB / Hex / Color name',
        },
        textColor: {
          type: 'string',
        },
        'checkInDateFormat:enable': {
          title: '#5 Check-in date format',
          type: 'boolean',
          description: '[selectReservationScreen.checkInDateFormat]',
        },
        checkInDateFormat: {
          type: 'string',
        },
        'checkOutDateFormat:enable': {
          title: '#6 Check-out Date Format',
          type: 'boolean',
          description: '[selectReservationScreen.checkOutDateFormat]',
        },
        checkOutDateFormat: {
          type: 'string',
        },
        'headerTextColor:enable': {
          title: '#7 Header text color',
          type: 'boolean',
          description: '[selectReservationScreen.headerTextColor] - RGBA / RGB / Hex / Color name',
        },
        headerTextColor: {
          type: 'string',
        },
        'shadowColor:enable': {
          title: '#8 Shadow color',
          type: 'boolean',
          description: '[selectReservationScreen.shadowColor] - RGBA / RGB / Hex / Color name',
        },
        shadowColor: {
          type: 'string',
        },
        'reservationCardColor:enable': {
          title: '#9 Reservation card color',
          type: 'boolean',
          description:
            '[selectReservationScreen.reservationCardColor] - RGBA / RGB / Hex / Color name',
        },
        reservationCardColor: {
          type: 'string',
        },
      },
    },
    packageScreen: {
      type: 'object',
      title: '#17 Packages screen',
      properties: {
        'isEnabled:enable': {
          title: '#1 Is enabled',
          type: 'boolean',
          description: 'Turn on packages screen [packageScreen.isEnabled]',
        },
        isEnabled: {
          type: undefined,
          enum: [true, false],
        },
        'buttonTextColor:enable': {
          type: 'boolean',
          title: '#2 Button text color',
          description: 'Text of categories section [packageScreen.buttonTextColor]',
        },
        buttonTextColor: {
          type: 'string',
        },
        'headerTextColor:enable': {
          title: '#3 Header text color',
          type: 'boolean',
          description: '[packageScreen.headerTextColor]',
        },
        headerTextColor: {
          type: 'string',
        },
        'headerColor:enable': {
          type: 'boolean',
          title: '#4 HeaderColor',
          description: 'Background of header card(prebook section) [packageScreen.headerColor]',
        },
        headerColor: {
          type: 'string',
        },
        'borderColor:enable': {
          type: 'boolean',
          title: '#5 Border color',
          description: 'Border of carts section [packageScreen.borderColor]',
        },
        borderColor: {
          type: 'string',
        },
        'viewCartTextColor:enable': {
          type: 'boolean',
          title: '#6 View cart text color',
          description: 'Cart button color [packageScreen.viewCartTextColor]',
        },
        viewCartTextColor: {
          type: 'string',
        },
        'cartTextColor:enable': {
          type: 'boolean',
          title: '#7 Card text color',
          description: 'Color of text cart section [packageScreen.cartTextColor]',
        },
        cartTextColor: {
          type: 'string',
        },
        'modalColor:enable': {
          type: 'boolean',
          title: '#8 Modal color',
          description: 'Modal bg color [packageScreen.modalColor]',
        },
        modalColor: {
          type: 'string',
        },
        'subtitleTextColor:enable': {
          type: 'boolean',
          title: '#9 Subtitle text color',
          description: '[packageScreen.subtitleTextColor]',
        },
        subtitleTextColor: {
          type: 'string',
        },
        'prebookText:enable': {
          type: 'boolean',
          title: '#10 Prebook text',
          description:
            'Color of text in package item that is pre booked default is red [packageScreen.prebookText]',
        },
        prebookText: {
          type: 'string',
        },
        'packageCardColor:enable': {
          type: 'boolean',
          title: '#11 Package card color',
          description: 'Background color of packages items card [packageScreen.packageCardColor]',
        },
        packageCardColor: {
          type: 'string',
        },
        'packageTextColor:enable': {
          type: 'boolean',
          title: '#12 Package text color',
          description: 'Text color of package item [packageScreen.packageTextColor]',
        },
        packageTextColor: {
          type: 'string',
        },
        'packageCategoryColor:enable': {
          type: 'boolean',
          title: '#13 Package category color',
          description:
            'Color of category text in package item that is pre booked default is blue [packageScreen.packageCategoryColor]',
        },
        packageCategoryColor: {
          type: 'string',
        },
        'packagePriceColor:enable': {
          type: 'boolean',
          title: '#14 Package price color',
          description:
            'Color of price text in package item that is pre booked default is blue [packageScreen.packagePriceColor]',
        },
        packagePriceColor: {
          type: 'string',
        },
        'submit:enable': {
          type: 'boolean',
          title: '#15 Submit',
          description: 'Color of add to reservation button [packageScreen.submit]',
        },
        submit: {
          type: 'string',
        },
        'hideInactivePackagesPrice:enable': {
          title: '#16 Hide inactive packages prices',
          type: 'boolean',
          description: 'Turn on packages screen [packageScreen.hideInactivePackagesPrice]',
        },
        hideInactivePackagesPrice: {
          type: undefined,
          enum: [true, false],
        },
        'isComparableType:enable': {
          title: '#17 Is Comparable Type',
          type: 'boolean',
          description: 'Turn on comparable packages screen [packageScreen.isComparableType]',
        },
        isComparableType: {
          type: undefined,
          enum: [true, false],
        },
        'displayPrebookedComparable:enable': {
          title: '#17 Display Prebooked Comparable',
          type: 'boolean',
          description:
            'Display preebook comparable packages at packagesScreen screen [packageScreen.displayPrebookedComparable]',
        },
        displayPrebookedComparable: {
          type: undefined,
          enum: [true, false],
        },
        'successIcon:enable': {
          title: '#18 Success icon URL',
          type: 'boolean',
          description:
            'Package successfully add to your reservation icon [packageScreen.successIcon]',
        },
        successIcon: {
          type: 'string',
        },
        'iconBackgroundColor:enable': {
          title: '#19 Icon background color',
          type: 'boolean',
          description: 'Icon background color [packageScreen.iconBackgroundColor]',
        },
        iconBackgroundColor: {
          type: 'string',
        },
        'cartPriceColor:enable': {
          title: '#20 Cart price color',
          type: 'boolean',
          description: 'Cart price text color [packageScreen.cartPriceColor]',
        },
        cartPriceColor: {
          type: 'string',
        },
        'packageButtonColor:enable': {
          title: '#21 Package button color',
          type: 'boolean',
          description: 'Package button color [packageScreen.packageButtonColor]',
        },
        packageButtonColor: {
          type: 'string',
        },
        'buttonColor:enable': {
          title: '#22 Button color',
          type: 'boolean',
          description: 'Button color [packageScreen.buttonColor]',
        },
        buttonColor: {
          type: 'string',
        },
        'loadingImage:enable': {
          title: '#23 Loading image URL',
          type: 'boolean',
          description: 'Loading image URL [packageScreen.loadingImage]',
        },
        loadingImage: {
          type: 'string',
        },
        prebookedText: {
          type: 'object',
          title: '#24 Pre-booked text',
          properties: {
            'textColor:enable': {
              title: '#24.1 Text color',
              type: 'boolean',
              description: '[packageScreen.textColor] - RGBA / RGB / Hex / Color name',
            },
            textColor: {
              type: 'string',
            },
            'fontSize:enable': {
              title: '#24.2 Font size',
              type: 'boolean',
              description: '[packageScreen.fontSize]',
            },
            fontSize: {
              type: 'number',
            },
            'fontWeight:enable': {
              title: '#24.3 Font weight',
              type: 'boolean',
              description: '[packageScreen.fontWeight]',
            },
            fontWeight: {
              type: undefined,
              enum: [
                'normal',
                'bold',
                '100',
                '200',
                '300',
                '400',
                '500',
                '600',
                '700',
                '800',
                '900',
              ],
            },
          },
        },
        'continueButtonBackgroundColor:enable': {
          title: '#25 Continue button background color',
          type: 'boolean',
          description: 'Button color [packageScreen.continueButtonBackgroundColor]',
        },
        continueButtonBackgroundColor: {
          type: 'string',
        },
        'continueButtonTextColor:enable': {
          title: '#26 Continue button text color',
          type: 'boolean',
          description: 'Button color [packageScreen.continueButtonTextColor]',
        },
        continueButtonTextColor: {
          type: 'string',
        },
        'continueButtonType:enable': {
          title: '#27 Continue button type',
          type: 'boolean',
          description: '[packageScreen.continueButtonType]',
        },
        continueButtonType: {
          type: 'string',
          enum: ['primary', 'outline'],
        },
        'viewCartButtonBackgroundColor:enable': {
          title: '#28 View cart button background color',
          type: 'boolean',
          description: 'Button color [packageScreen.viewCartButtonBackgroundColor]',
        },
        viewCartButtonBackgroundColor: {
          type: 'string',
        },
        'viewCartButtonTextColor:enable': {
          title: '#29 View cart button text color',
          type: 'boolean',
          description: 'Button color [packageScreen.viewCartButtonTextColor]',
        },
        viewCartButtonTextColor: {
          type: 'string',
        },
        'viewCartButtonType:enable': {
          title: '#30 View cart button type',
          type: 'boolean',
          description: '[packageScreen.viewCartButtonType]',
        },
        viewCartButtonType: {
          type: 'string',
          enum: ['primary', 'outline'],
        },
      },
    },
    checkInFailScreen: {
      type: 'object',
      title: '#18 Check-in fail screen',
      properties: {
        'displayButton:enable': {
          title: '#1 Show front desk button',
          type: 'boolean',
          description: 'Show front desk button [checkInFailScreen.displayButton]',
        },
        displayButton: {
          type: undefined,
          enum: [true, false],
        },
        'displayEndSessionButton:enable': {
          title: '#2 Display end session button',
          type: 'boolean',
          description: '[checkInFailScreen.displayEndSessionButton]',
        },
        displayEndSessionButton: {
          type: undefined,
          enum: [true, false],
        },
        'iconUrl:enable': {
          description: '[checkInFailScreen.iconUrl]',
          title: '#3 Error icon URL',
          type: 'boolean',
        },
        iconUrl: {
          type: 'string',
        },
        'useNewCheckInFailScreen:enable': {
          description: '[checkInFailScreen.useNewCheckInFailScreen]',
          title: '#4 Use new check-in fail screen',
          type: 'boolean',
        },
        useNewCheckInFailScreen: {
          type: undefined,
          enum: [true, false],
        },
        'headerTextColor:enable': {
          description: '[checkInFailScreen.headerTextColor]',
          title: '#5 Header text color',
          type: 'boolean',
        },
        headerTextColor: {
          type: 'string',
        },
        'titleTextColor:enable': {
          description: '[checkInFailScreen.titleTextColor]',
          title: '#6 Title text color',
          type: 'boolean',
        },
        titleTextColor: {
          type: 'string',
        },
        'warningIconColor:enable': {
          description: '[checkInFailScreen.warningIconColor]',
          title: '#7 Warning icon color',
          type: 'boolean',
        },
        warningIconColor: {
          type: 'string',
        },
      },
    },
    countDownPopup: {
      type: 'object',
      title: '#19 countDownPopup',
      properties: {
        'negativeButtonBackgroundColor:enable': {
          type: 'boolean',
          title: '#1 Negative button background color',
          description: '[countDownPopup.negativeButtonBackgroundColor]',
        },
        negativeButtonBackgroundColor: {
          type: 'string',
        },
        'positiveButtonBackgroundColor:enable': {
          type: 'boolean',
          title: '#2 Positive button background color',
          description: '[countDownPopup.positiveButtonBackgroundColor]',
        },
        positiveButtonBackgroundColor: {
          type: 'string',
        },
        'titleTextColor:enable': {
          type: 'boolean',
          title: '#3 Title text color',
          description: '[countDownPopup.titleTextColor]',
        },
        titleTextColor: {
          type: 'string',
        },
        'timerProgressColor:enable': {
          type: 'boolean',
          title: '#4 Timer progress color',
          description: '[countDownPopup.timerProgressColor]',
        },
        timerProgressColor: {
          type: 'string',
        },
        'timerTextColor:enable': {
          type: 'boolean',
          title: '#5 Timer text color',
          description: '[countDownPopup.timerTextColor]',
        },
        timerTextColor: {
          type: 'string',
        },
        'backgroundColor:enable': {
          type: 'boolean',
          title: '#6 Background color ',
          description: '[countDownPopup.backgroundColor]',
        },
        backgroundColor: {
          type: 'string',
        },
        'negativeButtonTextColor:enable': {
          type: 'boolean',
          title: '#7 Negative button text color',
          description: '[countDownPopup.negativeButtonTextColor]',
        },
        negativeButtonTextColor: {
          type: 'string',
        },
        'positiveButtonTextColor:enable': {
          type: 'boolean',
          title: '#8 Positive button text color',
          description: '[countDownPopup.positiveButtonTextColor]',
        },
        positiveButtonTextColor: {
          type: 'string',
        },
      },
    },
    scanQRCodeScreen: {
      type: 'object',
      title: '#20 Scan QR code screen',
      properties: {
        'useFrontCamera:enable': {
          title: '#1 Use front camera',
          type: 'boolean',
          description: '[scanQRCodeScreen.useFrontCamera]',
        },
        useFrontCamera: {
          type: undefined,
          enum: [true, false],
        },
        'enableFilterByQR:enable': {
          title: '#2 Enable reservation filter by QR Code',
          type: 'boolean',
          description: '[scanQRCodeScreen.enableFilterByQR]',
        },
        enableFilterByQR: {
          type: undefined,
          enum: [true, false],
        },
        'retakeTextColor:enable': {
          title: '#3 Retake button color',
          type: 'boolean',
          description: '[scanQRCodeScreen.retakeTextColor] - RGBA / RGB / Hex / Color name',
        },
        retakeTextColor: {
          type: 'string',
        },
        'textColor:enable': {
          title: '#4 Text color',
          type: 'boolean',
          description: '[scanQRCodeScreen.textColor] - RGBA / RGB / Hex / Color name',
        },
        textColor: {
          type: 'string',
        },
      },
    },
    conciergeScreen: {
      type: 'object',
      title: '#21 Concierge screen',
      properties: {
        'textColor:enable': {
          title: '#1 Text Color',
          type: 'boolean',
          description: '[conciergeScreen.textColor] - RGBA / RGB / Hex / Color name',
        },
        textColor: {
          type: 'string',
        },
        'buttonColor:enable': {
          title: '#2 Button Color',
          type: 'boolean',
          description: '[conciergeScreen.buttonColor] - RGBA / RGB / Hex / Color name',
        },
        buttonColor: {
          type: 'string',
        },
        'shadowColor:enable': {
          title: '#3 Shadow Color',
          type: 'boolean',
          description: '[conciergeScreen.shadowColor] - RGBA / RGB / Hex / Color name',
        },
        shadowColor: {
          type: 'string',
        },
        'buttonTextColor:enable': {
          title: '#4 Button Text Color',
          type: 'boolean',
          description: '[conciergeScreen.buttonTextColor] - RGBA / RGB / Hex / Color name',
        },
        buttonTextColor: {
          type: 'string',
        },
        'backgroundImageUrl:enable': {
          title: '#5 Background Image URL',
          type: 'boolean',
          description: '[conciergeScreen.backgroundImageUrl]',
        },
        backgroundImageUrl: {
          type: 'string',
        },
        'titleTextColor:enable': {
          title: '#6 Title Text Color',
          type: 'boolean',
          description: '[conciergeScreen.titleTextColor] - RGBA / RGB / Hex / Color name',
        },
        titleTextColor: {
          type: 'string',
        },
        'descriptionTextColor:enable': {
          title: '#7 Description Text Color',
          type: 'boolean',
          description: '[conciergeScreen.descriptionTextColor] - RGBA / RGB / Hex / Color name',
        },
        descriptionTextColor: {
          type: 'string',
        },
        'buttonBorderColor:enable': {
          title: '#8 Button Border Color',
          type: 'boolean',
          description: '[conciergeScreen.buttonBorderColor] - RGBA / RGB / Hex / Color name',
        },
        buttonBorderColor: {
          type: 'string',
        },
        'buttonBorderWidth:enable': {
          title: '#9 Button border width',
          type: 'boolean',
          description: '[conciergeScreen.buttonBorderWidth]',
        },
        buttonBorderWidth: {
          type: 'number',
        },
        'popupBackgroundColor:enable': {
          title: '#10 Popup background color',
          type: 'boolean',
          description: '[conciergeScreen.popupBackgroundColor] - RGBA / RGB / Hex / Color name',
        },
        popupBackgroundColor: {
          type: 'string',
        },
        'popupTextColor:enable': {
          title: '#11 Popup text color',
          type: 'boolean',
          description: '[conciergeScreen.popupTextColor] - RGBA / RGB / Hex / Color name',
        },
        popupTextColor: {
          type: 'string',
        },
        'popupButtonColor:enable': {
          title: '#12 Popup button color',
          type: 'boolean',
          description: '[conciergeScreen.popupButtonColor] - RGBA / RGB / Hex / Color name',
        },
        popupButtonColor: {
          type: 'string',
        },
        'buttonFontSize:enable': {
          title: '#13 Button font size',
          type: 'boolean',
          description: '[conciergeScreen.buttonFontSize]',
        },
        buttonFontSize: {
          type: 'number',
        },
        'buttonFontWeight:enable': {
          title: '#14 Button font weight',
          type: 'boolean',
          description: '[conciergeScreen.buttonFontWeight]',
        },
        buttonFontWeight: {
          type: undefined,
          enum: ['normal', 'bold', '100', '200', '300', '400', '500', '600', '700', '800', '900'],
        },
        'logoImageUrl:enable': {
          title: '#15 Logo image URL',
          type: 'boolean',
          description: '[conciergeScreen.logoImageUrl]',
        },
        logoImageUrl: {
          type: 'string',
        },
        'backIconColor:enable': {
          title: '#16 Back icon color',
          type: 'boolean',
          description: '[conciergeScreen.backIconColor] - RGBA / RGB / Hex / Color name',
        },
        backIconColor: {
          type: 'string',
        },
        'descriptionFontSize:enable': {
          title: '#17 Description font size',
          type: 'boolean',
          description: '[conciergeScreen.descriptionFontSize]',
        },
        descriptionFontSize: {
          type: 'number',
        },
        'descriptionFontWeight:enable': {
          title: '#18 Description font weight',
          type: 'boolean',
          description: '[conciergeScreen.descriptionFontWeight]',
        },
        descriptionFontWeight: {
          type: undefined,
          enum: ['normal', 'bold', '100', '200', '300', '400', '500', '600', '700', '800', '900'],
        },
        'titleFontSize:enable': {
          title: '#19 Title font size',
          type: 'boolean',
          description: '[conciergeScreen.titleFontSize]',
        },
        titleFontSize: {
          type: 'number',
        },
        'titleFontWeight:enable': {
          title: '#20 Title font weight',
          type: 'boolean',
          description: '[conciergeScreen.titleFontWeight]',
        },
        titleFontWeight: {
          type: undefined,
          enum: ['normal', 'bold', '100', '200', '300', '400', '500', '600', '700', '800', '900'],
        },
        'popupTitleFontSize:enable': {
          title: '#21 Popup title font size',
          type: 'boolean',
          description: '[conciergeScreen.popupTitleFontSize]',
        },
        popupTitleFontSize: {
          type: 'number',
        },
        'popupTitleFontWeight:enable': {
          title: '#22 Popup title font weight',
          type: 'boolean',
          description: '[conciergeScreen.popupTitleFontWeight]',
        },
        popupTitleFontWeight: {
          type: undefined,
          enum: ['normal', 'bold', '100', '200', '300', '400', '500', '600', '700', '800', '900'],
        },
        'popupDescriptionFontSize:enable': {
          title: '#23 Popup description font size',
          type: 'boolean',
          description: '[conciergeScreen.popupDescriptionFontSize]',
        },
        popupDescriptionFontSize: {
          type: 'number',
        },
        'popupDescriptionFontWeight:enable': {
          title: '#24 Popup description font weight',
          type: 'boolean',
          description: '[conciergeScreen.popupDescriptionFontWeight]',
        },
        popupDescriptionFontWeight: {
          type: undefined,
          enum: ['normal', 'bold', '100', '200', '300', '400', '500', '600', '700', '800', '900'],
        },
        'popupButtonTextColor:enable': {
          title: '#25 Popup button icon color',
          type: 'boolean',
          description: '[conciergeScreen.popupButtonTextColor] - RGBA / RGB / Hex / Color name',
        },
        popupButtonTextColor: {
          type: 'string',
        },
        'popupButtonFontSize:enable': {
          title: '#26 Popup button font size',
          type: 'boolean',
          description: '[conciergeScreen.popupButtonFontSize]',
        },
        popupButtonFontSize: {
          type: 'number',
        },
        'popupButtonFontWeight:enable': {
          title: '#27 Popup button font weight',
          type: 'boolean',
          description: '[conciergeScreen.popupButtonFontWeight]',
        },
        popupButtonFontWeight: {
          type: undefined,
          enum: ['normal', 'bold', '100', '200', '300', '400', '500', '600', '700', '800', '900'],
        },
        'descriptionTextAlignment:enable': {
          title: '#28 Description text alignment',
          type: 'boolean',
          description: '[conciergeScreen.descriptionTextAlignment]',
        },
        descriptionTextAlignment: {
          type: undefined,
          enum: ['auto', 'left', 'right', 'center', 'justify'],
        },
        'titleTextAlignment:enable': {
          title: '#29 Title text alignment',
          type: 'boolean',
          description: '[conciergeScreen.titleTextAlignment]',
        },
        titleTextAlignment: {
          type: undefined,
          enum: ['auto', 'left', 'right', 'center', 'justify'],
        },
        'buttonTextAlignment:enable': {
          title: '#30 Concierge Button text alignment',
          type: 'boolean',
          description: '[conciergeScreen.buttonTextAlignment]',
        },
        buttonTextAlignment: {
          type: undefined,
          enum: ['auto', 'left', 'right', 'center', 'justify'],
        },
        'logoImageAlignment:enable': {
          title: '#31 Logo image alignment',
          type: 'boolean',
          description: '[conciergeScreen.logoImageAlignment]',
        },
        logoImageAlignment: {
          type: undefined,
          enum: ['auto', 'flex-start', 'flex-end', 'center', 'stretch', 'baseline'],
        },
        'categoryButtonTextAlignment:enable': {
          title: '#32 Category button text alignment',
          type: 'boolean',
          description: '[conciergeScreen.categoryButtonTextAlignment]',
        },
        categoryButtonTextAlignment: {
          type: undefined,
          enum: ['auto', 'left', 'right', 'center', 'justify'],
        },
        'hideBackgroundImage:enable': {
          title: '#33 Hide concierge screen background image',
          type: 'boolean',
          description: '[conciergeScreen.hideBackgroundImage]',
        },
        hideBackgroundImage: {
          type: undefined,
          enum: [true, false],
        },
        'hideButtonIcon:enable': {
          title: '#34 Hide button icon',
          type: 'boolean',
          description: '[conciergeScreen.hideButtonIcon]',
        },
        hideButtonIcon: {
          type: undefined,
          enum: [true, false],
        },
        'useV3Screen:enable': {
          title: '#35 Enable V3 screen',
          type: 'boolean',
          description: '[conciergeScreen.useV3Screen]',
        },
        useV3Screen: {
          type: undefined,
          enum: [true, false],
        },
        'cardBackgroundColor:enable': {
          title: '#36 Cards background color',
          type: 'boolean',
          description: '[conciergeScreen.cardBackgroundColor] - RGBA / RGB / Hex / Color name',
        },
        cardBackgroundColor: {
          type: 'string',
        },
        'popupCloseIcon:enable': {
          title: '#37 Popup close icon',
          type: 'boolean',
          description: '[conciergeScreen.popupCloseIcon]',
        },
        popupCloseIcon: {
          type: 'string',
        },
        'cardTextColor:enable': {
          title: '#38 Card text color',
          type: 'boolean',
          description: '[conciergeScreen.cardTextColor] - RGBA / RGB / Hex / Color name',
        },
        cardTextColor: {
          type: 'string',
        },
        'categoryBackgroundColor:enable': {
          title: '#39 Category tab background color',
          type: 'boolean',
          description: '[conciergeScreen.categoryBackgroundColor] - RGBA / RGB / Hex / Color name',
        },
        categoryBackgroundColor: {
          type: 'string',
        },
        'categoryTextColor:enable': {
          title: '#40 Category tab text color',
          type: 'boolean',
          description: '[conciergeScreen.categoryTextColor] - RGBA / RGB / Hex / Color name',
        },
        categoryTextColor: {
          type: 'string',
        },
        'selectedCategoryBackgroundColor:enable': {
          title: '#41 Selected category tab background color',
          type: 'boolean',
          description:
            '[conciergeScreen.selectedCategoryBackgroundColor] - RGBA / RGB / Hex / Color name',
        },
        selectedCategoryBackgroundColor: {
          type: 'string',
        },
        'selectedCategoryTextColor:enable': {
          title: '#42 Selected category tab text color',
          type: 'boolean',
          description:
            '[conciergeScreen.selectedCategoryTextColor] - RGBA / RGB / Hex / Color name',
        },
        selectedCategoryTextColor: {
          type: 'string',
        },
        'conciergeHomeImage:enable': {
          title: '#43 Conciege home image',
          type: 'boolean',
          description: '[conciergeScreen.conciergeHomeImage]',
        },
        conciergeHomeImage: {
          type: 'string',
        },
        'backgroundColor:enable': {
          title: '#44 Background color of concierge screens',
          type: 'boolean',
          description: '[conciergeScreen.backgroundColor] - RGBA / RGB / Hex / Color name',
        },
        backgroundColor: {
          type: 'string',
        },
        'cardMediaBackgroundColor:enable': {
          title: '#45 Concierge media card background color',
          type: 'boolean',
          description: '[conciergeScreen.cardMediaBackgroundColor]',
        },
        cardMediaBackgroundColor: {
          type: 'string',
        },
        'hideAllCategory:enable': {
          title: '#46 Hide all category',
          type: 'boolean',
          description: '[conciergeScreen.hideAllCategory]',
        },
        hideAllCategory: {
          type: undefined,
          enum: [true, false],
        },
        'orderAlphabetically:enable': {
          title: '#47 Order alphabetically',
          type: 'boolean',
          description: '[conciergeScreen.orderAlphabetically]',
        },
        orderAlphabetically: {
          type: undefined,
          enum: [true, false],
        },
        'thumbnailReziseMode:enable': {
          title: '#48 Thumbnail resize mode',
          type: 'boolean',
          description: '[conciergeScreen.thumbnailReziseMode]',
        },
        thumbnailReziseMode: {
          type: undefined,
          enum: ['cover', 'contain', 'stretch', 'center'],
        },
        'cardNextIconUrl:enable': {
          title: '#49 Card next icon URL',
          type: 'boolean',
          description: '[conciergeScreen.cardNextIconUrl]',
        },
        cardNextIconUrl: {
          type: 'string',
        },
        'cartTextAlignment:enable': {
          title: '#50 Cart text alignment',
          type: 'boolean',
          description: '[conciergeScreen.cartTextAlignment]',
        },
        cartTextAlignment: {
          type: undefined,
          enum: ['flex-start', 'flex-end', 'center', 'stretch', 'baseline'],
        },
        'categoryThumbnailReziseMode:enable': {
          title: '#51 Category thumbnail resize mode',
          type: 'boolean',
          description: '[conciergeScreen.categoryThumbnailReziseMode]',
        },
        categoryThumbnailReziseMode: {
          type: undefined,
          enum: ['cover', 'contain', 'stretch', 'center'],
        },
      },
    },
    mrzIdVerificationScreen: {
      type: 'object',
      title: '#22 MRZ ID Verification screen',
      properties: {
        'instructionImageUrl:enable': {
          title: '#1 Instruction image url',
          type: 'boolean',
          description: '[mrzIdVerificationScreen.instructionImageUrl]',
        },
        instructionImageUrl: {
          type: 'string',
        },
        'instructionImageUrl2:enable': {
          title: '#2 Instruction image url 2',
          type: 'boolean',
          description: '[mrzIdVerificationScreen.instructionImageUrl2]',
        },
        instructionImageUrl2: {
          type: 'string',
        },
        'zoom:enable': {
          title: '#3 Camera zoom',
          type: 'boolean',
          description: '[mrzIdVerificationScreen.zoom]',
        },
        zoom: {
          type: 'number',
        },
        'isCameraMirrored:enable': {
          title: '#4 Is camera mirrored',
          type: 'boolean',
          description: '[mrzIdVerificationScreen.isCameraMirrored]',
        },
        isCameraMirrored: {
          type: undefined,
          enum: [true, false],
        },
        'scanViewLeftPanelBgColor:enable': {
          title: '#5 Scan view left panel background color',
          type: 'boolean',
          description:
            '[mrzIdVerificationScreen.scanViewLeftPanelBgColor] - RGBA / RGB / Hex / Color name',
        },
        scanViewLeftPanelBgColor: {
          type: 'string',
        },
        'scanViewRightPanelBgColor:enable': {
          title: '#6 Scan view right panel background color',
          type: 'boolean',
          description:
            '[mrzIdVerificationScreen.scanViewRightPanelBgColor] - RGBA / RGB / Hex / Color name',
        },
        scanViewRightPanelBgColor: {
          type: 'string',
        },
        'scanViewLeftPanelBorderColor:enable': {
          title: '#7 Scan view left panel border color',
          type: 'boolean',
          description:
            '[mrzIdVerificationScreen.scanViewLeftPanelBorderColor] - RGBA / RGB / Hex / Color name',
        },
        scanViewLeftPanelBorderColor: {
          type: 'string',
        },
        'scanViewRightPanelBorderColor:enable': {
          title: '#8 Scan view right panel border color',
          type: 'boolean',
          description:
            '[mrzIdVerificationScreen.scanViewRightPanelBorderColor] - RGBA / RGB / Hex / Color name',
        },
        scanViewRightPanelBorderColor: {
          type: 'string',
        },
        'logoImage:enable': {
          title: '#9 Logo image url',
          type: 'boolean',
          description: '[mrzIdVerificationScreen.logoImage]',
        },
        logoImage: {
          type: 'string',
        },
        'homeImage:enable': {
          title: '#10 Home image url',
          type: 'boolean',
          description: '[mrzIdVerificationScreen.homeImage]',
        },
        homeImage: {
          type: 'string',
        },
        'settingImage:enable': {
          title: '#11 Setting image url',
          type: 'boolean',
          description: '[mrzIdVerificationScreen.settingImage]',
        },
        settingImage: {
          type: 'string',
        },
        'mrzIdVerificationInstructionVideoUrl:enable': {
          title: '#12 MRZ ID Verification instruction video url',
          type: 'boolean',
          description: '[mrzIdVerificationScreen.mrzIdVerificationInstructionVideoUrl]',
        },
        mrzIdVerificationInstructionVideoUrl: {
          type: 'string',
        },
        'isInstruction:enable': {
          title: '#13 Is instruction',
          type: 'boolean',
          description: '[mrzIdVerificationScreen.isInstruction]',
        },
        isInstruction: {
          type: undefined,
          enum: [true, false],
        },
        'hasAgeVerification:enable': {
          title: '#14 Has age verification',
          type: 'boolean',
          description: '[mrzIdVerificationScreen.hasAgeVerification]',
        },
        hasAgeVerification: {
          type: undefined,
          enum: [true, false],
        },
        'ageVerificationImageUrl:enable': {
          title: '#15 Age verification image url',
          type: 'boolean',
          description: '[mrzIdVerificationScreen.ageVerificationImageUrl]',
        },
        ageVerificationImageUrl: {
          type: 'string',
        },
        'primaryImageUrl:enable': {
          title: '#16 Primary image url',
          type: 'boolean',
          description: '[mrzIdVerificationScreen.primaryImageUrl]',
        },
        primaryImageUrl: {
          type: 'string',
        },
        'secondaryImageUrl:enable': {
          title: '#17 Secondary image url',
          type: 'boolean',
          description: '[mrzIdVerificationScreen.secondaryImageUrl]',
        },
        secondaryImageUrl: {
          type: 'string',
        },
        'infoIconUrl:enable': {
          title: '#18 Info icon url',
          type: 'boolean',
          description: '[mrzIdVerificationScreen.infoIconUrl]',
        },
        infoIconUrl: {
          type: 'string',
        },
        'popupBackgroundColor:enable': {
          title: '#19 Popup background color',
          type: 'boolean',
          description:
            '[mrzIdVerificationScreen.popupBackgroundColor] - RGBA / RGB / Hex / Color name',
        },
        popupBackgroundColor: {
          type: 'string',
        },
        'popupTextColor:enable': {
          title: '#20 Popup text color',
          type: 'boolean',
          description: '[mrzIdVerificationScreen.popupTextColor] - RGBA / RGB / Hex / Color name',
        },
        popupTextColor: {
          type: 'string',
        },
        'popupTitleFontSize:enable': {
          title: '#21 Popu title font size',
          type: 'boolean',
          description: '[mrzIdVerificationScreen.popupTitleFontSize]',
        },
        popupTitleFontSize: {
          type: 'number',
        },
        'popupTitleFontWeight:enable': {
          title: '#22 Popup title font weight',
          type: 'boolean',
          description: '[mrzIdVerificationScreen.popupTitleFontWeight]',
        },
        popupTitleFontWeight: {
          type: undefined,
          enum: ['normal', 'bold', '100', '200', '300', '400', '500', '600', '700', '800', '900'],
        },
        'popupDescriptionFontSize:enable': {
          title: '#23 Popu description font size',
          type: 'boolean',
          description: '[mrzIdVerificationScreen.popupDescriptionFontSize]',
        },
        popupDescriptionFontSize: {
          type: 'number',
        },
        'popupDescriptionFontWeight:enable': {
          title: '#24 Popup description font weight',
          type: 'boolean',
          description: '[mrzIdVerificationScreen.popupDescriptionFontWeight]',
        },
        popupDescriptionFontWeight: {
          type: undefined,
          enum: ['normal', 'bold', '100', '200', '300', '400', '500', '600', '700', '800', '900'],
        },
        'popupButtonTextColor:enable': {
          title: '#25 Popup button text color',
          type: 'boolean',
          description:
            '[mrzIdVerificationScreen.popupButtonTextColor] - RGBA / RGB / Hex / Color name',
        },
        popupButtonTextColor: {
          type: 'string',
        },
        'popupButtonFontSize:enable': {
          title: '#26 Popu button font size',
          type: 'boolean',
          description: '[mrzIdVerificationScreen.popupButtonFontSize]',
        },
        popupButtonFontSize: {
          type: 'number',
        },
        'popupButtonFontWeight:enable': {
          title: '#27 Popup button font weight',
          type: 'boolean',
          description: '[mrzIdVerificationScreen.popupButtonFontWeight]',
        },
        popupButtonFontWeight: {
          type: undefined,
          enum: ['normal', 'bold', '100', '200', '300', '400', '500', '600', '700', '800', '900'],
        },
        'instructionVideoUrl:enable': {
          title: '#28 Instruction video url',
          type: 'boolean',
          description: '[mrzIdVerificationScreen.instructionVideoUrl]',
        },
        instructionVideoUrl: {
          type: 'string',
        },
        'instructionGifUrl:enable': {
          title: '#29 Instruction GIF url',
          type: 'boolean',
          description: '[mrzIdVerificationScreen.instructionGifUrl]',
        },
        instructionGifUrl: {
          type: 'string',
        },
        'shouldDisplayBackAlert:enable': {
          title: '#30 Should display back alert',
          type: 'boolean',
          description: '[mrzIdVerificationScreen.shouldDisplayBackAlert]',
        },
        shouldDisplayBackAlert: {
          type: undefined,
          enum: [true, false],
        },
        'instructionTextColor:enable': {
          title: '#31 Instruction text color',
          type: 'boolean',
          description: '[mrzIdVerificationScreen.instructionTextColor] - Hex',
        },
        instructionTextColor: {
          type: 'string',
        },
        'instructionTextFontSize:enable': {
          title: '#32 Instruction text font size',
          type: 'boolean',
          description: '[mrzIdVerificationScreen.instructionTextFontSize]',
        },
        instructionTextFontSize: {
          type: 'number',
        },
        'instructionTextFontWight:enable': {
          title: '#33 Instruction text font weight',
          type: 'boolean',
          description: '[mrzIdVerificationScreen.instructionTextFontWight]',
        },
        instructionTextFontWight: {
          type: undefined,
          enum: ['normal', 'bold', '100', '200', '300', '400', '500', '600', '700', '800', '900'],
        },
        'instructionTextAlignment:enable': {
          title: '#34 Instruction text alignment',
          type: 'boolean',
          description: '[mrzIdVerificationScreen.instructionTextAlignment]',
        },
        instructionTextAlignment: {
          type: undefined,
          enum: ['auto', 'left', 'right', 'center', 'justify'],
        },
        'instructionText2Color:enable': {
          title: '#35 Instruction text 2 color',
          type: 'boolean',
          description: '[mrzIdVerificationScreen.instructionText2Color] - Hex',
        },
        instructionText2Color: {
          type: 'string',
        },
        'instructionText2FontSize:enable': {
          title: '#36 Instruction text 2 font size',
          type: 'boolean',
          description: '[mrzIdVerificationScreen.instructionText2FontSize]',
        },
        instructionText2FontSize: {
          type: 'number',
        },
        'instructionText2FontWight:enable': {
          title: '#37 Instruction text 2 font weight',
          type: 'boolean',
          description: '[mrzIdVerificationScreen.instructionText2FontWight]',
        },
        instructionText2FontWight: {
          type: undefined,
          enum: ['normal', 'bold', '100', '200', '300', '400', '500', '600', '700', '800', '900'],
        },
        'instructionText2Alignment:enable': {
          title: '#38 Instruction text 2 alignment',
          type: 'boolean',
          description: '[mrzIdVerificationScreen.instructionText2Alignment]',
        },
        instructionText2Alignment: {
          type: undefined,
          enum: ['auto', 'left', 'right', 'center', 'justify'],
        },
        'scanInstructionTextColor:enable': {
          title: '#39 Scan instruction text color',
          type: 'boolean',
          description: '[mrzIdVerificationScreen.scanInstructionTextColor] - Hex',
        },
        scanInstructionTextColor: {
          type: 'string',
        },
        'scanInstructionTextFontSize:enable': {
          title: '#40 Scan instruction text font size',
          type: 'boolean',
          description: '[mrzIdVerificationScreen.scanInstructionTextFontSize]',
        },
        scanInstructionTextFontSize: {
          type: 'number',
        },
        'scanInstructionTextFontWight:enable': {
          title: '#41 Scan instruction text font weight',
          type: 'boolean',
          description: '[mrzIdVerificationScreen.scanInstructionTextFontWight]',
        },
        scanInstructionTextFontWight: {
          type: undefined,
          enum: ['normal', 'bold', '100', '200', '300', '400', '500', '600', '700', '800', '900'],
        },
        'scanInstructionTextAlignment:enable': {
          title: '#42 Scan instruction text alignment',
          type: 'boolean',
          description: '[mrzIdVerificationScreen.scanInstructionTextAlignment]',
        },
        scanInstructionTextAlignment: {
          type: undefined,
          enum: ['auto', 'left', 'right', 'center', 'justify'],
        },
        'headerTextColor:enable': {
          title: '#43 Header text color',
          type: 'boolean',
          description: '[mrzIdVerificationScreen.headerTextColor] - Hex',
        },
        headerTextColor: {
          type: 'string',
        },
        'headerTextFontSize:enable': {
          title: '#44 Header text font size',
          type: 'boolean',
          description: '[mrzIdVerificationScreen.headerTextFontSize]',
        },
        headerTextFontSize: {
          type: 'number',
        },
        'headerTextFontWight:enable': {
          title: '#45 Header text font weight',
          type: 'boolean',
          description: '[mrzIdVerificationScreen.headerTextFontWight]',
        },
        headerTextFontWight: {
          type: undefined,
          enum: ['normal', 'bold', '100', '200', '300', '400', '500', '600', '700', '800', '900'],
        },
        'headerTextAlignment:enable': {
          title: '#46 Header text alignment',
          type: 'boolean',
          description: '[mrzIdVerificationScreen.headerTextAlignment]',
        },
        headerTextAlignment: {
          type: undefined,
          enum: ['auto', 'left', 'right', 'center', 'justify'],
        },
        'cameraTextColor:enable': {
          title: '#47 Camera text color',
          type: 'boolean',
          description: '[mrzIdVerificationScreen.cameraTextColor] - Hex',
        },
        cameraTextColor: {
          type: 'string',
        },
        'cameraTextFontSize:enable': {
          title: '#48 Camera text font size',
          type: 'boolean',
          description: '[mrzIdVerificationScreen.cameraTextFontSize]',
        },
        cameraTextFontSize: {
          type: 'number',
        },
        'cameraTextFontWight:enable': {
          title: '#49 Camera text font weight',
          type: 'boolean',
          description: '[mrzIdVerificationScreen.cameraTextFontWight]',
        },
        cameraTextFontWight: {
          type: undefined,
          enum: ['normal', 'bold', '100', '200', '300', '400', '500', '600', '700', '800', '900'],
        },
        'cameraTextAlignment:enable': {
          title: '#50 Camera text alignment',
          type: 'boolean',
          description: '[mrzIdVerificationScreen.cameraTextAlignment]',
        },
        cameraTextAlignment: {
          type: undefined,
          enum: ['auto', 'left', 'right', 'center', 'justify'],
        },
        'remoteAssistanceImage:enable': {
          title: '#51 Remote Assistance Image',
          type: 'boolean',
          description: '[mrzIdVerificationScreen.remoteAssistanceImage]',
        },
        remoteAssistanceImage: {
          type: 'string',
        },
        'helpButtonColor:enable': {
          title: '#52 Help button background color',
          type: 'boolean',
          description: '[mrzIdVerificationScreen.helpButtonColor]',
        },
        helpButtonColor: {
          type: 'string',
        },
        'helpButtonTextColor:enable': {
          title: '#53 Help button text color',
          type: 'boolean',
          description: '[mrzIdVerificationScreen.helpButtonTextColor]',
        },
        helpButtonTextColor: {
          type: 'string',
        },
        'instructionContainerBackgroundColor:enable': {
          title: '#54 Instruction container background color',
          type: 'boolean',
          description: '[mrzIdVerificationScreen.instructionContainerBackgroundColor]',
        },
        instructionContainerBackgroundColor: {
          type: 'string',
        },
      },
    },
    signatureScreen: {
      type: 'object',
      title: '#23 Signature screen',
      properties: {
        'useNewLayout:enable': {
          title: '#1 Use new layout signature screen',
          type: 'boolean',
          description: '[signatureScreen.useNewLayout]',
        },
        useNewLayout: {
          type: undefined,
          enum: [true, false],
        },
        'tncCloseButtonColor:enable': {
          title: '#2 Color of close t&c button',
          type: 'boolean',
          description: '[signatureScreen.tncCloseButtonColor]',
        },
        tncCloseButtonColor: {
          type: 'string',
        },
        'tncCloseButtonTextColor:enable': {
          title: '#3 Text color of close button in signature screen',
          type: 'boolean',
          description: '[signatureScreen.tncCloseButtonTextColor]',
        },
        tncCloseButtonTextColor: {
          type: 'string',
        },
        'titleColor:enable': {
          title: '#5 Text color title of each section',
          type: 'boolean',
          description: '[signatureScreen.titleColor]',
        },
        titleColor: {
          type: 'string',
        },
        'subtitleColor:enable': {
          title: '#6 Text color subtitle of each section',
          type: 'boolean',
          description: '[signatureScreen.subtitleColor]',
        },
        subtitleColor: {
          type: 'string',
        },
        'textColor:enable': {
          title: '#7 Agreement text color',
          type: 'boolean',
          description: '[signatureScreen.textColor]',
        },
        textColor: {
          type: 'string',
        },
        'enableRegCard:enable': {
          title: '#8 Enable reg card capability',
          type: 'boolean',
          description: '[signatureScreen.enableRegCard]',
        },
        enableRegCard: {
          type: undefined,
          enum: [true, false],
        },
        'useSignatureWithUserInfoLayout:enable': {
          title: '#9 Enable signature with user information layout',
          type: 'boolean',
          description: '[signatureScreen.useSignatureWithUserInfoLayout]',
        },
        useSignatureWithUserInfoLayout: {
          type: undefined,
          enum: [true, false],
        },
        'enableTermsAndCondition:enable': {
          title: '#10 Enable terms and conditions capability (default to be enable)',
          type: 'boolean',
          description: '[signatureScreen.enableTermsAndCondition]',
        },
        enableTermsAndCondition: {
          type: undefined,
          enum: [true, false],
        },
        'errorTextColor:enable': {
          title: '#11 Error text color',
          type: 'boolean',
          description: '[signatureScreen.errorTextColor]',
        },
        errorTextColor: {
          type: 'string',
        },
        'errorBorderColor:enable': {
          title: '#12 Error border color',
          type: 'boolean',
          description: '[signatureScreen.errorBorderColor]',
        },
        errorBorderColor: {
          type: 'string',
        },
        'clearButtonType:enable': {
          title: '#13 Clear button type',
          type: 'boolean',
          description: '[signatureScreen.clearButtonType]',
        },
        clearButtonType: {
          type: 'string',
          enum: ['primary', 'outline'],
        },
        'clearButtonTextColor:enable': {
          title: '#14 Clear button text color',
          type: 'boolean',
          description: '[signatureScreen.clearButtonTextColor]',
        },
        clearButtonTextColor: {
          type: 'string',
        },
        'clearButtonBackgroundColor:enable': {
          title: '#15 Clear button background color',
          type: 'boolean',
          description: '[signatureScreen.clearButtonBackgroundColor]',
        },
        clearButtonBackgroundColor: {
          type: 'string',
        },
        'clearButtonBorderColor:enable': {
          title: '#16 Clear button border color',
          type: 'boolean',
          description: '[signatureScreen.clearButtonBorderColor]',
        },
        clearButtonBorderColor: {
          type: 'string',
        },
        'scrollToBottomIconUrl:enable': {
          title: '#17 Scroll to bottom icon url',
          type: 'boolean',
          description: '[signatureScreen.scrollToBottomIconUrl]',
        },
        scrollToBottomIconUrl: {
          type: 'string',
        },
        'scrollToTopIconUrl:enable': {
          title: '#18 Scroll to top icon url',
          type: 'boolean',
          description: '[signatureScreen.scrollToTopIconUrl]',
        },
        scrollToTopIconUrl: {
          type: 'string',
        },
        'userDataContainerBackgroundColor:enable': {
          title: "#19 User data's container background color",
          type: 'boolean',
          description: '[signatureScreen.userDataContainerBackgroundColor]',
        },
        userDataContainerBackgroundColor: {
          type: 'string',
        },
        'userDataItemBackgroundColor:enable': {
          title: "#20 User data's item background color",
          type: 'boolean',
          description: '[signatureScreen.userDataItemBackgroundColor]',
        },
        userDataItemBackgroundColor: {
          type: 'string',
        },
        'userDataLabelTextColor:enable': {
          title: "#21 User data's label text color",
          type: 'boolean',
          description: '[signatureScreen.userDataLabelTextColor]',
        },
        userDataLabelTextColor: {
          type: 'string',
        },
        'userDataValueTextColor:enable': {
          title: "#22 User data's value text color",
          type: 'boolean',
          description: '[signatureScreen.userDataValueTextColor]',
        },
        userDataValueTextColor: {
          type: 'string',
        },
        'clearButtonBorderWidth:enable': {
          title: '#23 Clear button border width',
          type: 'boolean',
          description: '[signatureScreen.clearButtonBorderWidth]',
        },
        clearButtonBorderWidth: {
          type: 'number',
        },
        'disableSendDynamicUserInput:enable': {
          title: '#24 Disable send dynamic user input',
          type: 'boolean',
          description: '[signatureScreen.disableSendDynamicUserInput]',
        },
        disableSendDynamicUserInput: {
          type: undefined,
          enum: [true, false],
        },
        'enableManualRequirementsUpdate:enable': {
          title: '#25 Enable manual requirements update',
          type: 'boolean',
          description: '[signatureScreen.enableManualRequirementsUpdate]',
        },
        enableManualRequirementsUpdate: {
          type: undefined,
          enum: [true, false],
        },
        'signatureDynamicKey:enable': {
          title: '#26 Signature dynamic key',
          type: 'boolean',
          description: '[signatureScreen.signatureDynamicKey]',
        },
        signatureDynamicKey: {
          type: 'string',
        },
        'enableSendHiddenDynamicFields:enable': {
          title: '#27 Enable send hidden dynamic fields',
          type: 'boolean',
          description: '[signatureScreen.enableSendHiddenDynamicFields]',
        },
        enableSendHiddenDynamicFields: {
          type: undefined,
          enum: [true, false],
        },
        'disableSendSignatureOnRegCard:enable': {
          title: '#28 Disable send signature on reg card',
          type: 'boolean',
          description: '[signatureScreen.disableSendSignatureOnRegCard]',
        },
        disableSendSignatureOnRegCard: {
          type: undefined,
          enum: [true, false],
        },
        'displayUserData:enable': {
          title: '#29 Display user data list',
          type: 'boolean',
          description: '[signatureScreen.displayUserData]',
        },
        displayUserData: {
          type: 'array',
          items: {
            type: 'string',
            enum: [
              'NAME',
              'CONFIRMATION_CODE',
              'EMAIL',
              'PHONE_NUMBER',
              'CHECK_IN_DATE',
              'CHECK_OUT_DATE',
            ],
          },
        },
        'disableSignaturePrefix:enable': {
          title: '#30 Disable signature base64 string prefix',
          type: 'boolean',
          description: '[signatureScreen.disableSignaturePrefix]',
        },
        disableSignaturePrefix: {
          type: undefined,
          enum: [true, false],
        },
        'enableSignatureResizeOnRegCard:enable': {
          title: '#31 Enable signature image resize on generating reg card',
          type: 'boolean',
          description: '[signatureScreen.enableSignatureResizeOnRegCard]',
        },
        enableSignatureResizeOnRegCard: {
          type: undefined,
          enum: [true, false],
        },
        'signatureResizeWidth:enable': {
          title: '#32 Signature image resize width',
          type: 'boolean',
          description: '[signatureScreen.signatureResizeWidth]',
        },
        signatureResizeWidth: {
          type: 'number',
        },
        'signatureResizeHeight:enable': {
          title: '#33 Signature image resize height',
          type: 'boolean',
          description: '[signatureScreen.signatureResizeHeight]',
        },
        signatureResizeHeight: {
          type: 'number',
        },
        agreements: {
          type: 'object',
          title: '#34 Agreement boxes theme',
          properties: {
            checked: {
              type: 'object',
              title: '#34.1 Agreement boxes (Checked state)',
              properties: {
                'backgroundColor:enable': {
                  title: '#34.1.1 Box background color',
                  type: 'boolean',
                  description:
                    '[signatureScreen.agreements.checked.backgroundColor] - RGBA / RGB / Hex / Color name',
                },
                backgroundColor: {
                  type: 'string',
                },
                'borderColor:enable': {
                  title: '#34.1.2 Box border color',
                  type: 'boolean',
                  description:
                    '[signatureScreen.agreements.checked.borderColor] - RGBA / RGB / Hex / Color name',
                },
                borderColor: {
                  type: 'string',
                },
                'borderWidth:enable': {
                  title: '#34.1.3 Box border width',
                  type: 'boolean',
                  description: '[signatureScreen.agreements.checked.borderWidth]',
                },
                borderWidth: {
                  type: 'number',
                },
                'borderRadius:enable': {
                  title: '#34.1.4 Box border radius',
                  type: 'boolean',
                  description: '[signatureScreen.agreements.checked.borderRadius]',
                },
                borderRadius: {
                  type: 'number',
                },
                'textColor:enable': {
                  title: '#34.1.5 Box text color',
                  description:
                    '[signatureScreen.agreements.checked.textColor] - RGBA / RGB / Hex / Color name',
                  type: 'boolean',
                },
                textColor: {
                  type: 'string',
                },
                'shadowColor:enable': {
                  title: '#34.1.6 Box shadow color',
                  type: 'boolean',
                  description:
                    '[signatureScreen.agreements.checked.shadowColor] - RGBA / RGB / Hex / Color name',
                },
                shadowColor: {
                  type: 'string',
                },
                'checkboxBorderColor:enable': {
                  title: '#34.1.7 Checkbox border color',
                  description:
                    '[signatureScreen.agreements.checked.checkboxBorderColor] - RGBA / RGB / Hex / Color name',
                  type: 'boolean',
                },
                checkboxBorderColor: {
                  type: 'string',
                },
                'checkboxBackgroundColor:enable': {
                  title: '#34.1.8 Checkbox background color',
                  description:
                    '[signatureScreen.agreements.checked.checkboxBackgroundColor] - RGBA / RGB / Hex / Color name',
                  type: 'boolean',
                },
                checkboxBackgroundColor: {
                  type: 'string',
                },
                'tickColor:enable': {
                  title: '#34.1.9 Checkbox tick color',
                  description:
                    '[signatureScreen.agreements.checked.tickColor] - RGBA / RGB / Hex / Color name',
                  type: 'boolean',
                },
                tickColor: {
                  type: 'string',
                },
              },
            },
            unchecked: {
              type: 'object',
              title: '#34.2 Agreement boxes (Unchecked state)',
              properties: {
                'backgroundColor:enable': {
                  title: '#34.2.1 Box background color',
                  type: 'boolean',
                  description:
                    '[signatureScreen.agreements.unchecked.backgroundColor] - RGBA / RGB / Hex / Color name',
                },
                backgroundColor: {
                  type: 'string',
                },
                'borderColor:enable': {
                  title: '#34.2.2 Box border color',
                  type: 'boolean',
                  description:
                    '[signatureScreen.agreements.unchecked.borderColor] - RGBA / RGB / Hex / Color name',
                },
                borderColor: {
                  type: 'string',
                },
                'borderWidth:enable': {
                  title: '#34.2.3 Box border width',
                  type: 'boolean',
                  description: '[signatureScreen.agreements.unchecked.borderWidth]',
                },
                borderWidth: {
                  type: 'number',
                },
                'borderRadius:enable': {
                  title: '#34.2.4 Box border radius',
                  type: 'boolean',
                  description: '[signatureScreen.agreements.unchecked.borderRadius]',
                },
                borderRadius: {
                  type: 'number',
                },
                'textColor:enable': {
                  title: '#34.2.5 Box text color',
                  description:
                    '[signatureScreen.agreements.unchecked.textColor] - RGBA / RGB / Hex / Color name',
                  type: 'boolean',
                },
                textColor: {
                  type: 'string',
                },
                'shadowColor:enable': {
                  title: '#34.2.6 Box shadow color',
                  type: 'boolean',
                  description:
                    '[signatureScreen.agreements.unchecked.shadowColor] - RGBA / RGB / Hex / Color name',
                },
                shadowColor: {
                  type: 'string',
                },
                'checkboxBorderColor:enable': {
                  title: '#34.2.7 Checkbox border color',
                  description:
                    '[signatureScreen.agreements.unchecked.checkboxBorderColor] - RGBA / RGB / Hex / Color name',
                  type: 'boolean',
                },
                checkboxBorderColor: {
                  type: 'string',
                },
                'checkboxBackgroundColor:enable': {
                  title: '#34.2.8 Checkbox background color',
                  description:
                    '[signatureScreen.agreements.unchecked.checkboxBackgroundColor] - RGBA / RGB / Hex / Color name',
                  type: 'boolean',
                },
                checkboxBackgroundColor: {
                  type: 'string',
                },
              },
            },
          },
        },
      },
    },
    mrzScannerScreen: {
      type: 'object',
      title: '#24 MRZ Scanner screen (ID Authentication method)',
      properties: {
        'instructionImageUrl:enable': {
          title: '#1 Instruction image URL',
          type: 'boolean',
          description: '[mrzScannerScreen.instructionImageUrl]',
        },
        instructionImageUrl: {
          type: 'string',
        },
        'instructionImageUrl2:enable': {
          title: '#2 Instruction image URL 2',
          type: 'boolean',
          description: '[mrzScannerScreen.instructionImageUrl2]',
        },
        instructionImageUrl2: {
          type: 'string',
        },
        'zoom:enable': {
          title: '#3 Zoom level',
          type: 'boolean',
          description: '[mrzScannerScreen.zoom]',
        },
        zoom: {
          type: 'number',
        },
        'isCameraMirrored:enable': {
          title: '#4 Is camera mirrored',
          type: 'boolean',
          description: '[mrzScannerScreen.isCameraMirrored]',
        },
        isCameraMirrored: {
          type: undefined,
          enum: [true, false],
        },
        'scanViewLeftPanelBgColor:enable': {
          title: '#5 Scan view left panel background color',
          type: 'boolean',
          description: '[mrzScannerScreen.scanViewLeftPanelBgColor] - Hex only',
        },
        scanViewLeftPanelBgColor: {
          type: 'string',
        },
        'scanViewRightPanelBgColor:enable': {
          title: '#6 Scan view right panel background color',
          type: 'boolean',
          description: '[mrzScannerScreen.scanViewRightPanelBgColor] - Hex only',
        },
        scanViewRightPanelBgColor: {
          type: 'string',
        },
        'scanViewLeftPanelBorderColor:enable': {
          title: '#7 Scan view left panel border color',
          type: 'boolean',
          description: '[mrzScannerScreen.scanViewLeftPanelBorderColor] - Hex only',
        },
        scanViewLeftPanelBorderColor: {
          type: 'string',
        },
        'scanViewRightPanelBorderColor:enable': {
          title: '#8 Scan view right panel border color',
          type: 'boolean',
          description: '[mrzScannerScreen.scanViewRightPanelBorderColor] - Hex only',
        },
        scanViewRightPanelBorderColor: {
          type: 'string',
        },
        'logoImage:enable': {
          title: '#9 Logo image URL',
          type: 'boolean',
          description: '[mrzScannerScreen.logoImage]',
        },
        logoImage: {
          type: 'string',
        },
        'homeImage:enable': {
          title: '#10 Home image URL',
          type: 'boolean',
          description: '[mrzScannerScreen.homeImage]',
        },
        homeImage: {
          type: 'string',
        },
        'settingImage:enable': {
          title: '#11 Setting image URL',
          type: 'boolean',
          description: '[mrzScannerScreen.settingImage]',
        },
        settingImage: {
          type: 'string',
        },
        'mrzIdVerificationInstructionVideoUrl:enable': {
          title: '#12 MRZ ID Verification instruction video url',
          type: 'boolean',
          description: '[mrzScannerScreen.mrzIdVerificationInstructionVideoUrl]',
        },
        mrzIdVerificationInstructionVideoUrl: {
          type: 'string',
        },
        'isInstruction:enable': {
          title: '#13 Is instruction',
          type: 'boolean',
          description: '[mrzScannerScreen.isInstruction]',
        },
        isInstruction: {
          type: undefined,
          enum: [true, false],
        },
        'hasAgeVerification:enable': {
          title: '#14 Has age verification',
          type: 'boolean',
          description: '[mrzScannerScreen.hasAgeVerification]',
        },
        hasAgeVerification: {
          type: undefined,
          enum: [true, false],
        },
        'ageVerificationImageUrl:enable': {
          title: '#15 Age verification image url',
          type: 'boolean',
          description: '[mrzScannerScreen.ageVerificationImageUrl]',
        },
        ageVerificationImageUrl: {
          type: 'string',
        },
        'primaryImageUrl:enable': {
          title: '#16 Primary image url',
          type: 'boolean',
          description: '[mrzScannerScreen.primaryImageUrl]',
        },
        primaryImageUrl: {
          type: 'string',
        },
        'secondaryImageUrl:enable': {
          title: '#17 Secondary image url',
          type: 'boolean',
          description: '[mrzScannerScreen.secondaryImageUrl]',
        },
        secondaryImageUrl: {
          type: 'string',
        },
        'infoIconUrl:enable': {
          title: '#18 Info icon url',
          type: 'boolean',
          description: '[mrzScannerScreen.infoIconUrl]',
        },
        infoIconUrl: {
          type: 'string',
        },
        'popupBackgroundColor:enable': {
          title: '#19 Popup background color',
          type: 'boolean',
          description: '[mrzScannerScreen.popupBackgroundColor] - RGBA / RGB / Hex / Color name',
        },
        popupBackgroundColor: {
          type: 'string',
        },
        'popupTextColor:enable': {
          title: '#20 Popup text color',
          type: 'boolean',
          description: '[mrzScannerScreen.popupTextColor] - RGBA / RGB / Hex / Color name',
        },
        popupTextColor: {
          type: 'string',
        },
        'popupTitleFontSize:enable': {
          title: '#21 Popu title font size',
          type: 'boolean',
          description: '[mrzScannerScreen.popupTitleFontSize]',
        },
        popupTitleFontSize: {
          type: 'number',
        },
        'popupTitleFontWeight:enable': {
          title: '#22 Popup title font weight',
          type: 'boolean',
          description: '[mrzScannerScreen.popupTitleFontWeight]',
        },
        popupTitleFontWeight: {
          type: undefined,
          enum: ['normal', 'bold', '100', '200', '300', '400', '500', '600', '700', '800', '900'],
        },
        'popupDescriptionFontSize:enable': {
          title: '#23 Popu description font size',
          type: 'boolean',
          description: '[mrzScannerScreen.popupDescriptionFontSize]',
        },
        popupDescriptionFontSize: {
          type: 'number',
        },
        'popupDescriptionFontWeight:enable': {
          title: '#24 Popup description font weight',
          type: 'boolean',
          description: '[mrzScannerScreen.popupDescriptionFontWeight]',
        },
        popupDescriptionFontWeight: {
          type: undefined,
          enum: ['normal', 'bold', '100', '200', '300', '400', '500', '600', '700', '800', '900'],
        },
        'popupButtonTextColor:enable': {
          title: '#25 Popup button text color',
          type: 'boolean',
          description: '[mrzScannerScreen.popupButtonTextColor] - RGBA / RGB / Hex / Color name',
        },
        popupButtonTextColor: {
          type: 'string',
        },
        'popupButtonFontSize:enable': {
          title: '#26 Popu button font size',
          type: 'boolean',
          description: '[mrzScannerScreen.popupButtonFontSize]',
        },
        popupButtonFontSize: {
          type: 'number',
        },
        'popupButtonFontWeight:enable': {
          title: '#27 Popup button font weight',
          type: 'boolean',
          description: '[mrzScannerScreen.popupButtonFontWeight]',
        },
        popupButtonFontWeight: {
          type: undefined,
          enum: ['normal', 'bold', '100', '200', '300', '400', '500', '600', '700', '800', '900'],
        },
        'instructionVideoUrl:enable': {
          title: '#28 Instruction video url',
          type: 'boolean',
          description: '[mrzScannerScreen.instructionVideoUrl]',
        },
        instructionVideoUrl: {
          type: 'string',
        },
        'instructionGifUrl:enable': {
          title: '#29 Instruction GIF url',
          type: 'boolean',
          description: '[mrzScannerScreen.instructionGifUrl]',
        },
        instructionGifUrl: {
          type: 'string',
        },
        'shouldDisplayBackAlert:enable': {
          title: '#30 Should display back alert',
          type: 'boolean',
          description: '[mrzScannerScreen.shouldDisplayBackAlert]',
        },
        shouldDisplayBackAlert: {
          type: undefined,
          enum: [true, false],
        },
        'instructionTextColor:enable': {
          title: '#31 Instruction text color',
          type: 'boolean',
          description: '[mrzScannerScreen.instructionTextColor] - Hex',
        },
        instructionTextColor: {
          type: 'string',
        },
        'instructionTextFontSize:enable': {
          title: '#32 Instruction text font size',
          type: 'boolean',
          description: '[mrzScannerScreen.instructionTextFontSize]',
        },
        instructionTextFontSize: {
          type: 'number',
        },
        'instructionTextFontWight:enable': {
          title: '#33 Instruction text font weight',
          type: 'boolean',
          description: '[mrzScannerScreen.instructionTextFontWight]',
        },
        instructionTextFontWight: {
          type: undefined,
          enum: ['normal', 'bold', '100', '200', '300', '400', '500', '600', '700', '800', '900'],
        },
        'instructionTextAlignment:enable': {
          title: '#34 Instruction text alignment',
          type: 'boolean',
          description: '[mrzScannerScreen.instructionTextAlignment]',
        },
        instructionTextAlignment: {
          type: undefined,
          enum: ['auto', 'left', 'right', 'center', 'justify'],
        },
        'instructionText2Color:enable': {
          title: '#35 Instruction text 2 color',
          type: 'boolean',
          description: '[mrzScannerScreen.instructionText2Color] - Hex',
        },
        instructionText2Color: {
          type: 'string',
        },
        'instructionText2FontSize:enable': {
          title: '#36 Instruction text 2 font size',
          type: 'boolean',
          description: '[mrzScannerScreen.instructionText2FontSize]',
        },
        instructionText2FontSize: {
          type: 'number',
        },
        'instructionText2FontWight:enable': {
          title: '#37 Instruction text 2 font weight',
          type: 'boolean',
          description: '[mrzScannerScreen.instructionText2FontWight]',
        },
        instructionText2FontWight: {
          type: undefined,
          enum: ['normal', 'bold', '100', '200', '300', '400', '500', '600', '700', '800', '900'],
        },
        'instructionText2Alignment:enable': {
          title: '#38 Instruction text 2 alignment',
          type: 'boolean',
          description: '[mrzScannerScreen.instructionText2Alignment]',
        },
        instructionText2Alignment: {
          type: undefined,
          enum: ['auto', 'left', 'right', 'center', 'justify'],
        },
        'scanInstructionTextColor:enable': {
          title: '#39 Scan instruction text color',
          type: 'boolean',
          description: '[mrzScannerScreen.scanInstructionTextColor] - Hex',
        },
        scanInstructionTextColor: {
          type: 'string',
        },
        'scanInstructionTextFontSize:enable': {
          title: '#40 Scan instruction text font size',
          type: 'boolean',
          description: '[mrzScannerScreen.scanInstructionTextFontSize]',
        },
        scanInstructionTextFontSize: {
          type: 'number',
        },
        'scanInstructionTextFontWight:enable': {
          title: '#41 Scan instruction text font weight',
          type: 'boolean',
          description: '[mrzScannerScreen.scanInstructionTextFontWight]',
        },
        scanInstructionTextFontWight: {
          type: undefined,
          enum: ['normal', 'bold', '100', '200', '300', '400', '500', '600', '700', '800', '900'],
        },
        'scanInstructionTextAlignment:enable': {
          title: '#42 Scan instruction text alignment',
          type: 'boolean',
          description: '[mrzScannerScreen.scanInstructionTextAlignment]',
        },
        scanInstructionTextAlignment: {
          type: undefined,
          enum: ['auto', 'left', 'right', 'center', 'justify'],
        },
        'headerTextColor:enable': {
          title: '#43 Header text color',
          type: 'boolean',
          description: '[mrzScannerScreen.headerTextColor] - Hex',
        },
        headerTextColor: {
          type: 'string',
        },
        'headerTextFontSize:enable': {
          title: '#44 Header text font size',
          type: 'boolean',
          description: '[mrzScannerScreen.headerTextFontSize]',
        },
        headerTextFontSize: {
          type: 'number',
        },
        'headerTextFontWight:enable': {
          title: '#45 Header text font weight',
          type: 'boolean',
          description: '[mrzScannerScreen.headerTextFontWight]',
        },
        headerTextFontWight: {
          type: undefined,
          enum: ['normal', 'bold', '100', '200', '300', '400', '500', '600', '700', '800', '900'],
        },
        'headerTextAlignment:enable': {
          title: '#46 Header text alignment',
          type: 'boolean',
          description: '[mrzScannerScreen.headerTextAlignment]',
        },
        headerTextAlignment: {
          type: undefined,
          enum: ['auto', 'left', 'right', 'center', 'justify'],
        },
        'cameraTextColor:enable': {
          title: '#47 Camera text color',
          type: 'boolean',
          description: '[mrzScannerScreen.cameraTextColor] - Hex',
        },
        cameraTextColor: {
          type: 'string',
        },
        'cameraTextFontSize:enable': {
          title: '#48 Camera text font size',
          type: 'boolean',
          description: '[mrzScannerScreen.cameraTextFontSize]',
        },
        cameraTextFontSize: {
          type: 'number',
        },
        'cameraTextFontWight:enable': {
          title: '#49 Camera text font weight',
          type: 'boolean',
          description: '[mrzScannerScreen.cameraTextFontWight]',
        },
        cameraTextFontWight: {
          type: undefined,
          enum: ['normal', 'bold', '100', '200', '300', '400', '500', '600', '700', '800', '900'],
        },
        'cameraTextAlignment:enable': {
          title: '#50 Camera text alignment',
          type: 'boolean',
          description: '[mrzScannerScreen.cameraTextAlignment]',
        },
        cameraTextAlignment: {
          type: undefined,
          enum: ['auto', 'left', 'right', 'center', 'justify'],
        },
        'remoteAssistanceImage:enable': {
          title: '#51 Remote Assistance Image',
          type: 'boolean',
          description: '[mrzScannerScreen.remoteAssistanceImage]',
        },
        remoteAssistanceImage: {
          type: 'string',
        },
      },
    },
    nameAndDepartureDateVerificationScreen: {
      type: 'object',
      title: '#25 Name and departure date verification screen',
      properties: {
        'displayDepartureDateFormat:enable': {
          title: '#1 Departure date format',
          type: 'boolean',
          description: '[nameAndDepartureDateVerificationScreen.displayDepartureDateFormat]',
        },
        displayDepartureDateFormat: {
          type: 'string',
        },
      },
    },
    wristbandDispensingScreen: {
      type: 'object',
      title: '#26 Wristband Dispensing Screen',
      properties: {
        'finalScreenImageUrl:enable': {
          title: '#1 Final screen image URL',
          type: 'boolean',
          description:
            'Picture that is displayed on the final screen of the flow. [wristbandDispensingScreen.finalScreenImageUrl]',
        },
        finalScreenImageUrl: {
          type: 'string',
        },
        'titleTextColor:enable': {
          title: '#2 Title text color',
          type: 'boolean',
          description:
            'Color of the title and subtitle. [wristbandDispensingScreen.titleTextColor] - Hex only',
        },
        titleTextColor: {
          type: 'string',
        },
        'primaryTextColor:enable': {
          title: '#3 Primary text color',
          type: 'boolean',
          description:
            'Text color that will be used throughout the screen except title and subtitle. [wristbandDispensingScreen.primaryTextColor] - Hex only',
        },
        primaryTextColor: {
          type: 'string',
        },
        'guestNumberBackgroundColor:enable': {
          title: '#4 Guest number background color',
          type: 'boolean',
          description:
            'Background color for the guest count and number. [wristbandDispensingScreen.guestNumberBackgroundColor] - Hex only',
        },
        guestNumberBackgroundColor: {
          type: 'string',
        },
        'guestNumberColor:enable': {
          title: '#5 Guest number color',
          type: 'boolean',
          description:
            'Color of the number for guest count. [wristbandDispensingScreen.guestNumberColor] - Hex only',
        },
        guestNumberColor: {
          type: 'string',
        },
        'guestDispenseStatusColor:enable': {
          title: '#6 Guest dispense status color',
          type: 'boolean',
          description: '[wristbandDispensingScreen.guestDispenseStatusColor] - Hex only',
        },
        guestDispenseStatusColor: {
          type: 'string',
        },
        'guestSetAccessButtonBackgroundColor:enable': {
          title: '#7 Guest set access background color',
          type: 'boolean',
          description: '[wristbandDispensingScreen.guestSetAccessButtonBackgroundColor] - Hex only',
        },
        guestSetAccessButtonBackgroundColor: {
          type: 'string',
        },
        'guestSetAccessButtonTextColor:enable': {
          title: '#8 Guest set access text color',
          type: 'boolean',
          description: '[wristbandDispensingScreen.guestSetAccessButtonTextColor] - Hex only',
        },
        guestSetAccessButtonTextColor: {
          type: 'string',
        },
        'guestWristbandDispensedTagBackgroundColor:enable': {
          title: '#9 Guest wristband dispensed tag background color',
          type: 'boolean',
          description:
            '[wristbandDispensingScreen.guestWristbandDispensedTagBackgroundColor] - Hex only',
        },
        guestWristbandDispensedTagBackgroundColor: {
          type: 'string',
        },
        'guestWristbandDispensedTagTextColor:enable': {
          title: '#10 Guest wristband dispensed tag text color',
          type: 'boolean',
          description: '[wristbandDispensingScreen.guestWristbandDispensedTagTextColor] - Hex only',
        },
        guestWristbandDispensedTagTextColor: {
          type: 'string',
        },
        'dispenseButtonBackgroundColor:enable': {
          title: '#11 Dispense button background color',
          type: 'boolean',
          description: '[wristbandDispensingScreen.dispenseButtonBackgroundColor] - Hex only',
        },
        dispenseButtonBackgroundColor: {
          type: 'string',
        },
        'dispenseButtonTextColor:enable': {
          title: '#12 Dispense button text color',
          type: 'boolean',
          description: '[wristbandDispensingScreen.dispenseButtonTextColor] - Hex only',
        },
        dispenseButtonTextColor: {
          type: 'string',
        },
        'logoutButtonBackgroundColor:enable': {
          title: '#13 Logout button background color',
          type: 'boolean',
          description: '[wristbandDispensingScreen.logoutButtonBackgroundColor] - Hex only',
        },
        logoutButtonBackgroundColor: {
          type: 'string',
        },
        'logoutButtonTextColor:enable': {
          title: '#14 Logout button text color',
          type: 'boolean',
          description: '[wristbandDispensingScreen.logoutButtonTextColor] - Hex only',
        },
        logoutButtonTextColor: {
          type: 'string',
        },
        'modalBackgroundColor:enable': {
          title: '#15 Modal background color',
          type: 'boolean',
          description:
            'Color of the modal background[wristbandDispensingScreen.modalBackgroundColor] - Hex only',
        },
        modalBackgroundColor: {
          type: 'string',
        },
        'modalTextColor:enable': {
          title: '#16 Modal text color',
          type: 'boolean',
          description:
            'Color of the modal texts. [wristbandDispensingScreen.modalTextColor] - Hex only',
        },
        modalTextColor: {
          type: 'string',
        },
        'modalConfirmButtonBackgroundColor:enable': {
          title: '#17 Modal confirm button background color',
          type: 'boolean',
          description: '[wristbandDispensingScreen.modalConfirmButtonBackgroundColor] - Hex only',
        },
        modalConfirmButtonBackgroundColor: {
          type: 'string',
        },
        'modalConfirmButtonTextColor:enable': {
          title: '#18 Modal confirm button text color',
          type: 'boolean',
          description: '[wristbandDispensingScreen.modalConfirmButtonTextColor] - Hex only',
        },
        modalConfirmButtonTextColor: {
          type: 'string',
        },
        'modalCancelButtonBackgroundColor:enable': {
          title: '#19 Modal cancel button background color',
          type: 'boolean',
          description: '[wristbandDispensingScreen.modalCancelButtonBackgroundColor] - Hex only',
        },
        modalCancelButtonBackgroundColor: {
          type: 'string',
        },
        'modalCancelButtonTextColor:enable': {
          title: '#20 Modal cancel button text color',
          type: 'boolean',
          description: '[wristbandDispensingScreen.modalCancelButtonTextColor] - Hex only',
        },
        modalCancelButtonTextColor: {
          type: 'string',
        },
        'mainContainerBackgroundColor:enable': {
          title: '#21 Main container background color',
          type: 'boolean',
          description: '[wristbandDispensingScreen.mainContainerBackgroundColor] - Hex only',
        },
        mainContainerBackgroundColor: {
          type: 'string',
        },
        'activeGuestBackgroundColor:enable': {
          title: '#22 Active guest background color',
          type: 'boolean',
          description: '[wristbandDispensingScreen.activeGuestBackgroundColor] - Hex only',
        },
        activeGuestBackgroundColor: {
          type: 'string',
        },
        'loadingSpinnerColor:enable': {
          title: '#23 Loading spinner color',
          type: 'boolean',
          description: '[wristbandDispensingScreen.loadingSpinnerColor] - Hex only',
        },
        loadingSpinnerColor: {
          type: 'string',
        },
        'activeSwitchBackgroundColor:enable': {
          title: '#24 Active switch color',
          type: 'boolean',
          description: '[wristbandDispensingScreen.activeSwitchBackgroundColor] - Hex only',
        },
        activeSwitchBackgroundColor: {
          type: 'string',
        },
        'disabledSwitchBackgroundColor:enable': {
          title: '#25 Not Active switch color',
          type: 'boolean',
          description: '[wristbandDispensingScreen.disabledSwitchBackgroundColor] - Hex only',
        },
        disabledSwitchBackgroundColor: {
          type: 'string',
        },
        'labelRow:enable': {
          title: '#26 Label Row Number',
          type: 'boolean',
          description: '[wristbandDispensingScreen.labelRow] - Number only',
        },
        labelRow: {
          type: 'number',
        },
        'labelColumn:enable': {
          title: '#27 Label Column Number',
          type: 'boolean',
          description: '[wristbandDispensingScreen.labelColumn] - Number only',
        },
        labelColumn: {
          type: 'number',
        },
        'labelFont:enable': {
          title: '#28 Label Font',
          type: 'boolean',
          description: "String (default = 'Medium') - [wristbandDispensingScreen.labelFont]",
        },
        labelFont: {
          type: 'string',
          enum: ['Small', 'Medium', 'Large'],
        },
        'labelRotation:enable': {
          title: '#29 Label Rotation',
          type: 'boolean',
          description:
            "String (default = 'Horizontal') - [wristbandDispensingScreen.labelRotation]",
        },
        labelRotation: {
          type: 'string',
          enum: ['Horizontal', 'Vertical'],
        },
        'errorModalPrimaryColor:enable': {
          title: '#30 Error modal primary color',
          type: 'boolean',
          description:
            'This sets the primary color that will be used by the icon and the CTA button in the error modal - [Hex Only][wristbandDispensingScreen.errorModalPrimaryColor]',
        },
        errorModalPrimaryColor: {
          type: 'string',
        },
        'voidWristbandFontSize:enable': {
          title: '#31 Label Font',
          type: 'boolean',
          description:
            "String (default = 'Small') - [wristbandDispensingScreen.voidWristbandFontSize]",
        },
        voidWristbandFontSize: {
          type: 'string',
          enum: ['Small', 'Medium', 'Large'],
        },
        'voidTextCoordinates:enable': {
          title:
            '#32 The set of coordinates used to map where each word will be printed onto the wristband',
          type: 'boolean',
          description:
            'The configuration must be set as follows: "rol,col;row,col;...row,col" - [wristbandDispensingScreen.voidTextCoordinates]',
        },
        voidTextCoordinates: {
          type: 'string',
        },
        'enableVoidWristbandAutomaticDiscarding:enable': {
          title: '#33 VOID wristband automatic discarding',
          type: 'boolean',
          description:
            'If set to true, the wristband will be automatically discarded after a certain period of time, without the need of user interaction. [general.enableVoidWristbandAutomaticDiscarding]',
        },
        enableVoidWristbandAutomaticDiscarding: {
          type: undefined,
          enum: [true, false],
        },
        'enableVoidWristbandAutomaticDiscardingTimeOut:enable': {
          title: '#34 VOID wristband automatic discarding time out',
          type: 'boolean',
          description:
            'This value sets the amount of seconds it takes to automatically discard the wristband. 10 seconds is the default. [general.enableVoidWristbandAutomaticDiscardingTimeOut]',
        },
        enableVoidWristbandAutomaticDiscardingTimeOut: {
          type: 'number',
        },
        'enableVoidWristbandFeature:enable': {
          title: '#35 Enable VOID wristband feature',
          type: 'boolean',
          description:
            'This controls whether the feature is enabled or not [general.enableVoidWristbandFeature]',
        },
        enableVoidWristbandFeature: {
          type: undefined,
          enum: [true, false],
        },
      },
    },
    simplifiedIdVerification: {
      type: 'object',
      title: '#27 Simplified ID Verification config',
      properties: {
        scanMode: {
          type: 'object',
          title: '#1 Scan mode for each flow',
          properties: {
            'authentication:enable': {
              title: '#1.1 Authentication flow',
              type: 'boolean',
              description: '[simplifiedIdVerification.scanMode.authentication]',
            },
            authentication: {
              type: 'string',
              enum: ['MRZ', 'PDF417'],
            },
            'requirement:enable': {
              title: '#1.2 Requirement flow',
              type: 'boolean',
              description: '[simplifiedIdVerification.scanMode.requirement]',
            },
            requirement: {
              type: 'string',
              enum: ['MRZ', 'PDF417'],
            },
          },
        },
        config: {
          type: 'object',
          title: '#2 Scan mode config',
          properties: {
            authentication: {
              type: 'object',
              title: '#2.1 Scan mode config for authentication flow',
              properties: {
                'allowMiddleName:enable': {
                  title: '#2.1.1 Allow middle name',
                  type: 'boolean',
                  description: '[simplifiedIdVerification.config.authentication.allowMiddleName]',
                },
                allowMiddleName: {
                  type: undefined,
                  enum: [true, false],
                },
                'middleNamePosition:enable': {
                  title: '#2.1.2 Middle name position',
                  type: 'boolean',
                  description:
                    '[simplifiedIdVerification.config.authentication.middleNamePosition]',
                },
                middleNamePosition: {
                  type: 'string',
                  enum: ['FIRSTNAME', 'LASTNAME'],
                },
                documentSelection: {
                  type: 'object',
                  title: '#2.1.3 Document selection screen',
                  properties: {
                    'headerColor:enable': {
                      title: '#2.1.3.1 Header color',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.authentication.documentSelection.headerColor] - Hex only',
                    },
                    headerColor: {
                      type: 'string',
                    },
                    'popupTextColor:enable': {
                      title: '#2.1.3.2 Error popup text color',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.authentication.documentSelection.popupTextColor] - Hex only',
                    },
                    popupTextColor: {
                      type: 'string',
                    },
                    'popupTitleFontSize:enable': {
                      title: '#2.1.3.3 Error popup title font size',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.authentication.documentSelection.popupTitleFontSize]',
                    },
                    popupTitleFontSize: {
                      type: 'number',
                    },
                    'popupTitleFontWeight:enable': {
                      title: '#2.1.3.4 Error popup title font weight',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.authentication.documentSelection.popupTitleFontWeight]',
                    },
                    popupTitleFontWeight: {
                      type: undefined,
                      enum: [
                        'normal',
                        'bold',
                        '100',
                        '200',
                        '300',
                        '400',
                        '500',
                        '600',
                        '700',
                        '800',
                        '900',
                      ],
                    },
                    'popupDescriptionFontSize:enable': {
                      title: '#2.1.3.5 Error popup description font size',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.authentication.documentSelection.popupDescriptionFontSize]',
                    },
                    popupDescriptionFontSize: {
                      type: 'number',
                    },
                    'popupDescriptionFontWeight:enable': {
                      title: '#2.1.3.6 Error popup description font weight',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.authentication.documentSelection.popupDescriptionFontWeight]',
                    },
                    popupDescriptionFontWeight: {
                      type: undefined,
                      enum: [
                        'normal',
                        'bold',
                        '100',
                        '200',
                        '300',
                        '400',
                        '500',
                        '600',
                        '700',
                        '800',
                        '900',
                      ],
                    },
                    'popupButtonTextColor:enable': {
                      title: '#2.1.3.7 Error popup button text color',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.authentication.documentSelection.popupButtonTextColor] - Hex only',
                    },
                    popupButtonTextColor: {
                      type: 'string',
                    },
                    'popupButtonFontSize:enable': {
                      title: '#2.1.3.8 Error popup button font size',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.authentication.documentSelection.popupButtonFontSize]',
                    },
                    popupButtonFontSize: {
                      type: 'number',
                    },
                    'popupButtonFontWeight:enable': {
                      title: '#2.1.3.9 Error popup button font weight',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.authentication.documentSelection.popupButtonFontWeight]',
                    },
                    popupButtonFontWeight: {
                      type: undefined,
                      enum: [
                        'normal',
                        'bold',
                        '100',
                        '200',
                        '300',
                        '400',
                        '500',
                        '600',
                        '700',
                        '800',
                        '900',
                      ],
                    },
                    'popupBackgroundColor:enable': {
                      title: '#2.1.3.10 Error popup background color',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.authentication.documentSelection.popupBackgroundColor] - Hex only',
                    },
                    popupBackgroundColor: {
                      type: 'string',
                    },
                    'mrzIdVerificationInstructionVideoUrl:enable': {
                      title: '#2.1.3.11 Simplified ID Verification instruction video url',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.authentication.documentSelection.mrzIdVerificationInstructionVideoUrl]',
                    },
                    mrzIdVerificationInstructionVideoUrl: {
                      type: 'string',
                    },
                    'isInstruction:enable': {
                      title: '#2.1.3.12 Display new instruction ui',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.authentication.documentSelection.isInstruction]',
                    },
                    isInstruction: {
                      type: undefined,
                      enum: [true, false],
                    },
                    'hasAgeVerification:enable': {
                      title: '#2.1.3.13 Has age verification',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.authentication.documentSelection.hasAgeVerification]',
                    },
                    hasAgeVerification: {
                      type: undefined,
                      enum: [true, false],
                    },
                    'ageVerificationImageUrl:enable': {
                      title: '#2.1.3.14 Age verification image url',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.authentication.documentSelection.ageVerificationImageUrl]',
                    },
                    ageVerificationImageUrl: {
                      type: 'string',
                    },
                    'primaryImageUrl:enable': {
                      title: '#2.1.3.15 Primary image url',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.authentication.documentSelection.primaryImageUrl]',
                    },
                    primaryImageUrl: {
                      type: 'string',
                    },
                    'secondaryImageUrl:enable': {
                      title: '#2.1.3.16 Secondary image url',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.authentication.documentSelection.secondaryImageUrl]',
                    },
                    secondaryImageUrl: {
                      type: 'string',
                    },
                    'documentsConfig:enable': {
                      title: '#2.1.3.17 Document configuration',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.authentication.documentSelection.documentsConfig]',
                    },
                    documentsConfig: {
                      title: 'Document configuration',
                      type: 'array',
                      items: {
                        type: 'object',
                        properties: {
                          scannerType: {
                            title: 'Scanner type',
                            type: 'string',
                            enum: ['MRZ', 'PDF417'],
                          },
                          documentType: {
                            title: 'Document type',
                            type: 'string',
                            enum: ['PASSPORT', 'ID_CARD'],
                          },
                          boxesBackgroundColor: {
                            title: 'Document box background color',
                            type: 'string',
                          },
                          boxesBorderColor: {
                            title: 'Document box border color',
                            type: 'string',
                          },
                          boxesBorderWidth: {
                            title: 'Document box border width',
                            type: 'number',
                          },
                          boxesTitleTextColor: {
                            title: 'Document box title text color',
                            type: 'string',
                          },
                          imageUrl: {
                            title: 'Document box image url',
                            type: 'string',
                          },
                          scannerConfig: {
                            title: 'Scanner screen config',
                            type: 'object',
                            properties: {
                              instructionImageUrl: {
                                title: 'Instruction image url',
                                type: 'string',
                              },
                              instructionImageUrl2: {
                                title: 'Instruction image url 2',
                                type: 'string',
                              },
                              zoom: {
                                title: 'Zoom level',
                                type: 'number',
                              },
                              isCameraMirrored: {
                                title: 'Is camera mirrored',
                                type: undefined,
                                enum: [true, false],
                              },
                              scanViewLeftPanelBgColor: {
                                title: 'Scan view left panel bg color',
                                type: 'string',
                              },
                              scanViewRightPanelBgColor: {
                                title: 'Scan view right panel bg color',
                                type: 'string',
                              },
                              scanViewLeftPanelBorderColor: {
                                title: 'Scan view left panel border color',
                                type: 'string',
                              },
                              scanViewRightPanelBorderColor: {
                                title: 'Scan view right panel bg color',
                                type: 'string',
                              },
                              logoImage: {
                                title: 'Logo image url',
                                type: 'string',
                              },
                              homeImage: {
                                title: 'Home icon image url',
                                type: 'string',
                              },
                              remoteAssistanceImage: {
                                title: 'Remote Assistance image url',
                                type: 'string',
                              },
                              settingImage: {
                                title: 'Setting icon image url',
                                type: 'string',
                              },
                              mrzIdVerificationInstructionVideoUrl: {
                                title: 'Simplified ID Verification instruction video url',
                                type: 'string',
                              },
                              isInstruction: {
                                title: 'Display new instruction ui',
                                type: undefined,
                                enum: [true, false],
                              },
                              hasAgeVerification: {
                                title: 'Has age verification',
                                type: undefined,
                                enum: [true, false],
                              },
                              ageVerificationImageUrl: {
                                title: 'Age verification image url',
                                type: 'string',
                              },
                              primaryImageUrl: {
                                title: 'Primary image url',
                                type: 'string',
                              },
                              secondaryImageUrl: {
                                title: 'Secondary image url',
                                type: 'string',
                              },
                              infoIconUrl: {
                                title: 'Info icon url',
                                type: 'string',
                              },
                              popupBackgroundColor: {
                                title: 'Popup background color',
                                type: 'string',
                              },
                              popupTextColor: {
                                title: 'Popup text color',
                                type: 'string',
                              },
                              popupTitleFontSize: {
                                title: 'Popup title font size',
                                type: 'number',
                              },
                              popupTitleFontWeight: {
                                title: 'Popup title font weight',
                                type: undefined,
                                enum: [
                                  'normal',
                                  'bold',
                                  '100',
                                  '200',
                                  '300',
                                  '400',
                                  '500',
                                  '600',
                                  '700',
                                  '800',
                                  '900',
                                ],
                              },
                              popupDescriptionFontSize: {
                                title: 'Popup description font size',
                                type: 'number',
                              },
                              popupDescriptionFontWeight: {
                                title: 'Popup description font weight',
                                type: undefined,
                                enum: [
                                  'normal',
                                  'bold',
                                  '100',
                                  '200',
                                  '300',
                                  '400',
                                  '500',
                                  '600',
                                  '700',
                                  '800',
                                  '900',
                                ],
                              },
                              popupButtonTextColor: {
                                title: 'Popup button text color',
                                type: 'string',
                              },
                              popupButtonFontSize: {
                                title: 'Popup button font size',
                                type: 'number',
                              },
                              popupButtonFontWeight: {
                                title: 'Popup button font weight',
                                type: undefined,
                                enum: [
                                  'normal',
                                  'bold',
                                  '100',
                                  '200',
                                  '300',
                                  '400',
                                  '500',
                                  '600',
                                  '700',
                                  '800',
                                  '900',
                                ],
                              },
                              instructionVideoUrl: {
                                title: 'Instruction video url',
                                type: 'string',
                              },
                              instructionGifUrl: {
                                title: 'Instruction GIF url',
                                type: 'string',
                              },
                              shouldDisplayBackAlert: {
                                title: 'Should display back alert',
                                type: undefined,
                                enum: [true, false],
                              },
                              instructionTextColor: {
                                title: 'Instruction text color',
                                type: 'string',
                              },
                              instructionTextFontSize: {
                                title: 'Instruction text font size',
                                type: 'number',
                              },
                              instructionTextFontWight: {
                                title: 'Instruction text font weight',
                                type: undefined,
                                enum: [
                                  'normal',
                                  'bold',
                                  '100',
                                  '200',
                                  '300',
                                  '400',
                                  '500',
                                  '600',
                                  '700',
                                  '800',
                                  '900',
                                ],
                              },
                              instructionTextAlignment: {
                                title: 'Instruction text alignment',
                                type: undefined,
                                enum: ['auto', 'left', 'right', 'center', 'justify'],
                              },
                              instructionText2Color: {
                                title: 'Instruction text 2 color',
                                type: 'string',
                              },
                              instructionText2FontSize: {
                                title: 'Instruction text 2 font size',
                                type: 'number',
                              },
                              instructionText2FontWight: {
                                title: 'Instruction text 2 font weight',
                                type: undefined,
                                enum: [
                                  'normal',
                                  'bold',
                                  '100',
                                  '200',
                                  '300',
                                  '400',
                                  '500',
                                  '600',
                                  '700',
                                  '800',
                                  '900',
                                ],
                              },
                              instructionText2Alignment: {
                                title: 'Instruction text 2 alignment',
                                type: undefined,
                                enum: ['auto', 'left', 'right', 'center', 'justify'],
                              },
                              scanInstructionTextColor: {
                                title: 'Scan instruction text color',
                                type: 'string',
                              },
                              scanInstructionTextFontSize: {
                                title: 'Scan instruction text font size',
                                type: 'number',
                              },
                              scanInstructionTextFontWight: {
                                title: 'Scan instruction text font weight',
                                type: undefined,
                                enum: [
                                  'normal',
                                  'bold',
                                  '100',
                                  '200',
                                  '300',
                                  '400',
                                  '500',
                                  '600',
                                  '700',
                                  '800',
                                  '900',
                                ],
                              },
                              scanInstructionTextAlignment: {
                                title: 'Scan instruction text alignment',
                                type: undefined,
                                enum: ['auto', 'left', 'right', 'center', 'justify'],
                              },
                              headerTextColor: {
                                title: 'Header text color',
                                type: 'string',
                              },
                              headerTextFontSize: {
                                title: 'Header text font size',
                                type: 'number',
                              },
                              headerTextFontWight: {
                                title: 'Header text font weight',
                                type: undefined,
                                enum: [
                                  'normal',
                                  'bold',
                                  '100',
                                  '200',
                                  '300',
                                  '400',
                                  '500',
                                  '600',
                                  '700',
                                  '800',
                                  '900',
                                ],
                              },
                              headerTextAlignment: {
                                title: 'Header text alignment',
                                type: undefined,
                                enum: ['auto', 'left', 'right', 'center', 'justify'],
                              },
                              cameraTextColor: {
                                title: 'Camera text color',
                                type: 'string',
                              },
                              cameraTextFontSize: {
                                title: 'Camera text font size',
                                type: 'number',
                              },
                              cameraTextFontWight: {
                                title: 'Camera text font weight',
                                type: undefined,
                                enum: [
                                  'normal',
                                  'bold',
                                  '100',
                                  '200',
                                  '300',
                                  '400',
                                  '500',
                                  '600',
                                  '700',
                                  '800',
                                  '900',
                                ],
                              },
                              cameraTextAlignment: {
                                title: 'Camera text alignment',
                                type: undefined,
                                enum: ['auto', 'left', 'right', 'center', 'justify'],
                              },
                            },
                          },
                        },
                        required: ['scannerType', 'documentType'],
                      },
                    },
                    'popupButtonBackgroundColor:enable': {
                      title: '#2.1.3.18 Popup button background color',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.authentication.documentSelection.popupButtonBackgroundColor] - RGBA / RGB / Hex / Color name',
                    },
                    popupButtonBackgroundColor: {
                      type: 'string',
                    },
                    'popupRetryButtonTextColor:enable': {
                      title: '#2.1.3.19 Popup retry button text color',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.authentication.documentSelection.popupRetryButtonTextColor] - RGBA / RGB / Hex / Color name',
                    },
                    popupRetryButtonTextColor: {
                      type: 'string',
                    },
                    'popupRetryButtonBackgroundColor:enable': {
                      title: '#2.1.3.20 Popup retry button background color',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.authentication.documentSelection.popupRetryButtonBackgroundColor] - RGBA / RGB / Hex / Color name',
                    },
                    popupRetryButtonBackgroundColor: {
                      type: 'string',
                    },
                    'popupRetryButtonFontSize:enable': {
                      title: '#2.1.3.21 Popup retry button font size',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.authentication.documentSelection.popupRetryButtonFontSize]',
                    },
                    popupRetryButtonFontSize: {
                      type: 'number',
                    },
                    'popupRetryButtonFontWeight:enable': {
                      title: '#2.1.3.22 Popup retry button font weight',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.authentication.documentSelection.popupRetryButtonFontWeight]',
                    },
                    popupRetryButtonFontWeight: {
                      type: undefined,
                      enum: [
                        'normal',
                        'bold',
                        '100',
                        '200',
                        '300',
                        '400',
                        '500',
                        '600',
                        '700',
                        '800',
                        '900',
                      ],
                    },
                  },
                },
                'allowSkipScreenOnSingleOption:enable': {
                  title: '#2.1.4 Allow skip screen on single option',
                  type: 'boolean',
                  description:
                    '[simplifiedIdVerification.config.authentication.allowSkipScreenOnSingleOption]',
                },
                allowSkipScreenOnSingleOption: {
                  type: undefined,
                  enum: [true, false],
                },
                'enableSkipOtp:enable': {
                  title: '#2.1.5 Enable skip OTP',
                  type: 'boolean',
                  description: '[simplifiedIdVerification.config.authentication.enableSkipOtp]',
                },
                enableSkipOtp: {
                  type: undefined,
                  enum: [true, false],
                },
              },
            },
            requirement: {
              type: 'object',
              title: '#2.2 Scan mode config for requirement flow',
              properties: {
                'allowMiddleName:enable': {
                  title: '#2.2.1 Allow middle name',
                  type: 'boolean',
                  description: '[simplifiedIdVerification.config.requirement.allowMiddleName]',
                },
                allowMiddleName: {
                  type: undefined,
                  enum: [true, false],
                },
                'middleNamePosition:enable': {
                  title: '#2.2.2 Middle name position',
                  type: 'boolean',
                  description: '[simplifiedIdVerification.config.requirement.middleNamePosition]',
                },
                middleNamePosition: {
                  type: 'string',
                  enum: ['FIRSTNAME', 'LASTNAME'],
                },
                documentSelection: {
                  type: 'object',
                  title: '#2.2.3 Document selection screen',
                  properties: {
                    'headerColor:enable': {
                      title: '#2.2.3.1 Header color',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.requirement.documentSelection.headerColor] - Hex only',
                    },
                    headerColor: {
                      type: 'string',
                    },
                    'popupTextColor:enable': {
                      title: '#2.2.3.2 Error popup text color',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.requirement.documentSelection.popupTextColor] - Hex only',
                    },
                    popupTextColor: {
                      type: 'string',
                    },
                    'popupTitleFontSize:enable': {
                      title: '#2.2.3.3 Error popup title font size',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.requirement.documentSelection.popupTitleFontSize]',
                    },
                    popupTitleFontSize: {
                      type: 'number',
                    },
                    'popupTitleFontWeight:enable': {
                      title: '#2.2.3.4 Error popup title font weight',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.requirement.documentSelection.popupTitleFontWeight]',
                    },
                    popupTitleFontWeight: {
                      type: undefined,
                      enum: [
                        'normal',
                        'bold',
                        '100',
                        '200',
                        '300',
                        '400',
                        '500',
                        '600',
                        '700',
                        '800',
                        '900',
                      ],
                    },
                    'popupDescriptionFontSize:enable': {
                      title: '#2.2.3.5 Error popup description font size',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.requirement.documentSelection.popupDescriptionFontSize]',
                    },
                    popupDescriptionFontSize: {
                      type: 'number',
                    },
                    'popupDescriptionFontWeight:enable': {
                      title: '#2.2.3.6 Error popup description font weight',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.requirement.documentSelection.popupDescriptionFontWeight]',
                    },
                    popupDescriptionFontWeight: {
                      type: undefined,
                      enum: [
                        'normal',
                        'bold',
                        '100',
                        '200',
                        '300',
                        '400',
                        '500',
                        '600',
                        '700',
                        '800',
                        '900',
                      ],
                    },
                    'popupButtonTextColor:enable': {
                      title: '#2.2.3.7 Error popup button text color',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.requirement.documentSelection.popupButtonTextColor] - Hex only',
                    },
                    popupButtonTextColor: {
                      type: 'string',
                    },
                    'popupButtonFontSize:enable': {
                      title: '#2.2.3.8 Error popup button font size',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.requirement.documentSelection.popupButtonFontSize]',
                    },
                    popupButtonFontSize: {
                      type: 'number',
                    },
                    'popupButtonFontWeight:enable': {
                      title: '#2.2.3.9 Error popup button font weight',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.requirement.documentSelection.popupButtonFontWeight]',
                    },
                    popupButtonFontWeight: {
                      type: undefined,
                      enum: [
                        'normal',
                        'bold',
                        '100',
                        '200',
                        '300',
                        '400',
                        '500',
                        '600',
                        '700',
                        '800',
                        '900',
                      ],
                    },
                    'popupBackgroundColor:enable': {
                      title: '#2.2.3.10 Error popup background color',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.requirement.documentSelection.popupBackgroundColor] - Hex only',
                    },
                    popupBackgroundColor: {
                      type: 'string',
                    },
                    'mrzIdVerificationInstructionVideoUrl:enable': {
                      title: '#2.2.3.11 Simplified ID Verification instruction video url',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.requirement.documentSelection.mrzIdVerificationInstructionVideoUrl]',
                    },
                    mrzIdVerificationInstructionVideoUrl: {
                      type: 'string',
                    },
                    'isInstruction:enable': {
                      title: '#2.2.3.12 Display new instruction ui',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.requirement.documentSelection.isInstruction]',
                    },
                    isInstruction: {
                      type: undefined,
                      enum: [true, false],
                    },
                    'hasAgeVerification:enable': {
                      title: '#2.2.3.13 Has age verification',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.requirement.documentSelection.hasAgeVerification]',
                    },
                    hasAgeVerification: {
                      type: undefined,
                      enum: [true, false],
                    },
                    'ageVerificationImageUrl:enable': {
                      title: '#2.2.3.14 Age verification image url',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.requirement.documentSelection.ageVerificationImageUrl]',
                    },
                    ageVerificationImageUrl: {
                      type: 'string',
                    },
                    'primaryImageUrl:enable': {
                      title: '#2.2.3.15 Primary image url',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.requirement.documentSelection.primaryImageUrl]',
                    },
                    primaryImageUrl: {
                      type: 'string',
                    },
                    'secondaryImageUrl:enable': {
                      title: '#2.2.3.16 Secondary image url',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.requirement.documentSelection.secondaryImageUrl]',
                    },
                    secondaryImageUrl: {
                      type: 'string',
                    },
                    'documentsConfig:enable': {
                      title: '#2.2.3.17 Document configuration',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.requirement.documentSelection.documentsConfig]',
                    },
                    documentsConfig: {
                      title: 'Document configuration',
                      type: 'array',
                      items: {
                        type: 'object',
                        properties: {
                          scannerType: {
                            title: 'Scanner type',
                            type: 'string',
                            enum: ['MRZ', 'PDF417'],
                          },
                          documentType: {
                            title: 'Document type',
                            type: 'string',
                            enum: ['PASSPORT', 'ID_CARD'],
                          },
                          boxesBackgroundColor: {
                            title: 'Document box background color',
                            type: 'string',
                          },
                          boxesBorderColor: {
                            title: 'Document box border color',
                            type: 'string',
                          },
                          boxesBorderWidth: {
                            title: 'Document box border width',
                            type: 'number',
                          },
                          boxesTitleTextColor: {
                            title: 'Document box title text color',
                            type: 'string',
                          },
                          imageUrl: {
                            title: 'Document box image url',
                            type: 'string',
                          },
                          scannerConfig: {
                            title: 'Scanner screen config',
                            type: 'object',
                            properties: {
                              instructionImageUrl: {
                                title: 'Instruction image url',
                                type: 'string',
                              },
                              instructionImageUrl2: {
                                title: 'Instruction image url 2',
                                type: 'string',
                              },
                              zoom: {
                                title: 'Zoom level',
                                type: 'number',
                              },
                              isCameraMirrored: {
                                title: 'Is camera mirrored',
                                type: undefined,
                                enum: [true, false],
                              },
                              scanViewLeftPanelBgColor: {
                                title: 'Scan view left panel bg color',
                                type: 'string',
                              },
                              scanViewRightPanelBgColor: {
                                title: 'Scan view right panel bg color',
                                type: 'string',
                              },
                              scanViewLeftPanelBorderColor: {
                                title: 'Scan view left panel border color',
                                type: 'string',
                              },
                              scanViewRightPanelBorderColor: {
                                title: 'Scan view right panel bg color',
                                type: 'string',
                              },
                              logoImage: {
                                title: 'Logo image url',
                                type: 'string',
                              },
                              homeImage: {
                                title: 'Home icon image url',
                                type: 'string',
                              },
                              remoteAssistanceImage: {
                                title: 'Remote Assistance image url',
                                type: 'string',
                              },
                              settingImage: {
                                title: 'Setting icon image url',
                                type: 'string',
                              },
                              mrzIdVerificationInstructionVideoUrl: {
                                title: 'Simplified ID Verification instruction video url',
                                type: 'string',
                              },
                              isInstruction: {
                                title: 'Display new instruction ui',
                                type: undefined,
                                enum: [true, false],
                              },
                              hasAgeVerification: {
                                title: 'Has age verification',
                                type: undefined,
                                enum: [true, false],
                              },
                              ageVerificationImageUrl: {
                                title: 'Age verification image url',
                                type: 'string',
                              },
                              primaryImageUrl: {
                                title: 'Primary image url',
                                type: 'string',
                              },
                              secondaryImageUrl: {
                                title: 'Secondary image url',
                                type: 'string',
                              },
                              infoIconUrl: {
                                title: 'Info icon url',
                                type: 'string',
                              },
                              popupBackgroundColor: {
                                title: 'Popup background color',
                                type: 'string',
                              },
                              popupTextColor: {
                                title: 'Popup text color',
                                type: 'string',
                              },
                              popupTitleFontSize: {
                                title: 'Popup title font size',
                                type: 'number',
                              },
                              popupTitleFontWeight: {
                                title: 'Popup title font weight',
                                type: undefined,
                                enum: [
                                  'normal',
                                  'bold',
                                  '100',
                                  '200',
                                  '300',
                                  '400',
                                  '500',
                                  '600',
                                  '700',
                                  '800',
                                  '900',
                                ],
                              },
                              popupDescriptionFontSize: {
                                title: 'Popup description font size',
                                type: 'number',
                              },
                              popupDescriptionFontWeight: {
                                title: 'Popup description font weight',
                                type: undefined,
                                enum: [
                                  'normal',
                                  'bold',
                                  '100',
                                  '200',
                                  '300',
                                  '400',
                                  '500',
                                  '600',
                                  '700',
                                  '800',
                                  '900',
                                ],
                              },
                              popupButtonTextColor: {
                                title: 'Popup button text color',
                                type: 'string',
                              },
                              popupButtonFontSize: {
                                title: 'Popup button font size',
                                type: 'number',
                              },
                              popupButtonFontWeight: {
                                title: 'Popup button font weight',
                                type: undefined,
                                enum: [
                                  'normal',
                                  'bold',
                                  '100',
                                  '200',
                                  '300',
                                  '400',
                                  '500',
                                  '600',
                                  '700',
                                  '800',
                                  '900',
                                ],
                              },
                              instructionVideoUrl: {
                                title: 'Instruction video url',
                                type: 'string',
                              },
                              instructionGifUrl: {
                                title: 'Instruction GIF url',
                                type: 'string',
                              },
                              shouldDisplayBackAlert: {
                                title: 'Should display back alert',
                                type: undefined,
                                enum: [true, false],
                              },
                              instructionTextColor: {
                                title: 'Instruction text color',
                                type: 'string',
                              },
                              instructionTextFontSize: {
                                title: 'Instruction text font size',
                                type: 'number',
                              },
                              instructionTextFontWight: {
                                title: 'Instruction text font weight',
                                type: undefined,
                                enum: [
                                  'normal',
                                  'bold',
                                  '100',
                                  '200',
                                  '300',
                                  '400',
                                  '500',
                                  '600',
                                  '700',
                                  '800',
                                  '900',
                                ],
                              },
                              instructionTextAlignment: {
                                title: 'Instruction text alignment',
                                type: undefined,
                                enum: ['auto', 'left', 'right', 'center', 'justify'],
                              },
                              instructionText2Color: {
                                title: 'Instruction text 2 color',
                                type: 'string',
                              },
                              instructionText2FontSize: {
                                title: 'Instruction text 2 font size',
                                type: 'number',
                              },
                              instructionText2FontWight: {
                                title: 'Instruction text 2 font weight',
                                type: undefined,
                                enum: [
                                  'normal',
                                  'bold',
                                  '100',
                                  '200',
                                  '300',
                                  '400',
                                  '500',
                                  '600',
                                  '700',
                                  '800',
                                  '900',
                                ],
                              },
                              instructionText2Alignment: {
                                title: 'Instruction text 2 alignment',
                                type: undefined,
                                enum: ['auto', 'left', 'right', 'center', 'justify'],
                              },
                              scanInstructionTextColor: {
                                title: 'Scan instruction text color',
                                type: 'string',
                              },
                              scanInstructionTextFontSize: {
                                title: 'Scan instruction text font size',
                                type: 'number',
                              },
                              scanInstructionTextFontWight: {
                                title: 'Scan instruction text font weight',
                                type: undefined,
                                enum: [
                                  'normal',
                                  'bold',
                                  '100',
                                  '200',
                                  '300',
                                  '400',
                                  '500',
                                  '600',
                                  '700',
                                  '800',
                                  '900',
                                ],
                              },
                              scanInstructionTextAlignment: {
                                title: 'Scan instruction text alignment',
                                type: undefined,
                                enum: ['auto', 'left', 'right', 'center', 'justify'],
                              },
                              headerTextColor: {
                                title: 'Header text color',
                                type: 'string',
                              },
                              headerTextFontSize: {
                                title: 'Header text font size',
                                type: 'number',
                              },
                              headerTextFontWight: {
                                title: 'Header text font weight',
                                type: undefined,
                                enum: [
                                  'normal',
                                  'bold',
                                  '100',
                                  '200',
                                  '300',
                                  '400',
                                  '500',
                                  '600',
                                  '700',
                                  '800',
                                  '900',
                                ],
                              },
                              headerTextAlignment: {
                                title: 'Header text alignment',
                                type: undefined,
                                enum: ['auto', 'left', 'right', 'center', 'justify'],
                              },
                              cameraTextColor: {
                                title: 'Camera text color',
                                type: 'string',
                              },
                              cameraTextFontSize: {
                                title: 'Camera text font size',
                                type: 'number',
                              },
                              cameraTextFontWight: {
                                title: 'Camera text font weight',
                                type: undefined,
                                enum: [
                                  'normal',
                                  'bold',
                                  '100',
                                  '200',
                                  '300',
                                  '400',
                                  '500',
                                  '600',
                                  '700',
                                  '800',
                                  '900',
                                ],
                              },
                              cameraTextAlignment: {
                                title: 'Camera text alignment',
                                type: undefined,
                                enum: ['auto', 'left', 'right', 'center', 'justify'],
                              },
                            },
                          },
                        },
                        required: ['scannerType', 'documentType'],
                      },
                    },
                    'popupButtonBackgroundColor:enable': {
                      title: '#2.2.3.18 Popup button background color',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.requirement.documentSelection.popupButtonBackgroundColor] - RGBA / RGB / Hex / Color name',
                    },
                    popupButtonBackgroundColor: {
                      type: 'string',
                    },
                    'popupRetryButtonTextColor:enable': {
                      title: '#2.2.3.19 Popup retry button text color',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.requirement.documentSelection.popupRetryButtonTextColor] - RGBA / RGB / Hex / Color name',
                    },
                    popupRetryButtonTextColor: {
                      type: 'string',
                    },
                    'popupRetryButtonBackgroundColor:enable': {
                      title: '#2.2.3.20 Popup retry button background color',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.requirement.documentSelection.popupRetryButtonBackgroundColor] - RGBA / RGB / Hex / Color name',
                    },
                    popupRetryButtonBackgroundColor: {
                      type: 'string',
                    },
                    'popupRetryButtonFontSize:enable': {
                      title: '#2.2.3.21 Popup retry button font size',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.requirement.documentSelection.popupRetryButtonFontSize]',
                    },
                    popupRetryButtonFontSize: {
                      type: 'number',
                    },
                    'popupRetryButtonFontWeight:enable': {
                      title: '#2.2.3.22 Popup retry button font weight',
                      type: 'boolean',
                      description:
                        '[simplifiedIdVerification.config.requirement.documentSelection.popupRetryButtonFontWeight]',
                    },
                    popupRetryButtonFontWeight: {
                      type: undefined,
                      enum: [
                        'normal',
                        'bold',
                        '100',
                        '200',
                        '300',
                        '400',
                        '500',
                        '600',
                        '700',
                        '800',
                        '900',
                      ],
                    },
                  },
                },
                'allowSkipScreenOnSingleOption:enable': {
                  title: '#2.2.4 Allow skip screen on single option',
                  type: 'boolean',
                  description:
                    '[simplifiedIdVerification.config.requirement.allowSkipScreenOnSingleOption]',
                },
                allowSkipScreenOnSingleOption: {
                  type: undefined,
                  enum: [true, false],
                },
              },
            },
            general: {
              type: 'object',
              title: '#2.3 Scan mode general config',
              properties: {
                'enableExpirationDateValidation:enable': {
                  title: '#2.3.1 Enable document expiration date validation',
                  type: 'boolean',
                  description:
                    '[simplifiedIdVerification.config.general.enableExpirationDateValidation]',
                },
                enableExpirationDateValidation: {
                  type: undefined,
                  enum: [true, false],
                },
                'continuousAutoFocusMode:enable': {
                  title: '#2.3.2 Enable continuos auto focus for camera',
                  type: 'boolean',
                  description: '[simplifiedIdVerification.config.general.continuousAutoFocusMode]',
                },
                continuousAutoFocusMode: {
                  type: undefined,
                  enum: [true, false],
                },
                'screenV2Disabled:enable': {
                  title: '#2.3.3 Disable new v2 screen',
                  type: 'boolean',
                  description: '[simplifiedIdVerification.config.general.screenV2Disabled]',
                },
                screenV2Disabled: {
                  type: undefined,
                  enum: [true, false],
                },
                'useScanbotParser:enable': {
                  title: '#2.3.4 Use Scanbot Parser',
                  type: 'boolean',
                  description: '[simplifiedIdVerification.config.general.useScanbotParser]',
                },
                useScanbotParser: {
                  type: undefined,
                  enum: [true, false],
                },
              },
            },
          },
        },
      },
    },
    finalScreen: {
      type: 'object',
      title: '#28 Final screen',
      properties: {
        'isEnabled:enable': {
          title: '#1 Is enabled',
          type: 'boolean',
          description: 'Turn on final screen [finalScreen.isEnabled]',
        },
        isEnabled: {
          type: undefined,
          enum: [true, false],
        },
        'imageUrl:enable': {
          title: '#2 Image URL',
          type: 'boolean',
          description: '[finalScreen.imageUrl]',
        },
        imageUrl: {
          type: 'string',
        },
        'displayQrCode:enable': {
          title: '#3 Display QR code',
          type: 'boolean',
          description: '[finalScreen.displayQrCode]',
        },
        displayQrCode: {
          type: undefined,
          enum: [true, false],
        },
        'shadowColor:enable': {
          title: '#4 Cards shadow color',
          type: 'boolean',
          description: '[finalScreen.shadowColor] - RGBA / RGB / Hex / Color name',
        },
        shadowColor: {
          type: 'string',
        },
        'cardBorderColor:enable': {
          title: '#5 Cards border color',
          type: 'boolean',
          description: '[finalScreen.cardBorderColor] - RGBA / RGB / Hex / Color name',
        },
        cardBorderColor: {
          type: 'string',
        },
        'cardBackgroundColor:enable': {
          title: '#6 Cards background color',
          type: 'boolean',
          description: '[finalScreen.cardBackgroundColor] - RGBA / RGB / Hex / Color name',
        },
        cardBackgroundColor: {
          type: 'string',
        },
        'textColor:enable': {
          title: '#7 Text color',
          type: 'boolean',
          description: '[finalScreen.textColor] - RGBA / RGB / Hex / Color name',
        },
        textColor: {
          type: 'string',
        },
        'iconColor:enable': {
          title: '#8 Icons color',
          type: 'boolean',
          description: '[finalScreen.iconColor] - RGBA / RGB / Hex / Color name',
        },
        iconColor: {
          type: 'string',
        },
      },
    },
    AdditionInfoScreen: {
      type: 'object',
      title: '#29 Additional Info screen',
      properties: {
        'disableSendDynamicUserInput:enable': {
          title: '#1 Disable send dynamic user input',
          type: 'boolean',
          description: '[AdditionInfoScreen.disableSendDynamicUserInput]',
        },
        disableSendDynamicUserInput: {
          type: undefined,
          enum: [true, false],
        },
        'enableManualRequirementsUpdate:enable': {
          title: '#2 Enable manual requirements update',
          type: 'boolean',
          description: '[AdditionInfoScreen.enableManualRequirementsUpdate]',
        },
        enableManualRequirementsUpdate: {
          type: undefined,
          enum: [true, false],
        },
        'hiddenDynamicFieldsKey:enable': {
          title: '#3 Hidden dynamic fields key',
          description:
            'Use to hide fields from dynamic input e.g. signature [AdditionInfoScreen.hiddenDynamicFieldsKey]',
          type: 'boolean',
        },
        hiddenDynamicFieldsKey: {
          type: 'array',
          items: {
            type: 'string',
          },
        },
        'enableSendHiddenDynamicFields:enable': {
          title: '#4 Enable send hidden dynamic fields',
          type: 'boolean',
          description: '[AdditionInfoScreen.enableSendHiddenDynamicFields]',
        },
        enableSendHiddenDynamicFields: {
          type: undefined,
          enum: [true, false],
        },
      },
    },
    noInternetConnectionOverlay: {
      type: 'object',
      title: '#30 No internet connection overlay',
      properties: {
        'backgroundColor:enable': {
          title: '#1 Overlay background color',
          type: 'boolean',
          description:
            '[noInternetConnectionOverlay.backgroundColor] - RGBA / RGB / Hex / Color name',
        },
        backgroundColor: {
          type: 'string',
        },
        'settingButtonTextColor:enable': {
          title: '#2 Setting button text color',
          type: 'boolean',
          description:
            '[noInternetConnectionOverlay.settingButtonTextColor] - RGBA / RGB / Hex / Color name',
        },
        settingButtonTextColor: {
          type: 'string',
        },
        'reconnectButtonTextColor:enable': {
          title: '#3 Reconnect button text color',
          description:
            '[noInternetConnectionOverlay.reconnectButtonTextColor] - RGBA / RGB / Hex / Color name',
          type: 'boolean',
        },
        reconnectButtonTextColor: {
          type: 'string',
        },
        'inputTextColor:enable': {
          title: '#4 Input text color',
          description:
            '[noInternetConnectionOverlay.inputTextColor] - RGBA / RGB / Hex / Color name',
          type: 'boolean',
        },
        inputTextColor: {
          type: 'string',
        },
        'textColor:enable': {
          title: '#5 text color',
          description: '[noInternetConnectionOverlay.textColor] - RGBA / RGB / Hex / Color name',
          type: 'boolean',
        },
        textColor: {
          type: 'string',
        },
      },
    },
    obfuscateAuthScreen: {
      type: 'object',
      title: '#31 Obfuscated auth screen',
      properties: {
        'backgroundColor:enable': {
          title: '#1 Card background color',
          type: 'boolean',
          description: '[obfuscateAuthScreen.backgroundColor] - RGBA / RGB / Hex / Color name',
        },
        backgroundColor: {
          type: 'string',
        },
        'shadowColor:enable': {
          title: '#2 Card shadow color',
          type: 'boolean',
          description: '[obfuscateAuthScreen.shadowColor] - RGBA / RGB / Hex / Color name',
        },
        shadowColor: {
          type: 'string',
        },
        'textColor:enable': {
          title: '#3 Card text color',
          description: '[obfuscateAuthScreen.textColor] - RGBA / RGB / Hex / Color name',
          type: 'boolean',
        },
        textColor: {
          type: 'string',
        },
        'buttonColor:enable': {
          title: '#4 Card button color',
          description: '[obfuscateAuthScreen.buttonColor] - RGBA / RGB / Hex / Color name',
          type: 'boolean',
        },
        buttonColor: {
          type: 'string',
        },
        'buttonTextColor:enable': {
          title: '#5 Card button text color',
          description: '[obfuscateAuthScreen.buttonTextColor] - RGBA / RGB / Hex / Color name',
          type: 'boolean',
        },
        buttonTextColor: {
          type: 'string',
        },
        'displayIdVerification:enable': {
          title: '#6 Display ID Verification button (default to true)',
          description: '[obfuscateAuthScreen.displayIdVerification]',
          type: 'boolean',
        },
        displayIdVerification: {
          type: undefined,
          enum: [true, false],
        },
        'displayPhoneNumber:enable': {
          title: '#7 Display Phone Number button (default to true)',
          description: '[obfuscateAuthScreen.displayPhoneNumber]',
          type: 'boolean',
        },
        displayPhoneNumber: {
          type: undefined,
          enum: [true, false],
        },
        'displayEmail:enable': {
          title: '#8 Display Email button (default to true)',
          description: '[obfuscateAuthScreen.displayEmail]',
          type: 'boolean',
        },
        displayEmail: {
          type: undefined,
          enum: [true, false],
        },
      },
    },
    ServiceScreen: {
      type: 'object',
      title: '#32 Service screen',
      properties: {
        'metadataCardColor:enable': {
          title: '#1 Metadata card color',
          type: 'boolean',
          description: '[ServiceScreen.metadataCardColor] - RGBA / RGB / Hex / Color name',
        },
        metadataCardColor: {
          type: 'string',
        },
        'metadataTextColor:enable': {
          title: '#2 Metadata text color',
          type: 'boolean',
          description: '[ServiceScreen.metadataTextColor] - RGBA / RGB / Hex / Color name',
        },
        metadataTextColor: {
          type: 'string',
        },
        'metadataPriceBgColor:enable': {
          title: '#3 Metadata price background color',
          description: '[ServiceScreen.metadataPriceBgColor] - RGBA / RGB / Hex / Color name',
          type: 'boolean',
        },
        metadataPriceBgColor: {
          type: 'string',
        },
        'metadataPriceTextColor:enable': {
          title: '#4 Metadata price text color',
          description: '[ServiceScreen.metadataPriceTextColor] - RGBA / RGB / Hex / Color name',
          type: 'boolean',
        },
        metadataPriceTextColor: {
          type: 'string',
        },
        'priceColor:enable': {
          title: '#5 Price color',
          description: '[ServiceScreen.priceColor] - RGBA / RGB / Hex / Color name',
          type: 'boolean',
        },
        priceColor: {
          type: 'string',
        },
        'serviceId:enable': {
          title: '#6 Service ID',
          description: '[ServiceScreen.serviceId]',
          type: 'boolean',
        },
        serviceId: {
          type: 'string',
        },
        'enabled:enable': {
          title: '#7 Enabled',
          description: '[ServiceScreen.enabled]',
          type: 'boolean',
        },
        enabled: {
          type: undefined,
          enum: [true, false],
        },
        'shadowColor:enable': {
          title: '#8 Shadow color',
          description: '[ServiceScreen.shadowColor] - RGBA / RGB / Hex / Color name',
          type: 'boolean',
        },
        shadowColor: {
          type: 'string',
        },
        'headerColor:enable': {
          title: '#9 Header color',
          description: '[ServiceScreen.headerColor] - RGBA / RGB / Hex / Color name',
          type: 'boolean',
        },
        headerColor: {
          type: 'string',
        },
        'backgroundColor:enable': {
          title: '#10 Background color',
          description: '[ServiceScreen.backgroundColor] - RGBA / RGB / Hex / Color name',
          type: 'boolean',
        },
        backgroundColor: {
          type: 'string',
        },
        'cardColor:enable': {
          title: '#11 Card color',
          description: '[ServiceScreen.cardColor] - RGBA / RGB / Hex / Color name',
          type: 'boolean',
        },
        cardColor: {
          type: 'string',
        },
        'confirmationImage:enable': {
          title: '#12 Confirmation image URL',
          description: '[ServiceScreen.confirmationImage]',
          type: 'boolean',
        },
        confirmationImage: {
          type: 'string',
        },
        'acknowledgeImage:enable': {
          title: '#13 Acknowledge image URL',
          description: '[ServiceScreen.acknowledgeImage]',
          type: 'boolean',
        },
        acknowledgeImage: {
          type: 'string',
        },
        'termsAndConditionsEnabled:enable': {
          title: '#14 Terms and Conditions link enabled',
          description: '[ServiceScreen.termsAndConditionsEnabled]',
          type: 'boolean',
        },
        termsAndConditionsEnabled: {
          type: undefined,
          enum: [true, false],
        },
        'modalBackgroundColor:enable': {
          title: '#15 Modal background color',
          description: '[ServiceScreen.modalBackgroundColor] - RGBA / RGB / Hex / Color name',
          type: 'boolean',
        },
        modalBackgroundColor: {
          type: 'string',
        },
      },
    },
    remoteAssistanceComponent: {
      type: 'object',
      title: '#33 Remote assistance component',
      properties: {
        'shadowColor:enable': {
          title: '#1 Shadow color',
          type: 'boolean',
          description: '[remoteAssistanceComponent.shadowColor] - RGBA / RGB / Hex / Color name',
        },
        shadowColor: {
          type: 'string',
        },
        'backgroundColor:enable': {
          title: '#2 Background color',
          type: 'boolean',
          description:
            '[remoteAssistanceComponent.backgroundColor] - RGBA / RGB / Hex / Color name',
        },
        backgroundColor: {
          type: 'string',
        },
      },
    },
    phoneVerificationScreen: {
      type: 'object',
      title: '#34 Phone verification screen',
      properties: {
        'backgroundColor:enable': {
          title: '#1 Background color',
          type: 'boolean',
          description: '[phoneVerificationScreen.backgroundColor] - RGBA / RGB / Hex / Color name',
        },
        backgroundColor: {
          type: 'string',
        },
      },
    },
    failedRequirementsOverlay: {
      type: 'object',
      title: '#35 Failed requirements overlay',
      properties: {
        'backgroundColor:enable': {
          title: '#1 Background color',
          type: 'boolean',
          description:
            '[failedRequirementsOverlay.backgroundColor] - RGBA / RGB / Hex / Color name',
        },
        backgroundColor: {
          type: 'string',
        },
        'textColor:enable': {
          title: '#2 Background color',
          type: 'boolean',
          description: '[failedRequirementsOverlay.textColor] - RGBA / RGB / Hex / Color name',
        },
        textColor: {
          type: 'string',
        },
        'buttonTextColor:enable': {
          title: '#3 Background color',
          type: 'boolean',
          description:
            '[failedRequirementsOverlay.buttonTextColor] - RGBA / RGB / Hex / Color name',
        },
        buttonTextColor: {
          type: 'string',
        },
      },
    },
    faceLivenessScreen: {
      type: 'object',
      title: '#36 Face liveness screen',
      properties: {
        'instructionImageUrl:enable': {
          title: '#1 Instruction image URL',
          type: 'boolean',
          description: '[faceLivenessScreen.instructionImageUrl]',
        },
        instructionImageUrl: {
          type: 'string',
        },
        'instructionVideoUrl:enable': {
          title: '#2 Instruction video URL',
          type: 'boolean',
          description: '[faceLivenessScreen.instructionVideoUrl]',
        },
        instructionVideoUrl: {
          type: 'string',
        },
        'instructionPanelBgColor:enable': {
          title: '#3 Instruction panel background color',
          type: 'boolean',
          description: '[faceLivenessScreen.instructionPanelBgColor] - Hex',
        },
        instructionPanelBgColor: {
          type: 'string',
        },
        'instructionPanelBorderColor:enable': {
          title: '#4 Instruction panel border color',
          type: 'boolean',
          description: '[faceLivenessScreen.instructionPanelBorderColor] - Hex',
        },
        instructionPanelBorderColor: {
          type: 'string',
        },
        'logoImage:enable': {
          title: '#5 Logo image',
          type: 'boolean',
          description: '[faceLivenessScreen.logoImage]',
        },
        logoImage: {
          type: 'string',
        },
        'homeImage:enable': {
          title: '#6 Home image',
          type: 'boolean',
          description: '[faceLivenessScreen.homeImage]',
        },
        homeImage: {
          type: 'string',
        },
        'settingImage:enable': {
          title: '#7 Setting image',
          type: 'boolean',
          description: '[faceLivenessScreen.settingImage]',
        },
        settingImage: {
          type: 'string',
        },
        'remoteAssistanceImage:enable': {
          title: '#8 Remote assistance image',
          type: 'boolean',
          description: '[faceLivenessScreen.remoteAssistanceImage]',
        },
        remoteAssistanceImage: {
          type: 'string',
        },
        'shouldDisplayBackAlert:enable': {
          title: '#9 Should display back alert',
          type: 'boolean',
          description: '[faceLivenessScreen.shouldDisplayBackAlert]',
        },
        shouldDisplayBackAlert: {
          type: undefined,
          enum: [true, false],
        },
        'instructionDescriptionTextColor:enable': {
          title: '#10 Instruction description text color',
          type: 'boolean',
          description: '[faceLivenessScreen.instructionDescriptionTextColor] - Hex',
        },
        instructionDescriptionTextColor: {
          type: 'string',
        },
        'instructionDescriptionTextFontSize:enable': {
          title: '#11 Instruction description text font size',
          type: 'boolean',
          description: '[faceLivenessScreen.instructionDescriptionTextFontSize]',
        },
        instructionDescriptionTextFontSize: {
          type: 'number',
        },
        'instructionDescriptionTextFontWight:enable': {
          title: '#12 Instruction description text font weight',
          type: 'boolean',
          description: '[faceLivenessScreen.instructionDescriptionTextFontWight]',
        },
        instructionDescriptionTextFontWight: {
          type: undefined,
          enum: ['normal', 'bold', '100', '200', '300', '400', '500', '600', '700', '800', '900'],
        },
        'instructionDescriptionTextAlignment:enable': {
          title: '#13 Instruction description text alignment',
          type: 'boolean',
          description: '[faceLivenessScreen.instructionDescriptionTextAlignment]',
        },
        instructionDescriptionTextAlignment: {
          type: undefined,
          enum: ['auto', 'left', 'right', 'center', 'justify'],
        },
        'instructionTextColor:enable': {
          title: '#14 Instruction text color',
          type: 'boolean',
          description: '[faceLivenessScreen.instructionTextColor] - Hex',
        },
        instructionTextColor: {
          type: 'string',
        },
        'instructionTextFontSize:enable': {
          title: '#15 Instruction text font size',
          type: 'boolean',
          description: '[faceLivenessScreen.instructionTextFontSize]',
        },
        instructionTextFontSize: {
          type: 'number',
        },
        'instructionTextFontWight:enable': {
          title: '#16 Instruction text font weight',
          type: 'boolean',
          description: '[faceLivenessScreen.instructionTextFontWight]',
        },
        instructionTextFontWight: {
          type: undefined,
          enum: ['normal', 'bold', '100', '200', '300', '400', '500', '600', '700', '800', '900'],
        },
        'instructionTextAlignment:enable': {
          title: '#17 Instruction text alignment',
          type: 'boolean',
          description: '[faceLivenessScreen.instructionTextAlignment]',
        },
        instructionTextAlignment: {
          type: undefined,
          enum: ['auto', 'left', 'right', 'center', 'justify'],
        },
        'headerTextColor:enable': {
          title: '#18 Header text color',
          type: 'boolean',
          description: '[faceLivenessScreen.headerTextColor] - Hex',
        },
        headerTextColor: {
          type: 'string',
        },
        'headerTextFontSize:enable': {
          title: '#19 Header text font size',
          type: 'boolean',
          description: '[faceLivenessScreen.headerTextFontSize]',
        },
        headerTextFontSize: {
          type: 'number',
        },
        'headerTextFontWight:enable': {
          title: '#20 Header text font weight',
          type: 'boolean',
          description: '[faceLivenessScreen.headerTextFontWight]',
        },
        headerTextFontWight: {
          type: undefined,
          enum: ['normal', 'bold', '100', '200', '300', '400', '500', '600', '700', '800', '900'],
        },
        'headerTextAlignment:enable': {
          title: '#21 Header text alignment',
          type: 'boolean',
          description: '[faceLivenessScreen.headerTextAlignment]',
        },
        headerTextAlignment: {
          type: undefined,
          enum: ['auto', 'left', 'right', 'center', 'justify'],
        },
        'subheaderTextColor:enable': {
          title: '#22 Subheader text color',
          type: 'boolean',
          description: '[faceLivenessScreen.subheaderTextColor] - Hex',
        },
        subheaderTextColor: {
          type: 'string',
        },
        'subheaderTextFontSize:enable': {
          title: '#23 Subheader text font size',
          type: 'boolean',
          description: '[faceLivenessScreen.subheaderTextFontSize]',
        },
        subheaderTextFontSize: {
          type: 'number',
        },
        'subheaderTextFontWight:enable': {
          title: '#24 Subheader text font weight',
          type: 'boolean',
          description: '[faceLivenessScreen.subheaderTextFontWight]',
        },
        subheaderTextFontWight: {
          type: undefined,
          enum: ['normal', 'bold', '100', '200', '300', '400', '500', '600', '700', '800', '900'],
        },
        'subheaderTextAlignment:enable': {
          title: '#25 Subheader text alignment',
          type: 'boolean',
          description: '[faceLivenessScreen.subheaderTextAlignment]',
        },
        subheaderTextAlignment: {
          type: undefined,
          enum: ['auto', 'left', 'right', 'center', 'justify'],
        },
        'cameraTextColor:enable': {
          title: '#26 Camera text color',
          type: 'boolean',
          description: '[faceLivenessScreen.cameraTextColor] - Hex',
        },
        cameraTextColor: {
          type: 'string',
        },
        'cameraTextFontSize:enable': {
          title: '#27 Camera text font size',
          type: 'boolean',
          description: '[faceLivenessScreen.cameraTextFontSize]',
        },
        cameraTextFontSize: {
          type: 'number',
        },
        'cameraTextFontWight:enable': {
          title: '#28 Camera text font weight',
          type: 'boolean',
          description: '[faceLivenessScreen.cameraTextFontWight]',
        },
        cameraTextFontWight: {
          type: undefined,
          enum: ['normal', 'bold', '100', '200', '300', '400', '500', '600', '700', '800', '900'],
        },
        'cameraTextAlignment:enable': {
          title: '#29 Camera text alignment',
          type: 'boolean',
          description: '[faceLivenessScreen.cameraTextAlignment]',
        },
        cameraTextAlignment: {
          type: undefined,
          enum: ['auto', 'left', 'right', 'center', 'justify'],
        },
        'backIconUrl:enable': {
          title: '#30 Back icon URL',
          type: 'boolean',
          description: '[faceLivenessScreen.backIconUrl]',
        },
        backIconUrl: {
          type: 'string',
        },
        'backTextColor:enable': {
          title: '#31 Back text color',
          type: 'boolean',
          description: '[faceLivenessScreen.backTextColor] - Hex',
        },
        backTextColor: {
          type: 'string',
        },
        'backTextFontSize:enable': {
          title: '#32 Back text font size',
          type: 'boolean',
          description: '[faceLivenessScreen.backTextFontSize]',
        },
        backTextFontSize: {
          type: 'number',
        },
        'backTextFontWight:enable': {
          title: '#33 Back text font weight',
          type: 'boolean',
          description: '[faceLivenessScreen.backTextFontWight]',
        },
        backTextFontWight: {
          type: undefined,
          enum: ['normal', 'bold', '100', '200', '300', '400', '500', '600', '700', '800', '900'],
        },
        'backTextAlignment:enable': {
          title: '#34 Back text alignment',
          type: 'boolean',
          description: '[faceLivenessScreen.backTextAlignment]',
        },
        backTextAlignment: {
          type: undefined,
          enum: ['auto', 'left', 'right', 'center', 'justify'],
        },
        'startButtonBackgroundColor:enable': {
          title: '#35 Start button background color',
          type: 'boolean',
          description: '[faceLivenessScreen.startButtonBackgroundColor] - Hex',
        },
        startButtonBackgroundColor: {
          type: 'string',
        },
        'startButtonTextColor:enable': {
          title: '#36 Start button text color',
          type: 'boolean',
          description: '[faceLivenessScreen.startButtonTextColor] - Hex',
        },
        startButtonTextColor: {
          type: 'string',
        },
        'faceMatchFailedDescriptionTextColor:enable': {
          title: '#37 Face match failed description text color',
          type: 'boolean',
          description: '[faceLivenessScreen.faceMatchFailedDescriptionTextColor] - Hex',
        },
        faceMatchFailedDescriptionTextColor: {
          type: 'string',
        },
        'faceMatchFailedDescriptionTextFontSize:enable': {
          title: '#38 Face match failed description text font size',
          type: 'boolean',
          description: '[faceLivenessScreen.faceMatchFailedDescriptionTextFontSize]',
        },
        faceMatchFailedDescriptionTextFontSize: {
          type: 'number',
        },
        'faceMatchFailedDescriptionTextFontWight:enable': {
          title: '#39 Face match failed description text font weight',
          type: 'boolean',
          description: '[faceLivenessScreen.faceMatchFailedDescriptionTextFontWight]',
        },
        faceMatchFailedDescriptionTextFontWight: {
          type: undefined,
          enum: ['normal', 'bold', '100', '200', '300', '400', '500', '600', '700', '800', '900'],
        },
        'faceMatchFailedDescriptionTextAlignment:enable': {
          title: '#40 Face match failed description text alignment',
          type: 'boolean',
          description: '[faceLivenessScreen.faceMatchFailedDescriptionTextAlignment]',
        },
        faceMatchFailedDescriptionTextAlignment: {
          type: undefined,
          enum: ['auto', 'left', 'right', 'center', 'justify'],
        },
        'retryButtonBackgroundColor:enable': {
          title: '#41 Retry button background color',
          type: 'boolean',
          description: '[faceLivenessScreen.retryButtonBackgroundColor] - Hex',
        },
        retryButtonBackgroundColor: {
          type: 'string',
        },
        'retryButtonTextColor:enable': {
          title: '#42 Retry button text color',
          type: 'boolean',
          description: '[faceLivenessScreen.retryButtonTextColor] - Hex',
        },
        retryButtonTextColor: {
          type: 'string',
        },
        'endSessionButtonBackgroundColor:enable': {
          title: '#43 End session button background color',
          type: 'boolean',
          description: '[faceLivenessScreen.endSessionButtonBackgroundColor] - Hex',
        },
        endSessionButtonBackgroundColor: {
          type: 'string',
        },
        'endSessionButtonTextColor:enable': {
          title: '#44 End session button text color',
          type: 'boolean',
          description: '[faceLivenessScreen.endSessionButtonTextColor] - Hex',
        },
        endSessionButtonTextColor: {
          type: 'string',
        },
        'faceMatchFailedImageUrl:enable': {
          title: '#45 Face match failed image URL',
          type: 'boolean',
          description: '[faceLivenessScreen.faceMatchFailedImageUrl]',
        },
        faceMatchFailedImageUrl: {
          type: 'string',
        },
        'faceLivenessFailedImageUrl:enable': {
          title: '#46 Face liveness failed image URL',
          type: 'boolean',
          description: '[faceLivenessScreen.faceLivenessFailedImageUrl]',
        },
        faceLivenessFailedImageUrl: {
          type: 'string',
        },
        'rewatchButtonTextColor:enable': {
          title: '#47 Rewatch button text color',
          type: 'boolean',
          description: '[faceLivenessScreen.rewatchButtonTextColor] - Hex',
        },
        rewatchButtonTextColor: {
          type: 'string',
        },
        'rewatchButtonTextFontSize:enable': {
          title: '#48 Rewatch button text font size',
          type: 'boolean',
          description: '[faceLivenessScreen.rewatchButtonTextFontSize]',
        },
        rewatchButtonTextFontSize: {
          type: 'number',
        },
        'rewatchButtonTextFontWight:enable': {
          title: '#49 Rewatch button text font weight',
          type: 'boolean',
          description: '[faceLivenessScreen.rewatchButtonTextFontWight]',
        },
        rewatchButtonTextFontWight: {
          type: undefined,
          enum: ['normal', 'bold', '100', '200', '300', '400', '500', '600', '700', '800', '900'],
        },
        'ovalThreshold:enable': {
          title: '#50 Oval threshold',
          type: 'boolean',
          description: '[faceLivenessScreen.ovalThreshold] - default is 2.0',
        },
        ovalThreshold: {
          type: 'number',
        },
        'maxRunningCount:enable': {
          title: '#51 Maximum running count',
          type: 'boolean',
          description: '[faceLivenessScreen.maxRunningCount] - default is 2',
        },
        maxRunningCount: {
          type: 'number',
        },
      },
    },
    paymentConfig: {
      type: 'object',
      title: '#37 Payment Config',
      properties: {
        'shouldIgnoreReverseOnPreAuthorization:enable': {
          title: '#1 Should ignore reversal on pre-authorization',
          type: 'boolean',
          description: '[paymentConfig.shouldIgnoreReverseOnPreAuthorization]',
        },
        shouldIgnoreReverseOnPreAuthorization: {
          type: undefined,
          enum: [true, false],
        },
        'timedOutTransactionInMilliSeconds:enable': {
          title: '#2 Timed out transaction in milliseconds',
          type: 'boolean',
          description: '[paymentConfig.timedOutTransactionInMilliSeconds]',
        },
        timedOutTransactionInMilliSeconds: {
          type: 'number',
        },
        'disableCancelTransaction:enable': {
          title: '#3 Disable cancelling transaction after timed out',
          type: 'boolean',
          description: '[paymentConfig.disableCancelTransaction]',
        },
        disableCancelTransaction: {
          type: undefined,
          enum: [true, false],
        },
      },
    },
    remoteAssistanceButton: {
      type: 'object',
      title: '#38 Remote Assistance Button',
      properties: {
        'topRightRemoteAssistanceButtonVisible:enable': {
          title: '#1 Display Top Right Remote Assistance Button Visible',
          type: 'boolean',
          description:
            'Display Top Right Remote Assistance Button Visible. [remoteAssistanceButton.topRightRemoteAssistanceButtonVisible]',
        },
        topRightRemoteAssistanceButtonVisible: {
          type: undefined,
          enum: [true, false],
        },
        'topRightRaButtonBorderColor:enable': {
          title: '#2 Top Right Remote Assistance Button Border Color',
          type: 'boolean',
          description:
            'Display Top Right Remote Assistance Button Border Color [remoteAssistanceButton.topRightRaButtonBorderColor]',
        },
        topRightRaButtonBorderColor: {
          type: 'string',
        },
        'topRightRaButtonBorderWidth:enable': {
          title: '#3 Top Right Remote Assistance Button Border Width',
          type: 'boolean',
          description:
            'Display Top Right Remote Assistance Button Border Width [remoteAssistanceButton.topRightRaButtonBorderWidth]',
        },
        topRightRaButtonBorderWidth: {
          type: 'number',
        },
        'topRightRaButtonBorderRadius:enable': {
          title: '#4 Top Right Remote Assistance Button Border Radius',
          type: 'boolean',
          description:
            'Display Top Right Remote Assistance Button Border Radius [remoteAssistanceButton.topRightRaButtonBorderRadius]',
        },
        topRightRaButtonBorderRadius: {
          type: 'number',
        },
        'topRightRaButtonTextColor:enable': {
          title: '#5 Top Right Remote Assistance Button Text Color',
          type: 'boolean',
          description:
            'Display Top Right Remote Assistance Button Text Color [remoteAssistanceButton.topRightRaButtonTextColor]',
        },
        topRightRaButtonTextColor: {
          type: 'string',
        },
        'topRightRaButtonBackgroundColor:enable': {
          title: '#6 Top Right Remote Assistance Button Background Color',
          type: 'boolean',
          description:
            'Display Top Right Remote Assistance Button Background Color [remoteAssistanceButton.topRightRaButtonBackgroundColor]',
        },
        topRightRaButtonBackgroundColor: {
          type: 'string',
        },
        'topRightRaButtonIcon:enable': {
          title: '#7 Top Right Remote Assistance Button Icon name or Url',
          type: 'boolean',
          description:
            'Display Top Right Remote Assistance Button Icon name or Url [remoteAssistanceButton.topRightRaButtonIcon]',
        },
        topRightRaButtonIcon: {
          type: 'string',
        },
        'topRightRaButtonIconColor:enable': {
          title: '#8 Top Right Remote Assistance Button Icon Color',
          type: 'boolean',
          description:
            'Display Top Right Remote Assistance Button Icon Color [remoteAssistanceButton.topRightRaButtonIconColor]',
        },
        topRightRaButtonIconColor: {
          type: 'string',
        },
        'topRightRaButtonIconRadius:enable': {
          title: '#9 Top Right Remote Assistance Button Icon Radius',
          type: 'boolean',
          description:
            'Display Top Right Remote Assistance Button Icon Radius [remoteAssistanceButton.topRightRaButtonIconRadius]',
        },
        topRightRaButtonIconRadius: {
          type: 'number',
        },
        'topRightRaButtonIconBackgroundColor:enable': {
          title: '#10 Top Right Remote Assistance Button Icon Background Color',
          type: 'boolean',
          description:
            'Display Top Right Remote Assistance Button Icon Background Color [remoteAssistanceButton.topRightRaButtonIconBackgroundColor]',
        },
        topRightRaButtonIconBackgroundColor: {
          type: 'string',
        },
        'topRightRaButtonIconBorderRadius:enable': {
          title: '#11 Top Right Remote Assistance Button Icon Radius',
          type: 'boolean',
          description:
            'Display Top Right Remote Assistance Button Icon Radius [remoteAssistanceButton.topRightRaButtonIconBorderRadius]',
        },
        topRightRaButtonIconBorderRadius: {
          type: 'number',
        },
        'topRightRaButtonWidth:enable': {
          title: '#12 Top Right Remote Assistance Button width',
          type: 'boolean',
          description:
            'Display Top Right Remote Assistance Button width [remoteAssistanceButton.topRightRaButtonWidth]',
        },
        topRightRaButtonWidth: {
          type: 'number',
        },
        'topRightRaButtonHeight:enable': {
          title: '#13 Top Right Remote Assistance Button height',
          type: 'boolean',
          description:
            'Display Top Right Remote Assistance Button height [remoteAssistanceButton.topRightRaButtonHeight]',
        },
        topRightRaButtonHeight: {
          type: 'number',
        },
        'topRightRaButtonTextFontSize:enable': {
          title: '#14 Top Right Remote Assistance Button text font size',
          type: 'boolean',
          description:
            'Display Top Right Remote Assistance Button text font size [remoteAssistanceButton.topRightRaButtonTextFontSize]',
        },
        topRightRaButtonTextFontSize: {
          type: 'number',
        },
        'menuRemoteAssistanceButtonVisible:enable': {
          title: '#15 Display Menu Remote Assistance Button Visible',
          type: 'boolean',
          description:
            'Display Menu Remote Assistance Button Visible. [remoteAssistanceButton.menuRemoteAssistanceButtonVisible]',
        },
        menuRemoteAssistanceButtonVisible: {
          type: undefined,
          enum: [true, false],
        },
        'menuRaButtonBorderColor:enable': {
          title: '#16 Menu Remote Assistance Button Border Color',
          type: 'boolean',
          description:
            'Display Menu Remote Assistance Button Border Color [remoteAssistanceButton.menuRaButtonBorderColor]',
        },
        menuRaButtonBorderColor: {
          type: 'string',
        },
        'menuRaButtonBorderWidth:enable': {
          title: '#17 Menu Remote Assistance Button Border Width',
          type: 'boolean',
          description:
            'Display Menu Remote Assistance Button Border Width [remoteAssistanceButton.menuRaButtonBorderWidth]',
        },
        menuRaButtonBorderWidth: {
          type: 'number',
        },
        'menuRaButtonBorderRadius:enable': {
          title: '#18 Menu Remote Assistance Button Border Radius',
          type: 'boolean',
          description:
            'Display Menu Remote Assistance Button Border Radius [remoteAssistanceButton.menuRaButtonBorderRadius]',
        },
        menuRaButtonBorderRadius: {
          type: 'number',
        },
        'menuRaButtonTextColor:enable': {
          title: '#19 Menu Remote Assistance Button Text Color',
          type: 'boolean',
          description:
            'Display Menu Remote Assistance Button Text Color [remoteAssistanceButton.menuRaButtonTextColor]',
        },
        menuRaButtonTextColor: {
          type: 'string',
        },
        'menuRaButtonBackgroundColor:enable': {
          title: '#20 Menu Remote Assistance Button Background Color',
          type: 'boolean',
          description:
            'Display Menu Remote Assistance Button Background Color [remoteAssistanceButton.menuRaButtonBackgroundColor]',
        },
        menuRaButtonBackgroundColor: {
          type: 'string',
        },
        'menuRaButtonIcon:enable': {
          title: '#21 Menu Remote Assistance Button Icon name or Url',
          type: 'boolean',
          description:
            'Display Menu Remote Assistance Button Icon name or Url [remoteAssistanceButton.menuRaButtonIcon]',
        },
        menuRaButtonIcon: {
          type: 'string',
        },
        'menuRaButtonIconColor:enable': {
          title: '#22 Menu Remote Assistance Button Icon Color',
          type: 'boolean',
          description:
            'Display Menu Remote Assistance Button Icon Color [remoteAssistanceButton.menuRaButtonIconColor]',
        },
        menuRaButtonIconColor: {
          type: 'string',
        },
        'menuRaButtonIconRadius:enable': {
          title: '#23 Menu Remote Assistance Button Icon Radius',
          type: 'boolean',
          description:
            'Display Menu Remote Assistance Button Icon Radius [remoteAssistanceButton.menuRaButtonIconRadius]',
        },
        menuRaButtonIconRadius: {
          type: 'number',
        },
        'menuRaButtonIconBackgroundColor:enable': {
          title: '#24 Menu Assistance Button Icon Background Color',
          type: 'boolean',
          description:
            'Display Menu Remote Assistance Button Icon Background Color [remoteAssistanceButton.menuRaButtonIconBackgroundColor]',
        },
        menuRaButtonIconBackgroundColor: {
          type: 'string',
        },
        'menuRaButtonIconBorderRadius:enable': {
          title: '#25 Menu Remote Assistance Button Icon Border Radius',
          type: 'boolean',
          description:
            'Display Menu Remote Assistance Button Icon Border Radius [remoteAssistanceButton.menuRaButtonIconBorderRadius]',
        },
        menuRaButtonIconBorderRadius: {
          type: 'number',
        },
        'menuRaButtonTextFontSize:enable': {
          title: '#26 Menu Remote Assistance Button text font size',
          type: 'boolean',
          description:
            'Display Menu Remote Assistance Button text font size [remoteAssistanceButton.menuRaButtonTextFontSize]',
        },
        menuRaButtonTextFontSize: {
          type: 'number',
        },
      },
    },
    verifyingRequirementsScreen: {
      type: 'object',
      title: '#39 Verifying requirements screen',
      properties: {
        featureFlags: {
          type: 'object',
          title: '#39.1 Feature flags',
          properties: {
            'loadingV2:enable': {
              title: '#39.1.1 Enable loading screen V2',
              type: 'boolean',
              description:
                'Using checking all requirements loading screen V2 [verifyingRequirementsScreen.featureFlags.loadingV2]',
            },
            loadingV2: {
              type: undefined,
              enum: [true, false],
            },
          },
        },
      },
    },
    jumioFaceLivenessScreen: {
      type: 'object',
      title: '#40 Jumio Face Liveness screen',
      properties: {
        iproov: {
          type: 'object',
          title: '#1 iProov',
          properties: {
            'filterForegroundColor:enable': {
              title: '#1 Filter foreground color',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.iproov.filterForegroundColor] - RGBA / RGB / Hex / Color name',
            },
            filterForegroundColor: {
              type: 'string',
            },
            'filterBackgroundColor:enable': {
              title: '#2 Filter background color',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.iproov.filterBackgroundColor] - RGBA / RGB / Hex / Color name',
            },
            filterBackgroundColor: {
              type: 'string',
            },
            'titleTextColor:enable': {
              title: '#3 Title text color',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.iproov.titleTextColor] - RGBA / RGB / Hex / Color name',
            },
            titleTextColor: {
              type: 'string',
            },
            'closeButtonTintColor:enable': {
              title: '#4 Close button color',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.iproov.closeButtonTintColor] - RGBA / RGB / Hex / Color name',
            },
            closeButtonTintColor: {
              type: 'string',
            },
            'surroundColor:enable': {
              title: '#5 Surround color',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.iproov.surroundColor] - RGBA / RGB / Hex / Color name',
            },
            surroundColor: {
              type: 'string',
            },
            'promptTextColor:enable': {
              title: '#6 Prompt text color',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.iproov.promptTextColor] - RGBA / RGB / Hex / Color name',
            },
            promptTextColor: {
              type: 'string',
            },
            'promptBackgroundColor:enable': {
              title: '#7 Prompt background color',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.iproov.promptBackgroundColor] - RGBA / RGB / Hex / Color name',
            },
            promptBackgroundColor: {
              type: 'string',
            },
            'genuinePresenceAssuranceReadyOvalStrokeColor:enable': {
              title: '#8 Genuine presence assurance ready oval stroke color',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.iproov.genuinePresenceAssuranceReadyOvalStrokeColor] - RGBA / RGB / Hex / Color name',
            },
            genuinePresenceAssuranceReadyOvalStrokeColor: {
              type: 'string',
            },
            'genuinePresenceAssuranceNotReadyOvalStrokeColor:enable': {
              title: '#9 Genuine presence assurance not ready oval stroke color',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.iproov.genuinePresenceAssuranceNotReadyOvalStrokeColor] - RGBA / RGB / Hex / Color name',
            },
            genuinePresenceAssuranceNotReadyOvalStrokeColor: {
              type: 'string',
            },
            'livenessAssuranceOvalStrokeColor:enable': {
              title: '#10 Liveness assurance oval stroke color',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.iproov.livenessAssuranceOvalStrokeColor] - RGBA / RGB / Hex / Color name',
            },
            livenessAssuranceOvalStrokeColor: {
              type: 'string',
            },
            'livenessAssuranceCompletedOvalStrokeColor:enable': {
              title: '#11 Liveness assurance completed oval stroke color',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.iproov.livenessAssuranceCompletedOvalStrokeColor] - RGBA / RGB / Hex / Color name',
            },
            livenessAssuranceCompletedOvalStrokeColor: {
              type: 'string',
            },
          },
        },
        primaryButton: {
          type: 'object',
          title: '#2 Primary button',
          properties: {
            'background:enable': {
              title: '#1 Background',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.primaryButton.background] - RGBA / RGB / Hex / Color name',
            },
            background: {
              type: 'string',
            },
            'backgroundPressed:enable': {
              title: '#2 Background pressed',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.primaryButton.backgroundPressed] - RGBA / RGB / Hex / Color name',
            },
            backgroundPressed: {
              type: 'string',
            },
            'backgroundDisabled:enable': {
              title: '#3 Background disabled',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.primaryButton.backgroundDisabled] - RGBA / RGB / Hex / Color name',
            },
            backgroundDisabled: {
              type: 'string',
            },
            'foreground:enable': {
              title: '#4 Foreground',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.primaryButton.foreground] - RGBA / RGB / Hex / Color name',
            },
            foreground: {
              type: 'string',
            },
            'foregroundPressed:enable': {
              title: '#5 Foreground pressed',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.primaryButton.foregroundPressed] - RGBA / RGB / Hex / Color name',
            },
            foregroundPressed: {
              type: 'string',
            },
            'foregroundDisabled:enable': {
              title: '#6 Foreground disabled',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.primaryButton.foregroundDisabled] - RGBA / RGB / Hex / Color name',
            },
            foregroundDisabled: {
              type: 'string',
            },
          },
        },
        secondaryButton: {
          type: 'object',
          title: '#3 Secondary button',
          properties: {
            'background:enable': {
              title: '#1 Background',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.secondaryButton.background] - RGBA / RGB / Hex / Color name',
            },
            background: {
              type: 'string',
            },
            'backgroundPressed:enable': {
              title: '#2 Background pressed',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.secondaryButton.backgroundPressed] - RGBA / RGB / Hex / Color name',
            },
            backgroundPressed: {
              type: 'string',
            },
            'backgroundDisabled:enable': {
              title: '#3 Background disabled',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.secondaryButton.backgroundDisabled] - RGBA / RGB / Hex / Color name',
            },
            backgroundDisabled: {
              type: 'string',
            },
            'foreground:enable': {
              title: '#4 Foreground',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.secondaryButton.foreground] - RGBA / RGB / Hex / Color name',
            },
            foreground: {
              type: 'string',
            },
          },
        },
        bubble: {
          type: 'object',
          title: '#4 Bubble',
          properties: {
            'background:enable': {
              title: '#1 Background',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.bubble.background] - RGBA / RGB / Hex / Color name',
            },
            background: {
              type: 'string',
            },
            'foreground:enable': {
              title: '#2 Foreground',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.bubble.foreground] - RGBA / RGB / Hex / Color name',
            },
            foreground: {
              type: 'string',
            },
            'backgroundSelected:enable': {
              title: '#3 Background selected',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.bubble.backgroundSelected] - RGBA / RGB / Hex / Color name',
            },
            backgroundSelected: {
              type: 'string',
            },
            'outline:enable': {
              title: '#4 Outline',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.bubble.outline] - RGBA / RGB / Hex / Color name',
            },
            outline: {
              type: 'string',
            },
          },
        },
        loading: {
          type: 'object',
          title: '#5 Loading',
          properties: {
            'circlePlain:enable': {
              title: '#1 Circle plain',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.loading.circlePlain] - RGBA / RGB / Hex / Color name',
            },
            circlePlain: {
              type: 'string',
            },
            'loadingCircleGradientStart:enable': {
              title: '#2 Loading circle gradient start',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.loading.loadingCircleGradientStart] - RGBA / RGB / Hex / Color name',
            },
            loadingCircleGradientStart: {
              type: 'string',
            },
            'loadingCircleGradientEnd:enable': {
              title: '#3 Loading circle gradient end',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.loading.loadingCircleGradientEnd] - RGBA / RGB / Hex / Color name',
            },
            loadingCircleGradientEnd: {
              type: 'string',
            },
            'errorCircleGradientStart:enable': {
              title: '#4 Error circle gradient start',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.loading.errorCircleGradientStart] - RGBA / RGB / Hex / Color name',
            },
            errorCircleGradientStart: {
              type: 'string',
            },
            'errorCircleGradientEnd:enable': {
              title: '#5 Error circle gradient end',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.loading.errorCircleGradientEnd] - RGBA / RGB / Hex / Color name',
            },
            errorCircleGradientEnd: {
              type: 'string',
            },
            'circleIcon:enable': {
              title: '#6 Circle icon',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.loading.circleIcon] - RGBA / RGB / Hex / Color name',
            },
            circleIcon: {
              type: 'string',
            },
          },
        },
        scanOverlay: {
          type: 'object',
          title: '#6 Scan overlay',
          properties: {
            'scanOverlay:enable': {
              title: '#1 Scan overlay',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.scanOverlay.scanOverlay] - RGBA / RGB / Hex / Color name',
            },
            scanOverlay: {
              type: 'string',
            },
            'scanBackground:enable': {
              title: '#2 Scan background',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.scanOverlay.scanBackground] - RGBA / RGB / Hex / Color name',
            },
            scanBackground: {
              type: 'string',
            },
            'livenessStroke:enable': {
              title: '#3 Liveness stroke',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.scanOverlay.livenessStroke] - RGBA / RGB / Hex / Color name',
            },
            livenessStroke: {
              type: 'string',
            },
            'livenessStrokeAnimation:enable': {
              title: '#4 Liveness stroke animation',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.scanOverlay.livenessStrokeAnimation] - RGBA / RGB / Hex / Color name',
            },
            livenessStrokeAnimation: {
              type: 'string',
            },
            'livenessStrokeAnimationCompleted:enable': {
              title: '#5 Liveness stroke animation completed',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.scanOverlay.livenessStrokeAnimationCompleted] - RGBA / RGB / Hex / Color name',
            },
            livenessStrokeAnimationCompleted: {
              type: 'string',
            },
          },
        },
        nfc: {
          type: 'object',
          title: '#7 NFC',
          properties: {
            'passportCover:enable': {
              title: '#1 Passport cover',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.nfc.passportCover] - RGBA / RGB / Hex / Color name',
            },
            passportCover: {
              type: 'string',
            },
            'passportPageDark:enable': {
              title: '#2 Passport page dark',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.nfc.passportPageDark] - RGBA / RGB / Hex / Color name',
            },
            passportPageDark: {
              type: 'string',
            },
            'passportPageLight:enable': {
              title: '#3 Passport page light',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.nfc.passportPageLight] - RGBA / RGB / Hex / Color name',
            },
            passportPageLight: {
              type: 'string',
            },
            'passportForeground:enable': {
              title: '#4 Passport foreground',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.nfc.passportForeground] - RGBA / RGB / Hex / Color name',
            },
            passportForeground: {
              type: 'string',
            },
            'phoneCover:enable': {
              title: '#5 Phone cover',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.nfc.phoneCover] - RGBA / RGB / Hex / Color name',
            },
            phoneCover: {
              type: 'string',
            },
          },
        },
        scanView: {
          type: 'object',
          title: '#8 Scan view',
          properties: {
            'tooltipForeground:enable': {
              title: '#1 Tooltip foreground',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.scanView.tooltipForeground] - RGBA / RGB / Hex / Color name',
            },
            tooltipForeground: {
              type: 'string',
            },
            'tooltipBackground:enable': {
              title: '#2 Tooltip background',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.scanView.tooltipBackground] - RGBA / RGB / Hex / Color name',
            },
            tooltipBackground: {
              type: 'string',
            },
            'foreground:enable': {
              title: '#3 Foreground',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.scanView.foreground] - RGBA / RGB / Hex / Color name',
            },
            foreground: {
              type: 'string',
            },
            'darkForeground:enable': {
              title: '#4 Dark foreground',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.scanView.darkForeground] - RGBA / RGB / Hex / Color name',
            },
            darkForeground: {
              type: 'string',
            },
            'documentShutter:enable': {
              title: '#5 Document shutter',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.scanView.documentShutter] - RGBA / RGB / Hex / Color name',
            },
            documentShutter: {
              type: 'string',
            },
            'faceShutter:enable': {
              title: '#6 Face shutter',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.scanView.faceShutter] - RGBA / RGB / Hex / Color name',
            },
            faceShutter: {
              type: 'string',
            },
          },
        },
        searchBubble: {
          type: 'object',
          title: '#9 Search bubble',
          properties: {
            'background:enable': {
              title: '#1 Background',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.searchBubble.background] - RGBA / RGB / Hex / Color name',
            },
            background: {
              type: 'string',
            },
            'foreground:enable': {
              title: '#2 Foreground',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.searchBubble.foreground] - RGBA / RGB / Hex / Color name',
            },
            foreground: {
              type: 'string',
            },
          },
        },
        confirmation: {
          type: 'object',
          title: '#10 Confirmation',
          properties: {
            'imageBackground:enable': {
              title: '#1 Image background',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.confirmation.imageBackground] - RGBA / RGB / Hex / Color name',
            },
            imageBackground: {
              type: 'string',
            },
            'imageBackgroundBorder:enable': {
              title: '#2 Image background border',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.confirmation.imageBackgroundBorder] - RGBA / RGB / Hex / Color name',
            },
            imageBackgroundBorder: {
              type: 'string',
            },
            'indicatorActive:enable': {
              title: '#3 Indicator active',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.confirmation.indicatorActive] - RGBA / RGB / Hex / Color name',
            },
            indicatorActive: {
              type: 'string',
            },
            'indicatorDefault:enable': {
              title: '#4 Indicator default',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.confirmation.indicatorDefault] - RGBA / RGB / Hex / Color name',
            },
            indicatorDefault: {
              type: 'string',
            },
          },
        },
        scanHelp: {
          type: 'object',
          title: '#11 Scan help',
          properties: {
            'faceAnimationForeground:enable': {
              title: '#1 Face animation foreground',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.scanHelp.faceAnimationForeground] - RGBA / RGB / Hex / Color name',
            },
            faceAnimationForeground: {
              type: 'string',
            },
          },
        },
        global: {
          type: 'object',
          title: '#12 Global',
          properties: {
            'background:enable': {
              title: '#1 Background',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.global.background] - RGBA / RGB / Hex / Color name',
            },
            background: {
              type: 'string',
            },
            'navigationIconColor:enable': {
              title: '#2 Navigation icon color',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.global.navigationIconColor] - RGBA / RGB / Hex / Color name',
            },
            navigationIconColor: {
              type: 'string',
            },
            'textForegroundColor:enable': {
              title: '#3 Text foreground color',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.global.textForegroundColor] - RGBA / RGB / Hex / Color name',
            },
            textForegroundColor: {
              type: 'string',
            },
            'primaryColor:enable': {
              title: '#4 Primary color',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.global.primaryColor] - RGBA / RGB / Hex / Color name',
            },
            primaryColor: {
              type: 'string',
            },
            'selectionIconForeground:enable': {
              title: '#5 Selection icon foreground',
              type: 'boolean',
              description:
                '[jumioFaceLivenessScreen.global.selectionIconForeground] - RGBA / RGB / Hex / Color name',
            },
            selectionIconForeground: {
              type: 'string',
            },
          },
        },
      },
    },
    earlyCheckInScreen: {
      type: 'object',
      title: '#41 Early Check-in screen',
      properties: {
        featureFlags: {
          type: 'object',
          title: '#1 Feature flags',
          properties: {
            'earlyCheckInScreenEnabled:enable': {
              title: '#1.1 Enable Early CheckIn screen',
              type: 'boolean',
              description: '[earlyCheckInScreen.featureFlags.earlyCheckInScreenEnabled]',
            },
            earlyCheckInScreenEnabled: {
              type: undefined,
              enum: [true, false],
            },
          },
        },
        background: {
          type: 'object',
          title: '#2 Background configuration',
          properties: {
            'type:enable': {
              title: '#2.1 Background type',
              type: 'boolean',
              description: '[earlyCheckInScreen.background.type] - Color | Image',
            },
            type: {
              type: undefined,
              enum: ['Color', 'Image'],
              default: 'Color',
            },
            'color:enable': {
              title: '#2.2 Background color',
              type: 'boolean',
              description:
                '[earlyCheckInScreen.background.color] - RGBA / RGB / Hex / Color Name - Default: [general.backgroundColor]',
            },
            color: {
              type: 'string',
            },
            'imageUrl:enable': {
              title: '#2.3 Image URL',
              type: 'boolean',
              description: '[earlyCheckInScreen.background.imageUrl] - URL',
            },
            imageUrl: {
              type: 'string',
            },
            'overlayOpacity:enable': {
              title: '#2.4 Background image overlay opacity',
              type: 'boolean',
              description:
                '[earlyCheckInScreen.background.overlayOpacity] - Value between 0.0 and 1.0',
            },
            overlayOpacity: {
              type: undefined,
              enum: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
            },
          },
        },
        'mainTextColor:enable': {
          type: 'boolean',
          title: '#3 Main text color',
          description: '[earlyCheckInScreen.mainTextColor] - RGBA / RGB / Hex / Color Name',
        },
        mainTextColor: {
          type: 'string',
        },
        'declineButtonColor:enable': {
          type: 'boolean',
          title: '#4 Decline button color',
          description: '[earlyCheckInScrenn.declineButtonColor] - RGBA / RGB / Hex / Color Name',
        },
        declineButtonColor: {
          type: 'string',
        },
        'cardBackgroundColor:enable': {
          type: 'boolean',
          title: '#5 Card background color',
          description: '[earlyCheckInScreen.cardBackgroundColor] - RGBA / RGB / Hex / Color Name',
        },
        cardBackgroundColor: {
          type: 'string',
        },
        'cardTextColor:enable': {
          type: 'boolean',
          title: '#6 Card text color',
          description: '[earlyCheckInScreen.cardTextColor] - RGBA / RGB / Hex / Color Name',
        },
        cardTextColor: {
          type: 'string',
        },
        'acceptButtonBackgroundColor:enable': {
          type: 'boolean',
          title: '#7 Accept button background color',
          description:
            '[earlyCheckInScreen.acceptButtonBackgroundColor] - RGBA / RGB / Hex / Color Name',
        },
        acceptButtonBackgroundColor: {
          type: 'string',
        },
      },
    },
  },
};

export const kioskThemeCustomizationUiSchema = {
  general: {
    'ui:title': '‎',
    qrCodeUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    textColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    borderColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    buttonColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    shadowColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    logoImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    primaryColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    doneButtonType: {
      'ui:label': false,
      'ui:readonly': true,
    },
    secondaryColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    textInputColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    toastTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    backgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    backgroundImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    clearButtonType: {
      'ui:label': false,
      'ui:readonly': true,
    },
    primaryTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    updateButtonType: {
      'ui:label': false,
      'ui:readonly': true,
    },
    headerLogoImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    continueButtonType: {
      'ui:label': false,
      'ui:readonly': true,
    },
    secondaryTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    validateButtonType: {
      'ui:label': false,
      'ui:readonly': true,
    },
    resendCodeButtonType: {
      'ui:label': false,
      'ui:readonly': true,
    },
    toastBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    disableRemoteAssistance: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    idleTimeout: {
      'ui:label': false,
      'ui:readonly': true,
    },
    allowCheckInReservationWithoutRoom: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    defaultPhonePrefix: {
      'ui:label': false,
      'ui:readonly': true,
    },
    buttonTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    snackBarBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    snackBarTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    squareLocationId: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  scanQRCodeScreen: {
    'ui:title': '‎',
    useFrontCamera: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    enableFilterByQR: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    retakeTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    textColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
  },
  countDownPopup: {
    'ui:title': '‎',
    negativeButtonBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    positiveButtonBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    negativeButtonTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    positiveButtonTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    titleTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    textColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    timerProgressColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    timerTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    backgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
  },
  signatureScreen: {
    'ui:title': '‎',
    useNewLayout: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    tncCloseButtonColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    tncCloseButtonTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    titleColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    subtitleColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    textColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    enableRegCard: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    useSignatureWithUserInfoLayout: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    enableTermsAndCondition: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    errorTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    errorBorderColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    clearButtonType: {
      'ui:label': false,
      'ui:readonly': true,
    },
    clearButtonTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    clearButtonBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    clearButtonBorderColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    scrollToBottomIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    scrollToTopIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    userDataContainerBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    userDataItemBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    userDataLabelTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    userDataValueTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    clearButtonBorderWidth: {
      'ui:label': false,
      'ui:readonly': true,
    },
    disableSendDynamicUserInput: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    enableManualRequirementsUpdate: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    signatureDynamicKey: {
      'ui:label': false,
      'ui:readonly': true,
    },
    enableSendHiddenDynamicFields: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    disableSendSignatureOnRegCard: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    displayUserData: {
      'ui:label': false,
      'ui:title': '‎',
      'ui:readonly': true,
      items: {
        'ui:label': false,
      },
    },
    disableSignaturePrefix: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    enableSignatureResizeOnRegCard: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    signatureResizeWidth: {
      'ui:label': false,
      'ui:readonly': true,
    },
    signatureResizeHeight: {
      'ui:label': false,
      'ui:readonly': true,
    },
    agreements: {
      checked: {
        backgroundColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        borderColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        borderRadius: {
          'ui:label': false,
          'ui:readonly': true,
        },
        borderWidth: {
          'ui:label': false,
          'ui:readonly': true,
        },
        textColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        shadowColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        checkboxBorderColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        checkboxBackgroundColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        tickColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
      },
      unchecked: {
        backgroundColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        borderColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        borderRadius: {
          'ui:label': false,
          'ui:readonly': true,
        },
        borderWidth: {
          'ui:label': false,
          'ui:readonly': true,
        },
        textColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        shadowColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        checkboxBorderColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        checkboxBackgroundColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
      },
    },
  },
  popup: {
    'ui:title': '‎',
    popupTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    popupButtonColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    popupBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    popupButtonTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
  },
  homeScreen: {
    'ui:title': '‎',
    headerLogoImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    checkinIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    checkOutIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    printAnotherKeycardIcon: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    folioIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    titleTextSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    headerTextSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    descriptionTextSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    buttonTextSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    titleImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
    },
    containerAlignment: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    iconDirection: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    iconColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    isNewLayout: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    hasVetting: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    isVertical: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    buttonColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    displayQrCode: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    buttonTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    backgroundImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    logoBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    displayCheckoutButton: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    displayWelcomeText: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    textColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    shadowColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    isInstruction: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    displayFolioButton: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    displayPrintAnotherKeycardButton: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    welcomeTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    descriptionTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    instructionBorderColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },

    displayConciergeButton: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    buttonBorderColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    buttonBorderWidth: {
      'ui:label': false,
      'ui:readonly': true,
    },
    buttonFontSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    buttonFontWeight: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    conciergeIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    displayCheckinButton: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    iconBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    iconSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    iconBackgroundSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    iconBackgroundBorderRadius: {
      'ui:label': false,
      'ui:readonly': true,
    },
    topRightRemoteAssistanceButtonVisible: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    homeScreenLogoSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  vettingScreen: {
    'ui:title': '‎',
    shadowColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    buttonColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    buttonTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    backgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    textColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    unselectedOptionBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    unselectedOptionTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    selectedOptionBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    selectedOptionTextAndBorderColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    warningIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    remoteAssistanceButtonTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    remoteAssistanceButtonBorderColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    enableRemoteAssistance: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    warningIconColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    dividerColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
  },
  loyaltyScreen: {
    'ui:title': '‎',
    enabled: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    isNewLoyalty: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    newLoyaltyImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    loyaltyCards: {
      'ui:label': false,
      'ui:title': '‎',
      'ui:readonly': true,
      items: {
        image: {
          uri: {
            'ui:widget': 'uri',
            'ui:fieldtype': 'link',
          },
        },
      },
    },
    newLoyaltyImageConfig: {
      height: {
        'ui:label': false,
        'ui:readonly': true,
      },
      width: {
        'ui:label': false,
        'ui:readonly': true,
      },
      borderRadius: {
        'ui:label': false,
        'ui:readonly': true,
      },
      marginTop: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    joinButton: {
      buttonContentType: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
      icon: {
        iconName: {
          'ui:label': false,
          'ui:readonly': true,
        },
        iconColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        iconSize: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
      image: {
        imageUrl: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'uri',
          'ui:fieldtype': 'link',
        },
        height: {
          'ui:label': false,
          'ui:readonly': true,
        },
        width: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
      text: {
        textColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
      },
      width: {
        'ui:label': false,
        'ui:readonly': true,
      },
      height: {
        'ui:label': false,
        'ui:readonly': true,
      },
      backgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderRadius: {
        'ui:label': false,
        'ui:readonly': true,
      },
      borderWidth: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    skipButton: {
      buttonContentType: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
      icon: {
        iconName: {
          'ui:label': false,
          'ui:readonly': true,
        },
        iconColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        iconSize: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
      image: {
        imageUrl: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'uri',
          'ui:fieldtype': 'link',
        },
        height: {
          'ui:label': false,
          'ui:readonly': true,
        },
        width: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
      text: {
        textColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
      },
      width: {
        'ui:label': false,
        'ui:readonly': true,
      },
      height: {
        'ui:label': false,
        'ui:readonly': true,
      },
      backgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderRadius: {
        'ui:label': false,
        'ui:readonly': true,
      },
      borderWidth: {
        'ui:label': false,
        'ui:readonly': true,
      },
      alignment: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
      leftPadding: {
        'ui:label': false,
        'ui:readonly': true,
      },
      rightPadding: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    shadowColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    cardHeaderColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    cardHeaderAlign: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    cardDescriptionAlign: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    cardDescriptionColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    header: {
      color: {
        'ui:label': false,
        'ui:readonly': true,
      },
      fontSize: {
        'ui:label': false,
        'ui:readonly': true,
      },
      marginTop: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    cardHeaderFontSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    cardContentMargin: {
      'ui:label': false,
      'ui:readonly': true,
    },
    cardDescriptionFontSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  paymentScreen: {
    'ui:title': '‎',
    supportCards: {
      'ui:label': false,
      'ui:title': '‎',
      'ui:readonly': true,
      items: {
        name: {},
        image: {
          'ui:widget': 'uri',
          'ui:fieldtype': 'link',
        },
      },
    },
    buttonColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    startTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    subtitleTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    textColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    progressBarInactiveColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    progressBarActiveImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    backgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    supportI4Go: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    cardHeaderColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    closeIconColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    imageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    defaultUnitTypeImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
  },
  checkoutScreen: {
    'ui:title': '‎',
    backgroundUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    headerColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    borderColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    textColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    calendarIconColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    accessGrantCardColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    amountTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    totalTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    isHasFolio: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    visaIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    confirmationTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    confirmationBGColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    initialBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    folioBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    initialTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    amexIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    discoverIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    mastercardIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    dinersIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    maestroIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    unionPayIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    jcbIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    folioDateFormat: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  authSelectScreen: {
    'ui:title': '‎',
    displayQrCode: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    authMethods: {
      'ui:label': false,
      'ui:title': '‎',
      'ui:readonly': true,
      items: {
        flow: {
          'ui:label': true,
          'ui:widget': 'select',
        },
        displayEmailAuth: {
          'ui:label': true,
          'ui:widget': 'select',
        },
        displayQrCodeAuth: {
          'ui:label': true,
          'ui:widget': 'select',
        },
        displayPhoneNumberAuth: {
          'ui:label': true,
          'ui:widget': 'select',
        },
        displayIdVerificationAuth: {
          'ui:label': true,
          'ui:widget': 'select',
        },
        displayIdAuthenticationAuth: {
          'ui:label': true,
          'ui:widget': 'select',
        },
        displayNameVerification: {
          'ui:label': true,
          'ui:widget': 'select',
        },
        displayRoomNumberAndLastNameVerification: {
          'ui:label': true,
          'ui:widget': 'select',
        },
        displayNameAndDepartureDateAuth: {
          'ui:label': true,
          'ui:widget': 'select',
        },
        displayConfirmationCodeAuth: {
          'ui:label': true,
          'ui:widget': 'select',
        },
      },
    },
    displayIdVerificationAuth: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    displayIdAuthenticationAuth: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    displayRoomNumberAndLastNameVerification: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    nameAuthImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    roomNumberAuthImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    emailAuthImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    phoneNumberAuthImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    idAuthImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    qrAuthImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    mrzAuthImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    cardBorderColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    cardTopBorderColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    headerColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    iconColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    authCardTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    authCardBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    nameAndDepartureDateImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    confirmationCodeImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
  },
  requirementsScreen: {
    'ui:title': '‎',
    imageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    displayQrCode: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    shadowColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    cardBorderColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    cardBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    textColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    iconColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    onRequirementBackButton: {
      disableOnRequirementBackPopup: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
      enableFromFirstPosition: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
    },
    contactFrontDeskTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
  },
  keyDispensingScreen: {
    'ui:title': '‎',
    displayHelp: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    helpImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    additionalImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    keyCardImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    hideLogoOnKeyCard: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    offerMode: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    offerNewLayout: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    keyDispensingTimeout: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'updown',
    },
    countDownPopupTimeout: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'updown',
    },
    printKeycardErrorTimeout: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'updown',
    },
    offerQR: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    displayInfoBelowCard: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    hideInfoOnCard: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    displayRoomNumberOnCard: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    textColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    borderColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    logoImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    keycardBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    keycardSleeveBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    keycardShadowColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    keycardTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    expiresDateFormat: {
      'ui:label': false,
      'ui:readonly': true,
    },
    doneButtonType: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    animatedKeyCard: {
      keyCardDispensingFlavour: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:title': '‎',
        'ui:widget': 'select',
      },
      title: {
        textSize: {
          'ui:label': false,
          'ui:readonly': true,
        },
        textColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        showOptionalTitle: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'select',
        },
        showRoomInTitle: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'select',
        },
      },
      description: {
        textSize: {
          'ui:label': false,
          'ui:readonly': true,
        },
        textColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
      },
      footer: {
        textSize: {
          'ui:label': false,
          'ui:readonly': true,
        },
        textColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
      },
      animation: {
        backgroundColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        imageUrl: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'uri',
          'ui:fieldtype': 'link',
        },
      },
    },
    keyCardPrintSuccess: {
      layout: {
        screenLayoutType: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:title': '‎',
          'ui:widget': 'select',
        },
        asideLayoutType: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:title': '‎',
          'ui:widget': 'select',
        },
        sectionLayoutType: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:title': '‎',
          'ui:widget': 'select',
        },
      },
      header: {
        textColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        textSize: {
          'ui:label': false,
          'ui:readonly': true,
        },
        iconVisible: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'select',
        },
        iconUrl: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'uri',
          'ui:fieldtype': 'link',
        },
      },
      subHeader: {
        textColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        textSize: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
      printExtraKeyCardButton: {
        type: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'select',
        },
        textColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        textSize: {
          'ui:label': false,
          'ui:readonly': true,
        },
        buttonTextColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        buttonTextSize: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
      asideLayoutGridConfig: {
        textColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        textSize: {
          'ui:label': false,
          'ui:readonly': true,
        },
        topGridType: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'select',
        },
        topGridImageUrl: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'uri',
          'ui:fieldtype': 'link',
        },
        bottomGridType: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'select',
        },
        bottomGridImageUrl: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'uri',
          'ui:fieldtype': 'link',
        },
        fontWeight: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'select',
        },
        header: {
          textColor: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'color',
          },
          textSize: {
            'ui:label': false,
            'ui:readonly': true,
          },
          fontWeight: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:widget': 'select',
          },
        },
      },
      roomInfo: {
        card: {
          borderColor: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'color',
          },
          borderRadius: {
            'ui:label': false,
            'ui:readonly': true,
          },
          borderWidth: {
            'ui:label': false,
            'ui:readonly': true,
          },
          backgroundColor: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'color',
          },
        },
        roomNumber: {
          textColor: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'color',
          },
        },
        additionalInfo: {
          textColor: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'color',
          },
        },
      },
    },
    keyCardPrintError: {
      header: {
        textColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        textSize: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
      description: {
        textColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        textSize: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
      message: {
        textColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        textSize: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
      showContinueButton: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
    },
  },
  idVerificationScreen: {
    'ui:title': '‎',
    visaZoom: {
      'ui:label': false,
      'ui:readonly': true,
    },
    homeImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    logoImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    closeImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    idCardZoom: {
      'ui:label': false,
      'ui:readonly': true,
    },
    useScanbot: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    passportZoom: {
      'ui:label': false,
      'ui:readonly': true,
    },
    settingImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    visaImageURL: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    idCardImageURL: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    driverLicenseImageURL: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    passportImageURL: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    haveFaceCapture: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    primaryImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    cardOverlayAlpha: {
      'ui:label': false,
      'ui:readonly': true,
    },
    cardOverlayImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    cropCapturedImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    driverLicenseZoom: {
      'ui:label': false,
      'ui:readonly': true,
    },
    secondaryImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    backInstructionURL: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    faceInstructionURL: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    frontInstructionURL: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    backImageCompression: {
      'ui:label': false,
      'ui:readonly': true,
    },
    frontImageCompression: {
      'ui:label': false,
      'ui:readonly': true,
    },
    remoteAssistanceImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    customInstructionImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    passportInstructionURL: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    scanViewLeftPanelBgColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    passportVerificationImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    scanViewRightPanelBgColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    iconColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    customFaceInstructionImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    idVerificationInstructionVideoUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    idCardFrontInstructionURL: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    idCardBackInstructionURL: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    driverLicenseFrontInstructionURL: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    driverLicenseBackInstructionURL: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    isInstruction: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    hasAgeVerification: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    ageVerificationImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    isCameraMirrored: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    displayInitialScreen: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    timeout: {
      'ui:label': false,
      'ui:readonly': true,
    },
    privacyPolicyUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    privacyCloseIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    continuousAutoFocusMode: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    enableFaceLiveness: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    faceCaptureOverlayImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    displayShutterIconButton: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    displayShutterLabelButton: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    dropdownBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    textInputColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
  },
  specialRequestScreen: {
    'ui:title': '‎',
    enabled: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    imageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
  },
  userInformationScreen: {
    'ui:title': '‎',
    additionalInfoRequired: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    isHideCost: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    OTA_EMAILS: {
      'ui:label': false,
      'ui:title': '‎',
      'ui:readonly': true,
      items: {
        'ui:label': false,
      },
    },
    disableEditUserInfo: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    displayCompanyName: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    defaultUnitTypeImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    checkInDateFormat: {
      'ui:label': false,
      'ui:readonly': true,
    },
    checkOutDateFormat: {
      'ui:label': false,
      'ui:readonly': true,
    },
    showV3Screen: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    showSecondaryUsers: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    hideImageRoomType: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    isDynamicEnabled: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    defaultPhoneInputCountry: {
      'ui:label': false,
      'ui:readonly': true,
    },
    obfuscateSensitiveData: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    enableAddSecondaryUsers: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    enableEditSecondaryUsers: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    imageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    shadowColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    backgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    cardBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    cardTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    enablePrimaryUserEmailPhoneNumberValidation: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    editUser: {
      disableFirstName: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
      disableLastName: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
    },
  },
  remoteAssistanceScreen: {
    'ui:title': '‎',
    disableVideoButton: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    disableVoiceButton: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    remoteAssistanceVoiceCallMode: {
      'ui:label': false,
      'ui:readonly': true,
    },
    callTimeout: {
      'ui:label': false,
      'ui:readonly': true,
    },
    disableGuestDetailsOnCallTimeout: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    remoteAssistanceVendor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
  },
  reservationStatusScreen: {
    'ui:title': '‎',
    imageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    displayImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    displayButton: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    showWebQrCode: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
  },
  selectReservationScreen: {
    'ui:title': '‎',
    reservationCardFallbackImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    hideRoomName: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    filterReservation: {
      'ui:label': false,
      'ui:title': '‎',
      'ui:readonly': true,
      items: {
        flow: {
          'ui:label': true,
          'ui:widget': 'select',
        },
        filterTypes: {
          'ui:label': true,
          'ui:widget': 'select',
        },
      },
    },
    textColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    checkInDateFormat: {
      'ui:label': false,
      'ui:readonly': true,
    },
    checkOutDateFormat: {
      'ui:label': false,
      'ui:readonly': true,
    },
    headerTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    shadowColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    reservationCardColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
  },
  packageScreen: {
    'ui:title': '‎',
    isEnabled: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    buttonTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    headerTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    headerColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    borderColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    viewCartTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    cartTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    modalColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    subtitleTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    prebookText: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    packageCardColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    packageTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    packageCategoryColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    packagePriceColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    submit: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    hideInactivePackagesPrice: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    isComparableType: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    displayPrebookedComparable: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    successIcon: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    iconBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    cartPriceColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    packageButtonColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    buttonColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    loadingImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    prebookedText: {
      textColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      fontSize: {
        'ui:label': false,
        'ui:readonly': true,
      },
      fontWeight: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
    },
    continueButtonBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    continueButtonTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    continueButtonType: {
      'ui:label': false,
      'ui:readonly': true,
    },
    viewCartButtonBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    viewCartButtonTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    viewCartButtonType: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  checkInFailScreen: {
    'ui:title': '‎',
    displayButton: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    displayEndSessionButton: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    iconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'link',
      'ui:widget': 'uri',
    },
    useNewCheckInFailScreen: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    headerTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    titleTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    warningIconColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
  },
  conciergeScreen: {
    'ui:title': '‎',
    textColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    buttonColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    shadowColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    buttonTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    backgroundImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    titleTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    descriptionTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    buttonBorderColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    buttonBorderWidth: {
      'ui:label': false,
      'ui:readonly': true,
    },
    popupBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    popupTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    popupButtonColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    buttonFontSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    buttonFontWeight: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    logoImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    backIconColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    descriptionFontSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    descriptionFontWeight: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    titleFontSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    titleFontWeight: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    popupTitleFontSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    popupTitleFontWeight: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    popupDescriptionFontSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    popupDescriptionFontWeight: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    popupButtonTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    popupButtonFontSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    popupButtonFontWeight: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    descriptionTextAlignment: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    titleTextAlignment: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    buttonTextAlignment: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    logoImageAlignment: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    categoryButtonTextAlignment: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    hideBackgroundImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    hideButtonIcon: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    cardBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    cardTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    categoryBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    categoryTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    selectedCategoryBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    selectedCategoryTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    backgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    useV3Screen: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    popupCloseIcon: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    conciergeHomeImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    cardMediaBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    hideAllCategory: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    orderAlphabeticallly: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    thumbnailReziseMode: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    cardNextIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    cartTextAlignment: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    categoryThumbnailReziseMode: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
  },
  mrzIdVerificationScreen: {
    'ui:title': '‎',
    instructionImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    instructionImageUrl2: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    zoom: {
      'ui:label': false,
      'ui:readonly': true,
    },
    isCameraMirrored: {
      'ui:label': false,
      'ui:readonly': true,
    },
    scanViewLeftPanelBgColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    scanViewRightPanelBgColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    scanViewLeftPanelBorderColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    scanViewRightPanelBorderColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    logoImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    homeImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    settingImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    mrzIdVerificationInstructionVideoUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    isInstruction: {
      'ui:label': false,
      'ui:readonly': true,
    },
    hasAgeVerification: {
      'ui:label': false,
      'ui:readonly': true,
    },
    ageVerificationImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    primaryImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    secondaryImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    infoIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    popupBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    popupTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    popupTitleFontSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    popupTitleFontWeight: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    popupDescriptionFontSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    popupDescriptionFontWeight: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    popupButtonTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    popupButtonFontSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    popupButtonFontWeight: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    instructionVideoUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    instructionGifUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    shouldDisplayBackAlert: {
      'ui:label': false,
      'ui:readonly': true,
    },
    instructionTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    instructionTextFontSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    instructionTextFontWight: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    instructionTextAlignment: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    instructionText2Color: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    instructionText2FontSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    instructionText2FontWight: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    instructionText2Alignment: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    scanInstructionTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    scanInstructionTextFontSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    scanInstructionTextFontWight: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    scanInstructionTextAlignment: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    headerTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    headerTextFontSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    headerTextFontWight: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    headerTextAlignment: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    cameraTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    cameraTextFontSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    cameraTextFontWight: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    cameraTextAlignment: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    backIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    backTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    backTextFontSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    backTextFontWight: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    backTextAlignment: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    remoteAssistanceImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    helpButtonColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    helpButtonTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    instructionContainerBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
  },
  mrzScannerScreen: {
    'ui:title': '‎',
    instructionImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    instructionImageUrl2: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    zoom: {
      'ui:label': false,
      'ui:readonly': true,
    },
    isCameraMirrored: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    scanViewLeftPanelBgColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    scanViewRightPanelBgColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    scanViewLeftPanelBorderColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    scanViewRightPanelBorderColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    logoImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    homeImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    settingImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    mrzIdVerificationInstructionVideoUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    isInstruction: {
      'ui:label': false,
      'ui:readonly': true,
    },
    hasAgeVerification: {
      'ui:label': false,
      'ui:readonly': true,
    },
    ageVerificationImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    primaryImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    secondaryImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    infoIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    popupBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    popupTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    popupTitleFontSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    popupTitleFontWeight: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    popupDescriptionFontSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    popupDescriptionFontWeight: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    popupButtonTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    popupButtonFontSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    popupButtonFontWeight: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    instructionVideoUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    instructionGifUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    shouldDisplayBackAlert: {
      'ui:label': false,
      'ui:readonly': true,
    },
    instructionTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    instructionTextFontSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    instructionTextFontWight: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    instructionTextAlignment: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    instructionText2Color: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    instructionText2FontSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    instructionText2FontWight: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    instructionText2Alignment: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    scanInstructionTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    scanInstructionTextFontSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    scanInstructionTextFontWight: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    scanInstructionTextAlignment: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    headerTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    headerTextFontSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    headerTextFontWight: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    headerTextAlignment: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    cameraTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    cameraTextFontSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    cameraTextFontWight: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    cameraTextAlignment: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    backIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    backTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    backTextFontSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    backTextFontWight: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    backTextAlignment: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    remoteAssistanceImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
  },
  nameAndDepartureDateVerificationScreen: {
    displayDepartureDateFormat: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  wristbandDispensingScreen: {
    'ui:title': '‎',
    finalScreenImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    titleTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    primaryTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    guestNumberColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    guestNumberBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    guestDispenseStatusColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    guestSetAccessButtonTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    guestSetAccessButtonBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    guestWristbandDispensedTagBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    guestWristbandDispensedTagTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    dispenseButtonBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    dispenseButtonTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    logoutButtonBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    logoutButtonTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    modalBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    modalTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    modalConfirmButtonBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    modalCancelButtonBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    modalCancelButtonTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    modalConfirmButtonTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    mainContainerBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    activeGuestBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    loadingSpinnerColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    activeSwitchBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    disabledSwitchBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    labelRow: {
      'ui:label': false,
      'ui:readonly': true,
    },
    labelColumn: {
      'ui:label': false,
      'ui:readonly': true,
    },
    labelFont: {
      'ui:label': false,
      'ui:readonly': true,
    },
    labelRotation: {
      'ui:label': false,
      'ui:readonly': true,
    },
    errorModalPrimaryColor: {
      'ui:label': false,
      'ui:readonly': true,
    },
    voidWristbandFontSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    voidTextCoordinates: {
      'ui:label': false,
      'ui:readonly': true,
    },
    enableVoidWristbandAutomaticDiscarding: {
      'ui:label': false,
      'ui:readonly': true,
    },
    enableVoidWristbandAutomaticDiscardingTimeOut: {
      'ui:label': false,
      'ui:readonly': true,
    },
    enableVoidWristbandFeature: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  simplifiedIdVerification: {
    'ui:title': '‎',
    scanMode: {
      authentication: {
        'ui:label': false,
        'ui:readonly': true,
      },
      requirement: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    config: {
      authentication: {
        allowMiddleName: {
          'ui:label': false,
          'ui:readonly': true,
        },
        middleNamePosition: {
          'ui:label': false,
          'ui:readonly': true,
        },
        documentSelection: {
          headerColor: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'color',
          },
          popupTextColor: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'color',
          },
          popupTitleFontSize: {
            'ui:label': false,
            'ui:readonly': true,
          },
          popupTitleFontWeight: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:widget': 'select',
          },
          popupDescriptionFontSize: {
            'ui:label': false,
            'ui:readonly': true,
          },
          popupDescriptionFontWeight: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:widget': 'select',
          },
          popupButtonTextColor: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'color',
          },
          popupButtonFontSize: {
            'ui:label': false,
            'ui:readonly': true,
          },
          popupButtonFontWeight: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:widget': 'select',
          },
          popupBackgroundColor: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'color',
          },
          mrzIdVerificationInstructionVideoUrl: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:widget': 'uri',
            'ui:fieldtype': 'link',
          },
          isInstruction: {
            'ui:label': false,
            'ui:readonly': true,
          },
          hasAgeVerification: {
            'ui:label': false,
            'ui:readonly': true,
          },
          ageVerificationImageUrl: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:widget': 'uri',
            'ui:fieldtype': 'link',
          },
          primaryImageUrl: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:widget': 'uri',
            'ui:fieldtype': 'link',
          },
          secondaryImageUrl: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:widget': 'uri',
            'ui:fieldtype': 'link',
          },
          documentsConfig: {
            'ui:label': false,
            'ui:title': '‎',
            'ui:readonly': true,
            items: {
              scannerType: {
                'ui:label': true,
                'ui:widget': 'select',
              },
              documentType: {
                'ui:label': true,
                'ui:widget': 'select',
              },
              boxesBackgroundColor: {},
              boxesBorderColor: {},
              boxesBorderWidth: {},
              boxesTitleTextColor: {},
              imageUrl: {},
              scannerConfig: {
                instructionImageUrl: {},
                imageUrl: {},
                instructionImageUrl2: {},
                zoom: {},
                isCameraMirrored: {
                  'ui:label': true,
                  'ui:widget': 'select',
                },
                scanViewLeftPanelBgColor: {},
                scanViewRightPanelBgColor: {},
                scanViewLeftPanelBorderColor: {},
                scanViewRightPanelBorderColor: {},
                logoImage: {},
                homeImage: {},
                remoteAssistanceImage: {},
                settingImage: {},
                mrzIdVerificationInstructionVideoUrl: {},
                isInstruction: {
                  'ui:label': true,
                  'ui:widget': 'select',
                },
                hasAgeVerification: {
                  'ui:label': true,
                  'ui:widget': 'select',
                },
                ageVerificationImageUrl: {},
                primaryImageUrl: {},
                secondaryImageUrl: {},
                infoIconUrl: {},
                popupBackgroundColor: {},
                popupTextColor: {},
                popupTitleFontSize: {},
                popupTitleFontWeight: {},
                popupDescriptionFontSize: {},
                popupDescriptionFontWeight: {},
                popupButtonTextColor: {},
                popupButtonFontSize: {},
                popupButtonFontWeight: {},
                instructionVideoUrl: {},
                instructionGifUrl: {},
                shouldDisplayBackAlert: {},
                instructionTextColor: {},
                instructionTextFontSize: {},
                instructionTextFontWight: {},
                instructionTextAlignment: {},
                instructionText2Color: {},
                instructionText2FontSize: {},
                instructionText2FontWight: {},
                instructionText2Alignment: {},
                scanInstructionTextColor: {},
                scanInstructionTextFontSize: {},
                scanInstructionTextFontWight: {},
                scanInstructionTextAlignment: {},
                headerTextColor: {},
                headerTextFontSize: {},
                headerTextFontWight: {},
                headerTextAlignment: {},
                cameraTextColor: {},
                cameraTextFontSize: {},
                cameraTextFontWight: {},
                cameraTextAlignment: {},
                backIconUrl: {},
                backTextColor: {},
                backTextFontSize: {},
                backTextFontWight: {},
                backTextAlignment: {},
              },
            },
          },
          popupButtonBackgroundColor: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'color',
          },
          popupRetryButtonTextColor: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'color',
          },
          popupRetryButtonBackgroundColor: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'color',
          },
          popupRetryButtonFontSize: {
            'ui:label': false,
            'ui:readonly': true,
          },
          popupRetryButtonFontWeight: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:widget': 'select',
          },
        },
        allowSkipScreenOnSingleOption: {
          'ui:label': false,
          'ui:readonly': true,
        },
        enableSkipOtp: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
      requirement: {
        allowMiddleName: {
          'ui:label': false,
          'ui:readonly': true,
        },
        middleNamePosition: {
          'ui:label': false,
          'ui:readonly': true,
        },
        documentSelection: {
          headerColor: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'color',
          },
          popupTextColor: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'color',
          },
          popupTitleFontSize: {
            'ui:label': false,
            'ui:readonly': true,
          },
          popupTitleFontWeight: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:widget': 'select',
          },
          popupDescriptionFontSize: {
            'ui:label': false,
            'ui:readonly': true,
          },
          popupDescriptionFontWeight: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:widget': 'select',
          },
          popupButtonTextColor: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'color',
          },
          popupButtonFontSize: {
            'ui:label': false,
            'ui:readonly': true,
          },
          popupButtonFontWeight: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:widget': 'select',
          },
          popupBackgroundColor: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'color',
          },
          mrzIdVerificationInstructionVideoUrl: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:widget': 'uri',
            'ui:fieldtype': 'link',
          },
          isInstruction: {
            'ui:label': false,
            'ui:readonly': true,
          },
          hasAgeVerification: {
            'ui:label': false,
            'ui:readonly': true,
          },
          ageVerificationImageUrl: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:widget': 'uri',
            'ui:fieldtype': 'link',
          },
          primaryImageUrl: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:widget': 'uri',
            'ui:fieldtype': 'link',
          },
          secondaryImageUrl: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:widget': 'uri',
            'ui:fieldtype': 'link',
          },
          documentsConfig: {
            'ui:label': false,
            'ui:title': '‎',
            'ui:readonly': true,
            items: {
              scannerType: {
                'ui:label': true,
                'ui:widget': 'select',
              },
              documentType: {
                'ui:label': true,
                'ui:widget': 'select',
              },
              boxesBackgroundColor: {},
              boxesBorderColor: {},
              boxesBorderWidth: {},
              boxesTitleTextColor: {},
              imageUrl: {},
              scannerConfig: {
                instructionImageUrl: {},
                imageUrl: {},
                instructionImageUrl2: {},
                zoom: {},
                isCameraMirrored: {
                  'ui:label': true,
                  'ui:widget': 'select',
                },
                scanViewLeftPanelBgColor: {},
                scanViewRightPanelBgColor: {},
                scanViewLeftPanelBorderColor: {},
                scanViewRightPanelBorderColor: {},
                logoImage: {},
                homeImage: {},
                remoteAssistanceImage: {},
                settingImage: {},
                mrzIdVerificationInstructionVideoUrl: {},
                isInstruction: {
                  'ui:label': true,
                  'ui:widget': 'select',
                },
                hasAgeVerification: {
                  'ui:label': true,
                  'ui:widget': 'select',
                },
                ageVerificationImageUrl: {},
                primaryImageUrl: {},
                secondaryImageUrl: {},
                infoIconUrl: {},
                popupBackgroundColor: {},
                popupTextColor: {},
                popupTitleFontSize: {},
                popupTitleFontWeight: {},
                popupDescriptionFontSize: {},
                popupDescriptionFontWeight: {},
                popupButtonTextColor: {},
                popupButtonFontSize: {},
                popupButtonFontWeight: {},
                instructionVideoUrl: {},
                instructionGifUrl: {},
                shouldDisplayBackAlert: {},
                instructionTextColor: {},
                instructionTextFontSize: {},
                instructionTextFontWight: {},
                instructionTextAlignment: {},
                instructionText2Color: {},
                instructionText2FontSize: {},
                instructionText2FontWight: {},
                instructionText2Alignment: {},
                scanInstructionTextColor: {},
                scanInstructionTextFontSize: {},
                scanInstructionTextFontWight: {},
                scanInstructionTextAlignment: {},
                headerTextColor: {},
                headerTextFontSize: {},
                headerTextFontWight: {},
                headerTextAlignment: {},
                cameraTextColor: {},
                cameraTextFontSize: {},
                cameraTextFontWight: {},
                cameraTextAlignment: {},
                backIconUrl: {},
                backTextColor: {},
                backTextFontSize: {},
                backTextFontWight: {},
                backTextAlignment: {},
              },
            },
          },
          popupButtonBackgroundColor: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'color',
          },
          popupRetryButtonTextColor: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'color',
          },
          popupRetryButtonBackgroundColor: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'color',
          },
          popupRetryButtonFontSize: {
            'ui:label': false,
            'ui:readonly': true,
          },
          popupRetryButtonFontWeight: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:widget': 'select',
          },
        },
        allowSkipScreenOnSingleOption: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
      general: {
        enableExpirationDateValidation: {
          'ui:label': false,
          'ui:readonly': true,
        },
        continuousAutoFocusMode: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'select',
        },
        screenV2Disabled: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'select',
        },
        useScanbotParser: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'select',
        },
      },
    },
  },
  finalScreen: {
    'ui:title': '‎',
    isEnabled: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    imageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    displayQrCode: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    shadowColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    cardBorderColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    cardBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    textColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    iconColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
  },
  AdditionInfoScreen: {
    'ui:title': '‎',
    displayButton: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    enableManualRequirementsUpdate: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    hiddenDynamicFieldsKey: {
      'ui:label': false,
      'ui:title': '‎',
      'ui:readonly': true,
      items: {
        'ui:label': false,
      },
    },
    enableSendHiddenDynamicFields: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
  },
  noInternetConnectionOverlay: {
    'ui:title': '‎',
    backgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    settingButtonTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    reconnectButtonTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    inputTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    textColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
  },
  obfuscateAuthScreen: {
    'ui:title': '‎',
    backgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    shadowColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    textColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    buttonColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    buttonTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    displayIdVerification: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    displayPhoneNumber: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    displayEmail: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
  },
  ServiceScreen: {
    'ui:title': '‎',
    metadataCardColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    metadataTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    metadataPriceBgColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    metadataPriceTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    priceColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    serviceId: {
      'ui:label': false,
      'ui:readonly': true,
    },
    enabled: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    shadowColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    headerColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    backgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    cardColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    confirmationImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'link',
    },
    acknowledgeImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'link',
    },
    termsAndConditionsEnabled: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'select',
    },
    modalBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
  },
  remoteAssistanceComponent: {
    'ui:title': '‎',
    shadowColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    backgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
  },
  phoneVerificationScreen: {
    'ui:title': '‎',
    backgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
  },
  failedRequirementsOverlay: {
    'ui:title': '‎',
    backgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    textColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    buttonTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
  },
  faceLivenessScreen: {
    'ui:title': '‎',
    instructionImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    instructionVideoUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    instructionPanelBgColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    instructionPanelBorderColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    logoImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    homeImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    settingImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    remoteAssistanceImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    shouldDisplayBackAlert: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    instructionDescriptionTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    instructionDescriptionTextFontSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    instructionDescriptionTextFontWight: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    instructionDescriptionTextAlignment: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    instructionTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    instructionTextFontSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    instructionTextFontWight: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    instructionTextAlignment: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    headerTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    headerTextFontSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    headerTextFontWight: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    headerTextAlignment: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    subheaderTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    subheaderTextFontSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    subheaderTextFontWight: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    subheaderTextAlignment: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    cameraTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    cameraTextFontSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    cameraTextFontWight: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    cameraTextAlignment: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    backIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    backTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    backTextFontSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    backTextFontWight: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    backTextAlignment: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    startButtonBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    startButtonTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    faceMatchFailedDescriptionTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    faceMatchFailedDescriptionTextFontSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    faceMatchFailedDescriptionTextFontWight: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    faceMatchFailedDescriptionTextAlignment: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    retryButtonBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    retryButtonTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    endSessionButtonBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    endSessionButtonTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    faceMatchFailedImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    faceLivenessFailedImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    rewatchButtonTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    rewatchButtonTextFontSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    rewatchButtonTextFontWight: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    ovalThreshold: {
      'ui:label': false,
      'ui:readonly': true,
    },
    maxRunningCount: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  paymentConfig: {
    'ui:title': '‎',
    shouldIgnoreReverseOnPreAuthorization: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    timedOutTransactionInMilliSeconds: {
      'ui:label': false,
      'ui:readonly': true,
    },
    disableCancelTransaction: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
  },
  remoteAssistanceButton: {
    topRightRemoteAssistanceButtonVisible: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    topRightRaButtonBorderColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    topRightRaButtonBorderWidth: {
      'ui:label': false,
      'ui:readonly': true,
    },
    topRightRaButtonBorderRadius: {
      'ui:label': false,
      'ui:readonly': true,
    },
    topRightRaButtonTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    topRightRaButtonBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    topRightRaButtonIcon: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    topRightRaButtonIconColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    topRightRaButtonIconRadius: {
      'ui:label': false,
      'ui:readonly': true,
    },
    topRightRaButtonIconBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    topRightRaButtonIconBorderRadius: {
      'ui:label': false,
      'ui:readonly': true,
    },
    topRightRaButtonWidth: {
      'ui:label': false,
      'ui:readonly': true,
    },
    topRightRaButtonHeight: {
      'ui:label': false,
      'ui:readonly': true,
    },
    topRightRaButtonTextFontSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    menuRemoteAssistanceButtonVisible: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    menuRaButtonBorderColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    menuRaButtonBorderWidth: {
      'ui:label': false,
      'ui:readonly': true,
    },
    menuRaButtonBorderRadius: {
      'ui:label': false,
      'ui:readonly': true,
    },
    menuRaButtonTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    menuRaButtonBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    menuRaButtonIcon: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    menuRaButtonIconColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    menuRaButtonIconRadius: {
      'ui:label': false,
      'ui:readonly': true,
    },
    menuRaButtonIconBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    menuRaButtonIconBorderRadius: {
      'ui:label': false,
      'ui:readonly': true,
    },
    menuRaButtonTextFontSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  verifyingRequirementsScreen: {
    featureFlags: {
      loadingV2: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
    },
  },
  jumioFaceLivenessScreen: {
    'ui:title': '‎',
    iproov: {
      filterForegroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      filterBackgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      titleTextColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      closeButtonTintColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      surroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      promptTextColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      promptBackgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      genuinePresenceAssuranceReadyOvalStrokeColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      genuinePresenceAssuranceNotReadyOvalStrokeColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      livenessAssuranceOvalStrokeColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      livenessAssuranceCompletedOvalStrokeColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
    },
    primaryButton: {
      background: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      backgroundPressed: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      backgroundDisabled: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      foreground: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      foregroundPressed: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      foregroundDisabled: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
    },
    secondaryButton: {
      background: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      backgroundPressed: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      backgroundDisabled: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      foreground: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
    },
    bubble: {
      background: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      foreground: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      backgroundSelected: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      outline: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
    },
    loading: {
      circlePlain: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      loadingCircleGradientStart: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      loadingCircleGradientEnd: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      errorCircleGradientStart: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      errorCircleGradientEnd: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      circleIcon: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
    },
    scanOverlay: {
      scanOverlay: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      scanBackground: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      livenessStroke: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      livenessStrokeAnimation: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      livenessStrokeAnimationCompleted: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
    },
    nfc: {
      passportCover: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      passportPageDark: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      passportPageLight: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      passportForeground: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      phoneCover: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
    },
    scanView: {
      tooltipForeground: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      tooltipBackground: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      foreground: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      darkForeground: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      documentShutter: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      faceShutter: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
    },
    searchBubble: {
      background: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      foreground: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
    },
    confirmation: {
      imageBackground: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      imageBackgroundBorder: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      indicatorActive: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      indicatorDefault: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
    },
    scanHelp: {
      faceAnimationForeground: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
    },
    global: {
      background: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      navigationIconColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      textForegroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      primaryColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      selectionIconForeground: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
    },
  },
  earlyCheckInScreen: {
    'ui:title': '‎',
    featureFlags: {
      earlyCheckInScreenEnabled: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
    },
    background: {
      type: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
      color: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      imageUrl: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'uri',
        'ui:fieldtype': 'link',
      },
      overlayOpacity: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    mainTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    declineButtonColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    cardBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    cardTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    acceptButtonBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
  },
};
