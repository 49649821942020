import Button from '../../Button';
import ButtonModel from '../../Button/enums/ButtonModel.enum';
import styled from 'styled-components';

export const BaseModalHeaderContainer = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-bottom: 15px;
  height: 35px;
`;

export const HeaderText = styled.h2`
  font-size: 24px;
  margin: 0;
  padding: 0;
  font-weight: 700;
  flex: 1;
`;

export const HeaderIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const CloseModalButton = styled(Button).attrs({
  model: ButtonModel.LIGHT_ROUNDED,
})``;
