import 'styled-components/macro';

import Container from '../../../../components/Container';
import KioskLiveUsageCard from './components/KioskLiveUsageCard';
import { KioskLiveUsageQuickActionsModal } from './components/KioskLiveUsageQuickActionsModal/KioskLiveUsageQuickActionsModal';
import KiosksLiveUsageContainer from './styles/KiosksLiveUsageContainer.style';
import KiosksLiveUsageFilter from './components/KiosksLiveUsageFilter';
import { KiosksLiveUsageHeader } from './styles/KiosksLiveUsage.style';
import LiveUsageNotAvailable from './components/LiveUsageNotAvailable';
import { LoadReservationOnKioskModal } from './components/LoadReservationOnKioskModal/LoadReservationOnKioskModal';
import LoadingAnimation from '../../../../components/LoadingAnimation';
import { QuickActionConfirmationModal } from './components/KioskLiveUsageQuickActionsModal/QuickActionConfirmationModal';
import React from 'react';
import { RootState } from '../../../../redux/reducers';
import { mockLiveUsage } from './__mocks__/liveUsageData';
import useGetKiosksLiveUsage from './hooks/useGetKiosksLiveUsage';
import useGetReservationConfig from '../../../../hooks/useGetReservationConfig';
import { useKioskLiveUsageContext } from './components/contexts/KioskLiveUsageContext';
import { useLoadReservationOnKioskModalContext } from './components/contexts/LoadReservationOnKioskModalContext';
import { useQuickActionConfirmationModalContext } from './components/contexts/QuickActionConfirmationModalContext';
import { useSelector } from 'react-redux';

const KiosksLiveUsage: React.FC = () => {
  const { allKiosks, isLoading } = useGetKiosksLiveUsage();
  const { isQuickActionsModalOpen } = useKioskLiveUsageContext();
  const { showQuickActionConfirmationModal } = useQuickActionConfirmationModalContext();
  const { isModalVisible } = useLoadReservationOnKioskModalContext();
  const usersActiveMembership = useSelector((state: RootState) => state.me.activeMembership);
  const { isLoadingReservationConfig, reservationConfig } = useGetReservationConfig({
    locationId: usersActiveMembership?.location?.id || '',
    tenantId: usersActiveMembership?.tenantId,
  });

  if (isLoading || isLoadingReservationConfig) {
    return (
      <Container>
        <LoadingAnimation />
      </Container>
    );
  }

  return (
    <Container>
      {reservationConfig?.enableLiveUsageEvents ? (
        <>
          <KiosksLiveUsageHeader>
            <KiosksLiveUsageFilter />
          </KiosksLiveUsageHeader>
          <Container style={KiosksLiveUsageContainer}>
            {allKiosks.map((kiosk) => (
              <KioskLiveUsageCard key={kiosk.kiosk.id} kiosk={kiosk} />
            ))}
          </Container>
          {isQuickActionsModalOpen && <KioskLiveUsageQuickActionsModal />}
          {showQuickActionConfirmationModal && <QuickActionConfirmationModal />}
          {isModalVisible && <LoadReservationOnKioskModal />}
        </>
      ) : (
        <Container
          style={{
            ...KiosksLiveUsageContainer,
            position: 'relative',
          }}
        >
          {mockLiveUsage.map((kiosk) => (
            <KioskLiveUsageCard key={kiosk.kiosk.id} kiosk={kiosk} />
          ))}
          <LiveUsageNotAvailable />
        </Container>
      )}
    </Container>
  );
};

export default KiosksLiveUsage;
