import { ApolloError } from '@apollo/client';
import { DataTableSearchType } from '../../components/DataTableV2';

export type LazyQueryHookParams = {
  take?: number;
};

export const defaultLazyQueryProps: LazyQueryHookParams = {
  take: 10,
};

export type LazyQueryHookData<T> = T[] | null | undefined;

export type LazyQueryHookType<T> = {
  data: LazyQueryHookData<T>;
  error: ApolloError | undefined;
  loading: boolean;
  query: () => void;
  hasMoreData: boolean;
  search?: (searchParams: DataTableSearchType) => void;
  sort?: (sortParam: string) => void;
};
