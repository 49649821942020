export enum SelectReservationFields {
  PrimaryGuest = 'PrimaryGuest',
  ConfirmationCode = 'ConfirmationCode',
  SecondaryGuests = 'SecondaryGuests',
  RoomNumber = 'RoomNumber',
  CheckInDate = 'CheckInDate',
  CheckInTime = 'CheckInTime',
  Arrow = 'Arrow',
  CheckOutDate = 'CheckOutDate',
  CheckOutTime = 'CheckOutTime',
}

export const SelectReservationHeaderFields = {
  PrimaryGuest: SelectReservationFields.PrimaryGuest,
  SecondaryGuests: SelectReservationFields.SecondaryGuests,
  RoomNumber: SelectReservationFields.RoomNumber,
  CheckIn: SelectReservationFields.CheckInDate,
  Arrow: SelectReservationFields.Arrow,
  CheckOut: SelectReservationFields.CheckOutDate,
} as const;

export const SelectReservationSearchFields = {
  GuestName: SelectReservationFields.PrimaryGuest,
  RoomNumber: SelectReservationFields.RoomNumber,
  ConfirmationCode: SelectReservationFields.ConfirmationCode,
} as const;

export const SelectReservationSortFields = {
  PrimaryGuest: SelectReservationFields.PrimaryGuest,
  CheckIn: SelectReservationFields.CheckInDate,
  CheckOut: SelectReservationFields.CheckOutDate,
} as const;
