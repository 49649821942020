import { Container, Message } from './ErrorMessageText.styles';
import React from 'react';

interface ErrorModalProps {
  message: string | Element;
}

export const ErrorMessageText: React.FC<ErrorModalProps> = ({ message }) => {
  return (
    <Container>
      <Message>{message}</Message>
    </Container>
  );
};
