import { InputBox, RelativeInputWrapper } from '../styles/GracePeriodInput.styles';

import { EarlyCheckInFormValues } from '../@types/EarlyCheckInFormValues';
import { EarlyCheckInInputInstructions } from '../styles/EarlyCheckInInputInstructions.style';
import InputMask from 'react-input-mask';
import React from 'react';
import { useFormContext } from 'react-hook-form';

export const EarlyCheckInTimeConstrainedMinutesInput: React.FC = () => {
  const { register, errors } = useFormContext<EarlyCheckInFormValues>();

  return (
    <RelativeInputWrapper>
      <InputBox error={errors.timeConstrainedInMinutes}>
        <InputMask mask="999" maskPlaceholder="">
          <input ref={register} />
        </InputMask>
        <span>mins</span>
      </InputBox>
      <EarlyCheckInInputInstructions>
        Before the check-in time in the reservation
      </EarlyCheckInInputInstructions>
    </RelativeInputWrapper>
  );
};
