/* eslint-disable no-confusing-arrow */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Theme from '../../theme';
import styled from 'styled-components';

export const DataTableV2Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

// Table
export const Table = styled.table`
  display: flex;
  flex-direction: column;

  max-height: 70vh;
  overflow-y: auto;

  border-collapse: collapse;
`;

// Body
export const TableBody = styled.tbody`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  padding-bottom: 24px;
`;

export const TableRow = styled.tr`
  display: flex;
  flex: 1;
  height: 64px;
  padding: 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  justify-content: space-between;
  cursor: pointer;

  background-color: ${Theme.colors.white.hexCode};

  font-weight: 600;
  font-size: ${Theme.fontSizes.thirdHeading};
`;

export const TableDataCell = styled.td`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-self: initial;
  align-items: center;
`;

export const TableDataCellMultiLine = styled(TableDataCell)`
  flex-direction: column;
  align-items: unset;
`;

// Header
export const TableHeader = styled(TableRow).attrs({ as: 'thead' })`
  background: transparent;
  font-size: 12px;
  padding-top: 0;
  cursor: unset;
`;

export const TableHeaderCell = styled(TableDataCell).attrs({ as: 'tr' })<{
  isSortable: boolean;
  isSort: boolean;
}>`
  cursor: ${({ isSortable }) => (isSortable ? 'pointer' : 'unset')};
  justify-content: flex-start;
  gap: 4px;
  color: ${({ isSort }) => (isSort ? Theme.colors.quickActionButton.hexCode : 'unset')};
`;

// Auxiliar
export const SelectValueButton = styled.button`
  color: ${Theme.colors.quickActionButton.hexCode};
  font-size: 24px;
  background: transparent;
  border: none;
`;

export const CentralizedRow = styled.tr`
  align-self: center;
`;

export const NoResultsMessage = styled.p`
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  font-size: 16px;
`;
