import React, { useMemo } from 'react';

import BaseQuickActionModal from '../../../../../../components/BaseQuickActionModal';
import { BaseQuickActionModalProps } from '../../../../../../components/BaseQuickActionModal/@types';
import { KioskLiveUsageModalHeaderIcon } from '../../../../../../assets/icons/kioskLiveUsageModalHeaderIcon';
import { LoadReservationOnKioskConfirmation } from './components/LoadReservationOnKioskConfirmation/LoadReservationOnKioskConfirmation';
import { LoadReservationStepsEnum } from './types';
import MarkerIcon from '../../../../../../assets/icons/LocationIcon';
import { SelectKiosk } from './components/SelectKiosk/SelectKiosk';
import { SelectLocation } from './components/SelectLocation/SelectLocation';
import { SelectReservation } from './components/SelectReservation/SelectReservation';
import { SelectReservationIcon } from '../../../../../../assets/icons/reservationIcon';
import Theme from '../../../../../../theme';
import { useLoadReservationOnKioskModalContext } from '../contexts/LoadReservationOnKioskModalContext';
import { useLoadReservationOnKioskProcessing } from './hooks/useLoadReservationOnKioskProcessing';

type ModalContentProps = Pick<
  BaseQuickActionModalProps,
  'header' | 'children' | 'styles' | 'modalAlignment'
>;

export const LoadReservationOnKioskModal: React.FC = () => {
  const { closeModal, step, location } = useLoadReservationOnKioskModalContext();

  const { openLoadReservationOnKioskProcessingModal } = useLoadReservationOnKioskProcessing();

  const modalContent = useMemo((): ModalContentProps => {
    switch (step) {
      case LoadReservationStepsEnum.SelectLocation: {
        return {
          header: {
            title: 'Select Location',
            icon: <MarkerIcon color={Theme.colors.quickActionButton.hexCode} />,
          },
          children: <SelectLocation />,
        };
      }

      case LoadReservationStepsEnum.SelectReservation: {
        return {
          header: {
            title: `Select Reservation for ${location?.name}`,
            icon: <SelectReservationIcon color={Theme.colors.quickActionButton.hexCode} />,
          },
          children: <SelectReservation />,
          styles: {
            width: '1000px',
          },
        };
      }

      case LoadReservationStepsEnum.SelectKiosk: {
        return {
          header: {
            title: 'Select Kiosk',
            icon: <KioskLiveUsageModalHeaderIcon color={Theme.colors.quickActionButton.hexCode} />,
          },
          children: <SelectKiosk />,
        };
      }

      case LoadReservationStepsEnum.Confirmation: {
        return {
          header: {
            title: 'Confirm Kiosk Load',
          },
          modalAlignment: 'center',
          children: <LoadReservationOnKioskConfirmation />,
        };
      }

      case LoadReservationStepsEnum.LoadingAndFeedback: {
        openLoadReservationOnKioskProcessingModal();
        break;
      }

      default: {
        break;
      }
    }

    return {
      children: <></>,
    };
  }, [step]);

  const { children, ...modalContentProps } = modalContent;

  return (
    <BaseQuickActionModal {...modalContentProps} closeModalAction={closeModal}>
      {children}
    </BaseQuickActionModal>
  );
};
