import styled, { css } from 'styled-components';
import Theme from '../../../../../../../../theme';

export const KioskStatusContainer = styled.div<{ isActive: boolean }>`
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 8px;
  ${({ isActive }) => {
    if (isActive) {
      return css`
        background: ${Theme.colors.emeraldGreen.hexCode};
        color: ${Theme.colors.white.hexCode};
      `;
    }

    return css`
      background: ${Theme.colors.lighGray.hexCode};
    `;
  }}
`;

export const KioskInfoContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-self: initial;
  align-items: center;
`;
