import { DashboardAccessGrantsOrderBy, SortOrder } from '../../../__generated__/graphql';
import { FilterDashboardAccessGrantsQueryArgs } from '..';
import OrderByDataTableParams from '../../../redux/actions/@types/DataTableOrderByParams';

type OrderByParameter = FilterDashboardAccessGrantsQueryArgs['orderBy'];

type SortOrderByKey = {
  key: keyof DashboardAccessGrantsOrderBy;
  sortOrder: SortOrder;
};

function findSortOrderByKey(
  params: OrderByDataTableParams[],
  key: string
): SortOrderByKey | undefined {
  const fieldsIndex = params.findIndex((field) => field.key === key);
  if (fieldsIndex > -1) {
    const fieldsKey = params[fieldsIndex].key as keyof DashboardAccessGrantsOrderBy;
    return {
      key: fieldsKey,
      sortOrder: params[fieldsIndex].sortOrder,
    };
  }
  return undefined;
}

export default function parseAccessGrantsOrderByParameters(
  params: OrderByDataTableParams[]
): OrderByParameter {
  if (!params) {
    return undefined;
  }

  const filterParams: Partial<OrderByParameter> = {};

  // Confirmation number sort
  const confirmationSortOrder = findSortOrderByKey(params, 'confirmationCode');
  if (confirmationSortOrder) {
    filterParams[confirmationSortOrder.key] = confirmationSortOrder.sortOrder;
  }

  // State sort
  const stateSortOrder = findSortOrderByKey(params, 'state');
  if (stateSortOrder) {
    filterParams[stateSortOrder.key] = stateSortOrder.sortOrder;
  }

  // Check-in sort
  const checkInSortOrder = findSortOrderByKey(params, 'startsAt');
  if (checkInSortOrder) {
    filterParams[checkInSortOrder.key] = checkInSortOrder.sortOrder;
  }

  // Check-out sort
  const checkOutSortOrder = findSortOrderByKey(params, 'endsAt');
  if (checkOutSortOrder) {
    filterParams[checkOutSortOrder.key] = checkOutSortOrder.sortOrder;
  }

  return Object.keys(filterParams).length > 0 ? filterParams : undefined;
}
