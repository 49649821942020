/* eslint-disable sort-keys */
import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3ThemeAssistanceSchema: NodeSchemaProperties = {
  remoteAssistance: {
    title: '#11 Remote assistance',
    type: 'object',
    properties: {
      'remoteAssistanceVoiceCallMode:enable': {
        title: '#1 Remote assistance voice call mode',
        type: 'boolean',
        description:
          'Way to make voice call on Remote Assistance [remoteAssistance.remoteAssistanceVoiceCallMode]',
      },
      remoteAssistanceVoiceCallMode: {
        type: 'string',
        enum: ['phoneNumber', 'dashboard'],
      },
      'hideVideoCallButton:enable': {
        title: '#2 Hide video call button',
        type: 'boolean',
        description: '[remoteAssistance.hideVideoCallButton]',
      },
      hideVideoCallButton: {
        type: undefined,
        enum: [true, false],
      },
      'hideVoiceCallButton:enable': {
        title: '#3 Hide voice call button',
        type: 'boolean',
        description: '[remoteAssistance.hideVoiceCallButton]',
      },
      hideVoiceCallButton: {
        type: undefined,
        enum: [true, false],
      },
      'displayDirectMessageButton:enable': {
        title: '#4 Display direct message button',
        type: 'boolean',
        description: '[remoteAssistance.displayDirectMessageButton]',
      },
      displayDirectMessageButton: {
        type: undefined,
        enum: [true, false],
      },
      'backgroundColor:enable': {
        title: '#5 Remote assistance background color',
        type: 'boolean',
        description: '[remoteAssistance.backgroundColor] - RGBA / RGB / Hex / Color name',
      },
      backgroundColor: {
        type: 'string',
      },
      keypad: {
        title: '#7 Keypad',
        type: 'object',
        properties: {
          'disableKeypad:enable': {
            title: '#7.1 Disable Remote assistance voice call keypad',
            type: 'boolean',
            description: '[remoteAssistance.keypad.disableKeypad]',
          },
          disableKeypad: {
            type: undefined,
            enum: [true, false],
          },
          'textColor:enable': {
            title: '#7.2 Text color',
            type: 'boolean',
            description: '[remoteAssistance.keypad.textColor] - RGBA / RGB / Hex / Color name',
          },
          textColor: {
            type: 'string',
          },
          'buttonColor:enable': {
            title: '#7.3 Button color',
            type: 'boolean',
            description: '[remoteAssistance.keypad.buttonColor] - RGBA / RGB / Hex / Color name',
          },
          buttonColor: {
            type: 'string',
          },
          'borderColor:enable': {
            title: '#7.4 Border color',
            type: 'boolean',
            description: '[remoteAssistance.keypad.borderColor] - RGBA / RGB / Hex / Color name',
          },
          borderColor: {
            type: 'string',
          },
        },
      },
      selectCallMethod: {
        title: '#8 Select call method',
        type: 'object',
        properties: {
          'iconColor:enable': {
            title: '#8.1 Icon color',
            type: 'boolean',
            description:
              '[remoteAssistance.selectCallMethod.iconColor] - RGBA / RGB / Hex / Color name',
          },
          iconColor: {
            type: 'string',
          },
          'iconTextColor:enable': {
            title: '#8.2 Icon text color',
            type: 'boolean',
            description:
              '[remoteAssistance.selectCallMethod.iconTextColor] - RGBA / RGB / Hex / Color name',
          },
          iconTextColor: {
            type: 'string',
          },
          'iconBackgroundColor:enable': {
            title: '#8.3 Icon background color',
            type: 'boolean',
            description:
              '[remoteAssistance.selectCallMethod.iconBackgroundColor] - RGBA / RGB / Hex / Color name',
          },
          iconBackgroundColor: {
            type: 'string',
          },
          'directMessageIconUrl:enable': {
            title: '#8.4 Direct message icon URL',
            type: 'boolean',
            description: '[remoteAssistance.selectCallMethod.directMessageIconUrl]',
          },
          directMessageIconUrl: {
            type: 'string',
          },
          'voiceIconUrl:enable': {
            title: '#8.5 Voice icon URL',
            type: 'boolean',
            description: '[remoteAssistance.selectCallMethod.voiceIconUrl]',
          },
          voiceIconUrl: {
            type: 'string',
          },
          'videoIconUrl:enable': {
            title: '#8.6 Video icon URL',
            type: 'boolean',
            description: '[remoteAssistance.selectCallMethod.videoIconUrl]',
          },
          videoIconUrl: {
            type: 'string',
          },
        },
      },
      callControl: {
        title: '#9 Call control',
        type: 'object',
        properties: {
          'backgroundColor:enable': {
            title: '#9.1 Background color',
            type: 'boolean',
            description:
              '[remoteAssistance.callControl.backgroundColor] - RGBA / RGB / Hex / Color name',
          },
          backgroundColor: {
            type: 'string',
          },
          'borderColor:enable': {
            title: '#9.2 Border color',
            type: 'boolean',
            description:
              '[remoteAssistance.callControl.borderColor] - RGBA / RGB / Hex / Color name',
          },
          borderColor: {
            type: 'string',
          },
          'iconColor:enable': {
            title: '#9.3 Icon color',
            type: 'boolean',
            description: '[remoteAssistance.callControl.iconColor] - RGBA / RGB / Hex / Color name',
          },
          iconColor: {
            type: 'string',
          },
          'buttonBackgroundColor:enable': {
            title: '#9.4 Button background color',
            type: 'boolean',
            description:
              '[remoteAssistance.callControl.buttonBackgroundColor] - RGBA / RGB / Hex / Color name',
          },
          buttonBackgroundColor: {
            type: 'string',
          },
          microphone: {
            title: '#9.5 Microphone',
            type: 'object',
            properties: {
              enabled: {
                title: '#9.5.1 Enabled',
                type: 'object',
                properties: {
                  'iconColor:enable': {
                    title: '#9.5.1.1 Icon color',
                    type: 'boolean',
                    description:
                      '[remoteAssistance.callControl.microphone.enabled.iconColor] - RGBA / RGB / Hex / Color name',
                  },
                  iconColor: {
                    type: 'string',
                  },
                  'buttonBackgroundColor:enable': {
                    title: '#9.5.1.2 Button background color',
                    type: 'boolean',
                    description:
                      '[remoteAssistance.callControl.microphone.enabled.buttonBackgroundColor] - RGBA / RGB / Hex / Color name',
                  },
                  buttonBackgroundColor: {
                    type: 'string',
                  },
                },
              },
              disabled: {
                title: '#9.5.2 Disabled',
                type: 'object',
                properties: {
                  'iconColor:enable': {
                    title: '#9.5.2.1 Icon color',
                    type: 'boolean',
                    description:
                      '[remoteAssistance.callControl.microphone.disabled.iconColor] - RGBA / RGB / Hex / Color name',
                  },
                  iconColor: {
                    type: 'string',
                  },
                  'buttonBackgroundColor:enable': {
                    title: '#9.5.2.2 Button background color',
                    type: 'boolean',
                    description:
                      '[remoteAssistance.callControl.microphone.disabled.buttonBackgroundColor] - RGBA / RGB / Hex / Color name',
                  },
                  buttonBackgroundColor: {
                    type: 'string',
                  },
                },
              },
            },
          },
          camera: {
            title: '#9.6 Camera',
            type: 'object',
            properties: {
              enabled: {
                title: '#9.6.1 Enabled',
                type: 'object',
                properties: {
                  'iconColor:enable': {
                    title: '#9.6.1.1 Icon color',
                    type: 'boolean',
                    description:
                      '[remoteAssistance.callControl.camera.enabled.iconColor] - RGBA / RGB / Hex / Color name',
                  },
                  iconColor: {
                    type: 'string',
                  },
                  'buttonBackgroundColor:enable': {
                    title: '#9.6.1.2 Button background color',
                    type: 'boolean',
                    description:
                      '[remoteAssistance.callControl.camera.enabled.buttonBackgroundColor] - RGBA / RGB / Hex / Color name',
                  },
                  buttonBackgroundColor: {
                    type: 'string',
                  },
                },
              },
              disabled: {
                title: '#9.6.2 Disabled',
                type: 'object',
                properties: {
                  'iconColor:enable': {
                    title: '#9.6.2.1 Icon color',
                    type: 'boolean',
                    description:
                      '[remoteAssistance.callControl.camera.disabled.iconColor] - RGBA / RGB / Hex / Color name',
                  },
                  iconColor: {
                    type: 'string',
                  },
                  'buttonBackgroundColor:enable': {
                    title: '#9.6.2.2 Button background color',
                    type: 'boolean',
                    description:
                      '[remoteAssistance.callControl.camera.disabled.buttonBackgroundColor] - RGBA / RGB / Hex / Color name',
                  },
                  buttonBackgroundColor: {
                    type: 'string',
                  },
                },
              },
            },
          },
          switchSoundOutput: {
            title: '#9.7 Switch sound output',
            type: 'object',
            properties: {
              'iconColor:enable': {
                title: '#9.7.1 Icon color',
                type: 'boolean',
                description:
                  '[remoteAssistance.callControl.switchSoundOutput.iconColor] - RGBA / RGB / Hex / Color name',
              },
              iconColor: {
                type: 'string',
              },
              'buttonBackgroundColor:enable': {
                title: '#9.7.2 Button background color',
                type: 'boolean',
                description:
                  '[remoteAssistance.callControl.switchSoundOutput.buttonBackgroundColor] - RGBA / RGB / Hex / Color name',
              },
              buttonBackgroundColor: {
                type: 'string',
              },
            },
          },
          endCall: {
            title: '#9.8 End call',
            type: 'object',
            properties: {
              'iconColor:enable': {
                title: '#9.8.1 Icon color',
                type: 'boolean',
                description:
                  '[remoteAssistance.callControl.endCall.iconColor] - RGBA / RGB / Hex / Color name',
              },
              iconColor: {
                type: 'string',
              },
              'buttonBackgroundColor:enable': {
                title: '#9.8.2 Button background color',
                type: 'boolean',
                description:
                  '[remoteAssistance.callControl.endCall.buttonBackgroundColor] - RGBA / RGB / Hex / Color name',
              },
              buttonBackgroundColor: {
                type: 'string',
              },
            },
          },
        },
      },
      directMessage: {
        title: '#10 Direct Message',
        type: 'object',
        properties: {
          'url:enable': {
            title: '#10.1 Direct message URL',
            type: 'boolean',
            description:
              'URL for deeplink action on pressing direct message button [remoteAssistance.directMessage.url]',
          },
          url: {
            type: 'string',
          },
          'fallbackUrl:enable': {
            title: '#10.2 Direct message fallback URL',
            type: 'boolean',
            description:
              'Fallback URL when directMessageUrl is not valid [remoteAssistance.directMessage.fallbackUrl]',
          },
          fallbackUrl: {
            type: 'string',
          },
        },
      },
      header: {
        title: '#11 Header',
        type: 'object',
        properties: {
          'displayIcon:enable': {
            title: '#11.1 Display Icon',
            type: 'boolean',
            description:
              'Property to show/hide icon, default to be displayed [remoteAssistance.header.displayIcon]',
          },
          displayIcon: {
            type: undefined,
            enum: [true, false],
          },
          'iconUrl:enable': {
            title: '#11.2 Icon URL',
            type: 'boolean',
            description: 'Custom icon image URL [remoteAssistance.header.iconUrl]',
          },
          iconUrl: {
            type: 'string',
          },
          'iconName:enable': {
            title: '#11.3 Icon Name',
            type: 'boolean',
            description:
              'Custom icon name from the list of icon embed in the app [remoteAssistance.header.iconName]',
          },
          iconName: {
            type: 'string',
          },
        },
      },
      'remoteAssistanceVendor:enable': {
        title: '#12 Remote assistance vendor',
        type: 'boolean',
        description: 'Vendor for remote assistance [remoteAssistance.remoteAssistanceVendor]',
      },
      remoteAssistanceVendor: {
        type: undefined,
        enum: ['virdee', 'sprinklr'],
      },
    },
  },
};

export const mobileV3ThemeAssistanceUiSchema: NodeUiSchemaProperties = {
  remoteAssistance: {
    remoteAssistanceVoiceCallMode: {
      'ui:label': false,
      'ui:readonly': true,
    },
    hideVideoCallButton: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    hideVoiceCallButton: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    displayDirectMessageButton: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    backgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    remoteAssistanceIcon: {
      homeIconUrl: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'uri',
        'ui:fieldtype': 'link',
      },
    },
    keypad: {
      disableKeypad: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
      textColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      buttonColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
    },
    selectCallMethod: {
      iconColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      iconTextColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      iconBackgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      directMessageIconUrl: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'uri',
        'ui:fieldtype': 'link',
      },
      voiceIconUrl: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'uri',
        'ui:fieldtype': 'link',
      },
      videoIconUrl: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'uri',
        'ui:fieldtype': 'link',
      },
    },
    callControl: {
      backgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      iconColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      buttonBackgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      microphone: {
        enabled: {
          iconColor: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'color',
          },
          buttonBackgroundColor: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'color',
          },
        },
        disabled: {
          iconColor: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'color',
          },
          buttonBackgroundColor: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'color',
          },
        },
      },
      camera: {
        enabled: {
          iconColor: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'color',
          },
          buttonBackgroundColor: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'color',
          },
        },
        disabled: {
          iconColor: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'color',
          },
          buttonBackgroundColor: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'color',
          },
        },
      },
      switchSoundOutput: {
        iconColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        buttonBackgroundColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
      },
      endCall: {
        iconColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        buttonBackgroundColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
      },
    },
    directMessage: {
      url: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'uri',
        'ui:fieldtype': 'link',
      },
      fallbackUrl: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'uri',
        'ui:fieldtype': 'link',
      },
    },
    header: {
      displayIcon: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
      iconUrl: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'uri',
        'ui:fieldtype': 'link',
      },
      iconName: {
        'ui:label': false,
        'ui:readonly': true,
      },
      remoteAssistanceVendor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
    },
  },
};
