import { useDataTableV2Context } from '../../../context/DataTableV2Context';

type UseDataTableSearchTextType = {
  getSearchText: (key: string) => string | undefined;
};

export function useDataTableSearchText(): UseDataTableSearchTextType {
  const { searchParams } = useDataTableV2Context();

  const getSearchText = (key: string): string | undefined => {
    if (!searchParams) {
      return undefined;
    }

    if (searchParams.field?.key !== key) {
      return undefined;
    }

    return searchParams.text;
  };

  return {
    getSearchText,
  };
}
