import { Item, Label, StyledRadioButton, Wrapper } from './styles/RequirementStateSelector.style';
import { RequirementStateSelectorProps, RequirementsOverrideForm } from '../../types';
import { LiveUsageCheckMarkIcon } from '../../../../../../assets/icons/liveUsageCheckMark';
import { REQUIREMENT_SELECTOR_STATE } from '../../constants';
import React from 'react';
import { RequirementType } from '../../../../../../__generated__/graphql';
import { useFormContext } from 'react-hook-form';

export const RequirementStateSelector: React.FC<RequirementStateSelectorProps> = ({ inputs }) => {
  const { register, getValues, setValue, trigger } = useFormContext<RequirementsOverrideForm>();

  const handleSelectorClick = (
    state: REQUIREMENT_SELECTOR_STATE,
    name: RequirementType,
    value: string
  ): void => {
    if (state === REQUIREMENT_SELECTOR_STATE.SELECTED) {
      setValue(name, undefined);
    } else if (state !== REQUIREMENT_SELECTOR_STATE.DISABLED) {
      setValue(name, value);
    }
    trigger();
  };

  return (
    <Wrapper itemsCount={inputs.length}>
      {inputs.map(({ name, label, value, disabled }) => {
        const values = getValues();
        const selected = values[name] === value;
        let state = REQUIREMENT_SELECTOR_STATE.UNSELECTED;
        if (disabled) {
          state = REQUIREMENT_SELECTOR_STATE.DISABLED;
        } else if (selected) {
          state = REQUIREMENT_SELECTOR_STATE.SELECTED;
        }

        return (
          <Item
            state={state}
            key={`${name}_${label}`}
            onClick={(): void => handleSelectorClick(state, name, value)}
          >
            <input type="radio" {...register(name)} value={value} hidden />
            <StyledRadioButton state={state}>
              {selected && <LiveUsageCheckMarkIcon />}
            </StyledRadioButton>
            <Label state={state}>{label}</Label>
          </Item>
        );
      })}
    </Wrapper>
  );
};
