import {
  ConfirmationText,
  ConfirmationWarningText,
} from './LoadReservationOnKioskConfirmation.style';
import React, { useMemo } from 'react';

import { BaseModalContainer } from '../../../../../../../../components/BaseQuickActionModal/styles/BaseModal.styles';
import BaseModalFooter from '../../../../../../../../components/BaseQuickActionModal/BaseModalFooter';
import { BaseQuickActionModalButton } from '../../../../../../../../components/BaseQuickActionModal/@types';
import { useLoadReservationOnKioskModalContext } from '../../../contexts/LoadReservationOnKioskModalContext';

export const LoadReservationOnKioskConfirmation: React.FC = () => {
  const {
    closeModal,
    accessGrant,
    kiosk,
    setConfirmation,
  } = useLoadReservationOnKioskModalContext();

  if (!accessGrant || !kiosk) {
    throw new Error();
  }

  const buttonStyleProps = useMemo(
    () => ({
      fontSize: '20px',
      padding: '16px 40px',
    }),
    []
  );

  const cancelButtonProps = useMemo((): BaseQuickActionModalButton => {
    return {
      text: 'Cancel',
      action: closeModal,
      styles: buttonStyleProps,
    };
  }, [closeModal]);

  const confirmButtonProps = useMemo((): BaseQuickActionModalButton => {
    return {
      text: `Load on ${kiosk.name}`,
      action: setConfirmation,
      styles: buttonStyleProps,
    };
  }, [setConfirmation, kiosk]);

  return (
    <BaseModalContainer>
      <ConfirmationText>
        Are you sure you want to load the reservation for <b>{accessGrant.user.name}</b>?
      </ConfirmationText>
      <ConfirmationWarningText>
        Please make sure either you or the guest are present in front of the kiosk.
      </ConfirmationWarningText>
      <BaseModalFooter cancelButton={cancelButtonProps} confirmButton={confirmButtonProps} />
    </BaseModalContainer>
  );
};
