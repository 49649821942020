import React, { useCallback } from 'react';
import BaseQuickActionModal from '../../../../../../components/BaseQuickActionModal';
import KeyDispensingConfirmationMessage from './KeyDisepensingConfirmationMessage';
import { QuickActionsModalTabType } from '../../types';
import { RequirementOverrideConfirmationMessage } from './RequirementOverrideConfirmationMessage';
import { useQuickActionConfirmationModalContext } from '../contexts/QuickActionConfirmationModalContext';

export const QuickActionConfirmationModal: React.FC = () => {
  const { modalData, closeQuickActionConfirmationModal } = useQuickActionConfirmationModalContext();

  const renderElement = useCallback(() => {
    switch (modalData?.type) {
      case QuickActionsModalTabType.KEY_CARD_DISPENSING:
        return <KeyDispensingConfirmationMessage />;
      case QuickActionsModalTabType.REQUIREMENTS_OVERRIDE:
        return <RequirementOverrideConfirmationMessage />;
      default:
        throw new Error('Invalid modal type');
    }
  }, []);

  return (
    <BaseQuickActionModal
      header={{
        title: modalData?.title,
      }}
      confirmButton={{
        action: (): void => {
          closeQuickActionConfirmationModal();
          modalData?.action();
        },
        text: modalData?.buttonText?.confirm || undefined,
      }}
      cancelButton={{
        action: closeQuickActionConfirmationModal,
        text: modalData?.buttonText?.cancel || undefined,
      }}
      closeModalAction={closeQuickActionConfirmationModal}
    >
      {renderElement()}
    </BaseQuickActionModal>
  );
};
