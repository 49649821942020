import React, { useMemo } from 'react';
import { RequirementItem, RequirementTextContainer } from './styles/RequirementsOverride.style';
import { RequirementItemProps } from '../../types';
import { RequirementStateSelector } from './RequerimentStateSelector';
import { RequirementStatus } from '../../../../../../__generated__/graphql';
import Theme from '../../../../../../theme';
import { requirementOverrideSelectorState } from '../../constants';

const RequirementItemRow: React.FC<RequirementItemProps> = ({
  requirement,
  matchingRequirement,
}) => {
  const { text, headerColor, subHeaderColor } = useMemo(() => {
    switch (requirement.status) {
      case RequirementStatus.Completed:
      case RequirementStatus.NotRequired:
        return {
          text: 'Completed',
          headerColor: Theme.colors.emeraldGreen.hexCode,
        };

      case RequirementStatus.Required:
        return {
          text: 'Pending',
          headerColor: Theme.colors.black.hexCode,
          subHeaderColor: Theme.colors.silverGrey.hexCode,
        };

      case RequirementStatus.Failed:
        return {
          text: 'Failed',
          headerColor: Theme.colors.danger.hexCode,
        };

      case RequirementStatus.ContactUser:
      case RequirementStatus.Redo:
      case RequirementStatus.VerifyInformation:
        return {
          text: 'Not Required',
          headerColor: Theme.colors.black.hexCode,
          subHeaderColor: Theme.colors.silverGrey.hexCode,
        };

      default:
        return {
          text: 'Pending',
          headerColor: Theme.colors.black.hexCode,
          subHeaderColor: Theme.colors.silverGrey.hexCode,
        };
    }
  }, []);

  return (
    <RequirementItem>
      <RequirementTextContainer headerColor={headerColor} subHeaderColor={subHeaderColor}>
        <p>{matchingRequirement.displayText}</p>
        <p>{text}</p>
      </RequirementTextContainer>
      <RequirementStateSelector
        inputs={matchingRequirement.selectors.map((selector) => {
          const selectorEnabled =
            requirementOverrideSelectorState[requirement.status][selector.text];

          return {
            name: matchingRequirement.type,
            label: selector.text,
            value: selector.status,
            disabled: !selectorEnabled,
          };
        })}
      />
    </RequirementItem>
  );
};

export default RequirementItemRow;
