import React, { FC } from 'react';
import BaseQuickActionModal from '../../../../../../components/BaseQuickActionModal';
import Button from '../../../../../../components/Button';
import ButtonModel from '../../../../../../components/Button/enums/ButtonModel.enum';
import { GuestEnagementContainer } from './guestEngagementStyle';
import { KioskLiveUsageData } from '../../../../../../__generated__/graphql';
import { MessageText } from '../../../../../../components/BaseQuickActionModal/styles/BaseModal.styles';
import { useGuestEngagement } from './hooks/useGuestEngagement';

export const GuestEngagementComponent: FC<{ kiosk: KioskLiveUsageData }> = ({ kiosk }) => {
  const { handleClick, isModalOpen, setIsModalOpen } = useGuestEngagement(kiosk);
  if (!kiosk.accessGrant?.id) {
    return null;
  }

  return (
    <GuestEnagementContainer>
      <Button onClick={(): void => setIsModalOpen(true)} model={ButtonModel.ACTIVE_CTA}>
        Assisted by Agent
      </Button>
      {isModalOpen && (
        <BaseQuickActionModal
          header={{
            title: 'Confirm Guest Assistance',
          }}
          confirmButton={{
            action: (): void => {
              handleClick();
            },
            text: 'Confirm',
          }}
          cancelButton={{
            action: (): void => {
              setIsModalOpen(false);
            },
            text: 'Cancel',
          }}
        >
          <MessageText>
            Did you personally assist this guest and help with any packages or questions?
          </MessageText>
        </BaseQuickActionModal>
      )}
    </GuestEnagementContainer>
  );
};
