import { gql } from '../__generated__/gql';

export const GET_KIOSKS_AND_COUNT = gql(`
  query GetKiosksAndCount(
    $orderBy: [KioskOrderByWithRelationInput!]
    $skip: Int
    $take: Int
    $where: KioskWhereInput
  ) {
    kiosks(orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
      id
      name
    }

    aggregateKiosk(where: $where) {
      count: _count {
        all: _all
      }
    }
  }
`);
