import { Datum } from '../../../components/DataTable';
import { FilterDashboardAccessGrantsQueryData } from '../../../hooks/useAccessGrantsTableData';
import { UseDateTimeFormatterInterface } from '../../../hooks/useDateTimeFormatter';
import links from '../../../util/links';

export default function generateVerificationsTableData(
  formatter: UseDateTimeFormatterInterface,
  data?: FilterDashboardAccessGrantsQueryData
): Datum[] {
  const { formatDate } = formatter;

  const accessGrants = data?.filterDashboardAccessGrants.data || [];

  const tableData: Datum[] = accessGrants.map((accessGrant) => {
    return {
      dataType: 'AccessGrant',
      fields: [
        {
          key: 'GUEST',
          link: accessGrant.user?.id ? `${links.paths.USERS}/${accessGrant.user?.id}` : '',
          value: accessGrant.user?.name || 'N/A',
        },
        {
          key: 'CONFIRMATION #',
          link: `${links.paths.ACCESS_GRANTS}/${accessGrant.id}`,
          value: accessGrant.confirmationCode || 'N/A',
        },
        {
          key: 'REQ. COMPLETED',
          value: !accessGrant.requirements.length
            ? 'N/A'
            : `${accessGrant.requirements.reduce((acc, r) => {
                if (r.status === 'COMPLETED' || r.status === 'NOT_REQUIRED') {
                  return acc + 1;
                }
                return acc;
              }, 0)} of ${accessGrant.requirements.length}`,
        },
        {
          key: 'CHECK IN',
          value: formatDate(accessGrant.startsAt, {
            timezone: accessGrant.location.timezone,
          }),
        },
        {
          key: 'LOCATION',
          value: accessGrant.location?.name || 'N/A',
        },
        {
          key: 'LAST SEEN',
          value: accessGrant.user.lastSeenAt
            ? formatDate(accessGrant.user.lastSeenAt, {
                timezone: accessGrant.location.timezone,
              })
            : 'N/A',
        },
      ],
      key: accessGrant.id,
    };
  });

  return tableData;
}
