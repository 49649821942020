import React from 'react';
import Theme from '../../../theme';

export const TableHighlightTextTextStyle: React.CSSProperties = {
  background: 'transparent',
  borderBottom: `1px solid rgba(${Theme.colors.borderTable.rgb}, 0.1)`,
  color: `${Theme.colors.white.hexCode}`,
  padding: `15px 0 45px 0`,
  textOverflow: 'ellipsis',
  fontFamily: 'San Francisco Pro',
  fontSize: `${Theme.fontSizes.bodyLarge}`,
  overflow: 'hidden',
};
