import Theme from '../../../theme';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 800px;
  height: 61px;
  padding: 12px 16px;
  align-items: center;
  gap: 40px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  background-color: ${Theme.colors.black.hexCode};
`;

export const Text = styled.p`
  color: ${Theme.colors.white.hexCode};
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.5px;
`;
