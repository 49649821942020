import { ActionProcessingStyle, BaseModalContainer } from './BaseModal.styles';
import { ReactNode, useCallback } from 'react';
import { BaseModalFooter } from './BaseModalFooter';
import { BaseModalHeader } from './BaseModalHeader';
import Container from '../../Container';
import Modal from 'react-modal';
import React from 'react';
import { closeForm } from '../../../redux/actions/modals.actions';
import { useDispatch } from 'react-redux';

interface BaseActionModalButton {
  text?: string;
  action: () => void;
}

export interface BaseModalContainerProps {
  shouldDisplayFooter?: boolean;
}

export interface BaseActionModalProps {
  children?: ReactNode;
  icon?: ReactNode;
  headerTitle?: string;
  confirmButton?: BaseActionModalButton;
  cancelButton?: BaseActionModalButton;
  success?: boolean;
  loader?: {
    display: boolean;
    text?: string;
  };
  error?: {
    message: string;
    tryAgainAction?: () => void;
  };
  shouldAllowSoftDismissing?: boolean;
}

export const BaseActionModal: React.FC<BaseActionModalProps> = ({
  children,
  headerTitle,
  confirmButton,
  cancelButton,
  shouldAllowSoftDismissing,
  icon,
}) => {
  const dispatch = useDispatch();

  const closeModal = useCallback((): void => {
    dispatch(closeForm());
  }, []);

  return (
    <Modal
      isOpen
      onRequestClose={closeModal}
      shouldCloseOnEsc={shouldAllowSoftDismissing !== false}
      shouldCloseOnOverlayClick={shouldAllowSoftDismissing !== false}
      style={ActionProcessingStyle}
    >
      <BaseModalContainer className="Modal Container">
        {headerTitle && (
          <BaseModalHeader headerTitle={headerTitle} onClose={closeModal} icon={icon} />
        )}
        <Container style={{ height: '100%' }}>{children}</Container>
        {(confirmButton?.action || cancelButton?.action) && (
          <BaseModalFooter confirmButton={confirmButton} cancelButton={cancelButton} />
        )}
      </BaseModalContainer>
    </Modal>
  );
};
