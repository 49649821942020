import 'styled-components/macro';

import React, { useState } from 'react';

import Banner from '../../components/Banner';
import BaseLayout from '../../templates/BaseLayout';
import Container from '../../components/Container';
import { KioskLiveUsageProvider } from './components/KiosksLiveUsage/components/contexts/KioskLiveUsageContext';
import KiosksLiveUsageTab from './components/KiosksLiveUsage';
import { LoadReservationButton } from './components/KiosksLiveUsage/components/LoadReservationOnKioskModal/LoadReservationButton';
import { LoadReservationOnKioskModalContextProvider } from './components/KiosksLiveUsage/components/contexts/LoadReservationOnKioskModalContext';
import { QuickActionConfirmationModalProvider } from './components/KiosksLiveUsage/components/contexts/QuickActionConfirmationModalContext';
import RemoteAssistanceLiveUsageTabIcon from '../../assets/icons/remoteAssistanceLiveUsageTabIcon';
import { RemoteAssistanceTabButton } from './components/RemoteAssistanceTabButton';
import RemoteAssistanceWaitingCallsTabIcon from '../../assets/icons/remoteAssistanceWaitingCallsTabIcon';
import Theme from '../../theme';
import WaitingCallsTab from './components/WaitingCalls';

enum RemoteAssistanceTabEnum {
  WAITING_CALLS = 'waitingCalls',
  KIOSKS_LIVE_USAGE = 'kiosksLiveUsage',
}

const RemoteAssistanceComponent: React.FC<{}> = () => {
  const defaultTab: RemoteAssistanceTabEnum = RemoteAssistanceTabEnum.KIOSKS_LIVE_USAGE;
  const [currentTab, setTab] = useState<RemoteAssistanceTabEnum>(defaultTab);

  const tabButtons: {
    tab: RemoteAssistanceTabEnum;
    title: 'Kiosk Live Usage' | 'Waiting Calls';
  }[] = [
    {
      tab: RemoteAssistanceTabEnum.KIOSKS_LIVE_USAGE,
      title: 'Kiosk Live Usage',
    },
    {
      tab: RemoteAssistanceTabEnum.WAITING_CALLS,
      title: 'Waiting Calls',
    },
  ];

  return (
    <BaseLayout>
      <Container
        css={`
          padding: 30px 35px 75px 25px;
        `}
      >
        <Banner text="Guest Assistance" />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Container
            css={`
              grid-auto-flow: column;
              grid-auto-columns: max-content;
              grid-column-gap: 10px;
              margin: 0 0 20px 0;
            `}
          >
            {tabButtons.map((tabButton) => {
              const { tab: tabButtonType, title: tabTitle } = tabButton;
              const active = currentTab === tabButtonType;
              const iconColor = active ? Theme.colors.white.hexCode : Theme.colors.black.hexCode;

              return (
                <RemoteAssistanceTabButton
                  active={active}
                  onClick={(): void => {
                    setTab(tabButtonType);
                  }}
                  key={tabButton.tab}
                >
                  {tabTitle === 'Kiosk Live Usage' && (
                    <RemoteAssistanceLiveUsageTabIcon color={iconColor} />
                  )}
                  {tabTitle === 'Waiting Calls' && (
                    <RemoteAssistanceWaitingCallsTabIcon color={iconColor} />
                  )}
                  {tabTitle}
                </RemoteAssistanceTabButton>
              );
            })}
          </Container>
          <LoadReservationButton />
        </div>
        {currentTab === RemoteAssistanceTabEnum.WAITING_CALLS && <WaitingCallsTab />}
        {currentTab === RemoteAssistanceTabEnum.KIOSKS_LIVE_USAGE && (
          <KioskLiveUsageProvider>
            <QuickActionConfirmationModalProvider>
              <KiosksLiveUsageTab />
            </QuickActionConfirmationModalProvider>
          </KioskLiveUsageProvider>
        )}
      </Container>
    </BaseLayout>
  );
};

const RemoteAssistancePage: React.FC = () => {
  return (
    <LoadReservationOnKioskModalContextProvider>
      <RemoteAssistanceComponent />
    </LoadReservationOnKioskModalContextProvider>
  );
};

export default RemoteAssistancePage;
