import { gql } from '../__generated__/gql';

const SET_ACCESS_SETUP = gql(`
  mutation setAccessSetup(
    $data: AccessSetupInput!
    $where: AccessSetupWhere!
  ) {
    setAccessSetup(data: $data, where: $where)  {
      success
    }
  }
`);

export default SET_ACCESS_SETUP;
