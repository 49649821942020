/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Button from '../../Button';
import ButtonModel from '../../Button/enums/ButtonModel.enum';
import styled from 'styled-components';

export const BaseModalHeaderContainer = styled.div<{ showIcon: boolean }>`
  display: grid;
  grid-template-columns: ${({ showIcon }) => {
    if (showIcon) {
      return '32px 1fr 35px';
    }

    return '1fr 35px';
  }};
  gap: 12px;
  margin-bottom: 15px;
  height: 35px;
`;

export const HeaderText = styled.h2`
  font-size: 25px;
  line-height: 25px;
  margin: 0;
  padding: 0;
  flex: 1;
  font-weight: 700;
`;

export const HeaderIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 35px;

  svg {
    width: 32px;
    height: 32px;
  }
`;

export const CloseModalButton = styled(Button).attrs({
  model: ButtonModel.LIGHT_ROUNDED,
})``;
