import React, { useCallback, useMemo, useState } from 'react';
import { TabSelector, TabsContainer } from './styles/KioskLiveUsageQuickActionsModal.styles';
import Container from '../../../../../../components/Container';
import { DispenserType } from '../../../../../../__generated__/graphql';
import { QuickActionsModalTabType } from '../../types';
import RemoteKeyDispensing from './RemoteKeyDispensing';
import RequirementsOverride from './RequirementsOverride';
import { useKioskLiveUsageContext } from '../contexts/KioskLiveUsageContext';

const TabbedMenu: React.FC = () => {
  const dafaultTab = QuickActionsModalTabType.REQUIREMENTS_OVERRIDE;
  const [selectedTab, setSelectedTab] = useState(dafaultTab);
  const { quickActionsLiveUsageData } = useKioskLiveUsageContext();

  const tabButtons: Array<QuickActionsModalTabType> = useMemo(() => {
    const tabs = [QuickActionsModalTabType.REQUIREMENTS_OVERRIDE];
    const kioskDispenserType = quickActionsLiveUsageData?.kiosk?.dispenserType as DispenserType;
    const dispenserModelsSupported = [DispenserType.F1_1000, DispenserType.F3_1000];

    if (dispenserModelsSupported.includes(kioskDispenserType)) {
      tabs.push(QuickActionsModalTabType.KEY_CARD_DISPENSING);
    }

    return tabs;
  }, []);

  const renderSelectedTabComponent = useCallback(() => {
    switch (selectedTab) {
      case QuickActionsModalTabType.REQUIREMENTS_OVERRIDE:
        return <RequirementsOverride />;

      case QuickActionsModalTabType.KEY_CARD_DISPENSING:
        return <RemoteKeyDispensing />;

      default:
        throw new Error('Unsuported tab value');
    }
  }, [selectedTab]);

  return (
    <Container>
      <TabsContainer>
        {tabButtons.map((tab, key) => (
          <TabSelector
            key={`item_${key}`}
            onClick={(): void => setSelectedTab(tab)}
            active={selectedTab === tab}
          >
            {tab}
          </TabSelector>
        ))}
      </TabsContainer>
      <div>{renderSelectedTabComponent()}</div>
    </Container>
  );
};

export default TabbedMenu;
