import { ControlTitle, Description, OptionName, ToogleContainer } from '../styles/Settings.styles';
import React, { createContext, useContext, useReducer } from 'react';
import { EarlyCheckInPackage } from '../../../__generated__/graphql';
import { SectionContainer } from '../styles/SectionContainer.styles';

export type EarlyCheckInPackageData = EarlyCheckInPackage;

export enum AccessSetupSettingsOption {
  checkInGracePeriodsChecked = 'checkInGracePeriodsChecked',
  kioskPaidEarlyCheckInChecked = 'kioskPaidEarlyCheckInChecked',
  kioskVipLoyaltyCodeOptionsChecked = 'kioskVipLoyaltyCodeOptionsChecked',
}

export type AccessSetupSettings = {
  checkInGracePeriodsChecked: { enabled: boolean };
  kioskPaidEarlyCheckInChecked: { enabled: boolean };
  kioskVipLoyaltyCodeOptionsChecked: { enabled: boolean };
};

const defaultOptions: AccessSetupSettings = {
  checkInGracePeriodsChecked: { enabled: false },
  kioskPaidEarlyCheckInChecked: { enabled: false },
  kioskVipLoyaltyCodeOptionsChecked: { enabled: false },
};

type SettingsContext = {
  options: AccessSetupSettings;
  updateOption: (key: keyof AccessSetupSettings, enabled: boolean) => void;
};

const settingsReducer = (
  state: AccessSetupSettings,
  action: { type: keyof AccessSetupSettings; enabled: boolean }
): AccessSetupSettings => {
  return {
    ...state,
    [action.type]: { enabled: action.enabled },
  };
};

const Context = createContext<SettingsContext>({
  options: defaultOptions,
  updateOption: () => {},
});

interface ProviderProps {
  children: React.ReactNode;
  enabled?: boolean;
}

const Provider: React.FC<ProviderProps> = ({ children }) => {
  const [options, dispatch] = useReducer(settingsReducer, defaultOptions);

  const updateOption = (key: keyof AccessSetupSettings, enabled: boolean): void => {
    dispatch({ type: key, enabled });
  };

  return <Context.Provider value={{ options, updateOption }}>{children}</Context.Provider>;
};

export const useSettings = (): SettingsContext => {
  const context = useContext(Context);

  if (!context) {
    throw new Error('useSettings must be used within a SettingsProvider');
  }

  return context;
};

interface ToogleProps {
  title: string;
  name?: string;
  checked: boolean;
  onChange?: (checked: boolean) => void;
}

const Toogle: React.FC<ToogleProps> = ({ name, title, onChange, checked }: ToogleProps) => {
  const handleChange: React.ChangeEventHandler<HTMLInputElement> | undefined = (event) => {
    onChange?.(event.target.checked);
  };

  return (
    <ToogleContainer>
      <input type="checkbox" checked={checked} name={name} onChange={handleChange} />
      <OptionName>{title}</OptionName>
    </ToogleContainer>
  );
};

interface ContainerProps {
  title: string;
  description: string;
  children: React.ReactNode;
  titleMode?: 'large' | 'normal';
}

const Container: React.FC<ContainerProps> = ({ children, description, title, titleMode }) => {
  return (
    <SectionContainer>
      <ControlTitle mode={titleMode}>{title}</ControlTitle>
      <Description>{description}</Description>
      {children}
    </SectionContainer>
  );
};

export const Settings = {
  Toogle,
  Container,
  Context,
  Provider,
};
