import {
  AccessGrantState,
  AccessGrantStatus,
  RequirementStatus,
  RequirementType,
} from '../../../__generated__/graphql';

import { DataTableFilterValues } from '../../../@types/client/DataTableFilterValues';
import { FilterDashboardAccessGrantsQueryArgs } from '..';

export default function parseAccessGrantsFilterParameters(
  fieldsFiltered: DataTableFilterValues[]
): Partial<FilterDashboardAccessGrantsQueryArgs> {
  const filterParams: Partial<FilterDashboardAccessGrantsQueryArgs> = {};

  if (fieldsFiltered?.length > 0) {
    // Location filter
    const locFieldsIndex = fieldsFiltered.findIndex((field) => field.fieldKey === 'loc');
    if (locFieldsIndex > -1) {
      const inInput = fieldsFiltered[locFieldsIndex].values;

      filterParams.locationId = { in: inInput };
    }

    // ID Verification filter
    const ivsFieldsIndex = fieldsFiltered.findIndex((field) => field.fieldKey === 'ivs');
    if (ivsFieldsIndex > -1) {
      const inInput = fieldsFiltered[ivsFieldsIndex].values as RequirementStatus[];

      filterParams.requirementType = { in: [RequirementType.IdVerification] };
      filterParams.requirementStatus = { in: inInput };
    }

    // Check-in filter
    const ciFieldsIndex = fieldsFiltered.findIndex((field) => field.fieldKey === 'ci');
    if (ciFieldsIndex > -1) {
      const checkInParams = fieldsFiltered[ciFieldsIndex].values.map(
        (num) => parseInt(num, 10) ?? 0
      );
      const maxCheckIn = Math.max(...checkInParams);
      const minCheckIn = Math.min(...checkInParams);

      if (minCheckIn < 0) {
        const gtInput = minCheckIn * 1000 + Date.now();
        const ltInput = Math.max(maxCheckIn, 0) * 1000 + Date.now();
        if (!filterParams.timeFilter) {
          filterParams.timeFilter = {};
        }
        filterParams.timeFilter.startsAt = {
          from: new Date(gtInput),
          to: new Date(ltInput),
        };
      } else {
        const ltInput = maxCheckIn * 1000 + Date.now();
        if (!filterParams.timeFilter) {
          filterParams.timeFilter = {};
        }
        filterParams.timeFilter.startsAt = {
          from: new Date(),
          to: new Date(ltInput),
        };
      }
    }

    // Check-out filter
    const coFieldsIndex = fieldsFiltered.findIndex((field) => field.fieldKey === 'co');
    if (coFieldsIndex > -1) {
      const checkOutParams = fieldsFiltered[coFieldsIndex].values.map(
        (num) => parseInt(num, 10) ?? 0
      );
      const maxCheckOut = Math.max(...checkOutParams);
      const ltInput = maxCheckOut * 1000 + Date.now();

      if (!filterParams.timeFilter) {
        filterParams.timeFilter = {};
      }
      filterParams.timeFilter.endsAt = {
        from: new Date(),
        to: new Date(ltInput),
      };
    }

    // State filter
    const stateFieldsIndex = fieldsFiltered.findIndex((field) => field.fieldKey === 'state');
    if (stateFieldsIndex > -1) {
      const inInput = fieldsFiltered[stateFieldsIndex].values as AccessGrantState[];

      filterParams.state = { in: inInput };
    }

    // Status filter
    const statusFieldsIndex = fieldsFiltered.findIndex((field) => field.fieldKey === 'status');
    if (statusFieldsIndex > -1) {
      const inInput = fieldsFiltered[statusFieldsIndex].values as AccessGrantStatus[];

      filterParams.status = { in: inInput };
    }
  }

  return filterParams;
}
