import * as React from 'react';

const RemoteAssistanceWaitingCallsTabIcon: React.FC<{ color?: string }> = ({ color }) => {
  const fillColor = React.useMemo(() => color || '#fff', [color]);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path
        fill={fillColor}
        fillRule="evenodd"
        d="M2.633 5.57c.172-.994.74-1.792 1.352-2.404a2.27 2.27 0 0 1 3.422.246L8.758 5.22a2.5 2.5 0 0 1-.234 3.264l-.093.093a.833.833 0 0 0 0 1.179l1.81 1.81a.833.833 0 0 0 1.178 0l.093-.093a2.5 2.5 0 0 1 3.265-.235l1.82 1.361a2.255 2.255 0 0 1 .245 3.4c-.619.62-1.425 1.196-2.432 1.37-1.853.32-5.406.317-8.753-3.03C2.31 10.99 2.315 7.419 2.633 5.57m2.53-1.225c-.48.48-.796.981-.887 1.509-.257 1.49-.295 4.452 2.56 7.306 2.856 2.857 5.8 2.824 7.29 2.567.538-.093 1.048-.417 1.537-.906a.588.588 0 0 0-.063-.887l-1.821-1.361a.83.83 0 0 0-1.089.078l-.092.093a2.5 2.5 0 0 1-3.536 0l-1.81-1.81a2.5 2.5 0 0 1 0-3.535l.093-.093a.83.83 0 0 0 .078-1.088L6.073 4.41a.603.603 0 0 0-.91-.065"
        clipRule="evenodd"
      ></path>
      <path
        fill={fillColor}
        fillRule="evenodd"
        d="M11.663 2.5a5.833 5.833 0 0 1 5.834 5.833.833.833 0 1 1-1.667 0 4.167 4.167 0 0 0-4.167-4.166.833.833 0 0 1 0-1.667m0 3.333a2.5 2.5 0 0 1 2.5 2.5.833.833 0 1 1-1.666 0 .833.833 0 0 0-.834-.833.833.833 0 0 1 0-1.667"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default RemoteAssistanceWaitingCallsTabIcon;
