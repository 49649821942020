import React, { useMemo } from 'react';
import { Button } from './Button';
import { EarlyCheckInPackageData } from './Settings';
import { EmptyList } from './EmptyList';
import { FeatureWrapper } from '../styles/Settings.styles';
import { TimeRange } from './TimeRange';
import { useAccessSetup } from '../../../pages/Location/components/AccessSetupContext';

interface VipOrLoyaltyOptionsProps {
  onEdit: (earlyCheckInPackage: EarlyCheckInPackageData) => void;
  onDelete: (earlyCheckInPackage: EarlyCheckInPackageData) => void;
  onCreate: () => void;
  enabled?: boolean;
}

export const VipOrLoyaltyOptions: React.FC<VipOrLoyaltyOptionsProps> = ({
  onCreate,
  onDelete,
  onEdit,
  enabled,
}) => {
  const { data } = useAccessSetup();
  const optionsList = useMemo(() => data?.packages?.filter((p) => !!p.loyaltyCodes), [data]);

  return (
    <FeatureWrapper enabled={enabled}>
      {!optionsList?.length ? <EmptyList title="No VIP/Loyalty options set up yet" /> : null}
      {optionsList?.map((o) => (
        <TimeRange.Item
          pmsPackageId={o.externalId}
          vipAndloyaltyCode={o.loyaltyCodes?.join(', ')}
          earlyCheckinPrice={o.price}
          onEdit={(): void => onEdit(o)}
          onDelete={(): void => onDelete(o)}
        />
      ))}
      <Button.Submit title="Add VIP/Loyalty Option" onClick={onCreate} />
    </FeatureWrapper>
  );
};
