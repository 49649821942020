import {
  IssueKeyForKioskWithAccessGrantIdMutation,
  MutationIssueKeyForKioskWithAccessGrantIdArgs,
} from '../__generated__/graphql';
import ISSUE_KEY_FOR_KIOSK_WITH_ACCESS_GRANT_ID from '../graphql/issueKeyForKioskWithAccessGrantId';
import { useCallback } from 'react';
import { useMutation } from '@apollo/client';

interface ActionResult {
  success: boolean;
  errorMessage?: string;
}

type Response = {
  sendDispenseKeyCommand: (kioskId: string, accessGrantId: string) => Promise<ActionResult>;
};

export function useSendDispenseKeyCommand(): Response {
  const [issueKeyForKioskWithAccessGrantId] = useMutation<
    IssueKeyForKioskWithAccessGrantIdMutation,
    MutationIssueKeyForKioskWithAccessGrantIdArgs
  >(ISSUE_KEY_FOR_KIOSK_WITH_ACCESS_GRANT_ID);

  const sendDispenseKeyCommand = useCallback(
    async (kioskId: string, accessGrantId: string): Promise<ActionResult> => {
      try {
        if (!accessGrantId || !kioskId) {
          throw new Error('Kiosk and Access Grant configurations are not valid');
        }

        const result = await issueKeyForKioskWithAccessGrantId({
          variables: { accessGrantId, kioskId },
        });

        const success = result.data?.issueKeyForKioskWithAccessGrantId.success === true;

        if (!success) {
          throw new Error('The command was not successfully processed by the server');
        }

        return {
          success,
        };
      } catch (error) {
        const { message } = error as Error;

        return {
          success: false,
          errorMessage: `Error sending command: ${message}`,
        };
      }
    },
    []
  );

  return { sendDispenseKeyCommand };
}
