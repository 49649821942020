import Theme from '../../../../../../../../theme';
import styled from 'styled-components';

export const ConfirmationText = styled.p`
  font-size: 18px;
`;

export const ConfirmationWarningText = styled(ConfirmationText)`
  color: ${Theme.colors.danger.hexCode};
`;
