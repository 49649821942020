import { Separator, SettingsTabWrapper } from '../../../components/GracePeriodsTab/styles';
import { AccessSetup } from './AccessSetupContext';
import CustomSectionTitle from '../../../components/GracePeriodsTab/components/CustomSectionTitle';
import { EarlyCheckInGracePeriod } from '../../../components/AccessSetup/components/EarlyCheckInGracePeriod';
import GRACE_PERIOD_INPUTS from '../../../components/GracePeriodsTab/constants/inputs';
import GracePeriodSection from '../../../components/GracePeriodsTab/components/GracePeriodSection';
import { KeyLimitSection } from '../../../components/GracePeriodsTab/components/KeyLimitSection';
import { KioskPaidEarlyCheckIn } from '../../../components/AccessSetup/components/KioskPaidEarlyCheckIn';
import { KioskVipOrLoyaltyOptions } from '../../../components/AccessSetup/components/KioskVipOrLoyaltyOptions';
import React from 'react';
import { Settings } from '../../../components/AccessSetup/components/Settings';
import { Spacer } from '../../../components/AccessSetup/styles/Spacer.styles';
import { StandardCheckinAndCheckoutTime } from '../../../components/AccessSetup/components/StandardCheckinAndCheckoutTime';
import useGetGracePeriodsData from '../../../hooks/useGetGracePeriodsData';
import useSetGracePeriodsData from '../../../hooks/useSetGracePeriodsData';

export enum GracePeriodConfigLevel {
  LOCATION = 'LOCATION',
  TENANT = 'TENANT',
  KIOSK = 'KIOSK',
}

interface AccessSetupTabProps {
  accessGrantId?: string;
  gracePeriodsConfigLevel: GracePeriodConfigLevel;
  locationId?: string;
  tenantId?: string;
}

export const AccessSetupTab: React.FC<AccessSetupTabProps> = (props: AccessSetupTabProps) => {
  const { gracePeriodsData, isLoadingGracePeriod } = useGetGracePeriodsData(props);
  const { isSubmitting, setGracePeriodsConfigCallback } = useSetGracePeriodsData(props);

  return (
    <SettingsTabWrapper>
      <AccessSetup.Provider locationId={props.locationId}>
        {props.gracePeriodsConfigLevel === 'LOCATION' && (
          <Settings.Provider>
            <CustomSectionTitle title="Time Options" />
            <StandardCheckinAndCheckoutTime locationId={props.locationId} />
            <Spacer size="20px" />
            <EarlyCheckInGracePeriod
              gracePeriodsData={gracePeriodsData}
              gracePeriodsConfigLevel={props.gracePeriodsConfigLevel}
              accessGrantId={props.accessGrantId}
              locationId={props.locationId}
              tenantId={props.tenantId}
            />
            <KioskPaidEarlyCheckIn />
            <Separator />
            <KioskVipOrLoyaltyOptions />
            <Separator />
          </Settings.Provider>
        )}
        <CustomSectionTitle title="Override Period Access Setup" margin="25px 0" />
        {GRACE_PERIOD_INPUTS.map(({ title, category, description }, index) => (
          <GracePeriodSection
            key={`section-title-item-${index}`}
            title={`${index + 1}. ${title}`}
            description={description}
            category={category}
            loading={isLoadingGracePeriod}
            gracePeriods={gracePeriodsData}
            setGracePeriodsConfigCallback={setGracePeriodsConfigCallback}
            isSubmitting={isSubmitting}
          />
        ))}
        <KeyLimitSection {...props} limitKeysConfigLevel={props.gracePeriodsConfigLevel} />
      </AccessSetup.Provider>
    </SettingsTabWrapper>
  );
};
