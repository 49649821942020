import { KeyDispensingStepProps, QuickActionsModalTabType } from '../../types';
import {
  QuickActionsButtonIconContainer,
  QuickActionsButtonStyle,
} from '../styles/KioskLiveUsageCard.style';
import React, { useCallback } from 'react';
import {
  RemoteKeyDispensingContainer,
  StepsContainer,
} from './styles/KioskLiveUsageQuickActionsModal.styles';
import { ActionBarDispenseKey } from '../../../../../../assets/icons/actionBarDispenseKey';
import KeyDispensingStep from './KeyDispensingStep';
import RemoteKeyDispensingBanner1 from '../../../../../../assets/img/KioskLiveUsage/remote-key-dispensing-banner1.png';
import RemoteKeyDispensingBanner2 from '../../../../../../assets/img/KioskLiveUsage/remote-key-dispensing-banner2.png';
import { openQuickActionProcessingModal } from '../../../../../../redux/actions/modals.actions';
import { useDispatch } from 'react-redux';
import { useKioskLiveUsageContext } from '../contexts/KioskLiveUsageContext';
import { useQuickActionConfirmationModalContext } from '../contexts/QuickActionConfirmationModalContext';
import { useSendDispenseKeyCommand } from '../../../../../../hooks/useSendDispenseKeyCommand';

const RemoteKeyDispensing: React.FC = () => {
  const { quickActionsLiveUsageData, closeQuickActionsModal } = useKioskLiveUsageContext();
  const { openQuickActionConfirmationModal } = useQuickActionConfirmationModalContext();
  const { sendDispenseKeyCommand } = useSendDispenseKeyCommand();
  const dispatch = useDispatch();
  const steps: Array<Omit<KeyDispensingStepProps, 'step'>> = [
    {
      title: 'Verify that you and/or the guest is in front of the right kiosk.',
      imageSrc: RemoteKeyDispensingBanner1,
    },
    {
      title: 'Dispense the key, wait, and make sure that you or the guest collected it.',
      imageSrc: RemoteKeyDispensingBanner2,
    },
  ];

  const sendDispenseKey = useCallback(() => {
    const accessGrantId = quickActionsLiveUsageData?.accessGrant?.id;
    const kioskId = quickActionsLiveUsageData?.kiosk.id;

    if (!kioskId || !accessGrantId) {
      throw new Error('kioskId and accessGrantId are requried');
    }

    return sendDispenseKeyCommand(kioskId, accessGrantId);
  }, []);

  const dispenseKey = useCallback(() => {
    closeQuickActionsModal();

    openQuickActionConfirmationModal({
      title: 'Confirm Keycard Dispensing',
      type: QuickActionsModalTabType.KEY_CARD_DISPENSING,
      action: () => {
        dispatch(
          openQuickActionProcessingModal({
            action: sendDispenseKey,
            ctaType: 'remotePrintKeyCard',
          })
        );
      },
      buttonText: {
        confirm: `Dispense on ${quickActionsLiveUsageData?.kiosk.name}`,
      },
    });
  }, []);

  return (
    <RemoteKeyDispensingContainer>
      <StepsContainer>
        {steps.map(({ title, imageSrc }, key) => (
          <KeyDispensingStep key={`item_${key}`} title={title} imageSrc={imageSrc} step={key + 1} />
        ))}
      </StepsContainer>
      <button
        onClick={dispenseKey}
        style={{
          ...QuickActionsButtonStyle,
          width: 285,
          justifySelf: 'center',
          fontSize: 20,
          display: 'flex',
          justifyContent: 'center',
          marginTop: 20,
        }}
      >
        <QuickActionsButtonIconContainer>
          <ActionBarDispenseKey />
          Dispense Keycard
        </QuickActionsButtonIconContainer>
      </button>
    </RemoteKeyDispensingContainer>
  );
};

export default RemoteKeyDispensing;
