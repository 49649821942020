import Theme from '../../../theme';
import styled from 'styled-components';

export const ToogleContainer = styled.div`
  display: flex;
  flex-direction: col;
`;

export const OptionName = styled.p`
  color: ${Theme.colors.white.hexCode};
  font-feature-settings: 'liga' off, 'clig' off;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 8px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`;

export const Description = styled.p`
  color: ${Theme.colors.white.hexCode};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const ControlTitle = styled.p<{ mode?: 'large' | 'normal' }>`
  font-size: ${({ mode }): string => {
    return mode === 'large' ? '24px' : '18px';
  }};
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: ${Theme.colors.white.hexCode};
`;

export const FeatureWrapper = styled.div<{ enabled?: boolean }>`
  opacity: ${({ enabled }): string => {
    return enabled ? '1' : '0.3';
  }};
  pointer-events: ${({ enabled }): string => {
    return enabled ? 'auto' : 'none';
  }};
`;
