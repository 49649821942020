import { Radio } from '../styles/RadioOption.styles';
import React from 'react';

interface RadioOptionProps {
  label: string;
  value: string;
  selected: boolean;
  onChange?: (option: string) => void;
}

export const RadioOption: React.FC<RadioOptionProps> = ({ label, value, selected, onChange }) => {
  return (
    <Radio.Label selected={selected}>
      <Radio.Input
        type="radio"
        onChange={(): void => onChange?.(value)}
        selected={selected}
        checked={selected}
      />
      {label}
    </Radio.Label>
  );
};
