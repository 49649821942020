import {
  MutationUpdateEarlyCheckInPackageArgs,
  UpdateEarlyCheckInPackageMutation,
} from '../../__generated__/graphql';
import { MutationHookDataWithPromise } from '../@types/MutationHookData';
import MutationHookParams from '../@types/MutationHookParams';
import UPDATE_EARLY_CHECKIN_PACKAGE from '../../graphql/updateEarlyCheckInPackage';
import { useMutation } from '@apollo/client';

export function useUpdateEarlyCheckInPackage({
  onCompleted,
  onError,
}: MutationHookParams<UpdateEarlyCheckInPackageMutation> = {}): MutationHookDataWithPromise<
  MutationUpdateEarlyCheckInPackageArgs,
  UpdateEarlyCheckInPackageMutation
> {
  const [update, { data, error, loading }] = useMutation<
    UpdateEarlyCheckInPackageMutation,
    MutationUpdateEarlyCheckInPackageArgs
  >(UPDATE_EARLY_CHECKIN_PACKAGE, {
    onCompleted,
    onError,
  });

  return {
    data,
    error,
    loading,
    promise: update,
  };
}
