import React, { useEffect, useRef, useState } from 'react';
import { Dropdown } from '../styles/InputDropdown.styles';
import { useFormContext } from 'react-hook-form';

interface InputDropdownProps {
  options: string[];
  name?: string;
  error?: string;
  onSelect?: (item: string) => void;
}

export const InputDropdown: React.FC<InputDropdownProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const { register, setValue } = useFormContext();

  const handleOnClick = (): void => {
    setIsOpen(true);
  };

  const handleOnItemClick = (item: string): void => {
    if (props.name) {
      setValue(props.name, item);
    }

    props.onSelect?.(item);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (dropdownRef?.current && !dropdownRef?.current?.contains(event?.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return (): void => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Dropdown.Container ref={dropdownRef}>
      <Dropdown.Button type="button" onClick={handleOnClick} error={props.error}>
        <Dropdown.Label name={props.name} ref={register} readOnly />
        <Dropdown.Separator />
        <Dropdown.Icon />
      </Dropdown.Button>
      {isOpen ? (
        <Dropdown.List>
          {props.options.map((item) => (
            <Dropdown.Item key={item} onClick={(): void => handleOnItemClick(item)}>
              {item}
            </Dropdown.Item>
          ))}
        </Dropdown.List>
      ) : null}
    </Dropdown.Container>
  );
};
