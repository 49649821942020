import Theme from '../../../theme';
import styled from 'styled-components';

export const Container = styled.div<{ error?: string }>`
  display: flex;
  alignitems: center;
  flexwrap: wrap;
  padding: 8px;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 2px solid
    ${({ error }): string => {
      return error ? Theme.colors.danger.hexCode : Theme.colors.whiteSmoke.hexCode;
    }};
  background: ${Theme.colors.white.hexCode};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 100%;
`;

export const Tag = styled.div`
  background: ${Theme.colors.whiteSmoke.hexCode};
  display: flex;
  border-radius: 4px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  margin: 4px;
`;

export const RemoveButton = styled.button`
  background: none;
  border: none;
  marginleft: 8px;
  cursor: pointer;
  fontsize: 16px;
`;

export const Input = styled.input`
  flex: 1;
  minwidth: 120px;
  border: none;
  outline: none;
  padding: 8px;
  fontsize: 14px;
`;
