import { DataTableHighlightText } from '../../DataTableV2/components/DataTableHighlightText/DataTableHighlightText';
import React from 'react';
import { TableHighlightTextTextStyle } from '../styles/TableHighlightText.style';

export interface TableHighlightTextProps {
  text: React.ReactElement | string | number;
  searchKey?: string;
  style?: React.CSSProperties;
  highlightedColor?: string;
}

const TableHighlightText: React.FC<TableHighlightTextProps> = ({
  text,
  searchKey,
  style,
  highlightedColor,
}) => {
  return (
    <span style={style ?? TableHighlightTextTextStyle}>
      {typeof text === 'string' ? (
        <DataTableHighlightText
          value={{
            key: searchKey ?? '',
            displayValue: text,
            style,
            highlightedColor,
          }}
        />
      ) : (
        text
      )}
    </span>
  );
};

export default TableHighlightText;
