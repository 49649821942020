import {
  FailureMessages,
  useLoadReservationOnKioskFeedbackListener,
} from './useLoadReservationOnKioskFeedbackListener';
import {
  LoadKioskSessionMutation,
  MutationLoadKioskSessionArgs,
} from '../../../../../../../__generated__/graphql';

import { ActionResult } from '../../../../../../../components/QuickActionBar/@types/QuickActionBarTypes';
import { LOAD_KIOSK_SESSION } from '../../../../../../../graphql/loadKioskSession';
import { RootState } from '../../../../../../../redux/reducers';
import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';

type LoadKioskSessionData = LoadKioskSessionMutation;
type LoadKioskSessionArgs = MutationLoadKioskSessionArgs;

type UseLoadReservationOnKioskType = {
  loadKioskSession: (args: LoadKioskSessionArgs) => Promise<ActionResult>;
};

export function useLoadReservationOnKiosk(): UseLoadReservationOnKioskType {
  const { activeMembership } = useSelector((state: RootState) => state.me);

  const [loadKioskSessionMutation] = useMutation<LoadKioskSessionData, LoadKioskSessionArgs>(
    LOAD_KIOSK_SESSION
  );

  const { loadReservationOnKioskFeedbackListener } = useLoadReservationOnKioskFeedbackListener();

  const loadKioskSession = useCallback(
    async (args: LoadKioskSessionArgs): Promise<ActionResult> => {
      try {
        const response = await loadKioskSessionMutation({
          variables: args,
        });

        if (!response.data?.loadKioskSession.success) {
          throw new Error();
        }

        return loadReservationOnKioskFeedbackListener({
          tenantId: activeMembership.tenantId,
          kioskId: args.kioskId,
          accessGrantId: args.accessGrantId,
        });
      } catch (error) {
        return {
          success: false,
          errorMessage: FailureMessages.UnknownError,
        };
      }
    },
    [activeMembership]
  );

  return {
    loadKioskSession,
  };
}
