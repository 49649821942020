import {
  LiveUsageLabelContainer,
  QuickActionsButtonIconContainer,
  QuickActionsButtonStyle,
} from './styles/KioskLiveUsageCard.style';
import React, { useCallback } from 'react';
import { KioskLiveUsageCardProps } from '../types';
import LiveUsageQuickActionsIcon from '../../../../../assets/icons/liveUsageQuickActionsIcon';
import { useKioskLiveUsageContext } from './contexts/KioskLiveUsageContext';

const LiveUsageQuickActionsButton: React.FC<KioskLiveUsageCardProps> = ({ kiosk }) => {
  const { openQuickActionsModal } = useKioskLiveUsageContext();
  const onPress = useCallback(() => {
    openQuickActionsModal(kiosk);
  }, []);

  if (!kiosk.accessGrant?.confirmationCode) {
    return <></>;
  }

  return (
    <button style={QuickActionsButtonStyle} onClick={onPress}>
      <QuickActionsButtonIconContainer>
        <LiveUsageQuickActionsIcon />
        {kiosk.accessGrant.confirmationCode}
      </QuickActionsButtonIconContainer>
      <LiveUsageLabelContainer>
        <div>Quick Actions</div>
        <div>&rarr;</div>
      </LiveUsageLabelContainer>
    </button>
  );
};

export default LiveUsageQuickActionsButton;
