import { gql } from '../__generated__/gql';

const GET_ACCESS_SETUP = gql(`
  query getAccessSetup($locationId: String!) {
    getAccessSetup(locationId: $locationId)  {
      defaultStartTime,
      defaultEndTime, 
      enableEarlyCheckInByTimeRange,
      enableEarlyCheckInByLoyalty
      packages {
        endTime,
        endTime,
        externalId,
        freeUpgrade,
        locationId,
        loyaltyCodes,
        price,
        serviceId,
        startTime
      }
    }
  }
`);

export default GET_ACCESS_SETUP;
