import Theme from '../../../../../../theme';
import styled from 'styled-components';

export const BlurredContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: start;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(${Theme.colors.whiteSmoke.rgb}, 0.5);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  padding: 100px;
  width: 100%;
  height: 100%;
`;

export const MessageContainer = styled.div`
  height: 210px;
  width: 420px;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-rows: 30px 1fr;
  gap: 20px;
  padding: 30px;
  background-color: ${Theme.colors.white.hexCode};
  border-radius: 12px;
  box-shadow: 0px 1px 4px 0px rgba(${Theme.colors.black.rgb}, 0.1);

  & > :first-child {
    font-size: 24px;
    font-weight: 700;
  }

  & > :nth-child(2) {
    font-size: 16px;
    font-weight: 600;
  }
`;
