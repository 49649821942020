import 'styled-components/macro';

import ButtonModel from './enums/ButtonModel.enum';
import { Colors } from '../../theme/colors';
import { ComponentModelProperties } from '../../@types/common';
import React from 'react';
import Theme from '../../theme';
import isValidColorString from '../../util/css/isValidColorString';
import isValidPixelString from '../../util/css';
import styled from 'styled-components';

const BUTTON_CSS_PROPERTY_MAP: ComponentModelProperties = {
  [ButtonModel.DEFAULT]: {
    breakpoints: {},
    default: {
      background: 'initial',
      color: 'initial',
      height: 'initial',
      minWidth: 'initial',
    },
  },
  [ButtonModel.STEP]: {
    breakpoints: {},
    default: {
      active: {
        background: Colors.blackRussian,
        borderColor: Colors.white,
        color: Colors.white,
      },
      background: Theme.colors.white.hexCode,
      borderColor: Colors.comet,
      borderRadius: '12px',
      borderWidth: '1px',
      color: Colors.comet,
      height: '37px',
      minWidth: '50px',
      width: 'max-content',
    },
  },
  [ButtonModel.ACTIVE_CTA]: {
    breakpoints: {},
    default: {
      background: Theme.colors.buttonDominant.hexCode,
      color: Theme.colors.white.hexCode,
      height: '56px',
      minWidth: '200px',
    },
  },
  [ButtonModel.ACTIVE_CTA_DARK]: {
    breakpoints: {
      '1200px': {
        height: '42px',
      },
      '1450px': {
        height: '42px',
      },
    },
    default: {
      background: Theme.colors.buttonDark.hexCode,
      color: Theme.colors.white.hexCode,
      height: '56px',
      minWidth: '200px',
    },
  },
  [ButtonModel.CIRCLE_ICON_BUTTON_DARK]: {
    breakpoints: {},
    default: {
      background: `rgba(${Theme.colors.bgBlackTint.rgb}, 0.8)`,
      color: Theme.colors.white.hexCode,
      height: '34px',
      width: '34px',
    },
  },
  [ButtonModel.PASSIVE_CTA]: {
    breakpoints: {},
    default: {
      background: `rgba(${Theme.colors.buttonSubmissive.rgb}, 0.1)`,
      color: Theme.colors.dark.hexCode,
      height: '56px',
      minWidth: '200px',
    },
  },
  [ButtonModel.ACTIVE_ICON_BUTTON]: {
    breakpoints: {},
    default: {
      background: Theme.colors.white.hexCode,
      borderRadius: '12px',
      color: Theme.colors.dark.hexCode,
      height: '40px',
      hover: {
        background: Theme.colors.white.hexCode,
        color: Theme.colors.dark.hexCode,
      },
      width: '40px',
    },
  },
  [ButtonModel.PASSIVE_ICON_BUTTON]: {
    breakpoints: {},
    default: {
      background: `rgba(${Theme.colors.white.rgb}, 0.2)`,
      borderRadius: '12px',
      color: Theme.colors.white.hexCode,
      height: '40px',
      hover: {
        background: `rgba(${Theme.colors.white.rgb}, 0.4)`,
        color: Theme.colors.white.hexCode,
      },
      width: '40px',
    },
  },
  [ButtonModel.INPUT_EMBEDDED_BUTTON]: {
    breakpoints: {
      '1200px': {
        borderRadius: '8px',
        fontSize: '12px',
        height: '37px',
        lineHeight: Theme.fontStyles.bodySmall.lineHeight,
        width: '75px',
      },
      '1450px': {
        borderRadius: '8px',
        fontSize: '12px',
        height: '37px',
        lineHeight: Theme.fontStyles.bodySmall.lineHeight,
        width: '75px',
      },
    },
    default: {
      background: Theme.colors.buttonInputEmbedded.hexCode,
      borderRadius: '12px',
      color: Theme.colors.white.hexCode,
      height: '48px',
      width: '148px',
    },
  },
  [ButtonModel.LIST_DARK]: {
    breakpoints: {},
    default: {
      background: 'transparent',
      borderRadius: '12px',
      borderWidth: '2px',
      color: Theme.colors.white.hexCode,
      height: '36px',
      width: '100%',
    },
  },
  [ButtonModel.FILTER_DARK]: {
    breakpoints: {
      '1200px': {
        borderRadius: '8px',
        fontSize: '12px',
        height: '37px',
        lineHeight: Theme.fontStyles.bodySmall.lineHeight,
        width: '90px',
      },
      '1450px': {
        borderRadius: '8px',
        fontSize: '12px',
        height: '37px',
        lineHeight: Theme.fontStyles.bodySmall.lineHeight,
        width: '90px',
      },
    },
    default: {
      background: `rgba(${Theme.colors.white.rgb}, .05)`,
      borderRadius: '16px',
      color: Colors.shuttleGrey,
      height: '56px',
      width: '120px',
    },
  },
  [ButtonModel.FILTER_DARK_SELECTED]: {
    breakpoints: {
      '1200px': {
        borderRadius: '8px',
        fontSize: '12px',
        height: '37px',
        lineHeight: Theme.fontStyles.bodySmall.lineHeight,
        width: '90px',
      },
      '1450px': {
        borderRadius: '8px',
        fontSize: '12px',
        height: '37px',
        lineHeight: Theme.fontStyles.bodySmall.lineHeight,
        width: '90px',
      },
    },
    default: {
      background: Colors.slateBlue,
      borderRadius: '16px',
      color: Theme.colors.white.hexCode,
      height: '56px',
      width: '120px',
    },
  },
  [ButtonModel.TAB]: {
    breakpoints: {},
    default: {
      active: {
        background: Theme.colors.bgBlackTint.hexCode,
        color: Theme.colors.white.hexCode,
      },
      background: 'transparent',
      borderRadius: '12px',
      color: Theme.colors.grey.hexCode,
      height: '36px',
      hover: {
        background: Colors.blackRussian,
        color: Theme.colors.white.hexCode,
      },
      padding: '0 22.5px',
      width: 'max-content',
    },
  },
  [ButtonModel.INLINE_BUTTON]: {
    breakpoints: {},
    default: {
      active: {
        background: 'transparent',
        color: '#A0ACCE',
      },
      background: 'transparent',
      color: Theme.colors.white.hexCode,
      width: 'max-content',
    },
  },
  [ButtonModel.LIGHT_ROUNDED]: {
    breakpoints: {},
    default: {
      background: `rgba(${Theme.colors.white.rgb})`,
      borderRadius: '50%',
      color: Theme.colors.bgBlackTint.hexCode,
      height: '35px',
      width: '35px',
    },
  },
  [ButtonModel.QUICK_ACTION_MODAL_CONFIRM]: {
    breakpoints: {
      '1450px': {
        fontSize: '16px',
      },
    },
    default: {
      background: `rgba(${Theme.colors.quickActionButton.rgb})`,
      borderRadius: '12px',
      color: Theme.colors.white.hexCode,
      fontSize: '18px',
      fontWeight: '600',
      height: '50px',
      minWidth: '150px',
      padding: '0 20px',
    },
  },
  [ButtonModel.QUICK_ACTION_MODAL_CANCEL]: {
    breakpoints: {},
    default: {
      background: 'transparent',
      borderRadius: '12px',
      color: Theme.colors.bgBlackTint.hexCode,
      fontSize: '18px',
      fontWeight: '700',
      height: '55px',
      width: '100px',
    },
  },
};

export interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  active?: boolean;
  background?: string;
  bold?: boolean;
  borderColor?: string;
  borderRadius?: string;
  borderWidth?: string;
  buttonType?: 'borderless' | 'classic';
  color?: string;
  columns?: string;
  css?: string;
  disabled?: boolean;
  fontSize?: string;
  height?: string;
  lineHeight?: string;
  margin?: string;
  maxContent?: boolean;
  model: keyof typeof BUTTON_CSS_PROPERTY_MAP;
  padding?: string;
  width?: string;
}

const Button = styled.button<ButtonProps>`
  align-items: center;
  background: ${({ active, background, model }): string => {
    if (background && isValidColorString(background)) {
      return background;
    }

    if (BUTTON_CSS_PROPERTY_MAP[model]) {
      const activeModel = BUTTON_CSS_PROPERTY_MAP[model];

      if (activeModel.default.active && active) {
        return activeModel.default.active.background;
      }

      return activeModel.default.background;
    }

    return 'initial';
  }};
  border-color: ${({ active, model }): string => {
    const activeModel = BUTTON_CSS_PROPERTY_MAP[model];

    if (activeModel?.default.active?.borderColor && active) {
      return activeModel.default.active?.borderColor;
    }

    if (activeModel.default.borderColor) {
      return BUTTON_CSS_PROPERTY_MAP[model].default.borderColor!;
    }

    return 'initial';
  }};
  border-radius: ${({ borderRadius, model }): string => {
    if (borderRadius) {
      return borderRadius;
    }

    if (BUTTON_CSS_PROPERTY_MAP[model].default.borderRadius) {
      return BUTTON_CSS_PROPERTY_MAP[model].default.borderRadius!;
    }

    return '16px';
  }};
  border-style: solid;
  border-width: ${({ borderWidth, model }): string => {
    if (borderWidth && isValidPixelString(borderWidth)) {
      return borderWidth;
    }

    return BUTTON_CSS_PROPERTY_MAP[model].default.borderWidth || '0';
  }};
  box-shadow: unset;
  box-sizing: border-box;
  color: ${({ active, color, model }): string => {
    if (color && isValidColorString(color)) {
      return color;
    }

    if (BUTTON_CSS_PROPERTY_MAP[model]) {
      const activeModel = BUTTON_CSS_PROPERTY_MAP[model];
      if (activeModel.default.active && active) {
        return activeModel.default.active.color;
      }

      return activeModel.default.color;
    }

    return 'initial';
  }};
  cursor: pointer;
  display: flex;
  font-size: ${({ fontSize }): string => {
    if (fontSize && isValidPixelString(fontSize)) {
      return fontSize;
    }

    return Theme.fontStyles.bodyLarge.size;
  }};
  font-weight: ${({ bold }): string => {
    return bold ? 'bold' : 'regular';
  }};
  grid-auto-columns: ${({ columns }): string => columns ?? 'initial'};
  height: ${({ height, model }): string => {
    if (height && isValidPixelString(height)) {
      return height;
    }

    const modelHeight = BUTTON_CSS_PROPERTY_MAP[model]?.default.height;

    if (modelHeight) {
      return modelHeight;
    }

    return '40px';
  }};
  justify-content: center;
  margin: 0;
  min-width: ${({ model }): string => {
    const modelMinWidth = BUTTON_CSS_PROPERTY_MAP[model]?.default.minWidth;

    if (modelMinWidth) {
      return modelMinWidth;
    }

    return 'initial';
  }};
  padding: ${({ padding, model }): string => {
    if (padding) {
      return padding;
    }

    const defaultPadding = BUTTON_CSS_PROPERTY_MAP[model]?.default.padding;

    if (defaultPadding) {
      return defaultPadding;
    }

    return '0';
  }};
  width: ${({ model, width }): string => {
    if (width && isValidPixelString(width)) {
      return width;
    }

    const modelWidth = BUTTON_CSS_PROPERTY_MAP[model]?.default.width;

    if (modelWidth) {
      return modelWidth;
    }

    return 'initial';
  }};

  &:active {
    opacity: 0.85;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    background: ${({ active, background, model }): string => {
      if (background && isValidColorString(background)) {
        return background;
      }

      const activeModel = BUTTON_CSS_PROPERTY_MAP[model];
      const activeBackground = activeModel.default.active?.background;

      if (active && activeBackground) {
        return activeBackground;
      }

      const hoverBackground = activeModel?.default.hover?.background;

      if (!active && hoverBackground) {
        return hoverBackground;
      }

      if (activeModel) {
        return activeModel.default.background;
      }

      return 'initial';
    }};
    color: ${({ active = false, color, model }): string => {
      const defaultColor = BUTTON_CSS_PROPERTY_MAP[model].default.color;

      if (active) {
        return BUTTON_CSS_PROPERTY_MAP[model].default.active?.color ?? defaultColor;
      }

      if (color && isValidColorString(color)) {
        return color;
      }

      const hoverColor = BUTTON_CSS_PROPERTY_MAP[model]?.default.hover?.color;

      if (hoverColor) {
        return hoverColor;
      }

      if (BUTTON_CSS_PROPERTY_MAP[model]) {
        return BUTTON_CSS_PROPERTY_MAP[model].default.color;
      }

      return 'initial';
    }};
  }

  p.label {
    color: ${({ background, model }): string => {
      if (background && isValidColorString(background)) {
        return background;
      }

      if (BUTTON_CSS_PROPERTY_MAP[model]) {
        return BUTTON_CSS_PROPERTY_MAP[model].default.background;
      }

      return 'initial';
    }};
  }

  svg g {
    fill: ${({ color, model }): string => {
      if (color && isValidColorString(color)) {
        return color;
      }

      const modelColor = BUTTON_CSS_PROPERTY_MAP[model]?.default.color;
      return modelColor || 'inherit';
    }};
  }

  @media only screen and (max-width: 1450px) {
    border-radius: ${({ borderRadius, model }): string => {
      if (borderRadius) {
        return borderRadius;
      }

      const modelProperties = BUTTON_CSS_PROPERTY_MAP[model];
      const breakpoint = modelProperties?.breakpoints['1200px'];
      const breakpointBorderRadius = breakpoint?.borderRadius;

      if (breakpointBorderRadius) {
        return breakpointBorderRadius;
      }

      const defaultBorderRadius = modelProperties.default.borderRadius;

      if (defaultBorderRadius) {
        return defaultBorderRadius;
      }

      return '16px';
    }};
    font-size: ${({ fontSize, model }): string => {
      if (fontSize && isValidPixelString(fontSize)) {
        return fontSize;
      }

      const modelProperties = BUTTON_CSS_PROPERTY_MAP[model];
      const breakpoint = modelProperties?.breakpoints['1450px'];
      const breakpointFontSize = breakpoint?.fontSize;

      if (breakpointFontSize) {
        return breakpointFontSize;
      }

      const defaultFontSize = modelProperties.default.fontSize;

      if (defaultFontSize) {
        return defaultFontSize;
      }

      return Theme.fontSizes.bodyLarge;
    }};
    height: ${({ height, model }): string => {
      if (height && isValidPixelString(height)) {
        return height;
      }

      const modelProperties = BUTTON_CSS_PROPERTY_MAP[model];
      const breakpoint = modelProperties?.breakpoints['1450px'];
      const breakpointHeight = breakpoint?.height;

      if (breakpointHeight) {
        return breakpointHeight;
      }

      const defaultHeight = modelProperties.default.height;

      if (defaultHeight) {
        return defaultHeight;
      }

      return '40px';
    }};
    line-height: ${({ lineHeight, model }): string => {
      if (lineHeight && isValidPixelString(lineHeight)) {
        return lineHeight;
      }

      const modelProperties = BUTTON_CSS_PROPERTY_MAP[model];
      const breakpoint = modelProperties?.breakpoints['1450px'];
      const breakpointLineHeight = breakpoint?.lineHeight;

      if (breakpointLineHeight) {
        return breakpointLineHeight;
      }

      const defaultLineHeight = modelProperties.default.lineHeight;

      if (defaultLineHeight) {
        return defaultLineHeight;
      }

      return Theme.fontStyles.bodyLarge.lineHeight;
    }};
    width: ${({ model, width }): string => {
      if (width && isValidPixelString(width)) {
        return width;
      }

      const modelProperties = BUTTON_CSS_PROPERTY_MAP[model];
      const breakpoint = modelProperties?.breakpoints['1450px'];
      const breakpointWidth = breakpoint?.width;

      if (breakpointWidth) {
        return breakpointWidth;
      }

      const defaultWidth = modelProperties.default.width;

      if (defaultWidth) {
        return defaultWidth;
      }

      return 'initial';
    }};
  }

  @media only screen and (max-width: 1200px) {
    border-radius: ${({ borderRadius, model }): string => {
      if (borderRadius) {
        return borderRadius;
      }

      const modelProperties = BUTTON_CSS_PROPERTY_MAP[model];
      const breakpoint = modelProperties?.breakpoints['1200px'];
      const breakpointBorderRadius = breakpoint?.borderRadius;

      if (breakpointBorderRadius) {
        return breakpointBorderRadius;
      }

      const defaultBorderRadius = modelProperties.default.borderRadius;

      if (defaultBorderRadius) {
        return defaultBorderRadius;
      }

      return '16px';
    }};
    font-size: ${({ fontSize, model }): string => {
      if (fontSize && isValidPixelString(fontSize)) {
        return fontSize;
      }

      const modelProperties = BUTTON_CSS_PROPERTY_MAP[model];
      const breakpoint = modelProperties?.breakpoints['1200px'];
      const breakpointFontSize = breakpoint?.fontSize;

      if (breakpointFontSize) {
        return breakpointFontSize;
      }

      const defaultFontSize = modelProperties.default.fontSize;

      if (defaultFontSize) {
        return defaultFontSize;
      }

      return Theme.fontSizes.bodyLarge;
    }};
    height: ${({ height, model }): string => {
      if (height && isValidPixelString(height)) {
        return height;
      }

      const modelProperties = BUTTON_CSS_PROPERTY_MAP[model];
      const breakpoint = modelProperties?.breakpoints['1200px'];
      const breakpointHeight = breakpoint?.height;

      if (breakpointHeight) {
        return breakpointHeight;
      }

      const defaultHeight = modelProperties.default.height;

      if (defaultHeight) {
        return defaultHeight;
      }

      return '40px';
    }};
    line-height: ${({ lineHeight, model }): string => {
      if (lineHeight && isValidPixelString(lineHeight)) {
        return lineHeight;
      }

      const modelProperties = BUTTON_CSS_PROPERTY_MAP[model];
      const breakpoint = modelProperties?.breakpoints['1200px'];
      const breakpointLineHeight = breakpoint?.lineHeight;

      if (breakpointLineHeight) {
        return breakpointLineHeight;
      }

      const defaultLineHeight = modelProperties.default.lineHeight;

      if (defaultLineHeight) {
        return defaultLineHeight;
      }

      return Theme.fontStyles.bodyLarge.lineHeight;
    }};
    width: ${({ model, width }): string => {
      if (width && isValidPixelString(width)) {
        return width;
      }

      const modelProperties = BUTTON_CSS_PROPERTY_MAP[model];
      const breakpoint = modelProperties?.breakpoints['1200px'];
      const breakpointWidth = breakpoint?.width;

      if (breakpointWidth) {
        return breakpointWidth;
      }

      const defaultWidth = modelProperties.default.width;

      if (defaultWidth) {
        return defaultWidth;
      }

      return 'initial';
    }};
  }
`;

Button.defaultProps = {
  model: ButtonModel.ACTIVE_CTA,
};

Button.displayName = 'StyledButton';

export default Button;
