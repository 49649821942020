import { KioskLiveUsageCardProps } from '../types';
import { KioskLiveUsageReservationContainerRowStyle } from './styles/KioskLiveUsageCard.style';
import React from 'react';

export const PrebookedPackagesInfo: React.FC<KioskLiveUsageCardProps> = ({ kiosk }) => {
  if (!kiosk.accessGrant) {
    return <></>;
  }

  return kiosk.accessGrant.bookedPackages.length > 0 ? (
    <div style={KioskLiveUsageReservationContainerRowStyle}>
      {kiosk.accessGrant.bookedPackages
        .map(({ title, quantity }) => `${title}(${quantity})`)
        .join(', ')}
    </div>
  ) : (
    <></>
  );
};
