/* eslint-disable sort-keys */
import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3ThemeHomeSchema: NodeSchemaProperties = {
  home: {
    title: '#3 Home',
    type: 'object',
    properties: {
      reservationInfo: {
        title: '#1 Reservation Info',
        type: 'object',
        properties: {
          'confirmationCodeTextColor:enable': {
            description: '[home.reservationInfo.confirmationCodeTextColor]',
            title: '#1.1 Confirmation Code Text Color',
            type: 'boolean',
          },
          confirmationCodeTextColor: {
            type: 'string',
          },
          checkInButton: {
            title: '#1.2 Check In Button',
            type: 'object',
            properties: {
              'backgroundColor:enable': {
                description: '[home.reservationInfo.checkInButton.backgroundColor]',
                title: '#1.2.1 Background Color',
                type: 'boolean',
              },
              backgroundColor: {
                type: 'string',
              },
              'textColor:enable': {
                description: '[home.reservationInfo.checkInButton.textColor]',
                title: '#1.2.2 Text Color',
                type: 'boolean',
              },
              textColor: {
                type: 'string',
              },
              'borderColor:enable': {
                description: '[home.reservationInfo.checkInButton.borderColor]',
                title: '#1.2.3 Border Color',
                type: 'boolean',
              },
              borderColor: {
                type: 'string',
              },
              'borderWidth:enable': {
                description: '[home.reservationInfo.checkInButton.borderWidth]',
                title: '#1.2.4 Border Width',
                type: 'boolean',
              },
              borderWidth: {
                type: 'integer',
              },
              'borderRadius:enable': {
                description: '[home.reservationInfo.checkInButton.borderRadius]',
                title: '#1.2.5 Border Radius',
                type: 'boolean',
              },
              borderRadius: {
                type: 'integer',
              },
            },
          },
          checkOutButton: {
            title: '#1.3 Check Out Button',
            type: 'object',
            properties: {
              'backgroundColor:enable': {
                description: '[home.reservationInfo.checkOutButton.backgroundColor]',
                title: '#1.3.1 Background Color',
                type: 'boolean',
              },
              backgroundColor: {
                type: 'string',
              },
              'textColor:enable': {
                description: '[home.reservationInfo.checkOutButton.textColor]',
                title: '#1.3.2 Text Color',
                type: 'boolean',
              },
              textColor: {
                type: 'string',
              },
              'borderColor:enable': {
                description: '[home.reservationInfo.checkOutButton.borderColor]',
                title: '#1.3.3 Border Color',
                type: 'boolean',
              },
              borderColor: {
                type: 'string',
              },
              'borderWidth:enable': {
                description: '[home.reservationInfo.checkOutButton.borderWidth]',
                title: '#1.3.4 Border Width',
                type: 'boolean',
              },
              borderWidth: {
                type: 'integer',
              },
            },
          },
        },
      },
      contactButton: {
        title: '#2 Contact Button',
        type: 'object',
        properties: {
          'backgroundColor:enable': {
            description: '[home.contactButton.backgroundColor]',
            title: '#2.1 Background Color',
            type: 'boolean',
          },
          backgroundColor: {
            type: 'string',
          },
          'textColor:enable': {
            description: '[home.contactButton.textColor]',
            title: '#2.2 Text Color',
            type: 'boolean',
          },
          textColor: {
            type: 'string',
          },
          'borderColor:enable': {
            description: '[home.contactButton.borderColor]',
            title: '#2.3 Border Color',
            type: 'boolean',
          },
          borderColor: {
            type: 'string',
          },
          'borderWidth:enable': {
            description: '[home.contactButton.borderWidth]',
            title: '#2.4 Border Width',
            type: 'boolean',
          },
          borderWidth: {
            type: 'integer',
          },
          'borderRadius:enable': {
            description: '[home.contactButton.borderRadius]',
            title: '#2.5 Border Radius',
            type: 'boolean',
          },
          borderRadius: {
            type: 'integer',
          },
          'directionsIconUrl:enable': {
            description: '[home.contactButton.directionsIconUrl]',
            title: '#2.6 Directions Icon Url',
            type: 'boolean',
          },
          directionsIconUrl: {
            type: 'string',
          },
          'remoteAssistanceIconUrl:enable': {
            description: '[home.contactButton.remoteAssistanceIconUrl]',
            title: '#2.7 Remote Assistance Icon Url',
            type: 'boolean',
          },
          remoteAssistanceIconUrl: {
            type: 'string',
          },
        },
      },
      widget: {
        title: '#3 Widget',
        type: 'object',
        properties: {
          'order:enable': {
            description: '[home.widget.order]',
            title: '#1 Order',
            type: 'boolean',
          },
          order: {
            type: 'array',
            items: {
              type: 'string',
              enum: ['services', 'amenities', 'quickaccess'],
            },
          },
        },
      },
      directionAndContactUs: {
        title: '#4 Direction and Contact Us',
        type: 'object',
        properties: {
          'showDirection:enable': {
            title: '#4.1 Show Direction',
            type: 'boolean',
            description: '[home.directionAndContactUs.showDirection]',
          },
          showDirection: {
            type: undefined,
            enum: [true, false],
          },
          'showContactUs:enable': {
            title: '#4.2 Show Contact Us',
            type: 'boolean',
            description: '[home.directionAndContactUs.showContactUs]',
          },
          showContactUs: {
            type: undefined,
            enum: [true, false],
          },
        },
      },
      checkOutButton: {
        title: '#5 Check Out Button',
        type: 'object',
        properties: {
          'disableCheckOutScreen:enable': {
            title: '#5.1 Disable Check Out Screen',
            type: 'boolean',
            description: '[home.checkOutButton.disableCheckOutScreen]',
          },
          disableCheckOutScreen: {
            type: undefined,
            enum: [true, false],
          },
          'hideCheckoutButton:enable': {
            title: '#5.2 Hide Checkout Button',
            type: 'boolean',
            description: '[home.checkOutButton.hideCheckoutButton]',
          },
          hideCheckoutButton: {
            type: undefined,
            enum: [true, false],
          },
        },
      },
      quickAccess: {
        title: '#6 Quick access config',
        type: 'object',
        properties: {
          'items:enable': {
            description: '[general.quickAccess.items]',
            title: '#6.1 Quick access items',
            type: 'boolean',
          },
          items: {
            type: 'array',
            items: {
              type: 'object',
              properties: {
                type: {
                  title: 'Type',
                  type: 'string',
                  enum: [
                    'hotelLocation',
                    'remoteAssistance',
                    'accessQrCode',
                    'seeMoreServices',
                    'concierge',
                  ],
                },
                iconName: {
                  title: 'Icon name',
                  type: 'string',
                },
                iconUrl: {
                  title: 'Icon image (Use this if you want to use an image instead of an icon)',
                  type: 'string',
                },
              },
            },
          },
        },
      },
      roomInfo: {
        title: '#7 Room Info',
        type: 'object',
        properties: {
          'hideRoomInfo:enable': {
            description: '[home.roomInfo.hideRoomInfo]',
            title: '#7.1 Hide the room info component',
            type: 'boolean',
          },
          hideRoomInfo: {
            type: undefined,
            enum: [true, false],
          },
          'showUnitTypeImage:enable': {
            description: '[home.roomInfo.showUnitTypeImage]',
            title: '#7.2 Show unit type image instead of room image',
            type: 'boolean',
          },
          showUnitTypeImage: {
            type: undefined,
            enum: [true, false],
          },
          'defaultImage:enable': {
            description: '[home.roomInfo.defaultImage]',
            title: '#7.1 Default image for the room',
            type: 'boolean',
          },
          defaultImage: {
            type: 'string',
          },
        },
      },
    },
  },
};

export const mobileV3ThemeHomeUiSchema: NodeUiSchemaProperties = {
  home: {
    reservationInfo: {
      confirmationCodeTextColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      checkInButton: {
        backgroundColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        textColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        borderColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        borderWidth: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'updown',
        },
        borderRadius: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'updown',
        },
      },
    },
    contactButton: {
      backgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      textColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderWidth: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'updown',
      },
      borderRadius: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'updown',
      },
      directionsIconUrl: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'link',
        'ui:widget': 'uri',
      },
      remoteAssistanceIconUrl: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'link',
        'ui:widget': 'uri',
      },
    },
    widget: {
      order: {
        'ui:label': false,
        'ui:title': '‎',
        'ui:readonly': true,
        items: {
          'ui:label': false,
        },
      },
    },
    directionAndContactUs: {
      showDirection: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
      showContactUs: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
    },
    checkOutButton: {
      disableCheckOutScreen: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
      hideCheckoutButton: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
    },
    quickAccess: {
      items: {
        'ui:label': true,
        'ui:widget': 'select',
      },
    },
    roomInfo: {
      hideRoomInfo: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
      showUnitTypeImage: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
      defaultImage: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'link',
        'ui:widget': 'uri',
      },
    },
  },
};
