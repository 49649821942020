import React, { useMemo } from 'react';

import { DataTableRowCellValue } from '../../@types';
import { TextHighlighted } from './DataTableHighlightText.styles';
import { useDataTableSearchText } from './hooks/useDataTableSearchText';

type DataTableHighlightTextProps = {
  value: DataTableRowCellValue;
};

export const DataTableHighlightText: React.FC<DataTableHighlightTextProps> = ({ value }) => {
  const { getSearchText } = useDataTableSearchText();

  const searchText = useMemo(() => {
    return getSearchText(value.key);
  }, [getSearchText]);

  if (!searchText) {
    return <p style={value.style}>{value.displayValue}</p>;
  }

  const regex = new RegExp(`(${searchText})`, 'gi');
  const textParts = value.displayValue.split(regex);

  return (
    <p style={value.style}>
      {textParts.map((segment, index) => {
        const isMatch = segment.toLowerCase() === searchText.toLowerCase();
        return isMatch ? (
          <TextHighlighted color={value.highlightedColor} key={index}>
            {segment}
          </TextHighlighted>
        ) : (
          segment
        );
      })}
    </p>
  );
};
