import { Button as AccessSetupButton, Label } from '../styles/AccessSetupModals.styles';
import { MdAdd, MdDelete, MdEdit } from 'react-icons/md';
import { ButtonStyle } from '../styles/Button.styles';
import React from 'react';
import Theme from '../../../theme';

interface ButtonProps {
  onClick?: () => void;
}

interface SubmitButtonProps {
  title: string;
}

const Edit: React.FC<ButtonProps> = ({ onClick }) => {
  return (
    <ButtonStyle.Edit onClick={onClick}>
      <MdEdit size="36px" color={Theme.colors.white.hexCode} cursor="pointer" />
    </ButtonStyle.Edit>
  );
};

const Delete: React.FC<ButtonProps> = ({ onClick }) => {
  return (
    <ButtonStyle.Edit onClick={onClick}>
      <MdDelete size="36px" color={Theme.colors.white.hexCode} cursor="pointer" />
    </ButtonStyle.Edit>
  );
};

const Submit: React.FC<ButtonProps & SubmitButtonProps> = ({ onClick, title }) => {
  return (
    <ButtonStyle.Submit onClick={onClick}>
      <MdAdd
        size="36px"
        color={Theme.colors.white.hexCode}
        cursor="pointer"
        style={{ width: 20, height: 20 }}
      />
      {title}
    </ButtonStyle.Submit>
  );
};

const Cancel: React.FC<{ handleCancel?: () => void }> = (props) => {
  return (
    <AccessSetupButton background={Theme.colors.whiteSmoke.hexCode} onClick={props.handleCancel}>
      <Label color={Theme.colors.black.hexCode}>Cancel</Label>
    </AccessSetupButton>
  );
};

const Save: React.FC<{ handleSave?: () => void; title?: string }> = (props) => {
  return (
    <AccessSetupButton
      background={Theme.colors.quickActionButton.hexCode}
      onClick={props.handleSave}
    >
      <Label color={Theme.colors.white.hexCode}>{props.title ?? 'Save'}</Label>
    </AccessSetupButton>
  );
};

export const Button = { Edit, Delete, Submit, Cancel, Save };
