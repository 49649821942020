import { Styles } from 'react-modal';
import Theme from '../../../theme';
import styled from 'styled-components';

export const AccessSetupModalStyle: Styles = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    border: '0',
    outline: '0',
    zIndex: 10,
  },
  content: {
    position: 'fixed',
    overflow: 'hidden',
    scrollbarWidth: 'none',
    alignSelf: 'center',
    border: '0',
    bottom: 'unset',
    gridAutoRows: '1fr',
    justifySelf: 'center',
    margin: '0 auto',
    outline: '0',
    zIndex: 1,
    backgroundColor: Theme.colors.whiteSmoke.hexCode,
    borderRadius: '12px',
    minHeight: '250px',
    width: '700px',
    top: '30%',
    height: '296px',
  },
};

export const AccessSetupCheckInAndCheckoutTimeModalStyle: Styles = {
  ...AccessSetupModalStyle,
  content: {
    ...AccessSetupModalStyle.content,
    height: '400px',
  },
};

export const AccessSetupGracePeriodModalStyle: Styles = {
  ...AccessSetupModalStyle,
  content: {
    ...AccessSetupModalStyle.content,
    height: '400px',
  },
};

export const Text = styled.p`
  color: ${Theme.colors.black.hexCode};
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Container = styled.form`
  margin-top: 32px;
`;

export const Button = styled.button<{ background: string; color?: string }>`
  min-width: 144px;
  height: 60px;
  padding: 16px 40px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 12px;
  border-width: 0px;
  background: ${({ background }): string => {
    return background;
  }};
  color: ${({ color }): string => {
    return color ?? '#000';
  }};
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const Label = styled.span<{ color: string }>`
  color: ${({ color }): string => {
    return color;
  }};
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 32px;
  justify-content: flex-end;
`;

export const OptionLabel = styled.p`
  color: ${Theme.colors.grey.hexCode};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 8px;
`;

type InputProps = {
  error?: string;
};

export const Input = styled.input<InputProps>`
  padding: 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  height: 53px;
  border: 2px solid
    ${({ error }): string => {
      return error ? Theme.colors.danger.hexCode : Theme.colors.whiteSmoke.hexCode;
    }};
  background: ${Theme.colors.white.hexCode};
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 100%;
`;

export const InputMessage = styled.span`
  color: ${Theme.colors.danger.hexCode};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.3px;
  align-self: stretch;
  margin: 4px 0 4px 0;
  height: 8px;
`;
